import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate, useLocation, Outlet } from "react-router-dom";
import $ from 'jquery'

import Nav from "./nav";
import { PrivateComponent, RandomMotivatingSentence } from "./common/common"
import { DEFAULT_IMG, GROUP, ICONS, IMAGE_PATH } from "./common/constants"
import Notification from "./notification"
import { getToken } from "../useToken";
import { IUser } from "./common/types";
import { reqGet } from "../service/apiRequest";

export default function Dashboard() {
  const token = getToken()
  const [user, setUser] = useState<IUser>()


  useEffect(() => {
    reqGet("user/" + token.UserID).then(res => { setUser(res) })
  }, [])


  return (
    <div className="dashboard-container">
      <Nav token={token} user={user} />

      <section className="dashboard">

        <header className="dashboard-header d-none d-xl-flex">
          <div>
            {/* <ContentTitle />*/}
            {PrivateComponent(<Breadcrumb />, GROUP.dev)}
          </div>

          <DashboardBoard user={user} />

        </header>



        <section className="dashboard-body">
          <Outlet />
        </section>

      </section>

    </div>
  );
}



function Breadcrumb() {
  const location = useLocation();
  const [breadcrumb, setBreadcrumb] = useState<Array<JSX.Element>>([]);

  useEffect(() => {
    generateBreadcrumb()
  }, [location])


  function translatLocation() {
    let tablePath: String[] = []

    tablePath = location.pathname.split('/');
    tablePath.shift()

    return tablePath
  }

  function generateBreadcrumb() {

    let link = ""

    setBreadcrumb(translatLocation().map((b, k, row) => {

      link += '/' + b;

      return <li className="breadcrumb-item" key={k}><Link to={link}>{b}</Link></li>
    }))
  }


  return <ul className="breadcrumb d-flex align-items-end">
    {breadcrumb}
  </ul>
}




function DashboardBoard({ user }: { user?: IUser | undefined }) {
  const navigate = useNavigate()

  function logout() {
    localStorage.removeItem('token')
    navigate('/login')
  }

  const MotivatingSentence = () => {
    const sentence = RandomMotivatingSentence()

    return <>
      <p className="paragraph">{sentence.sentence}</p>
      <p className="paragraph text-end mb-0"><i> — {sentence.author}</i></p>
    </>
  }

  return <nav className="dashboard-board">


    <div className="dropdown me-3">
      <button className="btn btn-board" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <h6 className="d-inline">Salut, {user?.FirstName} {user?.Name}</h6>
      </button>
      <div className="dropdown-menu mt-3 py-2 px-3 rounded-3 position-absolute" style={{ width: "400px", marginRight: 300 }}>
        {/*<p className="text-nowrap mb-0">Passe une bonne journée de travail !</p>*/}

        <MotivatingSentence />
      </div>
    </div>

    {PrivateComponent(
      <button className="btn btn-board" title="Calendrier">
        <i className={ICONS.CALENDAR}></i>
      </button>,
      GROUP.dev
    )}

    {PrivateComponent(
      <button className="btn btn-board" title="Tâches">
        <i className="bi bi-list-task"></i>
      </button>,
      GROUP.dev
    )}

    {PrivateComponent(
      <>
        <div className="dropdown">
          <button className="btn btn-board" title="Notifications" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className={ICONS.NOTIFICATION}></i>
          </button>
          <section className="dropdown-menu notification-menu mt-2">
            <Notification />
          </section>
        </div>
      </>,
      GROUP.admin
    )}

    {PrivateComponent(
      <button className="btn btn-board" title="Paramètres">
        <i className={ICONS.SETTINGS}></i>
      </button>,
      GROUP.dev
    )}

    <NavLink to="/account" className="btn btn-board" title="Mon compte" type="button" style={{ fontSize: "1.4rem" }}>
      <img style={{ height: "1.4rem", width: "1.4rem", objectFit: "cover", borderRadius: ".7rem" }} src={user?.UserImage ? IMAGE_PATH.USERS + user.UserImage : DEFAULT_IMG} alt="User profile picture" />
    </NavLink>

    <div className="dropdown">
      <button className="btn btn-board" type="button" title="Déconnexion" data-bs-toggle="dropdown" aria-expanded="false">
        <i className={ICONS.LOGOUT}></i>
      </button>
      <div className="dropdown-menu mt-2 p-3">
        <p className="text-nowrap">Etes-vous sûr de vouloir <br />vous déconnecter ?</p>
        <button className="btn btn-delete" onClick={() => logout()}> <i className={ICONS.LOGOUT + " me-2"}></i>Déconnexion</button>
      </div>
    </div>

    {PrivateComponent(
      <Link to={"/help"} className="btn btn-board" type="button" title="Aide">
        <i className="bi bi-question-circle"></i>
      </Link>,
      GROUP.dev)
    }
  </nav>
}


function ContentTitle() {

  const location = useLocation();
  const [locationTitle, setLocationTitle] = useState<string>("");

  useEffect(() => {
    setLocationTitle(translatLocationTitle())
  }, [location])

  function translatLocationTitle() {
    let title = ''

    if (location.pathname == "/" || location.pathname.startsWith("/home"))
      title = 'Accueil'
    else if (location.pathname.startsWith("/account"))
      title = 'Mon compte'
    else if (location.pathname.startsWith("/activity"))
      title = 'Activité'
    else if (location.pathname.startsWith("/address"))
      title = 'Adresses'
    else if (location.pathname.startsWith("/construction"))
      title = 'Chantier '
    else if (location.pathname.startsWith("/database"))
      title = 'Données'
    else if (location.pathname.startsWith("/material"))
      title = 'Matériels'
    else if (location.pathname.startsWith("/management"))
      title = 'Régies'
    else if (location.pathname.startsWith("/observation"))
      title = 'Observation'
    else if (location.pathname.startsWith("/product"))
      title = 'Pièces'
    else if (location.pathname.startsWith("/recurrence"))
      title = 'Récurrences'
    else if (location.pathname.startsWith("/statement"))
      title = 'Relevé'
    else if (location.pathname.startsWith("/subcontractor"))
      title = 'Sous-traitant'
    else if (location.pathname.startsWith("/tool"))
      title = 'Outils'
    else if (location.pathname.startsWith("/ui"))
      title = 'User Interface'
    else if (location.pathname.startsWith("/users"))
      title = 'Utilisateurs'
    else if (location.pathname.startsWith("/vehicle"))
      title = 'Vehicules'
    else if (location.pathname.startsWith("/warehouse"))
      title = 'Entrepôt'
    else if (location.pathname.startsWith("/zone"))
      title = 'Zones'


    return title
  }

  return <h2 className="dashboard-title">{locationTitle}</h2>
}

