import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { dateFormat, PrivateComponent, PrivateLinkAdd, ServiceBadge } from "../../../common/common";
import { IAddress, IAddressContract, IAddressService } from "../../../common/types";
import { reqDelete, reqGet, reqPut } from "../../../../service/apiRequest";
import { GROUP, ICONS, SERVICE } from "../../../common/constants";
import { getToken } from "../../../../useToken";


export default function AddressContract({ reload, setReload }) {
    const location = useLocation()
    const params = useParams()
    const idAddress = params.idAddress

    const LinkBack = () => {
        if (location?.state?.back)
            return location.state.back
        else if (idAddress)
            return "/address/" + idAddress
        else
            return "/address"
    }

    const [address, setAddress] = useState<IAddress>()
    const [addressContracts, setAddressContracts] = useState<Array<IAddressContract>>([])

    useEffect(() => {
        reqGet("address/" + idAddress).then(res => setAddress(res))
        reqGet("addresscontract/address/" + idAddress).then(res => {
            setAddressContracts(res)
        })
    }, [idAddress])

    useEffect(() => {
        if (reload) {
            reqGet("address/" + idAddress).then(res => setAddress(res))
            reqGet("addresscontract/address/" + idAddress).then(res => {
                setAddressContracts(res)
            })
        }
    }, [reload])


    return <main className="body-container content">

        <header className="header">
            <div>
                <h2 className="section-title">Contrats</h2>
                <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
            </div>

            {PrivateLinkAdd("add")}
        </header>


        <section className="body">
            {addressContracts.length > 0 && addressContracts.map(addressContract =>
                <React.Fragment key={addressContract.AddressContractID}>
                    <AddressContractCard addressContract={addressContract} address={address} reload={reload} setReload={setReload} />
                </React.Fragment>
            )}
        </section>

    </main>
}

export function AddressContractCard({ addressContract, address, reload, setReload }: { addressContract: IAddressContract, address: IAddress | undefined, reload?, setReload?}) {
    const token = getToken()
    const location = useLocation()
    const [contractServices, setContractServices] = useState<Array<IAddressService>>([])

    useEffect(() => {
        reqGet("addressservice/contract/" + addressContract.AddressContractID).then(res => {
            setContractServices(res)
        }, rej => { })
    }, [addressContract])

    useEffect(() => {
        if (reload) {
            reqGet("addressservice/contract/" + addressContract.AddressContractID).then(res => {
                setContractServices(res)
            }, rej => { })
        }
    }, [reload])


    function RemoveAddressContract() {

        reqDelete("addresscontract/" + addressContract.AddressContractID,
            {
                data: {
                    ...addressContract,
                    ActivityUserID: token.UserID,
                    AddressLine: address?.AddressLine,
                    AddressZipCode: address?.ZipCode,
                    City: address?.City
                }
            }).then(res => {
                setReload(true)
            })
    }

    function TerminateAddressContract() {

        reqPut("addresscontract/" + addressContract.AddressContractID + "/terminate",
            {
                AddressContractID: addressContract.AddressContractID,
                AddressContractTerminate: 1,
                AddressContractNumber: addressContract.AddressContractNumber,
                ActivityUserID: token.UserID,
                AddressLine: address?.AddressLine,
                AddressZipCode: address?.ZipCode,
                City: address?.City
            }).then(res => {
                setReload(true)
            })
    }

    function RestartAddressContract() {

        reqPut("addresscontract/" + addressContract.AddressContractID + "/restart",
            {
                AddressContractID: addressContract.AddressContractID,
                AddressContractTerminate: 0,
                AddressContractNumber: addressContract.AddressContractNumber,
                ActivityUserID: token.UserID,
                AddressLine: address?.AddressLine,
                AddressZipCode: address?.ZipCode,
                City: address?.City
            }).then(res => {
                setReload(true)
            })
    }


    function RemoveService(s: IAddressService) {

        reqDelete("addressservice/" + s.AddressServiceID,
            {
                data: {
                    ActivityUserID: token.UserID,
                    Service: s.Service,
                    AddressLine: address?.AddressLine,
                    AddressZipCode: address?.ZipCode,
                    City: address?.City
                }
            }).then(res => {
                setReload(true)
            })
    }


    return <section className="card">

        {/* TERMINATE */}
        <div className="modal" id={"terminateContract" + addressContract?.AddressContractID} tabIndex={-1} aria-labelledby={"terminateContract" + addressContract?.AddressContractID} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <header className="modal-header">
                        <h1 className="modal-title fs-5" id={"terminateContract" + addressContract?.AddressContractID}>Résilier contrat</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </header>

                    <div className="modal-body">
                        <p>Êtes-vous  sur de vouloir résilier :</p>
                        <p><b>Contrat N°{addressContract.AddressContractNumber}</b></p>
                    </div>

                    <footer className="modal-footer">
                        <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                            <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                        </button>

                        <button type="button" className={"btn btn-outline"} data-bs-dismiss="modal" onClick={() => TerminateAddressContract()}>
                            <i className={ICONS.TERMINATE + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Résilier</span>
                        </button>
                    </footer>
                </div>
            </div>
        </div>



        {/* RESTART */}
        <div className="modal" id={"restartContract" + addressContract?.AddressContractID} tabIndex={-1} aria-labelledby={"restartContract" + addressContract?.AddressContractID} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <header className="modal-header">
                        <h1 className="modal-title fs-5" id={"restartContract" + addressContract?.AddressContractID}>Resouscrire contrat</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </header>

                    <div className="modal-body">
                        <p>Êtes-vous  sur de vouloir resouscrire :</p>
                        <p><b>Contrat N°{addressContract.AddressContractNumber}</b></p>
                    </div>

                    <footer className="modal-footer">
                        <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                            <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                        </button>

                        <button type="button" className={"btn btn-outline"} data-bs-dismiss="modal" onClick={() => RestartAddressContract()}>
                            <i className={ICONS.CONTRACT + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Resouscrire</span>
                        </button>
                    </footer>
                </div>
            </div>
        </div>



        {/* REMOVE */}
        <div className="modal" id={"removeContract" + addressContract?.AddressContractID} tabIndex={-1} aria-labelledby={"removeContract" + addressContract?.AddressContractID} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <header className="modal-header">
                        <h1 className="modal-title fs-5" id={"removeContract" + addressContract?.AddressContractID}>Supprimer contrat</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </header>

                    <div className="modal-body">
                        <p>Êtes-vous  sur de vouloir supprimer :</p>
                        <p><b>Contrat N°{addressContract.AddressContractNumber}</b></p>
                    </div>

                    <footer className="modal-footer">
                        <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                            <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                        </button>

                        <button type="button" className={"btn btn-delete"} data-bs-dismiss="modal" onClick={() => RemoveAddressContract()}>
                            <i className={ICONS.DELETE + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Supprimer</span>
                        </button>
                    </footer>
                </div>
            </div>
        </div>



        <section className="card-body">
            <header className="header">
                <div>
                    <h3 className="section-title-2 d-inline">Contrat N°{addressContract?.AddressContractNumber}</h3>
                    {addressContract.AddressContractTerminate == 1 && <span className="badge bg-danger rounded-5 ms-3"><i className={ICONS.TERMINATE + " me-2"}></i>Résilisé</span>}
                </div>

                {PrivateComponent(
                    <div className="dropdown">
                        <button className="btn btn-sm btn-outline rounded-3" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                        <ul className="dropdown-menu">
                            {PrivateComponent(
                                <li>
                                    <Link to={addressContract?.AddressContractID + "/update"} className="dropdown-item mb-2" title="Modifier"><i className={ICONS.UPDATE + " me-2"}></i>Modifier</Link>
                                </li>,
                                GROUP.crud_sav, ["AddressContractUpdate"]
                            )}
                            {PrivateComponent(
                                <li>
                                    <Link to={addressContract?.AddressContractID + "/newprice"} className="dropdown-item mb-2" title="Nouveau prix"><i className={ICONS.ADD + " me-2"}></i>Nouveau prix</Link>
                                </li>,
                                GROUP.crud_sav, ["AddressContractNewPrice"]
                            )}

                            {addressContract.AddressContractTerminate == 0 ?
                                PrivateComponent(
                                    <li>
                                        <button className="dropdown-item mb-2" title="Résilier" data-bs-toggle="modal" data-bs-target={"#terminateContract" + addressContract?.AddressContractID}>
                                            <i className={ICONS.TERMINATE + " me-2"}></i> Résilier
                                        </button>
                                    </li>,
                                    GROUP.crud_sav, ["AddressContractTerminate"]
                                ) :
                                PrivateComponent(
                                    <li>
                                        <button className="dropdown-item mb-2" title="Restart" data-bs-toggle="modal" data-bs-target={"#restartContract" + addressContract?.AddressContractID}>
                                            <i className={ICONS.CONTRACT + " me-2"}></i> Resouscrire
                                        </button>
                                    </li>,
                                    GROUP.crud_sav, ["AddressContractRestart"]
                                )
                            }

                            {PrivateComponent(
                                <li>
                                    <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target={"#removeContract" + addressContract?.AddressContractID}>
                                        <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                    </button>
                                </li>,
                                GROUP.admin, ["AddressContractDelete"]
                            )}
                        </ul>
                    </div>,
                    GROUP.crud_sav, ["AddressContractUpdate", "AddressContractNewPrice", "AddressContractTerminate", "AddressContractRestart", "AddressContractDelete"]
                )}
            </header>

            <section className="container-fluid mb-3">

                <div className="row row-cols-2 row-cols-md-4">
                    <div className="col ps-0 mb-3 mb-md-0">
                        <h5 className="item-title">Date de contrat</h5>
                        <p className="item-text">{dateFormat(addressContract.AddressContractDate, "dd/MM/yyyy")}</p>
                    </div>
                    <div className="col ps-0 mb-3 mb-md-0">
                        <h5 className="item-title">Date de facturation</h5>
                        <p className="item-text">{dateFormat(addressContract.AddressContractBilling, "dd/MM/yyyy")}</p>
                    </div>
                    <div className="col ps-0 mb-3 mb-md-0">
                        <h5 className="item-title">Période de facturation</h5>
                        <p className="item-text">{addressContract.AddressContractBillingPeriod}</p>
                    </div>
                    {PrivateComponent(
                        <div className="col ps-0 mb-3 mb-md-0">
                            <h5 className="item-title">Prix</h5>
                            <p className="item-text">{addressContract.AddressContractPrice && addressContract.AddressContractPrice + " CHF"} <small>({dateFormat(addressContract.AddressContractDatePrice, "dd/MM/yyyy")})</small></p>
                        </div>,
                        GROUP.crud_sav, ["PriceRead", "AddressContractPriceRead"]
                    )}
                </div>

            </section>

            <div className="mb-3">
                <h5 className="item-title">Information</h5>
                {addressContract.AddressContractDateTerminate &&
                    <p className="item-text">Résilié le {dateFormat(addressContract.AddressContractDateTerminate, "dd/MM/yyyy")}</p>
                }
                <p className="item-text">{addressContract.AddressContractNote}</p>
            </div>



            <section>
                <header className="header">
                    <h3 className="section-title-2">Prestations</h3>
                    <Link to={addressContract.AddressContractID + "/service/add"} className="btn btn-add" state={{ back: location.pathname }}>
                        <i className={ICONS.ADD + " me-2"}></i> Ajouter
                    </Link>
                </header>

                <div className="">

                </div>

                <div className="list " id="list">

                    {contractServices?.length > 0 && contractServices?.map((s, k) => {

                        return <div className="list-item py-2 mb-2" key={k}>

                            <div className="modal" id={"removeService" + s.AddressServiceID} tabIndex={-1} aria-labelledby={"removeService" + s.AddressServiceID} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <header className="modal-header">
                                            <h1 className="modal-title fs-5" id={"removeService" + s.AddressServiceID}>Supprimer prestation</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </header>

                                        <div className="modal-body">
                                            <p>Êtes-vous  sur de vouloir supprimer :</p>
                                            <p><b>{s.Service}</b></p>
                                        </div>

                                        <footer className="modal-footer">
                                            <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                                                <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                                            </button>

                                            <button type="button" className={"btn btn-delete"} data-bs-dismiss="modal" onClick={() => RemoveService(s)}>
                                                <i className={ICONS.DELETE + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Supprimer</span>
                                            </button>
                                        </footer>
                                    </div>
                                </div>
                            </div>

                            <section className="list-item-data">
                                <div className="container-fluid px-0">
                                    <div className="row" style={{ minHeight: "3rem" }}>
                                        <div className="col-12 col-sm-4 col-lg-3 col-xxl-2 mb-3 mb-lg-0 order-1 ps-0">
                                            <ServiceBadge service={s.Service} />
                                        </div>

                                        {s.Service != SERVICE.HEATING &&
                                            <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 order-3">
                                                <h6 className="tmb-1">Périodicité</h6>
                                                <p className="mb-0">{s.AddressServicePeriodicity}</p>
                                            </div>
                                        }

                                        {s.InstallationID &&
                                            <div className="col-12 col-sm-8 col-lg-6 col-xxl-4 mt-3 mt-xxl-0 order-4 order-lg-5">
                                                <h6 className="mb-1">Installation</h6>
                                                <p className="text-truncate mb-0">{s.Type || s.IType || s.Class || s.IClass}<span>, {s.Model || s.IModel}</span></p>
                                            </div>}

                                        {s.Service == SERVICE.SUBCONTRACTOR &&
                                            <div className="col-12 col-sm-4 col-lg-3 col-xxl-2 mt-3 mt-lg-0 order-5 order-lg-4">
                                                <h6 className="mb-1">Sous-traitant</h6>
                                                <p className="text-truncate mb-0">{s.Company}</p>

                                            </div>
                                        }

                                        {/* Heating settings */}

                                        {/* s.Service == SERVICE.HEATING &&
                    <ServiceHeating idAddress={parseInt(idAddress)} />
                        */}

                                    </div>
                                </div>

                                {s.AddressServiceNote &&
                                    <div className="mt-xl-3 mt-3">
                                        <h6 className="mb-1">Information</h6>
                                        <p className="mb-0">{s.AddressServiceNote}</p>
                                    </div>}
                            </section>


                            <footer className="list-item-more align-items-start align-items-xl-center">
                                {PrivateComponent(
                                    <div className="dropdown">
                                        <button className="btn btn-sm btn-outline border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link to={addressContract.AddressContractID + "/service/" + s.AddressServiceID + "/update"} className="dropdown-item px-2 py-1 mb-2" state={{ back: location.pathname }}>
                                                    <i className={ICONS.UPDATE + " me-2"}></i>Modifier
                                                </Link>
                                            </li>
                                            <li>
                                                <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target={"#removeService" + s.AddressServiceID}>
                                                    <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                                </button>
                                            </li>
                                        </ul>
                                    </div>,
                                    GROUP.crud_sav, ["AddressServiceUpdate", "AddressServiceDelete"]
                                )}
                            </footer>

                        </div>

                    })}

                </div>
            </section>
        </section>

    </section>
}
