import React, { useEffect, useState, useMemo, useLayoutEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import $ from 'jquery'

import { reqGet } from "../../../service/apiRequest";
import { DebouncedInput, ProgressColor, fuzzyFilter, ProductTypeIconResolver } from '../../common/common'
import { DEFAULT_IMG, ICONS, PRODUCT_AVAILABLE, PRODUCT_CLASS_ID, PRODUCT_TYPE } from '../../common/constants'
import { IStorageProduct, IRack } from '../../common/types'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
} from '@tanstack/react-table'

export default function Storage({ reload, setReload }) {
    const location = useLocation()
    const params = useParams()
    const idWarehouse = params.idWarehouse

    const [data, setData] = useState<Array<IStorageProduct>>([])
    const [rackList, setRackList] = useState<Array<IRack>>([])
    const [rackFilter, setRackFilter] = useState<string>("")

    useEffect(() => {
        reqGet('storage/warehouse/' + idWarehouse).then((res) => { setData(res) })
        reqGet("storage/warehouse/" + idWarehouse + "/rack").then(res => { setRackList(res) })
    }, [])

    useEffect(() => {
        if (reload) {
            reqGet('storage/warehouse/' + idWarehouse).then((res) => { setData(res) })
            reqGet("storage/warehouse/" + idWarehouse + "/rack").then(res => { setRackList(res) })
            setReload(false)
        }
    }, [reload])



    const columns = useMemo<ColumnDef<IStorageProduct, any>[]>(
        () => [
            {
                accessorFn: row => row.ProductBrand,
                id: 'ProductBrand',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductSupplier,
                id: 'ProductSupplier',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductModel,
                id: 'Model',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductReference,
                id: 'Reference',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Rack + row.Row + "-" + row.Col,
                id: 'Rack',
                cell: info => info.getValue()
            }

        ], []
    )

    const [globalFilter, setGlobalFilter] = useState<string>('')

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        table.setPageSize(100)
    }, [])


    useLayoutEffect(() => {
        if (rackList)
            if (location?.state?.rackFilter) {
                $("#rackNav .btn.active").removeClass("active")
                $("#btnRack" + location.state.rackFilter).addClass("active")
                table.setColumnFilters([{ "id": "Rack", "value": location.state.rackFilter }])
                setRackFilter(location.state.rackFilter)
            }
    }, [rackList])

    function HandleRackSort(rack) {
        if (!rack) {
            table.setColumnFilters([])
            setRackFilter("")
        } else {
            table.setColumnFilters([{ "id": "Rack", "value": rack }])
            setRackFilter(rack)
        }
    }


    return <>
        <header className="header">

            <div className="container-fluid">
                <div className="row mb-3">

                    <div className="col-6 ps-0">
                        <label htmlFor="" className="form-label">Etagère</label>
                        <select name="" id="" className="form-select" onChange={(e) => HandleRackSort(e.target.value)}>
                            <option value="">Tout</option>
                            {rackList.map(rack => {
                                return <option key={rack.Rack} value={rack.Rack}>
                                    {rack.Rack}
                                </option>
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} className="mb-3" placeholder="Recherche" />

        </header>

        <section className="body">

            <div className="list list-hover">

                {table.getRowModel().rows.map(row => {
                    return StorageProductListItem(row.original, rackFilter)
                })}

            </div>


        </section>

        <footer className="d-flex justify-content-between align-items-center mt-3 pagination">
            <div className="d-flex align-items-center">
                <button className="btn btn-sm btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                    {'<<'}
                </button>
                <button className="btn btn-sm btn-outline me-3" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                    {'<'}
                </button>

                <p className="me-3 mb-0">
                    {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                </p>

                <button className="btn btn-sm btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                    {'>'}
                </button>
                <button className="btn btn-sm btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                    {'>>'}
                </button>
            </div>


            <div className="">
                <select className="form-select" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                    {[100, 200, 500].map(pageSize => (

                        <option key={pageSize} value={pageSize}>
                            Montrer {pageSize}
                        </option>

                    ))}

                </select>
            </div>
        </footer>

    </>
}


function StorageProductListItem(storage: IStorageProduct, rackFilter?: string) {
    const qMax: number = parseInt(storage?.QuantityMax ?? "0")
    const quantity: number = parseInt(storage?.Quantity ?? "0")
    const qMin: number = parseInt(storage?.QuantityMin ?? "0")

    var bg = ProgressColor(quantity, qMin, qMax)

    return <Link to={"" + storage.StorageID} key={storage.StorageID} className="btn list-item mb-3" state={{ rackFilter: rackFilter }}>

        <div className="d-flex justify-content-center align-items-center pe-3">
            <h5 className="mb-1">{storage.Rack}{storage.Row}-{storage.Col}{storage.Compartment ? "-" + storage.Compartment : ""}</h5>
        </div>

        <div className="list-item-start">
            <div className="item-start">
                {storage?.ProductImage ?
                    <img className="img-fluid" src={storage?.ProductImage ? "../media/images/product/" + storage?.ProductImage : DEFAULT_IMG} alt="" />
                    : <div className="bg-background-light p-2 rounded-5 h-100 w-100 d-flex align-items-center justify-content-center">
                        <i className={
                            (storage.ProductType == PRODUCT_TYPE.PIECE ? ICONS.PIECE :
                                storage.ProductType == PRODUCT_TYPE.TOOL ? ICONS.TOOL : ICONS.EXCHANGER)
                            + " text-muted"} style={{ fontSize: "1.6rem" }}></i>
                    </div>
                }
            </div>
        </div>

        <section className="list-item-data">
            <div className="row">

                <div className="col-7 text-start">
                    <h6 className="title text-truncate mb-0">{storage?.ProductModel}</h6>
                    <p className="text-muted text-truncate mb-1">
                        <small>{storage?.ProductBrand} {storage?.ProductReference ? " - " + storage.ProductReference : ""}</small>
                    </p>
                </div>

                <div className="col-5 text-end px-0">


                    {storage.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE &&
                        <>
                            <p className="text-end mb-2">{ProductTypeIconResolver(storage.ProductType)}</p>

                            <h6 className="mb-2">{quantity ? quantity : 0} <span className="text-muted">{qMax ? " / " + qMax : ""}</span></h6>

                            {qMin >= 0 && qMax >= 1 ?
                                <div className="progress" style={{ "height": "5px" }}>
                                    <div className={"progress-bar " + bg} role="progressbar" aria-label="Example 1px high" style={{ "width": (quantity * 100 / qMax) + "%" }} aria-valuenow={quantity} aria-valuemin={0} aria-valuemax={qMax}></div>
                                </div>
                                : ""}
                        </>
                    }

                    {storage.ProductClassID == PRODUCT_CLASS_ID.REUSABLE &&
                        <>
                            <div className="text-end mb-2">
                                {storage.ProductCondition == "Hors service" && <i className="bi bi-x-octagon text-danger me-2"></i>}

                                {storage.ProductCondition == "Maintenance" && <i className="bi bi-exclamation-triangle text-warning me-2"></i>}

                                {ProductTypeIconResolver(storage.ProductType)}
                            </div>

                            <p className="mb-0">{StorageAvailabilityBadge(storage.ProductAvailable)}</p>
                        </>
                    }
                </div>

            </div>

        </section>
    </Link>

}

export function StorageAvailabilityBadge(StorageAvailability: number | null) {
    if (StorageAvailability == PRODUCT_AVAILABLE.IN_STOCK)
        return <><span className="dot bg-success me-1"></span> En stock</>

    else if (StorageAvailability == PRODUCT_AVAILABLE.REPAIRING)
        return <><span className="dot bg-warning me-1"></span> En réparation</>

    else if (StorageAvailability == PRODUCT_AVAILABLE.OUT)
        return <><span className="dot bg-danger me-1"></span> Sorti</>

    else
        return <>Non stockée</>
}