import React, { useState, useEffect, useLayoutEffect } from 'react'
import { NavLink, Link, useParams, useNavigate } from 'react-router-dom'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { reqGet, reqPost } from '../../../service/apiRequest'
import { getToken } from '../../../useToken'
import { ButtonSubmit, Header, HeaderRow } from '../../common/common'
import { HashPassword } from '../../common/hash'
import { IUserRole, IUser } from '../../common/types'
import { post } from "../../../service/request"


const validationSchema = yup.object({
    Username: yup.string().required("Ce champ est obligatoire"),
    Password: yup.string().required("Ce champ est obligatoire"),
    FirstName: yup.string().required("Ce champ est obligatoire"),
    Name: yup.string().required("Ce champ est obligatoire"),
    Email: yup.string().email().nullable(),
    Phone: yup.string().nullable(),
    UserRoleID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    UserManagerID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    UserBirthday: yup.string().nullable(),
    UserDateContract: yup.string().nullable(),
    UserFrontier: yup.string().nullable(),
    UserCivilStatus: yup.string().nullable(),
    UserAddress: yup.string().nullable(),
    UserNationality: yup.string().nullable(),
    UserAVS: yup.string().nullable()
})

const initialValues = {
    Username: '',
    Password: '',
    Email: '',
    FirstName: '',
    Name: '',
    Phone: '',
    UserManagerID: null,
    UserBirthday: null,
    UserDateContract: null,
    UserFrontier: null,
    UserCivilStatus: null,
    UserAddress: null,
    UserNationality: null,
    UserAVS: null
}

export default function UserAdd({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()

    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IUser>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [userRole, setUserRole] = useState<Array<IUserRole>>([])
    const [managers, setManagers] = useState<Array<IUser>>([])

    const [checkUsername, setCheckUsername] = useState<boolean | null>(null)

    useEffect(() => {

        reqGet('userrole').then((res) => {
            if (token.Role === "Developpeur" || token.Role === "Admin")
                setUserRole(res)
            else
                setUserRole(res.filter(ur => ur.Role !== "Developpeur" && ur.Role !== "Admin"))

        })
        reqGet('user').then((res) => { setManagers(res.filter(u => u.Role == "Admin")) })
    }, [])

    const onSubmit: SubmitHandler<IUser> = data => {
        if (checkUsername != false) {

            data.Password = HashPassword(data.Password)

            reqPost('user', { ...data, ActivityUserID: token.UserID }).then((res) => {
                setReload(true)
                navigate('/users/' + res)
            })
        }
    }

    function CheckUsername() {
        const username = $("#Username").val()

        if (username != "") {

            post("checkusername", { Username: username }).then((res) => {
                setCheckUsername(res)

            })
        }
    }

    $("#Username").focusout(() => CheckUsername())

    function TogglePassword() {
        var input = $(".inputPassword");
        if (input[0].type === "password") {
            input[0].type = "text";
        } else {
            input[0].type = "password";
        }
    }

    return <div className="row h-100 justify-content-center">

        <div className="col-md-8 col-xxl-6 col-h-fill overflow-auto">
            <section className="body-container">

                {Header(
                    <h3 className="section-title">Ajouter un utilisateur</h3>,
                    <button onClick={() => navigate("/users")} className="btn link-nav">{'<'} Retour</button>
                )}

                <section className="body container-fluid">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="Username" className="form-label">Nom d'utilisateur *</label>
                            <input type="text" id="Username" className={
                                "form-control " +
                                (errors?.Username || checkUsername === false ? "is-invalid" :
                                    checkUsername === true ? "is-valid" : "")
                            } {...register("Username")} />

                            {!errors.Username?.message && checkUsername && <p className="text-success">Le nom d'utilisateur est valide</p>}

                            {checkUsername === false && <p className="input-msg-error">Le nom d'utilisateur est déjà utilisé</p>}

                            {errors.Username?.message && <p className="input-msg-error">{errors.Username?.message}</p>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="Password" className="form-label">Mot de passe *</label>
                            <input type="password" className={"inputPassword form-control " + (errors?.Password ? "is-invalid" : "")} {...register("Password")} />

                            <div className="form-check mt-1">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onClick={() => TogglePassword()} />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Afficher mot de passe
                                </label>
                            </div>

                            {errors.Password?.message && <p className="input-msg-error">{errors.Password?.message}</p>}
                        </div>

                        <div className="row">

                            <div className="col-6">

                                <div className="mb-3">
                                    <label htmlFor="FirstName" className="form-label">Prénom *</label>
                                    <input type="text" className={"form-control " + (errors?.FirstName ? "is-invalid" : "")} {...register("FirstName")} />

                                    {errors.FirstName?.message && <p className="input-msg-error">{errors.FirstName?.message}</p>}
                                </div>

                            </div>

                            <div className="col-6">

                                <div className="mb-3">
                                    <label htmlFor="Name" className="form-label">Nom *</label>

                                    <input type="text" className={"form-control " + (errors?.Name ? "is-invalid" : "")} {...register("Name")} />

                                    {errors.Name?.message && <p className="input-msg-error">{errors.Name?.message}</p>}

                                </div>

                            </div>

                        </div>

                        <div className="row row-cols-2 mb-3">
                            <div className="col">
                                <label htmlFor="UserRoleID" className="form-label">Role *</label>

                                <select className={"form-select " + (errors?.UserRoleID ? "is-invalid" : "")} {...register("UserRoleID")}>

                                    <option value="" hidden></option>

                                    {userRole.map((u: IUserRole, k: number) => {
                                        return <option key={k} value={u.UserRoleID}>{u.Role}</option>;
                                    })}

                                </select>

                                {errors.UserRoleID?.message && <p className="input-msg-error">{errors.UserRoleID?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="UserManagerID" className="form-label">Responsable</label>

                                <select className={"form-select " + (errors?.UserManagerID ? "is-invalid" : "")} {...register("UserManagerID")}>

                                    <option value="" hidden></option>

                                    {managers.map((u: IUser, k: number) => {
                                        return <option key={k} value={u.UserID}>{u.FirstName} {u.Name}</option>;
                                    })}

                                </select>

                                {errors.UserManagerID?.message && <p className="input-msg-error">{errors.UserManagerID?.message}</p>}
                            </div>
                        </div>




                        <div className="row row-cols-2 mb-3">
                            <div className="col">
                                <label htmlFor="Email" className="form-label">Email</label>
                                <input type="text" className={"form-control " + (errors?.Email ? "is-invalid" : "")} {...register("Email")} />

                                {errors.Email?.message && <p className="input-msg-error">{errors.Email?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="Phone" className="form-label">Téléphone</label>

                                <input type="text" className={"form-control " + (errors?.Phone ? "is-invalid" : "")} {...register("Phone")} />

                                {errors.Phone?.message && <p className="input-msg-error">{errors.Phone?.message}</p>}
                            </div>
                        </div>


                        <div className='row row-cols-2 mb-3'>
                            <div className='col'>
                                <label htmlFor="UserBirthday" className="form-label">Date de naissance</label>

                                <input type="date" className={"form-control " + (errors?.UserBirthday ? "is-invalid" : "")} {...register("UserBirthday")} />

                                {errors.UserBirthday?.message && <p className="input-msg-error">{errors.UserBirthday?.message}</p>}
                            </div>

                            <div className='col'>
                                <label htmlFor="UserAddress" className="form-label">Adresse</label>

                                <input type="text" className={"form-control " + (errors?.UserAddress ? "is-invalid" : "")} {...register("UserAddress")} />

                                {errors.UserAddress?.message && <p className="input-msg-error">{errors.UserAddress?.message}</p>}
                            </div>
                        </div>


                        <div className='row row-cols-2 mb-3'>
                            <div className='col'>
                                <label htmlFor="UserAVS" className="form-label">AVS</label>

                                <input type="text" className={"form-control " + (errors?.UserAVS ? "is-invalid" : "")} {...register("UserAVS")} />

                                {errors.UserAVS?.message && <p className="input-msg-error">{errors.UserAVS?.message}</p>}
                            </div>

                            <div className='col'>
                                <label htmlFor="UserNationality" className="form-label">Nationalité</label>

                                <input type="text" className={"form-control " + (errors?.UserNationality ? "is-invalid" : "")} {...register("UserNationality")} />

                                {errors.UserNationality?.message && <p className="input-msg-error">{errors.UserNationality?.message}</p>}
                            </div>
                        </div>


                        <div className='row row-cols-2 mb-3'>
                            <div className='col'>
                                <label htmlFor="UserCivilStatus" className="form-label">Etat civil</label>

                                <select className={"form-select " + (errors?.UserCivilStatus ? "is-invalid" : "")} {...register("UserCivilStatus")} >
                                    <option value="Célibataire">Célibataire</option>
                                    <option value="Marié">Marié</option>
                                    <option value="Divorcé">Divorcé</option>
                                </select>

                                {errors.UserCivilStatus?.message && <p className="input-msg-error">{errors.UserCivilStatus?.message}</p>}
                            </div>

                            <div className='col'>
                                <label htmlFor="UserDateContract" className="form-label">Date de contrat</label>

                                <input type="date" className={"form-control " + (errors?.UserDateContract ? "is-invalid" : "")} {...register("UserDateContract")} />

                                {errors.UserDateContract?.message && <p className="input-msg-error">{errors.UserDateContract?.message}</p>}
                            </div>
                        </div>



                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>


                    </form>
                </section>
            </section >

        </div>

    </div>
}