import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { reqGet, reqDelete } from "../../../../service/apiRequest";

import { getToken } from '../../../../useToken'
import { PrivateComponent, PrivateLinkUpdate, ProductTypeLinkResolver } from '../../../common/common'
import { LAYOUT, DEFAULT_IMG, GROUP, PERMISSION, PRODUCT_TYPE } from '../../../common/constants'
import { IStorage, IWarehouse, IProduct, IVehicle, IVehicleProduct } from '../../../common/types'

interface IVehicleStorage extends IVehicle, IVehicleProduct { }
interface IStorageWarehouse extends IStorage, IWarehouse { }

interface ITransfer {
    from: IStorage | IVehicle,
    to: { table: string, id: number }
}


export default function PieceContent({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idProduct = params.idProduct

    const [product, setProduct] = useState<IProduct>()
    const [productStorages, setProductStorages] = useState<Array<IStorageWarehouse> | null>(null)
    const [productVehicles, setProductVehicles] = useState<Array<IVehicleStorage> | null>(null)

    useEffect(() => {
        reqGet("product/" + idProduct).then(res => {
            if (res.ProductType != PRODUCT_TYPE.PIECE)
                navigate("/" + ProductTypeLinkResolver(res.ProductType) + "/" + idProduct);

            setProduct(res)
        }, rej => {
            navigate(-1)
        })
    }, [idProduct])

    useEffect(() => {

        if (product) {

            reqGet("storage/product/" + product.ProductID).then((res) => {
                setProductStorages(res)
            }, (rej) => {
                setProductStorages(null)
            })

            reqGet("vehicleproduct/product/" + product.ProductID).then((res) => {
                setProductVehicles(res)
            }, (rej) => {
                setProductVehicles(null)
            })
        }

    }, [product])

    const [assignment, setAssignment] = useState<boolean>(false)
    const [adjustContent, setAdjustContent] = useState<boolean>(false)
    const [transfer, setTransfer] = useState<ITransfer>()
    const [transfering, setTransfering] = useState<boolean>(false)

    function Assignment() {
        setAssignment(true)
    }

    function AdjustContent() {
        setAdjustContent(true)
    }

    function Transfer(stock: IStorage | IVehicle) {
        setTransfering(true)
        setTransfer({ from: stock, to: { table: "", id: 0 } })
    }

    function removeProduct() {
        reqDelete("product/" + idProduct, { data: { ActivityUserID: token.UserID } }).then(res => {
            setReload(true)
            navigate("/piece")
        })
    }

    if (!transfering) {
        return <section className="body-container">

            <div className="modal fade" id="removeModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Supprimer</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Êtes vous  sur de vouloir supprimer :</p>
                            <p><b>{product?.ProductModel}, {product?.ProductBrand} - {product?.ProductReference}</b></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => removeProduct()}>Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>

            <section className="header">
                <div>
                    <h3 className="section-title">Détails</h3>
                    <Link className="link-nav mb-3 d-md-none" to={'/piece'}>{'< Retour'}</Link>
                </div>
                <div>
                    {PrivateComponent(
                        <Link to={"/piece/" + product?.ProductID + "/activity"} className="btn btn-outline me-3" title="activité">
                            <i className='bi bi-clock me-1'></i> Activité
                        </Link>
                    )}
                    {PrivateLinkUpdate("/piece/" + product?.ProductID + "/update", GROUP.admin, PERMISSION.product)}
                    {PrivateComponent(
                        <button className="btn btn-delete ms-3" title="Supprimer" data-bs-toggle="modal" data-bs-target="#removeModal">
                            <i className="bi bi-trash3 me-lg-1"></i> <span className='d-none d-lg-inline'>Supprimer</span>
                        </button>,
                        GROUP.admin,
                        PERMISSION.product
                    )}
                </div>
            </section>

            <section className="body">
                <article className="card mb-3">

                    <div className="row">
                        <section className="col-4">
                            <img className="img-product rounded" src={product?.ProductImage ? "../media/images/product/" + product?.ProductImage : DEFAULT_IMG} />
                        </section>

                        <section className="col-8">
                            <h6 className="mb-1">{product?.ProductModel}</h6>
                            <p className="mb-1">{product?.ProductBrand} {product?.ProductBrand && product?.ProductReference && " - "} {product?.ProductReference}</p>
                        </section>
                    </div>
                </article>

                {product?.Price && PrivateComponent(
                    <>
                        <h3 className="section-title-2">Prix</h3>

                        <article className="card">

                            <section className="card-body">
                                <div className='row'>

                                    <div className="col-4">
                                        <p className="text-muted mb-1">Fournisseur</p>
                                        <p className="mb-0">{product?.ProductSupplier}</p>
                                    </div>

                                    <div className="col">
                                        <p className="text-muted mb-1">Facial</p>
                                        <p className="mb-0">{product?.Price}</p>
                                    </div>

                                    <div className="col">
                                        <p className="text-muted mb-1">Achat</p>
                                        <p className="mb-0">{product?.PriceBuying}</p>
                                    </div>

                                    <div className="col">
                                        <p className="text-muted mb-1">Vente</p>
                                        <p className="mb-0">{product?.PriceSelling}</p>
                                    </div>
                                </div>
                            </section>
                        </article>
                    </>
                    ,
                    GROUP.crud_sav,
                    ["PriceRead"]
                )}

                {PrivateComponent(
                    <Link to="material" className="link-nav mb-3">Afficher le matériel correspondant {'>'}</Link>,
                    GROUP.dev
                )}

                <div className="d-flex justify-content-between align-items-start mt-3">
                    <h3 className="section-title-2 mb-0">Stock</h3>
                    {PrivateComponent(
                        <button className="btn btn-outline bg-emphasis" onClick={() => Assignment()}>
                            <i className="bi bi-plus-lg me-1"></i> Nouvelle affectation
                        </button>,
                        GROUP.dev)
                    }
                </div>

                <div className="d-flex justify-content-between mb-3">
                    <div className="d-inline">
                        <p className="d-inline text-muted">Emplacement{(productStorages?.length ?? 0) > 1 && "s"}: </p>
                        <p className="d-inline me-3">{(productStorages?.length ?? 0) + (productVehicles?.length ?? 0)}</p>
                        <p className="d-inline text-muted">Total: </p>
                        <p className="d-inline">{product?.Quantity}</p>
                    </div>
                </div>

                {productStorages && productStorages.map(storage => {
                    return <div className="card" key={storage.StorageID}>
                        <div className="card-header pb-1">
                            <div className="d-inline-flex align-items-center">
                                <i className="bi bi-boxes me-2" style={{ fontSize: "1.5rem" }}></i>
                                <h5 className="card-title mb-0"> {storage?.WarehouseName}</h5>
                            </div>
                            <div className="d-inline-flex align-items-center">
                                {parseInt(storage.Quantity ?? "0") < parseInt(storage.QuantityMin ?? "0") ?
                                    <p className="d-inline mb-0 badge rounded-pill bg-danger me-2">
                                        <small>Insuffisant</small>
                                    </p>
                                    : ""}
                                <h3>{storage?.Quantity}</h3>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <p className="text-muted mb-0 me-2">Emplacement:</p>
                                    <Link to={"/warehouse/" + storage.WarehouseID + "/storage/" + storage.StorageID} className="link-nav">{storage?.Rack}{storage?.Row}-{storage?.Col}{storage?.Compartment ? "-" + storage?.Compartment : ""}</Link>
                                </div>
                                <div className="d-flex">
                                    <p className="text-muted mb-0 me-2">Min:</p>
                                    <p className="mb-0 me-3">{storage?.QuantityMin}</p>

                                    <p className="text-muted mb-0 me-2">Max:</p>
                                    <p className="mb-0">{storage?.QuantityMax}</p>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer d-flex align-items-start justify-content-between">
                            {PrivateComponent(
                                <button className="btn link-nav" onClick={() => AdjustContent()}>
                                    <i className="bi bi-sliders me-2"></i> Ajuster quantité
                                </button>,
                                GROUP.dev
                            )}
                            {PrivateComponent(
                                productStorages.length + (productVehicles ? productVehicles.length : 0) > 1 ?
                                    <button className="btn link-nav" onClick={() => Transfer(storage)}>
                                        <i className="bi bi-arrow-left-right me-1"></i> Transférer
                                    </button>
                                    : <></>,
                                GROUP.dev
                            )}
                        </div>
                    </div>
                })}


                {productVehicles && productVehicles.map(vehicle => {
                    return <div className="card" key={vehicle.VehicleID}>
                        <div className="card-header pb-1">
                            <div className="d-inline-flex align-items-center">
                                <i className="bi bi-car-front me-2" style={{ fontSize: "1.5rem" }}></i>
                                <h5 className="card-title mb-0">Vehicule N°{vehicle?.VehicleNumber}</h5>
                            </div>

                            <div className="d-inline-flex align-items-center">
                                {parseInt(vehicle.Quantity) < parseInt(vehicle.QuantityMin) ?
                                    <p className="d-inline mb-0 badge rounded-pill bg-danger me-2"><small>Insuffisant</small></p>
                                    : ""}
                                <h3 className="d-inline mb-0">{vehicle?.Quantity}</h3>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <p className="text-muted d-block mb-0">{vehicle?.FirstName} {vehicle?.Name}</p>
                                <div className="d-flex">
                                    <p className="text-muted mb-0 me-2">Min:</p>
                                    <p className="mb-0 me-3">{vehicle?.QuantityMin}</p>

                                    <p className="text-muted mb-0 me-2">Max:</p>
                                    <p className="mb-0">{vehicle?.QuantityMax}</p>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer d-flex align-items-start justify-content-between">
                            {PrivateComponent(
                                <button className="btn link-nav" onClick={() => AdjustContent()}>
                                    <i className="bi bi-sliders me-2"></i>Ajuster quantité
                                </button>,
                                GROUP.dev
                            )}

                            {PrivateComponent(
                                productVehicles.length + (productStorages ? productStorages.length : 0) > 1 ?
                                    <button className="btn link-nav" onClick={() => Transfer(vehicle)}>
                                        <i className="bi bi-arrow-left-right me-2"></i>Transférer
                                    </button>
                                    : <></>,
                                GROUP.dev
                            )}
                        </div>
                    </div>
                })}

            </section>
        </section>

    } else if (transfering) {

        return <section className="body-container">

            <section className="header">
                <div>
                    <button className="btn link-nav mb-1" onClick={() => setTransfering(false)}>{'< Retour'}</button>
                    <h3 className="section-title">Transférer</h3>
                </div>
            </section>

            <section className="body">
                <h4 className="section-title-2">Depuis</h4>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">Lieux</h5>
                    </div>

                    <div className="card-body">

                    </div>
                </div>

                <h4 className="section-title-2">Vers</h4>

                <div className="card">

                    <select className="form-select" name="" id="">
                        {productStorages && productStorages.map(storage => <option key={storage.StorageID} value={storage.StorageID ?? ""}>{storage.WarehouseName}</option>)}

                        {productVehicles && productVehicles.map(vehicle => <option key={vehicle.VehicleID} value={vehicle.VehicleID}>Véhicule N°{vehicle.VehicleNumber} | {vehicle?.FirstName} {vehicle?.Name}</option>)}
                    </select>

                </div>
            </section>

        </section >

    } else {
        return <></>
    }
}


function ProductTransfer({ setTransfering }) {
    const [storages, setStorages] = useState<Array<IStorage>>([])
    const [rack, setRack] = useState<Array<string>>([])
    const [row, setRow] = useState<Array<string>>([])
    const [col, setCol] = useState<Array<string>>([])

    const [vehicles, setVehicles] = useState<Array<IVehicle>>([])
    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>([])
    const [location, setLocation] = useState<string>("warehouse")


    useEffect(() => {
        reqGet("vehicle").then(res => { setVehicles(res.filter(v => v.UserID)) })
        reqGet("warehouse").then(res => { setWarehouses(res) })
        reqGet('storage/rack').then(result => {
            if (result) {
                setRack(result)
                reqGet('storage/row/' + result[0]).then(res => {
                    if (res) {
                        setRow(res)
                        reqGet('storage/col/' + result[0] + '/row/' + res[0]).then(r => setCol(r))
                    }
                })
            }
        })


    }, [])

    return <section className="body-container">

        <section className="header">
            <div>
                <button className="btn link-nav mb-1" onClick={() => setTransfering(false)}>{'< Retour'}</button>
                <h3 className="section-title">Transférer</h3>
            </div>
        </section>

        <section className="body">
            <h4 className="section-title-2">Depuis</h4>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Lieux</h5>
                </div>

                <div className="card-body">

                </div>
            </div>

            <h4 className="section-title-2">Vers</h4>

            <div className="card">

                <div className="btn-group btn-group-nav mb-3" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked onClick={() => setLocation("warehouse")} />
                    <label className="btn btn-outline-primary" htmlFor="btnradio1">Stockage</label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" onClick={() => setLocation("vehicle")} />
                    <label className="btn btn-outline-primary" htmlFor="btnradio2">Véhicle</label>
                </div>

                <select className="form-select mb-3" name="" id="">
                    {(location === "warehouse") && warehouses.map(warehouse => <option key={warehouse.WarehouseID} value={warehouse.WarehouseID}>{warehouse.WarehouseName}</option>)}
                    {(location === "vehicle") && vehicles.map(vehicle => <option key={vehicle.VehicleID} value={vehicle.VehicleID}>Véchile N°{vehicle.VehicleNumber} | {vehicle?.FirstName} {vehicle?.Name}</option>)}
                </select>



                {(location === "warehouse") && <div className="row row-cols-3">

                    <div className="col">
                        <label className="form-label" htmlFor="">Etagère</label>
                        <select className="form-select" name="" id="">
                            {rack && rack.map((r, k) => <option key={k} value={r}>{r}</option>)}
                        </select>
                    </div>

                    <div className="col">
                        <label className="form-label" htmlFor="">Rangée</label>
                        <select className="form-select" name="" id="">
                            {row && row.map((r, k) => <option key={k} value={r}>{r}</option>)}
                        </select>
                    </div>

                    <div className="col">
                        <label className="form-label" htmlFor="">Colonne</label>
                        <select className="form-select" name="" id="">
                            {col && col.map((c, k) => <option key={k} value={c}>{c}</option>)}
                        </select>
                    </div>

                </div>}
            </div>
        </section>
    </section >
}