import React from 'react';
import { StyleSheet } from '@react-pdf/renderer';

//const SERVER = "http://127.0.0.1:8080/edsa-multitherme-api/"
const SERVER = "https://multitherme-app.ch/api/"

export const DB_SERVER: string = SERVER;
export const DB_LOGIN: string = SERVER + "login.php"
export const REQUEST_SERVER: string = SERVER + "request.php/";

export const LAYOUT = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
}

// 8 Gb
export const DOCUMENT_SIZE_LIMIT = 8388608

export const DEFAULT_IMG = __dirname + "media/images/placeholder.png"
export const LOGO = __dirname + "media/images/multitherme/mt_logo.jpg"

export const IMAGE_PATH = {
    USERS: __dirname + "media/images/users/"
}

export const GROUP = {
    dev: ["Developpeur"],
    admin: ["Admin", "Developpeur", "Gestionnaire"],

    rh: ["Admin", "Developpeur", "Ressource humaine"],
    tec_hydrau: ["Admin", "Developpeur", "Technicien hydraulique"],

    technicien_strict: ["Admin", "Developpeur", "Technicien"],

    crud_address: ["Admin", "Developpeur", "Assistant technique", "Comptabilite", "Responsable chantier"],
    crud_sav: ["Admin", "Developpeur", "Gestionnaire", "Responsable chantier", "Ressource humaine", "Comptabilite", "Assistant technique"],
    crud_tec: ["Admin", "Developpeur", "Technicien", "Technicien hydraulique", "Monteur"],
    crud_management: ["Admin", "Developpeur", "Assistant technique"],
    crud_survey: ["Admin", "Developpeur", "Surveillant"]
}

export const PERMISSION = {
    address: ["AddressRead", "AddressCreate", "AddressUpdate"],
    management: ["ManagementRead", "ManagementCreate", "ManagementUpdate"],
    vehicleCheck: ["VehicleRead", "VechicleCheckCreate"],
    material: ["MaterialRead", "MaterialCreate", "MaterialUpdate"],
    product: ["ProductRead", "ProductCreate", "ProductUpdate", "ProductDelete"],
    storage: ["StorageRead", "StorageCreate", "StorageUpdate", "StorageDelete"]
}

export const MONTH_FR = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
export const MONTH = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export const ICONS = {
    ACCOUNT: "bi bi-person",
    ADD: "bi bi-plus-lg",
    ADDRESS: "bi bi-geo",
    ACTIVITY: "bi bi-activity",
    ARCHIVE: "bi bi-archive",
    AVS: "bi bi-shield-check",
    BIRTHDAY: "bi bi-cake",
    CALENDAR: "bi bi-calendar3",
    CANCEL: "bi bi-x-lg",
    CIVILSTATUS: "bi bi-person-heart",
    CLIENTS: "bi bi-person-vcard",
    CONSTRUCTION: "bi bi-cone-striped",
    CONTRACT: "bi bi-file-earmark-medical",
    COUNTER: "bi bi-bezier2",
    DANGER: "bi bi-x-octagon",
    DATABASE: "bi bi-database",
    DOCUMENT: "bi bi-file-earmark-pdf",
    DONE: "bi bi-check-circle",
    DELETE: "bi bi-trash3",
    ELECTRIC: "bi bi-lightning",
    EMAIL: "bi bi-at",
    EMAIL_SEND: "bi bi-envelope-arrow-up",
    EXCHANGER: "bi bi-shuffle",
    EXCEL: "bi bi-file-earmark-spreadsheet",
    EXECUTION: "bi bi-gear-wide-connected",
    EXPORT: "bi bi-box-arrow-up",
    FILE: "bi bi-file-earmark",
    FUEL: "bi bi-droplet-fill",
    GAZ: "bi bi-fire",
    HEAT: "bi bi-thermometer-sun",
    HEATING: "bi bi-thermometer-half",
    HELP: "bi bi-question-circle",
    HOLIDAY: "bi bi-suitcase",
    IMAGE: "bi bi-image",
    INFO: "bi bi-info-square",
    INSTALLATION: "bi bi-house-gear",
    INTERVENTION: "bi bi-file-earmark-check",
    INVENTORY_PICKING: "bi bi-ui-checks",
    INVOICE: "bi bi-receipt",
    KM: "bi bi-fuel-pump",
    LEAVE: "bi bi-clock",
    LOGOUT: "bi bi-box-arrow-left",
    LUNCH: "bi bi-egg-fried",
    MANAGER: "bi bi-person-check",
    MAP: "bi bi-pin-map",
    MAINTENANCE: "bi bi-screwdriver",
    MANAGEMENT: "bi bi-bank",
    MATERIALS: "bi bi-box-seam",
    MAZOUT: "bi bi-droplet-fill",
    MERGE: "bi bi-intersect",
    MISSION: "bi bi-calendar2-week",
    MORE: "bi bi-file-earmark-text",
    NATIONALITY: "bi bi-globe-europe-africa",
    NOTE: "bi bi-journal-text",
    NOTIFICATION: "bi bi-bell",
    OPTIONS: "bi bi-three-dots",
    ORDER: "bi bi-cart",
    OWNER: "bi bi-person-badge",
    PASSWORD_UPDATE: "bi bi-shield-lock",
    PDF: "bi bi-file-earmark-pdf",
    PELLETS: "bi bi-fire",
    PHONE: "bi bi-telephone",
    PHONE_CALL: "bi bi-telephone-forward",
    PICKET: "bi bi-telephone-inbound",
    PIECE: "bi bi-nut",
    PERMISSION: "bi bi-key",
    POWERPOINT: "bi bi-file-earmark-easel",
    PRIMARY: "bi bi-bell-fill",
    PRINT: "bi bi-printer",
    PUTBACK: "bi bi-box-arrow-in-down",
    QRCODE: "bi bi-qr-code",
    QUOTATION: "bi bi-file-binary",
    RESUPPLY: "bi bi-box-seam",
    SERVICES: "bi bi-shield-check",
    SETTINGS: "bi bi-gear",
    SHOW: "bi bi-eye",
    SOLAR: "bi bi-sun",
    STATEMENT: "bi bi-file-earmark-text",
    STORAGE: "bi bi-grid-3x3-gap",
    SUCCESS: "bi bi-check-lg",
    SURVEY: "bi bi-eye",
    TAKEOUT: "bi bi-box-arrow-up",
    TANK: "bi bi-fuel-pump",
    TASK: "bi bi-list-task",
    TENANT: "bi bi-key",
    TERMINATE: "bi bi-file-earmark-break",
    TEST: "bi bi-code-slash",
    TIMESHEET: "bi bi-stopwatch",
    TOOL: "bi bi-wrench",
    TROUBLESHOOTING: "bi bi-wrench-adjustable",
    UPDATE: "bi bi-pencil",
    UI: "bi bi-layout-wtf",
    USERS: "bi bi-people",
    VALIDATE: "bi bi-check-lg",
    VEHICLES: "bi bi-truck",
    VEHICLES_GUARANTOR: "bi bi-patch-check",
    WAITING: "bi bi-clock",
    WAREHOUSE: "bi bi-boxes",
    WARNING: "bi bi-exclamation-triangle",
    WATER: "bi bi-droplet",
    WORD: "bi bi-file-earmark-word",
    WORK: "bi bi-briefcase",
    WORKBOOK: "bi bi-journal-text",
    ZONES: "bi bi-geo-alt"
}

export const BADGE = {
    ADMIN: <span className="badge bg-primary ms-3" title="Administrateur uniquement">admin</span>,
    BETA: <span className="badge bg-primary ms-3" title="Beta">beta</span>,
    DEV: <span className="badge bg-emphasis ms-3" title="En développement">dev</span>
}

// INSTALLATION
export const INSTALLATION_STATUS = {
    GOOD: "Bon",
    MEDIUM: "Moyen",
    BAD: "Mauvais"
}


// MATERIAL
export const MATERIAL_CLASS_ID = {
    BOILER: 1,
    BURNER: 2,
    TANK: 3,
    EXCHANGER: 4,
    GENERATOR: 5,
    PUMP: 6,
    REGULATION: 7,
    SOLAR: 8,
    EXPANSION: 9,
    VENTILATION: 10,
}


// PRODUCT
export const PRODUCT_CLASS_ID = {
    CONSUMABLE: 1,
    REUSABLE: 2
}

export const PRODUCT_TYPE = {
    PIECE: "Piece",
    TOOL: "Outil",
    EXCHANGER: "Echangeur"
}

export const PRODUCT_AVAILABLE = {
    OUT: 0,
    IN_STOCK: 1,
    REPAIRING: 2
}

export const PRODUCT_CONDITION = {
    NEVER_USED: "Non utilisé",
    NEW: "Neuf",
    GOOD: "Bon",
    MAINTENANCE: "Maintenance",
    OUT_OF_ORDER: "Hors service"
}

export const SERVICE = {
    CLEANING: "Décrassage",
    COUNTERS: "Compteurs",
    HEATING: "Arrêt/MER chauffage",
    IDC: "IDC",
    MAINTEANANCE: "Entretien",
    OPTIMISATION: "Optimisation",
    PURGE: "Purge",
    SCALING: "Détartrage",
    SUBCONTRACTOR: "Sous-traitant",
    SURVEY: "Surveillance",
    WATER: "Analyse eau"
}


// TIMESHEET
export const TIMESHEET = {
    EXTRA_HOURS: 15,
    LATE: 5,
    MIDDAY_MIN: 45,
    ROUND: 15
}

export const TIMESHEET_TYPE = {
    TIMESHEET: "Timesheet",
    PICKET: "Piquet"
}

// HOLIDAY
export const HOLIDAY_TYPE = {
    ACCIDENT: "Accident",
    OTHER: "Autre",
    MATERNITY: "Congé matérnité",
    PATERNITY: "Congé patérnité",
    DEATH: "Décès",
    MOVE: "Déménagement",
    CHILD: "Enfant",
    ILLNESS: "Maladie",
    WEDDING: "Mariage",
    OBLIGATION: "Obligation légale",
    UNPAID: "Sans solde",
    VACATION: "Vacances"
}
export const HOLIDAY_STATUS = {
    WAITING: "En attente",
    ACCEPTED: "Accepté",
    REJECTED: "Rejeté"
}


// COUNTER
export const COUNTER_TYPE = {
    ELECTRIC: "Electrique",
    FUEL: "Mazout",
    GAZ: "Gaz",
    HEAT: "Chaleur",
    PELLETS: "Pellets",
    SOLAR: "Solaire",
    TANK: "Citerne",
    WATER_COLD: "Eau froide",
    WATER_WARM: "Eau chaude"
}


// PDF

export const PDF_STYLE = StyleSheet.create({
    // LAYOUT
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    page: {
        paddingTop: 30,
        paddingBottom: 65,
        paddingHorizontal: 30,
    },

    // TYPOGRAPHY
    title: {
        fontSize: "14pt",
        fontFamily: 'Helvetica-Bold',
        marginBottom: "3pt"
    },
    title2: {
        fontSize: "12pt",
        fontFamily: 'Helvetica-Bold',
        marginBottom: "3pt"
    },
    title3: {
        fontSize: "10pt",
        fontFamily: 'Helvetica-Bold',
        marginBottom: "3pt"
    },
    subtitle: {
        fontSize: "12pt",
        fontFamily: 'Helvetica',
        marginBottom: "50pt"
    },
    text: {
        fontSize: "10pt",
        textAlign: 'justify',
        fontFamily: 'Helvetica',
        marginBottom: "3pt"
    },
    bold: {
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold'
    },

    // UTILITIES
    mb0: {
        marginBottom: 0
    },
    mb1: {
        marginBottom: "7pt"
    },
    mb2: {
        marginBottom: "14pt"
    },
    mb3: {
        marginBottom: "21pt"
    },

    // TABLE
    table: {
        width: '100%',
        border: 0,
        fontSize: "10pt"
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        border: 0
    },
    col: {
        border: 0,
        paddingTop: "1px",
        paddingBottom: "1px"
    },
    th: {
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold'
    },

    // OTHER
    logo: {
        width: "80 pt"
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey'
    },
})