import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import $ from 'jquery'

import { getToken } from '../../../../useToken'
import { ServiceBadge, dateFormat, PrivateComponent, PrivateLinkAdd, Header } from "../../../common/common";
import { IAddress, IAddressService, IHeating, IService } from "../../../common/types";
import { reqDelete, reqGet } from "../../../../service/apiRequest";
import { GROUP, ICONS, SERVICE } from "../../../common/constants";

export default function AddressServiceContent({ reload, setReload }) {
    const location = useLocation()
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idAddress = params.idAddress ?? ""

    const [address, setAddress] = useState<IAddress>()
    const [addressServices, setAddressServices] = useState<Array<IAddressService>>([])

    useEffect(() => {
        reqGet("address/" + idAddress).then(res => setAddress(res))
        reqGet("addressservice/address/" + idAddress).then(res => setAddressServices(res))
    }, [idAddress])

    useEffect(() => {
        if (reload)
            reqGet("addressservice/address/" + idAddress).then(res => setAddressServices(res))
    }, [reload])

    function RemoveService(s: IAddressService) {

        reqDelete("addressservice/" + s.AddressServiceID,
            {
                data: {
                    ActivityUserID: token.UserID,
                    Service: s.Service,
                    AddressLine: address?.AddressLine,
                    AddressZipCode: address?.ZipCode,
                    City: address?.City
                }
            }).then(res => {
                setReload(true)
            })
    }

    const [linkBack, setLinkBack] = useState<string>("")

    useEffect(() => {
        LinkBack()
    }, [location.pathname])

    function LinkBack() {
        if (location.state?.path)
            setLinkBack(location.state.path)
        else
            setLinkBack("/address/" + idAddress)

    }


    return <div className="body-container content">

        <header className="header">
            <div>
                <h2 className="section-title">Prestations</h2>
                <Link to={linkBack} className="link-nav">{"<"} Retour</Link>
            </div>

            {PrivateLinkAdd("add", GROUP.crud_sav, ["AddressServiceAdd"])}
        </header>


        <section className="body">

            <div className="list " id="list">

                {addressServices?.map((s, k) => {

                    return <div className="list-item py-2 mb-2" key={k}>

                        <div className="modal" id={"removeService" + s.AddressServiceID} tabIndex={-1} aria-labelledby={"removeService" + s.AddressServiceID} aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <header className="modal-header">
                                        <h1 className="modal-title fs-5" id={"removeService" + s.AddressServiceID}>Supprimer prestation</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </header>

                                    <div className="modal-body">
                                        <p>Êtes-vous  sur de vouloir supprimer :</p>
                                        <p><b>{s.Service}</b></p>
                                    </div>

                                    <footer className="modal-footer">
                                        <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                                            <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                                        </button>

                                        <button type="button" className={"btn btn-delete"} data-bs-dismiss="modal" onClick={() => RemoveService(s)}>
                                            <i className={ICONS.DELETE + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Supprimer</span>
                                        </button>
                                    </footer>
                                </div>
                            </div>
                        </div>
                        <section className="list-item-data">
                            <div className="container-fluid px-0">
                                <div className="row" style={{ minHeight: "3rem" }}>
                                    <div className="col-12 col-sm-4 col-lg-3 col-xxl-2 mb-3 mb-lg-0 order-1">
                                        <ServiceBadge service={s.Service} />
                                    </div>

                                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 pe-0 order-2">
                                        <h6 className="mb-1">Date de contrat</h6>
                                        <p className="mb-0">{dateFormat(s.AddressServiceDate, "dd/MM/yyyy")}</p>
                                    </div>

                                    {s.Service != SERVICE.HEATING &&
                                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 order-3">
                                            <h6 className="tmb-1">Périodicité</h6>
                                            <p className="mb-0">{s.AddressServicePeriodicity}</p>
                                        </div>
                                    }

                                    {s.InstallationID &&
                                        <div className="col-12 col-sm-8 col-lg-6 col-xxl-4 mt-3 mt-xxl-0 order-4 order-lg-5">
                                            <h6 className="mb-1">Installation</h6>
                                            <p className="text-truncate mb-0">{s.Type || s.IType || s.Class || s.IClass}<span>, {s.Model || s.IModel}</span></p>
                                        </div>}

                                    {s.Service == SERVICE.SUBCONTRACTOR &&
                                        <div className="col-12 col-sm-4 col-lg-3 col-xxl-2 mt-3 mt-lg-0 order-5 order-lg-4">
                                            <h6 className="mb-1">Sous-traitant</h6>
                                            <p className="text-truncate mb-0">{s.Company}</p>

                                        </div>
                                    }

                                    {/* Heating settings */}

                                    {/* s.Service == SERVICE.HEATING &&
                                    <ServiceHeating idAddress={parseInt(idAddress)} />
                                        */}

                                </div>
                            </div>

                            {s.AddressServiceNote &&
                                <div className="mt-xl-3 mt-3">
                                    <h6 className="mb-1">Information</h6>
                                    <p className="mb-0">{s.AddressServiceNote}</p>
                                </div>}
                        </section>


                        <footer className="list-item-more align-items-start align-items-xl-center">
                            <div className="dropdown">
                                <button className="btn btn-sm btn-outline border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to={s.AddressServiceID + "/update"} className="dropdown-item px-2 py-1 mb-2"><i className={ICONS.UPDATE + " me-2"}></i>Modifier</Link>
                                    </li>
                                    <li>
                                        <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target={"#removeService" + s.AddressServiceID}>
                                            <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </footer>

                    </div>

                })}

            </div>

        </section>

    </div>
}
