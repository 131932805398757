import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import $ from 'jquery'

import { Header, ButtonSubmit, TimeRound, dateFormat } from '../../../../common/common'
import { ITimesheet, IUser } from "../../../../common/types";
import { reqGet, reqPut } from "../../../../../service/apiRequest";
import { getToken } from "../../../../../useToken";
import { TIMESHEET } from "../../../../common/constants";

export default function AccountTimesheetKmUpdate({ setReload }) {
    const params = useParams()
    const idTimesheet = params.idTimesheet
    const navigate = useNavigate()
    const token = getToken()
    const location = useLocation()

    const [timesheet, setTimesheet] = useState<ITimesheet>()

    useEffect(() => {
        reqGet("timesheet/" + idTimesheet).then(res => {
            // if timesheet is validate can't modify
            if (res.TimesheetValidate == 1)
                navigate(-1)

            setTimesheet(res)
            $("#TimesheetKm").val(res.TimesheetKm)
        })
    }, [idTimesheet])


    const onSubmit = () => {
        var data = {
            ActivityUserID: token.UserID,
            TimesheetID: idTimesheet,
            TimesheetKm: parseInt($("#TimesheetKm").val()) || null,
            TimesheetDate: dateFormat(timesheet, "dd/MM/yyyy")
        }

        reqPut('timesheet/' + idTimesheet + "/km", data).then((res) => {
            setReload(true)

            if (location?.state?.path)
                navigate(location.state.path)
            else
                navigate("/account/timesheet")
        })
    };

    const LinkBack = () => {

        if (location?.state?.path)
            return <Link to={location.state.path} className="link-nav">{"<"} Retour</Link>
        else
            return <Link to="/account/timesheet" className="link-nav">{"<"} Retour</Link>
    }

    return <div className="body-container content">
        <header className="header">
            <div>
                <h2 className="section-title">Modifier les km de la feuille d'heure</h2>
                <LinkBack />
            </div>
        </header>

        <section className="body">

            <div className="w-50 mb-3">
                <label className="form-label">Km (remboursable)</label>

                <div className="input-group">
                    <input type="text" pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]" id="TimesheetKm" className="form-control" />
                </div>

            </div>


            <div className="text-end">
                <p><small>Les champs avec une * sont obligatoire</small></p>
                <ButtonSubmit onClick={onSubmit} />
            </div>
        </section>
    </div>
}