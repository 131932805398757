import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { IActivity } from "../../../common/types";
import { ColumnDef, ColumnFiltersState, flexRender, getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { DebouncedInput, Filter, dateFormat, fuzzyFilter, fuzzySort } from "../../../common/common";
import { reqGet } from "../../../../service/apiRequest";
import { ResolveStatus } from "../../activity/activity";

export default function AddressActivity() {
    const location = useLocation()
    const params = useParams()
    const idAddress = params.idAddress

    const LinkBack = () => {
        if (location?.state?.back)
            return location.state.back
        else
            return "/address/" + idAddress
    }

    const [data, setData] = useState<Array<IActivity>>([])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')

    const columns = useMemo<ColumnDef<IActivity, any>[]>(
        () => [
            {
                accessorFn: row => row.Status,
                id: 'Status',
                header: 'Etat',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.DateTime,
                id: 'DateTime',
                header: 'Date',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.FirstName + ' ' + row.Name,
                id: 'User',
                header: 'Utilisateur',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Action,
                id: 'Action',
                header: 'Catégorie',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Description,
                id: 'Description',
                header: 'Description',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,

        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useLayoutEffect(() => {
        table.setPageSize(250)

        reqGet("activity/address/" + idAddress).then((res) => { setData(res); })

    }, [])


    return <main className="body-container content">
        <header className="row mb-3">
            <div className="col-6 col-sm-3 order-1">
                <h2 className="section-title mb-0">Activité</h2>
                <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
            </div>

            <div className="col-sm-6 col-lg-4 offset-lg-1 order-3 order-sm-2">
                <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche" />
            </div>
        </header>

        <nav className="nav-horizontal">
            <div className="row" style={{ minWidth: "500px" }}>

                {table.getHeaderGroups().map(headerGroup =>
                    <React.Fragment key={headerGroup.id}>

                        {headerGroup.headers.map((header, k) => {
                            return (
                                <div className='col' key={k}>

                                    {!header.isPlaceholder && (
                                        <>

                                            <label className="form-label mb-1" style={{ cursor: "pointer" }} {...{ onClick: header.column.getToggleSortingHandler() }}>

                                                {flexRender(header.column.columnDef.header, header.getContext())}

                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[header.column.getIsSorted() as string] ?? null}

                                            </label>

                                            {header.column.getCanFilter() ? (
                                                <div className="d-flex flex-direction-column">
                                                    <Filter column={header.column} table={table} />
                                                </div>
                                            ) : null}

                                        </>
                                    )}

                                </div>
                            )
                        })}

                    </React.Fragment>
                )}
            </div>
        </nav>


        <section className="body">

            <div className='list'>

                {table.getRowModel().rows.map(row => {
                    const activity: IActivity = row.original
                    const status = ResolveStatus(activity.Status)
                    //const action = ResolveAction(activity.Action)
                    //const link = ResolveActivtiyLink(activity)

                    return <div className="list-item">

                        <header className="list-item-start h-100" style={{ width: "1.75rem" }}>
                            {status}
                        </header>


                        <section className="list-item-data">

                            <div className="row">

                                <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                    <h6 className="mb-0">{activity.FirstName} {activity.Name}</h6>
                                    <p className="mb-0">{dateFormat(activity.DateTime, "dd/MM/yyyy, hh:mm")}</p>
                                </div>

                                <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-start align-items-center">
                                    <p className="mb-0">{activity.Action}</p>
                                </div>

                                <div className="d-none d-sm-flex col-sm-4 col-md-6 col-lg-8 align-items-center">
                                    <p className="text-truncate-2 mb-0">{activity.Description}</p>
                                </div>

                            </div>

                        </section>


                        <footer className="list-item-more h-100" style={{ width: "1.75rem" }}>
                            <div className="dropdown">

                                <button className="btn btn-unstyled" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-three-dots-vertical" style={{ fontSize: "1.2rem" }}></i>
                                </button>

                                <ul className="dropdown-menu p-0">
                                    {/*link &&
                                        <Link className="dropdown-item" to={link} state={{ path: location.pathname }}>Aller à la page</Link>
                                    */}

                                    <Link className="dropdown-item" to={activity.ActivityID.toString()}>Voir les détails</Link>
                                </ul>

                            </div>
                        </footer>

                    </div>
                })
                }
            </div>

        </section>



        <footer className="footer container-fluid">
            <div className="row pagination">

                <div className="col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start mb-3 mb-lg-0">

                    <button className="btn btn-outline me-2" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                        {'<<'}
                    </button>
                    <button className="btn btn-outline" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                        {'<'}
                    </button>

                    <p className="mb-0 mx-3 text-nowrap">
                        {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                    </p>

                    <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                        {'>'}
                    </button>
                    <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                        {'>>'}
                    </button>
                </div>

                <div className="col-7 col-sm-3 d-flex align-items-center mb-3 mb-lg-0 justify-content-md-end">
                    <p className="text-muted mb-0 me-3 text-nowrap">Page</p>

                    <input type="number" className="form-control w-50" defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                        }}
                    />
                </div>

                <div className="col-5 col-sm-4 mb-3 mb-lg-0">
                    <select className="form-select" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                        {[100, 250, 500].map(pageSize => (

                            <option key={pageSize} value={pageSize}>
                                Montrer {pageSize}
                            </option>

                        ))}

                    </select>
                </div>

            </div>
        </footer>
    </main>
}