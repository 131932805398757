import React, { useState, useEffect } from 'react'
import { NavLink, Link, useParams, useNavigate } from 'react-router-dom'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { reqGet, reqPut } from '../../../service/apiRequest'
import { getToken } from '../../../useToken'
import { ButtonSubmit, Header } from '../../common/common'
import { IUserRole, IUser } from '../../common/types'
import { post } from "../../../service/request"
import { ICONS } from '../../common/constants';

const validationSchema = yup.object({
    Username: yup.string().required("Ce champ est obligatoire"),
    FirstName: yup.string().required("Ce champ est obligatoire"),
    Name: yup.string().required("Ce champ est obligatoire"),
    Email: yup.string().email().nullable(),
    Phone: yup.string().nullable(),
    UserRoleID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    UserManagerID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    UserBirthday: yup.string().nullable(),
    UserDateContract: yup.string().nullable(),
    UserFrontier: yup.string().nullable(),
    UserCivilStatus: yup.string().nullable(),
    UserAddress: yup.string().nullable(),
    UserNationality: yup.string().nullable(),
    UserAVS: yup.string().nullable()
})

const initialValues = {
    Username: '',
    Email: '',
    FirstName: '',
    Name: '',
    Phone: '',
    UserManagerID: null,
    UserBirthday: null,
    UserDateContract: null,
    UserFrontier: null,
    UserCivilStatus: null,
    UserAddress: null,
    UserNationality: null,
    UserAVS: null
}


export default function UserUpdate({ setReload }) {
    const navigate = useNavigate()
    const params = useParams()
    const idUser = params.idUser

    const token = getToken()

    const { register, formState: { errors }, handleSubmit, getValues, setValue, watch } = useForm<IUser>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [userRole, setUserRole] = useState([])
    const [user, setUser] = useState<IUser>()
    const [managers, setManagers] = useState<Array<IUser>>([])

    const [checkUsername, setCheckUsername] = useState<boolean | null>(null)

    useEffect(() => {

        let Promise1 = reqGet('userrole')
        let Promise2 = reqGet('user/' + idUser)
        let Promise3 = reqGet('user')

        Promise.all([Promise1, Promise2, Promise3]).then(values => {
            setUser(values[1])

            const roles = values[0]

            if (token.Role === "Developpeur" || token.Role === "Admin")
                setUserRole(roles)
            else
                setUserRole(roles.filter(ur => ur.Role !== "Developpeur" && ur.Role !== "Admin"))

            setManagers(values[2].filter(u => u.Role == "Admin"))
        })

    }, [])

    useEffect(() => {
        if (user && userRole && managers) {
            setValue("Username", user.Username)
            setValue("Email", user.Email)
            setValue("FirstName", user.FirstName)
            setValue("Name", user.Name)
            setValue("Phone", user.Phone)
            setValue("UserRoleID", user.UserRoleID)
            setValue("UserManagerID", user.UserManagerID)
            setValue("UserBirthday", user.UserBirthday)
            setValue("UserDateContract", user.UserDateContract)
            setValue("UserFrontier", user.UserFrontier)
            setValue("UserCivilStatus", user.UserCivilStatus)
            setValue("UserAddress", user.UserAddress)
            setValue("UserNationality", user.UserNationality)
            setValue("UserAVS", user.UserAVS)
        }
    }, [user, userRole, managers])

    const onSubmit: SubmitHandler<IUser> = data => {
        if (checkUsername != false) {

            reqPut('user/' + idUser, { ...data, ActivityUserID: token.UserID }).then((res) => {
                setReload(true)
                navigate('/users/' + idUser)
            })
        }
    }


    function CheckUsername() {
        const username = $("#Username").val()

        if (username != "") {

            if (username == user?.Username) {
                setCheckUsername(true)

            } else {

                post("checkusername", { Username: username }).then((res) => {
                    setCheckUsername(res)
                })
            }

        }
    }

    $("#Username").focusout(() => CheckUsername())

    return <section className="body-container">

        {Header(
            <h3 className="section-title">Modifier utilisateur</h3>,
            <Link to={"/users/" + idUser} className="link-nav">{'<'} Retour</Link>
        )}

        <section className="body container-fluid">

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="mb-3">

                    <label htmlFor="Username" className="form-label">Nom d'utilisateur *</label>
                    <input type="text" id="Username" className={
                        "form-control " +
                        (errors?.Username || checkUsername === false ? "is-invalid" :
                            checkUsername === true ? "is-valid" : "")
                    } {...register("Username")} />

                    {!errors.Username?.message && checkUsername && <p className="text-success">Le nom d'utilisateur est valide</p>}

                    {checkUsername === false && <p className="input-msg-error">Le nom d'utilisateur est déjà utilisé</p>}

                    {errors.Username?.message && <p className="input-msg-error">{errors.Username?.message}</p>}
                </div>


                <div className="row row-cols-2 mx-0">

                    <div className="col ps-0">

                        <div className="mb-3">
                            <label htmlFor="FirstName" className="form-label">Prénom *</label>
                            <input type="text" className={"form-control " + (errors?.FirstName ? "is-invalid" : "")} {...register("FirstName")} />

                            {errors.FirstName?.message && <p className="input-msg-error">{errors.FirstName?.message}</p>}
                        </div>

                    </div>

                    <div className="col pe-0">

                        <div className="mb-3">
                            <label htmlFor="Name" className="form-label">Nom *</label>

                            <input type="text" className={"form-control " + (errors?.Name ? "is-invalid" : "")} {...register("Name")} />

                            {errors.Name?.message && <p className="input-msg-error">{errors.Name?.message}</p>}

                        </div>

                    </div>

                </div>

                <div className="row row-cols-2 row-cols-lg-1 row-cols-xxl-2 mb-3">
                    <div className="col mb-3">
                        <label htmlFor="UserRoleID" className="form-label">Role *</label>

                        <select className={"form-select " + (errors?.UserRoleID ? "is-invalid" : "")} {...register("UserRoleID")}>

                            <option value="" hidden></option>

                            {userRole.map((u: IUserRole, k: number) => {
                                console.log(token);
                                
                                if ((u.Role == "Admin" || u.Role == "Developpeur") && (token.UserRoleID != 2 && token.UserRoleID != 1))
                                    return <></>
                                else
                                    return <option key={k} value={u.UserRoleID}>{u.Role}</option>;
                            })}

                        </select>

                        {errors.UserRoleID?.message && <p className="input-msg-error">{errors.UserRoleID?.message}</p>}
                    </div>
                    <div className="col mb-3">
                        <label htmlFor="UserManagerID" className="form-label">Responsable</label>

                        <select className={"form-select " + (errors?.UserManagerID ? "is-invalid" : "")} {...register("UserManagerID")}>

                            <option value="" hidden></option>

                            {managers.map((u: IUser, k: number) => {
                                return <option key={k} value={u.UserID}>{u.FirstName} {u.Name}</option>;
                            })}

                        </select>

                        {errors.UserManagerID?.message && <p className="input-msg-error">{errors.UserManagerID?.message}</p>}
                    </div>

                    <div className="col mb-3">
                        <label htmlFor="Email" className="form-label">Email</label>
                        <input type="text" className={"form-control " + (errors?.Email ? "is-invalid" : "")} {...register("Email")} />

                        {errors.Email?.message && <p className="input-msg-error">{errors.Email?.message}</p>}
                    </div>

                    <div className="col mb-3">
                        <label htmlFor="Phone" className="form-label">Téléphone</label>

                        <input type="text" className={"form-control " + (errors?.Phone ? "is-invalid" : "")} {...register("Phone")} />

                        {errors.Phone?.message && <p className="input-msg-error">{errors.Phone?.message}</p>}
                    </div>

                    <div className='col mb-3'>
                        <label htmlFor="UserBirthday" className="form-label">Date de naissance</label>

                        <input type="date" className={"form-control " + (errors?.UserBirthday ? "is-invalid" : "")} {...register("UserBirthday")} />

                        {errors.UserBirthday?.message && <p className="input-msg-error">{errors.UserBirthday?.message}</p>}
                    </div>

                    <div className='col mb-3'>
                        <label htmlFor="UserAddress" className="form-label">Adresse</label>

                        <input type="text" className={"form-control " + (errors?.UserAddress ? "is-invalid" : "")} {...register("UserAddress")} />

                        {errors.UserAddress?.message && <p className="input-msg-error">{errors.UserAddress?.message}</p>}
                    </div>

                    <div className='col mb-3'>
                        <label htmlFor="UserAVS" className="form-label">AVS</label>

                        <input type="text" className={"form-control " + (errors?.UserAVS ? "is-invalid" : "")} {...register("UserAVS")} />

                        {errors.UserAVS?.message && <p className="input-msg-error">{errors.UserAVS?.message}</p>}
                    </div>

                    <div className='col mb-3'>
                        <label htmlFor="UserNationality" className="form-label">Nationalité</label>

                        <input type="text" className={"form-control " + (errors?.UserNationality ? "is-invalid" : "")} {...register("UserNationality")} />

                        {errors.UserNationality?.message && <p className="input-msg-error">{errors.UserNationality?.message}</p>}
                    </div>

                    <div className='col mb-3'>
                        <label htmlFor="UserCivilStatus" className="form-label">Etat civil</label>

                        <select className={"form-select " + (errors?.UserCivilStatus ? "is-invalid" : "")} {...register("UserCivilStatus")} >
                            <option value=""></option>
                            <option value="Célibataire">Célibataire</option>
                            <option value="Marié">Marié</option>
                            <option value="Divorcé">Divorcé</option>
                        </select>

                        {errors.UserCivilStatus?.message && <p className="input-msg-error">{errors.UserCivilStatus?.message}</p>}
                    </div>


                    <div className='col mb-3'>
                        <label htmlFor="UserDateContract" className="form-label">Date de contrat</label>

                        <input type="date" className={"form-control " + (errors?.UserDateContract ? "is-invalid" : "")} {...register("UserDateContract")} />

                        {errors.UserDateContract?.message && <p className="input-msg-error">{errors.UserDateContract?.message}</p>}
                    </div>
                </div>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <ButtonSubmit />
                </div>

            </form>

        </section>
    </section>
}