import React, { useEffect, useState } from "react";

import { CardBtnLink, Header, PrivateComponent, PrivateLinkUpdate } from "../../common/common";
import { reqDelete, reqGet } from "../../../service/apiRequest"
import { IManagement, IOwner } from "../../common/types";
import { NavLink, Link, Outlet, useNavigate, useParams, useLocation, Routes, Route } from "react-router-dom";
import { GROUP, ICONS, LAYOUT } from "../../common/constants";
import $ from 'jquery'
import { getToken } from "../../../useToken";

interface IClientContent extends IManagement, IOwner { }

export default function ClientManagement({ reload, setReload }) {
    const token = getToken()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const idManagement = params.idManagement
    const idOwner = params.idOwner

    const [content, setContent] = useState<IClientContent>()

    useEffect(() => {
        if (idManagement)
            reqGet("management/" + idManagement).then(res => { setContent(res) }, rej => { navigate("/client") })
        else if (idOwner)
            reqGet("owner/" + idOwner).then(res => { setContent(res) }, rej => { navigate("/client") })
        else
            navigate(-1)
    }, [idManagement, idOwner])

    const [display, setDisplay] = useState<string>("")
    const [displayContent, setDisplayContent] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location, location.pathname])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.xl) {
            setDisplay('d-block')
            setDisplayContent('d-block')

        } else {
            if (location.pathname == ("/client/management/" + idManagement) || location.pathname == ("/client/owner/" + idOwner)) {
                setDisplay('d-block')
                setDisplayContent('d-none')

            } else {
                setDisplay('d-none')
                setDisplayContent('d-block')
            }
        }
    }

    const LinkBack = () => {
        if ($(window).width() <= LAYOUT.lg)
            return <Link to="/client" className="link-nav">{"<"} Retour</Link>
        else
            return undefined
    }

    const ClientCard = () => {
        if (idManagement)
            return CardManagement(content)
        else if (idOwner)
            return CardOwner(content)
        else
            return <></>
    }

    function RemoveClient() {
        if (idManagement)
            reqDelete("management/" + idManagement, { data: { ActivityUserID: token.UserID } }).then(res => {
                setReload(true)
                navigate("/client")
            })
        else if (idOwner)
            reqDelete("owner/" + idOwner, { data: { ActivityUserID: token.UserID } }).then(res => {
                setReload(true)
                navigate("/client")
            })

    }


    const AddressLinks: {
        Title: string, State, Path: string, Icon: string, PrivateGroup: Array<String> | undefined, PrivatePermission: Array<String> | undefined
    }[] = [
            { Title: "Arrêt/MER chauffage", Path: "heating", Icon: ICONS.HEATING, State: undefined, PrivateGroup: GROUP.crud_sav, PrivatePermission: undefined },
            { Title: "Relevés", Path: "", Icon: ICONS.STATEMENT, State: undefined, PrivateGroup: GROUP.dev, PrivatePermission: undefined }
        ]

    return <section className="body-container content">

        <header className="header">
            <div>
                <h2 className="section-title mb-0">{content?.ManagementName || content?.OwnerName}</h2>
                {LinkBack()}
            </div>
        </header>

        <section className="body">

            <div className="row h-100">

                <div className={display + " col-xl-4"}>
                    <ClientCard />

                    <div className="d-xl-none">
                        {PrivateComponent(<CardBtnLink path="address" title="Addresses" icon={ICONS.ADDRESS} />)}

                        {AddressLinks.map((l, key) =>
                            <React.Fragment key={key}>
                                {PrivateComponent(<CardBtnLink path={l.Path} title={l.Title} icon={l.Icon} state={l.State} />, l.PrivateGroup, l.PrivatePermission)}
                            </React.Fragment>
                        )}
                    </div>

                </div>

                <div className={displayContent + " col-xl-8 col-h-fill"}>

                    {!params?.idAddress &&
                        <nav className="nav-horizontal mb-0">
                            <NavLink to="address" className="nav-link">Adresses</NavLink>
                            <NavLink to="heating" className="nav-link">Arrêt/MER chauffage</NavLink>
                        </nav>
                    }

                    <Outlet context={content} />

                </div>

            </div>

        </section>

    </section>
}



const CardManagement = (management) => <div className="card">

    <header className="card-header">
        <h5 className="card-title">Informations</h5>

        {PrivateComponent(
            <Link to={"update"} className="btn btn-outline" state={{ from: "client", path: location.pathname }}>
                <i className="bi bi-pencil me-1"></i> Modifier
            </Link>,
            GROUP.crud_sav,
            ["ManagementUpdate"]
        )}
    </header>

    <section className="card-body">
        <h6 className="mb-1">Adresse</h6>
        <p className="paragraph">{management?.ManagementAddress}, {management?.ZipCode} {management?.City}</p>

        <h6 className="mb-1">Téléphone</h6>
        <p className="paragraph">{management?.ManagementPhone ?? " "}</p>

        <h6 className="mb-1">Email</h6>
        <p className="paragraph">{management?.ManagementEmail ?? " "}</p>

        <h6 className="mb-1">Email mazout</h6>
        <p className="paragraph">{management?.ManagementEmailMazout ?? " "}</p>

        {management?.ManagementWebsite && <a className="link-nav" target="_blank" href={management?.ManagementWebsite}>Site web {">"}</a>}
    </section>

    <footer className="card-footer">
        <p className="mb-0 text-muted">Mis à jour le {management?.DateUpdate}</p>
    </footer>
</div>


const CardOwner = (owner) => <div className="card">
    <header className="card-header">
        <h5 className="card-title">Informations</h5>
    </header>

    <section className="card-body">
        <h6 className="mb-1">Adresse</h6>
        <p className="paragraph mb-0">{owner?.OwnerAddress}, {owner?.ZipCode} {owner?.City}</p>

        <h6 className="mb-1">Téléphone</h6>
        <p className="paragraph mb-0">{owner?.OwnerPhone}</p>

        <h6 className="mb-1">Email</h6>
        <p className="paragraph mb-0">{owner?.OwnerEmail}</p>
    </section>

    <footer className="card-footer">
        <p className="mb-0 text-muted">Mis à jour le {owner?.DateUpdate}</p>
    </footer>
</div>
