import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Header, ButtonSubmit, TimeRound, DateToday } from '../../../common/common'
import { IUser } from "../../../common/types";
import { reqGet, reqPost } from "../../../../service/apiRequest";
import { getToken } from "../../../../useToken";
import { TIMESHEET, TIMESHEET_TYPE } from "../../../common/constants";


const validationSchema = yup.object({
    TimesheetDate: yup
        .date()
        .max(new Date(), `La date ne peut pas dépasser la date actuelle; ${new Date().toLocaleDateString('fr-FR')}`)
        .required("Ce champ est obligatoire")
}).required();

export default function AccountTimesheetAdd({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()

    const { register, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm<{ TimesheetUserID: number, TimesheetDate: Date }>({
        mode: "onTouched",
        resolver: yupResolver(validationSchema)
    });

    const [user, setUser] = useState<IUser>()

    useEffect(() => {
        reqGet("user/" + token.UserID).then(res => setUser(res))
    }, [])


    function LunchBreak() {

        const ms = 1000 * 60 * TIMESHEET.ROUND

        var lunchStart, lunchEnd

        if ($("#TimesheetLunchStart").val()) {
            lunchStart = new Date()
            lunchStart.setHours($("#TimesheetLunchStart").val().split(":")[0])
            lunchStart.setMinutes($("#TimesheetLunchStart").val().split(":")[1])
            lunchStart = new Date(Math.round(lunchStart.getTime() / ms) * ms)
            $("#TimesheetLunchStart").val(lunchStart.toString().substring(16, 21))
        }

        if ($("#TimesheetLunchEnd").val()) {
            lunchEnd = new Date()
            lunchEnd.setHours($("#TimesheetLunchEnd").val().split(":")[0])
            lunchEnd.setMinutes($("#TimesheetLunchEnd").val().split(":")[1])
            lunchEnd = new Date(Math.round(lunchEnd.getTime() / ms) * ms)

            if (lunchStart && lunchEnd) {
                var diff = Math.floor((Math.abs(lunchEnd - lunchStart) / 1000) / 60);

                if (diff < TIMESHEET.MIDDAY_MIN) {
                    var minToAdd = TIMESHEET.MIDDAY_MIN - diff

                    lunchEnd = new Date(lunchEnd.getTime() + (minToAdd * 60000))
                }
            }
            $("#TimesheetLunchEnd").val(lunchEnd.toString().substring(16, 21))
        }
    }

    const onSubmit: SubmitHandler<{ TimesheetUserID: number, TimesheetDate: Date }> = data => {

        const month = data.TimesheetDate.getMonth() + 1
        var date = data.TimesheetDate.getFullYear() + "-" + (month < 10 ? "0" + month : month) + "-" + data.TimesheetDate.getDate()

        var d = {
            ActivityUserID: token.UserID,
            FirstName: token.FirstName,
            Name: token.Name,
            TimesheetType: TIMESHEET_TYPE.TIMESHEET,
            TimesheetUserID: token.UserID,
            TimesheetStart: $("#TimesheetStart").val() ?
                date + " " + $("#TimesheetStart").val() + ":00" : null,
            TimesheetLunchStart: $("#TimesheetLunchStart").val() ?
                date + " " + $("#TimesheetLunchStart").val() + ":00" : null,
            TimesheetLunchEnd: $("#TimesheetLunchEnd").val() ?
                date + " " + $("#TimesheetLunchEnd").val() + ":00" : null,
            TimesheetEnd: $("#TimesheetEnd").val() ?
                date + " " + $("#TimesheetEnd").val() + ":00" : null,
            TimesheetKm: parseInt($("#TimesheetKm").val()) ?? null
        }

        reqPost('timesheet', d).then((res) => {
            navigate("/account/timesheet")
            setReload(true)
        })

    };

    return <div className="body-container content">
        <header className="mb-3">
            <h2 className="section-title mb-0">Ajouter une feuille d'heure</h2>
            <Link to="/account/timesheet" className="link-nav">{"<"} Retour</Link>
        </header>

        <section className="body">

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row row-cols-2 mb-3">

                    <div className="col">
                        <label className="form-label">Date *</label>

                        <input type="date" className={"form-control " + (errors?.TimesheetDate ? "is-invalid" : "")} {...register("TimesheetDate")} id="TimesheetDate" defaultValue={DateToday()} />
                    </div>
                </div>

                {errors.TimesheetDate?.message && <p className="input-msg-error">{errors.TimesheetDate?.message}</p>}

                <div className="row  row-cols-2">
                    <div className="col mb-3">
                        <label className="form-label">Début de journée</label>

                        <div className="input-group">
                            <input type="time" id="TimesheetStart" className="form-control" onBlur={(e) => CheckValidTimesheetHour(user, getValues("TimesheetDate"), e)} />
                        </div>
                    </div>

                    <div className="col mb-3">
                        <label className="form-label">Pause de midi</label>

                        <div className="input-group">
                            <input type="time" id="TimesheetLunchStart" className="form-control" onBlur={(e) => LunchBreak()} />
                        </div>
                    </div>

                    <div className="col mb-3">
                        <label className="form-label">Fin pause de midi</label>

                        <div className="input-group">
                            <input type="time" id="TimesheetLunchEnd" className="form-control" onBlur={(e) => LunchBreak()} />
                        </div>
                    </div>

                    <div className="col mb-3">
                        <label className="form-label">Fin de journée</label>

                        <div className="input-group">
                            <input type="time" id="TimesheetEnd" className="form-control" onBlur={(e) => CheckValidTimesheetHour(user, getValues("TimesheetDate"), e)} />
                        </div>
                    </div>

                    <div className="col mb-3">
                        <label className="form-label">Km (remboursable)</label>

                        <div className="input-group">
                            <input type="text" pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]" id="TimesheetKm" className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <ButtonSubmit />
                </div>

            </form>
        </section>
    </div>
}


export function CheckValidTimesheetHour(user, date, e) {
    date = new Date(date)
    date.setHours(e.target.value.split(":")[0])
    date.setMinutes(e.target.value.split(":")[1])

    if (date > new Date()) {
        let newDate = new Date()
        $(e.target).val(newDate.toString().substring(16, 21))
    }
    else {

        var arrivalHour: string | null = null
        var departureHour: string | null = null

        switch (date.getDay()) {
            case 1:
                arrivalHour = user?.RoleMondayStart ?? null
                departureHour = user?.RoleMondayEnd ?? null
                break;
            case 2:
                arrivalHour = user?.RoleTuesdayStart ?? null
                departureHour = user?.RoleTuesdayEnd ?? null
                break;
            case 3:
                arrivalHour = user?.RoleWednesdayStart ?? null
                departureHour = user?.RoleWednesdayEnd ?? null
                break;
            case 4:
                arrivalHour = user?.RoleThursdayStart ?? null
                departureHour = user?.RoleThursdayEnd ?? null
                break;
            case 5:
                arrivalHour = user?.RoleFridayStart ?? null
                departureHour = user?.RoleFridayEnd ?? null
                break;
        }


        if (arrivalHour || departureHour)
            date = TimeRound(date, arrivalHour, departureHour)

        $(e.target).val(date.toString().substring(16, 21))
    }
}