import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import $ from 'jquery'
import { useReactToPrint } from "react-to-print";
import Holidays from "date-holidays"


import { getToken } from '../useToken'
import { Header, MonthWeeks, dateFormat } from './common/common'
import { reqGet, reqPost, reqPut } from "../service/apiRequest";
import { ICounter, IStorageProduct, IWeek, IZone } from "./common/types";
import { ICONS, MONTH, MONTH_FR } from "./common/constants";

export default function Test() {
  const hd = new Holidays('CH', 'GE')
  console.log(hd.getHolidays(2024));

  useEffect(() => {
    $("#show").html(hd.getHolidays(2024))
  }, [])

  return <section className="body-container content">

    <header className="header">
      <h2 className="section-title">Test</h2>
    </header>

    <section className="body">

      <h6>Types</h6>
      <ul>
        <li>Public: Public holidays</li>
        <li>Bank: Bank holidays, banks and offices are closed</li>
        <li>School: Schools are closed</li>
        <li>Optional: Majority of people take a day off</li>
        <li>Observance: Optional festivity, no paid day off</li>
      </ul>

      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Nom</th>
            <th>Type</th>
            <th>Règle</th>
          </tr>
        </thead>

        <tbody>
          {hd.getHolidays(2024).map((h, k) => {
            return <tr key={k}>
              <td>{dateFormat(h.start, "dd/MM/yyyy")} - {dateFormat(h.end, "dd/MM/yyyy")}</td>
              <td>{h.name}</td>
              <td>{h.type}</td>
              <td>{h.rule}</td>
            </tr>
          })}
        </tbody>
      </table>
    </section>
  </section>
};