import React from "react";
import QRCode from "react-qr-code";

export default function HelpStoragePutBack() {
    return <>

        <h2 className="section-title">Déposer un objet</h2>



        <h4 className="section-title-2">Depuis le menu principal</h4>

        <h4 className="section-title-2">Depuis l'emplacement</h4>

    </>
}