import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'

import { getToken } from '../../../../useToken'
import { reqGet, reqDelete } from '../../../../service/apiRequest'
import { PrivateLinkAdd, PrivateComponent, DebouncedInput, Filter, fuzzyFilter, fuzzySort } from "../../../common/common"
import { GROUP } from "../../../common/constants"
import { IBrand } from "../../../common/types"

import { useReactTable, ColumnFiltersState, getCoreRowModel, getFilteredRowModel, getFacetedRowModel, getFacetedUniqueValues, getFacetedMinMaxValues, getSortedRowModel, ColumnDef, flexRender, } from '@tanstack/react-table'

export default function BrandContent() {
    const token = getToken()

    const [selectedItem, setSelectedItem] = useState<IBrand>()
    const [reload, setReload] = useState<boolean>()
    const table = MaterialTable(reload, setReload)

    useEffect(() => {
        if (selectedItem)
            $('#btnUpdate').removeClass('disabled')
        else
            $('#btnUpdate').addClass('disabled')

    }, [selectedItem])

    function handleSelection(item, e) {
        $('#table tr.active').removeClass('active')
        e.currentTarget.classList.add('active')

        setSelectedItem(item)
    }

    function clearSelection() {
        setSelectedItem(undefined)
        $('#table tr.active').removeClass('active')
        $(".table-responsive").get(0).scrollTo(0, 0)
    }

    function removeItem() {
        reqDelete("brand/" + selectedItem?.BrandID, { data: { ActivityUserID: token.UserID } }).then(res => {
            setReload(true)
        })
    }

    return <div className="card card-flush card-100">

        <div className="modal fade" id="removeModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes vous  sur de vouloir supprimer :</p>
                        <p><b>{selectedItem?.Name}</b></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => removeItem()}>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="card-header d-block">
            <div className="row">
                <div className="col-4 col-md-3 col-lg-4 order-1">
                    <h2 className="section-title">Marque</h2>
                </div>

                <div className="col-md-4 order-3 order-md-2 text-center mt-3 mt-md-0">
                    <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche global" />
                </div>

                <div className="col-8 col-md-5 col-lg-4 order-2 order-md-3 d-flex align-items-start justify-content-end">
                    <div className="toolbar">
                        {PrivateComponent(
                            <Link to="add" className="btn btn-outline">
                                <i className="bi bi-plus-lg me-1"></i> Ajouter
                            </Link>,
                            GROUP.admin
                        )}

                        {PrivateComponent(
                            <Link to={selectedItem?.BrandID + "/update"} className={"btn btn-outline " + (!selectedItem ? "disabled" : "")}>
                                <i className="bi bi-pencil me-1"></i> Modifier
                            </Link>,
                            GROUP.admin
                        )}

                        {PrivateComponent(
                            <button className="btn btn-delete" title="Supprimer" data-bs-toggle="modal" data-bs-target="#removeModal" disabled={(!selectedItem)}>
                                 <i className="bi bi-trash3"></i><span className="d-none d-sm-inline ms-1"> Supprimer</span>
                            </button>,
                            GROUP.admin
                        )}

                    </div>
                </div>
            </div>
        </div>

        <div className="card-body">

            <div className="table-responsive">
                <table className="table table-hover table-sticky" id="table">

                    <thead>

                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>

                                {headerGroup.headers.map((header, k) => {
                                    return (
                                        <th key={k} colSpan={header.colSpan}>

                                            {header.isPlaceholder ? null : (
                                                <>

                                                    <p className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: header.column.getToggleSortingHandler() }}>

                                                        {flexRender(header.column.columnDef.header, header.getContext())}

                                                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[header.column.getIsSorted() as string] ?? null}

                                                    </p>

                                                    {header.column.getCanFilter() ? (
                                                        <div className="d-flex flex-direction-column">
                                                            <Filter column={header.column} table={table} />
                                                        </div>
                                                    ) : null}

                                                </>
                                            )}
                                        </th>
                                    )
                                })}

                            </tr>
                        ))}
                    </thead>

                    <tbody>
                        {table.getRowModel().rows.map(row => {
                            return (

                                <tr key={row.id} onClick={(e) => handleSelection(row.original, e)}>

                                    {row.getVisibleCells().map(cell => {
                                        return (

                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>

                                        )
                                    })}

                                </tr>
                            )

                        })}
                    </tbody>

                </table>
            </div>

        </div>

    </div>
}

export function MaterialTable(reload?: boolean, setReload?) {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')

    const columns = useMemo<ColumnDef<IBrand, any>[]>(
        () => [
            {
                accessorFn: row => row.Name,
                id: 'Name',
                header: 'Nom',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Email,
                id: 'Email',
                header: 'Email',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Phone,
                id: 'Phone',
                header: 'Téléphone',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Website,
                id: 'Website',
                header: 'Site internet',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            }
        ], []
    )

    const [data, setData] = useState<Array<IBrand>>([])

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,

        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        if (table.getState().columnFilters[0]?.id === 'Class') {
            if (table.getState().sorting[0]?.id !== 'Class') {
                table.setSorting([{ id: 'Class', desc: false }])
            }
        }
    }, [table.getState().columnFilters[0]?.id])



    useEffect(() => {
        reqGet("brand").then((res) => { setData(res) })
    }, [])

    useEffect(() => {
        if (reload) {
            reqGet("brand").then((res) => { setData(res) })
            setReload(false)
        }
    }, [reload])

    return table
}
