import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import $ from 'jquery'

import { LAYOUT } from '../../../common/constants';
import { Header, PrivateLinkAdd, PrivateLinkUpdate, dateFormat } from "../../../common/common";
import { ITask, ITimesheet } from "../../../common/types";
import { getToken } from "../../../../useToken";
import { reqGet } from "../../../../service/apiRequest";

export default function AccountTask() {
    const location = useLocation()

    const [display, setDisplay] = useState<string>("")
    const [display2, setDisplay2] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.xl) {
            setDisplay('d-flex')
            setDisplay2('d-flex')

        } else {
            if (location.pathname == "/account/task") {
                setDisplay('d-flex')
                setDisplay2('d-none')

            } else {
                setDisplay('d-none')
                setDisplay2('d-flex')
            }
        }

    }
    return <div className="container-fluid h-100">
        <div className="row h-100">
            <div className={display + " col-xl-6 col-h-fill"}>
                <div className="body-container content">

                    {Header(
                        <h2 className="section-title">Tâches</h2>,
                        <Link to="/account">{"<"} Retour</Link>,
                        PrivateLinkAdd("add")
                    )}

                    <section className="body">

                        <ul>
                            <li>Nombre tâches du jour</li>
                        </ul>

                        <TaskDay />
                    </section>
                </div>

            </div>

            <div className={display2 + " col-xl-6 col-h-fill px-0"}>
                <Outlet />
            </div>
        </div>
    </div>

}

function TaskCurrent({ }) {
    return <>

        <h3 className="section-title-2">Tâche en cours</h3>

        <div className="card">
            <header className="card-header">
                <h5 className="card-title">Quelque part</h5>

                <Link to="" className="link-nav">Adresse {">"}</Link>
            </header>

            <section className="card-body">
                <div className="row mb-3">
                    <div className="col-2">
                        <label className="form-label">Heures</label>
                        <p className="mb-0">4</p>
                    </div>
                    <div className="col">
                        <label className="form-label">De</label>
                        <p className="mb-0">08/11/23 08:00</p>
                    </div>
                    <div className="col">
                        <label className="form-label">A</label>
                        <p className="mb-0"></p>
                    </div>
                </div>

                <label className="form-label">Remarque</label>
                <p className="pagraph mb-0"></p>

            </section>

            <footer className="card-footer">
                <p className="text-muted mb-0">Modifié le 08/11/23 12:05</p>
            </footer>
        </div>
    </>
}



function TaskDay({ }) {
    const token = getToken()

    const [date, setDate] = useState<Date>(new Date())
    const [timeSheetDate, setTimesheetDate] = useState<ITimesheet>()
    const [tasks, setTasks] = useState<Array<ITask>>([])
/*
    useEffect(() => {
        reqGet("timesheet/user/" + token.UserID + "/date/" + date.toISOString().slice(0, 10)).then(res => {
            setTimesheetDate(res)
        })
        reqGet("task/user/" + token.UserID + "/date/" + date.toISOString().slice(0, 10)).then(res => { setTasks(res) })
    }, [date])*/

    function HandleDateChange(value) {
        setDate(new Date(value))
    }

    function HandleNextDay() {
        setDate(new Date(date.getTime() + (24 * 60 * 60 * 1000)))
    }

    function HandlePreviousDay() {
        setDate(new Date(date.getTime() - (24 * 60 * 60 * 1000)))
    }


    return <>
        <h3 className="section-title-2">Tâches de la journée</h3>

        <nav className="text-center mb-3">

            <button className="btn btn-outline d-inline rounded" onClick={() => HandlePreviousDay()}><i className="bi bi-caret-left"></i></button>

            <input
                type="date" className="form-control mx-3 d-inline w-auto"
                value={dateFormat((date.toLocaleDateString()), "yyyy-MM-dd")}
                onChange={(e) => HandleDateChange(e.target.value)}
                max={dateFormat((new Date().toLocaleDateString()), "yyyy-MM-dd")}
            />

            <button
                className="btn btn-outline d-inline rounded" id="btnNext"
                onClick={() => HandleNextDay()}
                disabled={(new Date(date.getTime() + (24 * 60 * 60 * 1000)).getTime() >= new Date().getTime()) ? true : false}
            >
                <i className="bi bi-caret-right"></i>
            </button>

        </nav>



        <div className="card">
            <section className="card-body">
                <div className="row row-cols-3">
                    <div className="col">
                        <h6 className="item-title mb-1">Début journée</h6>
                        <p className="paragraph mb-0">{dateFormat(timeSheetDate?.TimesheetStart, "hh:mm")}</p>
                    </div>

                    <div className="col">
                        <h6 className="item-title mb-1">Pause de midi</h6>
                        <p className="paragraph mb-0">
                            {dateFormat(timeSheetDate?.TimesheetLunchStart, "hh:mm")} - {dateFormat(timeSheetDate?.TimesheetLunchEnd, "hh:mm")}
                        </p>
                    </div>
                    <div className="col">
                        <h6 className="item-title mb-1">Fin journée</h6>
                        <p className="paragraph mb-0">{dateFormat(timeSheetDate?.TimesheetEnd, "hh:mm")}</p>
                    </div>
                </div>
            </section>
        </div>

        {tasks.length > 0 && tasks.map(t => {
            return <div className="card">
                <header className="card-header mb-2">
                    <div>
                        <h5 className="card-title mb-1 d-block">{t.TaskLocation}</h5>
                        {t.TaskAddressID && <Link to={"/address/" + t.TaskAddressID} className="link-nav">Adresse {">"}</Link>}
                        {t.TaskConstructionID && <Link to={"/construction/" + t.TaskConstructionID} className="link-nav">Chantier {">"}</Link>}
                    </div>

                    {PrivateLinkUpdate(t.TaskID + "/update")}
                </header>

                <section className="card-body">
                    <div className="row mb-3">
                        <div className="col">
                            <label className="form-label">De</label>
                            <p className="mb-0">{dateFormat(t.TaskStart, "hh:mm")}</p>
                        </div>
                        <div className="col">
                            <label className="form-label">A</label>
                            <p className="mb-0">{dateFormat(t.TaskStart, "hh:mm")}</p>
                        </div>

                        <div className="col-2">
                            <label className="form-label">Nb heures</label>
                            <p className="mb-0">{Math.abs(Math.round(new Date(t.TaskStart).getTime() - new Date(t.TaskEnd).getTime()) / (1000 * 60 * 60))}</p>
                        </div>
                    </div>

                    <label className="form-label">Remarque</label>
                    <p className="pagraph mb-0">{t.TaskNote}</p>

                </section>

                <footer className="card-footer">
                    <p className="text-muted mb-0">(Date ajouté) 08/11/23 12:05</p>
                </footer>
            </div>
        })}


    </>
}