import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { fuzzyFilter, Filter, dateFormat, PrivateComponent, ServiceBadge } from "../../common/common";
import { reqGet } from "../../../service/apiRequest";
import { IAddress, IService, IVehicle, IVehicleCheck } from "../../common/types"

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef
} from '@tanstack/react-table'
import { GROUP } from "../../common/constants";

export default function AddressAdvanced() {

    const [services, setServices] = useState<Array<IService>>([])
    const [dataBackup, setDataBackup] = useState<Array<IAddress>>([])
    const [data, setData] = useState<Array<IAddress>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const columns = useMemo<ColumnDef<IAddress, any>[]>(
        () => [
            {
                accessorFn: row => row.AddressLine,
                id: 'AddressLine',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.AddressComplementary,
                id: 'AddressComplementary',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.AddressLine + " " + row.AddressComplementary,
                id: 'Address',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.City,
                id: 'City',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ZipCode,
                id: 'ZipCode',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.City + " " + row.ZipCode,
                id: 'CityZipCode',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Services,
                id: 'Services',
                cell: info => info.getValue()
            }
        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        table.setPageSize(100)
        table.setSorting([{ id: "DateCheck", desc: true }])
        reqGet("service").then(res => { setServices(res) })
        reqGet("address").then((res) => { setData(res); setDataBackup(res)})
    }, [])

    function FilterService(service: string) {
        if (service) {
            setData(dataBackup.filter(d => d?.Services?.some(s => s.Service == service)))
        } else {
            setData(dataBackup)
        }
    }

    return <section className="body-container h-100">

        <section className="header">
            <h3 className="section-title">Adresses avancé</h3>
            <Link to="/address" className="link-nav">{"<"} Retour</Link>
        </section>

        <section className="body">

            <div className="card card-fill card-flush">
                <section className="card-body">

                    <div className="table-responsive">
                        <table className="table table-sticky align-middle" id="table" style={{ minWidth: "700px" }}>

                            <thead>

                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id} className="align-bottom pb-3">

                                        <th style={{ width: "200px" }}>
                                            <p className="mb-1" style={{ cursor: "pointer", width: "170px" }} {...{ onClick: table.getColumn("Address")?.getToggleSortingHandler() }}>
                                                Adresse
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Address")?.getIsSorted() as string] ?? null}
                                            </p>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("Address")} table={table} />
                                            </div>
                                        </th>

                                        <th style={{ width: "180px" }}>
                                            <p className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("CityZipCode")?.getToggleSortingHandler() }}>
                                                Ville
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("CityZipCode")?.getIsSorted() as string] ?? null}
                                            </p>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("CityZipCode")} table={table} />
                                            </div>
                                        </th>

                                        <th style={{ minWidth: "200px" }} className="align-top">
                                            <p className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Service")?.getToggleSortingHandler() }}>
                                                Services
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Service")?.getIsSorted() as string] ?? null}
                                            </p>

                                            <select name="" id="" className="form-select form-sm" style={{ width: "200px" }} onChange={(e) => FilterService(e.target.value)}>
                                                <option value="">Tout</option>
                                                {services.map(s => {
                                                    return <option key={s.ServiceID} value={s.Service}>{s.Service}</option>
                                                })}
                                            </select>
                                        </th>
                                    </tr>
                                ))}
                            </thead>

                            <tbody>
                                {table && table?.getRowModel().rows.map(row => {
                                    return (
                                        <tr key={row.id}>
                                            <td>
                                                <h6 className="item-title">{row.original.AddressLine} {row.original.Name}</h6>
                                                <p className="mb-0">{row.original.AddressComplementary}</p>
                                            </td>
                                            <td>
                                                <p className="mb-0">{row.original.ZipCode} {row.original.City}</p>
                                            </td>
                                            <td>
                                                {row.original?.Services?.map(s => {
                                                    return <ServiceBadge service={s.Service} className="me-2" />
                                                })}
                                            </td>
                                        </tr>
                                    )

                                })}
                            </tbody>

                        </table>
                    </div>

                </section>

                <footer className="card-footer d-flex justify-content-between align-items-center">

                    <nav className="pagination">
                        <button className="btn btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                            {'<<'}
                        </button>
                        <button className="btn btn-outline me-3" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                            {'<'}
                        </button>

                        <p className="mb-0 me-3">
                            {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                        </p>

                        <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                            {'>'}
                        </button>
                        <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                            {'>>'}
                        </button>

                        <div className="ms-3">
                            <select className="form-select form-sm" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                                {[100, 200, 500].map(pageSize => (

                                    <option key={pageSize} value={pageSize}>
                                        Afficher {pageSize}
                                    </option>

                                ))}
                            </select>
                        </div>
                    </nav>


                </footer>

            </div>

        </section >

    </section >
}