import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'

import { reqGet } from "../../../service/apiRequest";
import { Header, PrivateComponent } from '../../common/common';
import { GROUP, ICONS } from '../../common/constants';
import { IStorageProduct } from "../../common/types"

export default function WarehouseInsufficient() {
    const params = useParams()
    const idWarehouse = params.idWarehouse
    const navigate = useNavigate()

    const [insufficient, setInsufficient] = useState<Array<IStorageProduct>>([])

    useEffect(() => {
        reqGet("storage/warehouse/" + idWarehouse + "/insufficient").then(res => {

            if (res) {
                setInsufficient(res);
            }
        })
    }, [])

    return <div className="row h-100">
        <div className="col-lg-6 col-xxl-4 col-h-fill">


            <main className="body-container content">

                <header className="header">
                    <div>
                        <h2 className='section-title'>Stock insuffisant</h2>
                        <Link to={"/warehouse/" + idWarehouse} className="link-nav">{"<"} Retour</Link>
                    </div>

                    {PrivateComponent(
                        <button onClick={() => navigate("/warehouse/" + idWarehouse + "/order")} disabled={insufficient.length > 0 ? false : true} className="btn btn-add">
                            <i className={ICONS.ORDER + " me-2"}></i>Commander
                        </button>,
                        GROUP.admin,
                        ["ProductOrder"]
                    )}
                </header>

                <div className="body">

                    <article className="card card-fill">

                        <div className="card-body">
                            {insufficient.length > 0 ?
                                <div className="list-group list-group-flush">

                                    {insufficient.map(item => {

                                        return (
                                            <div key={item.ProductID} className="list-group-item">
                                                <div className="row">

                                                    <div className="col-5 ps-0">
                                                        <h6 className="item-title text-truncate mb-0">{item.ProductModel}</h6>

                                                        <Link to={"/warehouse/" + item.WarehouseID + "/storage/" + item.StorageID} className="link-nav d-inline me-2">{item.Rack}{item.Row}{item?.Col ? "-" + item.Col : ""}{item?.Compartment ? "-" + item.Compartment : ""}</Link>
                                                        <p className="item-subtitle d-inline me-2">{item.ProductReference}</p>
                                                    </div>

                                                    <div className="col-4 col-lg-4">
                                                        <p className="text-muted mb-0">Fournisseur</p>
                                                        <p className="mb-0">{item.TempSupplier}</p>
                                                    </div>

                                                    <div className="col-3 px-0 text-end">
                                                        <h4 className="mb-0">{item.Quantity}</h4>
                                                        <p className="mb-0"><small className="text-muted">Min:</small> {item.QuantityMin} <small className="text-muted">Max:</small> {item.QuantityMax}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        )

                                    })}
                                </div>
                                :
                                <p className="text-center text-muted">Toutes les pièces de l'entrepôt sont en quantité suffisante</p>
                            }
                        </div>

                        <footer className="card-footer text-center">
                            <p className="text-muted mb-0">{insufficient.length} éléments</p>
                        </footer>
                    </article>

                </div>
            </main>

        </div>
    </div>
}
