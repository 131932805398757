import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import $ from 'jquery'

import { Header, ButtonSubmit, TimeRound, dateFormat } from '../../../common/common'
import { ITimesheet, IUser } from "../../../common/types";
import { reqGet, reqPut } from "../../../../service/apiRequest";
import { getToken } from "../../../../useToken";
import Timesheet from './../../timesheet/timesheet';

export default function AccountPicketUpdate({ setReload }) {
    const params = useParams()
    const idTimesheet = params.idTimesheet
    const navigate = useNavigate()
    const token = getToken()
    const location = useLocation()

    const [timesheet, setTimesheet] = useState<ITimesheet>()
    const [user, setUser] = useState<IUser>()

    useEffect(() => {
        reqGet("user/" + token.UserID).then(res => setUser(res))
        reqGet("timesheet/" + idTimesheet).then(res => {

            // if timesheet is validate can't modify
            if (res.TimesheetValidate == 1)
                navigate(-1)

            setTimesheet(res)
            $("#TimesheetStart").val(res.TimesheetStart.substring(11, 16))
            $("#TimesheetEnd").val(res.TimesheetEnd.substring(11, 16))
        })
    }, [idTimesheet])

    const onSubmit = () => {
        var data = {
            ActivityUserID: token.UserID,
            TimesheetID: idTimesheet,
            TimesheetStart: $("#TimesheetStart").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetStart").val() + ":00" : null,
            TimesheetEnd: $("#TimesheetEnd").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetEnd").val() + ":00" : null
        }

        reqPut('timesheet/' + idTimesheet, data).then((res) => {
            setReload(true)
            navigate(-1)
        })
    };

    const LinkBack = () => {

        if (location?.state?.path)
            return <Link to={location.state.path} className="link-nav">{"<"} Retour</Link>
        else
            return <Link to="/account/picket" className="link-nav">{"<"} Retour</Link>
    }

    return <div className="body-container content">
        {Header(
            <h2 className="section-title">Modifier le service de piquet</h2>,
            LinkBack()
        )}

        <section className="body">

            <div className="mb-3">
                <label className="form-label">Date</label>
                <h4 className="item-title">{dateFormat(timesheet?.TimesheetStart, "dd/MM/yyyy")}</h4>
            </div>

            <div className="row row-cols-2">
                <div className="col mb-3">
                    <label className="form-label">Début de de piquet</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetStart" className="form-control" />
                    </div>
                </div>

                <div className="col mb-3">
                    <label className="form-label">Retour de piquet</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetEnd" className="form-control" />
                    </div>
                </div>
            </div>


            <div className="text-end">
                <p><small>Les champs avec une * sont obligatoire</small></p>
                <ButtonSubmit onClick={onSubmit} />
            </div>
        </section>
    </div>
}