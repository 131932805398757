import React, { useEffect, useMemo, useState } from 'react'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom'
import { reqGet, reqDelete } from '../../../service/apiRequest'
import $ from 'jquery'

import { getToken } from '../../../useToken'
import { PrivateComponent, DebouncedInput, fuzzySort, fuzzyFilter, Filter, Header, PrivateLinkAdd } from "../../common/common"
import { GROUP, ICONS, LAYOUT, MATERIAL_CLASS_ID } from "../../common/constants"
import { IMaterial, IMaterialClass } from "../../common/types"

import {
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    flexRender
} from '@tanstack/react-table'



export default function Material({ reload, setReload }) {
    const location = useLocation()
    const token = getToken()

    const [selectedMaterial, setSelectedMaterial] = useState<IMaterial>()

    const table = MaterialTable(reload, setReload)

    const [materialClass, setMaterialClass] = useState<Array<IMaterialClass>>([])

    useEffect(() => {
        reqGet("materialclass").then(res => setMaterialClass(res))
    }, [])

    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.lg) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/material") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    return <div className="row h-100">


        <div className={display + " col-12 col-lg-7 col-xxl-8 col-h-fill border-right"}>

            <section className="body-container content">

                <header className="row mb-3">
                    <div className="col-6 col-lg-3">
                        <h2 className="section-title">Matériels</h2>
                    </div>

                    <div className="col-6 col-lg-3 order-2 order-lg-3 text-end">
                        {PrivateLinkAdd("add", GROUP.crud_sav, ["MaterialCreate"])}
                    </div>

                    <div className="col col-lg-6 order-3 order-lg-2">
                        <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche dans tout le matériel" />
                    </div>
                </header>


                <nav className="card card-flush py-0">
                    <div className="overflow-x-auto">
                        <table className="table" style={{ minWidth: "700px" }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "170px" }}>
                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Class")?.getToggleSortingHandler() }}>
                                            Catégorie
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Class")?.getIsSorted() as string] ?? null}
                                        </h6>

                                        <div className="d-flex flex-direction-column mb-3">
                                            <select name="" id="" className="form-sm form-select" onChange={e => table.getColumn("Class")?.setFilterValue(e.target.value)}>
                                                <option value="">Tout</option>
                                                {materialClass?.map(mc => {
                                                    return <option key={mc.MaterialClassID} value={mc.Class}>{mc?.Class}</option>
                                                })}
                                            </select>
                                        </div>

                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Type")?.getToggleSortingHandler() }}>
                                            Type
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Type")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column">
                                            <Filter column={table.getColumn("Type")} table={table} />
                                        </div>
                                    </th>

                                    <th style={{ width: "170px" }}>
                                        <h6 className="mb-2" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Model")?.getToggleSortingHandler() }}>
                                            Modèle
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Model")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column mb-3">
                                            <Filter column={table.getColumn("Model")} table={table} />
                                        </div>

                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Brand")?.getToggleSortingHandler() }}>
                                            Marque
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Brand")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column">
                                            <Filter column={table.getColumn("Brand")} table={table} />
                                        </div>
                                    </th>

                                    <th style={{ width: "170px" }}>
                                        <h6 className="mb-1">Trier par</h6>
                                        <button className="btn btn-outline form-sm rounded mb-3" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("NbInstallation")?.getToggleSortingHandler() }}>
                                            Nombre installé
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("NbInstallation")?.getIsSorted() as string] ?? null}
                                        </button>

                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Capacity")?.getToggleSortingHandler() }}>
                                            Capacité (L)
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Capacity")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column">
                                            <Filter column={table.getColumn("Capacity")} table={table} />
                                        </div>

                                    </th>

                                    <th style={{ width: "170px" }}>
                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("MaterialYearProduction")?.getToggleSortingHandler() }}>
                                            Année production
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("MaterialYearProduction")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column mb-3">
                                            <Filter column={table.getColumn("MaterialYearProduction")} table={table} />
                                        </div>

                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("MaterialYearEndProduction")?.getToggleSortingHandler() }}>
                                            Année fin production
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("MaterialYearEndProduction")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column">
                                            <Filter column={table.getColumn("MaterialYearEndProduction")} table={table} />
                                        </div>
                                    </th>

                                    <th style={{ width: "170px" }}>
                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("PowerRange")?.getToggleSortingHandler() }}>
                                            Puissance
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("PowerRange")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column mb-3">
                                            <Filter column={table.getColumn("PowerRange")} table={table} />
                                        </div>

                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Alimentation")?.getToggleSortingHandler() }}>
                                            Alimentation
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Alimentation")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column">
                                            <Filter column={table.getColumn("Alimentation")} table={table} />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </nav>


                <section className="body">

                    <section className="list list-hover">
                        {table.getRowModel().rows.map(row => {
                            const material = row.original
                            return (
                                <NavLink to={material.MaterialID.toString()} key={material.MaterialID} className="list-item">

                                    <section className="list-item-data">

                                        <div className="row d-flex">
                                            <div className="col-6 col-sm-5 col-md-4">
                                                <h6 className="item-title text-truncate">{material.Class}</h6>
                                                <p className="item-text text-truncate">{material.Type}</p>
                                            </div>

                                            <div className="col-6 col-sm-5 col-md-4 col-lg-3">
                                                <h6 className="item-title text-truncate">{material?.Model}</h6>
                                                <p className="item-text text-truncate">
                                                    {material?.Brand} {(material?.Brand && material?.Reference) && " - "} {material?.Reference}
                                                </p>
                                            </div>

                                            <div className="col-sm-2 col-md-4 col-lg-5 d-none d-sm-block">

                                                <div className="row justify-content-end row-cols-md-2 row-cols-xxl-3">

                                                    <div className="col d-none d-sm-block">
                                                        <h6 className='item-title text-truncate'>Total installé</h6>
                                                        <p className="item-text">{material?.NbInstallation}</p>
                                                    </div>

                                                    <div className="col d-none d-md-block">
                                                        <h6 className='item-title'>Production</h6>
                                                        <p className="item-text">{material?.MaterialYearProduction} {(material?.MaterialYearProduction && material?.MaterialYearEndProduction) && " - "} {material?.MaterialYearEndProduction}</p>
                                                    </div>

                                                    <div className="col d-none d-xxl-block">

                                                        {(material.MaterialClassID == MATERIAL_CLASS_ID.BOILER ||
                                                            material.MaterialClassID == MATERIAL_CLASS_ID.TANK ||
                                                            material.MaterialClassID == MATERIAL_CLASS_ID.EXPANSION) &&
                                                            <>
                                                                <h6 className='item-title'>Capacité (L)</h6>
                                                                <p className="item-text">{material?.Capacity}</p>
                                                            </>
                                                        }

                                                        {(material.MaterialClassID == MATERIAL_CLASS_ID.BURNER ||
                                                            material.MaterialClassID == MATERIAL_CLASS_ID.GENERATOR) &&
                                                            <>
                                                                <h6 className='item-title'>Puissance</h6>
                                                                <p className="item-text">{material?.PowerRange}</p>
                                                            </>
                                                        }

                                                        {material.MaterialClassID == MATERIAL_CLASS_ID.SOLAR &&
                                                            <>
                                                                <h6 className='item-title'>Surface</h6>
                                                                <p className="item-text">{material?.Surface}</p>
                                                            </>
                                                        }

                                                        {material.MaterialClassID == MATERIAL_CLASS_ID.PUMP &&
                                                            <>
                                                                <h6 className="item-title">Alimentation</h6>
                                                                <p className="item-text">{material?.Alimentation}</p>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </section>

                                    <div className="list-item-more d-flex d-md-none">
                                        <i className="bi bi-chevron-right"></i>
                                    </div>
                                </NavLink>
                            )

                        })}
                    </section>




                </section>

                <footer className="container-fluid mt-3">
                    <div className="row mx-0 pagination">

                        <div className="col-8 col-lg-4 px-0 text-start order-2 order-lg-1">
                            <button className="btn btn-outline me-2" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                                {'<<'}
                            </button>
                            <button className="btn btn-outline me-2" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                                {'<'}
                            </button>

                            <p className="d-inline mb-0 me-2">
                                {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                            </p>

                            <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                                {'>'}
                            </button>
                            <button className="btn btn-outline me-2" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                                {'>>'}
                            </button>
                        </div>

                        <div className="col col-lg-3 offset-lg-5 pe-0 text-end order-4">
                            <select className="form-select float-end" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                                {[100, 250, 500, 1000].map(pageSize => (

                                    <option key={pageSize} value={pageSize}>
                                        Afficher {pageSize}
                                    </option>

                                ))}

                            </select>
                        </div>

                    </div>

                </footer>

            </section>

        </div>





        <div className="col-md col-h-fill">
            <Outlet />
        </div>

    </div >
}

export function MaterialTable(reload?: boolean, setReload?) {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')

    const columns = useMemo<ColumnDef<IMaterial, any>[]>(
        () => [
            {
                accessorFn: row => row.NbInstallation,
                id: 'NbInstallation',
                header: 'Nb installations',
                cell: info => info.getValue(),
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.Class,
                id: 'Class',
                header: 'Catégorie',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Type,
                id: 'Type',
                header: 'Type',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Brand,
                id: 'Brand',
                header: 'Marque',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Model,
                id: 'Model',
                header: 'Modèle',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Reference,
                id: 'Reference',
                header: 'Référence',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.MaterialYearProduction,
                id: 'MaterialYearProduction',
                header: 'Année production',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.MaterialYearEndProduction,
                id: 'MaterialYearEndProduction',
                header: 'Année fin de production',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Capacity,
                id: 'Capacity',
                header: 'Capacité (L)',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.PowerRange,
                id: 'PowerRange',
                header: 'Plage de puissance',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.ICF,
                id: 'ICF',
                header: 'ICF',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Surface,
                id: 'Surface',
                header: 'Surface',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Longueur,
                id: 'Longueur',
                header: 'Longueur',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Alimentation,
                id: 'Alimentation',
                header: 'Alimentation',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
        ], []
    )

    const [data, setData] = useState<Array<IMaterial>>([])

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        if (table.getState().columnFilters[0]?.id === 'Class') {
            if (table.getState().sorting[0]?.id !== 'Class') {
                table.setSorting([{ id: 'Class', desc: false }])
            }
        }
    }, [table.getState().columnFilters[0]?.id])



    useEffect(() => {
        table.setPageSize(250)

        reqGet("material").then((res) => { setData(res) })

    }, [])

    useEffect(() => {
        if (reload) {
            reqGet("material").then((res) => { setData(res) })
            setReload(false)
        }
    }, [reload])

    return table
}

