import React, { useEffect, useState } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { HeaderRow, ButtonSubmit } from "../../../common/common"
import { ITenant } from "../../../common/types"

const validationSchema = yup.object({
    TenantName: yup.string().required("Ce champ est obligatoire"),
    TenantEmail: yup.string().nullable(),
    TenantPhone: yup.string().nullable(),
})

const initialValues = {
    TenantName: "",
    TenantEmail: null,
    TenantPhone: null,
}

export default function TenantAdd() {
    const navigate = useNavigate()
    const token = getToken()
    const location = useLocation()

    const LinkBack = () => {
        if (location.state?.path)
            return location.state.path

        else
            return "/database/tenant"
    }

    const { register, formState: { errors }, handleSubmit } = useForm<ITenant>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    })

    const onSubmit: SubmitHandler<ITenant> = data => {

        reqPost('tenant', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate(LinkBack())
        })
    };

    return <div className="body-container">

        {HeaderRow(
            <h3 className="section-title">Ajouter un locataire</h3>,
            <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="TenantName" className="form-label">Nom *</label>
                            <input type="text" className={"form-control " + (errors?.TenantName ? "is-invalid" : "")} {...register("TenantName")} />

                            {errors.TenantName?.message && <p className="mt-2 text-danger">{errors.TenantName?.message}</p>}

                        </div>

                        <div className="mb-3">

                            <label htmlFor="TenantEmail" className="form-label">Email</label>
                            <input type="text" className={"form-control " + (errors?.TenantEmail ? "is-invalid" : "")} {...register("TenantEmail")} />

                            {errors.TenantEmail?.message && <p className="mt-2 text-danger">{errors.TenantEmail?.message}</p>}

                        </div>

                        <div className="mb-3">

                            <label htmlFor="TenantPhone" className="form-label">Téléphone</label>
                            <input type="text" className={"form-control " + (errors?.TenantPhone ? "is-invalid" : "")} {...register("TenantPhone")} />

                            {errors.TenantPhone?.message && <p className="mt-2 text-danger">{errors.TenantPhone?.message}</p>}

                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>

            </div>
        </div>
    </div>
}