import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { reqGet, reqDelete } from '../../../../service/apiRequest'

import { getToken } from '../../../../useToken'
import { PrivateLinkAdd, PrivateLinkUpdate, PrivateComponent, HeaderRow, ServiceBadge, dateFormat } from "../../../common/common";
import { GROUP, ICONS, MATERIAL_CLASS_ID, SERVICE } from "../../../common/constants"
import { IMaterialClass, IInstallation, IAddressService } from "../../../common/types"


export default function AddressInstalls() {
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const idAddress = params.idAddress

    const [materialClass, setMaterialClass] = useState<Array<IMaterialClass>>([])
    const [installs, setInstalls] = useState<Array<IInstallation>>([])

    const [reload, setReload] = useState<boolean>(false)

    useEffect(() => {

        if (!idAddress) {
            navigate("/")
        }

        reqGet("materialclass").then(function (res) {
            setMaterialClass(res)
        })

        reqGet("installation/address/" + idAddress).then(function (res) {
            setInstalls(res)
        })
    }, [idAddress])

    useEffect(() => {

        if (reload) {
            reqGet("installation/address/" + idAddress).then(function (res) {
                setInstalls(res)
            })
            setReload(false)
        }
    }, [reload])

    const LinkBack = () => {
        if (location.state?.from == "client")
            return <Link className="link-nav" to={location.state.path}>{'< Retour'}</Link>
        if (location?.state?.back)
            return <Link className="link-nav" to={location.state.back}>{'< Retour'}</Link>
        else
            return <Link className="link-nav" to={'/address/' + idAddress}>{'< Retour'}</Link>
    }

    return <section className="body-container content">

        <header className="header">
            <div>
                <h3 className="section-title">Installations</h3>
                <LinkBack />
            </div>

            {PrivateLinkAdd("add", [...GROUP.crud_sav, ...GROUP.crud_tec], ["InstallationAdd"])}
        </header>

        <section className="body">

            {materialClass.map((mc: IMaterialClass, k) => {

                let arrayInstalls = installs?.filter((i: IInstallation) => {
                    return i.MaterialClassID == mc.MaterialClassID || i.IMaterialClassID == mc.MaterialClassID
                })

                if (arrayInstalls.length > 0) {

                    return <React.Fragment key={k}>
                        <h2 className={"section-title-2 " + (k != 0 ? "mt-5" : "")}>{mc.Class}s</h2>

                        {arrayInstalls.map((i: IInstallation, j) => {

                            return <React.Fragment key={j}>
                                <InstallCard installation={i} setReload={setReload} />
                            </React.Fragment>
                        })
                        }

                    </React.Fragment>

                }
            })
            }

        </section>

    </section >

}

export function InstallCard({ installation, setReload }: { installation: IInstallation, setReload }) {
    const token = getToken()

    function RemoveInstall(id) {
        reqDelete("installation/" + id, { data: { ActivityUserID: token.UserID } }).then(res => {

            setReload(true)
        })
    }

    const subcontractor = installation.Service.find(s => s.Service == SERVICE.SUBCONTRACTOR)

    return <div className="card">

        <div className="modal fade" id={"modalRemoveInstallation" + installation?.InstallationID} tabIndex={-1} aria-labelledby={"modalRemoveInstallation" + installation?.InstallationID} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={"modalRemoveInstallation" + installation?.InstallationID}>Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes vous  sur de vouloir supprimer l'installation ?</p>

                        {installation.MaterialID ?
                            <h6>{installation?.Type}, {installation?.Brand} - {installation?.Model}</h6>
                            : <h6>{installation?.IType}, {installation?.IBrand} - {installation?.IModel}</h6>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => RemoveInstall(installation?.InstallationID)}>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>

        <header className="card-header">

            <h5 className="card-title">{installation.MaterialID == null ? installation.IType : installation.Type}</h5>

            {PrivateComponent(
                <div className="dropdown">
                    <button className="btn btn-sm btn-outline border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                    <ul className="dropdown-menu">
                        {PrivateComponent(
                            <li>
                                <Link to={installation?.InstallationID + "/update"} className="dropdown-item mb-2"><i className={ICONS.UPDATE + " me-2"}></i>Modifier</Link>
                            </li>,
                            undefined, ["InstallationUpdate"]
                        )}

                        {PrivateComponent(
                            <li>
                                <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target={"#modalRemoveInstallation" + installation?.InstallationID}>
                                    <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                </button>
                            </li>,
                            undefined, ["InstallationDelete"]
                        )}
                    </ul>
                </div>,
                [...GROUP.crud_sav, ...GROUP.crud_tec]
            )}

        </header>

        <section className="card-body mb-0">

            <section className='row row-cols-6 mb-3'>
                <div className='col'>
                    <h6 className="item-title">Modèle</h6>
                    <p className="item-text">{installation.MaterialID == null ? installation.IModel : installation.Model}</p>
                </div>

                <div className='col'>
                    <h6 className="item-title">Marque</h6>
                    <p className="item-text">{installation.MaterialID == null ? installation.IBrand : installation.Brand}</p>
                </div>

                <div className="col">
                    <h6 className="item-title">Référence</h6>
                    <p className='item-text'>{installation.Reference}</p>
                </div>

                <div className="col">
                    <h6 className="item-title">Année d'installation</h6>
                    <p className="item-text">{installation.YearInstalled}</p>
                </div>

                <div className="col">
                    <h6 className="item-title">Fin de garantie</h6>
                    <p className="item-text">{dateFormat(installation.EndWarranty, "dd/MM/yyyy")}</p>
                </div>

                <div className="col">
                    <h6 className="item-title">Etat</h6>
                    <p className="item-text">{installation.Status}</p>
                </div>
            </section>


            <section className="row row-cols-6 mb-3">

                {/* DEPENDS ON MATERIAL CLASS -------------------------- START */}
                {(installation?.MaterialClassID == MATERIAL_CLASS_ID.BOILER ||
                    installation?.MaterialClassID == MATERIAL_CLASS_ID.TANK ||
                    installation?.MaterialClassID == MATERIAL_CLASS_ID.EXPANSION) &&
                    <div className="col">
                        <h6 className="item-title">Capacité (L)</h6>
                        <p className="item-text">{installation.Capacity}</p>
                    </div>
                }

                {(installation?.MaterialClassID == MATERIAL_CLASS_ID.BURNER || installation?.MaterialClassID == MATERIAL_CLASS_ID.GENERATOR) &&
                    <>
                        <div className="col">
                            <h6 className="item-title">Puissance (kW)</h6>
                            <p className="item-text">{installation.PowerRange}</p>
                        </div>

                        <div className="col">
                            <h6 className="item-title">Puissance installée</h6>
                            <p className="item-text">{installation.PowerInstalled}</p>
                        </div>
                    </>
                }

                {installation?.MaterialClassID == MATERIAL_CLASS_ID.GENERATOR &&
                    <div className="col">
                        <h6 className="item-title">ICF</h6>
                        <p className="item-text">{installation.ICF}</p>
                    </div>
                }

                {(installation?.MaterialClassID == MATERIAL_CLASS_ID.SOLAR) &&
                    <>
                        <div className="col">
                            <h6 className="item-title">Surface</h6>
                            <p className="item-text">{installation.Surface}</p>
                        </div>

                        <div className="col">
                            <h6 className="item-title">Nombre</h6>
                            <p className="item-text">{installation.Number}</p>
                        </div>
                    </>
                }

                {installation?.MaterialClassID == MATERIAL_CLASS_ID.PUMP &&
                    <>
                        <div className="col">
                            <h6 className="item-title">Alimentation</h6>
                            <p className="item-text">{installation.Longueur}</p>
                        </div>

                        <div className="col">
                            <h6 className="item-title">Longeur</h6>
                            <p className="item-text">{installation.Longueur}</p>
                        </div>
                    </>
                }
                {/* DEPENDS ON MATERIAL CLASS -------------------------- END */}

            </section>


            {installation.Note &&
                <section className="mb-3">
                    <h6 className="item-title">Remarque</h6>
                    <p className="item-text">{installation.Note}</p>
                </section>
            }

            {installation.Service.filter(s => s.Service != SERVICE.SUBCONTRACTOR).length > 0 &&
                <>
                    <div className="row row-cols-6">
                        <div className="col">
                            <h6 className="item-title">Prestation</h6>
                        </div>

                        <div className="col">
                            <h6 className="item-title">Date du contrat</h6>
                        </div>

                        <div className="col">
                            <h6 className="item-title">Périodicité</h6>
                        </div>

                        <div className="col-6">
                            <h6 className="item-title">Remarque</h6>
                        </div>
                    </div>

                    {installation.Service.filter(s => s.Service != SERVICE.SUBCONTRACTOR).map((s: IAddressService, k: number) => {
                        return <div className="row row-cols-6 mb-3" key={s.ServiceID}>
                            <div className="col">
                                <ServiceBadge service={s.Service} />
                            </div>

                            <div className="col">
                                <p className="item-text"> {dateFormat(s.AddressServiceDate, "dd/MM/yyyy")} </p>
                            </div>

                            <div className="col">
                                <p className="item-text">{s.AddressServicePeriodicity}</p>
                            </div>

                            {s.AddressServiceNote &&
                                <div className='col-6'>
                                    <p className="item-text">{s.AddressServiceNote}</p>
                                </div>
                            }
                        </div>
                    })}
                </>
            }

            {subcontractor &&
                <>
                    <section className="row row-cols-6">
                        <div className="col">
                            <h6 className="item-title">Sous-traitant</h6>
                            <ServiceBadge service={subcontractor.Service} />
                        </div>

                        <div className="col">
                            <h6 className="item-title">Date du contrat</h6>
                            <p className="item-text"> {dateFormat(subcontractor.AddressServiceDate, "dd/MM/yyyy")} </p>
                        </div>

                        <div className="col">
                            <h6 className="item-title">Société</h6>
                            <p className="item-text">{subcontractor.Company}</p>
                        </div>

                        <div className="col">
                            <h6 className="item-title">Téléphone</h6>
                            <p className="item-text">{subcontractor.Phone}</p>
                        </div>

                        <div className="col">
                            <h6 className="item-title">Email</h6>
                            <p className="item-text">{subcontractor.Email}</p>
                        </div>

                    </section>

                    {subcontractor.AddressServiceNote &&
                        <div className="mt-3">
                            <h6 className="item-title">Remarque</h6>
                            <p className="item-text">{subcontractor.AddressServiceNote}</p>
                        </div>
                    }
                </>
            }
        </section>
    </div>
}
