import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { ButtonSubmit, dateFormat } from '../../../common/common'
import { IHoliday } from "../../../common/types";
import { reqGet, reqPut } from "../../../../service/apiRequest";
import { getToken } from "../../../../useToken";
import { HOLIDAY_STATUS, HOLIDAY_TYPE } from "../../../common/constants";


const initialValues = {
    HolidayType: HOLIDAY_TYPE.ACCIDENT,
    HolidayDateStart: "",
    HolidayDateEnd: "",
    HolidayAllDay: 1,
    HolidayNote: null,
    HolidayStatus: HOLIDAY_STATUS.WAITING
}

const validationSchema = yup.object().shape({
    HolidayType: yup.string().required("Ce champ est obligatoire"),
    HolidayDateStart: yup.string().required("Ce champ est obligatoire"),
    HolidayDateEnd: yup.string().required("Ce champ est obligatoire"),
    HolidayAllDay: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required("Ce champ est obligatoire"),
    HolidayNote: yup.string().nullable()
}).required();

export default function AccountHolidayUpdate({ setReload }) {
    const navigate = useNavigate()
    const location = useLocation()
    const token = getToken()
    const params = useParams()
    const idHoliday = params.idHoliday

    const LinkBack = () => {
        if (location?.state?.back)
            return location.state.back
        else
            return "/account/holiday"
    }

    const { register, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm<IHoliday>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [holiday, setHoliday] = useState<IHoliday>()

    useEffect(() => {
        if (idHoliday)
            reqGet("holiday/" + idHoliday).then((res: IHoliday) => {
                setHoliday(res)

                console.log("res", res);


                setValue("HolidayID", res.HolidayID)
                setValue("HolidayType", res.HolidayType)
                setValue("HolidayAllDay", res.HolidayAllDay)
                setValue("HolidayDateStart", res.HolidayAllDay == 0 ? res.HolidayDateStart.slice(0, res.HolidayDateStart.length - 3) : dateFormat(res.HolidayDateStart, "yyyy-MM-dd"))
                setValue("HolidayDateEnd", res.HolidayAllDay == 0 ? res.HolidayDateEnd : dateFormat(res.HolidayDateEnd, "yyyy-MM-dd"))
                setValue("HolidayStatus", res.HolidayStatus)
                setValue("HolidayNote", res.HolidayNote)

                console.log("React table", getValues());

            }, rej => {
                navigate(LinkBack())
            })
        else
            navigate(LinkBack())
    }, [])


    const onSubmit: SubmitHandler<IHoliday> = data => {
 
        data.HolidayDateStart = new Date(data.HolidayDateStart).toISOString().replace("T", " ").split(".")[0]
        data.HolidayDateEnd = new Date(data.HolidayDateEnd).toISOString().replace("T", " ").split(".")[0]

        reqPut('holiday/' + idHoliday, { ...data, UserID: token.UserID, ActivityUserID: token.UserID }).then((res) => {
            navigate(LinkBack())
            setReload(true)
        })
    };

    return <div className="body-container content">
        <header className="mb-3">
            <h2 className="section-title mb-0">Modification du congé</h2>
            <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
        </header>

        <section className="body">

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="mb-3">
                    <label className="form-label">Catégorie *</label>
                    <select id="HolidayType" className="form-select"{...register("HolidayType")} >
                        {Object.entries(HOLIDAY_TYPE).map((t, k) => <option key={k} value={t[1]}>{t[1]}</option>)}
                    </select>
                </div>

                <div className="mb-3">
                    <label className="form-label" htmlFor="HolidayAllDay">Toute la journée *</label>
                    <div className="form-switch">
                        <input className="form-check-input" type="checkbox" id="HolidayAllDay" defaultChecked {...register("HolidayAllDay")} />
                    </div>

                    {errors.HolidayAllDay?.message && <p className="input-msg-error">{errors.HolidayAllDay?.message}</p>}
                </div>

                <div className="row row-cols-2 mb-3">

                    <div className="col">
                        <label className="form-label">Date début *</label>

                        <input type={watch("HolidayAllDay") ? "date" : "datetime-local"} className={"form-control " + (errors?.HolidayDateStart ? "is-invalid" : "")} {...register("HolidayDateStart")} id="HolidayDateStart" />

                        {errors.HolidayDateStart?.message && <p className="input-msg-error">{errors.HolidayDateStart?.message}</p>}
                    </div>

                    <div className="col">
                        <label className="form-label">Date fin *</label>

                        <input type={watch("HolidayAllDay") ? "date" : "datetime-local"} className={"form-control " + (errors?.HolidayDateEnd ? "is-invalid" : "")} {...register("HolidayDateEnd")} id="HolidayDateEnd" />

                        {errors.HolidayDateEnd?.message && <p className="input-msg-error">{errors.HolidayDateEnd?.message}</p>}
                    </div>
                </div>


                <div className="mb-3">
                    <label className="form-label">Description</label>

                    <textarea id="HolidayNote" rows={4} {...register("HolidayNote")}></textarea>
                </div>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <ButtonSubmit />
                </div>

            </form>
        </section>
    </div>
}