import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import $ from 'jquery'
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { reqGet, reqPut } from "../../../../service/apiRequest";
import { ButtonSubmit, HeaderRow } from "../../../common/common"
import { ICounter, IRecurrence } from '../../../common/types'
import { MONTH_FR, MONTH } from '../../../common/constants'

interface IFormCounter extends ICounter {

  RecurrenceSurv: IRecurrence,
  RecurrenceTec: IRecurrence,
  ActivityUserID: number | null
}

const validationSchema = yup.object({
  CounterType: yup.string().required("Ce champ est obligatoire"),
  Label: yup.string().nullable(),
  IDCounter: yup.string().nullable(),
  Capacity: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
  VolumeMin: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
  Tolerance: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
  Unit: yup.string().nullable(),
  AddressID: yup.number().required(),

  RecurrenceSurv: yup.object({
    EndMonth: yup.array().of(yup.string()).nullable().max(12),
    Exceptional: yup.array().of(yup.string()).nullable().max(12),
    January: yup.string(),
    February: yup.string(),
    March: yup.string(),
    April: yup.string(),
    June: yup.string(),
    July: yup.string(),
    August: yup.string(),
    September: yup.string(),
    October: yup.string(),
    November: yup.string(),
    December: yup.string()
  }),
  RecurrenceTec: yup.object({
    EndMonth: yup.array().of(yup.string()).nullable().max(12),
    Exceptional: yup.array().of(yup.string()).nullable().max(12),
    January: yup.string(),
    February: yup.string(),
    March: yup.string(),
    April: yup.string(),
    June: yup.string(),
    July: yup.string(),
    August: yup.string(),
    September: yup.string(),
    October: yup.string(),
    November: yup.string(),
    December: yup.string()
  })
})

const initialValues = {
  CounterType: "",
  Label: "",
  IDCounter: "",
  Capacity: null,
  VolumeMin: null,
  Tolerance: null,
  Unit: "",
  AddressID: 0,

  RecurrenceSurv: {
    EndMonth: null,
    Exceptional: null,
    January: "0",
    February: "0",
    March: "0",
    April: "0",
    May: "0",
    June: "0",
    July: "0",
    August: "0",
    September: "0",
    October: "0",
    November: "0",
    December: "0"
  },
  RecurrenceTec: {
    EndMonth: null,
    Exceptional: null,
    January: "0",
    February: "0",
    March: "0",
    April: "0",
    May: "0",
    June: "0",
    July: "0",
    August: "0",
    September: "0",
    October: "0",
    November: "0",
    December: "0"
  },
  ActivityUserID: null
}

export default function AddressCounterUpdate() {
  let navigate = useNavigate();
  const params = useParams();

  const token = getToken()

  const idAddress = params.idAddress || "";
  const idCounter = params.idCounter;

  const { register, formState: { errors }, handleSubmit, setValue, getValues, watch } = useForm<IFormCounter>({
    mode: "onTouched",
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    setValue("ActivityUserID", token.UserID)
    reqGet("counter/" + idCounter).then(res => {
      setValue("CounterType", res.CounterType)
      setValue("Label", res.Label)
      setValue("IDCounter", res.IDCounter)
      setValue("Capacity", res.Capacity)
      setValue("VolumeMin", res.VolumeMin)
      setValue("Tolerance", res.Tolerance)
      setValue("Unit", res.Unit)

      setValue("RecurrenceSurv.RecurrenceID", res.RecurrenceSurv.RecurrenceID)
      setValue("RecurrenceSurv.Exceptional", res.RecurrenceSurv.Exceptional)
      setValue("RecurrenceSurv.January", res.RecurrenceSurv.January.toString())
      setValue("RecurrenceSurv.February", res.RecurrenceSurv.February.toString())
      setValue("RecurrenceSurv.March", res.RecurrenceSurv.March.toString())
      setValue("RecurrenceSurv.April", res.RecurrenceSurv.April.toString())
      setValue("RecurrenceSurv.May", res.RecurrenceSurv.May.toString())
      setValue("RecurrenceSurv.June", res.RecurrenceSurv.June.toString())
      setValue("RecurrenceSurv.July", res.RecurrenceSurv.July.toString())
      setValue("RecurrenceSurv.August", res.RecurrenceSurv.August.toString())
      setValue("RecurrenceSurv.September", res.RecurrenceSurv.September.toString())
      setValue("RecurrenceSurv.October", res.RecurrenceSurv.October.toString())
      setValue("RecurrenceSurv.November", res.RecurrenceSurv.November.toString())
      setValue("RecurrenceSurv.December", res.RecurrenceSurv.December.toString())

      setValue("RecurrenceTec.RecurrenceID", res.RecurrenceTec.RecurrenceID)
      setValue("RecurrenceTec.Exceptional", res.RecurrenceTec.Exceptional)
      setValue("RecurrenceTec.January", res.RecurrenceTec.January.toString())
      setValue("RecurrenceTec.February", res.RecurrenceTec.February.toString())
      setValue("RecurrenceTec.March", res.RecurrenceTec.March.toString())
      setValue("RecurrenceTec.April", res.RecurrenceTec.April.toString())
      setValue("RecurrenceTec.May", res.RecurrenceTec.May.toString())
      setValue("RecurrenceTec.June", res.RecurrenceTec.June.toString())
      setValue("RecurrenceTec.July", res.RecurrenceTec.July.toString())
      setValue("RecurrenceTec.August", res.RecurrenceTec.August.toString())
      setValue("RecurrenceTec.September", res.RecurrenceTec.September.toString())
      setValue("RecurrenceTec.October", res.RecurrenceTec.October.toString())
      setValue("RecurrenceTec.November", res.RecurrenceTec.November.toString())
      setValue("RecurrenceTec.December", res.RecurrenceTec.December.toString())


      res.RecurrenceSurv.Exceptional?.forEach((month) => {
        $("input:checkbox[name='checkSurvExceptional" + month + "']").prop("checked", true)
      })
      res.RecurrenceTec.Exceptional?.forEach((month) => {
        $("input:checkbox[name='checkTecExceptional" + month + "']").prop("checked", true)
      })
    })
    setValue("AddressID", parseInt(idAddress));
  }, [])

  useEffect(() => {
  }, [])

  const onSubmit: SubmitHandler<IFormCounter> = data => {
    reqPut('counter/' + idCounter, data).then((res) => {
      navigate('/address/' + idAddress + "/counter/" + idCounter)
    })

  }

  function SixWeeks() {
    setValue("RecurrenceTec.January", "1")
    setValue("RecurrenceTec.February", "1")
    setValue("RecurrenceTec.March", "0")
    setValue("RecurrenceTec.April", "1")
    setValue("RecurrenceTec.May", "1")
    setValue("RecurrenceTec.June", "0")
    setValue("RecurrenceTec.July", "1")
    setValue("RecurrenceTec.August", "1")
    setValue("RecurrenceTec.September", "0")
    setValue("RecurrenceTec.October", "1")
    setValue("RecurrenceTec.November", "1")
    setValue("RecurrenceTec.December", "0")
  }

  function HandleSurvExceptional() {
    var array: string[] = [];

    $("input:checkbox[name^='checkSurvExceptional']:checked").each(function () {
      array.push($(this).val());
    });

    setValue("RecurrenceSurv.Exceptional", array)
  }

  function HandleTecExceptional() {
    var array: string[] = [];

    $("input:checkbox[name^='checkTecExceptional']:checked").each(function () {
      array.push($(this).val());
    });

    setValue("RecurrenceTec.Exceptional", array)
  }


  return (
    <section className="body-container">

      {HeaderRow(
        <h3 className="section-title">Modifier le compteur</h3>,
        <button className="btn link-nav" onClick={() => navigate(-1)}>{"< Retour"}</button>
      )}

      <section className="body container-fluid">

        <div className="row justify-content-center">

          <div className="col-md-6">

            <div className="row mb-3">

              <div className="col-6">
                <label htmlFor="CounterType" className="form-label"> Type * </label>
                <select className={"form-select " + (errors?.CounterType ? "is-invalid" : "")} {...register("CounterType")} >

                  <option hidden disabled> </option>
                  <option value="Chaleur">Chaleur</option>
                  <option value="Citerne">Citerne</option>
                  <option value="Eau chaude">Eau chaude</option>
                  <option value="Eau froide">Eau froide</option>
                  <option value="Eléctrique">Eléctrique</option>
                  <option value="Gaz">Gaz</option>
                  <option value="Mazout">Mazout</option>
                  <option value="Pellets">Pellets</option>

                </select>

                {errors.CounterType?.message && <p className="mt-2 text-danger">{errors.CounterType?.message}</p>}
              </div>


              <div className="col-6">
                <label htmlFor="Label" className="form-label"> Libellé </label>
                <input type="text" className={"form-control " + (errors?.Label ? "is-invalid" : "")} {...register("Label")} />

                {errors.Label?.message && <p className="mt-2 text-danger">{errors.Label?.message}</p>}

              </div>
            </div>


            <div className="row mb-3">

              <div className="col-6">
                <label htmlFor="IDCounter" className="form-label"> ID Compteur </label>
                <input type="text" className={"form-control " + (errors?.IDCounter ? "is-invalid" : "")} {...register("IDCounter")} />

                {errors.IDCounter?.message && <p className="mt-2 text-danger">{errors.IDCounter?.message}</p>}

              </div>

              <div className="col-6">
                <label htmlFor="Unit" className="form-label"> Unité </label>
                <select className={"form-select " + (errors?.Unit ? "is-invalid" : "")} {...register("Unit")} >

                  <option value=""></option>
                  <option value="m3">m3</option>
                  <option value="kWh">kWh</option>
                  <option value="litres">litres</option>

                </select>

                {errors.Unit?.message && <p className="mt-2 text-danger">{errors.Unit?.message}</p>}

              </div>
            </div>


            <div className="row mb-3">

              <div className="col-4">
                <label htmlFor="Capacity" className="form-label"> Capacité </label>
                <input type="number" className={"form-control " + (errors?.Capacity ? "is-invalid" : "")} {...register("Capacity")} />

                {errors.Capacity?.message && <p className="mt-2 text-danger">{errors.Capacity?.message}</p>}

              </div>

              <div className="col-4">
                <label htmlFor="VolumeMin" className="form-label"> Volume minimum </label>
                <input type="number" className={"form-control " + (errors?.VolumeMin ? "is-invalid" : "")} {...register("VolumeMin")} />

                {errors.VolumeMin?.message && <p className="mt-2 text-danger">{errors.VolumeMin?.message}</p>}

              </div>

              <div className="col-4">
                <label htmlFor="Tolerance" className="form-label"> Tolérance </label>
                <input type="number" className={"form-control " + (errors?.Tolerance ? "is-invalid" : "")} {...register("Tolerance")} />

                {errors.Tolerance?.message && <p className="mt-2 text-danger">{errors.Tolerance?.message}</p>}

              </div>
            </div>
          </div>
        </div>





        <div className="row mb-3">
          <div className="col-md-6">

            <div className="form-section">
              <h4 className="section-title-2">Relevé surveillant</h4>

              <div className="row mb-3">
                <div className="col-4">
                  <p className="text-bold">Mois</p>
                </div>
                <div className="col-4">
                  <p className="text-bold mb-0">Récurrence</p>
                  <p className="text-muted"><i>Nombre de fois par mois</i></p>

                  <div className="d-flex justify-content-between">
                    <p className="mb-0">0</p>
                    <p className="mb-0">1</p>
                    <p className="mb-0">2</p>
                    <p className="mb-0">3</p>
                    <p className="mb-0">4</p>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <p className="text-bold mb-0">Exceptionnel</p>
                  <p className="text-muted"><i>Fin du mois</i></p>
                </div>
              </div>

              {MONTH.map((m, k) => {
                return <div className="row mb-3" key={k}>
                  <label htmlFor="staticEmail" className="col-4 col-form-label">{MONTH_FR[k]}</label>

                  <div className="col-4">
                    <div className="d-flex justify-content-between">
                      <input className="form-check-input" type="radio" value="0" {...register(`RecurrenceSurv.${m}`)} defaultChecked />
                      <input className="form-check-input" type="radio" value="1" {...register(`RecurrenceSurv.${m}`)} />
                      <input className="form-check-input" type="radio" value="2" {...register(`RecurrenceSurv.${m}`)} />
                      <input className="form-check-input" type="radio" value="3" {...register(`RecurrenceSurv.${m}`)} />
                      <input className="form-check-input" type="radio" value="4" {...register(`RecurrenceSurv.${m}`)} />
                    </div>
                  </div>

                  <div className="col-4 d-flex justify-content-center align-items-center">
                    <input type="checkbox" className="form-check-input" value={m} name={"checkSurvExceptional" + m} onChange={() => HandleSurvExceptional()} />
                  </div>
                </div>
              })}
            </div>

          </div>



          <div className="col-md-6">

            <div className="form-section">
              <h4 className="section-title-2">Relevé technicien</h4>

              <div className="row mb-3">
                <div className="col-4">
                  <p className="text-bold">Mois</p>
                </div>
                <div className="col-4">
                  <p className="text-bold">Récurrences</p>
                  <p className="text-muted"><i>Nombre de fois par mois</i></p>

                  <button className="btn btn-primary mb-2 py-1" onClick={() => SixWeeks()}>6 semaines</button>

                  <div className="d-flex justify-content-between">
                    <p className="mb-0">0</p>
                    <p className="mb-0">1</p>
                    <p className="mb-0">2</p>
                    <p className="mb-0">3</p>
                    <p className="mb-0">4</p>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <p className="text-bold mb-0">Exceptionnel</p>
                  <p className="text-muted"><i>Fin du mois</i></p>
                </div>
              </div>

              {MONTH.map((m, k) => {
                return <div className="row mb-3" key={k}>
                  <label htmlFor="staticEmail" className="col-4 col-form-label">{MONTH_FR[k]}</label>

                  <div className="col-4">
                    <div className="d-flex justify-content-between">
                      <input className="form-check-input" type="radio" value="0" {...register(`RecurrenceTec.${m}`)} defaultChecked />
                      <input className="form-check-input" type="radio" value="1" {...register(`RecurrenceTec.${m}`)} />
                      <input className="form-check-input" type="radio" value="2" {...register(`RecurrenceTec.${m}`)} />
                      <input className="form-check-input" type="radio" value="3" {...register(`RecurrenceTec.${m}`)} />
                      <input className="form-check-input" type="radio" value="4" {...register(`RecurrenceTec.${m}`)} />
                    </div>
                  </div>

                  <div className="col-4 d-flex justify-content-center align-items-center">
                    <input type="checkbox" className="form-check-input" value={m} name={"checkTecExceptional" + m} onChange={() => HandleTecExceptional()} />
                  </div>
                </div>
              })}
            </div>

          </div>
        </div>


        <div className="text-end">
          <p><small>Les champs avec une * sont obligatoire</small></p>
          <ButtonSubmit onClick={() => onSubmit(getValues())} />
        </div>


      </section >
    </section >
  );
}