import React, { useEffect, useState } from "react";
import { getToken } from "../../../../useToken";
import { ButtonSubmit, Header, HeaderRow } from "../../../common/common";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { IAddress, IDocument } from "../../../common/types";
import { reqGet, reqPost, reqPostFile } from "../../../../service/apiRequest";

interface IFile {
    name: string,
    size: number,
    lastModified: number,
    type: string
}

const validationSchema = yup.object({
    DocumentTitle: yup.string().required("Ce champ est obligatoire"),
    DocumentDescription: yup.string().nullable()
}).required();

const initialValues = {
    DocumentTitle: "Cholet",
    DocumentDescription: null
}

function FormatDocumentName(name: string) {
    name = name.trim().replace(/\s+/g, '_');
    return name
}

export default function AddressDocumentAdd({ setReload }) {
    const token = getToken()
    const params = useParams()
    const idAddress = params.idAddress
    const navigate = useNavigate()

    const documentSizeLimit = 8388608

    const [address, setAddress] = useState<IAddress>()
    const [documents, setDocuments] = useState<Array<IDocument>>([])

    useEffect(() => {
        reqGet("address/" + idAddress).then(res => setAddress(res))
        reqGet("document/address/" + idAddress).then(res => setDocuments(res))
    }, [idAddress]);

    const [selectedFile, setSelectedFile] = useState<IFile>();

    useEffect(() => {
        if (selectedFile && selectedFile?.size > documentSizeLimit) {
            console.log(selectedFile);

        }
    }, [selectedFile])

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const { register, formState: { errors }, handleSubmit } = useForm<IDocument>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });


    const onSubmit: SubmitHandler<IDocument> = data => {

        if (selectedFile && selectedFile?.size < documentSizeLimit) {

            let formData = new FormData();
            formData.append("Document", selectedFile);
            formData.append("AddressLine", address?.AddressLine)
            formData.append("AddressID", idAddress)
            formData.append("ActivityUserID", token.UserID)
            formData.append("DocumentName", FormatDocumentName(selectedFile?.name))
            formData.append("DocumentPath", "address/" + idAddress + "/")
            formData.append("DocumentTitle", data.DocumentTitle)
            formData.append("DocumentDescription", data?.DocumentDescription)

            reqPostFile('document', formData).then((res) => {
                setReload(true)
                navigate('/address/' + idAddress + '/documnent/' + res)
            })
        }
    };

    return <div className="body-container">

        {HeaderRow(
            <>
                <h2 className="section-title">Modifier le document</h2>
                <h5>{address?.AddressLine}, {address?.ZipCode} {address?.City}</h5>
            </>,
            <Link to={"/address/" + idAddress + "/document"} className="link-nav">{"<"} Retour</Link>
        )
        }

        <section className="body">

            <div className="row justify-content-center">
                <div className="col-xl-6">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <label htmlFor="document" className="form-label">Modifier le fichier *</label>
                            <input className={"form-control mb-1 " + (selectedFile && selectedFile?.size > documentSizeLimit ? "is-invalid" : "")} type="file" id="document" multiple={false} onChange={(e) => changeHandler(e)} />

                            {
                                selectedFile &&
                                <div>
                                    <p className="mb-1"><b>Taille:</b> {(selectedFile?.size / 1000).toFixed(2)} KB</p>
                                </div>
                            }

                            {selectedFile && selectedFile?.size > documentSizeLimit && <p className="input-msg-error">La taille du fichier dépasse la limite de 8 MB</p>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="DocumentTitle" className="form-label">Titre *</label>
                            <input type="text" className={"form-control " + (errors?.DocumentTitle ? "is-invalid" : "")} {...register("DocumentTitle")} />


                            {errors.DocumentTitle?.message && <p className="input-msg-error">{errors.DocumentTitle?.message}</p>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="DocumentDescription" className="form-label">Description</label>
                            <textarea className="form-control" {...register("DocumentDescription")} rows={5} />


                            {errors.DocumentDescription?.message && <p className="input-msg-error">{errors.DocumentDescription?.message}</p>}
                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit disabled={selectedFile && selectedFile?.size > documentSizeLimit ? true : false} />
                        </div>
                    </form>

                </div>
            </div>

        </section>

    </div >
}