import React from "react";
import { ICONS } from "../../../../common/constants";
import QRCode from "react-qr-code";

export default function HelpStorageTakeOut() {

    return <>

        <header className="mb-3">
            <h2 className="title" id="takeout">Retirer pièces et outils</h2>

            <p className="paragraph">Pour retirer une pièce il faut aller dans la navigation principal dans l'onglet <a className="link-paragraph" href="/warehouse">Entrepôt</a> puis selectionner l'entrepôt dans lequel on veut retirer une pièce.</p>
        </header>

        <section className="mb-3">
            <h3 className="section-title" id="fromhome">Depuis l'accueil de l'entrepôt</h3>

            <p className="paragraph">Dans l'écran d'accueil de l'entrepôt que nous avons selectionner, il suffit d'appuyer sur le bouton <mark className="mark">Retirer/Déplacer</mark> pour retirer une pièce. Deux choix sont disponible, le premier est de scanner un <mark className="mark">QR code</mark>, le deuxième est d'entrer <mark className="mark">manuellement</mark> la référence de notre pièce.</p>


            <i className="text-danger">Image</i>

            <h4 className="section-title-2" id="qrcode">QR Code</h4>


            <p className="paragraph">A chaque emplacement physique des pièces dans l'entrpôt de stockage, se trouve une étiquette avec les informations ce celle-ci et un <mark className="mark">QR code</mark>. Pour retirer la pièce il suffit de scanner le <mark className="mark">QR code</mark> depuis l'application, cela vous proposera la pièce faisant référence ainsi que la quantité à retirer. Entrer le nombre à retirer et appuyer sur <mark className="mark">valider</mark></p>

            <i className="text-danger">Image</i>


            <div className="alert alert-primary">
                <i className={ICONS.INFO + " me-3"}></i>
                Vérifié que votre navigateur Web accepte l'utilisation de la caméra
            </div>

            <h4 className="section-title" id="manual">Manuellement (référence)</h4>

            <p className="pargraph">Il y a deux façons de chercher une pièce manuellement:</p>

            <ol type="1">
                <li>Avec la référence de la pièce</li>
                <li>Avec la référence de l'emplacement</li>
            </ol>

            <i className="text-danger">Image</i>

            <p>Entrer le nombre à retirer et appuyer sur <mark className="mark">valider</mark></p>

            <i className="text-danger">Image</i>

        </section>

        <section className="mb-3">
            <h3 className="section-title" id="fromstorage">Depuis l'emplacement de la pièce</h3>

            <p className="paragraph">Pour trouver l'emplacement d'une pièce il faut aller dans <mark className="mark">stockage</mark> </p>


            <h4 className="section-title" id="storage">Trouver emplacement</h4>


            <h4 className="section-title" id="storagetakeout">Retirer depuis l'emplacement</h4>

            <p className="paragraph">Une fois à l'emplacement de la pièce on retrouve un bouton <mark className="mark">retirer</mark> comme à l'écran d'accueil. Lorsqu'on appuie dessus on arrive sur l'écran de retrait de la pièce avec celle-ci pré-selectionner, il ne reste qua indiquer le nombre à retirer.</p>
        </section>

    </>
}