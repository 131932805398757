import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import $ from 'jquery'
import { reqGet, reqPost } from '../../../service/apiRequest'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { IAddress, IAction, IBenefit, IVisualControl } from '../../common/types'

export default function WorkbookAdd({ token }) {
    const navigate = useNavigate()

    const params = useParams()
    const idWorkbook = params.idWorkbook
    const idAddress = params.idAddress

    const idUser = token.UserID

    const [address, setAddress] = useState<IAddress>()
    const [action, setAction] = useState<Array<IAction>>([])
    const [benefit, setBenefit] = useState<Array<IBenefit>>([])
    const [visualControl, setVisualControl] = useState<Array<IVisualControl>>([])

    const [observation, setObservation] = useState({
        note: null,
        benefit: [],
        visualControl: [],
        action: [],
        workbookID: idWorkbook,
        userID: idUser
    })

    useEffect(() => {

        reqGet('address/' + idAddress).then(function (res) {
            setAddress(res)
        })

        reqGet('action').then(function (res) {

            setAction(res)
        })

        reqGet('benefit').then(function (res) {

            setBenefit(res)
        })

        reqGet('visualcontrol').then(function (res) {

            setVisualControl(res)
        })

    }, [idWorkbook])

    function handleChange() {
        var benefit = $("#benefit :checkbox").map(function () {
            if (this.checked)
                return this.value
        }).get();

        var visualcontrol = $("#visualcontrol :checkbox").map(function () {
            if (this.checked)
                return this.value
        }).get();

        var action = $("#action :checkbox").map(function () {
            if (this.checked)
                return this.value
        }).get();


        setObservation(observation => ({
            visa: $('#visa').val(),
            note: $('#note').val(),
            benefit: benefit,
            visualControl: visualcontrol,
            action: action,
            workbookID: idWorkbook,
            userID: idUser
        }))
    }

    function submit() {

        reqPost('observation', observation).then((res) => {
            navigate('/address/' + idAddress + '/workbook/' + idWorkbook)
        })


    }

    return <section className="body-container">

        <header className="container-fluid border">
            <section className="row">
                <div className="col-6 col-md-3 order-1">
                    <Link className="link-nav" to={'/address/' + idAddress + '/workbook/' + idWorkbook}>{'< Retour'}</Link>
                </div>


                <div className="col-12 col-md-10 order-md-2 order-3 mt-2 mt-md-0">
                    <h3 className="section-title mb-0">
                        {address?.AddressLine}, <br className="d-block d-md-none" /> {address?.ZipCode} {address?.City}
                    </h3>
                </div>


                <div className="col-6 col-md-2 order-2 pe-0 order-md-3 text-end">
                    <button className="btn btn-primary" onClick={() => { submit() }}>Valider</button>

                </div>

            </section>
        </header>

        <section className="body container-fluid">

            <div className="row">

                <div className="col-sm-6 col-xl-3">


                    <div className="card h-100">
                        <div className="card-header">
                            <h5 className="card-title">Remarque</h5>
                        </div>

                        <div className="card-body">

                            <textarea id="note" rows={12} onChange={() => { handleChange() }} placeholder="Ecrivez une remarque"></textarea>

                        </div>
                    </div>

                </div>

                <div className="col-sm-6 col-xl-3 px-0 ps-sm-3 px-xl-3">

                    <div className="card h-100">

                        <div className="card-header">
                            <h5 className="card-title">Prestations *</h5>
                        </div>

                        <div className="card-body">

                            <fieldset id="benefit">

                                {benefit.map((b, k) => {

                                    return <div className="form-check" key={k}>

                                        <input className="form-check-input" type="checkbox" name="benefit"
                                            id={"benefit-" + b.Benefit.replace(/\s/g, '-')}
                                            onChange={() => { handleChange() }} value={b.Benefit} />

                                        <label className="form-check-label" htmlFor={"benefit-" + b.Benefit.replace(/\s/g, '-')}>
                                            {b.Benefit}
                                        </label>

                                    </div>
                                })}
                            </fieldset>

                        </div>

                    </div>

                </div>


                <div className="col-sm-6 col-xl-3 px-0 pe-sm-3 px-xl-3">

                    <div className="card h-100">
                        <div className="card-header">
                            <h5 className="card-title">Contrôles visuels</h5>
                        </div>

                        <div className="card-body">

                            <fieldset id="visualcontrol">

                                {visualControl.map((vc, k) => {

                                    return <div className="form-check" key={k}>

                                        <input className="form-check-input" type="checkbox" name="visualcontrol"
                                            id={"cv-" + vc.VisualControl.replace(/\s/g, '-')}
                                            onChange={() => { handleChange() }} value={vc.VisualControl} />

                                        <label className="form-check-label" htmlFor={"cv-" + vc.VisualControl.replace(/\s/g, '-')}>
                                            {vc.VisualControl}
                                        </label>

                                    </div>
                                })}
                            </fieldset>

                        </div>

                    </div>

                </div>


                <div className="col-sm-6 col-xl-3 px-0 ps-sm-3">

                    <div className="card h-100">
                        <div className="card-header">
                            <h5 className="card-title">Actions *</h5>
                        </div>

                        <div className="card-body">

                            <fieldset id="action">

                                {action.map((a, k) => {

                                    return <div className="form-check" key={k}>

                                        <input className="form-check-input" type="checkbox" name="action"
                                            id={"action-" + a.Action.replace(/\s/g, '-')}
                                            onChange={() => { handleChange() }} value={a.Action} />

                                        <label className="form-check-label" htmlFor={"action-" + a.Action.replace(/\s/g, '-')}>
                                            {a.Action}
                                        </label>

                                    </div>
                                })}

                            </fieldset>

                        </div>
                    </div>

                </div>
            </div>

        </section>
    </section >
}