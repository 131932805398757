import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Header, ButtonSubmit, SelectInputSearch } from '../../../common/common'
import { IAddress, IConstruction, IMission, IMissionType, IUser } from "../../../common/types";
import { reqGet, reqPost } from "../../../../service/apiRequest";
import { getToken } from "../../../../useToken";

interface IMissionExtended extends IMission {
    MissionHours: number,
    MissionMinutes: number
}

const validationSchema = yup.object({
    MissionTypeID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    MissionDate: yup.string().required("Ce champ est obligatoire"),
    MissionStart: yup.string().nullable(),
    MissionEnd: yup.string().nullable(),
    MissionNote: yup.string().nullable(),
    MissionConstructionID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    MissionLocation: yup.string().nullable(),
    MissionHours: yup.number().min(0).max(12).transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    MissionMinutes: yup.number().min(0).max(59).transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire")
}).required();

export default function AccountMissionAdd({ setReload }) {
    const params = useParams()
    const idMission = params.idMission
    const navigate = useNavigate()
    const token = getToken()

    const [user, setUser] = useState<IUser>()
    const [missionTypes, setMissionTypes] = useState<Array<IMissionType>>([])
    const [constructions, setConstructions] = useState<Array<IConstruction>>([])
    const [address, setAddress] = useState<Array<IAddress>>([])


    const initialValues = {
        MissionID: 0,
        MissionUserID: token.UserID,
        MissionTimesheetID: null,
        MissionTypeID: 0,
        MissionDate: "",
        MissionStart: null,
        MissionEnd: null,
        MissionNbHours: null,
        MissionNote: null,
        MissionLocation: null,
        MissionAddressID: null,
        MissionConstrcutionID: null,

        MissionHours: 0,
        MissionMinutes: 0
    }

    const { register, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm<IMissionExtended>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });


    useEffect(() => {
        reqGet("missiontype").then(res => {
            setMissionTypes(res)
            setValue("MissionTypeID", res[0].MissionTypeID)
        })
        reqGet("construction").then(res => {
            setConstructions(res.filter(c => c.ConstructionArchived != 1));
        })
        reqGet("address").then(res => setAddress(res))
    }, [])

    const onSubmit: SubmitHandler<IMissionExtended> = data => {
        var d = {
            ...data,
            ActivityUserID: token.UserID,
            FirstName: token.FirstName,
            Name: token.Name,
            MissionNbHours: data.MissionHours + ":" + (data.MissionMinutes < 10 ? "0" + data.MissionMinutes : data.MissionMinutes)
        }

        reqPost('mission', d).then((res) => {
            navigate("/account/mission")
            setReload(true)
        })


    };

    return <div className="body-container">
        {Header(
            <h2 className="section-title">Ajouter une mission</h2>,
            <Link to="/account/mission" className="link-nav">{"<"} Retour</Link>
        )}

        <section className="body">

            <form onSubmit={handleSubmit(onSubmit)}>
                {/*
                <div className="mb-3">
                    <label className="form-label">Type *</label>

                    <select className={"form-select " + (errors?.MissionTypeID ? "is-invalid" : "")} {...register("MissionTypeID")}>
                        {missionTypes.map(mt => {
                            return <option key={mt.MissionTypeID} value={mt.MissionTypeID}>{mt.MissionType}</option>
                        })}
                    </select>

                    {errors.MissionTypeID?.message && <p className="input-msg-error">{errors.MissionTypeID?.message}</p>}
                </div>
                 */}

                <div className="row row-col-2 mb-3">
                    <div className="col">
                        <label className="form-label">Date *</label>

                        <input type="date" className={"form-control " + (errors?.MissionDate ? "is-invalid" : "")} {...register("MissionDate")} id="MissionDate" />

                        {errors.MissionDate?.message && <p className="input-msg-error">{errors.MissionDate?.message}</p>}
                    </div>

                    <div className="col">

                        <label htmlFor="MissionNbHours" className="form-label">Nombre d'heures *</label>
                        <div className="input-group mb-3">
                            <input type="number" className={"form-control " + (errors?.MissionHours && "is-invalid")} placeholder="heures" defaultValue={"00"} min={0} {...register("MissionHours")} />
                            <span className="input-group-text">:</span>
                            <input type="number" className={"form-control " + (errors?.MissionMinutes && "is-invalid")} placeholder="minutes" defaultValue={"00"} min={0} max={59} {...register("MissionMinutes")} />
                        </div>


                        {errors.MissionHours?.message && <p className="input-msg-error">{errors.MissionHours?.message}</p>}
                        {errors.MissionMinutes?.message && <p className="input-msg-error">{errors.MissionMinutes?.message}</p>}
                    </div>
                </div>

                {/* 
                <div className="row row-cols-2">
                    <div className="col mb-3">
                        <label className="form-label">Début *</label>
                        <input type="time" id="MissionStart" className={"form-control " + (errors?.MissionStart ? "is-invalid" : "")} {...register("MissionStart")} />

                        {errors.MissionStart?.message && <p className="input-msg-error">{errors.MissionStart?.message}</p>}
                    </div>

                    <div className="col mb-3">
                        <label className="form-label">Fin *</label>

                        <input type="time" id="MissionEnd" className={"form-control " + (errors?.MissionEnd ? "is-invalid" : "")} {...register("MissionEnd")} />

                        {errors.MissionEnd?.message && <p className="input-msg-error">{errors.MissionEnd?.message}</p>}
                    </div>
                </div>
                */}

                <hr />

                <div className="mb-3">
                    {<SelectInputSearch
                        data={constructions}
                        onChange={(e) => (setValue("MissionConstructionID", e?.ConstructionID ?? null))}
                        id={"ConstructionID"}
                        values={["ConstructionName", "ConstructionReference", "ConstructionProjectNumber"]}
                        label={"Chantier *"}
                        disabled={watch("MissionLocation") ? true : false}
                    />}
                </div>

                {/*<div className="mb-3">
                    {<SelectInputSearch data={address} onChange={(e) => console.log(e)} id={"AddressID"} value={"AddressLine"} label={"Adresse"} />}
                    </div>*/}

                <div className="mb-3">
                    <label className="form-label">Lieux *</label>
                    <input type="text" className={"form-control " + (errors?.MissionLocation ? "is-invalid" : "")} {...register("MissionLocation")} disabled={watch("MissionConstructionID") != null ? true : false} />
                </div>

                <hr />

                <div className="mb-3">
                    <label className="form-label">Remarque</label>
                    <textarea id="MissionNote" rows={5} {...register("MissionNote")}></textarea>
                </div>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <ButtonSubmit onClick={() => handleSubmit(onSubmit)} />
                </div>

            </form>

        </section>
    </div>
}