import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, Outlet, Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { GROUP, HOLIDAY_STATUS, HOLIDAY_TYPE, LAYOUT } from "../../common/constants"
import { DebouncedInput, PrivateLinkAdd, dateFormat, fuzzyFilter } from "../../common/common";
import { reqGet, reqPost } from "../../../service/apiRequest";
import { IAddress, IHoliday, IObservation, IUser, IZone } from "../../common/types"

import { useReactTable, getCoreRowModel, getFilteredRowModel, getSortedRowModel, ColumnDef, } from '@tanstack/react-table'
import { getToken } from "../../../useToken";
import { HolidayCard } from "../account/account-holiday/account-holiday";

export default function Holiday({ reload, setReload }) {
    const navigate = useNavigate()
    const token = getToken()
    const location = useLocation()
    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/holiday") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    const columns = useMemo<ColumnDef<IHoliday, any>[]>(
        () => [
            {
                accessorFn: row => row.UserID,
                id: 'UserID',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.FirstName + " " + row.Name,
                id: 'User',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.HolidayStatus,
                id: 'HolidayStatus',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.HolidayType,
                id: 'HolidayType',
                cell: info => info.getValue()
            }
        ], []
    )

    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [users, setUsers] = useState<Array<IUser>>([])
    const [data, setData] = useState<Array<IHoliday>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        reqGet("user").then(res => setUsers(res))
    }, [])

    useEffect(() => {
        if (year) {
            reqGet("holiday/year/" + year).then(res => setData(res))
        }
    }, [year])

    useEffect(() => {
        if (reload) {
            reqGet("holiday/year/" + year).then(res => setData(res))
            setReload(false)
        }
    }, [reload])


    function HandleSort(e, status?) {
        $(".btn-filter").removeClass("active")
        $(e.target).addClass("active")

        if (status)
            table?.getColumn("HolidayStatus")?.setFilterValue(status)
        else
            table.getColumn("HolidayStatus")?.setFilterValue(null)
    }

    return (
        <div className="container-fluid h-100">
            <div className="row h-100">
                <section className={display + " col-md-6 col-lg-5 col-xxl-4 col-h-fill border-right py-3"}>
                    <div className="body-container">

                        <header className="header">
                            <h2 className="section-title">Congé</h2>

                            {PrivateLinkAdd("add", GROUP.rh)}
                        </header>

                        <section className="body">

                            <nav className="row row-cols-3 mb-3">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Année</label>
                                    <input className="form-control" type="number" defaultValue={year} onChange={(e) => setYear(parseInt(e.target.value))} />
                                </div>

                                <div className="col">
                                    <label htmlFor="" className="form-label">Type</label>
                                    <select className="form-select" onChange={e => table.getColumn("HolidayType")?.setFilterValue(e.target.value)}>
                                        <option value="">Tout</option>
                                        {Object.entries(HOLIDAY_TYPE).map((t, k) => <option key={k} value={t[1]}>{t[1]}</option>)}
                                    </select>
                                </div>

                                <div className="col">
                                    <label htmlFor="" className="form-label">Collaborateur</label>
                                    <select className="form-select" onChange={e => table.getColumn("User")?.setFilterValue(e.target.value)}>
                                        <option value="">Tout</option>
                                        {users.map((u, k) => <option key={k} value={u.FirstName + " " + u.Name}>{u.FirstName} {u.Name}</option>)}
                                    </select>
                                </div>
                            </nav>

                            <nav className="toolbar w-100 mb-3">
                                <button className="btn btn-filter d-inline rounded-5 active" onClick={(e) => HandleSort(e)}>Tout</button>
                                <button className="btn btn-filter d-inline rounded-5" onClick={(e) => HandleSort(e, HOLIDAY_STATUS.ACCEPTED)}>Accepté</button>
                                <button className="btn btn-filter d-inline rounded-5" onClick={(e) => HandleSort(e, HOLIDAY_STATUS.WAITING)}>En attente</button>
                                <button className="btn btn-filter d-inline rounded-5" onClick={(e) => HandleSort(e, HOLIDAY_STATUS.REJECTED)}>Rejeté</button>
                            </nav>

                            {table && table?.getRowModel().rows.map(row =>
                                <React.Fragment key={row.original.HolidayID}>
                                    <HolidayCard holiday={row.original} showUser={true} setReload={setReload} />
                                </React.Fragment>
                            )}

                        </section>
                    </div>
                </section>


                {data.length > 0 &&
                    <div className="col col-md-6 col-lg-5 col-xxl-4 col-h-fill">

                        <Outlet context={data} />

                    </div>}
            </div>
        </div>

    );
}