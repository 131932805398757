import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { getToken } from '../../../useToken'
import { dateFormat } from '../../common/common'
import { reqGet, reqPost } from "../../../service/apiRequest";
import { ICounter, IWeek, IZone } from "../../common/types";
import { ICONS, MONTH, MONTH_FR } from "../../common/constants";

export default function CounterStatement() {
    const token = getToken();
    const idUser = token.UserID

    new Date().getFullYear()

    const [year, setYear] = useState<number>(new Date().getFullYear())

    const [monthSelected, setMonthSelected] = useState<number>(1)

    const [counters, setCounters] = useState<Array<ICounter>>([])
    const [weeks, setWeeks] = useState<Array<IWeek>>([])
    const [calendar, setCalendar] = useState<any>({})

    useEffect(() => {
        reqGet("week/year/" + year).then(res => {
            setWeeks(res)
        })
    }, [])

    useEffect(() => {
        reqGet("week/year/" + year).then(res => {
            setCalendar([])
            setWeeks(res)
        })
    }, [year])

    useEffect(() => {
        if (weeks) {
            GetCounters(MONTH[monthSelected])
        }
    }, [weeks])

    function GetCounters(month) {
        reqGet('counter/month/' + month + "/survey").then(res => {
            setCounters(res)
            console.log("Get counters:", res);
        })
    }

    function filterMonthWeeks(weeks: Array<IWeek>, monthNb: number) {
        const monthWeeks = weeks.filter(w => parseInt(w.DateStart.split('-')[1]) == monthNb || parseInt(w.DateEnd.split('-')[1]) == monthNb)

        // Check first week of month
        var week = monthWeeks[0]
        // remove first week if between two month and days majority is for the previous one
        if (parseInt(week.DateStart.split('-')[1]) != parseInt(week.DateEnd.split('-')[1]) && parseInt(week.DateEnd.split('-')[2]) < 5) {
            // remove first
            monthWeeks.shift()
        }


        // Check last week of month
        week = monthWeeks[monthWeeks.length - 1]
        // remove last week if between two month and days majority is for the next one
        if (parseInt(week.DateStart.split('-')[1]) != parseInt(week.DateEnd.split('-')[1]) && parseInt(week.DateEnd.split('-')[2]) >= 5) {
            // remove last
            monthWeeks.pop()
        }

        return monthWeeks
    }

    function CreateCalendar2(counters: Array<ICounter>) {
        setCalendar([])

        const WEEK_COUNTER_MAX = 80
        const monthWeeks: any = filterMonthWeeks(weeks, monthSelected)

        for (var i = 0; i < monthWeeks.length; i++) {
            monthWeeks[i].Counters = new Array()
        }


        counters.forEach((counter, k) => {
            const nbStatement = counter[MONTH[monthSelected]]
            const exceptional: boolean = counter?.Exceptional?.includes(MONTH[monthSelected]) ? true : false


            if (nbStatement == 4) {

                for (var i = 0; i < monthWeeks.length; i++) {
                    monthWeeks[i].Counters.push(counter)
                }
            }

            else if (nbStatement == 1) {
                if (exceptional)
                    monthWeeks[monthWeeks.length - 1].Counters.push(counter)

                // if too much counters in a week
                else if (monthWeeks[0].Counters.length >= WEEK_COUNTER_MAX)
                    monthWeeks[1].Counters.push(counter)
                else if (monthWeeks[1].Counters.length >= WEEK_COUNTER_MAX)
                    monthWeeks[2].Counters.push(counter)
                else if (monthWeeks[2].Counters.length >= WEEK_COUNTER_MAX)
                    monthWeeks[3].Counters.push(counter)
                else
                    monthWeeks[0].Counters.push(counter)
            }

            else if (nbStatement == 2) {
                if (exceptional) {
                    monthWeeks[1].Counters.push(counter)
                    monthWeeks[3].Counters.push(counter)
                }
                // if too much counters in a week
                else if (monthWeeks[0].Counters.length >= monthWeeks[1].Counters.length) {
                    monthWeeks[1].Counters.push(counter)
                    monthWeeks[3].Counters.push(counter)
                } else {
                    monthWeeks[0].Counters.push(counter)
                    monthWeeks[2].Counters.push(counter)
                }
            }

            else if (nbStatement == 3) {
                // if too much counters in a week
                if (monthWeeks[0].Counters.length >= WEEK_COUNTER_MAX) {
                    monthWeeks[1].Counters.push(counter)
                    monthWeeks[2].Counters.push(counter)
                    monthWeeks[3].Counters.push(counter)
                } else {
                    monthWeeks[0].Counters.push(counter)
                    monthWeeks[1].Counters.push(counter)
                    monthWeeks[3].Counters.push(counter)
                }
            }

        })

        setCalendar({
            UserID: idUser,
            Month: MONTH[monthSelected],
            MonthNB: monthSelected,
            MonthWeeks: monthWeeks
        })
    }


    function ValidateCalendar() {
        console.log("Calendar:", calendar);


        reqPost("counterstatement/month", calendar).then(() => {
            console.log("Sent");
            setCalendar([])

        })

    }

    return <div className="body-container content">


        <div className="header">
            <h3 className="section-title">Générer calendrier surveillance</h3>
            <div className="toolbar">
                <select name="" id="" className="form-select d-inline-block w-auto me-3" onChange={(e) => setMonthSelected(parseInt(e.target.value))}>
                    {MONTH_FR.map((month, i) => {
                        return <option key={month} value={i + 1}>{month}</option>
                    })}
                </select>

                <input type="number" className="form-control d-inline-block me-3 w-auto" defaultValue={year || 2024} onChange={(e) => setYear(parseInt(e.target.value))} />

                {counters && <button className="btn btn-primary" onClick={() => CreateCalendar2(counters)}><i className="bi bi-recycle me-2"></i>Générer calendrier</button>}

                {<button className="btn btn-delete" disabled={calendar?.MonthWeeks?.length > 0 ? false : true} onClick={() => setCalendar([])}><i className={ICONS.DELETE + " me-2"}></i>Reset</button>}

                {<button className="btn btn-add" disabled={calendar?.MonthWeeks?.length > 0 ? false : true} onClick={() => ValidateCalendar()}><i className={ICONS.SUCCESS + " me-2"}></i>Valider calendrier</button>}
            </div>
        </div>

        <div className="body">
            <div className="row row-cols-4 mb-5">
                {calendar?.MonthWeeks?.length > 0 &&
                    <h4 className="section-title-2 w-100 mb-3" id={"#" + MONTH[monthSelected]}>{MONTH_FR[parseInt(calendar?.MonthWeeks[0].DateEnd.split('-')[1]) - 1]} {calendar?.MonthWeeks[0].DateEnd.split('-')[0]} <small>({counters.length} compteurs)</small></h4>
                }

                {calendar?.MonthWeeks?.length > 0 && calendar?.MonthWeeks?.map((week, key) => {
                    //console.log(monthWeek);

                    return <div key={week.WeekID} className="col border">
                        <h5>Semaine {week.WeekNumber} <small>({week.Counters.length})</small></h5>
                        <h6>{dateFormat(week.DateStart, "dd/MM/yyyy")} - {dateFormat(week.DateEnd, "dd/MM/yyyy")}</h6>


                        {week.Counters.map((counter, k) => {

                            if (k > 0 && week.Counters[k - 1].AddressLine == counter.AddressLine) {

                                return <React.Fragment key={counter.CounterID}>
                                    <p className="mb-0">{counter.Label} ({counter[MONTH[key]]})
                                        {(week.length - 1 == key && counter?.Exceptional?.includes(MONTH[key])) ?
                                            <span className="badge bg-primary rounded-5">E</span> :
                                            <></>
                                        }
                                    </p>
                                </React.Fragment>
                            }
                            else {
                                return <React.Fragment key={counter.CounterID}>
                                    <h6 className="mt-3 mb-1">{counter.AddressLine}</h6>
                                    <p className="mb-0">{counter.Label}, ({counter[MONTH[key]]})
                                        {(week.length - 1 == key && counter?.Exceptional?.includes(MONTH[key])) ?
                                            <span className="badge bg-primary rounded-5">E</span> :
                                            <></>
                                        }
                                    </p>
                                </React.Fragment>
                            }
                        })}


                    </div>
                })}
            </div>



        </div>

    </div>
}