import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useOutletContext } from "react-router-dom";
import $ from 'jquery'

import { ICONS, LAYOUT, TIMESHEET } from './../../../common/constants';
import { DateCompare, HandleDisplay, Header, PrivateComponent, PrivateLinkAdd, ResolveIcon, TimeCalculation, dateFormat, DateToday, ButtonModal } from "../../../common/common";
import { IMission, IUser, IWeek } from "../../../common/types";
import { reqDelete, reqGet, reqPost, reqPut } from "../../../../service/apiRequest";
import { getToken } from "../../../../useToken";

interface IMissionConstructionAddress extends IMission {
    AddressLine: string | null,
    AddressZipCode: string | null,
    AddressCity: string | null,
    ConstructionAddress: string | null,
    ConstructionZipCode: string | null,
    ConstructionCity: string | null
}

export default function AccountMission({ reload, setReload }) {
    const location = useLocation()

    const [display, setDisplay] = useState<string>("")
    const [display2, setDisplay2] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {
        if ($(window).width() >= LAYOUT.xl) {
            setDisplay('d-flex')
            setDisplay2('d-flex')
        } else {
            if (location.pathname == "/account/mission") {
                setDisplay('d-flex')
                setDisplay2('d-none')
            } else {
                setDisplay('d-none')
                setDisplay2('d-flex')
            }
        }
    }

    const user = useOutletContext<IUser>()

    if (user)
        return <div className="container-fluid h-100">
            <div className="row h-100">

                <div className={display + " col-xl-6 col-h-fill px-0"}>
                    <div className="body-container content">

                        {Header(
                            <h2 className="section-title">Mission</h2>,
                            <Link to="/account" className="link-nav">{"<"} Retour</Link>,
                            PrivateLinkAdd("add")
                        )}

                        <section className="body">

                            <MissionsDate user={user} reload={reload} setReload={setReload} />

                        </section>

                    </div>
                </div>

                <div className={display2 + " col-xl-6 col-h-fill px-0"}>
                    <Outlet />
                </div>

            </div>
        </div>

    else return <></>
}



function MissionsDate({ user, reload, setReload }: { user: IUser, reload: boolean, setReload }) {
    const token = getToken()
    const [missions, setMissions] = useState<Array<IMissionConstructionAddress>>([])
    const [date, setDate] = useState<string>(DateToday())

    useEffect(() => {
        if (date) {
            reqGet("mission/user/" + user.UserID + "/date/" + date).then(res => {
                setMissions(res);
            })
        }
    }, [date])

    useEffect(() => {
        if (date && reload) {
            reqGet("mission/user/" + user.UserID + "/date/" + date).then(res => {
                setMissions(res);
            })
            setReload(false)
        }
    }, [reload])

    function HandleDateChange(date) {
        setDate(date)
    }

    function HandleNextDate() {
        var actualDate = new Date(date)
        var nextDate = new Date(actualDate.getTime() + (24 * 60 * 60 * 1000))

        var month = nextDate.getMonth() < 9 ? ("0" + (nextDate.getMonth() + 1)) : nextDate.getMonth()
        var day = nextDate.getDate() < 10 ? ("0" + (nextDate.getDate())) : nextDate.getDate()

        setDate(nextDate.getFullYear() + "-" + month + "-" + day)
    }

    function HandlePreviousDate() {
        var actualDate = new Date(date)
        var previousDate = new Date(actualDate.getTime() - (24 * 60 * 60 * 1000))

        var month = previousDate.getMonth() < 9 ? ("0" + (previousDate.getMonth() + 1)) : previousDate.getMonth()
        var day = previousDate.getDate() < 10 ? ("0" + (previousDate.getDate())) : previousDate.getDate()

        setDate(previousDate.getFullYear() + "-" + month + "-" + day)
    }

    function RemoveMission(mission) {
        reqDelete("mission/" + mission.MissionID, { data: { ActivityUserID: token.UserID, ...mission } })

        setReload(true)
    }


    return <>
        <nav className="text-center mb-3">

            <button className="btn btn-outline d-inline rounded" onClick={() => HandlePreviousDate()} >
                <i className="bi bi-caret-left"></i>
            </button>

            <input type="date" className="form-control mx-3 d-inline w-auto" id="inputDate" onChange={(e) => HandleDateChange(e.target.value)} value={date} />

            <button className="btn btn-outline d-inline rounded" id="btnNext" onClick={() => HandleNextDate()} >
                <i className="bi bi-caret-right"></i>
            </button>

        </nav>


        {missions?.map(m => {

            return <section className="card" key={m.MissionID}>

                <div className="modal" id={"removeMission" + m.MissionID} tabIndex={-1} aria-labelledby={"removeMission" + m.MissionID} aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <header className="modal-header">
                                <h1 className="modal-title fs-5" id={"removeMission" + m.MissionID}>Supprimer mission</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </header>

                            <section className="modal-body">
                                <p>Etes-vous sûr de vouloir supprimer cette mission ?</p>
                                <b>
                                    {m.MissionLocation && m.MissionLocation}
                                    {m.MissionConstructionID && <>{m.ConstructionAddress}, {m.ConstructionZipCode} {m.ConstructionCity}</>}
                                    {m.MissionAddressID && <>{m.AddressLine}, {m.AddressZipCode} {m.AddressCity}</>}
                                </b>
                            </section>

                            <footer className="modal-footer">
                                <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                                    <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                                </button>

                                <button type="button" className={"btn btn-delete"} data-bs-dismiss="modal" onClick={() => RemoveMission(m)}>
                                    <i className={ICONS.DELETE + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Supprimer</span>
                                </button>
                            </footer>
                        </div>
                    </div>
                </div>
                <header>
                    <div className="d-flex justify-content-between mb-2">
                        <p className="mb-2"><i className={ResolveIcon(m?.MissionType ?? "") + " me-2"}></i>{m.MissionType}</p>

                        <div className="dropdown">
                            <button className="btn btn-sm btn-outline border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                            <ul className="dropdown-menu">
                                <li>
                                    <Link to={m.MissionID + "/update"} className="dropdown-item px-2 py-1 mb-2"><i className={ICONS.UPDATE + " me-2"}></i>Modifier</Link>
                                </li>
                                <li>
                                    <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target={"#removeMission" + m.MissionID}>
                                        <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between">
                        {m.MissionLocation && <h5 className="card-title">{m.MissionLocation}</h5>}
                        {m.MissionConstructionID && <h5 className="card-title">{m.ConstructionAddress}, {m.ConstructionZipCode} {m.ConstructionCity}</h5>}
                        {m.MissionAddressID && <h5 className="card-title">{m.AddressLine}, {m.AddressZipCode} {m.AddressCity}</h5>}

                        {m.MissionNbHours ?
                            <h5 className="card-title">{m.MissionNbHours.slice(0, -3)}</h5> :
                            <h5 className="card-title">{m.MissionStart?.slice(0, -3)} - {m.MissionEnd?.slice(0, -3)} ({(m?.MissionStart && m?.MissionEnd) && TimeCalculation(m?.MissionStart, m?.MissionEnd)})</h5>
                        }
                    </div>
                </header>

                {m.MissionNote &&
                    <section className="card-body">
                        <p className="paragraph">{m.MissionNote}</p>
                    </section>
                }

            </section>
        })}

    </>
}