import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const STYLES = {
    header1: {
        fontSize: 16,
        bold: true,
        lineHeight: 1.3
    },
    header2: {
        fontSize: 14,
        fontWeight: 600
    },
    bold: {
        fontSize: 14,
        bold: true,
        lineHeight: 1.3
    },
    list: {
        lineHeight: 1.5
    }
}


export default function PDF(pdf, form: JSX.Element) {
    const navigate = useNavigate()

    const back = <Link to={"/address/" + 6018} className="link-nav">{'<'} Retour</Link>
    const filename = ""

    useEffect(() => {
        updatePDF()
    }, [])

    function updatePDF() {
        const PDF = pdfMake.createPdf(pdf)

        const iFrame: HTMLElement | null = document.getElementById('printPdf')

        var callback = function (url) { iFrame?.setAttribute('src', url); }
        PDF.getDataUrl(callback, PDF);
    }

    function openPdf() {
        pdfMake.createPdf(pdf).open()
    }

    function printPdf() {
        pdfMake.createPdf(pdf).print()
    }

    function downloadPdf() {
        pdfMake.createPdf(pdf).download(filename + ".pdf")
    }

    return <div className="container-fluid h-100">
        <div className="row h-100">

            <div className="col-xl-6 h-100 overflow-auto">

                {back}
                <h2 className="section-title">Rapport technique</h2>

                <div className="d-inline-flex justify-content-between w-100 mb-3">
                    <h5 className="section-title2">Paramètres</h5>

                    <div className="toolbar">

                        <button className="btn btn-outline me-1" onClick={() => printPdf()}>
                            <i className="bi bi-printer"></i>
                        </button>

                        <button className="btn btn-outline me-1" onClick={() => downloadPdf()}>
                            <i className="bi bi-download"></i>
                        </button>

                        <button className="btn btn-outline" onClick={() => openPdf()}>
                            <i className="bi bi-box-arrow-up-right"></i>
                        </button>
                    </div>
                </div>

                <p className="fst-italic">
                    <small>
                        <i className="bi bi-exclamation-circle me-2"></i>
                        Seul les informations du pdf sont mises à jour depuis ici, les données de la base technique ne sont pas modifiée. Toutes modifications des données de la base technique ne doivent pas être faites ici.
                    </small>
                </p>


                <div className="card mb-5">
                    <div className="card-body">

                        <label htmlFor="filename" className="form-label">Nom du fichier</label>
                        <div className="input-group">
                            <input className="form-control" type="text" onChange={(e) => { console.log(e.target) }} />
                            <span className="input-group-text">.pdf</span>
                        </div>

                    </div>
                </div>


                <h5 className="section-title2 mb-3">Données du document</h5>

                {form}

            </div>

            <div className="col-xl-6 h-100 d-flex flex-column d-none d-xl-flex">

                <div className="d-inline-flex justify-content-center">
                    <button className="btn btn-primary mb-3" onClick={() => updatePDF()}>
                        <i className="bi bi-arrow-clockwise"></i> Mettre à jour
                    </button>
                </div>

                <iframe id="printPdf" name="printPdf" className="d-flex flex-grow-1 rounded w-100 border"></iframe>
            </div>

        </div>


    </div>
}


