import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../useToken'
import { reqGet, reqPost } from '../../../service/apiRequest'
import { HeaderRow, ButtonSubmit } from "../../common/common"
import { IClient, IZipCode } from "../../common/types"

const validationSchema = yup.object({
    ClientName: yup.string().required("Ce champ est obligatoire"),
    ClientType: yup.string().required("Ce champ est obligatoire"),
    ClientAddress: yup.string().nullable(),
    ZipCodeID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ClientEmail: yup.string().nullable(),
    ClientPhone: yup.string().nullable(),
    ClientFax: yup.string().nullable(),
    ClientWebsite: yup.string().nullable(),
    ClientEmailMazout: yup.string().nullable(),
    ClientNote: yup.string().nullable()
})

const initialValues = {
    ClientName: "",
    ClientType: "",
    ClientAddress: null,
    ZipCodeID: null,
    ClientEmail: null,
    ClientPhone: null,
    ClientFax: null,
    ClientWebsite: null,
    ClientEmailMazout: null,
    ActivityUserID: null,
    ClientNote: null
}

export default function ClientAdd({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()

    const [zipCode, setZipCode] = useState<Array<IZipCode>>([])

    const { register, formState: { errors }, handleSubmit, setValue, watch } = useForm<IClient>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    })

    useEffect(() => {
        reqGet("zipcode").then(res => { setZipCode(res) })
    }, [])

    const onSubmit: SubmitHandler<IClient> = data => {

        reqPost('client', { ...data, ActivityUserID: token.UserID }).then((res) => {
            if (data.ClientType == "Management")
                navigate("/client/management/" + res)
            else if (data.ClientType == "Owner")
                navigate("/client/management/" + res)
            else
                navigate("/client")
            setReload(true)
        })
    };

    return <div className="body-container content">

        {HeaderRow(
            <h3 className="section-title">Ajouter un client</h3>,
            <Link to="/client" className="link-nav">{"<"} Retour</Link>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="ClientType" className="form-label">Type *</label>
                            <select className='form-select' {...register("ClientType")} >
                                <option value="" hidden></option>
                                <option value="Management">Régie</option>
                                <option value="Owner">Propriétaire</option>
                            </select>

                            {errors.ClientType?.message && <p className="mt-2 text-danger">{errors.ClientType?.message}</p>}
                        </div>


                        {watch("ClientType") != "" && <>
                            <div className="mb-3">

                                <label htmlFor="ClientName" className="form-label">Nom *</label>
                                <input type="text" className={"form-control " + (errors?.ClientName ? "is-invalid" : "")} {...register("ClientName")} />

                                {errors.ClientName?.message && <p className="mt-2 text-danger">{errors.ClientName?.message}</p>}

                            </div>

                            <div className="row mb-3">

                                <div className="col-6">
                                    <label htmlFor="ClientAddress" className="form-label">Adresse</label>
                                    <input type="text" className={"form-control " + (errors?.ClientAddress ? "is-invalid" : "")} {...register("ClientAddress")} />

                                    {errors.ClientAddress?.message && <p className="mt-2 text-danger">{errors.ClientAddress?.message}</p>}
                                </div>

                                <div className="col-6">
                                    <label htmlFor="ZipCodeID" className="form-label">Code postal</label>
                                    <select className='form-select' {...register("ZipCodeID")} >
                                        <option value=""></option>
                                        {zipCode.map(z => {
                                            return <option key={z.ZipCodeID} value={z.ZipCodeID}>{z.ZipCode} {z.City}</option>
                                        })}
                                    </select>

                                    {errors.ZipCodeID?.message && <p className="mt-2 text-danger">{errors.ZipCodeID?.message}</p>}
                                </div>

                            </div>

                            <div className="row mb-3">

                                <div className="col-6">

                                    <label htmlFor="ClientEmail" className="form-label">Email</label>
                                    <input type="text" className={"form-control " + (errors?.ClientEmail ? "is-invalid" : "")} {...register("ClientEmail")} />

                                    {errors.ClientEmail?.message && <p className="mt-2 text-danger">{errors.ClientEmail?.message}</p>}

                                </div>

                                <div className="col-6">
                                    <label htmlFor="ClientPhone" className="form-label">Téléphone</label>
                                    <input type="text" className={"form-control " + (errors?.ClientPhone ? "is-invalid" : "")} {...register("ClientPhone")} />

                                    {errors.ClientPhone?.message && <p className="mt-2 text-danger">{errors.ClientPhone?.message}</p>}
                                </div>

                            </div>

                            {watch("ClientType") == "Management" && <>

                                <div className="row mb-3">
                                    <div className="col-6">
                                        <label htmlFor="ClientEmailMazout" className="form-label">Email mazout</label>
                                        <input type="text" className={"form-control " + (errors?.ClientEmailMazout ? "is-invalid" : "")} {...register("ClientEmailMazout")} />

                                        {errors.ClientEmailMazout?.message && <p className="mt-2 text-danger">{errors.ClientEmailMazout?.message}</p>}

                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="ClientFax" className="form-label">Fax</label>
                                        <input type="text" className={"form-control " + (errors?.ClientFax ? "is-invalid" : "")} {...register("ClientFax")} />

                                        {errors.ClientFax?.message && <p className="mt-2 text-danger">{errors.ClientFax?.message}</p>}
                                    </div>


                                </div>

                                <div className="mb-3">

                                    <label htmlFor="ClientWebsite" className="form-label">Site internet</label>
                                    <input type="text" className={"form-control " + (errors?.ClientWebsite ? "is-invalid" : "")} {...register("ClientWebsite")} />

                                    {errors.ClientWebsite?.message && <p className="mt-2 text-danger">{errors.ClientWebsite?.message}</p>}

                                </div>
                            </>
                            }

                            <div className="mb-3">

                                <label htmlFor="ClientNote" className="form-label">Remarques</label>
                                <textarea className={(errors?.ClientNote ? "is-invalid" : "")} id="ClientNote" rows={4} {...register("ClientNote")}></textarea>

                                {errors.ClientNote?.message && <p className="mt-2 text-danger">{errors.ClientNote?.message}</p>}

                            </div>

                            <div className="text-end">
                                <p><small>Les champs avec une * sont obligatoire</small></p>
                                <ButtonSubmit />
                            </div>
                        </>
                        }

                    </form>

                </div>

            </div>
        </div>
    </div>
}