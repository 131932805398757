import React, { useState, useEffect } from 'react'
import { Routes, Route, Link, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { reqGet, reqPut } from '../../../service/apiRequest'
import { getToken } from '../../../useToken'
import { IMaterial, IMaterialClass } from '../../common/types'
import { ButtonSubmit, HeaderRow } from '../../common/common'
import { MATERIAL_CLASS_ID } from '../../common/constants';

interface IFormInput extends IMaterial {
    ActivityUserID: number | null
}

const validationSchema = yup.object({
    MaterialClassID: yup.number().required("Ce champ est obligatoire"),
    Type: yup.string().required("Ce champ est obligatoire"),
    Brand: yup.string().nullable(),
    Model: yup.string().nullable(),
    ICF: yup.string().nullable(),
    Reference: yup.string().nullable(),
    PowerRange: yup.string().nullable(),
    Capacity: yup.string().nullable(),
    Surface: yup.string().nullable(),
    Longueur: yup.string().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    Alimentation: yup.string().nullable(),
    MaterialYearProduction: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    MaterialYearEndProduction: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()
})

const initialValues = {
    MaterialClassID: 1,
    Type: "",
    Brand: "",
    Model: "",
    ICF: "",
    Reference: "",
    PowerRange: "",
    Capacity: null,
    Surface: "",
    Longueur: null,
    Alimentation: "",
    ActivityUserID: null,
    MaterialYearProduction: null,
    MaterialYearEndProduction: null
}

export default function MaterialUpdate({ setReload }) {
    let navigate = useNavigate()
    const params = useParams()
    const idMaterial = params.idMaterial

    const token = getToken()

    const { register, formState: { errors }, handleSubmit, watch, setValue } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [materialClass, setMaterialClass] = useState<Array<IMaterialClass>>([])

    useEffect(() => {
        setValue("ActivityUserID", token.UserID)

        reqGet("materialclass").then(function (res) {

            setMaterialClass(res)

            reqGet("material/" + idMaterial).then(function (r) {

                setValue("MaterialClassID", r.MaterialClassID)
                setValue("Type", r.Type ?? "")
                setValue("Brand", r.Brand ?? "")
                setValue("Model", r.Model ?? "")
                setValue("ICF", r.ICF ?? "")
                setValue("Reference", r.Reference ?? "")
                setValue("PowerRange", r.PowerRange ?? "")
                setValue("Capacity", r.Capacity ?? "")
                setValue("Surface", r.Surface ?? "")
                setValue("Longueur", r.Longueur)
                setValue("Alimentation", r.Alimentation ?? "")
                setValue("MaterialYearProduction", r.MaterialYearProduction ?? "")
                setValue("MaterialYearEndProduction", r.MaterialYearEndProduction ?? "")
            })


        })

    }, [])

    const onSubmit: SubmitHandler<IFormInput> = data => {

        reqPut('material/' + idMaterial, data).then((res) => {
            setReload(true)
            navigate("/material/" + idMaterial)
        })

    };

    return <div className="body-container content">

        {HeaderRow(
            <h3 className="section-title mb-1">Modifier matériel</h3>,
            <Link to={"/material/" + idMaterial} className="link-nav">{'< Retour'}</Link>
        )}

        <div className="body">

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="row">

                    <div className="col-6">
                        <div className="mb-3">
                            <label htmlFor="inputClass" className="form-label">Catégorie *</label>
                            <select className={"form-select " + (errors?.MaterialClassID ? "is-invalid" : "")} {...register("MaterialClassID")}>

                                {materialClass.map((m, k) => {
                                    return <option key={k} value={m.MaterialClassID}>{m.Class}</option>;
                                })}
                            </select>

                            {errors.MaterialClassID?.message && <p className="mt-2 text-danger">{errors.MaterialClassID?.message}</p>}

                        </div>
                    </div>

                    <div className="col-6">
                        <div className="mb-3">

                            <label htmlFor="Type" className="form-label">Type *</label>
                            <input className={"form-control " + (errors?.Type ? "is-invalid" : "")} {...register("Type")} />

                            {errors.Type?.message && <p className="mt-2 text-danger">{errors.Type?.message}</p>}

                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">

                    <div className="col-6">

                        <div className="mb-3">

                            <label htmlFor="Brand" className="form-label">Marque</label>
                            <input className={"form-control " + (errors?.Brand ? "is-invalid" : "")} {...register("Brand")} />

                            {errors.Brand?.message && <p className="mt-2 text-danger">{errors.Brand?.message}</p>}

                        </div>
                    </div>

                    <div className="col-6">
                        <div className="mb-3">

                            <label htmlFor="Model" className="form-label">Modèle</label>
                            <input className={"form-control " + (errors?.Model ? "is-invalid" : "")} {...register("Model")} />

                            {errors.Model?.message && <p className="mt-2 text-danger">{errors.Model?.message}</p>}

                        </div>
                    </div>

                    <div className="col-6">
                        <div className="mb-3">

                            <label htmlFor="MaterialYearProduction" className="form-label">Année production</label>
                            <input type="number" min={1950} className={"form-control " + (errors?.MaterialYearProduction ? "is-invalid" : "")} {...register("MaterialYearProduction")} />

                            {errors.MaterialYearProduction?.message && <p className="mt-2 text-danger">{errors.MaterialYearProduction?.message}</p>}

                        </div>
                    </div>

                    <div className="col-6">
                        <div className="mb-3">

                            <label htmlFor="MaterialYearEndProduction" className="form-label">Année fin de production</label>
                            <input type="number" min={1950} className={"form-control " + (errors?.MaterialYearEndProduction ? "is-invalid" : "")} {...register("MaterialYearEndProduction")} />

                            {errors.MaterialYearEndProduction?.message && <p className="mt-2 text-danger">{errors.MaterialYearEndProduction?.message}</p>}

                        </div>
                    </div>

                    <div className="col-6">
                        <div className="mb-3">

                            <label htmlFor="Reference" className="form-label">Rérérence</label>
                            <input className={"form-control " + (errors?.Reference ? "is-invalid" : "")} {...register("Reference")} />

                            {errors.Reference?.message && <p className="mt-2 text-danger">{errors.Reference?.message}</p>}

                        </div>
                    </div>

                    {(watch("MaterialClassID") == MATERIAL_CLASS_ID.BURNER ||
                        watch("MaterialClassID") == MATERIAL_CLASS_ID.GENERATOR) &&
                        <>
                            <div className="col-6">
                                <div className="mb-3">

                                    <label htmlFor="PowerRange" className="form-label">Plage de puissance</label>
                                    <input className={"form-control " + (errors?.PowerRange ? "is-invalid" : "")} {...register("PowerRange")} />

                                    {errors.PowerRange?.message && <p className="mt-2 text-danger">{errors.PowerRange?.message}</p>}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-3">

                                    <label htmlFor="ICF" className="form-label">ICF</label>
                                    <input className={"form-control " + (errors?.ICF ? "is-invalid" : "")} {...register("ICF")} />

                                    {errors.ICF?.message && <p className="mt-2 text-danger">{errors.ICF?.message}</p>}
                                </div>
                            </div>
                        </>
                    }

                    {(watch("MaterialClassID") == MATERIAL_CLASS_ID.BOILER ||
                        watch("MaterialClassID") == MATERIAL_CLASS_ID.TANK ||
                        watch("MaterialClassID") == MATERIAL_CLASS_ID.EXPANSION) &&
                        <div className="col-6">

                            <div className="mb-3">

                                <label htmlFor="Capacity" className="form-label">Capacité <span className="text-muted fw-normal fst-italic">(litres)</span></label>
                                <input className={"form-control " + (errors?.Capacity ? "is-invalid" : "")} {...register("Capacity")} />

                                {errors.Capacity?.message && <p className="mt-2 text-danger">{errors.Capacity?.message}</p>}

                            </div>
                        </div>
                    }

                    {watch("MaterialClassID") == MATERIAL_CLASS_ID.SOLAR &&
                        <div className="col-6">

                            <div className="mb-3">

                                <label htmlFor="Surface" className="form-label">Surface</label>
                                <input className={"form-control " + (errors?.Surface ? "is-invalid" : "")} {...register("Surface")} />

                                {errors.Surface?.message && <p className="mt-2 text-danger">{errors.Surface?.message}</p>}

                            </div>
                        </div>
                    }


                    {watch("MaterialClassID") == MATERIAL_CLASS_ID.PUMP &&
                        <>
                            <div className="col-6">

                                <div className="mb-3">

                                    <label htmlFor="Alimentation" className="form-label">Alimentation</label>
                                    <input className={"form-control " + (errors?.Alimentation ? "is-invalid" : "")} {...register("Alimentation")} />

                                    {errors.Alimentation?.message && <p className="mt-2 text-danger">{errors.Alimentation?.message}</p>}

                                </div>
                            </div>

                            <div className="col-6">

                                <div className="mb-3">

                                    <label htmlFor="Longueur" className="form-label">Longueur</label>
                                    <input type="number" className={"form-control " + (errors?.Longueur ? "is-invalid" : "")} {...register("Longueur")} />

                                    {errors.Longueur?.message && <p className="mt-2 text-danger">{errors.Longueur?.message}</p>}

                                </div>
                            </div>
                        </>
                    }

                </div>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <ButtonSubmit />
                </div>

            </form>

        </div>
    </div>
}