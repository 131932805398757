import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import $ from 'jquery'

import { getToken } from '../../../useToken'
import { reqPut } from "../../../service/apiRequest"
import { HashPassword } from '../../common/hash'
import { ButtonSubmit, HeaderRow } from '../../common/common'


interface IFormInput {
    oldPassword: string,
    newPassword: String,
    newPassword2: String,
}

const validationSchema = yup.object({
    oldPassword: yup.string().required("Ce champ est obligatoire"),
    newPassword: yup.string().required("Ce champ est obligatoire"),
    newPassword2: yup.string().required('Ce champ est obligatoire').oneOf([yup.ref('newPassword')], 'Le mot de passe ne correspond pas'),
})

const initialValues = {
    oldPassword: '',
    newPassword: '',
    newPassword2: '',
}


function TogglePassword() {
    var input = $(".inputPassword");
    if (input[0].type === "password") {
        input[0].type = "text";
        input[1].type = "text";
        input[2].type = "text";
    } else {
        input[0].type = "password";
        input[1].type = "password";
        input[2].type = "text";
    }
}

export default function PasswordUpdate() {
    const navigate = useNavigate()

    const userID = getToken().UserID

    const { register, formState: { errors }, handleSubmit, setValue, watch } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [error, setError] = useState("")
    const [checkPassword, setCheckPassword] = useState<boolean | null>(null)

    const onSubmit: SubmitHandler<IFormInput> = data => {
        data.newPassword = HashPassword(data.newPassword)

        reqPut('account/updatepassword/' + userID, data).then((res) => {

            navigate('/account/data')

        }, (rej) => {
            setError("Le mot de passe n'est pas correct")
        })

    }

    return <div className="row">
        <section className="body-container">

            {HeaderRow(
                <h3 className="section-title mb-0">Modifier votre mot de passe</h3>,
                <button onClick={() => navigate(-1)} className="btn link-nav ps-0">{'<'} Retour</button>
            )}

            <section className="body container-fluid">

                <div className="row justify-content-center">

                    <div className="col-xl-6">

                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="mb-3">
                                <label htmlFor="oldPassword" className="form-label">Ancien mot de passe *</label>
                                <input type="password" id="oldPassword" className={"inputPassword form-control " + (errors?.oldPassword ? "is-invalid" : "")} {...register("oldPassword")} />

                                {checkPassword ? <p className="text-success">Le mot de passe correspond</p> : ""}

                                {errors.oldPassword?.message && <p className="input-msg-error">{errors.oldPassword?.message}</p>}

                            </div>

                            {error && <Alert Error={error}></Alert>}

                            <hr />

                            <div className="mb-3">
                                <label htmlFor="newPassword" className="form-label">Nouveau mot de passe *</label>
                                <input type="password" className={"inputPassword form-control " + (errors?.newPassword ? "is-invalid" : "")} {...register("newPassword")} />

                                {errors.newPassword?.message && <p className="input-msg-error">{errors.newPassword?.message}</p>}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="newPassword2" className="form-label">Confirmer mot de passe *</label>
                                <input type="password" className={"inputPassword form-control " + (errors?.newPassword2 ? "is-invalid" : "")} {...register("newPassword2")} />

                                {errors.newPassword2?.message && <p className="input-msg-error">{errors.newPassword2?.message}</p>}
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onClick={() => TogglePassword()} />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Afficher mot de passe
                                </label>
                            </div>

                            <div className="text-end">
                                <p><small>Les champs avec une * sont obligatoire</small></p>
                                <ButtonSubmit />
                            </div>

                        </form>

                    </div>

                </div>

            </section>

        </section>
    </div>
}


function Alert({ Error }) {
    return <div className="alert alert-danger">
        {Error}
    </div>
}