import React, { useEffect, useState } from 'react'
import { Routes, Outlet, NavLink, Link, useParams } from 'react-router-dom'
import { IConstruction } from '../../common/types'
import { reqGet } from '../../../service/apiRequest'
import { Header, PrivateComponent, PrivateLinkUpdate, dateFormat } from '../../common/common'
import { BADGE, GROUP } from '../../common/constants'

export default function ConstructionContent() {
    const params = useParams()
    const idConstruction = params.idConstruction

    const [construction, setConstruction] = useState<IConstruction>()

    useEffect(() => {
        reqGet("construction/" + idConstruction).then(res => {
            setConstruction(res)
        })
    }, [idConstruction])

    return <div className="body-container content">

        <header className="header">
            <div className="row">
                <div className="col-6 col-xxl-3 order-1">
                    <h2 className="section-title mb-1">{construction?.ConstructionName}</h2>
                    <h5 className="section-subtitle text-truncate mb-1">{construction?.ConstructionAddress}, {construction?.ZipCode} {construction?.City}</h5>
                    <h2 className="section-subtitle mb-1">{construction?.ConstructionAddressComplementary}</h2>
                    <Link to="/construction" className="link-nav">{"<"} Retour</Link>
                </div>
                <div className="col-xxl-6 text-center order-3 order-xxl-2">
                    <nav className="nav-horizontal justify-content-md-center pt-4 pt-md-0 mb-0">
                        <NavLink className='nav-link' to="">Accueil</NavLink>
                        {PrivateComponent(
                            <NavLink className='nav-link' to="data">Données {BADGE.DEV}</NavLink>,
                            GROUP.dev
                        )}
                        {PrivateComponent(
                            <NavLink className='nav-link' to="analytic">Analytique {BADGE.DEV}</NavLink>,
                            GROUP.dev
                        )}
                        {PrivateComponent(
                            <NavLink className='nav-link' to="documents">Documents {BADGE.DEV}</NavLink>,
                            GROUP.dev
                        )}
                        {PrivateComponent(
                            <NavLink className='nav-link' to="pv">PV {BADGE.DEV}</NavLink>,
                            GROUP.dev
                        )}
                        {PrivateComponent(
                            <NavLink className='nav-link' to="gallery">Gallerie {BADGE.DEV}</NavLink>,
                            GROUP.dev
                        )}
                    </nav>
                </div>

                <div className="col-6 col-xxl-3 text-end order-2 order-xxl-3">
                </div>
            </div>
        </header>

        <section className='body container-fluid'>
            <div className='row h-100'>
                <div className='col-2 px-0'>

                    <header className='d-flex justify-content-between mb-3'>
                        <h3 className='section-title-2'>Données</h3>

                        <div>
                            {PrivateLinkUpdate("update", GROUP.crud_sav)}
                        </div>
                    </header>

                    <div className='card'>
                        <section className='card-body'>

                            <h6>Responsable</h6>
                            <p>{construction?.FirstName} {construction?.Name}</p>

                            <h6>Référence</h6>
                            <p>{construction?.ConstructionReference}</p>

                            <h6>Numéro de projet</h6>
                            <p>{construction?.ConstructionProjectNumber}</p>

                            <h6>Début du chantier prévu</h6>
                            <p>{dateFormat(construction?.ConstructionScheduledStart, "dd/MM/yyyy")}</p>

                            <h6>Fin du chantier prévu</h6>
                            <p>{dateFormat(construction?.ConstructionScheduledEnd, "dd/MM/yyyy")}</p>

                            <h6>Référence</h6>
                            <p>{construction?.ConstructionReference}</p>

                            <h6>Description</h6>
                            <p>{construction?.ConstructionDescription}</p>

                        </section>
                    </div>

                </div>

                <div className='col-8'>

                </div>

                <div className='col-2 col-h-fill px-0'>
                    <h3 className="section-title-2 mb-3">Activité</h3>

                    <div className='card card-fill p-0'>
                        <section className="card-body p-0">

                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Cholet</li>
                                <li className="list-group-item">Cholet 2</li>
                                <li className="list-group-item">Cholet 3</li>
                            </ul>

                        </section>
                    </div>
                </div>
            </div>
        </section>

    </div>
}