import axios from "axios";
import { DB_SERVER } from '../components/common/constants'


const options = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS"
  }
}


export function reqGet(url) {

  return new Promise(function (resolve, reject) {

    axios.get(DB_SERVER + url)
      .then((res) => {
        if (res.status === 200) {

          resolve(res.data)

        } else {
          console.log('Failed');
        }

      })
      .catch((err) => {
        reject(err)
      });


  })
};

// INSERT
export function reqPost(url, args) {

  return new Promise(function (resolve, reject) {

    axios.post(DB_SERVER + url, args, options).then((res) => {
      if (res.status === 200 || res.status === 201) {

        resolve(res.data)

      } else {
        console.log('Failed');
      }

    })
      .catch((err) => {

        reject(err)
      });


  })
}



export function reqPostFile(url, args) {

  return new Promise(function (resolve, reject) {

    axios.post(DB_SERVER + url, args, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS"
      }
    }).then((res) => {
      if (res.status === 200 || res.status === 201) {

        resolve(res.data)

      } else {
        console.log('Failed');
      }

    })
      .catch((err) => {

        reject(err)
      });


  })
}


// UPDATE
export function reqPut(url, args) {

  return new Promise(function (resolve, reject) {

    axios.put(DB_SERVER + url, args, options).then((res) => {

      if (res.status === 200) {

        resolve(res.data)

      } else {
        console.log('Failed');
      }

    })
      .catch((err) => {

        reject(err)
      });


  })
}

export function reqDelete(url, args) {

  return new Promise(function (resolve, reject) {

    axios.delete(DB_SERVER + url, args, options).then((res) => {

      if (res.status === 200) {

        resolve(res.data)

      } else {
        console.log('Failed');
      }

    })
      .catch((err) => {

        reject(err)
      });


  })
}
