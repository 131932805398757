import React from "react";
import { Link } from "react-router-dom";

export default function HelpVehicleCheck() {

    return <>

        <h2 className="section-title">Contrôles véhicules</h2>

        <p className="paragraph"></p>

        <h4 className="section-title-2">Effectuer un contrôle véhicules</h4>

        <p className="paragraph">Pour effectuer un contrôle de son véhicules il faut aller dans l'onglet <mark className="mark"><Link className="link-paragraph" to="/account">Mon compte</Link></mark>, dans <mark className="mark">Mon véhicule</mark> il y a le véhicule qui m'est attribué.</p>

        <i className="text-danger">Image</i>

        <p className="paragraph">Dans l'interface du véhicule je retrouve les informations général de celui-ci ainsi</p>

        <h4 className="section-title-2">Consulter les contrôles</h4>

    </>
}