import React, { useEffect, useState, useMemo } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import $ from 'jquery'

import { fuzzyFilter, DebouncedInput } from '../../common/common'
import { GROUP, LAYOUT } from '../../common/constants'
import { reqGet } from '../../../service/apiRequest'
import { IUser } from '../../common/types'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
} from '@tanstack/react-table'

export default function PermissionUser() {

    const [users, setUsers] = useState<Array<IUser>>([])

    useEffect(() => {
        reqGet('user').then(res => { setUsers(res) })
    }, [])

    const location = useLocation()

    const columns = useMemo<ColumnDef<IUser, any>[]>(
        () => [
            {
                accessorFn: row => row.Username,
                id: 'Username',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.FirstName,
                id: 'FirstName',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Name,
                id: 'Name',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Role,
                id: 'Role',
                cell: info => info.getValue()
            }
        ], []
    )

    const [data, setData] = useState<Array<IUser>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')
    const [display, setDisplay] = useState<string>("")

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        reqGet("user").then((res) => { setData(res) })
    }, [])

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md && !location.pathname.includes("counter")) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/address") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    return <div className="row h-100">

        <div className="col-lg-3 col-h-fill ps-0">
            <section className="card card-fill card-nav-list">

                <header className="card-header">
                    <h5 className="card-title">Utilisateurs</h5>

                    <Link to="/users" className="link.nav">Détails {">"}</Link>

                </header>

                <section className="card-body">

                    <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} className="mb-3" placeholder="Recherche" />


                    <nav className="list-group list-group-hover">

                        {table.getRowModel().rows.map(row => {

                            return (
                                <NavLink to={row.original.UserID.toString()} key={row.original.UserID} className="list-group-item">

                                    <h6 className="item-title">{row.original.FirstName} {row.original.Name}</h6>

                                    <p className="item-subtitle"><i>{row.original.Role}</i></p>
                                </NavLink>
                            )

                        })}
                    </nav>
                </section>
            </section>
        </div>

        <div className="col-lg-9 col-h-fill">

            <div className="row justify-content-center h-100">

                <div className="col-4 col-h-fill">
                    <section className="card card-fill">

                        <header className="card-header">
                            <h5 className="card-title">Groupes</h5>

                            <button className="btn btn-primary">Enregistrer</button>
                        </header>

                        <section className="card-body">
                            <div className="list-group">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" disabled />
                                    <label className="form-check-label">
                                        Développeur
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Admin
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" checked />
                                    <label className="form-check-label">
                                        Technicien
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        SAV
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Stock
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" checked />
                                    <label className="form-check-label">
                                        Véhicules
                                    </label>
                                </div>

                            </div>
                        </section>

                    </section>
                </div>

                <div className="col-4 col-h-fill">
                    <section className="card card-fill">

                        <header className="card-header">
                            <h5 className="card-title">Permissions</h5>

                            <button className="btn btn-primary">Enregistrer</button>
                        </header>

                        <section className="card-body">
                            <div className="list-group">

                                <h6>Adresses</h6>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Lecture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Ecriture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Modification
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Suppression
                                    </label>
                                </div>

                                <h6 className="mt-3">Compteurs</h6>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Lecture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Ecriture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Modification
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Suppression
                                    </label>
                                </div>

                                <h6 className="mt-3">Installations</h6>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Lecture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Ecriture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Modification
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Suppression
                                    </label>
                                </div>

                                <h6 className="mt-3">Matériels</h6>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Lecture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Ecriture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Modification
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Suppression
                                    </label>
                                </div>

                                <h6 className="mt-3">Pièces</h6>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Lecture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Ecriture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Modification
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Suppression
                                    </label>
                                </div>

                                <h6 className="mt-3">Véhicules</h6>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Lecture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Ecriture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Modification
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Suppression
                                    </label>
                                </div>

                                <h6 className="mt-3">Véhicules contrôles</h6>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Lecture
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" />
                                    <label className="form-check-label">
                                        Ecriture
                                    </label>
                                </div>
                            </div>
                        </section>

                    </section>
                </div>


                <div className="col-4 col-h-fill">
                    <section className="card card-fill">

                        <header className="card-header">
                            <h5 className="card-title">Résumé</h5>
                        </header>

                        <section className="card-body">
                            <h6>Groupes</h6>
                            <ul>
                                <li>Technicien</li>
                                <li>Véhicules</li>
                            </ul>

                            <h6>Groupes</h6>
                            <ul>
                                <li>Installations</li>
                                <ul>
                                    <li>Lecture</li>
                                    <li>Ecriture</li>
                                </ul>
                                <li>Stock</li>
                                <ul>
                                    <li>Inventaire picking</li>
                                    <li>Inventaire global</li>
                                </ul>
                            </ul>
                        </section>

                    </section>
                </div>

            </div>

        </div>

    </div>
}