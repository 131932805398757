import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import $ from 'jquery'

import { IAddress, IHeating, IObservation } from "../../../common/types";
import { dateFormat } from '../../../common/common'
import { reqPost } from "../../../../service/apiRequest";
import { getToken } from "../../../../useToken";

interface IHeatingObservation extends IHeating, IAddress {
    AddressServiceNote: string,
    UserID: number,
    ObservationDate: string,
    ObservationFirstName: string,
    ObservationName: string,
    ZoneFirstName: string,
    ZoneName: string
}

export default function AccountHeating({ reload, setReload }) {
    const navigate = useNavigate()
    const params = useParams()
    const idAddress = params.idAddress

    const token = getToken()

    const data = useOutletContext<Array<IHeatingObservation>>()

    const [addressHeating, setAddressHeating] = useState<IHeatingObservation>()
    const [lastObservation, setLastObservation] = useState<IObservation>()

    useEffect(() => {
        // Check id validity
        if (!data.find(a => a.AddressID === parseInt(idAddress ?? "0"))) {
            navigate("/account/heating")
        } else {
            setAddressHeating(data.find(a => a.AddressID === parseInt(idAddress ?? "0")))
        }

    }, [idAddress])


    function SubmitHeating() {
        var d = {
            ...addressHeating,
            ActivityUserID: token.UserID,
            Note: $("#ObservationNote").val(),
            Date: $("#inputDate").val()
        }


        reqPost('observation/heating/' + addressHeating?.HeatingID, d).then((res) => {
            setReload(true)
            navigate("/account/heating")
        })
        $("#ObservationNote").val("")
    };

    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

    return <div className="body-container content">

        <header className="header">
            <h4 className="section-title mb-0">{addressHeating?.AddressLine}, {addressHeating?.ZipCode} {addressHeating?.City}</h4>
            <h5 className="mb-0">{addressHeating?.AddressComplementary}</h5>

            <div className="d-flex justify-content-between">
                <Link className={"link-nav"} to={"/account/heating"}>{"<"} Retour</Link>

                <Link className="link-nav" to={"/addressHeating/" + idAddress}>Aller à l'adresse {">"}</Link>
            </div>
        </header>

        <section className="body">


            <div className="container-fluid">
                <div className="row row-cols-2">
                    <div className="col ps-0">
                        <div className="card">
                            <header className="card-header">
                                <h5 className="card-title">Régie</h5>
                            </header>
                            <section className="card-body">
                                {addressHeating?.ManagementName ?? "Non défini"}
                            </section>
                        </div>
                    </div>

                    <div className="col pe-0">
                        <div className="card">
                            <header className="card-header">
                                <h5 className="card-title">Zone</h5>
                            </header>
                            <section className="card-body">
                                {addressHeating?.ZoneFirstName ?? "Non défini"} {addressHeating?.ZoneName}
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <h4 className="section-title-2 mt-4">{addressHeating?.HeatingAction == "Start" ? "Mise en route" : "Arrêt"} du chauffage</h4>

            <div className="card">
                <section className="card-body">
                    <div className="row row-cols-2 mb-2">
                        <div className="col">
                            <h6 className="mb-0">Programmé dès le</h6>
                            <p className="mb-0">{dateFormat(addressHeating?.HeatingDate, "dd/MM/yyyy")}</p>
                        </div>

                        <div className="col">
                            <h6 className="mb-0">Attribué à</h6>
                            <p className="mb-0">{addressHeating?.ZoneFirstName ?? "Non défini"} {addressHeating?.ZoneName}</p>
                        </div>
                    </div>

                    {addressHeating?.AddressServiceNote && <>
                        <h6 className="mt-3 mb-0">Information</h6>
                        <p className="mb-0">{addressHeating?.AddressServiceNote}</p>
                    </>}
                </section>
            </div>

            <h3 className="section-title-2">Effectuer</h3>

            <div className="mb-3">
                <label htmlFor="inputDate" className="form-label">Date</label>
                <input type="datetime-local" id="inputDate" className="form-control" defaultValue={now.toISOString().slice(0, 16)} />
            </div>

            <label className="form-label" htmlFor="ObservationNote">Commentaire</label>
            <textarea className="mb-3" name="ObservationNote" id="ObservationNote" rows={4}></textarea>


            <div className="text-end">
                <p><small>Les champs avec une * sont obligatoire</small></p>
                <button className="btn btn-add" onClick={() => SubmitHeating()}>
                    <i className="bi bi-check me-1"></i> Valider
                </button>
            </div>

        </section>
    </div>
}