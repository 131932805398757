import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { HeaderRow } from '../../../common/common'
import { reqGet, reqPut } from '../../../../service/apiRequest'
import { ISubcontractor } from '../../../common/types'

const validationSchema = yup.object({
    SubcontractorID: yup.number().required("Ce champ est obligatoire"),
    Company: yup.string().required("Ce champ est obligatoire"),
    Email: yup.string().email("L'email n'est pas valide").nullable(),
    Phone: yup.string().nullable(),
})

const initialValues = {
    SubcontractorID: 0,
    Company: "",
    Email: null,
    Phone: null
}

export default function SubcontractorUpdate() {
    const token = getToken()

    let navigate = useNavigate()
    const params = useParams()
    let idSubcontractor = params.idSubcontractor

    const { register, formState: { errors }, handleSubmit, getValues, setValue } = useForm<ISubcontractor>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {

        setValue("SubcontractorID", idSubcontractor ? parseInt(idSubcontractor) : 0)

        reqGet("subcontractor/" + idSubcontractor).then(res => {
            setValue("Company", res.Company)
            setValue("Email", res.Email)
            setValue("Phone", res.Phone)
        })

    }, [])

    const onSubmit: SubmitHandler<ISubcontractor> = data => {

        if (getValues("SubcontractorID") != 0) {
            reqPut('subcontractor/' + idSubcontractor, { ...data, ActivityUserID: token.UserID }).then((res) => {
                navigate(-1)
            })
        }

    };

    return <div className="body-container h-100">

        {HeaderRow(
            <h3 className="section-title mb-1">Modifier sous-traitant</h3>,
            <button onClick={() => navigate(-1)} className="btn link-nav">{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="Company" className="form-label">Entrepise *</label>
                            <input className={"form-control " + (errors?.Company ? "is-invalid" : "")} {...register("Company")} />

                            {errors.Company?.message && <p className="mt-2 text-danger">{errors.Company?.message}</p>}

                        </div>
                        <div className="mb-3">

                            <label htmlFor="Email" className="form-label">Email</label>
                            <input className={"form-control " + (errors?.Email ? "is-invalid" : "")} {...register("Email")} />

                            {errors.Email?.message && <p className="mt-2 text-danger">{errors.Email?.message}</p>}

                        </div>

                        <div className="mb-3">

                            <label htmlFor="Phone" className="form-label">Téléphone</label>
                            <input className={"form-control " + (errors?.Phone ? "is-invalid" : "")} {...register("Phone")} />

                            {errors.Phone?.message && <p className="mt-2 text-danger">{errors.Phone?.message}</p>}

                        </div>



                        <p><small>Les champs avec une * sont obligatoire</small></p>

                        <input type="submit" className="btn btn-primary" value="Modifier" />

                    </form>

                </div>

                <div id="alert" className="d-none alert alert-danger align-self-end position-absolute w-75" role="alert">
                    A simple danger alert—check it out!
                </div>
            </div >


        </div>
    </div>
}