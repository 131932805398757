import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export default function useToken() {
    const navigate = useNavigate();

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
    }
    useEffect(() => {

        return () => {
            window.removeEventListener("beforeunload", () => localStorage.removeItem('token'));
        };

    }, []);

    return { setToken: saveToken, token }

}

export function getToken() {
    const tokenString = localStorage.getItem('token')
    const userToken = tokenString ? JSON.parse(tokenString) : null
    /*
    console.log('Gettoken: ')
    console.log(userToken)
*/

    return userToken
}