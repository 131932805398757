import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import $ from "jquery"

import { getToken } from '../../../../useToken'
import { reqGet, reqPut } from '../../../../service/apiRequest'
import { IProduct, IBrand, ISupplier, IExchanger } from '../../../common/types'
import { HeaderRow, ButtonSubmit, CompareString } from '../../../common/common'
import { ICONS } from '../../../common/constants';

const validationSchema = yup.object({
    ProductLabel: yup.string().nullable(),
    ProductModel: yup.string().nullable(),
    ProductReference: yup.string().nullable(),
    BrandID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    SupplierID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ProductHeight: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ProductWidth: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ProductDepth: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()
})

const initialValues = {
    ProductLabel: "",
    ProductModel: "",
    ProductReference: "",
    BrandID: null,
    SupplierID: null,
    ProductHeight: null,
    ProductWidth: null,
    ProductDepth: null,
    ProductOutput: null
}

export default function ExchangerUpdate() {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idProduct = params.idProduct

    const { register, formState: { errors }, handleSubmit, setValue, getValues } = useForm<IProduct>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [brand, setBrand] = useState<Array<IBrand>>([])
    const [supplier, setSupplier] = useState<Array<ISupplier>>([])
    const [productExchangers, setProductExchangers] = useState<Array<IProduct>>([])
    const [exchangers, setExchangers] = useState<Array<IExchanger>>([])

    useEffect(() => {
        if (idProduct) {
            setValue("ProductID", parseInt(idProduct))
            let Promise1 = reqGet("exchanger").then(res => {
                setExchangers(res)
            })

            let Promise2 = reqGet("brand").then(res => {
                setBrand(res)
            })
            let Promise3 = reqGet("supplier").then(res => {
                setSupplier(res)
            })

            Promise.all([Promise1, Promise2, Promise3]).then((values) => {

                reqGet("product/" + idProduct).then(res => {
                    setValue("ProductType", res?.ProductType)
                    setValue("ProductLabel", res?.ProductLabel?.split('-')[1])
                    setValue("ProductModel", res?.ProductModel)
                    setValue("ProductReference", res?.ProductReference)
                    setValue("BrandID", res?.BrandID)
                    setValue("SupplierID", res?.SupplierID)
                    setValue("ProductHeight", res?.ProductHeight)
                    setValue("ProductWidth", res?.ProductWidth)
                    setValue("ProductDepth", res?.ProductDepth)

                    $("#ExchangerLabel").val(res.ProductLabel?.split(['-'])[0])

                    // setting the two part of product output
                    var output = res.ProductOutput?.split("-")
                    if (output) {
                        $("#ProductOutputPt1").val(output[0])
                        $("#ProductOutputPt2").val(output[1])
                    }
                })
            })
        }
    }, [])

    function SetProductFromExchanger(exchanger: IExchanger) {
        setValue("ProductModel", exchanger.ExchangerModel)
        setValue("ProductLabel", exchanger.ExchangerLabel)
        setValue("ProductReference", exchanger.ExchangerReference)
        setValue("BrandID", exchanger.BrandID)
        setValue("ProductHeight", exchanger.ExchangerHeight)
        setValue("ProductWidth", exchanger.ExchangerWidth)
        setValue("ProductDepth", exchanger.ExchangerDepth)
        setValue("ProductOutput", exchanger.ExchangerOutput)

        $("#ExchangerLabel").val(exchanger.ExchangerLabel)

        var output = exchanger.ExchangerOutput?.split("-")
        if (output) {
            $("#ProductOutputPt1").val(output[0])
            $("#ProductOutputPt2").val(output[1])
        }
    }

    function HandleProductModel(exchangerID: number) {
        var ex = exchangers.find(e => e.ExchangerID === exchangerID)
        if (ex)
            SetProductFromExchanger(ex)
    }

    const onSubmit: SubmitHandler<IProduct> = data => {
        data.ProductOutput = $("#ProductOutputPt1").val() + "-" + $("#ProductOutputPt2").val()

        reqPut('product/' + idProduct, {
            ...data,
            ActivityUserID: token.UserID,
            Reference: data?.ProductReference?.replace(/\s+/g, '')
        }).then((res) => {
            navigate('/exchanger/' + res)
        })

    };

    const [validLabel, setValidLabel] = useState<Boolean>()

    function ValidateLabel(label: string) {
        const exchangerLabel = getValues("ProductLabel") + "-" + label

        if (productExchangers?.some(ex => CompareString(ex?.ProductLabel, exchangerLabel))) {
            setValidLabel(false)
        } else {
            setValidLabel(true)
        }
    }

    return <div className="body-container">

        {HeaderRow(
            <h3 className="section-title mb-1">Modifier échangeur</h3>,
            <button className="btn link-nav" onClick={() => navigate(-1)}>{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="row row-cols-2 mb-3">

                            <div className="col">
                                <label htmlFor="ProductModel" className="form-label">Modèle *</label>
                                <select className="form-select" onChange={(e) => HandleProductModel(parseInt(e.target.value))}>
                                    {exchangers.map(e => {
                                        return <option key={e.ExchangerID} value={e.ExchangerID}>{e.ExchangerModel}</option>
                                    })}
                                </select>

                                {errors.ProductModel?.message && <p className="mt-2 text-danger">{errors.ProductModel?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="ProductLabel" className="form-label">Label *</label>
                                <div className='row'>
                                    <div className="col-3">
                                        <input type="text" className="form-control" id="ExchangerLabel" disabled />
                                    </div>
                                    <div className='col-9'>
                                        <input type="number" className={"form-control " + (errors?.ProductLabel ? "is-invalid" : "")} {...register("ProductLabel")} min={1} onChange={(e) => ValidateLabel(e.target.value)} placeholder={"0"} />
                                    </div>
                                </div>

                                {errors.ProductLabel?.message && <p className="mt-2 text-danger">{errors.ProductLabel?.message}</p>}

                                {validLabel == false && <p className="mb-0 mt-2 text-danger"><i className={ICONS.DANGER + " me-2"}></i>Le label existe déjà</p>}
                            </div>

                        </div>


                        <div className="row row-cols-3 mb-3">
                            <div className="col">
                                <label htmlFor="ProductReference" className="form-label">Référence</label>
                                <input type="text" className={"form-control " + (errors?.ProductReference ? "is-invalid" : "")} {...register("ProductReference")} disabled />

                                {errors.ProductReference?.message && <p className="mt-2 text-danger">{errors.ProductReference?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="BrandID" className="form-label">Marque *</label>
                                <select className={"form-select " + (errors?.BrandID ? "is-invalid" : "")} id="" {...register("BrandID")} disabled>
                                    {brand.map(b => {
                                        return <option key={b.BrandID} value={b.BrandID}>{b.Name}</option>
                                    })}
                                </select>

                                {errors.BrandID?.message && <p className="mt-2 text-danger">{errors.BrandID?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="SupplierID" className="form-label">Fournisseur *</label>
                                <select className={"form-select " + (errors?.SupplierID ? "is-invalid" : "")} id="" {...register("SupplierID")} disabled>
                                    <option value=""></option>
                                    {supplier.map(b => {
                                        return <option key={b.SupplierID} value={b.SupplierID}>{b.Name}</option>
                                    })}
                                </select>

                                {errors.SupplierID?.message && <p className="mt-2 text-danger">{errors.SupplierID?.message}</p>}
                            </div>
                        </div>

                        <div className="row row-cols-3 mb-3">
                            <div className="col">
                                <label htmlFor="ProductHeight" className="form-label">Hauteur</label>
                                <input type="text" className={"form-control " + (errors?.ProductHeight ? "is-invalid" : "")} {...register("ProductHeight")} disabled />

                                {errors.ProductHeight?.message && <p className="mt-2 text-danger">{errors.ProductHeight?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="ProductWidth" className="form-label">Largeur</label>
                                <input type="text" className={"form-control " + (errors?.ProductWidth ? "is-invalid" : "")} {...register("ProductWidth")} disabled />

                                {errors.ProductWidth?.message && <p className="mt-2 text-danger">{errors.ProductWidth?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="ProductDepth" className="form-label">Profondeur</label>
                                <input type="txet" className={"form-control " + (errors?.ProductDepth ? "is-invalid" : "")} {...register("ProductDepth")} disabled />

                                {errors.ProductDepth?.message && <p className="mt-2 text-danger">{errors.ProductDepth?.message}</p>}
                            </div>
                        </div>

                        <label htmlFor="ProductOutput" className="form-label">Sortie</label>
                        <div className="row">
                            <div className='col-4 col-md-3'>
                                <input type="text" maxLength={4} id="ProductOutputPt1" className={"form-control " + (errors?.ProductOutput ? "is-invalid" : "")} placeholder='1010' disabled />
                            </div>
                            <div className='col-1 text-center'>-</div>
                            <div className='col-4 col-md-3'>
                                <input type="text" maxLength={4} id="ProductOutputPt2" className={"form-control " + (errors?.ProductOutput ? "is-invalid" : "")} placeholder='1010' disabled />
                            </div>

                            {errors.ProductOutput?.message && <p className="mt-2 text-danger">{errors.ProductOutput?.message}</p>}
                        </div>


                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>
            </div >


        </div>
    </div>
}