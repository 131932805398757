import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { faker } from '@faker-js/faker'

export default function ConstructionDocuments() {
    faker.locale = 'fr_CH'

    return <>

        <div className="header">
            <h3 className="section-title">Documents</h3>

            <div className="d-flex justify-content-end w-25">
                <Link to="" className="btn btn-tools">
                    <i className="bi bi-plus-circle"></i>
                    <span className="d-block">Ajouter</span>
                </Link>

                <Link to="" id="btnUpdate" className="btn btn-tools disabled">
                    <i className="bi bi-pencil-square"></i>
                    <span className="d-block">Modifier</span>
                </Link>
            </div>
        </div>

        <div className="row">

            <div className="col-md-4 col-h-fill">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title mb-1">Dernier plan</h5>
                    </div>

                    <div className="card-body d-flex justify-content-between">
                        <div>
                            <h6 className="item-title">{faker.company.catchPhrase()}</h6>
                            <p className="item-subtitle">18.04.2022</p>
                        </div>
                        <button className="btn btn-tools">Ouvrir</button>
                    </div>

                </div>

                <div className="card card-fill">

                    <div className="card-header">
                        <h5 className="card-title">Historique des plans</h5>
                    </div>

                    <div className="card-body">

                        <div className="list-group">

                            <div className="list-group-item d-flex justify-content-between">
                                <div>
                                    <h6 className="item-title">{faker.company.catchPhrase()}</h6>
                                    <p className="item-subtite">16.03.2022</p>
                                </div>

                                <button className="btn btn-tools text-nowrap">Ouvrir</button>
                            </div>

                            <div className="list-group-item d-flex justify-content-between">
                                <div>
                                    <h6 className="item-title">{faker.company.catchPhrase()}</h6>
                                    <p className="item-subtite">16.03.2022</p>
                                </div>

                                <button className="btn btn-tools text-nowrap">Ouvrir</button>
                            </div>

                            <div className="list-group-item d-flex justify-content-between">
                                <div>
                                    <h6 className="item-title">{faker.company.catchPhrase()}</h6>
                                    <p className="item-subtite">16.03.2022</p>
                                </div>

                                <button className="btn btn-tools text-nowrap">Ouvrir</button>
                            </div>

                            <div className="list-group-item d-flex justify-content-between">
                                <div>
                                    <h6 className="item-title">{faker.company.catchPhrase()}</h6>
                                    <p className="item-subtite">16.03.2022</p>
                                </div>

                                <button className="btn btn-tools text-nowrap">Ouvrir</button>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div className="col-md-4">
                <div className="card card-fill">

                    <div className="card-header">
                        <h5 className="card-title">Autres documents</h5>
                    </div>

                    <div className="card-body">

                        <div className="list-group">

                            <div className="list-group-item d-flex justify-content-between">
                                <div>
                                    <h6 className="item-title">{faker.company.catchPhrase()}</h6>
                                    <p className="item-subtite">16.03.2022</p>
                                </div>

                                <button className="btn btn-tools text-nowrap">Ouvrir</button>
                            </div>

                            <div className="list-group-item d-flex justify-content-between">
                                <div>
                                    <h6 className="item-title">{faker.company.catchPhrase()}</h6>
                                    <p className="item-subtite">16.03.2022</p>
                                </div>

                                <button className="btn btn-tools text-nowrap">Ouvrir</button>
                            </div>

                            <div className="list-group-item d-flex justify-content-between">
                                <div>
                                    <h6 className="item-title">{faker.company.catchPhrase()}</h6>
                                    <p className="item-subtite">16.03.2022</p>
                                </div>

                                <button className="btn btn-tools text-nowrap">Ouvrir</button>
                            </div>

                            <div className="list-group-item d-flex justify-content-between">
                                <div>
                                    <h6 className="item-title">{faker.company.catchPhrase()}</h6>
                                    <p className="item-subtite">16.03.2022</p>
                                </div>

                                <button className="btn btn-tools text-nowrap">Ouvrir</button>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

           


        </div>
    </>
}