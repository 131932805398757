import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { flexRender, } from '@tanstack/react-table'

import { reqGet, reqPut } from '../../../../service/apiRequest'
import { getToken } from '../../../../useToken'
import { IInstallation, IApartment } from '../../../common/types';
import { HeaderRow, DebouncedInput, ButtonSubmit, Filter } from '../../../common/common'

import { MaterialTable } from '../../material/material'
import { INSTALLATION_STATUS, MATERIAL_CLASS_ID } from '../../../common/constants';
import { MaterialSelection } from './address-install-add';

const defaultData: string[] = []

let today = new Date();

let year = today.getFullYear()

interface IFormInput extends IInstallation {
    ActivityUserID: number | null;
}

const validationSchema = yup.object({
    AddressID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    MaterialID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    ApartmentID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    Maintenance: yup.mixed().transform((value) => (value ? 1 : 0)).required("Ce champ est obligatoire"),
    Note: yup.string().nullable(),
    PowerInstalled: yup.string().nullable(),
    YearInstalled: yup.number().transform((value) => (isNaN(value) ? undefined : value)).min(year - 50).max(year + 1).nullable(),
    Scaling: yup.string().nullable(),
    Projection: yup.string().required("Ce champ est obligatoire"),
    Status: yup.string().required("Ce champ est obligatoire"),
    DateReview: yup.string().nullable(),
    Number: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    EndWarranty: yup.string().nullable(),
    SubcontractorID: yup.number().transform((value) => (isNaN(value) ? null : value)).nullable()

}).required();

const initialValues = {
    AddressID: 0,
    MaterialID: null,
    ApartmentID: null,
    Maintenance: 0,
    Note: null,
    PowerInstalled: null,
    YearInstalled: null,
    Scaling: null,
    Projection: "0",
    Status: null,
    DateReview: null,
    Number: null,
    EndWarranty: null,
    SubcontractorID: null,
    ActivityUserID: null
}


export default function AddressInstallUpdateController() {
    let navigate = useNavigate()
    const params = useParams()

    const idAddress = params.idAddress
    const idInstall = params.idInstall

    const [updated, setUpdated] = useState<boolean>(false)

    useEffect(() => {
        if (updated) {
            navigate('/address/' + idAddress + '/installation')
        }
    }, [updated])

    return <div className="body-container">
        {HeaderRow(
            <h3 className="section-title">Modifier l'installation</h3>,
            <Link to={"/address/" + idAddress + "/installation"} className="link-nav">{'< Retour'}</Link>,
        )}

        <div className="body container-fluid">

            <div className="row justify-content-center">

                <AddressInstallUpdate idInstall={idInstall} setUpdated={setUpdated} />

            </div>
        </div>
    </div>
}



export function AddressInstallUpdate({ idInstall, setUpdated }) {
    const token = getToken()

    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [apartments, setApartments] = useState<Array<IApartment>>([])

    const [material, setMaterial] = useState<IInstallation>()

    useEffect(() => {

        setValue("ActivityUserID", token.UserID)

        reqGet("installation/" + idInstall).then(res => {

            if (res?.MaterialID) {
                setMaterial(res)
            }

            if (res?.EndWarranty) {
                let date = res.EndWarranty.split(".")
                setValue("EndWarranty", date[2] + "-" + date[1] + "-" + date[0])
            }

            setValue("MaterialID", res.MaterialID)
            //setValue("apartmentID", res.Type)
            setValue("Note", res.Note)
            setValue("PowerInstalled", res.PowerInstalled)
            setValue("YearInstalled", res.YearInstalled)
            setValue("Scaling", res.Scaling)
            setValue("DateReview", res.DateReview)
            setValue("Number", res.Number)
            setValue("Status", res.Status)
        })

        /*
            reqGet('apartment/address/' + idAddress).then(function (res) {
                setApartments(res)
            })
        */

    }, [])

    useEffect(() => {
        if (material)
            setValue("MaterialID", material.MaterialID)

    }, [material])

    const onSubmit: SubmitHandler<IFormInput> = data => {

        reqPut('installation/' + idInstall, data).then((res) => {
            setUpdated(true)
        })
    }

    return <>

        {
            /*
            apartments.length > 0 ?
                <div className="mb-3">
                    <label htmlFor="apartment" className="form-label">Appartements </label>
                    <select name="select-management" className="form-select" id="apartment" onChange={() => { handleChange() }}>
                        {apartments.map((m, k) => {
                            return <option key={k} value={m.ApartmentID}>Appartement {m.ApartmentNumber}</option>;
                        })}
                    </select>
                </div>
                : ''
                    */
        }

        <MaterialSelection material={material} setMaterial={setMaterial} errors={errors?.MaterialID} />



        <div className="col-md-10 col-xxl-8 pb-3">
            <div className="row mb-3">


                <div className="col-3">

                    <label className="form-label">Etat *</label>
                    <select className={"form-select " + (errors?.Status ? "is-invalid" : "")} {...register("Status")}>
                        <option value="" hidden></option>
                        <option value={INSTALLATION_STATUS.GOOD}>Bon</option>
                        <option value={INSTALLATION_STATUS.MEDIUM}>Moyen</option>
                        <option value={INSTALLATION_STATUS.BAD}>Mauvais</option>
                    </select>

                    {errors.Status?.message && <p className="mt-2 text-danger">{errors.Status?.message}</p>}

                </div>

                <div className="col">

                    <label htmlFor="yearInstalled" className="form-label">Année installé</label>
                    <input type="number" className={"form-control " + (errors?.YearInstalled ? "is-invalid" : "")} min={year - 50} max={year + 1} id="yearInstalled" {...register("YearInstalled")} />

                    {errors.YearInstalled?.message && <p className="mt-2 text-danger">{errors.YearInstalled?.message}</p>}

                </div>


                <div className="col">


                    <label className="form-label mb-2" htmlFor="endWarranty">Fin de garantie</label>

                    <input type="date" className={"form-control " + (errors?.EndWarranty ? "is-invalid" : "")} {...register("EndWarranty")} />

                    {errors.EndWarranty?.message && <p className="mt-2 text-danger">{errors.EndWarranty?.message}</p>}

                </div>
            </div>

            <div className="row mb-3">
                {(material?.MaterialClassID == MATERIAL_CLASS_ID.BURNER || material?.MaterialClassID == MATERIAL_CLASS_ID.GENERATOR) &&
                    <div className="col-6">
                        <label htmlFor="powerInstalled" className="form-label">Puissance installée </label>
                        <input type="text" className={"form-control " + (errors?.PowerInstalled ? "is-invalid" : "")} id="powerInstalled" {...register("PowerInstalled")} />

                        {errors.PowerInstalled?.message && <p className="mt-2 text-danger">{errors.PowerInstalled?.message}</p>}
                    </div>
                }

                {(material?.MaterialClassID == MATERIAL_CLASS_ID.SOLAR) &&
                    <div className="col-6">

                        <label htmlFor="number" className="form-label">Nombre</label>
                        <input type="number" className={"form-control " + (errors?.Number ? "is-invalid" : "")} id="scaling" {...register("Number")} />

                        {errors.Number?.message && <p className="mt-2 text-danger">{errors.Number?.message}</p>}

                    </div>
                }

            </div>

            <div className="mb-3">

                <label htmlFor="Note" className="form-label">Remarques</label>

                <textarea className={(errors?.Note ? "is-invalid" : "")} id="note" rows={4} {...register("Note")}></textarea>

                {errors.Note?.message && <p className="mt-2 text-danger">{errors.Note?.message}</p>}

            </div>

            <div className="text-end">
                <p><small>Les champs avec une * sont obligatoire</small></p>
                <ButtonSubmit onClick={handleSubmit(onSubmit)} />
            </div>

        </div>
    </>
}