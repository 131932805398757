import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import $ from 'jquery'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { reqGet, reqPost } from '../../../service/apiRequest'
import { getToken } from '../../../useToken'
import { IConstruction, IUser, IZipCode } from "../../common/types"
import { Header, ButtonSubmit } from '../../common/common'

interface IConstructionExtended extends IConstruction {
    AddressLine: string,
    AddressNumber: string
}

const validationSchema = yup.object({
    ConstructionName: yup.string().required("Ce champ est obligatoire"),
    AddressLine: yup.string().nullable(),
    AddressNumber: yup.string().nullable(),
    ConstructionAddressComplementary: yup.string().nullable(),
    ConstructionZipCodeID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ConstructionReference: yup.string().required("Ce champ est obligatoire"),
    ConstructionProjectNumber: yup.string().nullable(),
    ConstructionManagerID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    ConstructionDescription: yup.string().nullable(),
    ConstructionStatus: yup.string().nullable(),
    ConstructionScheduledStart: yup.string().nullable(),
    ConstructionScheduledEnd: yup.string().nullable(),
    ConstructionHoursEstimate: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()

}).required();

const initialValues = {
    ConstructionName: "",
    ConstructionAddress: "",
    AddressLine: "",
    AddressNumber: "",
    ConstructionAddressComplementary: "",
    ConstructionZipCodeID: null,
    ConstructionReference: "",
    ConstructionProjectNumber: null,
    ConstructionManagerID: null,
    ConstructionDescription: null,
    ConstructionStatus: null,
    ConstructionScheduledStart: null,
    ConstructionScheduledEnd: null,
    ConstructionHoursEstimate: null
}

export default function ConstructionAdd() {
    const token = getToken()
    const navigate = useNavigate()

    const [zipCodes, setZipCodes] = useState<Array<IZipCode>>([])
    const [users, setUsers] = useState<Array<IUser>>([])

    const { register, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm<IConstructionExtended>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        reqGet("zipcode").then(res => setZipCodes(res))
        reqGet("user").then(res => {
            setUsers(res)
            setValue("ConstructionManagerID", res.filter(u => u.Role == "Admin")[0].UserID)
        })
    }, [])


    const onSubmit: SubmitHandler<IConstructionExtended> = data => {
        data.ConstructionAddress = data.AddressLine + " " + data.AddressNumber

        reqPost('construction', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate('/construction')
        })
    };

    useEffect(() => {
        var user = users.find(u => u.UserID == getValues("ConstructionManagerID"))

        $("#refStart").text(user?.FirstName[0] + user?.Name[0] + "_")
    }, [users, watch("ConstructionManagerID")])

    return <div className="container-fluid h-100 content">
        <div className="row justify-content-center h-100">
            <div className="col-md-10 col-xxl-6 col-h-fill">

                {Header(
                    <h2 className="section-title">Ajouter un chantier</h2>,
                    <Link to="/construction" className="link-nav">{"<"} Retour</Link>
                )}

                <section className="body">
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">
                            <label className="form-label" htmlFor="ConstructionName">Nom du chantier *</label>
                            <input type="text" className={"form-control " + (errors?.ConstructionName && "is-invalid")} {...register("ConstructionName")} />

                            {errors?.ConstructionName?.message && <p className="input-msg-error">{errors?.ConstructionName.message}</p>}
                        </div>

                        <div className="row mb-3">
                            <div className="col-6 col-md-8">
                                <label className="form-label" htmlFor='ConstructionAddress'>Adresse</label>
                                <div className="input-group">

                                    <input className={"form-control w-75 " + (errors?.AddressLine && "is-invalid")} placeholder="Rue" {...register("AddressLine")} />

                                    <input className={"form-control w-25 " + (errors?.AddressNumber && "is-invalid")} placeholder="Numéro(s)" {...register("AddressNumber")} />

                                </div>

                                {errors.AddressLine?.message && <p className="input-msg-error">{errors.AddressLine?.message}</p>}
                                {errors.AddressNumber?.message && <p className="input-msg-error">{errors.AddressNumber?.message}</p>}
                            </div>

                            <div className="col-6 col-md-4">
                                <label className="form-label" htmlFor='ConstructionZipCodeID'>Code postale</label>
                                <select id="" className={"form-select " + (errors?.ConstructionZipCodeID ? "is-invalid" : "")} {...register("ConstructionZipCodeID")} >
                                    <option value=""></option>
                                    {zipCodes.map((z) => {
                                        return <option key={z.ZipCodeID} value={z.ZipCodeID}>{z.ZipCode} {z.City}</option>
                                    })}
                                </select>

                                {errors.ConstructionZipCodeID?.message && <p className="input-msg-error">{errors.ConstructionZipCodeID?.message}</p>}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label className="form-label" htmlFor="ConstructionAddressComplementary">Complément d'adresse</label>
                            <input type="text" className={"form-control " + (errors?.ConstructionAddressComplementary && "is-invalid")} {...register("ConstructionAddressComplementary")} />

                            {errors?.ConstructionAddressComplementary?.message && <p className="input-msg-error">{errors?.ConstructionAddressComplementary.message}</p>}
                        </div>

                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label" htmlFor='ConstructionManagerID'>Responsable *</label>
                                <select id="ConstructionManagerID" className={"form-select " + (errors?.ConstructionManagerID ? "is-invalid" : "")} {...register("ConstructionManagerID")}>
                                    {users.filter(u => u.Role == "Admin").map((u) => {
                                        return <option key={u.UserID} value={u.UserID}>{u.FirstName} {u.Name}</option>
                                    })}
                                </select>

                                {errors.ConstructionManagerID?.message && <p className="input-msg-error">{errors.ConstructionManagerID?.message}</p>}
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor='ConstructionReference'>Référence *</label>

                                <div className="input-group">
                                    <span className="input-group-text" id="refStart">{ }</span>
                                    <input type="text" className="form-control" {...register("ConstructionReference")} />
                                </div>

                                {errors.ConstructionReference?.message && <p className="input-msg-error">{errors.ConstructionReference?.message}</p>}
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor='ConstructionProjectNumber'>Numéro de projet</label>
                                <input type="text" className="form-control" {...register("ConstructionProjectNumber")} />
                            </div>
                        </div>

                        <div className="row row-cols-3 mb-3">
                            <div className="col">
                                <label htmlFor="ConstructionScheduledStart" className="form-label">Début de chantier prévu</label>
                                <input type="date" className="form-control" {...register("ConstructionScheduledStart")} />
                            </div>
                            <div className="col">
                                <label htmlFor="ConstructionScheduledEnd" className="form-label">Fin de chantier prévu</label>
                                <input type="date" className="form-control" {...register("ConstructionScheduledEnd")} />
                            </div>
                            <div className="col">
                                <label htmlFor="ConstructionHoursEstimate" className="form-label">Nombre d'heures éstimée</label>
                                <input type="number" className="form-control" {...register("ConstructionHoursEstimate")} />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ConstructionDescription" className="form-label">Description</label>
                            <textarea id="ConstructionDescription" {...register("ConstructionDescription")} rows={4}></textarea>
                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>
                </section>

            </div>
        </div>
    </div>
}