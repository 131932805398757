import React from 'react'
import { Link, useParams, useNavigate, useLocation, useOutletContext } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { reqPost } from '../../../../service/apiRequest'
import { getToken } from '../../../../useToken'
import { ButtonSubmit, Header } from '../../../common/common'
import { IContact } from '../../../common/types'


const validationSchema = yup.object({
    ContactName: yup.string().required("Ce champ est obligatoire"),
    ContactPhone: yup.string().nullable(),
    ContactEmail: yup.string().nullable(),
    ContactAddress: yup.string().nullable()
})

const initialValues = {
    ContactName: '',
    ContactPhone: null,
    ContactEmail: null,
    ContactAddress: null
}

export default function UserContactAdd({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idUser = params.idUser
    const location = useLocation()

    const context: any = useOutletContext()

    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IContact>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });


    const onSubmit: SubmitHandler<IContact> = data => {
        const d = {
            ...data,
            UserID: idUser,
            UserName: context?.FirstName + " " + context?.Name,
            ActivityUserID: token.UserID
        }

        reqPost('contact/user/' + idUser, d).then((res) => {
            setReload(true)
            navigate('/users/' + idUser)
        })
    }

    const LinkBack = () => {
        if (location?.state?.path) {
            return <></>
        } else {
            return <Link to={"/users/" + idUser} className="link-nav">{"<"} Retour</Link>
        }
    }

    return <div className="row h-100 justify-content-center">

        <div className="col-xxl-10 col-h-fill overflow-auto">
            <section className="body-container content">

                {Header(
                    <h3 className="section-title">Ajouter un contact</h3>,
                    LinkBack()
                )}

                <section className="body container-fluid">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">
                            <label htmlFor="ContactName" className="form-label">Nom complet *</label>
                            <input type="text" className={"form-control " + (errors?.ContactName ? "is-invalid" : "")} {...register("ContactName")} />

                            {errors.ContactName?.message && <p className="input-msg-error">{errors.ContactName?.message}</p>}
                        </div>

                        <div className="row row-cols-2 mb-3">
                            <div className="col">
                                <label htmlFor="ContactPhone" className="form-label">Téléphone</label>
                                <input type="text" className={"form-control " + (errors?.ContactPhone ? "is-invalid" : "")} {...register("ContactPhone")} />

                                {errors.ContactPhone?.message && <p className="input-msg-error">{errors.ContactPhone?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="ContactEmail" className="form-label">Email</label>
                                <input type="text" className={"form-control " + (errors?.ContactEmail ? "is-invalid" : "")} {...register("ContactEmail")} />

                                {errors.ContactEmail?.message && <p className="input-msg-error">{errors.ContactEmail?.message}</p>}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ContactAddress" className="form-label">Adresse</label>
                            <input type="text" className={"form-control " + (errors?.ContactAddress ? "is-invalid" : "")} {...register("ContactAddress")} />

                            {errors.ContactAddress?.message && <p className="input-msg-error">{errors.ContactAddress?.message}</p>}
                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>


                    </form>
                </section>
            </section >

        </div>

    </div>
}