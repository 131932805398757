import React, { useState, useEffect, useLayoutEffect } from 'react'
import { NavLink, Link, useParams, useNavigate } from 'react-router-dom'
import { reqGet, reqPost } from '../../../service/apiRequest'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../useToken'
import { ButtonSubmit, HeaderRow } from '../../common/common';
import { IVehicle, IVehicleCheck } from '../../common/types'

yup.setLocale({
    mixed: {
        required: "Ce champ est obligatoire"
    }
})

interface IFormInput extends IVehicleCheck {
    ActivityUserID: number | null
}

const validationSchema = yup.object({
    VehicleID: yup.number().required(),
    UserID: yup.number().required(),
    Km: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required(),
    ProchainService: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required(),
    DernierService: yup.string().nullable(),
    DernierControle: yup.string().nullable(),
    Permis: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    CarteVerte: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    CarnetService: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    Vignette: yup.number().nullable(),
    TrianglePanne: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    KitReparationPneu: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    AmpoulesReserve: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    PneuReserve: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    Extincteur: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    Pharmacie: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    HarnaisSecurite: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    CasqueChantier: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    NumeroUrgence: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    PorteCharge: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    IdentificationMT: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    Pneus: yup.string().required(),
    ChangementPneus: yup.string().nullable(),
    Rangement: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required(),
    PropreteExterieur: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required(),
    PropreteInterieur: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required(),
    AlerteService: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    Note: yup.string()

}).required();

const initialValues = {
    VehicleID: 0,
    UserID: 0,
    Status: "Good",
    Km: 0,
    ProchainService: 0,
    DernierService: null,
    DernierControle: null,
    Permis: 0,
    CarteVerte: 0,
    CarnetService: 0,
    Vignette: null,
    TrianglePanne: 0,
    KitReparationPneu: 0,
    AmpoulesReserve: 0,
    PneuReserve: 0,
    Extincteur: 0,
    Pharmacie: 0,
    HarnaisSecurite: 0,
    CasqueChantier: 0,
    NumeroUrgence: 0,
    PorteCharge: 0,
    IdentificationMT: 0,
    Pneus: 'Quatre saisons',
    ChangementPneus: null,
    Rangement: 2,
    PropreteExterieur: 2,
    PropreteInterieur: 2,
    AlerteService: 0,
    Note: '',
    ActivityUserID: null
}

export default function VehicleCheckAdd({ setReload }) {
    const params = useParams()
    const navigate = useNavigate()

    const token = getToken()

    const { register, formState: { errors }, handleSubmit, setValue, getValues, watch } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });


    const idVehicle = params.idVehicle || ""
    const idUser = token.UserID

    const [tires, setTires] = useState<Array<string>>([])
    const [tiresChanging, setTiresChanging] = useState<Array<string>>([])
    const [vehicle, setVehicle] = useState<IVehicle>()
    const [vehicleCheck, setVehicleCheck] = useState<IVehicleCheck>()

    let today = new Date();
    let year = today.getFullYear()

    useEffect(() => {
        setValue("ActivityUserID", token.UserID)

        reqGet("vehicle/" + idVehicle).then(res => setVehicle(res))

        reqGet('vehiclecheck/pneus').then((res) => { setTires(res) })

        reqGet('vehiclecheck/changementpneus').then((res) => { setTiresChanging(res) })

        reqGet('vehiclecheck/vehicle/' + idVehicle + "/last").then((res) => { setVehicleCheck(res) })
    }, [idVehicle])

    useLayoutEffect(() => {
        if (vehicleCheck) {
            setValue("ProchainService", vehicleCheck.ProchainService ?? 0)
            setValue("DernierService", vehicleCheck.DernierService)
            setValue("DernierControle", vehicleCheck.DernierControle)
            setValue("Vignette", vehicleCheck.Vignette)
            setValue("Pneus", vehicleCheck.Pneus)
            setValue("ChangementPneus", vehicleCheck.ChangementPneus)
        }
    }, [vehicleCheck])


    useEffect(() => {
        setValue("VehicleID", parseInt(idVehicle))
        setValue("UserID", idUser)
    }, [])


    const onSubmit: SubmitHandler<IFormInput> = data => {
        reqPost('vehiclecheck', { ...data, Status: CheckingStatusValidity() }).then((res) => {
            setReload(true)
            navigate(-1)
        })
    }

    function CheckingStatusValidity() {
        const actualDate = new Date()
        const res = ["Good", "Warning", "Bad"]

        const values = getValues()

        // first bad values
        if (values?.Vignette != actualDate.getFullYear()) {
            return res[2]
        } else if (values?.PneuReserve == 0) {
            return res[2]
        } else if (values?.TrianglePanne == 0) {
            return res[2]
        } else if (values?.Extincteur == 0) {
            return res[2]
        } else if (values?.Pharmacie == 0) {
            return res[2]
        } else if (vehicle?.Assignment == "Chantier" && values?.HarnaisSecurite == 0) {
            return res[2]
        } else if (vehicle?.Assignment == "Chantier" && values?.CasqueChantier == 0) {
            return res[2]
        } else if (values?.NumeroUrgence == 0) {
            return res[2]
        } else if (values?.AlerteService == 1) {
            return res[2]
        }
        // then warning values
        else if ((values?.Km ?? 0) >= (values?.ProchainService ?? 0)) {
            return res[1]
        } else if (values?.Permis == 0) {
            return res[1]
        } else if (values?.CarteVerte == 0) {
            return res[1]
        } else if (values?.CarnetService == 0) {
            return res[1]
        } else if (values?.AmpoulesReserve == 0) {
            return res[1]
        } else if (!TiresChecking(values.Pneus, actualDate)) {
            return res[1]
        }

        return res[0]
    }

    function TiresChecking(tires: string | null, date: Date) {
        // if date is bigger than 14 may set actual year otherwise set past year
        const dateWinter = new Date(date.getFullYear() - (date.getMonth() < 5 ? 1 : 0), 10, 14)
        // if date is lower than 14 may set actual year otherwise set next year
        const dateSummer = new Date(date.getFullYear() + (date.getMonth() <= 5 ? 0 : 1), 4, 14)

        if (tires != "Quatre saisons") {
            if (date > dateWinter && date <= dateSummer) {

                if (tires != "Hiver") {
                    return false
                }
            } else if (tires != "Eté") {
                return false
            }
        }

        return true
    }

    return <section className="body-container content">

        {HeaderRow(
            <h3 className="section-title">Contrôle véhicule</h3>,
            <button onClick={() => navigate(-1)} className="btn link-nav">{'<'} Retour</button>
        )}

        <section className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-xl-6">

                    <form className="px-0" onSubmit={handleSubmit(onSubmit)}>

                        <h4 className="section-title-2">Equipement</h4>

                        <div className="mb-2 row mx-0">
                            <div className="col-8 px-0">
                                <label htmlFor="Km" className="d-inline form-label mb-1">Kilométrage</label>
                                <p className='mb-0'>Précédent: {vehicleCheck?.Km} km</p>

                            </div>

                            <div className="col-4 px-0">
                                <input type="number" className="form-control" min="0" {...register("Km")} />
                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="ProchainService" className="col-8 px-0 d-inline form-label">Prochain service (km)</label>

                            <div className="col-4 px-0">
                                <input type="number" className="form-control" min="0" {...register("ProchainService")} />
                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="DernierService" className="col-8 px-0 d-inline form-label">Dernier service (date)</label>

                            <div className="col-4 px-0">
                                <input type="date" className="form-control" {...register("DernierService")} />
                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="DernierControle" className="col-8 px-0 d-inline form-label">Dernier contrôle (bureau des autos)</label>

                            <div className="col-4 px-0">
                                <input type="date" className="form-control" {...register("DernierControle")} />
                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="Permis" className="col-8 px-0 form-label">Copie du permis de circulation</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="Permis" {...register("Permis")} />

                                <label className="form-check-label" htmlFor="permis">{watch("Permis") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="CarteVerte" className="col-8 px-0 form-label">Carte verte assurance</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="CarteVerte" {...register("CarteVerte")} />

                                <label className="form-check-label" htmlFor="carteVerte">{watch("CarteVerte") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="CarnetService" className="col-8 px-0 form-label">Carnet de service</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="CarnetService" {...register("CarnetService")} />

                                <label className="form-check-label" htmlFor="carnetService">{watch("CarnetService") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="Vignette" className="col-8 px-0 d-inline form-label">Vignette (année)</label>
                            <div className="col-4 px-0">
                                <input type="number" className="form-control" min={year - 5} max={year + 1} id="Vignette" {...register("Vignette")} />
                            </div>

                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="PneuReserve" className="col-8 px-0 form-label">Pneu de réserve</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="PneuReserve" {...register("PneuReserve")} />

                                <label className="form-check-label" htmlFor="PneuReserve">{watch("PneuReserve") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="KitReparationPneu" className="col-8 px-0 form-label">Kit de réparation des pneus</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="KitReparationPneu" {...register("KitReparationPneu")} />

                                <label className="form-check-label" htmlFor="KitReparationPneu">{watch("KitReparationPneu") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="AmpoulesReserve" className="col-8 px-0 form-label">Ampoules de réserve</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="AmpoulesReserve" {...register("AmpoulesReserve")} />

                                <label className="form-check-label" htmlFor="AmpoulesReserve">{watch("AmpoulesReserve") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="TrianglePanne" className="col-8 px-0 form-label">Triangle de panne</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="TrianglePanne" {...register("TrianglePanne")} />

                                <label className="form-check-label" htmlFor="trianglePanne">{watch("TrianglePanne") ? "Oui" : "Non"}</label>

                            </div>
                        </div>







                        <h4 className="section-title-2 mt-5">EPI</h4>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="Extincteur" className="col-8 px-0 form-label">Extincteur</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="Extincteur" {...register("Extincteur")} />

                                <label className="form-check-label" htmlFor="extincteur">{watch("Extincteur") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="Pharmacie" className="col-8 px-0 form-label">Pharmacie</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="Pharmacie" {...register("Pharmacie")} />

                                <label className="form-check-label" htmlFor="pharmacie">{watch("Pharmacie") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="HarnaisSecurite" className="col-8 px-0 form-label">Harnais de sécurité</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="HarnaisSecurite" {...register("HarnaisSecurite")} />

                                <label className="form-check-label" htmlFor="harnaisSecurite">{watch("HarnaisSecurite") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="CasqueChantier" className="col-8 px-0 form-label">Casque de chantier</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="CasqueChantier" {...register("CasqueChantier")} />

                                <label className="form-check-label" htmlFor="casqueChantier">{watch("CasqueChantier") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="NumeroUrgence" className="col-8 px-0 form-label">Numéro d'urgence</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="NumeroUrgence" {...register("NumeroUrgence")} />

                                <label className="form-check-label" htmlFor="numeroUrgence">{watch("NumeroUrgence") ? "Oui" : "Non"}</label>

                            </div>
                        </div>









                        <h4 className="section-title-2 mt-5">Etat</h4>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="PorteCharge" className="col-8 px-0 form-label">Porte-charge</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="PorteCharge" {...register("PorteCharge")} />

                                <label className="form-check-label" htmlFor="porteCharge">{watch("PorteCharge") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="IdentificationMT" className="col-8 px-0 form-label">Identification Multitherme</label>

                            <div className="col-4 px-0 form-check form-switch text-end">

                                <input className="form-check-input ms-0 me-3" type="checkbox" role="switch" id="IdentificationMT" {...register("IdentificationMT")} />

                                <label className="form-check-label" htmlFor="IdentificationMT">{watch("IdentificationMT") ? "Oui" : "Non"}</label>

                            </div>
                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="AlerteService" className="col-8 px-0 form-label">Alertes service</label>

                            <div className="col-4 form-check form-switch px-0 text-end">
                                <input className="form-check-input ms-0 me-5" type="checkbox" role="switch" id="AlerteService" {...register("AlerteService")} />
                                <label className="form-check-label" htmlFor="alerteService">{watch("AlerteService") ? "Oui" : "Non"}</label>
                            </div>

                        </div>


                        <div className="mb-2 row mx-0">

                            <label htmlFor="Pneus" className="col-8 px-0 form-label">Pneus</label>

                            <div className="col-4 px-0 px-0">
                                <select className="form-select" id="Pneus" {...register("Pneus")}>
                                    {tires.map((t, k) => {
                                        return <option key={k} value={t}>{t}</option>;
                                    })}
                                </select>
                            </div>

                        </div>


                        <div className="mb-2 row mx-0">

                            <label htmlFor="ChangementPneus" className="col-8 px-0 form-label">Changement de pneus prévu</label>

                            <div className="col-4 px-0">
                                <select className="form-select" id="ChangementPneus" {...register("ChangementPneus")}>
                                    <option value="null">Non prévu</option>
                                    {tiresChanging.map((cp, k) => {
                                        return <option key={k} value={cp}>{cp}</option>;
                                    })}
                                </select>
                            </div>

                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="Rangement" className="col-8 px-0 form-label">Rangement</label>

                            <div className="col-4 px-0">
                                <input type="range" className="form-range" min="1" max="3" step="1" id="Rangement" list="tickmarks-Rangement" {...register("Rangement")} />

                                <datalist id="tickmarks-Rangement">
                                    <option value="1" label="1"></option>
                                    <option value="2"></option>
                                    <option value="3" label="3"></option>
                                </datalist>
                            </div>

                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="PropreteExterieur" className="col-8 px-0 form-label">Propreté extérieur</label>

                            <div className="col-4 px-0">
                                <input type="range" className="form-range" min="1" max="3" step="1" id="PropreteExterieur" list="tickmarks-propreteExterieur" {...register("PropreteExterieur")} />

                                <datalist id="tickmarks-propreteExterieur">
                                    <option value="1" label="1"></option>
                                    <option value="2"></option>
                                    <option value="3" label="3"></option>
                                </datalist>
                            </div>

                        </div>

                        <div className="mb-2 row mx-0">

                            <label htmlFor="PropreteInterieur" className="col-8 px-0 form-label">Propreté intérieur</label>

                            <div className="col-4 px-0">
                                <input type="range" className="form-range" min="1" max="3" step="1" id="PropreteInterieur" list="tickmarks-propreteInterieur" {...register("PropreteInterieur")} />

                                <datalist id="tickmarks-propreteInterieur">
                                    <option value="1" label="1"></option>
                                    <option value="2"></option>
                                    <option value="3" label="3"></option>
                                </datalist>
                            </div>

                        </div>

                        {/*
                            <div className="mb-2 row mx-0">

                                <label htmlFor="images" className="col-6 px-0 form-label">Dégâts (images)</label>

                                <div className="col-6 px-0">
                                    <input className="form-control" type="file" id="input-Images" multiple />
                                </div>
                            </div>
                        */}

                        <div className="mb-3">

                            <label htmlFor="Note" className="form-label">Remarques</label>

                            <textarea id="input-Note" rows={5} {...register("Note")}></textarea>

                        </div>


                        <div className="text-end">
                            <ButtonSubmit />
                        </div>
                    </form>

                    <div id="alert" className="d-none alert alert-danger align-self-end position-absolute w-auto" role="alert">
                        A simple danger alert—check it out!
                    </div>

                </div>

            </div>
        </section >
    </section >
}


function selectService(services, handleChange) {
    return <select name="select-zipcode" className="form-select" id="managementID" defaultValue="0" onChange={() => { handleChange() }}>
        <option hidden disabled value="0"> -- Séléctionner une option -- </option>
        {services.map((m, k) => {
            return <option key={k} value={m.ManagementID}>{m.Service}</option>;
        })}
    </select>
}