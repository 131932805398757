import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../../common/common";
import { useParams } from "react-router-dom";

export default function ClientAddressAdd() {
    const navigate = useNavigate()
    const params = useParams()
    const idManagement = params.idManagement
    const idOwner = params.idOwner

    const LinkBack = () => {
        if (idManagement)
            return <Link to={"/client/management/" + idManagement + "/address"} className="link-nav">{"<"} Retour</Link>
        else if (idOwner)
            return <Link to={"/client/owner/" + idOwner + "/address"} className="link-nav">{"<"} Retour</Link>
        navigate(-1)
    }

    return <section className="body-container">

        {Header(
            <h2 className="section-title">Ajouter une adresse {(idManagement && "à la régie") || (idOwner && "au propriétaire")}</h2>,
            LinkBack()
        )}

        <section className="body">
        </section>

    </section>
}