import React, { useEffect, useState, useMemo } from 'react'
import { Link, NavLink, useLocation, Outlet, useParams } from 'react-router-dom'
import $ from 'jquery'

import { reqGet } from "../../../../service/apiRequest";
import { StorageAvailabilityBadge } from '../../storage/storage';
import { fuzzyFilter, PrivateLinkAdd, ProgressColor, DebouncedInput, Header } from '../../../common/common'
import { LAYOUT, DEFAULT_IMG, GROUP, PERMISSION, ICONS } from '../../../common/constants'
import { IBrand, IExchanger, IProduct } from '../../../common/types'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
} from '@tanstack/react-table'


export default function Exchanger({ reload, setReload }) {
    const location = useLocation()

    const table = ExchangerTable(reload, setReload)
    const [brands, setBrands] = useState<Array<IBrand>>([])
    const [exchangers, setExchangers] = useState<Array<IExchanger>>([])

    useEffect(() => {
        reqGet("brand").then(res => setBrands(res))
        reqGet("exchanger").then(res => setExchangers(res))
    }, [])

    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/exchanger") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    function FilterBrand(brand: string) {
        table.getColumn("ProductBrand")?.setFilterValue(brand)
    }

    function FilterLabel(label: string) {
        table.getColumn("ProductLabel")?.setFilterValue(label)
    }

    function FilterStatus(id) {
        table.getColumn("ProductAvailable")?.setFilterValue(id)
    }

    return <div className="row h-100">

        <div className={display + " col-md-6 col-lg-7 col-xl-8 col-h-fill px-0 pe-lg-3"}>
            <div className="body-container">

                {Header(
                    <div>
                        <h3 className="section-title mb-1">Echangeurs</h3>
                        <Link to="template" className="link-nav">Modèle d'échangeur {">"}</Link>
                    </div>,
                    undefined,
                    PrivateLinkAdd("add", GROUP.crud_sav, PERMISSION.product)
                )}



                <section className='container-fluid'>
                    <div className="row mb-3">

                        <div className="col col-lg-3">
                            <label className="text-light mb-1">Marque</label>
                            <select className="form-select" onChange={(e) => FilterBrand(e.target.value)}>
                                <option value="">Tout</option>

                                {brands.map((b: IBrand) => {
                                    return <option value={b.Name} key={b.BrandID}>{b.Name}</option>
                                })}
                            </select>
                        </div>

                        <div className="col col-lg-3">
                            <label className="text-light mb-1">Label</label>
                            <select className="form-select" onChange={(e) => FilterLabel(e.target.value)}>
                                <option value="">Tout</option>

                                {exchangers.map((e: IExchanger) => {
                                    return <option value={e.ExchangerLabel} key={e.ExchangerID}>{e.ExchangerLabel}</option>
                                })}
                            </select>
                        </div>

                        <div className="col-lg offset-lg-1 mt-3 mt-lg-0 h-lg-100 d-flex align-items-end">
                            <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche" />
                        </div>

                    </div>
                </section>



                <div className="body container-fluid">

                    <div className="list list-hover">

                        {table.getRowModel().rows.map(row => {
                            return <NavLink to={row.original.ProductID.toString()} key={row.original.ProductID} className="list-item mb-3" >
                                <ExchangerItem product={row.original} />
                            </NavLink>
                        })}

                    </div>


                </div>

                <footer className="container-fluid mt-3">
                    <div className="row mx-0">

                        <div className="col-8 col-lg-4 px-0 text-start order-2 order-lg-1">
                            <button className="btn btn-light me-2" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                                {'<<'}
                            </button>
                            <button className="btn btn-light me-2" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                                {'<'}
                            </button>

                            <p className="d-inline mb-0 me-2">
                                <span className="text-muted">Page {' '}</span>
                                {table.getState().pagination.pageIndex + 1}
                                <span className="text-muted">{' '} sur {' '}</span>
                                {table.getPageCount()}
                            </p>

                            <button className="btn btn-light me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                                {'>'}
                            </button>
                            <button className="btn btn-light me-2" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                                {'>>'}
                            </button>
                        </div>

                        <div className="col-12 col-lg-4 text-center order-1 order-lg-2 mb-3 mb-lg-0">
                            <p className="text-muted mb-0">
                                {table.getCoreRowModel().rows.length} pièces - {table.getCoreRowModel().rows.reduce((accumulator, row) => {
                                    return accumulator + parseInt(row.original.Quantity ?? "0");
                                }, 0)} éléments
                            </p>
                        </div>

                        <div className="col col-lg-4 pe-0 tex-end order-3">
                            <select className="form-select float-end w-auto" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                                {[50, 100, 200].map(pageSize => (

                                    <option key={pageSize} value={pageSize}>
                                        Afficher {pageSize}
                                    </option>

                                ))}

                            </select>
                        </div>

                    </div>

                </footer>

            </div>


        </div>

        <div className="col-md-6 col-lg-5 col-xl-4 col-h-fill px-0">
            <Outlet />
        </div>

    </div >
}

function ExchangerItem({ product }) {

    return <>
        <div className="list-item-start">
            <div className="item-start">
                {product?.ProductImage ?
                    <img className="img-fluid" src={product?.ProductImage ? "../media/images/product/" + product?.ProductImage : DEFAULT_IMG} alt="" />
                    : <div className="bg-background p-2 rounded-5 h-100 w-100 d-flex align-items-center justify-content-center">
                        <i className={ICONS.EXCHANGER + " text-muted"} style={{ fontSize: "1.6rem" }}></i>
                    </div>
                }
            </div>
        </div>

        <section className="list-item-data pe-md-3">
            <div className="row d-flex">

                <div className="col-8 col-sm-6">
                    <h6 className="title text-truncate">{product?.ProductLabel}: {product?.ProductModel}</h6>
                    <p className="text-muted mb-1">
                        {product?.ProductBrand} {product?.ProductBrand && product?.ProductReference && " - "} {product?.ProductReference}
                    </p>
                </div>

                <div className="col-4 col-sm-6 text-end">

                    <div className="row justify-content-end row-cols-sm-3">

                        <div className="col">
                            <h6 className="text-light">Status</h6>
                            <p className="paragraph">
                                {StorageAvailabilityBadge(product.ProductAvailable)}
                            </p>
                        </div>
                        <div className="col d-none d-sm-block">
                            <h6 className="text-light">Entrepôt</h6>
                            <p className="paragraph text-truncate">{product?.WarehouseName}</p>
                        </div>

                        <div className="col d-none d-sm-block">
                            <h6 className="text-light">Stockage</h6>
                            <p className="paragraph text-truncate">
                                {product?.WarehouseName ?
                                    product?.Rack + product?.Row + "-" + product?.Col + (product?.Compartment ? "-" + product?.Compartment : "") :
                                    ""
                                }
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </section>

        <div className="tool-more d-flex d-md-none">
            <i className="bi bi-chevron-right"></i>
        </div>
    </>
}

export function ExchangerTable(reload?: boolean, setReload?) {

    const [data, setData] = useState<Array<IProduct>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const columns = useMemo<ColumnDef<IProduct, any>[]>(
        () => [
            {
                accessorFn: row => row.ProductModel,
                id: 'ProductModel',
                header: 'Modèle',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductLabel,
                id: 'ProductLabel',
                header: 'Label',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductBrand,
                id: 'ProductBrand',
                header: 'Marque',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductAvailable,
                id: 'ProductAvailable',
                header: 'Status',
                cell: info => info.getValue()
            }

        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        table.setPageSize(50)

        reqGet('product/exchanger').then((res) => {
            setData(res);
        })
    }, [])

    useEffect(() => {
        if (reload) {
            reqGet('product/exchanger').then((res) => { setData(res); })
            setReload(false)
        }
    }, [reload])

    return table
}