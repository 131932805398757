import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import $ from "jquery";

import { reqGet, reqDelete } from "../../../service/apiRequest";
import { getToken } from '../../../useToken'
import { PrivateComponent, PrivateLinkAdd, PrivateLinkUpdate, Header, dateFormat } from "../../common/common";
import { LAYOUT, GROUP, PERMISSION } from "../../common/constants"
import { IVehicle, IVehicleCheck } from "../../common/types"
import { date } from "yup";

export function VehicleChecking(idVehicle, vehicle: IVehicle | null = null) {
    const token = getToken()
    const navigate = useNavigate()

    const [reload, setReload] = useState<boolean>(false)
    const [vehicleCheck, setVehicleCheck] = useState<Array<IVehicleCheck> | null>()
    const [vehicleCheckData, setVehicleCheckData] = useState<IVehicleCheck | null>()

    useEffect(() => {

        if (idVehicle)
            reqGet("vehiclecheck/vehicle/" + idVehicle).then((res) => {
                setVehicleCheck(res)
                setVehicleCheckData(res[0])

            }, (rej) => {
                setVehicleCheck(null)
                setVehicleCheckData(null)
            })

    }, [idVehicle])

    useEffect(() => {

        if (reload) {
            if (idVehicle) {
                reqGet("vehiclecheck/vehicle/" + idVehicle).then((res) => {
                    setVehicleCheck(res)
                    setVehicleCheckData(res[0])

                }, (rej) => {
                    setVehicleCheck(null)
                    setVehicleCheckData(null)
                })
            }
            setReload(false)
        }

    }, [reload])

    function HandleSelection(v, e) {
        $('#vehicleCheckList .list-group-item.active').removeClass('active')
        e.currentTarget.classList.add('active')

        setVehicleCheckData(v)
    }

    function TiresDot(tires, date) {
        const dateArray = date.split(".")

        const checkDate = new Date(dateArray[2], dateArray[1] - 1, dateArray[0])
        // if date is bigger than 14 may set actual year otherwise set past year
        const dateWinter = new Date(parseInt(dateArray[2]) - (parseInt(dateArray[1]) >= 5 ? 0 : 1), 10, 14)
        // if date is lower than 14 may set actual year otherwise set next year
        const dateSummer = new Date(parseInt(dateArray[2]) + (parseInt(dateArray[1]) <= 5 ? 0 : 1), 4, 14)

        const dot = <div className="dot me-3 bg-warning"></div>

        if (tires != "Quatre saisons") {
            if (checkDate > dateWinter && checkDate <= dateSummer) {

                if (tires != "Hiver") {
                    return dot
                }
            } else if (tires != "Eté") {
                return dot
            }
        }
        return ""
    }


    function removeVehicleCheck() {
        if (vehicleCheckData)
            reqDelete("vehiclecheck/" + vehicleCheckData?.VehicleCheckID, { data: { ...vehicleCheckData, ActivityUserID: token.UserID } }).then(res => {
                setReload(true)
            })
    }

    if (vehicleCheck) {
        return <>

            <div className="modal fade" id="removeVehicleCheckMsg" tabIndex={-1} aria-labelledby="removeVehicleCheck" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="removeVehicleCheck">Supprimer</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Êtes vous  sur de vouloir supprimer :</p>
                            <p>Le contrôle du <b>{vehicleCheckData?.DateCheck}</b> fait par <b>{vehicleCheckData?.FirstName} {vehicleCheckData?.Name}</b></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => removeVehicleCheck()}>Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex mb-4">
                <ul className="nav-horizontal hide-scrollbar flex-grow-1 mb-0" id="navCheckType">

                    <button className="btn nav-link active">Contrôle</button>

                    {PrivateComponent(
                        <button className="btn nav-link">Stock</button>,
                        GROUP.dev
                    )}
                    {PrivateComponent(
                        <button className="btn nav-link">Inventaire</button>,
                        GROUP.dev
                    )}
                </ul>
                <div>
                    {PrivateComponent(
                        <Link to="check-add" className="btn btn-outline">
                            <i className="bi bi-plus-lg me-1"></i> Contrôle
                        </Link>
                    )}
                </div>
            </div>

            <section className="row justify-content-center">

                <div className="col-xxl-10">

                    <div className="card mb-3">
                        <div className="card-body">

                            <div className="row">
                                <label htmlFor="dateCheck" className="col-6 col-form-label form-label">Date</label>

                                <div className="col">
                                    <select name="select-zipcode" className="form-select" id="dateCheck" defaultValue="0" onChange={(e) => { HandleSelection(vehicleCheck[$("#dateCheck").val()], e) }}>

                                        {vehicleCheck?.map((v, k) => {
                                            return <option key={k} value={k}>{dateFormat(v.DateCheck, "dd/MM/yyyy")}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>


                        </div>
                    </div>

                    {vehicleCheckData &&
                        <>

                            <div className="row px-0 mb-3">
                                <div className="col">
                                    <label className="form-label mb-1">Réalisé par</label>
                                    <p className="mb-0 text-nowrap">{vehicleCheckData?.FirstName + " " + vehicleCheckData?.Name}</p>
                                </div>

                                <div className="col text-end">
                                    {PrivateComponent(
                                        <button className="btn btn-delete ms-3" title="Supprimer" data-bs-toggle="modal" data-bs-target="#removeVehicleCheckMsg">
                                            <i className="bi bi-trash3 me-1"></i> Supprimer ce contrôle
                                        </button>,
                                        GROUP.admin
                                    )}
                                </div>
                            </div>


                            <div className="card">

                                <header className="card-header">
                                    <h2 className="card-title">Remarque</h2>
                                </header>

                                <section className="card-body">
                                    <p className="mb-0">{vehicleCheckData.Note}</p>
                                </section>

                            </div>



                            <p className="d-inline-block text-muted"><i>
                                Les champs marqués d'un <span className="dot bg-warning mx-1"></span> ou d'un <span className="dot bg-danger mx-1"></span> doivent être corrigé suivant les consignes et les délais défini avec le répondant.
                            </i></p>

                            <div className="card">

                                <div className="card-header">
                                    <h5 className="card-title">Equipement</h5>
                                </div>

                                <div className="card-body">
                                    <ul className="list-group list-borderless">


                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Kilométrage</div>
                                                <div className="col-4 text-end">{vehicleCheckData["Km"]?.toLocaleString('en-US').replace(/,/g, "'")} Km</div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Prochain service</div>
                                                <div className="col-4 text-end">
                                                    {(vehicleCheckData["ProchainService"] ?? 0) <= (vehicleCheckData["Km"] ?? 0) && <div className="dot me-3 bg-warning"></div>}
                                                    {vehicleCheckData["ProchainService"]?.toLocaleString('en-US').replace(/,/g, "'")} Km</div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Dernier service (date)</div>
                                                <div className="col-4 text-end">{dateFormat(vehicleCheckData["DernierService"], "dd/MM/yyyy")}</div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Dernier contrôle (bureau des autos)</div>
                                                <div className="col-4 text-end">{dateFormat(vehicleCheckData["DernierControle"], "dd/MM/yyyy")}</div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Copie du permis de circulation</div>
                                                <div className="col-4 text-end">
                                                    {vehicleCheckData["Permis"] == 0 && <div className="dot me-3 bg-warning"></div>}
                                                    {vehicleCheckData["Permis"] == 0 ? 'Non' : vehicleCheckData["Permis"] == 1 ? 'Oui' : vehicleCheckData["Permis"]}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Carte verte assurance</div>
                                                <div className="col-4 text-end">
                                                    {vehicleCheckData["CarteVerte"] == 0 && <div className="dot me-3 bg-warning"></div>}
                                                    {vehicleCheckData["CarteVerte"] == 0 ? 'Non' : vehicleCheckData["CarteVerte"] == 1 ? 'Oui' : vehicleCheckData["CarteVerte"]}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Carnet de service</div>
                                                <div className="col-4 text-end">
                                                    {vehicleCheckData["CarnetService"] == 0 && <div className="dot me-3 bg-warning"></div>}
                                                    {vehicleCheckData["CarnetService"] == 0 ? 'Non' : vehicleCheckData["CarnetService"] == 1 ? 'Oui' : vehicleCheckData["CarnetService"]}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Vignette</div>
                                                <div className="col-4 text-end">
                                                    {parseInt(vehicleCheckData.DateCheck?.split(".")[2] ?? "") > (vehicleCheckData?.Vignette ?? 0) && <div className="dot me-3 bg-danger"></div>}
                                                    {vehicleCheckData["Vignette"] == 0 ? 'Non' : vehicleCheckData["Vignette"] == 1 ? 'Oui' : vehicleCheckData["Vignette"]}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Pneu de réserve</div>
                                                <div className="col-4 text-end">
                                                    {vehicleCheckData["PneuReserve"] == 0 && <div className="dot me-3 bg-danger"></div>}
                                                    {vehicleCheckData["PneuReserve"] == 0 ? 'Non' : vehicleCheckData["PneuReserve"] == 1 ? 'Oui' : vehicleCheckData["PneuReserve"]}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Kit de réparation des pneus</div>
                                                <div className="col-4 text-end">{vehicleCheckData["KitReparationPneu"] == 0 ? 'Non' : vehicleCheckData["KitReparationPneu"] == 1 ? 'Oui' : vehicleCheckData["KitReparationPneu"]}</div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Ampoules de réserve</div>
                                                <div className="col-4 text-end">
                                                    {vehicleCheckData["AmpoulesReserve"] == 0 && <div className="dot me-3 bg-warning"></div>}
                                                    {vehicleCheckData["AmpoulesReserve"] == 0 ? 'Non' : vehicleCheckData["AmpoulesReserve"] == 1 ? 'Oui' : vehicleCheckData["AmpoulesReserve"]}</div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Triangle de panne</div>
                                                <div className="col-4 text-end">
                                                    {vehicleCheckData["TrianglePanne"] == 0 && <div className="dot me-3 bg-danger"></div>}
                                                    {vehicleCheckData["TrianglePanne"] == 0 ? 'Non' : vehicleCheckData["TrianglePanne"] == 1 ? 'Oui' : vehicleCheckData["TrianglePanne"]}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                </div>

                            </div>

                            <div className="card">

                                <div className="card-header">
                                    <h5 className="card-title">EPI</h5>
                                </div>

                                <div className="card-body">

                                    <ul className="list-group list-borderless">
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Extincteur</div>
                                                <div className="col-4 text-end">
                                                    {vehicleCheckData["Extincteur"] == 0 && <div className="dot me-3 bg-danger"></div>}
                                                    {vehicleCheckData["Extincteur"] == 0 ? 'Non' : vehicleCheckData["Extincteur"] == 1 ? 'Oui' : vehicleCheckData["Extincteur"]}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Pharmacie</div>
                                                <div className="col-4 text-end">
                                                    {vehicleCheckData["Pharmacie"] == 0 && <div className="dot me-3 bg-danger"></div>}
                                                    {vehicleCheckData["Pharmacie"] == 0 ? 'Non' : vehicleCheckData["Pharmacie"] == 1 ? 'Oui' : vehicleCheckData["Pharmacie"]}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Harnais de sécurité</div>
                                                <div className="col-4 text-end">
                                                    {vehicle?.Assignment == "Chantier" && vehicleCheckData["HarnaisSecurite"] == 0 && <div className="dot me-3 bg-danger"></div>}
                                                    {vehicleCheckData["HarnaisSecurite"] == 0 ? 'Non' : vehicleCheckData["HarnaisSecurite"] == 1 ? 'Oui' : vehicleCheckData["HarnaisSecurite"]}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Casque de chantier</div>
                                                <div className="col-4 text-end">
                                                    {vehicle?.Assignment == "Chantier" && vehicleCheckData["CasqueChantier"] == 0 && <div className="dot me-3 bg-danger"></div>}
                                                    {vehicleCheckData["CasqueChantier"] == 0 ? 'Non' : vehicleCheckData["CasqueChantier"] == 1 ? 'Oui' : vehicleCheckData["CasqueChantier"]}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Numéro d'urgence</div>
                                                <div className="col-4 text-end">
                                                    {vehicleCheckData["NumeroUrgence"] == 0 && <div className="dot me-3 bg-danger"></div>}
                                                    {vehicleCheckData["NumeroUrgence"] == 0 ? 'Non' : vehicleCheckData["NumeroUrgence"] == 1 ? 'Oui' : vehicleCheckData["NumeroUrgence"]}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                </div>

                            </div>

                            <div className="card">

                                <div className="card-header">
                                    <h5 className="card-title">Etat</h5>
                                </div>

                                <div className="card-body">
                                    <ul className="list-group list-borderless">
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Porte-charge</div>
                                                <div className="col-4 text-end">{vehicleCheckData["PorteCharge"] == 0 ? 'Non' : vehicleCheckData["PorteCharge"] == 1 ? 'Oui' : vehicleCheckData["PorteCharge"]}</div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Identification Multitherme</div>
                                                <div className="col-4 text-end">{vehicleCheckData["IdentificationMT"] == 0 ? 'Non' : vehicleCheckData["IdentificationMT"] == 1 ? 'Oui' : vehicleCheckData["IdentificationMT"]}</div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Alerte service</div>
                                                <div className="col-4 text-end">
                                                    {vehicleCheckData["AlerteService"] == 1 && <div className="dot me-3 bg-danger"></div>}
                                                    {vehicleCheckData["AlerteService"] == 0 ? 'Non' : vehicleCheckData["AlerteService"] == 1 ? 'Oui' : vehicleCheckData["AlerteService"]}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Pneus</div>
                                                <div className="col-4 text-end">
                                                    {TiresDot(vehicleCheckData["Pneus"], vehicleCheckData.DateCheck)}
                                                    {vehicleCheckData["Pneus"]}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Changement des pneus</div>
                                                <div className="col-4 text-end">{vehicleCheckData["ChangementPneus"]}</div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Rangement (1-3)</div>
                                                <div className="col-4">
                                                    <input type="range" className="form-range" min="1" max="3" step="1" value={vehicleCheckData["Rangement"] ?? 1} disabled />
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Propreté extérieur (1-3)</div>
                                                <div className="col-4">
                                                    <input type="range" className="form-range" min="1" max="3" step="1" value={vehicleCheckData["PropreteExterieur"] ?? 1} disabled />
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-8 text-muted">Propreté intérieur (1-3)</div>
                                                <div className="col-4">
                                                    <input type="range" className="form-range" min="1" max="3" step="1" value={vehicleCheckData["PropreteInterieur"] ?? 1} disabled />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </>
                    }
                </div>
            </section>
        </>
    } else {
        return <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center text-center">
            <p>Il n'y pas encore de contrôle pour ce véhicule</p>
            {PrivateComponent(
                <Link className="btn btn-primary" to="check-add">
                    <i className="bi bi-plus-lg me-1"></i> Effectuer contrôle
                </Link>
            )}

        </div>
    }

}
