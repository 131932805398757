import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { reqGet, reqPost, reqPut } from '../../../../service/apiRequest'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { IAddress, IHeating, IManagement, IOwner } from "../../../common/types"
import { ButtonSubmit, Header } from '../../../common/common'

interface IAddressHeating extends IAddress, IHeating { }

export default function HeatingUpdate() {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idManagement = params.idManagement
    const idOwner = params.idOwner
    const idHeating = params.idHeating

    const content = useOutletContext<IManagement | IOwner>()
    
    const [addresses, setAddresses] = useState<Array<IAddressHeating>>([])
    const [heating, setHeating] = useState<IHeating>()

    const validationSchema = yup.object({
        HeatingDate: yup.string().required("Ce champ est obligatoire"),
        HeatingAction: yup.string().required("Ce champ est obligatoire")
    }).required();


    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IHeating>({
        mode: "onTouched",
        resolver: yupResolver(validationSchema)
    });

    const LinkBack = () => {
        if (idManagement)
            return <Link to={"/client/management/" + idManagement + "/heating"} className="link-nav">{"<"} Retour</Link>
        else if (idOwner)
            return <Link to={"/client/owner/" + idOwner + "/heating"} className="link-nav">{"<"} Retour</Link>
        navigate(-1)
    }

    useEffect(() => {
        if (!idManagement && !idOwner)
            navigate(-1)
        else {
            reqGet("heating/" + idHeating).then(res => {
                setHeating(res)
                setValue("HeatingAction", res.HeatingAction)
                setValue("HeatingDate", res.HeatingDate)

                if (idManagement)
                    reqGet("address/management/" + idManagement + "/heatingdate/" + res.HeatingDate).then(res => setAddresses(res))

                else if (idOwner)
                    reqGet("address/owner/" + idOwner + "/heatingdate/" + res.HeatingDate).then(res => setAddresses(res))
            })

        }

    }, [])

    const onSubmit: SubmitHandler<IHeating> = data => {

        if (idManagement)
            reqPut('heating/management/' + idManagement + "/heatingdate/" + heating?.HeatingDate, { ...data, ...content, ActivityUserID: token.UserID }).then((res) => {
                navigate('/client/management/' + idManagement + "/heating")
            })
        else if (idOwner)
            reqPut('heating/owner/' + idOwner + "/heatingdate/" + heating?.HeatingDate, { ...data, ...content, ActivityUserID: token.UserID }).then((res) => {
                navigate('/client/owner/' + idOwner + "/heating")
            })
    };

    return (
        <section className="body-container h-100">
            {Header(
                <h3 className="section-title">Modifier {heating?.HeatingAction == "Start" ? " la mise en route " : " l'arrêt "} du chauffage</h3>,
                LinkBack()
            )}

            <section className="body container-fluid">

                <form onSubmit={handleSubmit(onSubmit)}>

                    <section className="row row-cols-2 mb-3">
                        <div className="col">
                            <label className="form-label">Date *</label>

                            <input type="date" className={"form-control " + (errors?.HeatingDate ? "is-invalid" : "")} {...register("HeatingDate")} />

                            {errors.HeatingDate?.message && <p className="input-msg-error">{errors.HeatingDate?.message}</p>}
                        </div>

                        <div className="col">
                            <label className="form-label">Action *</label>

                            <select className={"form-select " + (errors?.HeatingAction ? "is-invalid" : "")} {...register("HeatingAction")} >
                                <option value="Start">Mise en route</option>
                                <option value="Stop">Arrêt</option>
                            </select>

                            {errors.HeatingAction?.message && <p className="input-msg-error">{errors.HeatingAction?.message}</p>}
                        </div>
                    </section>



                    <div className="text-end">
                        <p><small>Les champs avec une * sont obligatoire</small></p>
                        <ButtonSubmit />
                    </div>

                </form>


                {addresses?.length > 0 && <>
                    <h3 className='section-title-2'>Adresses concernées</h3>

                    {addresses?.filter(a => !a.ObservationID) &&
                        <div className='card'>
                            <table className='table mb-0'>
                                {addresses?.filter(a => !a.ObservationID).map((a) => {
                                    return <tr>
                                        <td key={a.AddressID}>{a.AddressLine}, {a.ZipCode}, {a.City}</td>
                                    </tr>
                                })}
                            </table>
                        </div>
                    }


                    {addresses?.filter(a => a.ObservationID)?.length > 0 &&
                        <>
                            <h4 className='section-subtitle'>Adresses ayant déja efféctué {heating?.HeatingAction == "Start" ? "la mise en route" : "l'arrêt"} du chauffage</h4>

                            <div className='card'>
                                <table className='table mb-0'>
                                    {addresses?.filter(a => a.ObservationID).map((a) => {
                                        return <tr>
                                            <td key={a.AddressID}>{a.AddressLine}, {a.ZipCode}, {a.City}</td>
                                        </tr>
                                    })}
                                </table>
                            </div>
                        </>
                    }
                </>
                }
            </section>
        </section >
    );
}
