import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import $ from 'jquery'

import WarehouseActivity from "./warehouse-activity";
import WarehouseInsufficient from "./warehouse-insufficient"
import WarehouseOutside from "./warehouse-outside"
import { reqGet } from "../../../service/apiRequest";
import { CardBtnLink, Header, PrivateComponent, PrivateLinkUpdate } from "../../common/common";
import { GROUP, PERMISSION, LAYOUT, ICONS } from "../../common/constants"
import { IWarehouse, IWarehouseStats } from "../../common/types";

export default function StorageDashboard() {
    const params = useParams()
    const idWarehouse = params.idWarehouse
    const navigate = useNavigate()
    const location = useLocation()

    const [displayOutlet, setDisplayOutlet] = useState<boolean>(false)
    const [warehouse, setWarehouse] = useState<IWarehouse>()
    const [warehouseStats, setWarehouseStats] = useState<IWarehouseStats>({ NbInsufficient: 0, NbOut: 0, NbRepairing: 0, TotalPrice: 0, TotalPriceBuying: 0 })

    useEffect(() => {
        reqGet("warehouse/" + idWarehouse).then(res => {
            if (res)
                setWarehouse(res)
            else
                navigate("/warehouse")
        }, rej => {
            navigate("/warehouse")
        })

        reqGet("warehouse/" + idWarehouse + "/stats").then(res => {
            setWarehouseStats(res);
        })
    }, [])

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {

            setDisplayOutlet(false)

        } else {
            if (!location.pathname.includes("activity") && !location.pathname.includes("insufficient")) {
                setDisplayOutlet(false)

            } else {
                setDisplayOutlet(true)
            }
        }
    }

    if (!displayOutlet) {

        return <div className="container-fluid h-100">

            <div className="row h-100">

                <div className="col-md-6 col-lg-7 col-xxl-8 col-h-fill overflow-hidden py-3">

                    <main className="body-container overflow-hidden">

                        <header className="header">
                            <div>
                                <h2 className="section-title d-inline me-2">{warehouse?.WarehouseName}</h2>
                                <h4 className="section-subtitle d-inline mb-0">{warehouse?.WarehouseAddress}</h4>
                                <Link className="link-nav d-block" to="/warehouse">{"<"} Retour</Link>
                            </div>

                            {PrivateLinkUpdate("update", GROUP.admin)}
                        </header>

                        {warehouse?.WarehouseAddress &&
                            <a href={"https://www.google.com/maps/search/?api=1&query=" + warehouse?.WarehouseAddress.replace(" ", "+")} target="_blank" className="link-nav mb-3"><i className={ICONS.MAP + " me-2"}></i>Voir sur la carte {">"}</a>
                        }

                        <section className="body container-fluid">


                            <section className="row">
                                {PrivateComponent(
                                    <>
                                        <div className="col-6 col-lg-4">
                                            <div className="card">
                                                <header className="card-header">
                                                    <h5 className="card-title">Insuffisant</h5>
                                                    <Link to="insufficient" className="link-nav">Détails {">"}</Link>
                                                </header>
                                                <section className="card-body">
                                                    <h2 className="d-inline mb-0 me-2">{warehouseStats?.NbInsufficient}</h2>
                                                    <p className="text-muted d-inline mb-0"><small>emplacement{warehouseStats?.NbInsufficient > 1 && "s"}</small></p>
                                                </section>
                                            </div>
                                        </div>

                                        <div className="col-6 col-lg-4">
                                            <div className="card">
                                                <header className="card-header">
                                                    <h5 className="card-title">Sorti</h5>
                                                    <Link to="outside" className="link-nav">Détails {">"}</Link>
                                                </header>
                                                <section className="card-body">
                                                    <h2 className="d-inline mb-0 me-2">{warehouseStats?.NbOut}</h2>
                                                    <p className="text-muted d-inline mb-0"><small>outil{warehouseStats?.NbOut > 1 && "s"}</small></p>
                                                </section>
                                            </div>
                                        </div>

                                        <div className="col-6 col-lg-4">
                                            <div className="card">
                                                <header className="card-header">
                                                    <h5 className="card-title">Maintenance</h5>
                                                    <Link to="restore" className="link-nav">Détails {">"}</Link>
                                                </header>
                                                <section className="card-body">
                                                    <h2 className="d-inline mb-0 me-2">{warehouseStats?.NbRepairing}</h2>
                                                    <p className="text-muted d-inline mb-0"><small>outil{warehouseStats?.NbRepairing > 1 && "s"}</small></p>
                                                </section>
                                            </div>
                                        </div>

                                    </>,
                                    GROUP.admin,
                                    PERMISSION.storage
                                )}

                                <div className="col col-lg-4 pb-3">
                                    {PrivateComponent(
                                        <Link to="storage" className="card bg-primary h-100 m-0">
                                            <div className="d-flex h-100 flex-column justify-content-center align-items-center">
                                                <h1><i className={ICONS.STORAGE}></i></h1>

                                                <h5 className="card-title mb-0">Stockage</h5>
                                            </div>
                                        </Link>
                                    )}
                                </div>

                                <div className="col-12 col-md-6 col-lg-4">
                                    {PrivateComponent(
                                        <CardBtnLink path="takeout" title="Retirer/Déplacer" icon={ICONS.TAKEOUT} />
                                    )}

                                    {PrivateComponent(
                                        <CardBtnLink path="putback" title="Déposer" icon={ICONS.PUTBACK} />
                                    )}

                                </div>

                                <div className="col-12 col-md-6 col-lg-4">
                                    {PrivateComponent(
                                        <CardBtnLink path="inventory-picking" title="Inventaire picking" icon={ICONS.INVENTORY_PICKING} />,
                                        GROUP.admin,
                                        ["InventoryPicking"]
                                    )}

                                    {PrivateComponent(
                                        <CardBtnLink path="resupply" title="Réapprovisionnement" icon={ICONS.RESUPPLY} />,
                                        GROUP.admin,
                                        ["InventoryResupply"]
                                    )}
                                </div>

                                {PrivateComponent(
                                    <div className="col-12 d-md-none">
                                        <CardBtnLink path="activity" title="Activité" icon={ICONS.ACTIVITY} />
                                    </div>,
                                    GROUP.admin,
                                    PERMISSION.storage
                                )}

                            </section>

                            {/* PrivateComponent(
                                <>

                                    <h3 className="section-title">Valeur du stock</h3>

                                    <div className="row row-cols-2 row-cols-lg-3">
                                        <div className="col">
                                            <div className="card">
                                                <header className="card-header">
                                                    <h5 className="card-title">Total prix d'achat</h5>
                                                </header>

                                                <section className="card-body">
                                                    <h2 className="mb-0">{warehouseStats?.TotalPriceBuying?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, "'")}</h2>
                                                </section>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card">
                                                <header className="card-header">
                                                    <h5 className="card-title">Total prix de vente</h5>
                                                </header>

                                                <section className="card-body">
                                                    <h2 className="mb-0">{warehouseStats?.TotalPrice?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, "'")}</h2>
                                                </section>
                                            </div>
                                        </div>
                                    </div>

                                </>
                                , GROUP.admin, ["PriceRead"]
                            )*/}
                        </section>

                    </main>
                </div>

                {PrivateComponent(
                    <aside className="col-md-6 col-lg-5 col-xxl-4 col-h-fill d-none d-md-flex border-left">
                        <WarehouseActivity />
                    </aside>,
                    GROUP.admin,
                    PERMISSION.storage
                )}

            </div>
        </div>
    } else {
        return <>
            <Outlet />
        </>
    }
}



