import React, { useState, useEffect } from 'react'
import { NavLink, Link, useParams, useNavigate } from 'react-router-dom'
import { reqGet, reqPut } from '../../../service/apiRequest'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { ButtonSubmit, ConvertBytes, FormatDocumentName, Header, HeaderRow } from '../../common/common'
import { getToken } from '../../../useToken'
import { IUserRole, IUser, IDocument } from '../../common/types'
import { post } from "../../../service/request"
import { DEFAULT_IMG, DOCUMENT_SIZE_LIMIT, IMAGE_PATH } from '../../common/constants';

const validationSchema = yup.object({
    Username: yup.string().required("Ce champ est obligatoire"),
    FirstName: yup.string().required("Ce champ est obligatoire"),
    Name: yup.string().required("Ce champ est obligatoire"),
    Email: yup.string().email().nullable(),
    Phone: yup.string().nullable(),
    UserRoleID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    UserManagerID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    UserBirthday: yup.string().nullable(),
    UserDateContract: yup.string().nullable(),
    UserFrontier: yup.string().nullable(),
    UserCivilStatus: yup.string().nullable(),
    UserAddress: yup.string().nullable(),
    UserNationality: yup.string().nullable(),
    UserAVS: yup.string().nullable()
})

const initialValues = {
    Username: '',
    Email: '',
    FirstName: '',
    Name: '',
    Phone: '',
    UserManagerID: null,
    UserBirthday: null,
    UserDateContract: null,
    UserFrontier: null,
    UserCivilStatus: null,
    UserAddress: null,
    UserNationality: null,
    UserAVS: null
}


export default function AccountUpdate({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()
    const idUser = token.UserID

    const [selectedFile, setSelectedFile] = useState<File>();
    const [fileError, setFileError] = useState<string | null>("")

    const [documents, setDocuments] = useState<Array<IDocument>>([])

    useEffect(() => {
        if (selectedFile) {

            if (documents.find(d => d.DocumentName == FormatDocumentName(selectedFile?.name)))
                setFileError("Ce fichier à déjà été ajouté à cette adresse")

            else if (selectedFile.size > DOCUMENT_SIZE_LIMIT)
                setFileError("Le fichier dépasse la limite de 8 MB")

            else
                setFileError(null)

        }
    }, [selectedFile])

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const { register, formState: { errors }, handleSubmit, getValues, setValue, watch } = useForm<IUser>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [userRole, setUserRole] = useState([])
    const [user, setUser] = useState<IUser>()

    const [checkUsername, setCheckUsername] = useState<boolean | null>(null)
    useEffect(() => {

        reqGet('user/' + idUser).then(function (res) {
            setUser(res)

            setValue("UserID", res.UserID)
            setValue("Username", res.Username)
            setValue("Email", res.Email)
            setValue("FirstName", res.FirstName)
            setValue("Name", res.Name)
            setValue("Phone", res.Phone)
            setValue("UserBirthday", res.UserBirthday)
            setValue("UserDateContract", res.UserDateContract)
            setValue("UserFrontier", res.UserFrontier)
            setValue("UserCivilStatus", res.UserCivilStatus)
            setValue("UserAddress", res.UserAddress)
            setValue("UserNationality", res.UserNationality)
            setValue("UserAVS", res.UserAVS)

        })


    }, [])

    const onSubmit: SubmitHandler<IUser> = data => {

        reqPut('user/profile/' + idUser, data).then((res) => {
            navigate('/account')
            setReload(true)
        })
    }

    function CheckUsername() {
        const username = $("#username").val()

        if (user?.Username && username != user?.Username && username != "") {

            post("checkusername", { Username: username }).then((res) => {

                setCheckUsername(res)

            })
        } else {
            setCheckUsername(null)
        }
    }

    $("#username").focusout(() => CheckUsername())

    return <div className="row h-100 justify-content-center content">

        <div className="col-lg-10 col-xxl-6 col-h-fill overflow-auto">
            <section className="body-container">

                {Header(
                    <h3 className="section-title">Modifier mon profile</h3>,
                    <Link to={"/account"} className="link-nav">{'<'} Retour</Link>
                )}

                <section className="body container-fluid">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/*
                        <div className="text-center">
                            <img style={{ height: "9rem", width: "9rem", borderRadius: "4.5rem", objectFit: "cover", marginBottom: "1rem" }} src={user?.UserImage ? IMAGE_PATH.USERS + user.UserImage : DEFAULT_IMG} />
                        </div>

                        <div className="mb-5">
                            <label htmlFor="document" className="form-label">Ajouter un fichier *</label>
                            <input className={"form-control mb-1 " + (fileError !== "" && fileError !== null ? "is-invalid" : "")} type="file" id="userImg" multiple={false} onChange={(e) => changeHandler(e)} />

                            {
                                selectedFile &&
                                <div>
                                    <p className="mb-1"><b>Taille:</b> {ConvertBytes(selectedFile?.size)}</p>
                                </div>
                            }

                            {fileError != null && <p className="input-msg-error">{fileError}</p>}
                        </div>
                        */}

                        <div className="row row-cols-2 mb-3">

                            <div className="col mb-3">

                                <label htmlFor="FirstName" className="form-label">Prénom *</label>
                                <input type="text" className={"form-control " + (errors?.FirstName ? "is-invalid" : "")} {...register("FirstName")} />

                                {errors.FirstName?.message && <p className="input-msg-error">{errors.FirstName?.message}</p>}

                            </div>

                            <div className="col mb-3">

                                <label htmlFor="Name" className="form-label">Nom *</label>

                                <input type="text" className={"form-control " + (errors?.Name ? "is-invalid" : "")} {...register("Name")} />

                                {errors.Name?.message && <p className="input-msg-error">{errors.Name?.message}</p>}
                            </div>

                            <div className="col mb-3">
                                <label htmlFor="Email" className="form-label">Email</label>
                                <input type="text" className={"form-control " + (errors?.Email ? "is-invalid" : "")} {...register("Email")} />

                                {errors.Email?.message && <p className="input-msg-error">{errors.Email?.message}</p>}
                            </div>

                            <div className="col mb-3">
                                <label htmlFor="Phone" className="form-label">Téléphone</label>

                                <input type="text" className={"form-control " + (errors?.Phone ? "is-invalid" : "")} {...register("Phone")} />

                                {errors.Phone?.message && <p className="input-msg-error">{errors.Phone?.message}</p>}
                            </div>

                            <div className='col mb-3'>
                                <label htmlFor="UserBirthday" className="form-label">Date de naissance</label>

                                <input type="date" className={"form-control " + (errors?.UserBirthday ? "is-invalid" : "")} {...register("UserBirthday")} />

                                {errors.UserBirthday?.message && <p className="input-msg-error">{errors.UserBirthday?.message}</p>}
                            </div>

                            <div className='col mb-3'>
                                <label htmlFor="UserAddress" className="form-label">Adresse</label>

                                <input type="text" className={"form-control " + (errors?.UserAddress ? "is-invalid" : "")} {...register("UserAddress")} />

                                {errors.UserAddress?.message && <p className="input-msg-error">{errors.UserAddress?.message}</p>}
                            </div>

                            <div className='col mb-3'>
                                <label htmlFor="UserAVS" className="form-label">AVS</label>

                                <input type="text" className={"form-control " + (errors?.UserAVS ? "is-invalid" : "")} {...register("UserAVS")} />

                                {errors.UserAVS?.message && <p className="input-msg-error">{errors.UserAVS?.message}</p>}
                            </div>

                            <div className='col mb-3'>
                                <label htmlFor="UserNationality" className="form-label">Nationalité</label>

                                <input type="text" className={"form-control " + (errors?.UserNationality ? "is-invalid" : "")} {...register("UserNationality")} />

                                {errors.UserNationality?.message && <p className="input-msg-error">{errors.UserNationality?.message}</p>}
                            </div>

                            <div className='col mb-3'>
                                <label htmlFor="UserCivilStatus" className="form-label">Etat civil</label>

                                <select className={"form-select " + (errors?.UserCivilStatus ? "is-invalid" : "")} {...register("UserCivilStatus")} >
                                    <option value=""></option>
                                    <option value="Célibataire">Célibataire</option>
                                    <option value="Marié">Marié</option>
                                    <option value="Divorcé">Divorcé</option>
                                </select>

                                {errors.UserCivilStatus?.message && <p className="input-msg-error">{errors.UserCivilStatus?.message}</p>}
                            </div>

                            <div className='col mb-3'>
                                <label htmlFor="UserDateContract" className="form-label">Date de contrat</label>

                                <input type="date" className={"form-control " + (errors?.UserDateContract ? "is-invalid" : "")} {...register("UserDateContract")} />

                                {errors.UserDateContract?.message && <p className="input-msg-error">{errors.UserDateContract?.message}</p>}
                            </div>
                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>


                    </form>
                </section>
            </section>

        </div>

    </div>
}