import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { reqGet, reqDelete } from "../../../service/apiRequest";
import $ from 'jquery'
import { faker } from '@faker-js/faker'
import QRCode from "react-qr-code";
faker.locale = 'fr_CH'

import { getToken } from '../../../useToken'
import { PrivateComponent, PrivateLinkUpdate } from '../../common/common'
import { LAYOUT, DEFAULT_IMG, GROUP, PERMISSION } from '../../common/constants'
import { IStorage, IWarehouse, IProduct, IVehicle, IVehicleProduct } from '../../common/types'

interface IVehicleStorage extends IVehicle, IVehicleProduct { }
interface IStorageWarehouse extends IStorage, IWarehouse { }

interface ITransfer {
    from: IStorage | IVehicle,
    to: { table: string, id: number }
}


export default function ProductContentLabel() {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idProduct = params.idProduct

    const [product, setProduct] = useState<IProduct>()

    useEffect(() => {
        reqGet("product/" + idProduct).then(res => { setProduct(res) })
    }, [idProduct])

    return <section className="body-container">

        <section className="header">
            <h3 className="section-title">Matériel de la pièce</h3>

            <div className='d-flex justify-content-between align-items-center'>
                <Link className="link-nav mb-3" to={'/product/' + product?.ProductID}>{'< Retour'}</Link>
                {PrivateComponent(
                    <button className="btn btn-outline">
                        <i className="bi bi-plus-lg me-1"></i> Gérer
                    </button>,
                    GROUP.admin
                )}
            </div>
        </section>

        <section className="body">
            <div className="card mb-3">
                <div className="row">
                    <div className="col-4">
                        <img className="img-product rounded" src={product?.Image ? "../media/images/product/" + product?.Image : "../" + DEFAULT_IMG} />
                    </div>
                    <div className="col-8">
                        <h6 className="mb-1">{product?.Model}</h6>
                        <p className="mb-1">{product?.Brand} {product?.Brand && product?.Reference && " - "} {product?.Reference}</p>
                    </div>
                </div>
            </div>

            <div className='card card-fill'>

                <div className='card-header'>
                    <h5 className='card-title'>Matériels</h5>
                </div>

                <div className='card-body'>

                </div>

            </div>

        </section>
    </section>
}