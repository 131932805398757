import React, { useState, useEffect } from 'react'
import { Routes, Route, Link, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { HeaderRow, ButtonSubmit } from '../../../common/common'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { ITaskType } from '../../../common/types'

const validationSchema = yup.object({
    TaskType: yup.string().required("Ce champ est obligatoire")
})

const initialValues = {
    TaskType: ""
}

export default function SupplierAdd() {
    const token = getToken()
    let navigate = useNavigate()

    const { register, formState: { errors }, handleSubmit, watch } = useForm<ITaskType>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const onSubmit: SubmitHandler<ITaskType> = data => {

        reqPost('tasktype', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate(-1)
        })

    };

    return <div className="body-container h-100">

        {HeaderRow(
            <h3 className="section-title mb-1">Ajouter un type de tâche</h3>,
            <button onClick={() => navigate(-1)} className="btn link-nav">{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-lg-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className=" mb-3">

                            <label htmlFor="TaskType" className="form-label">Type de tâche *</label>
                            <input className={"form-control " + (errors?.TaskType ? "is-invalid" : "")} {...register("TaskType")} />

                            {errors.TaskType?.message && <p className="mt-2 text-danger">{errors.TaskType?.message}</p>}
                        </div>

                        <p><small>Les champs avec une * sont obligatoire</small></p>
                        <ButtonSubmit />

                    </form>

                </div>
            </div >


        </div>
    </div>
}