import React, { useState, useEffect } from 'react'
import { Routes, Route, Link, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { HeaderRow, ButtonSubmit } from '../../../common/common'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { IBrand } from '../../../common/types'

const validationSchema = yup.object({
    Name: yup.string().required("Ce champ est obligatoire"),
    Email: yup.string().email("L'email n'est pas valide").nullable(),
    Phone: yup.string().nullable(),
    Website: yup.string().nullable(),
})

const initialValues = {
    Name: "",
    Email: "",
    Phone: "",
    Website: ""
}

export default function BrandAdd() {
    const token = getToken()
    let navigate = useNavigate()

    const { register, formState: { errors }, handleSubmit, watch } = useForm<IBrand>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const onSubmit: SubmitHandler<IBrand> = data => {

        reqPost('brand', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate(-1)
        })

    };

    return <div className="body-container h-100">

        {HeaderRow(
            <h3 className="section-title mb-1">Ajouter une marque</h3>,
            <button onClick={() => navigate(-1)} className="btn link-nav">{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="Name" className="form-label">Nom *</label>
                            <input className={"form-control " + (errors?.Name ? "is-invalid" : "")} {...register("Name")} />

                            {errors.Name?.message && <p className="mt-2 text-danger">{errors.Name?.message}</p>}

                        </div>
                        <div className="mb-3">

                            <label htmlFor="Email" className="form-label">Email</label>
                            <input className={"form-control " + (errors?.Email ? "is-invalid" : "")} {...register("Email")} />

                            {errors.Email?.message && <p className="mt-2 text-danger">{errors.Email?.message}</p>}

                        </div>

                        <div className="mb-3">

                            <label htmlFor="Phone" className="form-label">Téléphone</label>
                            <input className={"form-control " + (errors?.Phone ? "is-invalid" : "")} {...register("Phone")} />

                            {errors.Phone?.message && <p className="mt-2 text-danger">{errors.Phone?.message}</p>}

                        </div>

                        <div className="mb-3">

                            <label htmlFor="Website" className="form-label">Site internet</label>
                            <input className={"form-control " + (errors?.Website ? "is-invalid" : "")} {...register("Website")} />

                            {errors.Website?.message && <p className="mt-2 text-danger">{errors.Website?.message}</p>}

                        </div>

<div className="text-end">
    <p><small>Les champs avec une * sont obligatoire</small></p>
    <ButtonSubmit />
</div>

                    </form>

                </div>
            </div >


        </div>
    </div>
}