import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useOutletContext } from "react-router-dom";
import $ from 'jquery'

import { BADGE, ICONS, LAYOUT, TIMESHEET } from './../../../common/constants';
import { Header, PrivateLinkAdd, TimeCalculation, dateFormat } from "../../../common/common";
import { ITimesheet, IUser, IWeek } from "../../../common/types";
import { reqDelete, reqGet, reqPost, reqPut } from "../../../../service/apiRequest";
import { getToken } from "../../../../useToken";
import { TimesheetWeekDuration, TimesheetWeekKm } from "../../timesheet/timesheet";

const BADGE_VALIDATE = <span className="badge bg-success ms-2 p-0" style={{ width: "1.2rem", height: "1.2rem", fontSize: ".8rem", lineHeight: "1.2rem" }} title="Validé">
    <i className={ICONS.SUCCESS}></i>
</span>

const BADGE_MANUALLY = <span className="badge bg-primary-inversed ms-2 p-0" style={{ width: "1.2rem", height: "1.2rem", fontSize: ".8rem", lineHeight: "1.2rem" }} title="Modifié manuellement">
    <i className={"bi bi-hand-index"}></i>
</span>

export default function AccountTimesheet({ reload, setReload }) {
    const location = useLocation()
    const token = getToken()

    const [display, setDisplay] = useState<string>("")
    const [display2, setDisplay2] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.xxl) {
            setDisplay('d-flex')
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/account/timesheet") {
                setDisplay('d-flex')
                setDisplay2('d-none')

            } else {
                setDisplay('d-none')
                setDisplay2('d-flex')
            }
        }

    }

    const user = useOutletContext<IUser>()
    const [timesheetToday, setTimesheetToday] = useState<ITimesheet>()

    const [duration, setDuration] = useState<string>("00:00")

    useEffect(() => {
        if (user) {
            reqGet("timesheet/type/timesheet/user/" + user.UserID + "/today").then(res => {
                setTimesheetToday(res)

                updateDuration(res)

                console.log(TimeCalculation(
                    res.TimesheetStart,
                    (
                        (!res?.TimesheetLunchStart || res.TimesheetLunchEnd) &&
                        !res?.TimesheetEnd
                    ) ?
                        new Date() :
                        res?.TimesheetEnd,
                    res?.TimesheetLunchStart,
                    res?.TimesheetLunchEnd
                ));

            })

            setReload(false)
        }
    }, [user])

    useEffect(() => {
        if (user && reload) {
            reqGet("timesheet/type/timesheet/user/" + user.UserID + "/today").then(res => {
                setTimesheetToday(res)

                updateDuration(res)
            })

            setReload(false)
        }
    }, [reload])

    function RemoveTimesheet() {
        if (timesheetToday && timesheetToday?.TimesheetValidate != 1)
            reqDelete("timesheet/" + timesheetToday.TimesheetID, { data: { ActivityUserID: token.UserID, FirstName: token.FirstName, Name: token.Name } }).then(() => {
                setReload(true)
            })
    }

    const updateDuration = (timesheet) => setDuration(
        TimeCalculation(
            timesheet.TimesheetStart,
            (
                (!timesheet?.TimesheetLunchStart || timesheet.TimesheetLunchEnd) &&
                !timesheet?.TimesheetEnd
            ) ?
                new Date() :
                timesheet?.TimesheetEnd,
            timesheet?.TimesheetLunchStart,
            timesheet?.TimesheetLunchEnd
        ))

    if (user)
        return <div className="container-fluid h-100">

            <div className="modal" id={"removeTimesheet" + timesheetToday?.TimesheetID} tabIndex={-1} aria-labelledby={"removeTimesheet" + timesheetToday?.TimesheetID} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <header className="modal-header">
                            <h1 className="modal-title fs-5" id={"removeTimesheet" + timesheetToday?.TimesheetID}>Supprimer feuille d'heure</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </header>

                        <section className="modal-body">
                            <p>Etes-vous sûr de vouloir supprimer cet feuille d'heure ?</p>
                            <b>
                                {dateFormat(timesheetToday?.TimesheetStart, "dd/MM/yyyy")}, {dateFormat(timesheetToday?.TimesheetStart, "hh:mm")} - {dateFormat(timesheetToday?.TimesheetEnd, "hh:mm")}
                            </b>
                        </section>

                        <footer className="modal-footer">
                            <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                                <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                            </button>

                            <button type="button" className={"btn btn-delete"} data-bs-dismiss="modal" onClick={() => RemoveTimesheet()}>
                                <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                            </button>
                        </footer>
                    </div>
                </div>
            </div>


            <div className="row h-100">
                <div className={display + " col-xxl-6 col-h-fill px-0"}>
                    <div className="body-container content">

                        <header className="header">
                            <div>
                                <h2 className="section-title">Pointage</h2>
                                <Link to="/account" className="link-nav">{"<"} Retour</Link>
                            </div>

                            {PrivateLinkAdd("add")}
                        </header>


                        <section className="body">

                            <h3 className="section-title-2">Ajourd'hui</h3>

                            {(!timesheetToday?.TimesheetStart) &&
                                <TimesheetStart user={user} timesheet={timesheetToday} setReload={setReload} />
                            }


                            {timesheetToday &&
                                <div className="card">

                                    <header className="card-header">
                                        <h5 className="card-title"><i className={ICONS.CALENDAR + " me-2"}></i>{dateFormat(timesheetToday.TimesheetStart, "dd MMM yyyy")}</h5>

                                        {timesheetToday.TimesheetValidate != 1 &&
                                            <div className="dropdown">
                                                <button className="btn btn-sm btn-outline border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link to={timesheetToday.TimesheetID + "/update"} className="dropdown-item mb-2"><i className={ICONS.UPDATE + " me-2"}></i>Modifier</Link>
                                                    </li>

                                                    <li>
                                                        <Link to={timesheetToday.TimesheetID + "/update-km"} className="dropdown-item mb-2"><i className={ICONS.KM + " me-2"}></i>Km</Link>
                                                    </li>

                                                    <li>
                                                        <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target={"#removeTimesheet" + timesheetToday.TimesheetID}>
                                                            <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </header>


                                    <section className="card-body">


                                        <div className="d-flex align-items-center justify-content-center mb-4">
                                            <div className="bg-primary-inversed p-3 rounded-3 me-3">

                                                <h1 className="text-color-darker mb-0">{parseInt(duration?.split(":")[0]) < 10 && duration?.split(":")[0] != "00" ? "0" + duration?.split(":")[0] : duration?.split(":")[0]}</h1>
                                            </div>
                                            <div className="bg-primary-inversed text-color p-3 rounded-3 me-3">
                                                <h1 className="text-color-darker mb-0">{duration?.split(":")[1]}</h1>
                                            </div>
                                            <h5 className="text-muted mb-0">heures</h5>
                                        </div>



                                        <div className="row justify-content-center">
                                            <div className="col text-center">
                                                <h5><i className={ICONS.WORK}></i></h5>
                                                <p className="item-text">
                                                    {dateFormat(timesheetToday.TimesheetStart, "hh:mm")}
                                                </p>
                                            </div>

                                            <div className="col text-center">
                                                <h5><i className={ICONS.LUNCH}></i></h5>
                                                <p className="item-text">
                                                    {
                                                        (timesheetToday.TimesheetLunchStart || timesheetToday.TimesheetLunchEnd) ?
                                                            <>{dateFormat(timesheetToday.TimesheetLunchStart, "hh:mm")} - {dateFormat(timesheetToday.TimesheetLunchEnd, "hh:mm")}</> :
                                                            "--:--"
                                                    }
                                                </p>
                                            </div>
                                            <div className="col text-center">
                                                <h5><i className={ICONS.LEAVE}></i></h5>
                                                <p className="item-text">
                                                    {timesheetToday.TimesheetEnd ? dateFormat(timesheetToday.TimesheetEnd, "hh:mm") : "--:--"}
                                                </p>
                                            </div>
                                            {timesheetToday.TimesheetKm &&
                                                <div className="col-3 text-center">
                                                    <h5><i className={ICONS.KM}></i></h5>
                                                    <p className="item-text">{timesheetToday.TimesheetKm}</p>
                                                </div>}
                                        </div>
                                    </section>


                                    {!timesheetToday?.TimesheetEnd &&
                                        <footer className="card-footer">
                                            {!timesheetToday?.TimesheetLunchEnd &&
                                                <div className="row row-cols-2">
                                                    <div className="col">
                                                        {!timesheetToday?.TimesheetLunchStart && <TimesheetLunchStart user={user} idTimesheet={timesheetToday?.TimesheetID} setReload={setReload} />}
                                                        {timesheetToday?.TimesheetLunchStart &&
                                                            <TimesheetLunchEnd user={user} timesheet={timesheetToday} setReload={setReload} />}
                                                    </div>
                                                    <div className="col">
                                                        {timesheetToday &&
                                                            <TimesheetEnd user={user} timesheet={timesheetToday} setReload={setReload} />
                                                        }
                                                    </div>
                                                </div>
                                            }

                                            {timesheetToday?.TimesheetLunchEnd &&
                                                <TimesheetEnd user={user} timesheet={timesheetToday} setReload={setReload} />
                                            }
                                        </footer>
                                    }

                                    <div className="col">
                                    </div>
                                </div>
                            }

                            {/* timesheetToday &&
                                <>
                                    <h2 className="section-title-2">Ajourd'hui</h2>

                                    <TimesheetCard timesheet={timesheetToday} setReload={setReload} />
                                </>
                            */}

                            <TimesheetsWeek user={user} reload={reload} setReload={setReload} />

                        </section>
                    </div>

                </div>

                <div className={display2 + " col-xl-6 col-h-fill px-0"}>
                    <Outlet />
                </div>
            </div>
        </div>

    else return <></>
}


function TimesheetStart({ user, timesheet, setReload }: { user: IUser, timesheet: ITimesheet | undefined, setReload }) {
    const [disabled, setDisabled] = useState<boolean>(false)

    useEffect(() => {
        if (timesheet?.TimesheetEnd) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [timesheet])

    function StartDay() {
        reqPost("timesheet/start", { ActivityUserID: user.UserID, FirstName: user.FirstName, Name: user.Name }).then(res => {
            setReload(true)
        })
    }

    return <button className="card card-btn btn-success  p-1 mb-0" onClick={() => StartDay()} disabled={disabled}>
        <header className="card-header">
            <div className="header-content">
                <i className={"card-icon " + ICONS.WORK}></i>
            </div>
        </header>

        <section className="card-body">
            <h5 className="card-title">Commencer journée</h5>
        </section >
    </button>
}

function TimesheetEnd({ user, timesheet, setReload }: { user: IUser, timesheet: ITimesheet, setReload }) {
    const [disabled, setDisabled] = useState<boolean>(false)

    useEffect(() => {
        if (timesheet?.TimesheetLunchStart && !timesheet?.TimesheetLunchEnd) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [timesheet])


    function EndDay() {
        reqPut("timesheet/" + timesheet.TimesheetID + "/end", { ActivityUserID: user.UserID, TimesheetNote: "", FirstName: user.FirstName, Name: user.Name }).then(res => {
            setReload(true)
        })

    }

    return <button className="card card-btn btn-primary p-1 mb-0" onClick={() => EndDay()} disabled={disabled}>
        <header className="card-header">
            <div className="header-content">
                <i className={"card-icon " + ICONS.LEAVE}></i>
            </div>
        </header>

        <section className="card-body">
            <h5 className="card-title">Fin de journée</h5>
        </section>
    </button>
}

function TimesheetLunchStart({ user, idTimesheet, setReload }: { user: IUser, idTimesheet: number, setReload }) {

    function LunchStart() {
        reqPut("timesheet/" + idTimesheet + "/lunchstart", { ActivityUserID: user.UserID, FirstName: user.FirstName, Name: user.Name }).then(res => {
            setReload(true)
        })

    }

    return <button className="card card-btn bg-warning-inversed border-0  p-1 mb-0" onClick={() => LunchStart()}>
        <header className="card-header">
            <div className="header-content">
                <i className={"card-icon " + ICONS.LUNCH}></i>
            </div>
        </header>

        <section className="card-body">
            <h5 className="card-title">Pause midi début</h5>
        </section>
    </button>
}

function TimesheetLunchEnd({ user, timesheet, setReload }: { user: IUser, timesheet: ITimesheet, setReload }) {
    const [disabled, setDisabled] = useState<boolean>(false)

    // timer 30s
    setInterval(() => LunchBreak(), 30 * 1000)

    useEffect(() => {
        LunchBreak()
    }, [timesheet])


    function LunchBreak() {
        const lunchBreak = TimeCalculation(timesheet?.TimesheetLunchStart ?? "", new Date())
        const breakMin = (parseInt(lunchBreak.split(":")[0]) * 60) + parseInt(lunchBreak.split(":")[1])

        if (timesheet?.TimesheetLunchStart && breakMin >= TIMESHEET.MIDDAY_MIN) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }

    function LunchEnd() {
        reqPut("timesheet/" + timesheet.TimesheetID + "/lunchend", { ActivityUserID: user.UserID, FirstName: user.FirstName, Name: user.Name }).then(res => {
            setReload(true)
        })

    }

    return <button className="card card-btn bg-warning border-0  p-1 mb-0" onClick={() => LunchEnd()} disabled={disabled}>
        <header className="card-header">
            <div className="header-content">
                <i className={"card-icon " + ICONS.WORK}></i>
            </div>
        </header>

        <section className="card-body">
            <h5 className="card-title">Pause midi fin</h5>
        </section>
    </button>
}



function TimesheetsWeek({ user, reload, setReload }: { user: IUser, reload: boolean, setReload }) {
    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [timesheets, setTimesheets] = useState<Array<ITimesheet>>([])
    const [weeks, setWeeks] = useState<Array<IWeek>>([])
    const [week, setWeek] = useState<IWeek>()

    useEffect(() => {
        reqGet("week/year/" + year).then(res => {
            setWeeks(res)

            var currentDate = new Date()
            var oneJan = new Date(currentDate.getFullYear(), 0, 1);
            let weekNb = Math.ceil((((currentDate.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7);

            const w = res.find(w => w.WeekNumber >= weekNb)
            setWeek(w)
        })
    }, [])

    useEffect(() => {
        if (week) {
            reqGet("timesheet/type/timesheet/user/" + user.UserID + "/week/" + week.WeekID).then(res => {
                setTimesheets(res);
            })
        }
    }, [week])

    useEffect(() => {
        if (week && reload) {
            reqGet("timesheet/type/timesheet/user/" + user.UserID + "/week/" + week.WeekID).then(res => {
                setTimesheets(res);
            })
            setReload(false)
        }
    }, [reload])

    var [init, setInit] = useState<boolean>(true)
    useEffect(() => {
        if (year && !init)
            reqGet("week/year/" + year).then(res => {
                setWeeks(res)
                if (week?.WeekNumber == 1)
                    setWeek(res[res.length - 1])
                else
                    setWeek(res[0])
            })
        else setInit(false)
    }, [year])

    function HandleWeekChange(idWeek) {
        setWeek(weeks.find(w => w.WeekID == idWeek))
    }

    function HandleNextWeek() {
        if (week?.WeekNumber == 52) {
            setYear(year + 1)
        } else {
            var w = weeks.find(w => w.WeekNumber == (week?.WeekNumber ?? 0) + 1)
            setWeek(w)
            $("#selectWeek").val(w?.WeekID)
        }

    }

    function HandlePreviousWeek() {
        if (week?.WeekNumber == 1) {
            setYear(year - 1)
        } else {
            var w = weeks.find(w => w.WeekNumber == (week?.WeekNumber ?? 0) - 1)
            setWeek(w)
            $("#selectWeek").val(w?.WeekID)
        }
    }


    return <>
        <h3 className="section-title-2">
            Semaine {week?.WeekNumber}
            <span className="section-subtitle ms-3">{dateFormat(week?.DateStart, "dd MMM")} - {dateFormat(week?.DateEnd, "dd MMM")}</span>
        </h3>


        <nav className="text-center mb-3">

            <button
                className="btn btn-outline d-inline rounded"
                onClick={() => HandlePreviousWeek()}
            >
                <i className="bi bi-caret-left"></i>
            </button>

            <select className="form-select mx-3 d-inline w-auto" onChange={(e) => HandleWeekChange(e.target.value)} id="selectWeek" value={week?.WeekID}>
                {weeks.map((w) => {
                    return <option key={w.WeekID} value={w.WeekID}>Sem. {w.WeekNumber}: {dateFormat(w.DateStart, "dd MMM yyyy")}</option>
                })}
            </select>

            <input type="number" className="form-control me-3 d-inline" style={{ width: "65px" }} value={year} onChange={(e) => setYear(parseInt(e.target.value))} />

            <button
                className="btn btn-outline d-inline rounded" id="btnNext"
                onClick={() => HandleNextWeek()}
            >
                <i className="bi bi-caret-right"></i>
            </button>

        </nav>

        <section className="row row-cols-3">
            <section className="col">
                <article className="card">
                    <header className="card-header">
                        <h5 className="card-title">Jours <span className="d-none d-lg-inline">de travail</span></h5>
                    </header>

                    <section className="card-body">
                        <p className="paragraph mb-0">{timesheets.length}</p>
                    </section>
                </article>
            </section>

            <section className="col">
                <article className="card">
                    <header className="card-header">
                        <h5 className="card-title d-none d-lg-inline">Nombre d'heures</h5>
                        <h5 className="card-title d-lg-none">Heures</h5>
                    </header>

                    <section className="card-body">
                        <p className="paragraph mb-0">{timesheets.length > 0 ? TimesheetWeekDuration(timesheets, true) : 0}</p>
                    </section>
                </article>
            </section>

            <section className="col">
                <article className="card">
                    <header className="card-header">
                        <h5 className="card-title">Km</h5>
                    </header>

                    <section className="card-body">
                        <p className="paragraph mb-0">{timesheets.length > 0 ? TimesheetWeekKm(timesheets) : 0}</p>
                    </section>
                </article>
            </section>
        </section>

        {(timesheets && timesheets.length > 0) && timesheets.map((t) => {
            return <TimesheetCard timesheet={t} setReload={setReload} key={t.TimesheetID} />
        })
        }
    </>
}



export function TimesheetCard({ timesheet, setReload, km = true }: { timesheet: ITimesheet, setReload?, km?: boolean }) {
    const token = getToken()

    const duration = TimeCalculation(timesheet.TimesheetStart, timesheet.TimesheetEnd, timesheet.TimesheetLunchStart, timesheet.TimesheetLunchEnd, "Decimal")

    function RemoveTimesheet(timesheet: ITimesheet) {
        if (timesheet.TimesheetValidate != 1)
            reqDelete("timesheet/" + timesheet.TimesheetID, { data: { ActivityUserID: token.UserID, ...timesheet } }).then(() => {
                setReload(true)
            })
    }


    return <div className="card">

        <div className="modal" id={"removeTimesheet" + timesheet.TimesheetID} tabIndex={-1} aria-labelledby={"removeTimesheet" + timesheet.TimesheetID} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <header className="modal-header">
                        <h1 className="modal-title fs-5" id={"removeTimesheet" + timesheet.TimesheetID}>Supprimer feuille d'heure</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </header>

                    <section className="modal-body">
                        <p>Etes-vous sûr de vouloir supprimer cette feuille d'heure ?</p>
                        <b>
                            {dateFormat(timesheet.TimesheetStart, "dd/MM/yyyy")}, {dateFormat(timesheet.TimesheetStart, "hh:mm")} - {dateFormat(timesheet.TimesheetEnd, "hh:mm")}
                        </b>
                    </section>

                    <footer className="modal-footer">
                        <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                            <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                        </button>

                        <button type="button" className={"btn btn-delete"} data-bs-dismiss="modal" onClick={() => RemoveTimesheet(timesheet)}>
                            <i className={ICONS.DELETE + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Supprimer</span>
                        </button>
                    </footer>
                </div>
            </div>
        </div>


        <header className="card-header">
            <h5 className="card-title"><i className={ICONS.CALENDAR + " me-2"}></i>{dateFormat(timesheet.TimesheetStart, "dd MMM yyyy")} {timesheet?.TimesheetDateUpdated && BADGE_MANUALLY} {timesheet?.TimesheetValidate == 1 && BADGE_VALIDATE}</h5>

            {timesheet.TimesheetValidate != 1 &&
                <div className="dropdown">
                    <button className="btn btn-sm btn-outline border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                    <ul className="dropdown-menu">
                        <li>
                            <Link to={timesheet.TimesheetID + "/update"} className="dropdown-item mb-2"><i className={ICONS.UPDATE + " me-2"}></i>Modifier</Link>
                        </li>
                        {km &&
                            <li>
                                <Link to={timesheet.TimesheetID + "/update-km"} className="dropdown-item mb-2"><i className={ICONS.KM + " me-2"}></i>Km</Link>
                            </li>
                        }
                        <li>
                            <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target={"#removeTimesheet" + timesheet.TimesheetID}>
                                <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                            </button>
                        </li>
                    </ul>
                </div>
            }
        </header>

        <section className="card-body">
            <div className="row row-cols-3">
                <div className="col-7">
                    <h6 className="item-title mb-1">Heures</h6>
                    <p className="mb-0">
                        {dateFormat(timesheet.TimesheetStart, "hh:mm")}

                        {(timesheet.TimesheetLunchStart || timesheet.TimesheetLunchEnd) && <span className="ms-3">{dateFormat(timesheet.TimesheetLunchStart, "hh:mm")} - {dateFormat(timesheet.TimesheetLunchEnd, "hh:mm")}</span>}

                        {timesheet.TimesheetEnd && <span className="ms-3">{dateFormat(timesheet.TimesheetEnd, "hh:mm")}</span>}
                    </p>
                </div>

                <div className="col-3">
                    <h6 className="item-title mb-1">Total</h6>
                    <p className="paragraph mb-0">{duration}</p>
                </div>

                {(km && timesheet.TimesheetKm) &&
                    <div className="col-2">
                        <h6 className="item-title mb-1">Km</h6>
                        <p className="paragraph mb-0">{timesheet.TimesheetKm}</p>
                    </div>}
            </div>
        </section>
    </div>
}
