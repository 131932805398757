import React, { useState, useEffect } from 'react'
import { Routes, Link, NavLink } from 'react-router-dom'
import { faker } from '@faker-js/faker'
//import { Document, Page } from 'react-pdf'

export default function ConstructionPv() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return <>

        <div className="header">
            <h3 className="section-title">PV</h3>

            <div className="d-flex justify-content-end w-25">
                <Link to="" className="btn btn-tools">
                    <i className="bi bi-plus-circle"></i>
                    <span className="d-block">Ajouter</span>
                </Link>

                <Link to="" id="btnUpdate" className="btn btn-tools disabled">
                    <i className="bi bi-pencil-square"></i>
                    <span className="d-block">Modifier</span>
                </Link>
            </div>
        </div>

        <div className="row row-h-fill mx-0">

            <div className="col-md-5 col-lg-3 col-h-fill ps-0">

                <div className="card card-fill p-0">

                    <div className="card-body">

                        <div className="list-group list-group-hover h-100 overflow-auto border-0">

                            <NavLink to="1" className="list-group-item" /*activeClassName="selected"*/>
                                <h6 className="item-title">Intervention du genou</h6>
                                <p className="item-subtitle">20.05.2022</p>
                            </NavLink>

                            <NavLink to="2" className="list-group-item">
                                <h6 className="item-title">Mise au net des plans</h6>
                                <p className="item-subtitle">07.04.2022</p>
                            </NavLink>

                            <NavLink to="3" className="list-group-item">
                                <h6 className="item-title">Aller voir l'archi</h6>
                                <p className="item-subtitle">04.02.2022</p>
                            </NavLink>

                            <NavLink to="4" className="list-group-item">
                                <h6 className="item-title">Comparer les plans</h6>
                                <p className="item-subtitle">19.11.2022</p>
                            </NavLink>

                            <NavLink to="5" className="list-group-item">
                                <h6 className="item-title">Proposer offre</h6>
                                <p className="item-subtitle">01.11.2022</p>
                            </NavLink>

                        </div>

                    </div>
                </div>

            </div>

            <div className="col-md-7 col-xxl-9">

                <div className="row h-75">

                    <h4 className="section-title-2">{faker.company.catchPhrase()}</h4>

                    <div className="col-md-6">

                        <div className="">

                            <h5 className="text-muted">Note</h5>

                            <p>{faker.lorem.paragraph()}</p>


                        </div>
                    </div>


                    <div className="col-md-6 col-h-fill">

                        <div className="card card-fill">

                            <div className="card-header">
                                <h5 className="card-title">PDF preview</h5>
                            </div>

                            <div className="card-body">
                                {/*<Document file="./plan_pdf.pdf" onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page pageNumber={pageNumber} />
                                </Document>*/}
                                <p>
                                    Page {pageNumber} of {numPages}
                                </p>
                            </div>
                        </div>

                    </div>

                </div>


            </div>

        </div>
    </>
}