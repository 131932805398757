import React, { useEffect } from 'react';
import $ from 'jquery'
import { faker } from '@faker-js/faker'
faker.locale = 'fr_CH'

export default function Ui() {

    useEffect(() => {
        $(".square").css({ "height": "80px", "width": "80px" })
    }, [])

    return <div className="container-fluid overflow-auto content">


        <h2 className="section-title">Typographie</h2>

        <div className="row mb-5">
            <div className="col-6">
                <h1>Titre h1</h1>
                <h2>Titre h2</h2>
                <h3>Titre h3</h3>
                <h4>Titre h4</h4>
                <h5>Titre h5</h5>
                <h6>Titre h6</h6>
                <p>Texte lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate non magnam, rem placeat debitis sequi soluta eligendi quod pariatur ipsa illo atque ex esse facilis adipisci culpa cumque voluptatem. Deserunt.</p>
                <a href="">a</a>
            </div>

            <div className="col-6">
                <h1 className="title">.title</h1>
                <h2 className="section-title">.title-section</h2>
                <h2 className="section-title-2">.title-section-2</h2>
                <h4 className="section-subtitle">.section-subtitle</h4>
                <p className="paragraph">.paragraph. Texte lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate non magnam, rem placeat debitis sequi soluta eligendi quod pariatur ipsa illo atque ex esse facilis adipisci culpa cumque voluptatem. Deserunt.</p>
                <p className="text-muted">.text-muted</p>
                <a className="link-nav" href="">.link-nav</a>
            </div>
        </div>





        <h2 className="section-title">Couleurs</h2>

        <h2 className="section-title-2">background</h2>
        <div className="row row-cols-6 mb-5">

            <div className="col">
                <p className="mb-1">.bg-background-lighter</p>
                <div className="square bg-background-lighter"></div>
            </div>
            <div className="col">
                <p className="mb-1">.bg-background-light</p>
                <div className="square bg-background-light"></div>
            </div>
            <div className="col">
                <p className="mb-1">.bg-background</p>
                <div className="square bg-background"></div>
            </div>
            <div className="col">
                <p className="mb-1">.bg-background-dark</p>
                <div className="square bg-background-dark"></div>
            </div>
            <div className="col">
                <p className="mb-1">.bg-background-darker</p>
                <div className="square bg-background-darker"></div>
            </div>

        </div>


        <h2 className="section-title">Boutons</h2>

        <div className="mb-3">

            <button className="btn btn-primary me-3">.btn .btn-primary</button>
            <button className="btn btn-secondary me-3">.btn .btn-secondary</button>
            <button className="btn btn-light me-3">.btn .btn-light</button>
            <button className="btn btn-outline me-3">.btn .btn-outline</button>
            <button className="btn btn-delete me-3">.btn .btn-delete</button>

        </div>

        <h6>Disabled</h6>
        <div className="mb-5">
            <button className="btn btn-primary me-3" disabled>.btn .btn-primary</button>
            <button className="btn btn-secondary me-3" disabled>.btn .btn-secondary</button>
            <button className="btn btn-light me-3" disabled>.btn .btn-light</button>
            <button className="btn btn-outline me-3" disabled>.btn .btn-outline</button>
            <button className="btn btn-delete me-3" disabled>.btn .btn-delete</button>

        </div>


        <h2 className="section-title">Card</h2>

        <div className="row row-cols-2 row-cols-lg-3">
            <div className="col">

                <p>Card simple</p>

                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">card title</h5>
                        <h6 className="card-subtitle justify-self-end">card subtitle</h6>
                    </div>

                    <div className="card-body">
                        <p className="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum a libero ipsam sed deserunt officia distinctio sint alias. Quisquam officia obcaecati dolore distinctio labore recusandae ipsa est sunt, enim quo!</p>
                    </div>

                    <div className="card-footer">
                        <h6><small>Card footer</small></h6>
                    </div>
                </div>
            </div>

            <div className="col">

                <p>Card with list group</p>

                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">card title</h5>
                    </div>

                    <div className="card-body">
                        <ul className="list-group">
                            <li className="list-group-item">Item 1</li>
                            <li className="list-group-item">Item 2</li>
                            <li className="list-group-item">Item 3</li>
                            <li className="list-group-item">Item 4</li>
                        </ul>
                    </div>

                    <div className="card-footer">
                        <h6><small>Card footer</small></h6>
                    </div>
                </div>
            </div>

            <div className="col">

                <p>Card with table</p>

                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">card title</h5>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>th 1</th>
                                        <th>th 2</th>
                                        <th>th 3</th>
                                        <th>th 4</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>td 1</td>
                                        <td>td 2</td>
                                        <td>td 3</td>
                                        <td>td 4</td>
                                    </tr>
                                    <tr>
                                        <td>td 1</td>
                                        <td>td 2</td>
                                        <td>td 3</td>
                                        <td>td 4</td>
                                    </tr>
                                    <tr>
                                        <td>td 1</td>
                                        <td>td 2</td>
                                        <td>td 3</td>
                                        <td>td 4</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="card-footer">
                        <h6><small>Card footer</small></h6>
                    </div>
                </div>
            </div>
        </div>


        <h3 className="section-title-2 mb-0">.card-fill</h3>
        <h6 className="section-subtitle mb-3">(height: 300px)</h6>
        <div className="row mb-5 row-cols-2 row-cols-lg-3">

            <div className="col col-h-fill" style={{ height: "300px" }}>

                <p>.card-fill</p>

                <div className="card card-fill">
                    <div className="card-header">
                        <h5 className="card-title">card title</h5>
                    </div>

                    <div className="card-body">
                        <div className="card-paragraph">

                            <p className="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum a libero ipsam sed deserunt officia distinctio sint alias. Quisquam officia obcaecati dolore distinctio labore recusandae ipsa est sunt, enim quo!</p>

                            <p className="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum a libero ipsam sed deserunt officia distinctio sint alias. Quisquam officia obcaecati dolore distinctio labore recusandae ipsa est sunt, enim quo!</p>

                            <p className="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum a libero ipsam sed deserunt officia distinctio sint alias. Quisquam officia obcaecati dolore distinctio labore recusandae ipsa est sunt, enim quo!</p>

                            <p className="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum a libero ipsam sed deserunt officia distinctio sint alias. Quisquam officia obcaecati dolore distinctio labore recusandae ipsa est sunt, enim quo!</p>
                        </div>
                    </div>

                    <div className="card-footer">
                        <h6><small>Card footer</small></h6>
                    </div>
                </div>
            </div>

            <div className="col col-h-fill" style={{ height: "300px" }}>

                <p>.card-fill with list group</p>

                <div className="card card-fill">
                    <div className="card-header">
                        <h5 className="card-title">card title</h5>
                    </div>

                    <div className="card-body">
                        <ul className="list-group">
                            <li className="list-group-item">Item 1</li>
                            <li className="list-group-item">Item 2</li>
                            <li className="list-group-item">Item 3</li>
                            <li className="list-group-item">Item 4</li>
                            <li className="list-group-item">Item 5</li>
                            <li className="list-group-item">Item 6</li>
                            <li className="list-group-item">Item 7</li>
                        </ul>
                    </div>

                    <div className="card-footer">
                        <h6><small>Card footer</small></h6>
                    </div>
                </div>
            </div>

            <div className="col col-h-fill" style={{ height: "300px" }}>

                <p>.card-fill with table</p>

                <div className="card card-fill">
                    <div className="card-header">
                        <h5 className="card-title">card title</h5>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>th 1</th>
                                        <th>th 2</th>
                                        <th>th 3</th>
                                        <th>th 4</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>td 1</td>
                                        <td>td 2</td>
                                        <td>td 3</td>
                                        <td>td 4</td>
                                    </tr>
                                    <tr>
                                        <td>td 1</td>
                                        <td>td 2</td>
                                        <td>td 3</td>
                                        <td>td 4</td>
                                    </tr>
                                    <tr>
                                        <td>td 1</td>
                                        <td>td 2</td>
                                        <td>td 3</td>
                                        <td>td 4</td>
                                    </tr>
                                    <tr>
                                        <td>td 1</td>
                                        <td>td 2</td>
                                        <td>td 3</td>
                                        <td>td 4</td>
                                    </tr>
                                    <tr>
                                        <td>td 1</td>
                                        <td>td 2</td>
                                        <td>td 3</td>
                                        <td>td 4</td>
                                    </tr>
                                    <tr>
                                        <td>td 1</td>
                                        <td>td 2</td>
                                        <td>td 3</td>
                                        <td>td 4</td>
                                    </tr>
                                    <tr>
                                        <td>td 1</td>
                                        <td>td 2</td>
                                        <td>td 3</td>
                                        <td>td 4</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="card-footer">
                        <h6><small>Card footer</small></h6>
                    </div>
                </div>

            </div>
        </div>



        <h2 className="section-title">Liste</h2>

        <div className="row row-cols-4 mb-5">

            <div className="col">
                <h6>.list-group</h6>
                <ul className="list-group">
                    <li className="list-group-item">Item 1</li>
                    <li className="list-group-item">Item 2</li>
                    <li className="list-group-item">Item 3</li>
                    <li className="list-group-item">Item 4</li>
                </ul>
            </div>

            <div className="col">
                <h6>.list-group-flush</h6>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Item 1</li>
                    <li className="list-group-item">Item 2</li>
                    <li className="list-group-item">Item 3</li>
                    <li className="list-group-item">Item 4</li>
                </ul>
            </div>

            <div className="col">
                <h6>.list</h6>
                <div className="list">
                    <div className="item">Item 1</div>
                    <div className="item">Item 2</div>
                    <div className="item">Item 3</div>
                    <div className="item">Item 4</div>
                </div>
            </div>

            <div className="col">
                <div className='card'>
                    <div className='card-header'>
                        <h6 className="card-title">.list-group-hover</h6>
                    </div>
                    <div className="card-body">
                        <ul className="list-group list-group-hover">
                            <li className="list-group-item">Item 1</li>
                            <li className="list-group-item">Item 2</li>
                            <li className="list-group-item">Item 3</li>
                            <li className="list-group-item">Item 4</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>




        <h2 className="section-title">Formulaire</h2>

        <div className="row mb-3">
            <div className="col-3 mb-3">
                <label htmlFor="" className="form-label">Text</label>
                <input type="text" className="form-control" placeholder=".form-control" />
            </div>
            <div className="col-3 mb-3">
                <label htmlFor="" className="form-label">Number</label>
                <input type="number" className="form-control" placeholder=".form-control" />
            </div>
            <div className="col-3 mb-3">
                <label htmlFor="" className="form-label">Select</label>
                <select className="form-select">
                    <option value="" hidden disabled>select</option>
                    <option value="">option 1</option>
                    <option value="">option 2</option>
                    <option value="">option 3</option>
                    <option value="">option 4</option>
                </select>
            </div>
            <div className="col-3 mb-3">
                <label htmlFor="" className="form-label">Date</label>
                <input type="date" className="form-control" placeholder=".form-control" />
            </div>

            <div className="col-3 mb-3">
                <label htmlFor="" className="form-label">Checkbox</label>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Default checkbox
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" defaultChecked />
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                        Checked checkbox
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDisabled" disabled />
                    <label className="form-check-label" htmlFor="flexCheckDisabled">
                        Disabled checkbox
                    </label>
                </div>

            </div>
            <div className="col-3 mb-3">
                <label htmlFor="" className="form-label">Radio</label><div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                        Default radio
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Default checked radio
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioDisabled" disabled />
                    <label className="form-check-label" htmlFor="flexRadioDisabled">
                        Disabled radio
                    </label>
                </div>

            </div>
            <div className="col-6 mb-3">
                <label htmlFor="" className="form-label">Textarea</label>
                <textarea name="" id="" rows={4}></textarea>
            </div>

            <div className="col-6 mb-3">
                <label htmlFor="" className="form-label">Search</label>
                <div className="form-search">
                    <i className="bi bi-search"></i>
                    <input type="text" className="form-control" placeholder="Have a question? Ask Now" />
                </div>
            </div>
        </div>

    </div>
}