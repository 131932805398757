import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import $ from 'jquery'

import { getToken } from '../../../../useToken'
import { reqGet, reqDelete } from '../../../../service/apiRequest'
import { LAYOUT, GROUP, MONTH, MONTH_FR, COUNTER_TYPE } from '../../../common/constants'
import { Header, PrivateComponent, PrivateLinkUpdate, PrivateLinkAdd, dateFormat, ResolveIcon, CardBtnLink } from '../../../common/common'
import { ICounter, ICounterStatement } from '../../../common/types'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default function AddressCounters() {
    const params = useParams()
    const idAddress = params.idAddress
    const idCounter = params?.idCounter

    const [counters, setCounters] = useState<Array<ICounter>>([])
    const [reload, setReload] = useState<boolean>(false)

    useEffect(() => {
        reqGet("counter/address/" + idAddress).then(res => { setCounters(res) }, rej => { })

    }, [idAddress])


    useEffect(() => {
        if (reload) {
            reqGet("counter/address/" + idAddress).then(res => { setCounters(res) }, rej => { })
            setReload(false)
        }
    }, [reload])

    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname.endsWith("/counter")) {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }

    }

    return <section className="h-100 container-fluid">

        <div className="row h-100">
            <div className={display + " col-md-4 col-xl-3 col-h-fill"}>

                <section className="body-container">
                    {Header(
                        <h3 className="section-title">Compteurs</h3>,
                        <Link to={'/address/' + idAddress} className="link-nav">{'< Retour'}</Link>,
                        PrivateLinkAdd("add", GROUP.admin, ["CounterCreate"])
                    )}

                    <section className="body">

                        <div className="list mt-3">
                            {counters?.length > 0 && counters?.map((c, i) => {

                                return <React.Fragment key={i}>
                                    <CardBtnLink path={c.CounterID.toString()} title={c?.Label} text={c?.CounterType + (c.IDCounter ? " - " + c.IDCounter : "")} icon={ResolveIcon(c.CounterType)} />
                                </React.Fragment>
                            })
                            }
                        </div>

                    </section>
                </section>

            </div>

            <div className="col-md-8 col-xl-9 col-h-fill">
                {idCounter && <ProductDetail idCounter={idCounter} setReload={setReload} back={'/address/' + idAddress + "/counter"} />}
            </div>

        </div>
    </section>
}



function ProductDetail({ idCounter, setReload, back }) {
    const navigate = useNavigate()
    const token = getToken()

    const [counter, setCounter] = useState<ICounter>()
    const [counterStatement, setCounterStatement] = useState<Array<ICounterStatement>>([])

    useEffect(() => {
        if (idCounter) {
            reqGet("counter/" + idCounter).then(res => { setCounter(res) }, rej => { })
            reqGet("counterstatement/counter/" + idCounter).then((res) => { setCounterStatement(res) }, rej => { setCounterStatement([]) })
        }
    }, [idCounter])

    function removeCounter() {
        reqDelete("counter/" + idCounter, { data: { ActivityUserID: token.UserID } }).then(res => {
            setReload(true)
            navigate(back)
        }, rej => { })
    }

    return <main className="body-container">


        <div className="modal fade" id="modalRemoveCounter" tabIndex={-1} aria-labelledby="modalRemoveCounter" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="modalRemoveCounter">Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes-vous  sur de vouloir supprimer :</p>
                        <p><b>{counter?.CounterType}, {counter?.Label} {counter?.IDCounter ? "(" + counter.IDCounter + ")" : ""}</b></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => removeCounter()}>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>

        <header className="header">

            <h3 className="section-title">{counter?.Label}</h3>

            <div className='toolbar'>
                {PrivateLinkUpdate(idCounter + "/update", GROUP.admin, ["CounterUpdate"])}

                {PrivateComponent(
                    <button className="btn btn-delete ms-3" title="Supprimer" data-bs-toggle="modal" data-bs-target="#modalRemoveCounter">
                        <i className="bi bi-trash3 me-1"></i> Supprimer
                    </button>,
                    GROUP.admin
                )}
            </div>

        </header>

        <section className="body container-fluid">
            <div className="row mb-3" style={{ minHeight: "400px" }}>

                <section className="col-6 col-xxl-2 col-h-fill order-1">
                    <h3 className="section-title-2">Information</h3>

                    <div className="card card-fill">
                        <div className="card-body">
                            <h6 className='mb-1'>Type</h6>
                            <p className="paragraph mb-3">{counter?.CounterType}</p>

                            <h6 className='mb-1'>ID compteur</h6>
                            <p className="paragraph mb-3">{counter?.IDCounter}</p>

                            <h6 className='mb-1'>Capacité</h6>
                            <p className="paragraph mb-3">{counter?.Capacity}</p>

                            <h6 className='mb-1'>Volume minimum</h6>
                            <p className="paragraph mb-3">{counter?.VolumeMin}</p>

                            <h6 className='mb-1'>Tolérance</h6>
                            <p className="paragraph mb-3">{counter?.Tolerance}</p>

                            <h6 className='mb-1'>Unité</h6>
                            <p className="paragraph mb-0">{counter?.Unit}</p>
                        </div>
                    </div>
                </section>

                <section className="col-xxl-7 col-h-fill order-3 order-xl-2">
                    <h3 className="section-title-2">Graphique</h3>

                    <CounterChart counter={counter} counterStatement={counterStatement} />
                </section>


                <section className="col-6 col-xxl-3 col-h-fill order-3 order-md-2">
                    <h3 className="section-title-2">Relevés</h3>

                    <div className="card card-fill border-0 p-0">
                        <section className="card-body p-0">

                            <div className="list">
                                {counterStatement && counterStatement?.map((c: ICounterStatement, k: number) => {
                                    return <div key={c.CounterStatementID} className="list-item d-block">
                                        <h6 className="mb-0">Semaine {c?.WeekNumber}</h6>
                                        <h6>{dateFormat(c?.DateStart, "dd/MM")} - {dateFormat(c.DateEnd, "dd/MM/yyyy")}</h6>
                                        <p className='mb-0'>{c?.CounterStatementValue} {c?.Unit}</p>
                                        <p className='mb-0'>{dateFormat(c?.CounterStatementDate, "dd/MM/yyyy")}</p>
                                    </div>
                                })}
                            </div>

                        </section>
                    </div>
                </section>
            </div>


            <div className="row">

                <section className="col-12 col-h-fill">
                    <div>
                        <h3 className="section-title-2 d-inline-block me-2">Recurrence</h3>
                        <h5 className="text-muted d-inline-block"><i>Passage par mois</i></h5>
                    </div>

                    <div className="row row-cols-md-2 h-100">
                        <div className="col col-h-fill">

                            <div className="card card-fill">
                                <div className="card-header">
                                    <h5 className="card-title">Surveillant</h5>
                                </div>

                                <section className="d-flex align-items-center mb-3">

                                    <span className="badge badge-primary rounded-pill me-2">E</span>
                                    <p className="d-inline text-muted mb-0 me-4">Exceptionnel</p>

                                </section>

                                <div className="card-body px-3">

                                    <div className="row row-cols-3 row-cols-xl-4 row-cols-xxl-5">

                                        {MONTH.map((m, k) => {
                                            return <div className="col" key={k}>
                                                <p className="text-muted mb-0">{MONTH_FR[k]}</p>
                                                <p>
                                                    {counter?.RecurrenceSurv[m]}
                                                    <span className="badge badge-primary rounded-5 ms-2">{counter?.RecurrenceSurv.Exceptional?.includes(m) ? "E" : ""}</span>
                                                </p>
                                            </div>
                                        })}

                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="col col-h-fill">
                            <div className="card card-fill">
                                <div className="card-header">
                                    <h5 className="card-title">Technicien</h5>
                                </div>

                                <section className="d-flex align-items-center mb-3">

                                    <span className="badge badge-primary rounded-pill me-2">E</span>
                                    <p className="d-inline text-muted mb-0 me-4">Exceptionnel</p>

                                </section>

                                <div className="card-body px-3">

                                    <div className="row row-cols-3 row-cols-xl-4 row-cols-xxl-5">

                                        {MONTH.map((m, k) => {
                                            return <div className="col" key={k}>
                                                <p className="text-muted mb-0">{MONTH_FR[k]}</p>
                                                <p>
                                                    {counter?.RecurrenceTec[m]}
                                                    <span className="badge badge-primary rounded-5 ms-2">{counter?.RecurrenceTec.Exceptional?.includes(m) ? "E" : ""}</span>
                                                </p>
                                            </div>
                                        })}

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </div>


        </section>


    </main>
}

function CounterChart({ counter, counterStatement }: { counter: ICounter | undefined, counterStatement: Array<ICounterStatement> }) {
    let labels: Array<string | null> = []
    let counterData: Array<number | null> = []

    for (let i = 0; i < 20; i++) {
        labels.push(dateFormat(counterStatement[i]?.CounterStatementDate, "dd/MM/yy"))
        counterData.push(counterStatement[i]?.CounterStatementValue)
    }


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
        },
    }

    labels = labels.reverse()
    const data = {
        labels,
        legend: {},
        datasets: [
            {
                label: counter?.Unit,
                data: counterData.reverse(),
                borderColor: 'rgb(96, 32, 245)',
                backgroundColor: 'rgb(96, 32, 245)',
            }
        ],
    }

    if (counter?.VolumeMin) {
        data.datasets.push(
            {
                label: 'Volume minimum',
                data: new Array(20).fill(counter.VolumeMin),
                borderColor: 'rgb(255, 0, 0)',
                backgroundColor: 'rgb(255, 0, 0)',
                pointRadius: 0
            })
    }

    if (counter?.VolumeMin && counter?.Tolerance) {
        data.datasets.push(
            {
                label: 'Tolérance',
                data: new Array(20).fill(counter.VolumeMin - counter.Tolerance),
                borderColor: 'rgb(230,230,0)',
                backgroundColor: 'rgb(230,230,0)',
                pointRadius: 0
            })
    }


    return <div className="card card-fill">
        <div className="card-body">

            <Line options={options} data={data} className="chart-canvas" />

        </div>
    </div>
}
