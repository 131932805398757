import React, { useEffect, useMemo, useState } from "react";

import { DebouncedInput, Header, PrivateComponent, PrivateLinkAdd, PrivateLinkUpdate, ServiceBadge, dateFormat, fuzzyFilter } from "../../../common/common";
import { reqGet } from "../../../../service/apiRequest"
import { IAddress, IManagement, IService } from "../../../common/types";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { GROUP, LAYOUT, SERVICE } from "../../../common/constants";
import $ from 'jquery'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
} from '@tanstack/react-table'

export default function ClientAddress({ reload, setReload }) {
    const navigate = useNavigate()
    const params = useParams()
    const idManagement = params.idManagement
    const idOwner = params.idOwner

    const [services, setServices] = useState<Array<IService>>([])

    useEffect(() => {
        reqGet("service").then(res => { setServices(res) })
    }, [])

    const columns = useMemo<ColumnDef<IAddress, any>[]>(
        () => [
            {
                accessorFn: row => row.AddressLine,
                id: 'AddressLine',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.AddressComplementary,
                id: 'AddressComplementary',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Services,
                id: 'Services',
                cell: info => info.getValue()
            }
        ], []
    )

    const [dataBackup, setDataBackup] = useState<Array<IAddress>>([])
    const [data, setData] = useState<Array<IAddress>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        reqData()
    }, [idManagement, idOwner])

    useEffect(() => {
        if (reload) {
            reqData()
            setReload(false)
        }
    }, [reload])

    function reqData() {
        reqGet(idManagement ? "address/management/" + idManagement : "address/owner/" + idOwner).then((res) => {
            setData(res)
            setDataBackup(res)
        })
    }

    const LinkBack = () => {
        if (idManagement)
            return "/client/management/" + idManagement
        else if (idOwner)
            return "/client/owner/" + idOwner
        else
            return ""
    }

    function FilterService(service: string) {
        if (service) {
            setData(dataBackup.filter(d => d.Services.some(s => s.Service == service)))
        } else {
            setData(dataBackup)
        }
    }

    return <section className="body-container">
        <header className="header">
            <h2 className="section-title-2">Adresses {(idManagement && "de la régie") || (idOwner && "du propriétaire")}</h2>
            <Link to={LinkBack()} className="link-nav d-lg-none">{"<"} Retour</Link>
        </header>

        <section className="body">

            <div className="card card-flush card-fill">

                <header className="card-header d-block">

                    <div className="row">
                        <div className="col-6 col-sm-4">
                            <label htmlFor="" className="form-label">Prestations</label>
                            <select name="" id="" className="form-select" onChange={(e) => FilterService(e.target.value)}>
                                <option value="">Tout</option>
                                {services.map(s => {
                                    return <option key={s.ServiceID} value={s.Service}>{s.Service}</option>
                                })}
                            </select>
                        </div>
                        <div className="col-6 col-sm-8">
                            <label htmlFor="" className="form-label">Recherche</label>
                            <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche" />
                        </div>
                    </div>


                </header>

                <section className="card-body">

                    <div className="list-group">

                        {table.getRowModel().rows.map(row => {
                            var a = row.original

                            return <div className="list-group-item" key={a.AddressID}>

                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h6 className="mb-2">{a.AddressLine}, {a.ZipCode} {a.City} {a?.AddressComplementary}</h6>
                                    </div>

                                    {idManagement &&
                                        <Link to={"/client/management/" + idManagement + "/address/" + a.AddressID + "/details"} className="link-nav">Détails {">"}</Link>
                                    }

                                    {idOwner &&
                                        <Link to={"/client/owner/" + idOwner + "/address/" + a.AddressID + "/details"} className="link-nav">Détails {">"}</Link>
                                    }
                                </div>

                                <div className="mb-2">
                                    <p className="d-inline me-3">Prestations ({a?.Services.length}):</p>
                                    {a?.Services?.map((s, k) => {
                                        return <ServiceBadge key={k} service={s.Service} className="me-2" firstLetter={true} />
                                    })}
                                </div>

                                {a?.Services.find(s => s.Service === SERVICE.HEATING) && a.HeatingDate &&
                                    <div className="mb-2">
                                        <p className="mb-0">{a.HeatingAction == "Start" ? "Mise en route " : "Arrêt "} du chauffage: <b>{dateFormat(a.HeatingDate, "dd/MM/yyyy")}</b></p>
                                    </div>}
                            </div>
                        })}

                    </div>
                </section>

                <section className="card-footer text-center">
                    <p className="mb-0">{table.getCoreRowModel().rows.length} adresses</p>
                </section>
            </div>

        </section>

    </section>
}