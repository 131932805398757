import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Header, ButtonSubmit, TimeRound } from '../../../common/common'
import { ITimesheet, IUser } from "../../../common/types";
import { reqGet, reqPost, reqPut } from "../../../../service/apiRequest";
import { getToken } from "../../../../useToken";
import { TIMESHEET_TYPE } from "../../../common/constants";


const validationSchema = yup.object({
    TimesheetDate: yup.string().required("Ce champ est obligatoire"),

}).required();

export default function AccountPicketAdd({ setReload }) {
    const params = useParams()
    const idTimesheet = params.idTimesheet
    const navigate = useNavigate()
    const token = getToken()
    const location = useLocation()

    const { register, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm<{ TimesheetUserID: number, TimesheetDate: string }>({
        mode: "onTouched",
        resolver: yupResolver(validationSchema)
    });

    const [user, setUser] = useState<IUser>()

    useEffect(() => {
        reqGet("user/" + token.UserID).then(res => setUser(res))
    }, [])



    const onSubmit: SubmitHandler<{ TimesheetUserID: number, TimesheetDate: string }> = data => {
        var d = {
            ActivityUserID: token.UserID,
            FirstName: token.FirstName,
            Name: token.Name,
            TimesheetType: TIMESHEET_TYPE.TIMESHEET,
            TimesheetUserID: token.UserID,
            TimesheetStart: $("#TimesheetStart").val() ?
                data.TimesheetDate + " " + $("#TimesheetStart").val() + ":00" : null,
            TimesheetEnd: $("#TimesheetEnd").val() ?
                data.TimesheetDate + " " + $("#TimesheetEnd").val() + ":00" : null
        }


        reqPost('timesheet', d).then((res) => {
            navigate("/account/picket")
            setReload(true)
        })

    };

    return <div className="body-container content">
        {Header(
            <h2 className="section-title">Ajouter un service de piquet</h2>,
            <Link to="/account/picket" className="link-nav">{"<"} Retour</Link>
        )}

        <section className="body">

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row row-cols-2 mb-3">

                    <div className="col">
                        <label className="form-label">Date *</label>

                        <input type="date" className={"form-control " + (errors?.TimesheetDate ? "is-invalid" : "")} {...register("TimesheetDate")} id="TimesheetDate" />

                        {errors.TimesheetDate?.message && <p className="input-msg-error">{errors.TimesheetDate?.message}</p>}
                    </div>
                </div>

                <div className="row row-cols-2">
                    <div className="col mb-3">
                        <label className="form-label">Début de piquet</label>

                        <div className="input-group">
                            <input type="time" id="TimesheetStart" className="form-control" />
                        </div>
                    </div>

                    <div className="col mb-3">
                        <label className="form-label">Retour de piquet</label>

                        <div className="input-group">
                            <input type="time" id="TimesheetEnd" className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <ButtonSubmit />
                </div>

            </form>
        </section>
    </div>
}