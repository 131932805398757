import React from "react";

export default function HelpVehicleCheck() {
    return <>

        <h2 className="section-title">Entrepôt</h2>

        <h4 className="section-title-2">Ajout</h4>

    </>
}