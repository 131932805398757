import React, { useState, useEffect, useLayoutEffect } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom'
import $ from 'jquery'

import { PrivateComponent } from './common/common';
import { BADGE, DEFAULT_IMG, GROUP, ICONS, IMAGE_PATH, LOGO, PERMISSION } from "./common/constants"

const APP_TITLE = "Multi & Therme App"
const VERSION = "∞ Alpha 2.1"



export default function Nav({ token, user }) {
    return <>
        <NavComputer />
        <NavMobile token={token} user={user} />
    </>
}


export function NavComputer() {

    return <>
        <nav className="nav-container col d-none d-xl-block">
            <div className="nav">

                <header className="nav-header">
                    <Link to="home" className="text-decoration-none">
                        <img className='nav-logo' src={LOGO} alt="Multitherme logo" />
                        <h6 className="nav-title link-underline-hover">{APP_TITLE}</h6>
                    </Link>
                </header>

                <section className="nav-body hide-scrollbar">
                    <NavBody />
                </section>
            </div>
        </nav>
    </>
}


export function NavMobile({ token, user }) {
    const navigate = useNavigate()
    const [navActive, setNavActive] = useState("d-none");
    const [navPersonalActive, setPersonalNavActive] = useState("d-none");

    useLayoutEffect(() => {
        $("#navMobile .nav-link").click(toggle)
        $("#navMobilePersonal .nav-link").click(togglePersonal)
    })

    function toggle() {
        if (navActive == "d-block") {
            setNavActive("d-none");
        } else {
            setPersonalNavActive("d-none");
            setNavActive("d-block");
        }
    }
    function togglePersonal() {
        if (navPersonalActive == "d-block") {
            setPersonalNavActive("d-none");
        } else {
            setNavActive("d-none");
            setPersonalNavActive("d-block");
        }
    }

    function logout() {
        localStorage.removeItem('token')
        navigate('/login')
    }

    return (
        <>
            <nav className="nav-mobile-header d-xl-none">
                <div className="w-25 ps-3">
                    <button className="btn btn-unstyled p-0" onClick={toggle}>
                        <i className="bi bi-list"></i>
                    </button>
                </div>

                <div className="w-50 text-center">
                    <Link to="/home">
                        <img className='nav-logo' src={LOGO} alt="Multitherme logo" />
                        <h5 className="nav-mobile-title">MT App</h5>
                    </Link>
                </div>

                <div className="w-25 pe-3 d-flex justify-content-end align-items-center">
                    {PrivateComponent(
                        <button className="btn p-0 me-3" title="Notifications" type="button" style={{ fontSize: ".75rem" }}>
                            <i className={ICONS.NOTIFICATION}></i>
                        </button>,
                        GROUP.dev
                    )}

                    <NavLink to="/account" className="btn btn-board" title="Mon compte" type="button" style={{ fontSize: "1.4rem" }}>
                        <img style={{ height: "2rem", width: "2rem", objectFit: "cover", borderRadius: "1rem" }} src={user?.UserImage ? IMAGE_PATH.USERS + user.UserImage : DEFAULT_IMG} alt="User profile picture" />
                    </NavLink>
                    {/*
                    <div className="dropdown dropdown-account">
                        <button className="btn p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-person"></i>
                        </button>

                        <ul className="dropdown-menu p-3">
                            <h6 className="text-nowrap mb-3 ps-3">{token.FirstName} {token.Name}</h6>
                            <li><Link className="dropdown-item mb-2" to="/account" title="Mon compte">Mon compte</Link></li>
                            <li><button className="dropdown-item" onClick={() => logout()}>Déconnexion</button></li>
                        </ul>
                    </div>*/}
                </div>
            </nav>



            <nav id="navMobile" className={"nav-mobile d-xl-none " + navActive}>
                <button className="btn btn-exit-nav" onClick={toggle} title="Fermer">
                    <i className="bi bi-x"></i>
                </button>
                <nav className="nav">

                    <div className="nav-header d-flex justify-content-between align-items-center">

                        <Link to="/home" onClick={toggle}>
                            <h5 className="nav-title ms-0 mb-0">{APP_TITLE}</h5>
                        </Link>

                    </div>

                    <div className="nav-body hide-scrollbar">

                        <NavBody />

                    </div>
                </nav>
            </nav>

            <nav id="navMobilePersonal" className={"nav-mobile-personal d-xl-none " + navPersonalActive}>
                <button className="btn btn-exit-nav" onClick={togglePersonal} title="Fermer">
                    <i className="bi bi-x"></i>
                </button>
                <nav className="nav">

                    <div className="nav-header d-flex justify-content-between align-items-center">

                        <Link to="/account" onClick={togglePersonal}>
                            <h5 className="nav-title ms-0 mb-0">{token.FirstName} {token.Name}</h5>
                        </Link>

                    </div>

                    <div className="nav-body hide-scrollbar">
                        <section className="nav-group mb-0">
                            <NavLink to="/account" className="nav-link">
                                <i className={ICONS.ACCOUNT}></i> Mon compte
                            </NavLink>
                            <hr />
                            <button className="nav-link w-100 text-start" onClick={() => logout()}>
                                <i className={ICONS.LOGOUT}></i> Déconnexion
                            </button>
                        </section>
                    </div>
                </nav>
            </nav>
        </>
    );
}


function NavBody() {

    const [rManagement, setRManagement] = useState<Array<JSX.Element | null>>([])
    const [rStock, setRStock] = useState<Array<JSX.Element | null>>([])
    const [rConstruction, setRConstruction] = useState<Array<JSX.Element | null>>([])
    const [rData, setRData] = useState<Array<JSX.Element | null>>([])
    const [rOther, setROther] = useState<Array<JSX.Element | null>>([])

    useEffect(() => {
        setRManagement(InitRManagement())
        setRStock(InitRStock())
        setRData(InitRData())
        setRConstruction(InitRConstruction())
        setROther(InitROther())
    }, [])

    function InitRManagement() {
        let routes: Array<JSX.Element | null> = []

        routes.push(
            <NavLink to="/address" className="nav-link">
                <i className={ICONS.ADDRESS}></i> Adresses
            </NavLink>
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/construction" className="nav-link">
                    <i className={ICONS.CONSTRUCTION}></i> Chantier {BADGE.BETA}
                </NavLink>,
                GROUP.crud_sav
            ) ?? null
        )


        routes.push(
            PrivateComponent(
                <NavLink to="/client" className="nav-link">
                    <i className={ICONS.CLIENTS}></i> Clients
                </NavLink>,
                GROUP.crud_sav,
                ["ClientRead"]
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/contract" className="nav-link">
                    <i className={ICONS.CONTRACT}></i> Contrats {BADGE.BETA}
                </NavLink>,
                GROUP.crud_sav,
                ["ContractRead"]
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/installation" className="nav-link">
                    <i className={ICONS.INSTALLATION}></i> Installations
                </NavLink>
                , GROUP.crud_sav
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/material" className="nav-link">
                    <i className={ICONS.MATERIALS}></i> Matériels
                </NavLink>,
                GROUP.admin,
                PERMISSION.material
            ) ?? null
        )

        /*
        routes.push(
            PrivateComponent(
                <NavLink to="/observation" className="nav-link text-truncate">
                    <i className="bi bi-inboxes"></i> Procédures {BADGE.DEV}
                </NavLink>,
                GROUP.dev
            ) ?? null
        )
        */

        routes.push(
            PrivateComponent(
                <NavLink to="/vehicle" className="nav-link">
                    <i className={ICONS.VEHICLES}></i> Véhicules
                </NavLink>,
                GROUP.admin,
                PERMISSION.vehicleCheck
            ) ?? null
        )

        routes.push(
            <NavLink to="/zone" className="nav-link">
                <i className={ICONS.ZONES}></i> Zones
            </NavLink>
        )

        // return routes not null
        return routes.filter(r => r)
    }



    function InitRStock() {
        let routes: Array<JSX.Element | null> = []

        routes.push(
            PrivateComponent(
                <NavLink to="/warehouse" className="nav-link">
                    <i className={ICONS.WAREHOUSE}></i> Entrepôts
                </NavLink>
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/tool" className="nav-link">
                    <i className={ICONS.TOOL}></i> Outils
                </NavLink>,
                GROUP.admin,
                PERMISSION.product
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/piece" className="nav-link">
                    <i className={ICONS.PIECE}></i> Pièces
                </NavLink>,
                GROUP.admin,
                PERMISSION.product
            ) ?? null
        )

        /*
        routes.push(
            PrivateComponent(
                <NavLink to="/exchanger" className="nav-link">
                    <i className={ICONS.EXCHANGER}></i> Echangeurs
                </NavLink>,
                GROUP.admin,
                PERMISSION.product
            ) ?? null
        )*/

        routes.push(
            PrivateComponent(
                <NavLink to="/product-price" className="nav-link">
                    <i className={"bi bi-currency-dollar"}></i> Prix
                </NavLink>,
                GROUP.admin,
                ["PriceRead"]
            ) ?? null
        )

        // return routes not null
        return routes.filter(r => r)
    }



    function InitRConstruction() {
        let routes: Array<JSX.Element | null> = []

        // return routes not null
        return routes.filter(r => r)
    }



    function InitRData() {
        let routes: Array<JSX.Element | null> = []

        routes.push(
            PrivateComponent(
                <NavLink to="/activity" className="nav-link">
                    <i className={ICONS.ACTIVITY}></i> Activité
                </NavLink>,
                GROUP.admin
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/heating" className="nav-link">
                    <i className={ICONS.HEATING}></i> Chauffage
                </NavLink>,
                GROUP.crud_sav,
                ["HeatingRead"]
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/counter-statement" className="nav-link text-truncate">
                    <i className={ICONS.COUNTER}></i> Compteurs {BADGE.DEV}
                </NavLink>,
                GROUP.dev
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/holiday" className="nav-link">
                    <i className={ICONS.HOLIDAY}></i> Congés {BADGE.BETA}
                </NavLink>,
                GROUP.rh,
                ["HolidayRead"]
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/database" className="nav-link">
                    <i className={ICONS.DATABASE}></i> Données
                </NavLink>,
                GROUP.admin,
                ["ManagementRead", "OwnerRead", "ZipCodeRead"]
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/permission" className="nav-link">
                    <i className={ICONS.PERMISSION}></i> Droits {BADGE.DEV}
                </NavLink>,
                GROUP.dev
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/timesheet" className="nav-link">
                    <i className={ICONS.TIMESHEET}></i> Relevé d'heures
                </NavLink>,
                GROUP.rh
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/survey" className="nav-link">
                    <i className={ICONS.SURVEY}></i> Surveillance
                </NavLink>,
                GROUP.crud_sav,
                ["SurveyRead"]
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/users" className="nav-link">
                    <i className={ICONS.USERS}></i> Utilisateurs
                </NavLink>,
                GROUP.crud_sav
            ) ?? null
        )

        // return routes not null
        return routes.filter(r => r)
    }



    function InitROther() {
        let routes: Array<JSX.Element | null> = []

        routes.push(
            PrivateComponent(
                <NavLink to="/help" className="nav-link">
                    <i className={ICONS.HELP}></i> Aide {BADGE.BETA}
                </NavLink>
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/test" className="nav-link">
                    <i className={ICONS.TEST}></i> Test
                </NavLink>,
                GROUP.dev
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="/ui" className="nav-link">
                    <i className={ICONS.UI}></i> Ui
                </NavLink>,
                GROUP.admin
            ) ?? null
        )

        // return routes not null
        return routes.filter(r => r)
    }


    return <>


        <div className="nav-group">
            <h6 className="nav-group-title">Gestion</h6>
            {rManagement && rManagement.length > 0 &&
                rManagement.map((r, k) => <React.Fragment key={k}>{r}</React.Fragment>)
            }
        </div>


        {rStock && rStock.length > 0 &&
            <div className="nav-group nav-storage">
                <h6 className="nav-group-title">Stock</h6>

                {rStock.map((r, k) => <React.Fragment key={k}>{r}</React.Fragment>)}
            </div>}

        {rConstruction && rConstruction.length > 0 &&
            <div className="nav-group nav-construction">
                <h6 className="nav-group-title">Chantier</h6>

                {rConstruction.map((r, k) => <React.Fragment key={k}>{r}</React.Fragment>)}
            </div>}

        {rData && rData.length > 0 &&
            <div className="nav-group nav-admin">
                <h6 className="nav-group-title">Administration</h6>

                {rData.map((r, k) => <React.Fragment key={k}>{r}</React.Fragment>)}
            </div>}

        {rOther && rOther.length > 0 &&
            <div className="nav-group nav-other">
                <h6 className="nav-group-title">Autre</h6>

                {rOther.map((r, k) => <React.Fragment key={k}>{r}</React.Fragment>)}
            </div>}

    </>
}


function NavFooter() {
    const navigate = useNavigate()

    function logout() {
        localStorage.removeItem('token')
        navigate('/login')
    }
    return <>
        <section className="nav-group mb-0">

            <h6 className='nav-group-title'>Personnel</h6>

            <Link className="nav-link" to="/account">
                <i className="bi bi-person"></i> Mon compte
            </Link>

            {PrivateComponent(
                <NavLink to="/notification" className="nav-link">
                    <i className="bi bi-bell"></i> Notifications
                </NavLink>,
                GROUP.dev
            )}

            <button className="w-100 text-start nav-link" onClick={() => logout()}>
                <i className="bi bi-box-arrow-left"></i> Déconnexion
            </button>
        </section>
    </>
}