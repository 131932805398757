import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GenerateWorkingHours, dateFormat, fuzzyFilter, fuzzySort } from "../../common/common";
import { reqGet } from "../../../service/apiRequest";
import { ITimesheet, IWeek } from "../../common/types";
import { FormatTimesheetArray, ITimesheetArray, TimesheetWeekDuration, TimesheetWeekKm } from "./timesheet";
import { Page, Text, View, Document, StyleSheet, Image, Font, PDFViewer } from '@react-pdf/renderer';
import { ICONS, LAYOUT } from "../../common/constants";
import $ from 'jquery'
import { ColumnDef, getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

interface ITimesheetPercent {
    Start: Date | string | null,
    End?: Date | string | null,
    BreakStart?: Date | string | null,
    BreakEnd?: Date | string | null,
    Duration: string,
    Percent: number,
    PercentDuration: string
}

export default function TimesheetPrint() {
    const params = useParams()
    const idWeek = params.idWeek

    const [week, setWeek] = useState<IWeek>()
    const [timesheetsChecked, setTimesheetsChecked] = useState<Array<ITimesheetArray>>([])

    const [weeks, setWeeks] = useState<Array<IWeek>>([])
    const [year, setYear] = useState<number>(new Date().getFullYear())

    const [init, setInit] = useState<Boolean>(true)

    const [data, setData] = useState<Array<any>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const columns = useMemo<ColumnDef<any>[]>(
        () => [
            {
                accessorFn: row => row.FirstName,
                id: 'FirstName',
                cell: info => info.getValue(),
                sortingFn: fuzzySort
            },
            {
                accessorFn: row => row.Name,
                id: 'Name',
                cell: info => info.getValue(),
                sortingFn: fuzzySort
            }
        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        reqGet("week/year/" + year).then(res => {
            setWeeks(res)
        })

        reqGet("week/" + idWeek).then((w: IWeek) => {
            setWeek(w)
            const date = new Date(w.DateStart)

            reqGet("timesheet/weekstart/" + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()).then(res => {
                setData(FormatTimesheetArray(res))
            })

        })
        table.getColumn("Name")?.toggleSorting(false)
    }, [idWeek])



    // HANDLE CHECK ----------------------------------------
    function HandleChecked() {
        let arrayID: Array<number> = []
        $(".form-check-input:checked").each(function () {
            arrayID.push(parseInt($(this).val()))
        })

        setTimesheetsChecked(data.filter(user => arrayID.includes(user.UserID)))

        if (data.filter(user => arrayID.includes(user.UserID)).length == data.length) {
            $("#checkAll").prop('checked', true)
            $('#checkAllLabel').text('Tout déselectionner');
        } else {
            $("#checkAll").prop('checked', false)
            $('#checkAllLabel').text('Tout sélectionner');
        }
    }

    function CheckAll() {

        if (!$("#checkAll").is(':checked')) {
            $('.form-check-input').prop('checked', false);
            $('#checkAllLabel').text('Tout sélectionner');
            setTimesheetsChecked([])
        } else {
            $(".form-check-input").prop('checked', true)
            $('#checkAllLabel').text('Tout déselectionner');
            setTimesheetsChecked(data)
        }
    }
    // HANDLE CHECK ----------------------------------------


    // HANDLE WEEK CHANGE ----------------------------------
    // Week updated
    useEffect(() => {
        if (week) {
            var date = new Date(week?.DateStart)

            reqGet("timesheet/weekstart/" + year + "-" + (date.getMonth() + 1) + "-" + (date.getDate() - date.getDay() + 1)).then(res => {
                setData(FormatTimesheetArray(res))
            })

            $("#weekID-" + week.WeekID).addClass("active")

            // uncheck all
            $('.form-check-input').prop('checked', false);
            $('#checkAllLabel').text('Tout sélectionner');
            setTimesheetsChecked([])
        }
    }, [week])

    // Year updated
    useEffect(() => {
        if (year && !init)
            reqGet("week/year/" + year).then(res => {
                setWeeks(res)
                if (week?.WeekNumber == 1)
                    setWeek(res[res.length - 1])
                else
                    setWeek(res[0])
            })
        else setInit(false)

    }, [year])

    function HandleWeekChange(idWeek) {
        setWeek(weeks?.find(w => w.WeekID == idWeek))
    }

    function HandleNextWeek() {
        if (week?.WeekNumber == 52) {
            setYear(year + 1)
        } else {
            var w = weeks?.find(w => w.WeekNumber == (week?.WeekNumber ?? 0) + 1)
            setWeek(w)
            $("#selectWeek").val(w?.WeekID)
        }
    }

    function HandlePreviousWeek() {
        if (week?.WeekNumber == 1) {
            setYear(year - 1)
        } else {
            var w = weeks?.find(w => w.WeekNumber == (week?.WeekNumber ?? 0) - 1)
            setWeek(w)
            $("#selectWeek").val(w?.WeekID)
        }
    }
    // HANDLE WEEK CHANGE ----------------------------------


    // HANDLE DISPLAY MOBILE PDF --------------------------
    const [display, setDisplay] = useState<string>("")
    const [display2, setDisplay2] = useState<string>("")
    const [displayMobilePDF, setDisplayMobilePDF] = useState<boolean>(false)

    function HandleDisplayPDF(isDisplay) {
        setDisplayMobilePDF(isDisplay)
    }


    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location, displayMobilePDF])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.lg) {
            setDisplay('d-flex')
            setDisplay2('d-flex')
        } else {
            if (displayMobilePDF) {
                setDisplay('d-flex')
                setDisplay2('d-none')
            } else {
                setDisplay('d-none')
                setDisplay2('d-flex')
            }
        }
    }
    // HANDLE DISPLAY MOBILE PDF --------------------------

    return <section className="body-container content">

        <header className="container-fluid">
            <div className="row">
                <div className="col-lg-6 ps-0">
                    <h2 className="section-title mb-0">Imprimer les feuilles d'heures</h2>
                    <Link to="/timesheet" className="link-nav">{"<"} Retour</Link>
                </div>

                <div className="col-lg-6 pe-0">
                    <nav className="d-flex justify-content-center justify-content-lg-end align-items-center">
                        <button
                            className="btn btn-outline d-inline rounded"
                            onClick={() => HandlePreviousWeek()}
                        >
                            <i className="bi bi-caret-left"></i>
                        </button>

                        <select className="form-select mx-3 d-inline w-auto" onChange={(e) => HandleWeekChange(e.target.value)} id="selectWeek" value={week?.WeekID}>
                            {weeks?.map((w) => {
                                return <option key={w.WeekID} value={w.WeekID}>Sem. {w.WeekNumber}: {dateFormat(w.DateStart, "dd MMM yyyy")}</option>
                            })}
                        </select>

                        <input type="number" className="form-control me-3 d-inline" style={{ width: "65px" }} value={year} onChange={(e) => setYear(parseInt(e.target.value))} />

                        <button
                            className="btn btn-outline d-inline rounded" id="btnNext"
                            onClick={() => HandleNextWeek()}
                        >
                            <i className="bi bi-caret-right"></i>
                        </button>
                    </nav>

                </div>
            </div>
        </header>



        <section className="body container-fluid">

            <div className="row h-100">
                <div className={display2 + " col-lg-4 col-h-fill px-0 pe-lg-3"}>
                    <div className="body-container">
                        <header>
                            <h3 className="section-title-2">Utilisateurs</h3>
                        </header>

                        <nav className="nav-horizontal justify-content-between align-items-center mb-1">
                            <div className="form-check mb-0">
                                <input className="form-check-input" type="checkbox" id="checkAll" onChange={() => CheckAll()} />
                                <p className="form-check-label ms-3 mb-0" id="checkAllLabel">Tout sélectionner</p>
                            </div>

                            <select name="selectSortName" id="selectSortName" className="form-select w-auto" onChange={(e) => table.getColumn(e.target.value)?.toggleSorting()}>
                                <option value="Name">Nom</option>
                                <option value="FirstName">Prénom</option>
                            </select>
                        </nav>

                        <section className="body border rounded">
                            <div className="list-group list-group-flush list-group-condensed">
                                {table && table?.getRowModel().rows.map(row => {

                                    let user = row.original

                                    return <div className="list-group-item d-flex justify-content-between align-items-start" key={user.UserID}>
                                        <div className="form-check mb-0">
                                            <input className="form-check-input mt-3" type="checkbox" value={user.UserID} id={"checkbox-" + user.UserID} onChange={() => HandleChecked()} />

                                            <div className="ms-3">
                                                {table.getColumn("Name")?.getIsSorted() ?
                                                    <h6 className="form-label mb-1">{user.Name} {user.FirstName}</h6> :
                                                    <h6 className="form-label mb-1">{user.FirstName} {user.Name}</h6>
                                                }
                                                <p className="mb-0">{user.Role}</p>
                                            </div>
                                        </div>

                                        <div className="text-end">
                                            <h6 className="mb-1">{TimesheetWeekDuration(user.Timesheets, true)} heures</h6>
                                            <p className="mb-0">{user.Timesheets.length} feuilles</p>
                                        </div>

                                    </div>
                                })}
                            </div>
                        </section>

                        <footer className="footer d-lg-none">
                            <button className="btn btn-add" onClick={() => HandleDisplayPDF(true)}><i className={ICONS.ADD + " me-2"}></i>Valider</button>
                        </footer>
                    </div>
                </div>

                <div className={display + " col-lg-8 col-h-fill px-0 align-items-start ps-lg-3"}>
                    <h3 className="section-title-2">Semaine {week?.WeekNumber}
                        <span className="section-subtitle ms-3">{dateFormat(week?.DateStart, "dd MMM")} - {dateFormat(week?.DateEnd, "dd MMM")}</span>
                    </h3>



                    <button className="btn d-block link-nav p-0 d-lg-none mb-3" onClick={() => HandleDisplayPDF(false)}>{"<"} Retour</button>

                    <div className="card card-fill w-100">
                        <PDFViewer width={"100%"} height={"100%"}>
                            <PdfTimesheet week={week} timesheetsChecked={timesheetsChecked} />
                        </PDFViewer>

                    </div>
                </div>
            </div>

        </section>
    </section>
}



const PdfTimesheet = ({ week, timesheetsChecked }: { week: IWeek | undefined, timesheetsChecked: Array<ITimesheetArray> }) => {

    return <Document>
        {timesheetsChecked.map((user: ITimesheetArray) => {
            return <Page style={styles.body}>
                <Image style={styles.logo} src="/media/images/multitherme/mt_logo_text.jpg" />
                <Text style={styles.title}>Feuille d'heures</Text>
                <Text style={styles.subtitle}>{user.FirstName} {user.Name}</Text>

                <Text style={[styles.bold, { fontSize: 12, marginBottom: 2 }]}>Semaine {week?.WeekNumber}</Text>
                <Text style={[{ marginBottom: "30pt" }, styles.text]}>{dateFormat(week?.DateStart, "dd/MM/yyyy")} - {dateFormat(week?.DateEnd, "dd/MM/yyyy")}</Text>
                <View style={[styles.table, { marginBottom: "10pt" }]}>
                    <View style={[styles.row]} wrap={false}>
                        <Text style={[styles.col, styles.header]}>Date</Text>
                        <Text style={[styles.col, { width: "17%" }, styles.header]}>Début</Text>
                        <Text style={[styles.col, { width: "26%" }, styles.header]}>Pause de midi</Text>
                        <Text style={[styles.col, { width: "17%" }, styles.header]}>Fin</Text>
                        <Text style={[styles.col, styles.header]}>Sous-total</Text>
                        <Text style={[styles.col, styles.header]}>Pourcent</Text>
                        <Text style={[styles.col, styles.header]}>Total</Text>
                    </View>

                    {user.Timesheets.map((t, key) => {

                        return <>

                            {TimesheetPercent({ timesheet: t }).map((tp: ITimesheetPercent, k: number) => {

                                return <View key={t.TimesheetID + "_" + k} style={styles.row} wrap={false}>
                                    <Text style={[styles.col, styles.text]}>{dateFormat(tp.Start, "dd/MM/yyyy")}</Text>
                                    <Text style={[styles.col, { width: "17%" }, styles.text]}>{dateFormat(tp.Start, "hh:mm")}</Text>
                                    <Text style={[styles.col, { width: "26%" }, styles.text]}>{dateFormat(tp.BreakStart, "hh:mm")}{tp.BreakEnd ? " - " + dateFormat(tp.BreakEnd, "hh:mm") : ""}</Text>
                                    <Text style={[styles.col, { width: "17%" }, styles.text]}>{dateFormat(tp.End, "hh:mm")}</Text>
                                    <Text style={[styles.col, styles.text]}>{tp.Duration}</Text>
                                    <Text style={[styles.col, styles.text]}>{tp.Percent * 100}%</Text>
                                    <Text style={[styles.col, styles.text]}>{tp.PercentDuration}</Text>
                                </View>
                            })}

                        </>

                    })}


                    <View style={[styles.row]} wrap={false}>
                        <Text style={[{ width: "80%" }, styles.text, styles.bold]}>Total de la semaine</Text>
                        <Text style={[{ margin: 0 }, styles.col, styles.text, styles.bold]}>{TimesheetWeekDuration(user.Timesheets, true)}</Text>
                        <Text style={[styles.col, styles.text]}></Text>
                        <Text style={[{ margin: 0 }, styles.col, styles.text, styles.bold]}>{TimesheetWeekDuration(user.Timesheets, true, true)}</Text>
                    </View>
                </View>


                <View style={styles.table}>
                    <View style={[styles.row]} wrap={false}>
                        <Text style={[styles.col, { width: "86%" }, styles.header]}>Date</Text>
                        <Text style={[styles.col, { width: "14%" }, styles.header]}>Km</Text>
                    </View>

                    {user.Timesheets.map((t, key) => {

                        if (t.TimesheetKm)
                            return <View key={key} style={styles.row} wrap={false}>
                                <Text style={[styles.col, { width: "86%" }, styles.text]}>{dateFormat(t.TimesheetStart, "dd/MM/yyyy")}</Text>
                                <Text style={[styles.col, { width: "14%" }, styles.text]}>{t.TimesheetKm}</Text>
                            </View>

                    })}


                    <View style={[styles.row]} wrap={false}>
                        <Text style={[styles.col, { width: "86%" }, styles.bold]}>Total de la semaine</Text>
                        <Text style={[styles.col, { width: "14%" }, styles.bold]}>{TimesheetWeekKm(user.Timesheets)}</Text>
                    </View>
                </View>
            </Page>
        })}

    </Document>
}

Font.register({
    family: 'Helvetica',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 20,
        fontFamily: 'Helvetica-Bold'

    },
    subtitle: {
        fontSize: 14,
        fontFamily: 'Helvetica',
        marginBottom: "50pt"
    },
    header: {
        fontSize: 11,
        fontFamily: 'Helvetica-Bold'
    },
    text: {
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Helvetica'
    },
    bold: {
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold'
    },
    logo: {
        width: "80 pt",
        marginBottom: "50pt"
    },
    table: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
    },
    col: {
        width: "20%",
        fontSize: "9px"
    }
});



const TimesheetPercent = ({ timesheet }: { timesheet: ITimesheet | any }) => {
    let array: Array<ITimesheetPercent> = []

    if (!timesheet)
        return array

    const MIN_CONV = 60000

    var min_morning = 0, min_day = 0, min_afternoon = 0, min_night = 0, min_sat = 0, min_sun = 0, min_holiday = 0

    const start = new Date(timesheet.TimesheetStart)
    const end = timesheet.TimesheetEnd ? new Date(timesheet.TimesheetEnd) : null
    const bStart = timesheet.TimesheetLunchStart ? new Date(timesheet.TimesheetLunchStart) : null
    const bEnd = timesheet.TimesheetLunchEnd ? new Date(timesheet.TimesheetLunchEnd) : null

    let pointer_temp

    const WORKING_HOURS = GenerateWorkingHours(start)

    // if saturday
    if (start.getDay() == 6) {
        if (end)
            min_sat += Math.round((end.getTime() - start.getTime()) / MIN_CONV)
        else
            return array
    }
    // if sunday
    else if (start.getDay() == 0) {
        if (end)
            min_sun += Math.round((end.getTime() - start.getTime()) / MIN_CONV)
        else
            return array
    }
    else {
        // if before working hours
        if (start.getHours() < WORKING_HOURS.DAY.START.getHours()) {
            min_morning += Math.round((WORKING_HOURS.DAY.START.getTime() - start.getTime()) / MIN_CONV)
            pointer_temp = WORKING_HOURS.DAY.START
        } else {
            pointer_temp = start
        }

        // if break
        if (bStart) {
            min_day += Math.round((bStart.getTime() - pointer_temp.getTime()) / MIN_CONV)
        }

        // if end break
        if (bEnd) {
            pointer_temp = bEnd
        }

        // if end
        if (end) {

            // If after end of the day
            if (end > WORKING_HOURS.DAY.END) {

                // If start after end of the day
                if (start < WORKING_HOURS.DAY.END) {
                    min_day += Math.round((WORKING_HOURS.DAY.END.getTime() - pointer_temp.getTime()) / MIN_CONV)
                    pointer_temp = WORKING_HOURS.DAY.END
                }

                //if after end of afternoon
                if (end > WORKING_HOURS.AFTERNOON.END) {

                    // If start after end of the afternoon
                    if (start < WORKING_HOURS.AFTERNOON.END) {
                        min_afternoon += Math.round((WORKING_HOURS.AFTERNOON.END.getTime() - pointer_temp.getTime()) / MIN_CONV)
                        pointer_temp = WORKING_HOURS.AFTERNOON.END
                    }

                    min_night += Math.round((end.getTime() - pointer_temp.getTime()) / MIN_CONV)
                } else
                    min_afternoon += Math.round((end.getTime() - pointer_temp.getTime()) / MIN_CONV)
            } else {
                min_day += Math.round((end.getTime() - pointer_temp.getTime()) / MIN_CONV)
            }
        }
    }

    /*
    if (min_morning > 0) {
        let e = min_day > 0 ? WORKING_HOURS.MORNING.END : timesheet.TimesheetEnd

        array.push({
            Start: timesheet.TimesheetStart,
            End: e,
            Duration: (Math.round(min_morning / 60 * 4) / 4).toFixed(2),
            Percent: WORKING_HOURS.MORNING.PERCENT,
            PercentDuration: (Math.round(min_morning * WORKING_HOURS.MORNING.PERCENT / 60 * 4) / 4).toFixed(2)
        })
    }
    */

    if (min_day > 0) {
        //let s = min_morning > 0 ? WORKING_HOURS.DAY.START : timesheet.TimesheetStart
        let e = min_afternoon > 0 ? WORKING_HOURS.DAY.END : timesheet.TimesheetEnd

        array.push({
            Start: timesheet.TimesheetStart,
            End: e,
            BreakStart: timesheet.TimesheetLunchStart,
            BreakEnd: timesheet.TimesheetLunchEnd,
            Duration: (Math.round(min_day / 60 * 4) / 4).toFixed(2),
            Percent: WORKING_HOURS.DAY.PERCENT,
            PercentDuration: (Math.round(min_day * WORKING_HOURS.DAY.PERCENT / 60 * 4) / 4).toFixed(2)
        })
    }


    if (min_afternoon > 0) {
        let s = min_day > 0 ? WORKING_HOURS.AFTERNOON.START : timesheet.TimesheetStart
        let e = min_night > 0 ? WORKING_HOURS.AFTERNOON.END : timesheet.TimesheetEnd

        array.push({
            Start: s,
            End: e,
            Duration: (Math.round(min_afternoon / 60 * 4) / 4).toFixed(2),
            Percent: WORKING_HOURS.AFTERNOON.PERCENT,
            PercentDuration: (Math.round(min_afternoon * WORKING_HOURS.AFTERNOON.PERCENT / 60 * 4) / 4).toFixed(2)
        })
    }


    if (min_night > 0) {
        let s = min_afternoon > 0 ? WORKING_HOURS.NIGHT.START : timesheet.TimesheetStart

        array.push({
            Start: s,
            End: timesheet.TimesheetEnd,
            Duration: (Math.round(min_night / 60 * 4) / 4).toFixed(2),
            Percent: WORKING_HOURS.NIGHT.PERCENT,
            PercentDuration: (Math.round(min_night * WORKING_HOURS.NIGHT.PERCENT / 60 * 4) / 4).toFixed(2)
        })
    }


    if (min_sat > 0) {
        array.push({
            Start: timesheet.TimesheetStart,
            End: timesheet.TimesheetEnd,
            Duration: (Math.round(min_sat / 60 * 4) / 4).toFixed(2),
            Percent: WORKING_HOURS.SATURDAY.PERCENT,
            PercentDuration: (Math.round(min_sat * WORKING_HOURS.SATURDAY.PERCENT / 60 * 4) / 4).toFixed(2)
        })
    }


    if (min_sun > 0) {
        array.push({
            Start: timesheet.TimesheetStart,
            End: timesheet.TimesheetEnd,
            Duration: (Math.round(min_sun / 60 * 4) / 4).toFixed(2),
            Percent: WORKING_HOURS.SUNDAY.PERCENT,
            PercentDuration: (Math.round(min_sun * WORKING_HOURS.SUNDAY.PERCENT / 60 * 4) / 4).toFixed(2)
        })
    }

    return array
}
