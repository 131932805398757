import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate, Outlet } from "react-router-dom";

import { PrivateComponent } from '../../common/common'
import { GROUP, PERMISSION } from "../../common/constants"

export default function Databse() {
    const navigate = useNavigate()
    const location = useLocation()

    const [navLink, setNavLink] = useState<Array<JSX.Element | null>>([])

    useEffect(() => {
        const nav = InitNav()
        setNavLink(nav)
        if (location.pathname.endsWith("database")) {
            navigate(nav[0]?.props.to)
        }
    }, [])


    function InitNav() {
        let routes: Array<JSX.Element | null> = []

        routes.push(
            PrivateComponent(
                <NavLink to="zipcode" className="nav-link">
                    Code postaux
                </NavLink>,
                GROUP.crud_sav,
                ["ZipCodeRead"]
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="supplier" className="nav-link">
                    Fournisseur
                </NavLink>,
                GROUP.crud_sav
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="tenant" className="nav-link">
                    Locataire
                </NavLink>,
                GROUP.crud_sav,
                ["TenantRead"]
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="brand" className="nav-link">
                    Marque
                </NavLink>,
                GROUP.crud_sav
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="owner" className="nav-link">
                    Propriétaire
                </NavLink>,
                GROUP.crud_sav,
                ["OwnerRead"]
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="management" className="nav-link">
                    Régie
                </NavLink>,
                GROUP.crud_sav,
                ["ManagementRead"]
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="userrole" className="nav-link">
                    Rôle utilisateur
                </NavLink>,
                GROUP.admin
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="subcontractor" className="nav-link">
                    Sous-traitant
                </NavLink>,
                GROUP.crud_sav
            ) ?? null
        )

        routes.push(
            PrivateComponent(
                <NavLink to="tasktype" className="nav-link">
                    Type tâche
                </NavLink>,
                GROUP.admin,
                ["TaskTypeRead"]
            ) ?? null
        )


        // return routes not null
        return routes.filter(r => r)
    }

    return <section className="body-container content">

        <header className="header">

            <h2 className="section-title">Données</h2>

            {navLink && navLink.length > 0 &&
                <nav className="nav-horizontal pt-2 px-3 mb-0">
                    {navLink.map((l, k) => <React.Fragment key={k}>{l}</React.Fragment>)}
                </nav>
            }
        </header>

        <section className="body content">
            <Outlet />
        </section>
    </section>
}