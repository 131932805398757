import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { ButtonSubmit, CompareString, Header } from "../../../../common/common"
import { reqGet, reqPost } from "../../../../../service/apiRequest";
import { IBrand, IExchanger } from "../../../../common/types";
import { ICONS } from "../../../../common/constants";
import { getToken } from "../../../../../useToken";

export default function ExchangerTemplateAdd({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()

    const validationSchema = yup.object({
        ExchangerModel: yup.string().required("Ce champ est obligatoire"),
        ExchangerLabel: yup.string().required("Ce champ est obligatoire"),
        BrandID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        ExchangerReference: yup.string().nullable(),
        ExchangerHeight: yup.string().nullable(),
        ExchangerWidth: yup.string().nullable(),
        ExchangerDepth: yup.string().nullable(),
        ExchangerOutput: yup.string().nullable()
    })

    const initialValues = {
        ExchangerModel: "",
        ExchangerLabel: "",
        BrandID: null,
        ExchangerReference: null,
        ExchangerHeight: null,
        ExchangerWidth: null,
        ExchangerDepth: null,
        ExchangerOutput: null
    }

    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IExchanger>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [brand, setBrand] = useState<Array<IBrand>>([])
    const [exchangers, setExchangers] = useState<Array<IExchanger>>([])

    useEffect(() => {
        reqGet("exchanger").then(res => { setExchangers(res) })

        reqGet("brand").then(res => {
            setBrand(res)
            setValue("BrandID", res[0].BrandID)
        })

    }, [])

    const onSubmit: SubmitHandler<IExchanger> = data => {

        data.ExchangerOutput = $("#ExchangerOutputPt1").val() + ($("#ExchangerOutputPt1").val() || $("#ExchangerOutputPt2").val() ? "-" : "") + $("#ExchangerOutputPt2").val()

        reqPost('exchanger', { ...data, ActivityUserID: token.UserID }).then((res) => {
            setReload(true)
            navigate('/exchanger/template')
        })

    };

    const [validModel, setValidModel] = useState<Boolean>()

    function ValidateModel(model: string) {

        if (exchangers?.some(ex => CompareString(ex.ExchangerModel, model))) {
            setValidModel(false)
        } else {
            setValidModel(true)
        }

    }

    const [validLabel, setValidLabel] = useState<Boolean>()

    function ValidateLabel(label: string) {

        if (exchangers?.some(ex => CompareString(ex.ExchangerLabel, label))) {
            setValidLabel(false)
        } else {
            setValidLabel(true)
        }

    }

    return <section className="body-container">
        {Header(
            <h2 className="section-title">Ajouter un template d'échangeur</h2>,
            <Link to="/exchanger/template">{"<"} Retour</Link>
        )}

        <section className="body">

            <div className="body container-fluid">

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="row row-cols-2 mb-3">

                        <div className="col">
                            <label htmlFor="ExchangerModel" className="form-label">Modèle *</label>
                            <input type="text" className={"form-control " + (errors?.ExchangerModel ? "is-invalid" : "")} {...register("ExchangerModel")} onBlur={(e) => e.target.value ? ValidateModel(e.target.value) : ""} />

                            {errors.ExchangerModel?.message && <p className="mt-2 text-danger">{errors.ExchangerModel?.message}</p>}

                            {validModel == true && <p className="mb-0 mt-2 text-success"><i className={ICONS.SUCCESS + " me-2"}></i>Le modèle est valide</p>}
                            {validModel == false && <p className="mb-0 mt-2 text-danger"><i className={ICONS.DANGER + " me-2"}></i>Le modèle existe déjà</p>}
                        </div>

                        <div className="col">
                            <label htmlFor="ExchangerLabel" className="form-label">Label *</label>
                            <input type="text" className={"form-control " + (errors?.ExchangerLabel ? "is-invalid" : "")} {...register("ExchangerLabel")} placeholder="Ex: E1" onBlur={(e) => e.target.value ? ValidateLabel(e.target.value) : ""} />

                            {errors.ExchangerLabel?.message && <p className="mt-2 text-danger">{errors.ExchangerLabel?.message}</p>}

                            {validLabel == true && <p className="mb-0 mt-2 text-success"><i className={ICONS.SUCCESS + " me-2"}></i>Le label est valide</p>}
                            {validLabel == false && <p className="mb-0 mt-2 text-danger"><i className={ICONS.DANGER + " me-2"}></i>Le label existe déjà</p>}
                        </div>

                    </div>

                    <div className="row row-cols-2 mb-3">
                        <div className="col">
                            <label htmlFor="ExchangerReference" className="form-label">Référence</label>
                            <input type="text" className={"form-control " + (errors?.ExchangerReference ? "is-invalid" : "")} {...register("ExchangerReference")} />

                            {errors.ExchangerReference?.message && <p className="mt-2 text-danger">{errors.ExchangerReference?.message}</p>}
                        </div>

                        <div className="col">
                            <label htmlFor="BrandID" className="form-label">Marque</label>
                            <select className={"form-select " + (errors?.BrandID ? "is-invalid" : "")} id="" {...register("BrandID")}>
                                <option value=""></option>
                                {brand.map(b => {
                                    return <option key={b.BrandID} value={b.BrandID}>{b.Name}</option>
                                })}
                            </select>

                            {errors.BrandID?.message && <p className="mt-2 text-danger">{errors.BrandID?.message}</p>}
                        </div>
                    </div>

                    <div className="row row-cols-3 mb-3">
                        <div className="col">
                            <label htmlFor="ExchangerHeight" className="form-label">Hauteur</label>
                            <input type="number" className={"form-control " + (errors?.ExchangerHeight ? "is-invalid" : "")} {...register("ExchangerHeight")} />

                            {errors.ExchangerHeight?.message && <p className="mt-2 text-danger">{errors.ExchangerHeight?.message}</p>}
                        </div>

                        <div className="col">
                            <label htmlFor="ExchangerWidth" className="form-label">Largeur</label>
                            <input type="number" className={"form-control " + (errors?.ExchangerWidth ? "is-invalid" : "")} {...register("ExchangerWidth")} />

                            {errors.ExchangerWidth?.message && <p className="mt-2 text-danger">{errors.ExchangerWidth?.message}</p>}
                        </div>

                        <div className="col">
                            <label htmlFor="ExchangerDepth" className="form-label">Profondeur</label>
                            <input type="number" className={"form-control " + (errors?.ExchangerDepth ? "is-invalid" : "")} {...register("ExchangerDepth")} />

                            {errors.ExchangerDepth?.message && <p className="mt-2 text-danger">{errors.ExchangerDepth?.message}</p>}
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="ExchangerOutput" className="form-label">Sortie</label>
                        <div className="row">
                            <div className='col-4 col-md-3'>
                                <input type="text" maxLength={4} id="ExchangerOutputPt1" className={"form-control " + (errors?.ExchangerOutput ? "is-invalid" : "")} placeholder='1010' />
                            </div>
                            <div className='col-1 text-center'>-</div>
                            <div className='col-4 col-md-3'>
                                <input type="text" maxLength={4} id="ExchangerOutputPt2" className={"form-control " + (errors?.ExchangerOutput ? "is-invalid" : "")} placeholder='1010' />
                            </div>

                            {errors.ExchangerOutput?.message && <p className="mt-2 text-danger">{errors.ExchangerOutput?.message}</p>}
                        </div>
                    </div>


                    <div className="text-end">
                        <p><small>Les champs avec une * sont obligatoire</small></p>
                        <ButtonSubmit disabled={!validModel || !validLabel ? true : false} />
                    </div>

                </form>

            </div>

        </section>
    </section>
}