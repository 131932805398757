import React, { useEffect, useState } from "react";
import { Link, useLocation, useOutletContext, useParams } from "react-router-dom";
import { IAddress, IInvoice } from "../../../common/types";
import { reqGet } from "../../../../service/apiRequest";
import { PrivateComponent } from "../../../common/common";
import { GROUP, ICONS } from "../../../common/constants";

export default function AddressInvoiceContent() {
    const location = useLocation()
    const params = useParams()
    const idAddress = params.idAddress
    const idInvoice = params.idInvoice

    const [invoice, setInvoice] = useState<IInvoice>()

    const address: IAddress = useOutletContext<IAddress>()


    useEffect(() => {
        reqGet("invoice/" + idInvoice).then(res => setInvoice(res))
        console.log(address);
    }, [idInvoice])

    const LinkBack = () => {
        if (location?.state?.back)
            return location.state.back
        else
            return "/address/" + idAddress + "/invoice"
    }


    function RemoveInvoice(){

    }

    return <main className="body-container content">

        <div className="modal fade" id="modalRemoveAddress" tabIndex={-1} aria-labelledby="modalRemoveAddress" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="modalRemoveAddress">Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes-vous sur de vouloir supprimer :</p>
                        <h6 className="item-title"><b>{address?.AddressLine}, {address?.ZipCode} {address?.City}</b></h6>
                        {address?.AddressComplementary && <p className="item-text"><i>{address?.AddressComplementary}</i></p>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => RemoveInvoice()}>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>  



        <header className="header">
            <div>
                <h2 className="section-title">{invoice?.InvoiceTitle}</h2>
                <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
            </div>

            {PrivateComponent(
                <div className="dropdown">
                    <button className="btn btn-sm btn-outline rounded-3" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                    <ul className="dropdown-menu">
                        {PrivateComponent(
                            <li>
                                <Link to="update" className="dropdown-item mb-2"><i className={ICONS.UPDATE + " me-2"}></i>Modifier</Link>
                            </li>,
                            GROUP.admin, ["AddressInvoiceUpdate"]
                        )}
                        {PrivateComponent(
                            <li>
                                <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target="#modalRemoveInvoice">
                                    <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                </button>
                            </li>,
                            GROUP.admin, ["AddressInvoiceDelete"]
                        )}
                    </ul>
                </div>,
                GROUP.admin
            )}
        </header>

    </main>
}