import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

import { Header } from '../../common/common'

export default function Authorization() {
    return <section className="body-container">

        {Header(<h2 className="section-title">Permissions</h2>)}

        <section>
            <nav className="nav-horizontal">
                <NavLink to="groups" className="nav-link">Groupes</NavLink>
                <NavLink to="roles" className="nav-link">Roles</NavLink>
            </nav>
        </section>

        <section className="body container-fluid">
            <Outlet />
        </section>

    </section>
}