import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from 'jquery'

import { IActivity, ITimesheet, IWeek } from "../../common/types";
import { reqDelete, reqGet, reqPut } from "../../../service/apiRequest";
import { PrivateComponent, dateFormat, TimeCalculation, MonthWeeks, ButtonModal, DateFrom, isAuthorized } from "../../common/common";

import { GROUP, ICONS, MONTH_FR } from "../../common/constants";
import { getToken } from "../../../useToken";


export interface ITimesheetArray {
    UserID: number,
    FirstName: string,
    Name: string,
    Role: string,
    Timesheets: Array<{
        TimesheetID: number, TimesheetType: string, TimesheetStart: string | null, TimesheetLunchStart: string | null, TimesheetLunchEnd: string | null, TimesheetEnd: string | null, TimesheetDateUpdated: string | null, TimesheetNote: string | null, TimesheetValidate: number, TimesheetKm: number | null
    }>
}

export default function Timesheet() {
    const location = useLocation()

    const [init, setInit] = useState<Boolean>(true)
    const [reload, setReload] = useState<boolean>(false)

    const [weekActive, setWeekActive] = useState<IWeek>()
    const [weeks, setWeeks] = useState<Array<IWeek>>([])
    const [year, setYear] = useState<number>(new Date().getFullYear())

    const [timesheets, setTimesheets] = useState<Array<ITimesheet>>([])

    // INIT
    useEffect(() => {
        reqGet("week/year/" + year).then(res => {
            setWeeks(res)


            var w: IWeek

            if (location?.state?.date) {
                var oneJan = new Date(location?.state?.date.getFullYear(), 0, 1);
                let weekNb = Math.ceil((((location?.state?.date.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7);
                w = res.find(w => w.WeekNumber >= weekNb)
            } else {
                var currentDate = new Date()
                var oneJan = new Date(currentDate.getFullYear(), 0, 1);
                let weekNb = Math.ceil((((currentDate.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7);

                w = res.find(w => w.WeekNumber >= weekNb)
            }
            setWeekActive(w)
        })
    }, [])

    // Reload everything
    useEffect(() => {
        if (reload && weekActive) {
            reqGet("timesheet/week/" + weekActive.WeekID).then(res => {
                setTimesheets(res)
            })

            setReload(false)
        }
    }, [reload])

    // Week updated
    useEffect(() => {
        if (weekActive) {
            reqGet("timesheet/week/" + weekActive.WeekID).then(res => {
                setTimesheets(res)
            })
        }
    }, [weekActive])

    // Year updated
    useEffect(() => {
        if (year && !init)
            reqGet("week/year/" + year).then(res => {
                if (new Date(weeks[0].DateEnd).getFullYear() > new Date(res[0].DateEnd).getFullYear())
                    setWeekActive(res[res.length - 1])
                else
                    setWeekActive(res[0])

                setWeeks(res)
            }, rej => { })
        else setInit(false)
    }, [year])


    // Handle interface ----------------------------------
    function HandleWeekChange(idWeek) {
        setWeekActive(weeks?.find(w => w.WeekID == idWeek))
    }

    function HandleNextWeek() {
        if (weekActive?.WeekNumber == 52) {
            setYear(year + 1)
        } else {
            var w = weeks?.find(w => w.WeekNumber == (weekActive?.WeekNumber ?? 0) + 1)
            setWeekActive(w)
            $("#selectWeek").val(w?.WeekID)
        }

    }

    function HandlePreviousWeek() {
        if (weekActive?.WeekNumber == 1) {
            setYear(year - 1)
        } else {
            var w = weeks?.find(w => w.WeekNumber == (weekActive?.WeekNumber ?? 0) - 1)
            setWeekActive(w)
            $("#selectWeek").val(w?.WeekID)
        }
    }


    const DateOptions = {
        weekday: "short",
        month: "short",
        day: "numeric"
    }


    return <div className="body-container content">

        <header className="row mb-3">
            <div className="col-6 col-md-3">
                <h2 className="section-title">Relevé heures</h2>
            </div>

            <div className="col-12 col-md-6 order-3 order-md-2">
                <nav className="text-center mb-3">

                    <button
                        className="btn btn-outline d-inline rounded"
                        onClick={() => HandlePreviousWeek()}
                    >
                        <i className="bi bi-caret-left"></i>
                    </button>

                    <select className="form-select mx-3 d-inline w-auto" onChange={(e) => HandleWeekChange(e.target.value)} id="selectWeek" value={weekActive?.WeekID}>
                        {weeks?.length > 0 && weeks?.map((w) => {
                            return <option key={w.WeekID} value={w.WeekID}>Semaine {w.WeekNumber}: {dateFormat(w.DateStart, "dd/MM/yyyy")}</option>
                        })}
                    </select>

                    <input type="number" className="form-control me-3 d-inline" style={{ width: "65px" }} value={year} onChange={(e) => setYear(parseInt(e.target.value))} />

                    <button
                        className="btn btn-outline d-inline rounded" id="btnNext"
                        onClick={() => HandleNextWeek()}
                    >
                        <i className="bi bi-caret-right"></i>
                    </button>

                </nav>
            </div>

            <div className="col-6 col-md-3 order-2 order-md-3 text-end">

                {PrivateComponent(
                    <Link to="add" title="Ajouter" className="btn btn-add me-3"><i className={ICONS.ADD + " me-2"}></i> Ajouter</Link>,
                    GROUP.rh
                )}
                {PrivateComponent(
                    <Link className="btn btn-outline" to={weekActive?.WeekID + "/print"}>
                        <i className={ICONS.PRINT + " me-2"}></i> Imprimer
                    </Link>,
                    GROUP.rh
                )}
            </div>

        </header>


        <header className="container-fluid mb-3">
            <div className="row">
                <div className="col-2">
                    <h6 className="mb-6">Utilisateur</h6>
                </div>
                <div className="col">
                    <h6 className="mb-1">{weekActive && dateFormat(new Date(weekActive.DateStart), "ddd dd MMM")}</h6>
                </div>
                <div className="col">
                    <h6 className="mb-1">{weekActive && dateFormat(DateFrom(weekActive.DateStart, 1), "ddd dd MMM")}</h6>
                </div>
                <div className="col">
                    <h6 className="mb-1">{weekActive && dateFormat(DateFrom(weekActive.DateStart, 2), "ddd dd MMM")}</h6>
                </div>
                <div className="col">
                    <h6 className="mb-1">{weekActive && dateFormat(DateFrom(weekActive.DateStart, 3), "ddd dd MMM")}</h6>
                </div>
                <div className="col">
                    <h6 className="mb-1">{weekActive && dateFormat(DateFrom(weekActive.DateStart, 4), "ddd dd MMM")}</h6>
                </div>
                <div className="col">
                    <h6 className="mb-1">{weekActive && dateFormat(DateFrom(weekActive.DateStart, 5), "ddd dd MMM")}</h6>
                </div>
                <div className="col">
                    <h6 className="mb-1">{weekActive && dateFormat(DateFrom(weekActive.DateStart, 6), "ddd dd MMM")}</h6>
                </div>
                <div className="col">
                    <h6 className="mb-6">Total</h6>
                </div>
                <div className="col text-end">
                </div>
            </div>
        </header>

        <section className="body">
            <TimesheetArray timesheets={timesheets} reload={reload} setReload={setReload} />
        </section>

    </div >
}


function TimesheetArray({ timesheets, reload, setReload }: { timesheets: Array<ITimesheet>, reload, setReload }) {

    const token = getToken()
    const location = useLocation()
    const [timesheetArray, setTimesheetArray] = useState<Array<ITimesheetArray>>([])

    useEffect(() => {
        setTimesheetArray(FormatTimesheetArray(timesheets))

        $('[id^=btnActivityTimesheet] i').removeClass("bi-chevron-up").addClass("bi-chevron-down")
        $('[id^=activityTimesheet]').addClass("d-none")
    }, [timesheets])

    function HandleDetails(idUser) {
        var btnDetails = $("#btnDetailsUser" + idUser + " i")
        var details = $("#detailsUser" + idUser)

        if (details.hasClass("d-none")) {
            btnDetails.removeClass("bi-chevron-down").addClass("bi-chevron-up")
            details.removeClass("d-none")
        } else {
            btnDetails.removeClass("bi-chevron-up").addClass("bi-chevron-down")
            details.addClass("d-none")
        }

    }


    function HandleActivity(idActivity) {
        var btnActivity = $("#btnActivityTimesheet" + idActivity + " i")
        var activity = $("#activityTimesheet" + idActivity)

        if (activity.hasClass("d-none")) {
            btnActivity.removeClass("bi-chevron-down").addClass("bi-chevron-up")
            activity.removeClass("d-none")
        } else {
            btnActivity.removeClass("bi-chevron-up").addClass("bi-chevron-down")
            activity.addClass("d-none")
        }

    }

    // Validation -------------------------------- START
    function ValidateWeek(user) {
        let promises: Array<any> = [];

        user.Timesheets.forEach((timesheet, key) => {
            promises.push(reqPut("timesheet/" + timesheet.TimesheetID + "/validate"))
        })

        Promise.all(promises).then((values) => {
            setReload(true)
        })

    }

    function IsValid({ user, day }: { user, day?: number }) {
        var valid = true
        let sheets: Array<any> = []

        if (day || day == 0) {
            sheets = user.Timesheets.filter(t => t?.TimesheetStart ? new Date(t?.TimesheetStart).getDay() == day : null)
        } else {
            sheets = user.Timesheets
        }

        if (sheets.length == 0) {
            return false
        } else {
            sheets.forEach((timesheet) => {
                if (timesheet.TimesheetValidate != 1)
                    valid = false
            })

            return valid
        }
    }
    // Validation -------------------------------- END

    function RemoveTimesheet(timesheet) {
        reqDelete("timesheet/" + timesheet.TimesheetID, { data: { ActivityUserID: token.UserID, ...timesheet } }).then(() => {
            setReload(true)
        })
    }

    function TimesheetDayUpdated(timesheets, day) {
        let filtered = timesheets.filter(t => t?.TimesheetStart ? new Date(t?.TimesheetStart).getDay() == day : null)

        for (let i = 0; i < filtered.length; i++) {

            if (filtered[i].TimesheetDateUpdated) {
                return true
            }
        }

        return false
    }

    const BADGE_VALIDATE = <span className="badge bg-success ms-2 p-0" style={{ width: "1.2rem", height: "1.2rem", fontSize: ".8rem", lineHeight: "1.2rem" }} title="Validé">
        <i className={ICONS.SUCCESS}></i>
    </span>

    const BADGE_MANUALLY = <span className="badge bg-primary-inversed ms-2 p-0" style={{ width: "1.2rem", height: "1.2rem", fontSize: ".8rem", lineHeight: "1.2rem" }} title="Modifié manuellement">
        <i className={"bi bi-hand-index"}></i>
    </span>

    return <div className="list">

        {timesheetArray.length > 0 && timesheetArray.map((user) => {
            return <div key={user.UserID} className="list-item flex-column mb-3 p-0">

                {/* MODAL REMOVE */}
                <div className="modal" id={"validateWeek" + user.UserID} tabIndex={-1} aria-labelledby={"validateWeek" + user.UserID} aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <header className="modal-header">
                                <h1 className="modal-title fs-5" id={"validateWeek" + user.UserID}>Valider la semaine</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </header>

                            <section className="modal-body">
                                <p>Êtes-vous sûr de vouloir valider les feuilles d'heures de la semaine ?</p>
                                <p></p>
                                <b>{user.FirstName} {user.Name}</b>
                            </section>

                            <footer className="modal-footer">
                                <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                                    <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                                </button>

                                <button type="button" className={"btn btn-add"} data-bs-dismiss="modal" onClick={() => ValidateWeek(user)}>
                                    <i className={ICONS.SUCCESS + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Valider</span>
                                </button>
                            </footer>
                        </div>
                    </div>
                </div>


                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-2">
                            <h6 className="text-truncate">{user.FirstName} {user.Name}</h6>
                            <p className="text-muted text-truncate mb-0">{user.Role}</p>
                        </div>

                        {[...Array(7)].map((x, i) => {
                            i = i == 6 ? 0 : i + 1

                            return <div className="col" key={i}>
                                <p className="mb-0">
                                    {TimesheetDayDuration({ timesheets: user.Timesheets.filter(t => t?.TimesheetStart ? new Date(t?.TimesheetStart).getDay() == i : null) })}

                                    {IsValid({ user: user, day: i }) && BADGE_VALIDATE}
                                </p>
                            </div>
                        })}

                        <div className="col">
                            <h6>
                                {TimesheetWeekDuration(user.Timesheets, true)}

                                {IsValid({ user: user }) && BADGE_VALIDATE}

                            </h6>
                        </div>

                        <div className="col-1 text-end" key={reload}>

                            <div className="dropdown">
                                <button className="btn btn-sm btn-outline rounded-2 border-0 my-2" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                                <ul className="dropdown-menu">
                                    {!IsValid({ user: user }) &&
                                        <li>
                                            <button className="dropdown-item mb-2" title="Valider la semaine" data-bs-toggle="modal" data-bs-target={"#validateWeek" + user.UserID}>
                                                <i className={ICONS.SUCCESS + " me-2"}></i> Valider
                                            </button>
                                        </li>
                                    }
                                    <li>
                                        <Link to="add" title="Ajouter" state={{ idUser: user.UserID }} className="dropdown-item"><i className={ICONS.ADD + " me-2"}></i> Ajouter</Link>
                                    </li>
                                </ul>
                            </div>


                            <button className="btn link-nav p-0 mb-3" id={"btnDetailsUser" + user.UserID} onClick={() => HandleDetails(user.UserID)}>Détails <i className="bi bi-chevron-down ms-1"></i></button>

                        </div>
                    </div>
                </div>



                {/* ---------------------- Week details ---------------------- */}
                <div className="container-fluid mx-0 p-3 border-top d-none" id={"detailsUser" + user.UserID}>
                    <div className="list">

                        {user.Timesheets.length > 0 && user.Timesheets.map(t => {
                            return <React.Fragment key={t.TimesheetID}>

                                {/* Modal Remove */}
                                <div className="modal" id={"removeTimesheet" + t.TimesheetID} tabIndex={-1} aria-labelledby={"removeTimesheet" + t.TimesheetID} aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <header className="modal-header">
                                                <h1 className="modal-title fs-5" id={"removeTimesheet" + t.TimesheetID}>Supprimer la feuille d'heure</h1>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </header>

                                            <section className="modal-body">
                                                <p>Êtes-vous sûr de vouloir supprimer cette feuille d'heure ?</p>
                                                <b>{user.FirstName} {user.Name}</b> <br />
                                                <b>{dateFormat(t.TimesheetStart, "dd/MM/yyyy")}</b>
                                            </section>

                                            <footer className="modal-footer">
                                                <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                                                    <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                                                </button>

                                                <button type="button" className={"btn btn-delete"} data-bs-dismiss="modal" onClick={() => RemoveTimesheet(t)}>
                                                    <i className={ICONS.DELETE + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Supprimer</span>
                                                </button>
                                            </footer>
                                        </div>
                                    </div>
                                </div>

                                <section className="list-item align-items-center flex-column mb-3">

                                    <div className="container-fluid d-flex px-0">
                                        <header className="list-item-start me-3">
                                            <div className="item-start">
                                                <div className="bg-background-light p-2 rounded-5 h-100 w-100 d-flex align-items-center justify-content-center">
                                                    {t.TimesheetType == "Timesheet" ?
                                                        <i className={ICONS.TIMESHEET} title="Heure de travail" style={{ fontSize: "1.6rem" }}></i> :
                                                        <i className={ICONS.PICKET} title="Heure de piquet" style={{ fontSize: "1.6rem" }}></i>
                                                    }
                                                </div>
                                            </div>
                                        </header>

                                        <section className="list-item-data">

                                            <div className="row">

                                                <div className="col-6 col-sm-3 col-xl-2 order-1">
                                                    <h6 className="item-title">Date</h6>
                                                    <p className="mb-0">
                                                        {dateFormat(t.TimesheetStart, "dd/MM/yyyy")}
                                                    </p>
                                                </div>

                                                <div className="col-sm-6 col-lg-4 order-3 order-md-2 mt-3 mt-sm-0">
                                                    <h6 className="item-title">Heures</h6>
                                                    <p className="mb-0">
                                                        {dateFormat(t.TimesheetStart, "hh:mm")}

                                                        {(t.TimesheetLunchStart || t.TimesheetLunchEnd) && <span className="ms-3">{dateFormat(t.TimesheetLunchStart, "hh:mm")} - {dateFormat(t.TimesheetLunchEnd, "hh:mm")}</span>}

                                                        {t.TimesheetEnd && <span className="ms-3">{dateFormat(t.TimesheetEnd, "hh:mm")}</span>}

                                                        {t.TimesheetDateUpdated && BADGE_MANUALLY}
                                                    </p>

                                                </div>

                                                <div className="col-6 col-sm col-lg-2 order-2 order-md-3">
                                                    <h6 className="item-title">Total</h6>

                                                    {TimesheetDayDuration({ timesheets: [t] })}
                                                    {t.TimesheetValidate == 1 ? BADGE_VALIDATE : null}
                                                </div>

                                                {t.TimesheetKm &&
                                                    <div className="col-6 col-sm col-lg-2 order-3 order-md-4">
                                                        <h6 className="item-title">Km</h6>
                                                        {t.TimesheetKm}
                                                    </div>}

                                                {t.TimesheetDateUpdated &&
                                                    <div className="col-6 col-sm col-lg-2 order-4 order-md-5">
                                                        <h6 className="item-title">Modification</h6>
                                                        <p className="mb-0">
                                                            {dateFormat(t.TimesheetDateUpdated, "dd/MM/yyyy hh:mm")}
                                                        </p>
                                                    </div>
                                                }

                                            </div>

                                        </section>

                                        <footer className="list-item-more d-block text-end">
                                            {(t.TimesheetValidate == 0 || isAuthorized(GROUP.admin)) &&
                                                <div className="dropdown">
                                                    <button className="btn btn-sm btn-outline border-0 rounded-2" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                                                    <ul className="dropdown-menu">
                                                        <>
                                                            {PrivateComponent(
                                                                <li>
                                                                    <Link to={t.TimesheetID + "/update"} className="dropdown-item mb-2" state={{ path: location.pathname }}>
                                                                        <i className={ICONS.UPDATE + " me-2"}></i> Modifier
                                                                    </Link>
                                                                </li>,
                                                                GROUP.rh)
                                                            }

                                                            {PrivateComponent(
                                                                <li>
                                                                    <button className="dropdown-item btn-delete" title="Supprimer" data-bs-toggle="modal" data-bs-target={"#removeTimesheet" + t.TimesheetID}>
                                                                        <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                                                    </button>
                                                                </li>,
                                                                GROUP.rh)
                                                            }
                                                        </>
                                                    </ul>
                                                </div>
                                            }


                                            <button className="btn link-nav p-0" id={"btnActivityTimesheet" + t.TimesheetID} onClick={() => HandleActivity(t.TimesheetID)}>Activités <i className="bi bi-chevron-down ms-1"></i></button>

                                        </footer>


                                    </div>

                                    <div className="container-fluid px-0 pt-3 d-none" id={"activityTimesheet" + t.TimesheetID}>
                                        <TimesheetActivity timesheet={t} />
                                    </div>

                                </section>
                            </React.Fragment>
                        })}
                    </div>
                </div>
            </div>

        })}

    </div >
}

export function TimesheetDayDuration({ timesheets }) {

    if (timesheets.length > 0) {
        var day: Array<string> = []

        timesheets.forEach(t => {
            day.push(TimeCalculation(t?.TimesheetStart, t?.TimesheetEnd, t?.TimesheetLunchStart, t?.TimesheetLunchEnd, "decimal"))
        })

        var minutes: number = 0
        day.forEach(d => {
            minutes += parseInt(d.split(":")[0]) * 60 + parseInt(d.split(":")[1])
        })

        return (Math.round(minutes / 60 * 4) / 4).toFixed(2)
        //return Math.floor(minutes / 60) + ":" + (minutes % 60 < 9 ? "0" + minutes % 60 : minutes % 60)
    } else return ""
}

export function FormatTimesheetArray(timesheets: Array<ITimesheet>) {


    if (timesheets.length > 0) {
        $('[id^="btnDetailsUser"] i').removeClass("bi-chevron-up").addClass("bi-chevron-down")
        $('[id^="detailsUser"]').addClass("d-none")

        let array: Array<ITimesheetArray> = []

        timesheets.forEach((t) => {

            // If user hasn't been added
            if (!array.find(a => a.UserID == t.UserID)) {

                // Add user
                array.push({
                    UserID: t.UserID,
                    FirstName: t.FirstName ?? "",
                    Name: t.Name ?? "",
                    Role: t.Role ?? "",
                    Timesheets: [{
                        TimesheetID: t.TimesheetID,
                        TimesheetType: t.TimesheetType,
                        TimesheetStart: t.TimesheetStart,
                        TimesheetEnd: t.TimesheetEnd ?? null,
                        TimesheetLunchStart: t.TimesheetLunchStart ?? null,
                        TimesheetLunchEnd: t.TimesheetLunchEnd ?? null,
                        TimesheetDateUpdated: t.TimesheetDateUpdated ?? null,
                        TimesheetNote: t.TimesheetNote ?? null,
                        TimesheetValidate: t.TimesheetValidate,
                        TimesheetKm: t.TimesheetKm
                    }]
                })

            } else {

                array.find(a => a.UserID == t.UserID)?.Timesheets.push({
                    TimesheetID: t.TimesheetID,
                    TimesheetType: t.TimesheetType,
                    TimesheetStart: t.TimesheetStart,
                    TimesheetEnd: t.TimesheetEnd ?? null,
                    TimesheetLunchStart: t.TimesheetLunchStart ?? null,
                    TimesheetLunchEnd: t.TimesheetLunchEnd ?? null,
                    TimesheetDateUpdated: t.TimesheetDateUpdated ?? null,
                    TimesheetNote: t.TimesheetNote ?? null,
                    TimesheetValidate: t.TimesheetValidate,
                    TimesheetKm: t.TimesheetKm
                })
            }
        })

        return array
    } else return []
}

export function TimesheetWeekDuration(timesheets, decimal = false, percent = false) {

    if (timesheets.length > 0) {
        var week: Array<string> = []

        timesheets.forEach(t => {
            week.push(TimeCalculation(t?.TimesheetStart, t?.TimesheetEnd, t?.TimesheetLunchStart, t?.TimesheetLunchEnd, decimal ? "Decimal" : "", percent))
        })

        var minutes: number = 0
        week.forEach(d => {
            if (decimal)
                minutes += parseFloat(d)
            else
                minutes += parseInt(d.split(":")[0]) * 60 + parseInt(d.split(":")[1])
        })

        if (decimal)
            return minutes
        else
            return Math.floor(minutes / 60) + ":" + (minutes % 60 < 9 ? "0" + minutes % 60 : minutes % 60)
    } else return ""
}

export function TimesheetWeekKm(timesheets) {

    if (timesheets.length > 0) {
        var km: number = 0

        timesheets.forEach(t => {
            km += t.TimesheetKm
        })

        return km
    } else return 0
}

function TimesheetActivity({ timesheet }: { timesheet: ITimesheet | any }) {

    const [activity, setActivity] = useState<Array<IActivity>>([])

    useEffect(() => {
        reqGet("activity/timesheet/" + timesheet.TimesheetID).then(res => setActivity(res))
    }, [timesheet])

    return <section className="list-group bg-background-lighter">

        {activity?.length > 0 && activity?.map((a: IActivity) => {
            const data = JSON.parse(a.Data)

            return <div className="list-group-item py-1" key={a.ActivityID}>
                <div className="row">
                    <div className="col-2">
                        <h6 className="item-title mb-1">{a.FirstName} {a.Name}</h6>
                        <p className="mb-0">{dateFormat(a.DateTime, "dd/MM/yyyy hh:mm")}</p>
                    </div>
                    <div className="col-6">
                        <h6 className="item-title mb-1">Remarque</h6>
                        <p className="text-truncate mb-0">{a.Description}</p>
                    </div>
                    <div className="col-4">
                        <h6 className="item-title mb-1">Heures</h6>
                        <p className="mb-0">
                            {dateFormat(data.TimesheetStart, "hh:mm")}

                            {(data.TimesheetLunchStart || data.TimesheetLunchEnd) && <span className="ms-3">{dateFormat(data.TimesheetLunchStart, "hh:mm")} - {dateFormat(data.TimesheetLunchEnd, "hh:mm")}</span>}

                            {data.TimesheetEnd && <span className="ms-3">{dateFormat(data.TimesheetEnd, "hh:mm")}</span>}
                        </p>
                    </div>
                </div>
            </div>
        })
        }

    </section>
}