import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { reqGet, reqPut } from '../../../service/apiRequest'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import $ from 'jquery'

import { getToken } from '../../../useToken'
import { IConstruction, IUser, IZipCode } from "../../common/types"
import { Header, ButtonSubmit } from '../../common/common'

const validationSchema = yup.object({
    ConstructionName: yup.string().required("Ce champ est obligatoire"),
    ConstructionAddress: yup.string().nullable(),
    ConstructionAddressComplementary: yup.string().nullable(),
    ConstructionZipCodeID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ConstructionReference: yup.string().required("Ce champ est obligatoire"),
    ConstructionProjectNumber: yup.string().nullable(),
    ConstructionManagerID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    ConstructionDescription: yup.string().nullable(),
    ConstructionStatus: yup.string().nullable(),
    ConstructionScheduledStart: yup.string().nullable(),
    ConstructionScheduledEnd: yup.string().nullable(),
    ConstructionHoursEstimate: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()

}).required();

const initialValues = {
    ConstructionName: "",
    ConstructionAddress: "",
    ConstructionZipCodeID: null,
    ConstructionReference: "",
    ConstructionProjectNumber: null,
    ConstructionManagerID: null,
    ConstructionDescription: null,
    ConstructionStatus: null,
    ConstructionScheduledStart: null,
    ConstructionScheduledEnd: null,
    ConstructionHoursEstimate: null
}

export default function ConstructionUpdate() {
    const params = useParams()
    const idConstruction = params.idConstruction
    const token = getToken()
    const navigate = useNavigate()
    const location = useLocation()

    const [construction, setConstruction] = useState<IConstruction>()
    const [zipCodes, setZipCodes] = useState<Array<IZipCode>>([])
    const [users, setUsers] = useState<Array<IUser>>([])

    const { register, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm<IConstruction>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        let promise1 = reqGet("zipcode").then(res => setZipCodes(res))
        let promise2 = reqGet("user").then(res => setUsers(res))

        Promise.all([promise1, promise2]).then(res => {
            reqGet("construction/" + idConstruction).then(r => {
                setConstruction(r)

                setValue("ConstructionID", r.ConstructionID)
                setValue("ConstructionName", r.ConstructionName)
                setValue("ConstructionAddress", r.ConstructionAddress)
                setValue("ConstructionZipCodeID", r.ConstructionZipCodeID)
                setValue("ConstructionReference", r.ConstructionReference)
                setValue("ConstructionManagerID", r.ConstructionManagerID)
                setValue("ConstructionProjectNumber", r.ConstructionProjectNumber)
                setValue("ConstructionScheduledStart", r.ConstructionScheduledStart)
                setValue("ConstructionScheduledEnd", r.ConstructionScheduledEnd)
                setValue("ConstructionDescription", r.ConstructionDescription)
                setValue("ConstructionHoursEstimate", r.ConstructionHoursEstimate)
            })
        })
    }, [])


    const LinkBack = () => {
        if (location?.state?.path)
            return location.state.path
        else
            return '/construction/' + idConstruction
    }


    const onSubmit: SubmitHandler<IConstruction> = data => {
        reqPut('construction/' + idConstruction, { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate(LinkBack())
        })
    };



    return <div className="container-fluid h-100 content">
        <div className="row justify-content-center h-100">
            <div className="col-md-10 col-xxl-6 col-h-fill">

                {Header(
                    <h2 className="section-title">Modifier le chantier</h2>,
                    <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
                )}

                <section className="body">
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">
                            <label className="form-label" htmlFor="ConstructionName">Nom du chantier *</label>
                            <input type="text" className={"form-control " + (errors?.ConstructionName && "is-invalid")} {...register("ConstructionName")} />

                            {errors?.ConstructionName?.message && <p className="input-msg-error">{errors?.ConstructionName.message}</p>}
                        </div>

                        <div className="row mb-3">
                            <div className="col-6 col-md-8">
                                <label className="form-label" htmlFor='ConstructionAddress'>Adresse</label>
                                <input type="text" className="form-control" {...register("ConstructionAddress")} />
                            </div>

                            <div className="col-6 col-md-4">
                                <label className="form-label" htmlFor='ConstructionZipCodeID'>Code postale</label>
                                <select id="" className="form-select" {...register("ConstructionZipCodeID")} >
                                    <option value=""></option>
                                    {zipCodes.map((z) => {
                                        return <option key={z.ZipCodeID} value={z.ZipCodeID}>{z.ZipCode} {z.City}</option>
                                    })}
                                </select>
                            </div>
                        </div>


                        <div className="mb-3">
                            <label className="form-label" htmlFor="ConstructionAddressComplementary">Complément d'adresse</label>
                            <input type="text" className={"form-control " + (errors?.ConstructionAddressComplementary && "is-invalid")} {...register("ConstructionAddressComplementary")} />

                            {errors?.ConstructionAddressComplementary?.message && <p className="input-msg-error">{errors?.ConstructionAddressComplementary.message}</p>}
                        </div>

                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label" htmlFor='ConstructionManagerID'>Responsable *</label>
                                <select id="ConstructionManagerID" className="form-select" {...register("ConstructionManagerID")} >
                                    {users.filter(u => u.Role == "Admin" || u.Role == "Responsable chantier").map((u) => {
                                        return <option key={u.UserID} value={u.UserID}>{u.FirstName} {u.Name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor='ConstructionReference'>Référence *</label>
                                <input type="text" className="form-control" {...register("ConstructionReference")} />
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor='ConstructionProjectNumber'>Numéro Abacus</label>
                                <input type="text" className="form-control" {...register("ConstructionProjectNumber")} />
                            </div>
                        </div>

                        <div className="row row-cols-3 mb-3">
                            <div className="col">
                                <label htmlFor="ConstructionScheduledStart" className="form-label">Début de chantier prévu</label>
                                <input type="date" className="form-control" {...register("ConstructionScheduledStart")} />
                            </div>
                            <div className="col">
                                <label htmlFor="ConstructionScheduledEnd" className="form-label">Fin de chantier prévu</label>
                                <input type="date" className="form-control" {...register("ConstructionScheduledEnd")} />
                            </div>
                            <div className="col">
                                <label htmlFor="ConstructionHoursEstimate" className="form-label">Nombre d'heures éstimée</label>
                                <input type="number" className="form-control" {...register("ConstructionHoursEstimate")} />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ConstructionDescription" className="form-label">Description</label>
                            <textarea id="ConstructionDescription" {...register("ConstructionDescription")} rows={4}></textarea>
                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>
                </section>

            </div>
        </div>
    </div>
}