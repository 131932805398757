import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { reqGet } from '../../../service/apiRequest'
import $ from 'jquery'

import { getToken } from '../../../useToken'
import { IAddress, IInstallation, ICounter, IAction, IBenefit, IVisualControl, IObservation, IBenefitDetail, IMaterialClass, ICounterStatement } from '../../common/types'
import { InstallCard } from '../address/address-installation/address-installation'
import { GROUP } from '../../common/constants'
import { AddressInstallUpdate } from '../address/address-installation/address-install-update'

interface IFormObservation extends IObservation {
    MaterialClassID: Array<number> | null,
    ActionID: Array<number> | null,
    BenefitDetailID: Array<number> | null,
    VisualControlID: Array<number> | null,

    InstallationUpdates: Array<IInstallation> | null,
    CounterStatements: Array<ICounterStatement> | null
}


export default function Observation(idAddress?, from?) {
    const navigate = useNavigate()

    const token = getToken()
    const [observation, setObservation] = useState<IFormObservation>()

    idAddress = 6018
    from = "address/" + idAddress

    const [address, setAddress] = useState<IAddress>()

    useEffect(() => {
        if (idAddress) {
            reqGet("address/" + idAddress).then(res => {
                setAddress(res)
            })
        } else {
            navigate("/")
        }
    }, [])

    const TOTAL_STEP = 7
    const stepName = ["Prestation", "Matériels", "Contrôles visuels", "Actions", "Mise a jour des installations", "Relevé des compteurs", "Remarques"]
    const [currentStep, setCurrentStep] = useState<number>(1)
    const [stepValid, setStepValid] = useState<boolean>(false)
    const [stepVue, setStepVue] = useState<JSX.Element>()

    useEffect(() => {
        switch (currentStep) {
            case 1:
                setStepVue(<Benefit observation={observation} setObservation={setObservation} setStepValid={setStepValid} />)
                break;
            case 2:
                setStepVue(<Material setObservation={setObservation} setStepValid={setStepValid} />)
                break;
            case 3:
                setStepVue(<VisualControl setObservation={setObservation} setStepValid={setStepValid} />)
                break;
            case 4:
                setStepVue(<Action setObservation={setObservation} setStepValid={setStepValid} />)
                break;
            case 5:
                setStepVue(<Installation idAddress={idAddress} setObservation={setObservation} setStepValid={setStepValid} />)
                break;
            case 6:
                setStepVue(<Counters idAddress={idAddress} setObservation={setObservation} setStepValid={setStepValid} />)
                break;
            case 7:
                setStepVue(<Note setObservation={setObservation} />)
                break;

            default:
                setStepVue(<></>)
                break;
        }
    }, [currentStep])

    function handlePrevious() {
        var previous = (currentStep == 6 && !GROUP.technicien_strict.includes(token.Role)) ? 2 : 1

        if (currentStep != 1) {
            setCurrentStep(currentStep - previous)
        }
    }

    function handleNext() {
        var next = (currentStep == 4 && !GROUP.technicien_strict.includes(token.Role)) ? 2 : 1

        if (stepValid && currentStep < TOTAL_STEP) {
            setCurrentStep(currentStep + next)
            setStepValid(false)
        }
    }

    function handleFinish() {
        if (stepValid && currentStep == TOTAL_STEP) {

        }
    }


    return <section className="body-container h-100 overflow-auto">

        <section className="row w-100 mx-0 h-100">

            <aside className="col ps-0 d-none d-lg-flex">

                <div className="body-container container-fluid">

                    <section className="row">

                        <div className="col-6 col-lg-3 order-1">
                            <Link to={from} className="link-nav ps-0">Annuler</Link>
                        </div>

                        <div className="col-12 order-3 text-truncate">
                            <h5>{address?.AddressLine}</h5>

                        </div>

                        <div className="col-6 col-lg-3 order-2 order-lg-3 text-end">
                        </div>

                    </section>

                    <section className="body p-0">
                        {summary(observation)}
                    </section>

                </div>

            </aside>



            <section className="col-lg-10 h-100 px-0 ps-lg-3">
                <section className="body-container px-0">

                    <section className="header px-0">
                        <div className="card w-100 h-100 m-0 py-2">

                            <div className="card-body">

                                <div className="row m-0 h-100">

                                    <div className="col-4 col-lg-2 order-2 order-lg-1 h-100 p-0 d-flex flex-column justify-content-center">
                                        {currentStep > 1 && <button className="btn btn-outline-primary" onClick={() => handlePrevious()}>
                                            {'<'} Précédent
                                        </button>}
                                    </div>

                                    <div className="col-12 col-lg-8 order-1 order-lg-2">
                                        <h3 className="section-title mb-0 text-center">{stepName[currentStep - 1]}</h3>

                                        <div className="text-center">
                                            <small>
                                                {[...Array(currentStep)].map((i, k) => {
                                                    return <i key={k} className="bi bi-circle-fill color-contrast mx-2"></i>
                                                })}


                                                {[...Array(TOTAL_STEP - currentStep)].map((i, k) => {
                                                    return <i key={k} className="bi bi-circle color-contrast mx-2"></i>
                                                })}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="col-4 offset-4 offset-lg-0 col-lg-2 order-3 h-100 p-0 d-flex flex-column justify-content-center">
                                        {currentStep < TOTAL_STEP && <button className="btn btn-outline-primary" disabled={!stepValid} onClick={() => handleNext()}>
                                            Suivant {'>'}
                                        </button>}
                                        {currentStep == TOTAL_STEP && <button className="btn btn-outline-primary" disabled={!stepValid} onClick={() => handleFinish()}>
                                            Terminé
                                        </button>}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </section>

                    <section className="body p-0">
                        {stepVue}

                    </section>

                </section >
            </section>

        </section>

    </section>

}

function summary(observation) {
    return <div className="card card-100">

        <div className="card-header d-block">
            <h5 className="card-title">Résumé</h5>
        </div>

        <div className="card-body">

            <h5 className="text-light">Prestation</h5>

            {observation?.BenefitID && <p>{observation?.BenefitID}</p>}



            <h5 className="text-light mt-3">Prestation: détails</h5>

            <div className="d-inline-block">
                {observation?.BenefitDetailID && observation.BenefitDetailID.map((bd, k) => {
                    return <p className="d-inline" key={k}> {bd}, </p>

                })}
            </div>



            <h5 className="text-light mt-3">Matériels</h5>

            <div className="d-inline-block">
                {observation?.MaterialClassID && observation.MaterialClassID.map((mc, k) => {
                    return <p className="d-inline" key={k}> {mc}, </p>

                })}
            </div>


            <h5 className="text-light mt-3">Contrôles visuels</h5>

            <div className="d-inline-block">
                {observation?.VisualControlID && observation.VisualControlID.map((vc, k) => {
                    return <p className="d-inline" key={k}> {vc}, </p>

                })}
            </div>


            <h5 className="text-light mt-3">Actions</h5>

            <div className="d-inline-block">
                {observation?.ActionID && observation.ActionID.map((a, k) => {
                    return <p className="d-inline" key={k}> {a}, </p>

                })}
            </div>


            <h5 className="text-light mt-3">Installations</h5>

            <div className="d-inline-block">
                { }
            </div>

            <h5 className="text-light mt-3">Compteurs</h5>

            <div className="d-inline-block">
                {observation?.CounterStatements && observation.CounterStatements.map((cs, k) => {
                    return <p className="d-inline" key={k}> {cs.Value}, </p>
                })}
            </div>

            <h5 className="text-light mt-3">Remarques</h5>

            <div className="d-inline-block">
                {observation?.Note}
            </div>

        </div>

    </div>
}

function Benefit({ observation, setObservation, setStepValid }) {
    const [benefits, setBenefits] = useState<Array<IBenefit>>([])
    const [benefitDetails, setBenefitDetails] = useState<Array<IBenefitDetail>>([])
    const [benefit, setBenefit] = useState<number>()

    useEffect(() => {
        reqGet('benefit').then((res) => {
            setBenefits(res)

            if (observation?.Benefit) {
                observation.Benefit.map()
            }
        })
        reqGet('benefitdetail').then((res) => {
            setBenefitDetails(res)
        })
    }, [])

    function handleBenefit(e) {
        setBenefit(e.target.value)
        setObservation(data => ({ ...data, BenefitID: e.target.value }))

        if (e.target.value > 0) {
            setStepValid(true)
        } else {
            setStepValid(false)
        }
    }

    function handleBenefitDetail() {
        var array: number[] = [];

        $("input:checkbox[name='checkBenefitDetail']:checked").each(function () {
            array.push(parseInt($(this).val()));
        });

        setObservation(data => ({ ...data, BenefitDetailID: array }))
    }

    return <section className="row h-100">

        <div className="col-md-4 col-xl-3 h-100">

            <div className="card card-100">

                <div className="card-header">
                    <h5 className="card-title">Prestation</h5>
                </div>

                <div className="card-body">

                    <fieldset id="benefit">

                        {benefits.map((b, k) => {

                            return <div className="form-check" key={k}>

                                <input className="form-check-input" type="radio" name="radioBenefit" value={b.BenefitID} onChange={(e) => handleBenefit(e)} />
                                <label className="form-check-label" htmlFor="radioBenefit"> {b.Benefit} </label>

                            </div>
                        })}
                    </fieldset>

                </div>

            </div>

        </div>

        {benefit && <div className="col-md-4 col-xl-3 h-100">

            <div className="card card-100">

                <div className="card-header">
                    <h5 className="card-title">Complément</h5>
                </div>

                <div className="card-body">

                    <fieldset id="benefit">

                        {benefitDetails.map((bd, k) => {
                            return <div className="form-check" key={k}>
                                <input className="form-check-input" type="checkbox" name="checkBenefitDetail" value={bd.BenefitDetailID} onChange={() => { handleBenefitDetail() }} />
                                <label className="form-check-label" htmlFor="checkBenefitDetail">{bd.BenefitDetail}</label>
                            </div>
                        })}

                    </fieldset>

                </div>

            </div>

        </div>}

    </section>
}

function Material({ setObservation, setStepValid }) {
    const [materialClass, setMaterialClass] = useState<Array<IMaterialClass>>([])

    useEffect(() => {
        reqGet("materialclass").then(res => {
            setMaterialClass(res)
        })
    }, [])

    function handleMaterialClass() {
        var array: number[] = [];

        $("input:checkbox[name='checkMaterialClass']:checked").each(function () {
            array.push(parseInt($(this).val()));
        });

        if (array.length > 0) {
            setStepValid(true)
        } else {
            setStepValid(false)
        }

        setObservation(data => ({ ...data, MaterialClassID: array }))
    }


    return <section className="row h-100">

        <div className="col-md-4 col-xl-3 h-100">

            <div className="card card-100">

                <div className="card-header">
                    <h5 className="card-title">Matériels</h5>
                </div>

                <div className="card-body">

                    <fieldset id="materialClass">

                        {materialClass.map((mc, k) => {

                            return <div className="form-check" key={k}>

                                <input className="form-check-input" type="checkbox" name="checkMaterialClass" value={mc.MaterialClassID} onChange={() => handleMaterialClass()} />
                                <label className="form-check-label" htmlFor="checkMaterialClass"> {mc.Class} </label>

                            </div>
                        })}
                    </fieldset>

                </div>

            </div>

        </div>
    </section>
}

function VisualControl({ setObservation, setStepValid }) {
    const [visualControl, setVisualControl] = useState<Array<IVisualControl>>([])

    useEffect(() => {
        reqGet('visualcontrol').then(function (res) {
            setVisualControl(res)
        })
    }, [])

    function handleVisualControl() {
        var array: number[] = [];

        $("input:checkbox[name='checkVisualControl']:checked").each(function () {
            array.push(parseInt($(this).val()));
        });

        if (array.length > 0) {
            setStepValid(true)
        } else {
            setStepValid(false)
        }

        setObservation(data => ({ ...data, VisualControlID: array }))
    }

    return <section className="row h-100">

        <div className="col-md-4 col-xl-3 h-100">

            <div className="card card-100">
                <div className="card-header">
                    <h5 className="card-title">Contrôles visuels</h5>
                </div>

                <div className="card-body">


                    <fieldset id="visualControl">

                        {visualControl.map((vc, k) => {

                            return <div className="form-check" key={k}>

                                <input className="form-check-input" type="checkbox" name="checkVisualControl" value={vc.VisualControlID} onChange={() => handleVisualControl()} />
                                <label className="form-check-label" htmlFor="checkMaterialClass"> {vc.VisualControl} </label>

                            </div>
                        })}
                    </fieldset>

                </div>

            </div>

        </div>

    </section>
}

function Action({ setObservation, setStepValid }) {
    const [action, setAction] = useState<Array<IAction>>([])

    useEffect(() => {
        reqGet('action').then(function (res) {
            setAction(res)
        })
    }, [])

    function handleAction() {
        var array: number[] = [];

        $("input:checkbox[name='checkAction']:checked").each(function () {
            array.push(parseInt($(this).val()));
        });

        if (array.length > 0) {
            setStepValid(true)
        } else {
            setStepValid(false)
        }

        setObservation(data => ({ ...data, ActionID: array }))
    }

    return <section className="row h-100">

        <div className="col-md-4 col-xl-3 h-100">
            <div className="card card-100">
                <div className="card-header">
                    <h5 className="card-title">Actions</h5>
                </div>

                <div className="card-body">

                    <fieldset id="action">
                        {action.map((a, k) => {

                            return <div className="form-check" key={k}>

                                <input className="form-check-input" type="checkbox" name="checkAction" value={a.ActionID} onChange={() => handleAction()} />
                                <label className="form-check-label" htmlFor="checkMaterialClass"> {a.Action} </label>

                            </div>
                        })}
                    </fieldset>

                </div>
            </div>
        </div>
    </section>
}

function Installation({ idAddress, setObservation, setStepValid }) {
    const [materialClass, setMaterialClass] = useState<Array<IMaterialClass>>([])
    const [installation, setInstallation] = useState<Array<IInstallation>>([])
    const [idInstall, setIdInstall] = useState<number>()
    const [update, setUpdate] = useState<boolean>(false)

    useEffect(() => {
        setStepValid(true)

        reqGet("materialclass").then(function (res) {
            setMaterialClass(res)
        })

        reqGet("installation/address/" + idAddress).then((res) => {
            setInstallation(res)
        })
    }, [update])

    function handleUpdate(id) {
        setUpdate(true)
        setIdInstall(id)
    }

    if (!update) {
        return <div>
            {materialClass.map((mc: IMaterialClass, k) => {
                let arrayInstalls = installation?.filter((i: IInstallation) => {
                    return i.MaterialClassID == mc.MaterialClassID || i.IMaterialClassID == mc.MaterialClassID
                })

                if (arrayInstalls.length > 0) {

                    return <React.Fragment key={mc.InstallationID}>
                        <h2 className={"section-title-2 " + (k != 0 ? "mt-5" : "")}>{mc.Class}s <span className="badge rounded-pill badge-primary">{arrayInstalls.length}</span></h2>

                        {arrayInstalls.map((i, k) => {
                            return <React.Fragment key={k}>
                                {InstallCard(i,
                                    <button className="btn btn-tools" onClick={() => handleUpdate(i.InstallationID)}>
                                        Modifier
                                        <i className="bi bi-pencil-square ms-1"></i>
                                    </button>
                                )}
                            </React.Fragment>
                        })}

                    </React.Fragment>


                }
            })}
        </div>
    } else {
        return <div>

            <div className="mb-3">
                <button className="btn btn-tools ps-0" onClick={() => setUpdate(false)}>{"< Retour"}</button>
            </div>

            <AddressInstallUpdate idInstall={idInstall} setUpdated={(u: boolean) => setUpdate(!u)} />
        </div>
    }
}

function Counters({ idAddress, setObservation, setStepValid }) {
    const [counters, setCounters] = useState<Array<ICounter>>([])
    const [statements, setStatements] = useState<Array<{ CounterID, Value }>>([])

    useEffect(() => {
        setStepValid(true)
        reqGet("counter/address/" + idAddress).then((res) => {
            setCounters(res)
        })
    }, [])

    function handleStatement(id, value) {
        var array = statements
        const counter = array.find((c) => c.CounterID == id);
        const counterIndex = array.findIndex((c => c.CounterID == id));

        if (value && !counter) {
            array.push({ CounterID: id, Value: value })

        } else if (!value && counter) {

            array.splice(counterIndex, 1);

        } else if (value && counter) {

            array[counterIndex].Value = value;
        }


        setStatements(array)
        setObservation(data => ({ ...data, CounterStatements: array }))
    }

    return <>
        <div className="row mx-0">
            <div className="col">
                <p className="fw-bold">Compteur</p>
            </div>

            <div className="col-2 text-end">
                <p className="fw-bold">Relevé</p>
            </div>
        </div>



        {counters.map((counter, index) => {
            return <div className="card" key={index}>

                <div className="card-body">
                    <div className="row mx-0">

                        <div className="col">
                            <div className="row">

                                <div className="col">
                                    <h6 className="text-light">Type</h6>
                                    <p className="mb-0">{counter?.CounterType}</p>
                                </div>

                                <div className="col text-nowrap">
                                    <h6 className="text-light">Libellé</h6>
                                    <p className="mb-0">{counter?.Label}</p>
                                </div>

                                <div className="col">
                                    <h6 className="text-light">Capacité</h6>
                                    <p className="mb-0">{counter?.Capacity}</p>
                                </div>

                                <div className="col">
                                    <h6 className="text-light">Vol. min.</h6>
                                    <p className="mb-0">{counter?.VolumeMin}</p>
                                </div>

                                <div className="col">
                                    <h6 className="text-light">Tolérance</h6>
                                    <p className="mb-0">{counter?.Tolerance}</p>
                                </div>

                                <div className="col">
                                    <h6 className="text-light">Unité</h6>
                                    <p className="mb-0">{counter?.Unit}</p>
                                </div>

                            </div>
                        </div>

                        <div className="col-2 d-flex flex-column justify-content-center text-end">
                            <input className="form-control" type="number"
                                onChange={(e) => handleStatement(counter.CounterID, parseInt(e.target.value))} />
                        </div>

                    </div>
                </div>
            </div>
        })}
    </>
}

function Note({ setObservation }) {

    function handleNote(e) {

        setObservation(data => ({ ...data, Note: e.target.value }))
    }

    return <>
        <div className="card card-100">
            <div className="card-header">
                <h5 className="card-title">Remarques</h5>
            </div>

            <div className="card-body">

                <textarea id="note" rows={15} placeholder="Ecrivez une remarque" onChange={(e) => handleNote(e)}></textarea>

            </div>
        </div>
    </>
}