import React, { useEffect, useState } from "react";
import { Header, PrivateComponent } from "../../common/common";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";

import HelpController from "./help-controller"
import { GROUP } from "../../common/constants";

export default function Help() {
    const location = useLocation()

    const [mobileSubNav, setMobileSubNav] = useState<boolean>(false)

    useEffect(() => {
        setMobileSubNav(false)
    }, [location])

    const ToggleSubNav = () => {
        setMobileSubNav(!mobileSubNav)
    }

    return <section className="body-container">

        <header className="mb-3 border-bottom p-0 d-md-none">
            <div className="d-flex align-items-center p-2">
                <button className="d-flex flex-shrink-1 btn btn-outline rounded-0 me-3" onClick={() => ToggleSubNav()}><i className={"bi bi-layout-text-sidebar"}></i></button>

                <Link to="/help"><h5 className="mb-0">Aide</h5></Link>
            </div>
        </header>

        {mobileSubNav &&
            <nav className="bg-background-lighter p-3 border-bottom d-md-none" style={{ position: "absolute", height: "75%", width: "100%", zIndex: 99, top: "90px" }}>
                <div className="w-75 h-100 overflow-y-auto">
                    <HelpNav />
                </div>
            </nav>
        }

        <section className="body container-fluid">

            <div className="row h-100">

                <div className="col-md-3 col-xxl-2 d-none d-md-flex col-h-fill py-3">

                    <Link to="/help"><h2 className="title">Aide</h2></Link>

                    <HelpNav />

                </div>

                <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-0 col-xl-7 offset-xl-1 col-h-fill content">
                    <HelpController />
                </div>

            </div>

        </section>

    </section >
}

function HelpNav() {
    return <nav className="nav nav-help">

        <div className="nav-body">

            <section className="nav-group">
                <h4 className="nav-group-title">Documents</h4>

                <ul className="list-unstyled">
                    <NavLink to="documents/convention" className="nav-link"><li>Convention</li></NavLink>
                </ul>
            </section>

            <section className="nav-group">
                <h4 className="nav-group-title">Prestations</h4>

                <ul className="list-unstyled">
                    {PrivateComponent(<NavLink to="services" className="nav-link"><li>Prestations</li></NavLink>, GROUP.crud_sav)}
                    {PrivateComponent(<NavLink to="service/survey" className="nav-link"><li>Surveillance</li></NavLink>, GROUP.crud_sav)}
                    {PrivateComponent(<NavLink to="service/heating" className="nav-link"><li>Arrêt/mise en route chauffage</li></NavLink>, GROUP.crud_sav)}
                </ul>
            </section>

            <section className="nav-group">
                <h4 className="nav-group-title">Stock</h4>

                <ul className="list-unstyled">
                    {PrivateComponent(<NavLink to="storage/warehouse" className="nav-link"><li>Stockage</li></NavLink>, GROUP.dev)}
                    <NavLink to="storage/product-takeout" className="nav-link"><li>Retirer pièce</li></NavLink>
                    {PrivateComponent(<NavLink to="storage/product-putback" className="nav-link"><li>Déposer objet</li></NavLink>, GROUP.dev)}
                </ul>
            </section>


            {PrivateComponent(
                <section className="nav-group">
                    <h4 className="nav-group-title">Véhicules</h4>

                    <ul className="list-unstyled">
                        {PrivateComponent(<NavLink to="vehicle/check" className="nav-link"><li>Contrôle</li></NavLink>)}
                    </ul>

                </section>
                , GROUP.dev)}
        </div>
    </nav>
}