import axios from "axios";
import { REQUEST_SERVER } from '../components/common/constants'


const options = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS"

  }
}


export function post(req, args) {
  return new Promise(function (resolve, reject) {

    axios.post(REQUEST_SERVER + req, args, options).then((res) => {
      if (res.status === 200 || res.status === 201) {

        resolve(res.data)

      } else {
        console.log('Failed');
      }

    })
      .catch((err) => {

        reject(err)
      });
  })
}