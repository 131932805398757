import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { reqGet, reqPost, reqPut } from '../../../../service/apiRequest'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { IHeating, IManagement, IOwner } from "../../../common/types"
import { ButtonSubmit, Header } from '../../../common/common'
import { ICONS } from "../../../common/constants";

export default function ClientHeatingInsert() {
    const location = useLocation()
    const params = useParams()
    const idManagement = params.idManagement
    const idOwner = params.idOwner
    const navigate = useNavigate()
    const token = getToken()

    const content = useOutletContext<IManagement | IOwner>()

    const validationSchema = yup.object({
        HeatingDate: yup.string().required("Ce champ est obligatoire"),
        HeatingAction: yup.string().required("Ce champ est obligatoire"),
    }).required();

    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IHeating>({
        mode: "onTouched",
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        if (!idManagement && !idOwner)
            navigate(-1)

    }, [])

    const onSubmit: SubmitHandler<IHeating> = data => {
        if (idManagement)
            reqPost('heating/management/' + idManagement, { ...data, ...content, ManagementID: idManagement, ActivityUserID: token.UserID }).then((res) => {
                navigate('/client/management/' + idManagement + "/heating")
            })
        else if (idOwner)
            reqPost('heating/owner/' + idOwner, { ...data, ...content, OwnerID: idOwner, ActivityUserID: token.UserID }).then((res) => {
                navigate('/client/owner/' + idOwner + "/heating")
            })
    };

    const LinkBack = () => {
        if (idManagement)
            return <Link to={"/client/management/" + idManagement + "/heating"} className="link-nav">{"<"} Retour</Link>
        else if (idOwner)
            return <Link to={"/client/owner/" + idOwner + "/heating"}>{"<"} Retour</Link>
        else
            return <></>
    }

    return <section className="body-container">

        {Header(
            <h2 className="section-title">Arrêt/MER chauffage {(idManagement && "de la régie") || (idOwner && "du propriétaire")}</h2>,
            LinkBack()
        )}

        <section className="body">
            <form onSubmit={handleSubmit(onSubmit)}>

                <section className="row row-cols-2 mb-3">
                    <div className="col">
                        <label className="form-label">Date *</label>

                        <input type="date" className={"form-control " + (errors?.HeatingDate ? "is-invalid" : "")} {...register("HeatingDate")} />

                        {errors.HeatingDate?.message && <p className="input-msg-error">{errors.HeatingDate?.message}</p>}
                    </div>

                    <div className="col">
                        <label className="form-label">Action *</label>

                        <select className={"form-select " + (errors?.HeatingAction ? "is-invalid" : "")} {...register("HeatingAction")} >
                            <option value="Start">Mise en route</option>
                            <option value="Stop">Arrêt</option>
                        </select>

                        {errors.HeatingAction?.message && <p className="input-msg-error">{errors.HeatingAction?.message}</p>}
                    </div>
                </section>

                <p><i className={ICONS.WARNING}></i> La date sera ajouté uniquement aux adresses possédant la prestation d'arrêt et de mise en route du chauffage !</p>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <ButtonSubmit />
                </div>

            </form>

        </section>
    </section>
}