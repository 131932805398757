import React, { useState, useEffect } from 'react'
import { Routes, Route, Link, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { HeaderRow } from '../../../common/common'
import { reqGet, reqPut } from '../../../../service/apiRequest'
import { IZipCode } from '../../../common/types'

const validationSchema = yup.object({
    ZipCodeID: yup.number().required("Ce champ est obligatoire"),
    ZipCode: yup.string().required("Ce champ est obligatoire"),
    City: yup.string().required("Ce champ est obligatoire"),
})

const initialValues = {
    ZipCode: "",
    City: ""
}

export default function ZipcodeUpdate() {
    const token = getToken()
    let navigate = useNavigate()
    const params = useParams()
    let idZipCode = params.idZipCode

    const { register, formState: { errors }, handleSubmit, getValues, setValue } = useForm<IZipCode>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {

        setValue("ZipCodeID", idZipCode ? parseInt(idZipCode) : 0)

        reqGet("zipcode/" + idZipCode).then(res => {
            setValue("ZipCode", res.ZipCode)
            setValue("City", res.City)
        })

    }, [])

    const onSubmit: SubmitHandler<IZipCode> = data => {
        reqPut('zipcode/' + idZipCode, { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate(-1)
        })

    };

    return <div className="body-container h-100">

        {HeaderRow(
            <h3 className="section-title mb-1">Modifier NPA</h3>,
            <button onClick={() => navigate(-1)} className="btn link-nav">{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="ZipCode" className="form-label">Code postal *</label>
                            <input className={"form-control " + (errors?.ZipCode ? "is-invalid" : "")} {...register("ZipCode")} />

                            {errors.ZipCode?.message && <p className="mt-2 text-danger">{errors.ZipCode?.message}</p>}

                        </div>
                        <div className="mb-3">

                            <label htmlFor="City" className="form-label">Ville *</label>
                            <input className={"form-control " + (errors?.City ? "is-invalid" : "")} {...register("City")} />

                            {errors.City?.message && <p className="mt-2 text-danger">{errors.City?.message}</p>}

                        </div>
                        
                        <p><small>Les champs avec une * sont obligatoire</small></p>

                        <input type="submit" className="btn btn-primary" value="Modifier" />

                    </form>

                </div>

                <div id="alert" className="d-none alert alert-danger align-self-end position-absolute w-75" role="alert">
                    A simple danger alert—check it out!
                </div>
            </div >


        </div>
    </div>
}