import React, { useEffect, useState } from "react";
import { IHeating, IManagement, IOwner } from "../../../common/types";
import $ from 'jquery'
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { reqDelete, reqGet } from "../../../../service/apiRequest";
import { Header, PrivateComponent, PrivateLinkAdd, dateFormat } from "../../../common/common";
import { GROUP, ICONS } from "../../../common/constants";
import { getToken } from "../../../../useToken";

export default function ClientHeating() {
    const navigate = useNavigate()
    const params = useParams()
    const token = getToken()
    const idManagement = params.idManagement
    const idOwner = params.idOwner

    const content = useOutletContext<IManagement | IOwner>()

    const LinkBack = () => {
        if (idManagement)
            return "/client/management/" + idManagement
        else if (idOwner)
            return "/client/owner/" + idOwner
        else
            return "/client"
    }

    const [reload, setReload] = useState<Boolean>(false)
    const [heatings, setHeatings] = useState<Array<IHeating>>([])
    const [heatingSelected, setHeatingSelected] = useState<IHeating>()

    useEffect(() => {
        if (idManagement)
            reqGet("heating/management/" + idManagement).then(res => setHeatings(res))

        else if (idOwner)
            reqGet("heating/owner/" + idOwner).then(res => setHeatings(res))

        else
            navigate("/client")
    }, [idManagement, idOwner])

    useEffect(() => {
        if (reload) {
            if (idManagement)
                reqGet("heating/management/" + idManagement).then(res => setHeatings(res))

            else if (idOwner)
                reqGet("heating/owner/" + idOwner).then(res => setHeatings(res))

            else
                navigate("/client")
        }
    }, [reload])

    function HandleSelection(heating, e) {
        $('#listHeating .list-item.active').removeClass('active')
        e.currentTarget.classList.add('active')

        setHeatingSelected(heating)
    }

    function RemoveHeating() {

        if (heatingSelected?.HeatingID) {
            if (idManagement)
                reqDelete("heating/management/" + idManagement + "/heatingdate/" + heatingSelected?.HeatingDate, { data: { ActivityUserID: token.UserID, ...content, HeatingDate: heatingSelected?.HeatingDate } }).then(res => {
                    setReload(true)
                })
            if (idOwner)
                reqDelete("heating/owner/" + idOwner + "/heatingdate/" + heatingSelected?.HeatingDate, { data: { ActivityUserID: token.UserID, ...content, HeatingDate: heatingSelected?.HeatingDate } }).then(res => {
                    setReload(true)
                })
        }
    }

    return <section className="body-container">


        <div className="modal fade" id="modalRemoveHeating" tabIndex={-1} aria-labelledby="modalRemoveHeating" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="modalRemoveHeating">Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes-vous sur de vouloir supprimer cet arrêt/MER de chauffage ?</p>

                        <i>Les adresses sur lesquelles l'arrèt/MER de chauffage a été effectué ne seront pas supprimé. </i>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => RemoveHeating()}>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>

        <header className="header">
            <h2 className="section-title-2">Arrêt/MER chauffage</h2>

            {PrivateLinkAdd("add", GROUP.crud_sav, ["HeatingCreate"])}

        </header>

        <section className="body">
            <div className="row">
                <div className="col">
                    <h6>Action</h6>
                </div>
                <div className="col">
                    <h6>Date</h6>
                </div>
                <div className="col-3">
                    <h6>Nb adresses</h6>
                </div>
            </div>

            <section className="list" id="listHeating">

                {heatings.length > 0 && heatings?.map(h => {
                    return <div className="list-item" key={h.HeatingID}>


                        <section className="list-item-data">
                            <div className="row">
                                <div className="col">
                                    <p className="mb-0">{h.HeatingAction == "Start" ? "Mise en route" : "Arrêt"}</p>
                                </div>
                                <div className="col">
                                    <p className="mb-0">{dateFormat(h?.HeatingDate, "dd/MM/yyyy")}</p>
                                </div>
                                <div className="col-3 text-center">
                                    <p className="badge bg-primary rounded-5 mb-0">{h.AddressNumber}</p>
                                </div>
                            </div>
                        </section>

                        <footer className="list-item-more h-100" style={{ width: "1.75rem" }}>
                            <div className="dropdown">

                                <button className="btn btn-unstyled" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-three-dots-vertical" style={{ fontSize: "1.2rem" }}></i>
                                </button>

                                <ul className="dropdown-menu">
                                    {PrivateComponent(
                                        <Link to={h.HeatingID + "/update"} title="Modifier" className="dropdown-item" state={{ path: location.pathname }}>
                                            <i className={ICONS.UPDATE + " me-2"}></i>Modifier
                                        </Link>,
                                        GROUP.crud_sav,
                                        ["HeatingUpdate"]
                                    )}

                                    {PrivateComponent(
                                        <button className="dropdown-item" title="Supprimer" data-bs-toggle="modal" data-bs-target="#modalRemoveHeating">
                                            <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                        </button>,
                                        GROUP.crud_sav,
                                        ["HeatingDelete"]
                                    )}
                                </ul>

                            </div>
                        </footer>

                    </div>
                })
                }

            </section>
        </section>

    </section>
}