import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { Header, ButtonSubmit, TimeRound } from '../../common/common'
import { ITimesheet, IUser } from "../../common/types";
import { reqGet, reqPost, reqPut } from "../../../service/apiRequest";
import { getToken } from "../../../useToken";
import { TIMESHEET_TYPE } from "../../common/constants";
import { CheckValidTimesheetHour } from "../account/account-timesheet/account-timesheet-add";


const validationSchema = yup.object({
    TimesheetType: yup.string().required("Ce champ est obligatoire"),
    TimesheetUserID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    TimesheetDate: yup
        .date()
        .max(new Date(), `La date ne peut pas dépasser la date actuelle; ${new Date().toLocaleDateString('fr-FR')}`)
        .required("Ce champ est obligatoire")

}).required();

export default function TimesheetAdd() {
    const params = useParams()
    const idTimesheet = params.idTimesheet
    const navigate = useNavigate()
    const token = getToken()
    const location = useLocation()

    const { register, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm<{ TimesheetType: string, TimesheetUserID: number, TimesheetDate: Date }>({
        mode: "onTouched",
        resolver: yupResolver(validationSchema)
    });

    const [user, setUser] = useState<IUser>()
    const [users, setUsers] = useState<Array<IUser>>([])

    useEffect(() => {
        reqGet("user").then(res => setUsers(res))
        reqGet("timesheet/" + idTimesheet).then(res => {

            setValue("TimesheetType", TIMESHEET_TYPE.TIMESHEET)

            $("#TimesheetStart").val(res.TimesheetStart.substring(11, 16))
            $("#TimesheetLunchStart").val(res.TimesheetLunchStart.substring(11, 16))
            $("#TimesheetLunchEnd").val(res.TimesheetLunchEnd.substring(11, 16))
            $("#TimesheetEnd").val(res.TimesheetEnd.substring(11, 16))
        })
    }, [idTimesheet])

    useEffect(() => {
        if (users.length > 0) {
            $("#selectUser").val(location?.state?.idUser)
            setValue("TimesheetUserID", location?.state?.idUser)
            reqGet("user/" + location?.state?.idUser).then(res => setUser(res))
        }
    }, [users])

    useEffect(() => {
        if (getValues("TimesheetUserID"))
            reqGet("user/" + getValues("TimesheetUserID")).then(res => setUser(res))
    }, [watch("TimesheetUserID")])

    const onSubmit: SubmitHandler<{ TimesheetType: string, TimesheetUserID: number, TimesheetDate: Date }> = data => {

        const month = data.TimesheetDate.getMonth() + 1
        var date = data.TimesheetDate.getFullYear() + "-" + (month < 10 ? "0" + month : month) + "-" + data.TimesheetDate.getDate()

        var d = {
            ActivityUserID: token.UserID,
            FirstName: user?.FirstName,
            Name: user?.Name,
            TimesheetType: data.TimesheetType,
            TimesheetUserID: data.TimesheetUserID,
            TimesheetStart: $("#TimesheetStart").val() ?
                date + " " + $("#TimesheetStart").val() + ":00" : null,
            TimesheetLunchStart:
                data.TimesheetType == TIMESHEET_TYPE.TIMESHEET ?
                    $("#TimesheetLunchStart").val() ?
                        date + " " + $("#TimesheetLunchStart").val() + ":00" : null
                    : null,
            TimesheetLunchEnd:
                data.TimesheetType == TIMESHEET_TYPE.TIMESHEET ?
                    $("#TimesheetLunchEnd").val() ?
                        date + " " + $("#TimesheetLunchEnd").val() + ":00" : null
                    : null,
            TimesheetEnd: $("#TimesheetEnd").val() ?
                date + " " + $("#TimesheetEnd").val() + ":00" : null
        }

        reqPost('timesheet', d).then((res) => {
            navigate("/timesheet", { state: { date: date } })
        })

    };

    return <div className="container-fluid">

        <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-6">
                <div className="body-container">
                    <header className="mb-3">
                        <h2 className="section-title mb-0">Ajouter une feuille d'heure</h2>
                        <Link to="/timesheet" className="link-nav">{"<"} Retour</Link>
                    </header>

                    <section className="body">

                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="row row-cols-2 mb-3">

                                <div className="col">
                                    <label className="form-label">Type *</label>

                                    <select className={"form-select " + (errors?.TimesheetType ? "is-invalid" : "")} {...register("TimesheetType")}>
                                        <option value={TIMESHEET_TYPE.TIMESHEET}>Feuille d'heures</option>
                                        <option value={TIMESHEET_TYPE.PICKET}>Piquet</option>
                                    </select>

                                    {errors.TimesheetType?.message && <p className="input-msg-error">{errors.TimesheetType?.message}</p>}
                                </div>
                            </div>

                            <div className="row row-cols-4 row-cols-xxl-2 mb-3">
                                <div className="col">
                                    <label className="form-label">Utilisateur *</label>

                                    <select className={"form-select " + (errors?.TimesheetUserID ? "is-invalid" : "")} {...register("TimesheetUserID")} id="selectUser">
                                        {users.length > 0 && users.map(u => {
                                            return <option key={u.UserID} value={u.UserID}> {u?.FirstName} {u?.Name}</option>
                                        })}
                                    </select>

                                    {errors.TimesheetUserID?.message && <p className="input-msg-error">{errors.TimesheetUserID?.message}</p>}
                                </div>

                                <div className="col">
                                    <label className="form-label">Date *</label>

                                    <input type="date" className={"form-control " + (errors?.TimesheetDate ? "is-invalid" : "")} {...register("TimesheetDate")} id="TimesheetDate" />

                                    {errors.TimesheetDate?.message && <p className="input-msg-error">{errors.TimesheetDate?.message}</p>}
                                </div>
                            </div>

                            <div className="row row-cols-4">
                                <div className="col mb-3">
                                    <label className="form-label">Début</label>

                                    <div className="input-group">
                                        <input type="time" id="TimesheetStart" className="form-control" onBlur={(e) => CheckValidTimesheetHour(user, getValues("TimesheetDate"), e)} />
                                    </div>
                                </div>

                                {watch("TimesheetType") == TIMESHEET_TYPE.TIMESHEET && <>
                                    <div className="col mb-3">
                                        <label className="form-label">Pause de midi</label>

                                        <div className="input-group">
                                            <input type="time" id="TimesheetLunchStart" className="form-control" onBlur={(e) => CheckValidTimesheetHour(user, getValues("TimesheetDate"), e)} />
                                        </div>
                                    </div>

                                    <div className="col mb-3">
                                        <label className="form-label">Fin pause de midi</label>

                                        <div className="input-group">
                                            <input type="time" id="TimesheetLunchEnd" className="form-control" onBlur={(e) => CheckValidTimesheetHour(user, getValues("TimesheetDate"), e)} />
                                        </div>
                                    </div>
                                </>}

                                <div className="col mb-3">
                                    <label className="form-label">Fin</label>

                                    <div className="input-group">
                                        <input type="time" id="TimesheetEnd" className="form-control" onBlur={(e) => CheckValidTimesheetHour(user, getValues("TimesheetDate"), e)} />
                                    </div>
                                </div>
                            </div>

                            <div className="text-end">
                                <p><small>Les champs avec une * sont obligatoire</small></p>
                                <ButtonSubmit />
                            </div>

                        </form>
                    </section>
                </div>

            </div>
        </div>

    </div>
}