import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { reqGet, reqPost } from '../../../service/apiRequest'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../useToken'
import { IAddress, IManagement, IOwner, ITenant, IZipCode } from "../../common/types"
import { HeaderRow, ButtonSubmit, ReplaceSpecialChars, ManagementInputSearch, OwnerInputSearch, TenantInputSearch } from '../../common/common'

interface IFormInput extends IAddress {
    AddressNumber: string;
    ActivityUserID: number | null;
}

const validationSchema = yup.object({
    AddressLine: yup.string().required("Ce champ est obligatoire"),
    AddressNumber: yup.string().required("Ce champ est obligatoire"),
    AddressComplementary: yup.string().nullable(),
    OwnerID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    Tenant: yup.string().nullable(),
    TenantID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ZipCodeID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    ManagementID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()

}).required();

const initialValues = {
    AddressLine: "",
    AddressNumber: "",
    AddressComplementary: null,
    OwnerID: null,
    Tenant: null,
    TenantID: null,
    ZipCodeID: null,
    ManagementID: null,
    ActivityUserID: null
}

export default function AddressAdd({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()

    const { register, formState: { errors }, handleSubmit, watch, setValue } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [zipcode, setZipcode] = useState<Array<IZipCode>>([])
    const [managementSelected, setManagementSelected] = useState<IManagement | null>(null)
    const [ownerSelected, setOwnerSelected] = useState<IOwner | null>(null)
    const [tenantSelected, setTenantSelected] = useState<ITenant | null>(null)

    useEffect(() => {
        setValue("ActivityUserID", token.UserID)

        reqGet('zipcode').then(res => { setZipcode(res) })
    }, [])


    const onSubmit: SubmitHandler<IFormInput> = data => {

        data.ManagementID = managementSelected?.ManagementID || null
        data.OwnerID = ownerSelected?.OwnerID || null
        data.TenantID = tenantSelected?.TenantID || null

        reqPost('address', data).then((res) => {
            setReload(true)
            navigate('/address/' + res)
        })
    };

    return (
        <section className="body-container h-100">
            {HeaderRow(
                <h3 className="section-title">Ajouter une adresse</h3>,
                <Link to={"/address"} className="link-nav d-block d-md-none">{'<'} Retour</Link>,
            )}

            <section className="body container-fluid">

                <div className="row h-100 justify-content-center">


                    <div className="col-xl-6">

                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="mb-3">
                                <label className="form-label">Adresse *</label>

                                <div className="input-group">

                                    <input className={"form-control w-75 " + (errors?.AddressLine ? "is-invalid" : "")} placeholder="Rue" {...register("AddressLine")} />

                                    <input className={"form-control w-25 " + (errors?.AddressNumber ? "is-invalid" : "")} placeholder="Numéro(s)" {...register("AddressNumber")} />

                                </div>

                                {errors.AddressLine?.message && <p className="input-msg-error">{errors.AddressLine?.message}</p>
                                    || errors.AddressNumber?.message && <p className="input-msg-error">{errors.AddressNumber?.message}</p>}
                            </div>

                            <div className="row mb-3">

                                <div className="col-6">

                                    <label className="form-label">Code postal *</label>

                                    <select className={"form-select " + (errors?.ZipCodeID ? "is-invalid" : "")} {...register("ZipCodeID")}>

                                        <option hidden> </option>

                                        {zipcode.map((z: IZipCode, k: number) => {
                                            return <option key={k} value={z.ZipCodeID}>{z.ZipCode} {z.City}</option>;
                                        })}

                                    </select>

                                    {errors.ZipCodeID?.message && <p className="input-msg-error">{errors.ZipCodeID?.message}</p>}

                                </div>

                                <div className="col-6">

                                    <label className="form-label">Complément d'adresse</label>

                                    <input className={"form-control " + (errors?.AddressComplementary ? "is-invalid" : "")} {...register("AddressComplementary")} />

                                    {errors.AddressComplementary?.message && <p className="input-msg-error">{errors.AddressComplementary?.message}</p>}

                                </div>

                            </div>



                            <div className="row row-cols-2 mb-3">

                                <div className="col">
                                    <ManagementInputSearch onChange={(management) => setManagementSelected(management)} />
                                </div>

                                <div className="col">
                                    <OwnerInputSearch onChange={(owner) => setOwnerSelected(owner)} />
                                </div>

                            </div>


                            <div className="row row-cols-2 mb-3">
                                <div className="col">
                                    <TenantInputSearch onChange={(tenant) => setTenantSelected(tenant)} />
                                </div>
                            </div>

                            <div className="text-end">
                                <p><small>Les champs avec une * sont obligatoire</small></p>
                                <ButtonSubmit />
                            </div>

                        </form>

                    </div>

                </div>

            </section>
        </section >
    );
}
