import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import { faker } from '@faker-js/faker'
faker.locale = 'fr_CH'

import { getToken } from '../../../useToken'
import { AlertMsg, Header } from '../../common/common'
import { GROUP, DEFAULT_IMG, LAYOUT, PRODUCT_CLASS_ID, PRODUCT_TYPE, ICONS } from '../../common/constants'
import { reqGet, reqPut } from "../../../service/apiRequest";
import { IStorageProduct } from "../../common/types"
import { StorageRefItem, StorageRefResolver } from "./warehouse-takeout";

interface IStorageResupply extends IStorageProduct {
    ResupplyQuantity: number
}

export default function StorageResupply() {
    const navigate = useNavigate()
    const params = useParams()
    const idWarehouse = params.idWarehouse
    const token = getToken()

    const [reference, setReference] = useState<number | null>(null)
    const [storage, setStorage] = useState<IStorageResupply | null>(null)
    const [entryMode, setEntryMode] = useState<string | null>(null)
    const [data, setData] = useState<Array<IStorageResupply>>([])

    const [display, setDisplay] = useState<string>("")
    const [addingProduct, setAddingProduct] = useState<boolean>(false)

    const [authSubmit, setAuthSubmit] = useState<boolean>(false)

    useEffect(() => {
        if (reference) {

            // checking storage ID
            reqGet("storage/" + reference + "/warehouse/" + idWarehouse).then(res => {
                if (res != false) {
                    setStorage(res)
                } else {

                    // checking product reference
                    reqGet("storage/productref/" + reference + "/warehouse/" + idWarehouse).then(result => {
                        if (result != false) {
                            setStorage(result)
                        } else {
                            setStorage(null)
                        }
                    })
                }

            })
        }
    }, [reference])

    function HandleEntryMode(value) {
        if (value == null) {
            setEntryMode(null)
            setReference(null)
            setStorage(null)
        } else {
            setEntryMode(value)
        }
    }

    function AddProduct() {
        if (storage) {
            if (data.length == 0) {
                setData(oldArray => [...oldArray, { ...storage, ResupplyQuantity: $("#ResupplyQuantity").val() || null }])

            } else if (!data.find((item) => item.StorageID === storage.StorageID)) {

                setData(oldArray => [...oldArray, { ...storage, ResupplyQuantity: $("#ResupplyQuantity").val() || null }])
            } else {
                // pièce déja ajoutée
            }

            setEntryMode(null)
            setReference(null)
            setStorage(null)
            setAddingProduct(false)
        }
    }

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [addingProduct])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (!addingProduct) {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }

    }

    function UpdateItem(storage: IStorageResupply, quantity: string) {
        const newData = data.map(d => {
            if (storage.StorageID == d.StorageID) {

                return { ...d, ResupplyQuantity: parseInt(quantity) }
            } else
                return d;
        });

        setData(newData);
    }

    function RemoveItem(product: IStorageResupply) {
        setData(data.filter(item => item.StorageID !== product.StorageID));
    }

    function ConfirmResupply() {
        const resupply = {
            ActivityUserID: token.UserID,
            Data: data
        }

        reqPut("warehouse/resupply/" + idWarehouse, resupply).then(res => {
            navigate("/warehouse/" + idWarehouse)
        })


    }


    return <div className="row h-100">


        <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="modalLabel">Confirmer réapprovisionnement</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes-vous sûr d'avoir terminé le réapprovisionnement ?</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => ConfirmResupply()}>Confirmer</button>
                    </div>
                </div>
            </div>
        </div>


        <div className={display + " col-md-6 col-lg-8 col-h-fill"}>

            <section className="body-container content">

                <header className="header">
                    <div>
                        <h2 className="section-title">Réapprovisionnement</h2>
                        <Link to={"/warehouse/" + idWarehouse} className="link-nav">{"<"} Retour</Link>
                    </div>

                    <button className="btn btn-add" title="Confirmer réapprovisionnement" disabled={data.length > 0 ? false : true} data-bs-toggle="modal" data-bs-target="#confirmModal">
                        <i className={ICONS.ADD + " me-2"}></i>Confirmer réapprovisionnement
                    </button>
                </header>

                <section className="container-fluid d-block d-md-none mb-3 px-0 overflow-hidden">
                    <h6 className="mb-2">Ajouter:</h6>

                    <div className="row row-cols-2">
                        <div className="col">
                            <button className="btn btn-light w-100 py-2" onClick={() => { setAddingProduct(true); setEntryMode("qrCode") }}>
                                <i className="bi bi-qr-code-scan me-2"></i>Scanner QR code
                            </button>
                        </div>

                        <div className="col">
                            <button className="btn btn-light w-100 py-2" onClick={() => { setAddingProduct(true); setEntryMode("manual") }}>
                                <i className="bi bi-keyboard me-2"></i>Entrer manuellement
                            </button>
                        </div>
                    </div>
                </section>


                <section className="body">

                    <div className="data-list">

                        {data.map(product => {
                            return <div className="product-item" key={product.ProductID}>
                                <div className="product-img">
                                    <img className="img-fluid border" src={product?.ProductImage ? "../media/images/product/" + product?.ProductImage : DEFAULT_IMG} alt="" />
                                </div>

                                <div className="product-data pe-md-3">
                                    <div className="row d-flex">

                                        <div className="col-7 col-sm-5">
                                            <h6 className="title text-truncate">{product.ProductModel}</h6>
                                            <p className="text-light text-truncate mb-1">{product.ProductBrand} - {product.ProductReference}</p>
                                            {product.Rack && <h6>{product.Rack}{product.Row} - {product.Col}</h6>}
                                        </div>

                                        <div className="col-5 col-sm-7 col-lg-3">
                                            <h6 className="title text-truncate">Ajouter</h6>
                                            <input type="number" className="form-control" defaultValue={product.ResupplyQuantity} onChange={(e) => UpdateItem(product, e.currentTarget.value)} />
                                        </div>

                                    </div>
                                </div>

                                <div className="product-more">
                                    <button className="btn btn-outline" onClick={() => RemoveItem(product)}>
                                        <i className="bi bi-x-lg me-1"></i> <span className="d-none d-md-inline">Retirer</span>
                                    </button>
                                </div>
                            </div>
                        })}

                    </div>

                </section>



            </section>
        </div>

        {$(window).width() >= LAYOUT.md || ($(window).width() < LAYOUT.md && addingProduct) ?
            <div className="col-md-6 col-lg-4 col-h-fill">

                <section className="body-container content">
                    <header className="mb-0">
                        <h3 className="section-title mb-0">Selectionner</h3>
                        {entryMode != null ?
                            <button className="btn link-nav" onClick={() => HandleEntryMode(null)}>{"<"} Retour</button>
                            : <></>
                        }
                    </header>

                    <section className="body container-fluid">

                        <StorageRefResolver entryMode={entryMode} setEntryMode={(value) => HandleEntryMode(value)} setReference={setReference} />

                        {reference == null ?
                            <></> :
                            storage ?
                                <StorageRefItem storage={storage} /> :
                                AlertMsg("info", "Aucune référence trouvée")
                        }

                        {reference && storage?.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE ?
                            <>
                                <div className="card">
                                    <div className="card-body">
                                        <label htmlFor="ResupplyQuantity" className="form-label">Nombre à ajouter</label>
                                        <input id="ResupplyQuantity" type="number" className="form-control mb-3" min={1} onChange={(e) => e.target.value ? setAuthSubmit(true) : setAuthSubmit(false)} />
                                    </div>
                                </div>

                                <div className="text-end">
                                    <button className="btn btn-primary" onClick={() => AddProduct()} disabled={!authSubmit}><i className="bi bi-check me-1"></i>Valider</button>
                                </div>
                            </> :
                            <></>
                        }


                        {reference && storage && storage?.ProductClassID != PRODUCT_CLASS_ID.CONSUMABLE ?
                            AlertMsg("info", "Ne peut être réaprovisioner") :
                            <></>
                        }

                    </section>

                </section>
            </div>
            : ""}
    </div >
}
