import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom'
import $ from 'jquery'

import { reqGet } from '../../../../service/apiRequest'
import { getToken } from '../../../../useToken'
import { IUser, IVehicle } from '../../../common/types'
import { GROUP, PERMISSION } from '../../../common/constants'
import { PrivateLinkAdd, PrivateLinkUpdate, Header } from '../../../common/common'
import { VehicleData } from '../../vehicle/vehicle-content'
import { VehicleChecking } from '../../vehicle/vehicle-checking'

export default function AccountVehicle() {
    const params = useParams()
    const token = getToken()
    const navigate = useNavigate()

    const idVehicle = params.idVehicle

    const [vehicle, setVehicle] = useState<IVehicle>()
    const [checkType, setCheckType] = useState('checking')

    useEffect(() => {
        reqGet("vehicle/" + idVehicle).then((res) => {

            if (token.UserID !== res.UserID) {
                navigate("/")
            }

            setVehicle(res)
        }, rej => {
            navigate(-1)
        })
    }, [idVehicle])


    function handleCheckType(type, e) {
        $('#navCheckType .nav-link.active').removeClass('active')
        e.currentTarget.classList.add('active')

        setCheckType(type)
    }

    return <section className="body-container content">

        {Header(
            <h3 className="section-title mb-0">
                {vehicle?.VehicleNumber ? vehicle.VehicleNumber + " - " : ''} {vehicle?.Brand ? vehicle.Brand + " : " : ''} {vehicle?.Model}
            </h3>,
            <Link to="/account" className="link-nav">{'<'} Retour</Link>,
            PrivateLinkUpdate("update", GROUP.admin, PERMISSION.vehicleCheck)
        )}

        <div className="body container-fluid">
            <div className="row h-100">

                <div className="col-xl-8 h-100 overflow-auto order-2 order-xl-1 px-0 pe-xl-3">
                    {VehicleChecking(vehicle?.VehicleID, vehicle)}
                </div>

                <div className="col-xl-4 order-1 order-xl-2 d-flex mb-3 mb-xl-0 px-0 ps-xl-3">
                    {vehicle && <VehicleData vehicle={vehicle} />}
                </div>

            </div>
        </div>

    </section>
}