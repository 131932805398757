import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, Outlet, Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { LAYOUT } from "../../../common/constants"
import { DebouncedInput, dateFormat, fuzzyFilter } from "../../../common/common";
import { reqGet, reqPost } from "../../../../service/apiRequest";
import { IAddress, IHeating } from "../../../common/types"

import { useReactTable, getCoreRowModel, getFilteredRowModel, getSortedRowModel, ColumnDef, } from '@tanstack/react-table'
import { getToken } from "../../../../useToken";

interface IAddressHeating extends IAddress, IHeating { }

export default function AccountHeating({ reload, setReload }) {
    const navigate = useNavigate()
    const token = getToken()
    const location = useLocation()

    const [display, setDisplay] = useState<string>("")
    const [display2, setDisplay2] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.xl) {
            setDisplay('d-flex')
            setDisplay2('d-flex')

        } else {
            if (location.pathname == "/account/heating") {
                setDisplay('d-flex')
                setDisplay2('d-none')

            } else {
                setDisplay('d-none')
                setDisplay2('d-flex')
            }
        }
    }

    const columns = useMemo<ColumnDef<IAddressHeating, any>[]>(
        () => [
            {
                accessorFn: row => row.AddressLine,
                id: 'AddressLine',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.AddressComplementary,
                id: 'AddressComplementary',
                cell: info => info.getValue()
            }
        ], []
    )

    const [data, setData] = useState<Array<IAddressHeating>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        reqGet("heating/user/" + token.UserID).then((res) => {
            setData(res)
        }, rej => {
            navigate(-1)
        })
    }, [])


    useEffect(() => {
        if (reload) {
            reqGet("heating/user/" + token.UserID).then((res) => {
                setData(res)
            }, rej => {
                navigate(-1)
            })
            setReload(false)
        }
    }, [reload])

    return (<div className="container-fluid h-100">
        <div className="row row-cols-xl-2 h-100 border-right">
            <section className={display + " col-h-fill py-3 border-right"} >

                <header className="mb-3">
                    <h2 className="section-title mb-0">Arrêt/MER chauffage</h2>
                    <Link to="/account" className="link-nav">{"<"} Retour</Link>
                </header>

                <article className="card card-fill card-flush">

                    <section className="card-body">

                        <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} className="mb-3" placeholder="Recherche" />


                        <nav className="list-group list-group-hover">

                            {data.length == 0 && <p className="text-muted">Vous n'avez pas d'arrêt ou de mise en route du chauffage</p>}

                            {table.getRowModel().rows.map(row => {

                                return (
                                    <NavLink to={row.original.AddressID.toString()} key={row.original.AddressID} className="list-group-item mb-3">

                                        <div className="row mx-0 mb-1">

                                            <div className="col-8 px-0">
                                                <h6 className="item-title text-truncate">{row.original.AddressLine} {row.original.AddressComplementary && "(" + row.original.AddressComplementary + ")"}</h6>

                                                <p className="mb-0">{row.original.ZipCode} {row.original.City}</p>
                                            </div>

                                            <div className="col-4 text-end px-0">
                                                <p className="item-title mb-0">dès le {dateFormat(row.original.HeatingDate, "dd/MM/yyyy")}</p>

                                                <p className="mb-0">{row.original.HeatingAction == "Start" ? "Mise en route" : "Arrêt"}</p>
                                            </div>

                                        </div>

                                        {(row.original.AddressServiceNote || row.original.HeatingNote) &&
                                            <div className="col-6 mt-2">
                                                <h6 className="item-title">Information</h6>

                                                {row.original.AddressServiceNote && <p className="paragraph">{row.original.AddressServiceNote}</p>}

                                                {row.original.HeatingNote && <p className="paragraph">{row.original.HeatingNote}</p>}
                                            </div>
                                        }
                                    </NavLink>
                                )

                            })}
                        </nav>
                    </section>

                    <footer className="card-footer text-start">
                        <p className="mb-0 text-muted">Seul les arrêt et mise en route non effectué, au maximum dans le mois qui vient, sont affiché.</p>
                    </footer>

                </article>
            </section>


            {data.length > 0 &&
                <div className={display2 + " col h-100 overflow-auto px-0"}>

                    <Outlet context={data} />

                </div>}
        </div>
    </div>
    );
}