import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { reqGet, reqPost } from "../../../../service/apiRequest";
import { ButtonSubmit, dateFormat } from "../../../common/common";
import { IAddress, IAddressContract } from "../../../common/types";
import { getToken } from "../../../../useToken";

export default function AddressContractNewPrice() {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idAddress = params.idAddress
    const idAddressContract = params.idAddressContract

    const validationSchema = yup.object({
        AddressContractPrice: yup.string().required("Ce champ est obligatoire")
    }).required();

    const initialValues = {
        AddressContractID: parseInt(idAddressContract ?? ""),
        AddressContractPrice: null
    }

    const { register, formState: { errors }, handleSubmit, watch, getValues } = useForm<IAddressContract>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [address, setAddress] = useState<IAddress>()
    const [addressContract, setAddressContract] = useState<IAddressContract>()

    useEffect(() => {
        if (idAddress && idAddressContract) {
            reqGet("address/" + idAddress).then(res => setAddress(res))
            reqGet("addresscontract/" + idAddressContract).then(res => { console.log(res); setAddressContract(res) })
        } else {
            navigate('/address/' + idAddress + "/contract")
        }
    }, [idAddressContract])

    const onSubmit: SubmitHandler<IAddressContract> = data => {

        reqPost('addresscontract/newprice',
            {
                ...data,
                ActivityUserID: token.UserID,
                AddressLine: address?.AddressLine,
                AddressZipCode: address?.ZipCode,
                City: address?.City
            }).then((res) => {
                navigate('/address/' + idAddress + "/contract")
            })
    };

    return <div className="container-fluid">
        <div className="row justify-content-center">
            <div className="col-lg-8 px-0">

                <div className="body-container content">

                    <header className="header">
                        <div>
                            <h2 className="section-title">Entrez le nouveau prix</h2>
                            <Link to={"/address/" + idAddress + "/contract"} className="link-nav">{"<"} Retour</Link>
                        </div>
                    </header>

                    <section className="body">

                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="row row-cols-2 mb-3">

                                <div className="col">
                                    <label className="form-label">Ancien prix</label>
                                    <p className="item-text">{addressContract?.AddressContractPrice} CHF</p>
                                    <p className="item-text">{dateFormat(addressContract?.AddressContractDatePrice, "dd/MM/yyyy")}</p>
                                </div>

                                <div className="col">
                                    <label className="form-label">Nouveau prix *</label>
                                    <input type="text" className={"form-control " + (errors?.AddressContractPrice ? "is-invalid" : "")} {...register("AddressContractPrice")} />

                                    {errors.AddressContractPrice?.message && <p className="input-msg-error">{errors.AddressContractPrice?.message}</p>}
                                </div>

                            </div>

                            <div className="text-end">
                                <p><small>Les champs avec une * sont obligatoire</small></p>
                                <ButtonSubmit />
                            </div>

                        </form>


                    </section>
                </div>

            </div>
        </div>
    </div>
}