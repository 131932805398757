import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { IProduct, IBrand } from '../../../common/types'
import { HeaderRow, ButtonSubmit } from '../../../common/common'
import { PRODUCT_TYPE } from '../../../common/constants';

const validationSchema = yup.object({
    ProductModel: yup.string().required("Ce champ est obligatoire"),
    ProductReference: yup.string().nullable(),
    BrandID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ProductNote: yup.string().nullable(),
    ProductPower: yup.string().nullable(),
    ProductVoltage: yup.string().nullable(),
    ProductIntensity: yup.string().nullable()
})

const initialValues = {
    ProductType: PRODUCT_TYPE.TOOL,
    ProductModel: "",
    ProductReference: "",
    BrandID: null,
    ProductNote: null,
    ProductPower: null,
    ProductVoltage: null,
    ProductIntensity: null
}

export default function ToolAdd() {
    const navigate = useNavigate()
    const token = getToken()

    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IProduct>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [brand, setBrand] = useState<Array<IBrand>>([])

    useEffect(() => {
        reqGet("brand").then(res => {
            setBrand(res)
        })

    }, [])

    const onSubmit: SubmitHandler<IProduct> = data => {
        reqPost('product', { ...data, ActivityUserID: token.UserID, Reference: data?.ProductReference?.replace(/\s+/g, '') }).then((res) => {
            navigate('/tool/' + res)
        })

    };

    return <div className="body-container content">

        {HeaderRow(
            <h3 className="section-title mb-1">Ajouter un outil</h3>,
            <button className="btn link-nav" onClick={() => navigate(-1)}>{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="row row-cols-3 mb-3">

                            <div className="col">
                                <label htmlFor="ProductModel" className="form-label">Modèle *</label>
                                <input type="text" className={"form-control " + (errors?.ProductModel ? "is-invalid" : "")} {...register("ProductModel")} />

                                {errors.ProductModel?.message && <p className="mt-2 text-danger">{errors.ProductModel?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="ProductReference" className="form-label">Référence</label>
                                <input type="text" className={"form-control " + (errors?.ProductReference ? "is-invalid" : "")} {...register("ProductReference")} />

                                {errors.ProductReference?.message && <p className="mt-2 text-danger">{errors.ProductReference?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="BrandID" className="form-label">Marque</label>
                                <select className={"form-select " + (errors?.BrandID ? "is-invalid" : "")} id="" {...register("BrandID")}>
                                    <option value=""></option>
                                    {brand.map(b => {
                                        return <option key={b.BrandID} value={b.BrandID}>{b.Name}</option>
                                    })}
                                </select>

                                {errors.BrandID?.message && <p className="mt-2 text-danger">{errors.BrandID?.message}</p>}
                            </div>
                        </div>

                        <div className="row row-cols-3 mb-3">
                            <div className="col">
                                <label htmlFor="ProductPower" className="form-label">Puissance</label>
                                <div className="input-group">
                                    <input type="text" className={"form-control " + (errors?.ProductPower ? "is-invalid" : "")} {...register("ProductPower")} />
                                    <span className="input-group-text">kW</span>
                                </div>

                                {errors.ProductPower?.message && <p className="mt-2 text-danger">{errors.ProductPower?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="ProductVoltage" className="form-label">Tension</label>
                                <div className="input-group">
                                    <input type="text" className={"form-control " + (errors?.ProductVoltage ? "is-invalid" : "")} {...register("ProductVoltage")} />
                                    <span className="input-group-text">V</span>
                                </div>

                                {errors.ProductVoltage?.message && <p className="mt-2 text-danger">{errors.ProductVoltage?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="ProductIntensity" className="form-label">Intensité</label>
                                <div className="input-group">
                                    <input type="text" className={"form-control " + (errors?.ProductIntensity ? "is-invalid" : "")} {...register("ProductIntensity")} />
                                    <span className="input-group-text">A</span>
                                </div>

                                {errors.ProductIntensity?.message && <p className="mt-2 text-danger">{errors.ProductIntensity?.message}</p>}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="" className="form-label">Remarque</label>
                            <textarea rows={4} {...register("ProductNote")}></textarea>
                        </div>


                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>
            </div >


        </div>
    </div>
}