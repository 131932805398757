import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { reqGet, reqPost } from '../../../service/apiRequest'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { IVehicle, IUser } from "../../common/types"
import { getToken } from '../../../useToken'
import { HeaderRow, ButtonSubmit } from '../../common/common'

yup.setLocale({
    mixed: {
        required: "Ce champ est obligatoire"
    }
})

interface IFormInput extends IVehicle {
    ActivityUserID: number | null
}

let today = new Date();

let year = today.getFullYear()

const validationSchema = yup.object({
    VehicleNumber: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    Assignment: yup.string().required(),
    Model: yup.string().required(),
    Brand: yup.string().required(),
    VehicleType: yup.string().required(),
    Numberplate: yup.string().required(),
    Fuel: yup.string().required(),

    Year: yup.number().transform((value) => (isNaN(value) ? undefined : value))
        .min(1990, "L'année minimum est 1990")
        .max(year + 1, "L'année maximum est " + (year + 1)).nullable(),

    PurchaseYear: yup.number().transform((value) => (isNaN(value) ? undefined : value))
        .min(1990, "L'année minimum est 1990")
        .max(year, "L'année maximum est " + (year)).nullable(),

    Geoloc: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),
    RoofGallery: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required(),

    CritAir: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    UserID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    GuarantorID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    TiresSummer: yup.string().nullable(),
    TiresWinter: yup.string().nullable()

}).required();

const initialValues = {
    VehicleNumber: null,
    Assignment: "Chantier",
    Model: "",
    Brand: "",
    VehicleType: "",
    Numberplate: '',
    Fuel: 'Essence',
    Year: null,
    PurchaseYear: null,
    Geoloc: 1,
    CritAir: 0,
    RoofGallery: 0,
    UserID: null,
    GuarantorID: null,
    TiresSummer: "",
    TiresWinter: "",
    ActivityUserID: null
}

export default function VehicleAdd({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()

    const { register, formState: { errors }, handleSubmit, setValue, watch } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [users, setUsers] = useState<Array<IUser>>([])
    const [vehicleType, setVehicleType] = useState<Array<string>>([])

    useEffect(() => {
        setValue("ActivityUserID", token.UserID)

        reqGet('user').then(function (res) { setUsers(res) })
        reqGet('vehicle/vehicleType').then((res) => { setVehicleType(res) })
    }, [])

    const onSubmit: SubmitHandler<IFormInput> = data => {
        reqPost('vehicle', data).then((res) => {
            setReload(true)
            navigate('/vehicle/' + res)
        })
    }


    return <section className="body-container">
        {HeaderRow(
            <h3 className="section-title">Ajouter un véhicule</h3>,
            <Link to={"/vehicle"} className="link-nav d-block d-md-none">{'<'} Retour</Link>
        )}

        <section className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-xl-6">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="row mb-3">

                            <div className="col-5">

                                <label htmlFor="VehicleNumber" className="form-label">Numéro véhicule</label>
                                <input type="number" className={"form-control " + (errors?.VehicleNumber ? "is-invalid" : "")} {...register("VehicleNumber")} />

                                {errors.VehicleNumber?.message && <p className="mt-2 text-danger">{errors.VehicleNumber?.message}</p>}

                            </div>

                            <div className="col-7">

                                <label htmlFor="Numberplate" className="form-label">Plaque *</label>
                                <input type="text" className={"form-control " + (errors?.Numberplate ? "is-invalid" : "")} {...register("Numberplate")} />

                                {errors.Numberplate?.message && <p className="mt-2 text-danger">{errors.Numberplate?.message}</p>}

                            </div>
                        </div>

                        <div className="row mb-3">

                            <div className="col-6">
                                <label htmlFor="Model" className="form-label">Modèle *</label>
                                <input type="text" className={"form-control " + (errors?.Model ? "is-invalid" : "")} {...register("Model")} />

                                {errors.Model?.message && <p className="mt-2 text-danger">{errors.Model?.message}</p>}

                            </div>

                            <div className="col-6">
                                <label htmlFor="Brand" className="form-label">Marque *</label>
                                <input type="text" className={"form-control " + (errors?.Brand ? "is-invalid" : "")} {...register("Brand")} />

                                {errors.Brand?.message && <p className="mt-2 text-danger">{errors.Brand?.message}</p>}
                            </div>
                        </div>

                        <div className="row mb-3">

                            <div className="col-6">

                                <label htmlFor="Brand" className="form-label">Affectation *</label>
                                <select className={"form-select " + (errors?.Brand ? "is-invalid" : "")} {...register("Assignment")}>
                                    <option value="Bureau">Bureau</option>
                                    <option value="Chantier">Chantier</option>
                                </select>

                                {errors.Assignment?.message && <p className="mt-2 text-danger">{errors.Assignment?.message}</p>}

                            </div>

                            <div className="col-6">

                                <label htmlFor="type" className="form-label">Type *</label>

                                <select className={"form-select " + (errors?.VehicleType ? "is-invalid" : "")} {...register("VehicleType")}>

                                    <option hidden> </option>

                                    {vehicleType.map((vt, k) => {
                                        return <option key={k} value={vt}>{vt}</option>;
                                    })}
                                </select>

                                {errors.VehicleType?.message && <p className="mt-2 text-danger">{errors.VehicleType?.message}</p>}

                            </div>

                        </div>

                        <div className="mb-3">

                            <label htmlFor="fuel" className="form-label d-block">Carburant *</label>

                            <div className="form-check form-check-inline mt-2">
                                <input className="form-check-input" type="radio" value="Essence" {...register("Fuel")} />
                                <label className="form-check-label" htmlFor="radioFuel"> Essence </label>
                            </div>

                            <div className="form-check form-check-inline mt-2">
                                <input className="form-check-input" type="radio" value="Diesel" {...register("Fuel")} />
                                <label className="form-check-label" htmlFor="radioFuel"> Diesel </label>
                            </div>

                            <div className="form-check form-check-inline mt-2">
                                <input className="form-check-input" type="radio" value="Electrique" {...register("Fuel")} />
                                <label className="form-check-label" htmlFor="radioFuel"> Electrique </label>
                            </div>
                        </div>


                        <div className="row mb-3">

                            <div className="col-6">

                                <label htmlFor="Year" className="form-label">Année</label>
                                <input type="number" className={"form-control " + (errors?.Year ? "is-invalid" : "")} {...register("Year")} />

                                {errors.Year?.message && <p className="mt-2 text-danger">{errors.Year?.message}</p>}

                            </div>

                            <div className="col-6">

                                <label htmlFor="PurchaseYear" className="form-label">Année d'achat</label>
                                <input type="number" className={"form-control " + (errors?.PurchaseYear ? "is-invalid" : "")} {...register("PurchaseYear")} />

                                {errors.PurchaseYear?.message && <p className="mt-2 text-danger">{errors.PurchaseYear?.message}</p>}

                            </div>

                        </div>

                        <div className="row mb-3">

                            <div className="col">

                                <label htmlFor="geoloc" className="form-label">Geolocalisation *</label>

                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="geoloc" {...register("Geoloc")} />
                                    <label className="form-check-label" htmlFor="geoloc">{watch("Geoloc") ? "Oui" : "Non"}</label>

                                    {errors.Geoloc?.message && <p className="mt-2 text-danger">{errors.Geoloc?.message}</p>}

                                </div>
                            </div>

                            <div className="col">

                                <label htmlFor="roofGallery" className="form-label">Galerie de toit *</label>

                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="roofGallery" {...register("RoofGallery")} />
                                    <label className="form-check-label" htmlFor="roofGallery">{watch("RoofGallery") ? "Oui" : "Non"}</label>

                                    {errors.RoofGallery?.message && <p className="mt-2 text-danger">{errors.RoofGallery?.message}</p>}

                                </div>


                            </div>

                            <div className="col-5">

                                <label htmlFor="critair" className="form-label">Crit' Air</label>

                                <select className="form-select" {...register("CritAir")}>
                                    <option hidden disabled></option>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>

                            </div>

                        </div>

                        <div className="row mb-3">

                            <div className="col-6">
                                <label htmlFor="userID" className="form-label">Responsable</label>
                                <select className="form-select" {...register("UserID")}>
                                    <option value=""> A disposition </option>
                                    {users.map((u: IUser, k: number) => {
                                        return <option key={k} value={u.UserID}>{u.FirstName} {u.Name}</option>;
                                    })}
                                </select>

                                {errors.UserID?.message && <p className="mt-2 text-danger">{errors.UserID?.message}</p>}
                            </div>

                            <div className="col-6">
                                <label htmlFor="guarantorID" className="form-label">Répondant</label>
                                <select className="form-select" {...register("GuarantorID")}>
                                    <option value=""></option>
                                    {users.map((u: IUser, k: number) => {
                                        return <option key={k} value={u.UserID}>{u.FirstName} {u.Name}</option>;
                                    })}
                                </select>

                                {errors.GuarantorID?.message && <p className="mt-2 text-danger">{errors.GuarantorID?.message}</p>}
                            </div>
                        </div>

                        <div className="row mb-3">

                            <div className="col-6">

                                <label htmlFor="TiresSummer" className="form-label">Pneus d'été</label>
                                <input type="text" className={"form-control " + (errors?.TiresSummer ? "is-invalid" : "")} {...register("TiresSummer")} />

                                {errors.TiresSummer?.message && <p className="mt-2 text-danger">{errors.TiresSummer?.message}</p>}

                            </div>

                            <div className="col-6">

                                <label htmlFor="TiresWinter" className="form-label">Pneus d'hiver</label>
                                <input type="text" className={"form-control " + (errors?.TiresWinter ? "is-invalid" : "")} {...register("TiresWinter")} />

                                {errors.TiresWinter?.message && <p className="mt-2 text-danger">{errors.TiresWinter?.message}</p>}

                            </div>

                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>

            </div>
        </section>
    </section>
}