import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link, useLocation, useOutletContext } from 'react-router-dom'
import $ from 'jquery'

import { Header, dateFormat } from "../../common/common"
import { reqGet, reqPost } from '../../../service/apiRequest'
import { IAddress, IHeating } from '../../common/types'
import { SERVICE } from '../../common/constants';

export default function WorkbookAdd({ token, setReload }) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const idAddress = params.idAddress

    const data = useOutletContext<Array<IAddress>>()

    const LinkBack = () => {
        if (location?.state?.path) {
            return location.state.path
        } else {
            return '/address/' + idAddress + '/workbook'
        }
    }

    const [service, setService] = useState<string>(SERVICE.SURVEY)

    const [address, setAddress] = useState<IAddress>()

    useEffect(() => {
        reqGet('address/' + idAddress).then(function (res) {
            setAddress(res)
        })
    }, [idAddress])


    function WorkbookAddController({ service }) {
        switch (service) {
            case SERVICE.SURVEY:
                return <WorkbookAddSurvey />
            case SERVICE.HEATING:
                return <WorkbookAddHeating />
            default:
                return <></>
        }
    }

    function WorkbookAddSurvey() {

        function submitSurvey() {
            var data = {
                ...address,
                ActivityUserID: token.UserID,
                WorkbookID: address?.WorkbookID,
                Note: $("#ObservationNote").val()
            }


            reqPost('observation/survey', data).then((res) => {
                navigate('/address/' + idAddress + '/workbook')
                setReload(true)
            })
        };
        return <>
            <h4 className="section-title-2 mt-4">Effectuer surveillance</h4>

            <label className="form-label" htmlFor="ObservationNote">Commentaire</label>
            <textarea className="mb-3" name="ObservationNote" id="ObservationNote" rows={4}></textarea>


            <div className="text-end">
                <p><small>Les champs avec une * sont obligatoire</small></p>
                <button className="btn btn-primary" onClick={() => submitSurvey()}>
                    <i className="bi bi-check me-1"></i> Valider
                </button>
            </div>
        </>
    }

    function WorkbookAddHeating() {

        const [heatings, setHeatings] = useState<Array<IHeating>>([])
        const [heating, setHeating] = useState<IHeating>()

        useEffect(() => {
            reqGet("heating/address/" + idAddress + "/todo").then(res => {
                setHeatings(res)
                setHeating(res[0])

            })
        }, [])

        function SubmitHeating() {
            var data = {
                ...address,
                ActivityUserID: token.UserID,
                Note: $("#ObservationNote").val(),
                HeatingID: heating?.HeatingID,
                HeatingAction: heating?.HeatingAction,
                Date: $("#inputDate").val()
            }

            reqPost('observation/heating/' + heating?.HeatingID, data).then((res) => {
                navigate('/address/' + idAddress + '/workbook')
                setReload(true)
            })
        };

        function HandleHeating(heatingID) {
            const h = heatings.find(h => h.HeatingID === parseInt(heatingID))

            setHeating(h)
            $("#inputDate").val(h?.HeatingDate)
        }

        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

        return <>
            <h4 className="section-title-2 mt-4">Effectuer arrêt/MER chauffage</h4>

            {heatings.length > 0 ? <>
                <div className="mb-3">
                    <label htmlFor="" className="form-label">Selectionner l'action</label>
                    <select name="" id="" className="form-select" onChange={e => HandleHeating(e.target.value)}>
                        {heatings && heatings.map(h => {
                            return <option key={h.HeatingID} value={h.HeatingID}>{h.HeatingAction == "Start" ? "Mise en route, " : "Arrêt, "} {dateFormat(h.HeatingDate, "dd/MM/yyyy")}</option>
                        })}
                    </select>
                </div>

                <div className="mb-3">
                    <label htmlFor="inputDate" className="form-label">Date</label>
                    <input type="datetime-local" id="inputDate" className="form-control" defaultValue={now.toISOString().slice(0, 16)} />
                </div>

                <div className="mb-3">
                    <label className="form-label" htmlFor="ObservationNote">Commentaire</label>
                    <textarea className="mb-3" name="ObservationNote" id="ObservationNote" rows={4}></textarea>
                </div>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <button className="btn btn-primary" onClick={() => SubmitHeating()}>
                        <i className="bi bi-check me-1"></i> Valider
                    </button>
                </div>
            </>
                :
                <p className="">Il n'y a aucun arrêt ou mise en route du chauffage de prévu</p>
            }
        </>
    }

    return (
        <section className="container-fluid">
            <div className="row justify-content-center">

                <div className="col-sm-8 col-md-6 col-xl-5 px-0 pe-sm-3">
                    <section className="body-container">

                        <header className="mb-3">
                            <h2 className="section-title mb-0">Ajouter au carnet de chaufferie</h2>
                            <Link className="link-nav mb-0" to={LinkBack()}>{'<'} Retour</Link>
                        </header>

                        <section className="body">


                            <div className="mb-3">
                                <label htmlFor="" className="form-label">Choisir prestation</label>
                                <select name="" id="" className="form-select" onChange={(e) => setService(e.target.value)}>
                                    <option value={SERVICE.SURVEY}>{SERVICE.SURVEY}</option>
                                    <option value={SERVICE.HEATING}>{SERVICE.HEATING}</option>
                                </select>
                            </div>

                            <WorkbookAddController service={service} />
                        </section>
                    </section>

                </div>

            </div>
        </section>
    )
}
