import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { flexRender, } from '@tanstack/react-table'
import { getToken } from '../../../../useToken'
import { IInstallation, IMaterial, IApartment, IMaterialClass } from '../../../common/types'
import { HeaderRow, DebouncedInput, ButtonSubmit, Filter, Header } from '../../../common/common'
import { GROUP, ICONS, INSTALLATION_STATUS, MATERIAL_CLASS_ID } from '../../../common/constants'

import { MaterialTable } from '../../material/material'

let today = new Date();
let year = today.getFullYear()

const validationSchema = yup.object({
    AddressID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    MaterialID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    ApartmentID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    Maintenance: yup.mixed().transform((value) => (value ? 1 : 0)).required("Ce champ est obligatoire"),
    Note: yup.string().nullable(),
    PowerInstalled: yup.string().nullable(),
    YearInstalled: yup.number().transform((value) => (isNaN(value) ? undefined : value)).min(year - 50).max(year + 1).nullable(),
    Scaling: yup.string().nullable(),
    Projection: yup.string().required("Ce champ est obligatoire"),
    Status: yup.string().required("Ce champ est obligatoire"),
    DateReview: yup.string().nullable(),
    Number: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    EndWarranty: yup.string().nullable()

}).required();

const initialValues = {
    AddressID: 0,
    MaterialID: null,
    ApartmentID: null,
    Maintenance: 0,
    Note: null,
    PowerInstalled: null,
    YearInstalled: null,
    Scaling: null,
    Projection: "0",
    Status: null,
    DateReview: null,
    Number: null,
    EndWarranty: null,
    ActivityUserID: null
}



export default function AddressInstallAdd() {
    let navigate = useNavigate()
    const params = useParams()
    const token = getToken()

    const idAddress = params.idAddress

    const { register, control, formState: { errors }, handleSubmit, getValues, setValue, watch } = useForm<IInstallation>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [material, setMaterial] = useState<IMaterial>()

    useEffect(() => {
        if (idAddress != undefined) {
            setValue("AddressID", parseInt(idAddress))
        }
        /* reqGet('apartment/address/' + idAddress).then(res => setApartments(res)}) */

    }, [])

    useEffect(() => {
        if (material)
            setValue("MaterialID", material.MaterialID)

    }, [material])

    const onSubmit: SubmitHandler<IInstallation> = data => {
        reqPost('installation', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate('/address/' + idAddress + '/installation')
        })
    }

    return <div className="body-container">

        {HeaderRow(
            <h3 className="section-title">Ajouter une installation</h3>,
            <button className="btn link-nav" onClick={() => navigate(-1)}>{'< Retour'}</button>
        )}

        <div className="body">

            <MaterialSelection setMaterial={setMaterial} errors={errors?.MaterialID} />

            <div className="row justify-content-center">
                {
                    /*
                    apartments.length > 0 ?
                        <div className="mb-3">
                            <label htmlFor="apartment" className="form-label">Appartements </label>
                            <select name="select-management" className="form-select" id="apartment" onChange={() => { handleChange() }}>
                                {apartments.map((m, k) => {
                                    return <option key={k} value={m.ApartmentID}>Appartement {m.ApartmentNumber}</option>;
                                })}
                            </select>
                        </div>
                        : ''
                            */
                }

                <div className="col-xxl-8 pb-3">

                    <div className="row mb-3">
                        <div className="col-3">

                            <label className="form-label">Etat *</label>
                            <select className={"form-select " + (errors?.Status ? "is-invalid" : "")} {...register("Status")}>
                                <option value="" hidden></option>
                                <option value={INSTALLATION_STATUS.GOOD}>Bon</option>
                                <option value={INSTALLATION_STATUS.MEDIUM}>Moyen</option>
                                <option value={INSTALLATION_STATUS.BAD}>Mauvais</option>
                            </select>

                            {errors.Status?.message && <p className="mt-2 text-danger">{errors.Status?.message}</p>}

                        </div>

                        <div className="col">

                            <label htmlFor="yearInstalled" className="form-label">Année installé</label>
                            <input type="number" className={"form-control " + (errors?.YearInstalled ? "is-invalid" : "")} min={year - 50} max={year + 1} id="yearInstalled" {...register("YearInstalled")} />

                            {errors.YearInstalled?.message && <p className="mt-2 text-danger">{errors.YearInstalled?.message}</p>}

                        </div>


                        <div className="col">


                            <label className="form-label mb-2" htmlFor="endWarranty">Fin de garantie</label>

                            <input type="date" className={"form-control " + (errors?.EndWarranty ? "is-invalid" : "")} {...register("EndWarranty")} />

                            {errors.EndWarranty?.message && <p className="mt-2 text-danger">{errors.EndWarranty?.message}</p>}

                        </div>
                    </div>

                    <div className="row mb-3">
                        {(material?.MaterialClassID == MATERIAL_CLASS_ID.BURNER || material?.MaterialClassID == MATERIAL_CLASS_ID.GENERATOR) &&
                            <div className="col-6">
                                <label htmlFor="powerInstalled" className="form-label">Puissance installée </label>
                                <input type="text" className={"form-control " + (errors?.PowerInstalled ? "is-invalid" : "")} id="powerInstalled" {...register("PowerInstalled")} />

                                {errors.PowerInstalled?.message && <p className="mt-2 text-danger">{errors.PowerInstalled?.message}</p>}
                            </div>
                        }

                        {(material?.MaterialClassID == MATERIAL_CLASS_ID.SOLAR) &&
                            <div className="col-6">

                                <label htmlFor="number" className="form-label">Nombre</label>
                                <input type="number" className={"form-control " + (errors?.Number ? "is-invalid" : "")} id="scaling" {...register("Number")} />

                                {errors.Number?.message && <p className="mt-2 text-danger">{errors.Number?.message}</p>}

                            </div>
                        }

                    </div>


                    <div className="mb-3">

                        <label htmlFor="note" className="form-label">Remarque</label>

                        <textarea className={(errors?.Note ? "is-invalid" : "")} id="note" rows={4} {...register("Note")}></textarea>

                        {errors.Note?.message && <p className="mt-2 text-danger">{errors.Note?.message}</p>}

                    </div>

                    <div className="text-end">
                        <p><small>Les champs avec une * sont obligatoire</small></p>
                        <ButtonSubmit onClick={handleSubmit(onSubmit)} />
                    </div>

                </div>



            </div>
        </div >
    </div >
}




export function MaterialSelection({ material, setMaterial, errors }: { material?, setMaterial, errors }) {
    const [selectedMaterial, setSelectedMaterial] = useState<IMaterial>()
    const [chooseMaterial, setChooseMaterial] = useState<boolean>(false)

    const table = MaterialTable()
    const [materialClass, setMaterialClass] = useState<Array<IMaterialClass>>([])

    useEffect(() => {
        reqGet("materialclass").then(res => setMaterialClass(res))
    }, [])
    useEffect(() => {
        if (material) {
            setSelectedMaterial(material.MaterialID)
            $("#inputSelectedMaterial").val((material?.Type ?? "") + " " + (material?.Model ?? "") + " " + (material?.Brand ?? ""))
        }
    }, [material])

    function handleSelection(material, e) {
        $('#table tr.active').removeClass('active')
        e.currentTarget.classList.add('active')

        setSelectedMaterial(material)
    }

    function validateSelection() {
        if (selectedMaterial) {
            $("#inputSelectedMaterial").val((selectedMaterial?.Type ?? "") + " " + (selectedMaterial?.Model ?? "") + " " + (selectedMaterial?.Brand ?? ""))

            setChooseMaterial(false)

            setMaterial(selectedMaterial)
        }
    }

    return <div className="container-fluid">

        <div className="row justify-content-center">

            <div className="col-xxl-8 px-0">
                <label htmlFor="inputSelectedMaterial ps-0" className="form-label">Matériel séléctionné *</label>
                <div className="d-flex align-items-start mb-3 px-3">
                    <input type="text" className={"form-control mb-0 me-3 " + (errors ? "is-invalid" : "")} id="inputSelectedMaterial" disabled />

                    {!chooseMaterial &&
                        <button className="btn btn-outline text-nowrap" onClick={() => setChooseMaterial(true)}>Choisir le matériel <i className="bi bi-caret-down-fill ms-2"></i></button>
                    }

                </div>

                {errors?.message && <p className="mb-3 text-danger">{errors?.message}</p>}



                {chooseMaterial &&
                    <div className="mb-3" style={{ height: "650px" }}>

                        <div className="card card-100 card-border">

                            <header className="card-header row mb-3">
                                <div className="col-6 col-xl-3 order-1">
                                    <h5 className="card-title">Liste du matériel</h5>
                                </div>

                                <div className="col-xl-6 order-3 order-xl-2 mt-3 mt-xl-0">
                                    <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche dans tout le matériel" />
                                </div>

                                <div className="col-6 col-xl-3 order-2 order-xl-3 text-end">
                                    <button className="btn btn-outline me-3" onClick={() => setChooseMaterial(false)}>
                                        <i className={ICONS.CANCEL + " me-2"}></i>Annuler</button>
                                    <button className="btn btn-add" onClick={() => validateSelection()}><i className={ICONS.ADD + " me-2"}></i>Valider</button>
                                </div>
                            </header>


                            <nav className='mb-3'>

                                <div className="row">
                                    <div className="col">
                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Class")?.getToggleSortingHandler() }}>
                                            Catégorie
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Class")?.getIsSorted() as string] ?? null}
                                        </h6>

                                        <div className="d-flex flex-direction-column mb-2">
                                            <select name="" id="" className="form-sm form-select" onChange={e => table.getColumn("Class")?.setFilterValue(e.target.value)}>
                                                <option value="">Tout</option>
                                                {materialClass?.map(mc => {
                                                    return <option key={mc.MaterialClassID} value={mc.Class}>{mc?.Class}</option>
                                                })}
                                            </select>
                                        </div>

                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Type")?.getToggleSortingHandler() }}>
                                            Type
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Type")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column">
                                            <Filter column={table.getColumn("Type")} table={table} />
                                        </div>
                                    </div>

                                    <div className="col">
                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Model")?.getToggleSortingHandler() }}>
                                            Modèle
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Model")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column mb-2">
                                            <Filter column={table.getColumn("Model")} table={table} />
                                        </div>

                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Brand")?.getToggleSortingHandler() }}>
                                            Marque
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Brand")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column">
                                            <Filter column={table.getColumn("Brand")} table={table} />
                                        </div>
                                    </div>

                                    <div className='col'>
                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Capacity")?.getToggleSortingHandler() }}>
                                            Capacité (L)
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Capacity")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column mb-2">
                                            <Filter column={table.getColumn("Capacity")} table={table} />
                                        </div>

                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("PowerRange")?.getToggleSortingHandler() }}>
                                            Puissance
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("PowerRange")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column">
                                            <Filter column={table.getColumn("PowerRange")} table={table} />
                                        </div>
                                    </div>

                                    <div className="col">
                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("MaterialYearProduction")?.getToggleSortingHandler() }}>
                                            Année production
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("MaterialYearProduction")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column mb-2">
                                            <Filter column={table.getColumn("MaterialYearProduction")} table={table} />
                                        </div>

                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("MaterialYearEndProduction")?.getToggleSortingHandler() }}>
                                            Année fin production
                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("MaterialYearEndProduction")?.getIsSorted() as string] ?? null}
                                        </h6>
                                        <div className="d-flex flex-direction-column">
                                            <Filter column={table.getColumn("MaterialYearEndProduction")} table={table} />
                                        </div>
                                    </div>

                                </div>
                            </nav>


                            <section className="card-body border rounded">

                                <div className="list list-hover">
                                    {table.getRowModel().rows.map((row, key) => {
                                        const install: IInstallation = row.original
                                        return (

                                            <div className='list-item' key={row.id} onClick={(e) => handleSelection(row.original, e)}>

                                                <section className='list-item-data'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <h6>{install.Class}</h6>
                                                            <p className="mb-0">{install.Type}</p>
                                                        </div>

                                                        <div className='col'>
                                                            <h6>{install.Model}</h6>
                                                            <p className="mb-0">{install.Brand}</p>
                                                        </div>

                                                        <div className="col">

                                                            {(install.MaterialClassID == MATERIAL_CLASS_ID.BOILER ||
                                                                install.MaterialClassID == MATERIAL_CLASS_ID.TANK ||
                                                                install.MaterialClassID == MATERIAL_CLASS_ID.EXPANSION) &&
                                                                <>
                                                                    <h6>Capacité (L)</h6>
                                                                    <p className="paragraph mb-0">{install?.Capacity}</p>
                                                                </>}

                                                            {(install.MaterialClassID == MATERIAL_CLASS_ID.BURNER ||
                                                                install.MaterialClassID == MATERIAL_CLASS_ID.GENERATOR) &&
                                                                <>
                                                                    <h6>Puissance</h6>
                                                                    <p className="paragraph mb-0">{install?.PowerRange}</p>
                                                                </>}

                                                            {install.MaterialClassID == MATERIAL_CLASS_ID.SOLAR &&
                                                                <>
                                                                    <h6 className="">Surface</h6>
                                                                    <p className="paragraph mb-0">{install?.Surface}</p>
                                                                </>}

                                                            {install.MaterialClassID == MATERIAL_CLASS_ID.PUMP &&
                                                                <>
                                                                    <h6 className="">Alimentation</h6>
                                                                    <p className="paragraph mb-0">{install?.Alimentation}</p>
                                                                </>}
                                                        </div>

                                                        <div className='col'>
                                                            <h6>Date production</h6>
                                                            <p className="mb-0">{install.MaterialYearProduction} {install.MaterialYearEndProduction && " - " + install.MaterialYearEndProduction}</p>
                                                        </div>
                                                    </div>
                                                </section>

                                            </div>
                                        )

                                    })}
                                </div>
                            </section>

                            <footer className="card-footer">
                                <div className="d-flex justify-content-center align-items-center pagination">
                                    <button className="btn btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                                        {'<<'}
                                    </button>
                                    <button className="btn btn-outline me-3" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                                        {'<'}
                                    </button>
                                    <p className="mb-0 me-3">
                                        {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                                    </p>
                                    <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                                        {'>'}
                                    </button>
                                    <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                                        {'>>'}
                                    </button>
                                </div>
                            </footer>

                        </div>

                    </div>
                }
            </div>

        </div>

    </div>
}