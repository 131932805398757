import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { fuzzyFilter, Filter, ServiceBadge } from "../../common/common";
import { reqGet } from "../../../service/apiRequest";
import { IAddress, IInstallation, IService } from "../../common/types"

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef
} from '@tanstack/react-table'
import { SERVICE } from "../../common/constants";

export default function Installation() {

    const [services, setServices] = useState<Array<IService>>([])
    const [dataBackup, setDataBackup] = useState<Array<IInstallation>>([])
    const [data, setData] = useState<Array<IInstallation>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const columns = useMemo<ColumnDef<IInstallation, any>[]>(
        () => [
            {
                accessorFn: row => row.AddressLine + " " + row.AddressComplementary,
                id: 'AddressLine',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Class,
                id: 'Class',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Type,
                id: 'Type',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.YearInstalled,
                id: 'YearInstalled',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.MaterialYearProduction + " " + row.MaterialYearEndProduction,
                id: 'MaterialProduction',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Model,
                id: 'Model',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Brand,
                id: 'Brand',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Service,
                id: 'Service',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.PowerInstalled,
                id: 'PowerInstalled',
                cell: info => info.getValue()
            }
        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        table.setPageSize(100)
        table.setSorting([{ id: "YearInstalled", desc: true }])
        reqGet("service").then(res => { setServices(res) })
        reqGet("installation").then((res) => { setData(res); setDataBackup(res) })
    }, [])

    function FilterService(service: string) {
        if (service) {
            setData(dataBackup.filter(d => d?.Service?.some(s => s.Service == service)))
        } else {
            setData(dataBackup)
        }
    }

    return <section className="body-container h-100 content">

        <section className="header">
            <h3 className="section-title">Installations</h3>
        </section>

        <section className="body">

            <div className="card card-fill card-flush">
                <section className="card-body">

                    <div className="overflow-x-auto">
                        <table className="table table-sticky" id="table" style={{ minWidth: "700px" }}>

                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id} className="align-bottom">

                                        <th style={{ width: "220px" }}>
                                            <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("AddressLine")?.getToggleSortingHandler() }}>
                                                Adresse
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("AddressLine")?.getIsSorted() as string] ?? null}
                                            </h6>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("AddressLine")} table={table} />
                                            </div>
                                        </th>


                                        <th style={{ width: "220px" }}>
                                            <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Class")?.getToggleSortingHandler() }}>
                                                Catégorie
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Class")?.getIsSorted() as string] ?? null}
                                            </h6>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("Class")} table={table} />
                                            </div>

                                            <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Type")?.getToggleSortingHandler() }}>
                                                Type
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Type")?.getIsSorted() as string] ?? null}
                                            </h6>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("Type")} table={table} />
                                            </div>
                                        </th>

                                        <th style={{ width: "180px" }}>
                                            <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Model")?.getToggleSortingHandler() }}>
                                                Modèle
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Model")?.getIsSorted() as string] ?? null}
                                            </h6>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("Model")} table={table} />
                                            </div>

                                            <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Brand")?.getToggleSortingHandler() }}>
                                                Marque
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Brand")?.getIsSorted() as string] ?? null}
                                            </h6>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("Brand")} table={table} />
                                            </div>
                                        </th>

                                        <th style={{ width: "180px" }}>
                                            <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("YearInstalled")?.getToggleSortingHandler() }}>
                                                Date installé
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("YearInstalled")?.getIsSorted() as string] ?? null}
                                            </h6>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("YearInstalled")} table={table} />
                                            </div>
                                        </th>

                                        <th style={{ width: "180px" }}>
                                            <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("MaterialProduction")?.getToggleSortingHandler() }}>
                                                Date production
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("MaterialProduction")?.getIsSorted() as string] ?? null}
                                            </h6>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("MaterialProduction")} table={table} />
                                            </div>
                                        </th>

                                        <th style={{ width: "180px" }}>
                                            <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("PowerInstalled")?.getToggleSortingHandler() }}>
                                                Puissance
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("PowerInstalled")?.getIsSorted() as string] ?? null}
                                            </h6>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("PowerInstalled")} table={table} />
                                            </div>
                                        </th>


                                        <th style={{ minWidth: "180px" }} className="align-bottom">
                                            <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Service")?.getToggleSortingHandler() }}>
                                                Services
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Tires")?.getIsSorted() as string] ?? null}
                                            </h6>

                                            <select name="" id="" className="form-select form-sm mb-3" style={{ width: "200px" }} onChange={(e) => FilterService(e.target.value)}>
                                                <option value="">Tout</option>
                                                {services.filter(s => s.Service == SERVICE.SCALING || s.Service == SERVICE.MAINTEANANCE || s.Service == SERVICE.SUBCONTRACTOR || s.Service == SERVICE.CLEANING).map(s => {
                                                    return <option key={s.ServiceID} value={s.Service}>{s.Service}</option>
                                                })}
                                            </select>
                                        </th>


                                    </tr>
                                ))}
                            </thead>

                            <tbody>
                                {table && table?.getRowModel().rows.map(row => {
                                    const installation = row.original
                                    return (
                                        <tr key={row.id}>
                                            <td>
                                                <h6 className="item-title">{installation.AddressLine}</h6>
                                                <p className="mb-0">{installation.AddressComplementary}</p>
                                                <Link to={"/address/" + installation.AddressID} className="link-nav">Voir adresse {">"}</Link>
                                            </td>
                                            <td>
                                                <h6 className="item-title">{installation.Class || installation.IClass}</h6>
                                                <p className="mb-0">{installation.Type || installation.IType}</p>
                                            </td>
                                            <td>
                                                <h6 className="item-title">{installation.Model || installation.IModel}</h6>
                                                <p className="mb-0">{installation.Brand || installation.IBrand}</p>
                                            </td>
                                            <td>
                                                <p className="mb-0">{installation.YearInstalled}</p>
                                            </td>
                                            <td>
                                                <p className="mb-0">{installation.MaterialYearProduction} {(installation.MaterialYearProduction && installation.MaterialYearProduction) && "-"} {installation.MaterialYearEndProduction}</p>
                                            </td>
                                            <td>
                                                <p className="mb-0">{installation.PowerInstalled}</p>
                                            </td>
                                            <td>
                                                {installation.Service.map((s: IService) => <ServiceBadge service={s.Service} firstLetter={true} className="me-2" />)}
                                            </td>

                                        </tr>
                                    )

                                })}
                            </tbody>

                        </table>
                    </div>

                </section>

                <footer className="card-footer d-flex justify-content-between align-items-center">

                    <nav className="pagination">
                        <button className="btn btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                            {'<<'}
                        </button>
                        <button className="btn btn-outline me-3" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                            {'<'}
                        </button>

                        <p className="mb-0 me-3">
                            {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                        </p>

                        <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                            {'>'}
                        </button>
                        <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                            {'>>'}
                        </button>

                        <div className="ms-3">
                            <select className="form-select form-sm" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                                {[100, 200, 500].map(pageSize => (

                                    <option key={pageSize} value={pageSize}>
                                        Afficher {pageSize}
                                    </option>

                                ))}
                            </select>
                        </div>
                    </nav>


                </footer>

            </div>

        </section >

    </section >
}