import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link, useLocation, useOutletContext } from 'react-router-dom'
import $ from 'jquery'

import { Header, dateFormat } from "../../common/common"
import { reqGet, reqPost, reqPut } from '../../../service/apiRequest'
import { IAddress, IHeating, IUser } from '../../common/types'
import { SERVICE } from '../../common/constants';
import { getToken } from '../../../useToken'

interface IHeatingObservation extends IHeating, IAddress {
    AddressServiceNote: string,
    UserID: number,
    ObservationNote: string,
    ObservationDate: string,
    ObservationFirstName: string,
    ObservationName: string,
    ZoneFirstName: string,
    ZoneName: string
}


export default function HeatingAdd({ setReload }) {
    const token = getToken()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const idHeating = params.idHeating

    const [heating, setHeating] = useState<IHeatingObservation>()
    const [users, setUsers] = useState<Array<IUser>>()

    useEffect(() => {
        reqGet('heating/' + idHeating).then((res) => { setHeating(res) })
        reqGet("user").then(res => setUsers(res))
    }, [idHeating])

    function SubmitHeating() {
        var data = {
            ActivityUserID: token.UserID,
            HeatingID: idHeating,
            WorkbookID: heating?.WorkbookID,
            Note: $("#ObservationNote").val(),
            UserID: $("#inputUser").val(),
            Date: $("#inputDate").val()
        }

        reqPost('observation/heating/' + idHeating, data).then((res) => {
            navigate('/heating/' + idHeating)
            setReload(true)
        })
    }

    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

    return (
        <>

            <header className="mb-3">
                <h2 className="section-title-2 mb-0">Modifier {heating?.HeatingAction == "Start" ? "la mise en route" : "l'arrêt"}</h2>
                <Link className="link-nav mb-0" to={"/heating/" + idHeating}>{'<'} Retour</Link>
            </header>

            <section>

                <div className="row row-cols-2 mb-3">
                    <div className="col">
                        <label htmlFor="inputDate" className="form-label">Date</label>
                        <input type="datetime-local" id="inputDate" className="form-control" defaultValue={now.toISOString().slice(0, 16)} />
                    </div>
                    <div className="col">
                        <label htmlFor="inputUser" className="form-label">Technicien</label>
                        <select id="inputUser" className="form-select" defaultValue={heating?.UserID}>
                            {users && users.filter(u => u.Role.includes("Technicien")).map(u => {
                                return <option key={u.UserID} value={u.UserID}>{u.FirstName} {u.Name}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className="mb-3">
                    <label className="form-label" htmlFor="ObservationNote">Commentaire</label>
                    <textarea className="mb-3" name="ObservationNote" id="ObservationNote" rows={4}></textarea>
                </div>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <button className="btn btn-primary" onClick={() => SubmitHeating()}>
                        <i className="bi bi-check me-1"></i> Valider
                    </button>
                </div>
            </section>
        </>
    )
}
