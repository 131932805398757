import React, { useEffect, useState } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { reqGet } from '../../../service/apiRequest'
import $ from 'jquery'
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import PDF, { STYLES } from "./pdf"

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { DateToday, dateFormat } from '../../common/common'
import { IAddress, IInstallation, IMaterialClass } from '../../common/types'

interface IInstallsProps {
    filename: string,
    date: string | null,
    sre: string | null,
    nbApartement: string | null,
    note: string | null,

    installation: Array<IInstallation>
}

const validationSchema = yup.object({
    filename: yup.string().required("Ce champ est obligatoire"),
    date: yup.string().transform((val, originalValue) => dateFormat(originalValue, 'dd.MM.yyyy')).nullable(),
    sre: yup.string().nullable(),
    nbApartement: yup.string().nullable()

}).required();


export default function PdfTest() {
    const navigate = useNavigate()
    const params = useParams()

    //const idAddress = params.idAddress
    const idAddress = "6018"

    const [address, setAddress] = useState<IAddress>()
    const [installs, setInstalls] = useState<Array<IInstallation>>()
    const [materialClass, setMaterialClass] = useState<Array<IMaterialClass>>()

    const [pdf, setPdf] = useState({ cholet: "cholet" })

    const { register, control, formState: { errors }, handleSubmit, setValue, getValues } = useForm<IInstallsProps>({
        mode: "onTouched",
        resolver: yupResolver(validationSchema)
    });

    const { fields } = useFieldArray({
        control,
        name: "installation",
    });



    useEffect(() => {

        if (idAddress && parseInt(idAddress) > 0) {

            const pAddress = reqGet("address/" + idAddress).then(res => res)
            const pInstalls = reqGet("installation/address/" + idAddress).then(res => res)
            const pMatericalClass = reqGet("materialclass").then(res => res)

            Promise.all([pAddress, pInstalls, pMatericalClass]).then(values => {

                setAddress(values[0])
                setInstalls(values[1])
                setMaterialClass(values[2])

                setValue('filename', "rapport_technique_" + values[0].AddressLine.toLowerCase().trim().replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "_"))
                setValue('installation', values[1].sort((a, b) => (a.MaterialClassID > b.MaterialClassID) ? 1 : ((b.MaterialClassID > a.MaterialClassID) ? -1 : 0)))
                setValue('note', values[0].Note)

                let pdfTechnicals = pdfAddressTechnicals(getValues(), values[0], getValues("installation"), values[2])

                setPdf(pdfTechnicals)

            })
        }

    }, [])



    //pdfAddressTechnicals(getValues(), address, getValues("installation"), materialClass)

    if (pdf) {
        return PDF(pdf, Form(register, fields))
    } else {
        return <></>
    }
}


function Form(register, fields) {
    return <>
        <div className="card">
            <div className="card-body">

                <label htmlFor="date" className="form-label">Date</label>
                <input className="form-control" type="date" defaultValue={DateToday()} {...register("date")} />

            </div>
        </div>


        <div className="card">
            <div className="card-body">

                <div className="row">

                    <div className="col-6">
                        <label htmlFor="sre" className="form-label">SRE</label>
                        <input type="text" className="form-control"  {...register("sre")} />
                    </div>

                    <div className="col-6">
                        <label htmlFor="nbApartement" className="form-label">Nb appartements</label>
                        <input type="text" className="form-control"  {...register("nbApartement")} />
                    </div>

                </div>

            </div>
        </div>


        <div className="card">
            <div className="card-body">


                <label htmlFor="sre" className="form-label">Remarques général</label>
                <textarea className="form-control" rows={7} {...register("note")}></textarea>

            </div>
        </div>


        <div className="card">
            <div className="card-body">

                <div className="row">

                    <div className="col">
                        <label htmlFor="nbApartement" className="form-label">Installation</label>
                    </div>

                    <div className="col-3 text-end">
                        <label htmlFor="nbApartement" className="form-label">Prévision</label>
                    </div>

                </div>

                {fields.map((field, index) => {

                    return <div key={field.InstallationID} className="input-group mb-3">
                        <div className="input-group-text w-75 text-truncate d-block text-start">
                            <p className={"mb-" + (field?.Note ? "1" : "0")}>
                                {field.MaterialID ?
                                    (field.Type ?? "") + " " + (field.Brand ?? "") + " " + (field.Model ?? "")
                                    : (field.IType ?? "") + " " + (field?.IBrand ?? "") + " " + (field.IModel ?? "")}
                            </p>

                            {field.Note && <p className="text-muted fst-italic mb-0">{field.Note}</p>}
                        </div>

                        <input type="text" className="form-control text-end" {...register(`installation.${index}.Projection`)} />
                    </div>
                })}

            </div>
        </div>
    </>
}




function pdfAddressTechnicals(props, address, installs, materialClass) {

    var docDefinition = {
        info: {
            title: "rapport_technique_chemin_de_chapelly_22" + props?.date,
            author: "multitherme",
            subject: "rapport_technique",
            keywords: ""
        },

        footer: (currentPage, pageCount) => {
            return {
                columns: [
                    {
                        width: "auto",
                        text: "Rue Ancienne 84A - Case Postale - 1227 Carouge - Tel. 022 300 16 77 - Fax 022 300 16 90",
                        style: { fontSize: 8 },
                        margin: [42, 0, 0, 0]
                    },
                    {
                        text: currentPage.toString() + ' sur ' + pageCount,
                        style: { fontSize: 8 },
                        alignment: 'right',
                        margin: [0, 0, 42, 0]
                    }
                ]

            }
        },
        content: [
            {
                columns: [
                    {
                        //image: await getBase64ImageFromURL("./media/images/multitherme/mt_logo_text.jpg")
                    },
                    {
                        width: 380,
                        text: [
                            {
                                text: address.AddressLine + ", " + address.ZipCode + " " + address.City + "\n",
                                style: STYLES.header1
                            },
                            {
                                text: "Rapport technique du " + (props?.date ?? ""),
                                style: STYLES.header2
                            },
                        ]
                    }
                ]
            },

            { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 10] },

            {
                columns: [
                    {
                        text: [
                            (address.ManagementName ? {
                                text: "Régie :     " + address.ManagementName + "\n",
                                lineHeight: 1.5
                            } : ""),
                            (props?.sre ? {
                                text: "SRE :     " + props.sre + "\n",
                                lineHeight: 1.5
                            } : ""),
                            (props?.nbApartement ? {
                                text: "Nombre d'appartements :     " + props.nbApartement + "\n"
                            } : ""),
                        ]
                    }
                ]
            },

            { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 10] },

            { text: '\n Memo', style: STYLES.bold },
            { text: '0.         Matériel en bon état', style: STYLES.list },
            { text: '1.         Prévoir le remplacement à long terme (dans les 10 ans à venir)', style: STYLES.list },
            { text: '2.         Prévoir le remplacement à moyen terme (dans les 5 ans à venir)', style: STYLES.list },
            { text: '3.         Prévoir le remplacement à court terme (dans l\'année)', style: STYLES.list },
            { text: 'C.E.     Coût estimatif du remplacement', style: STYLES.list },

            { text: props?.note ? '\n Remarques' : "", style: STYLES.bold },
            { text: props.note },

            { text: '\n' },

            TableInstallation(installs, materialClass)

        ],
        styles: STYLES
    }

    return docDefinition
}


function TableInstallation(installs, materialClass) {
    var installTable = [{}]

    materialClass.forEach((mc) => {

        // Filtering installs by class, taking account of those which doesn't have any material ID
        let IFiltered = installs.filter(i => i.MaterialID ? i.MaterialClassID == mc.MaterialClassID : i.IMaterialClassID == mc.MaterialClassID)

        // if there are any installation
        if (IFiltered.length > 0) {

            installTable.push(

                // class header
                { text: '\n ' + mc.Class + 's', style: STYLES.header1 },

                ArrayInstallation(IFiltered)
            )
        }
    })



    return installTable
}

function ArrayInstallation(installation) {

    const header: Array<{ text: string, style: {} }> =
        [
            { text: 'Description', style: { bold: true } },
            { text: 'Type', style: { bold: true } },
            { text: 'Marque', style: { bold: true } },
            { text: 'Modèle', style: { bold: true } },
            { text: "Année d'installation", style: { bold: true } },
            { text: "Prévision", style: { bold: true } }
        ]

    var array: Array<{}> = []
    var columns: Array<string> = []

    array.push(header)

    installation.forEach((i, k) => {
        if (i.MaterialID)
            columns = ['Note', 'Type', 'Brand', "Model", "YearInstalled", "Projection"]
        else
            columns = ['Note', 'IType', 'IBrand', "IModel", "YearInstalled", "Projection"]

        var install_row: string[] = []

        columns.forEach(col => {
            install_row.push(i[col])
        })

        array.push(install_row)

    })

    return {
        table: {
            dontBreakRows: true,
            headerRows: 1,
            body: array
        }
    };
}


