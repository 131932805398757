import React, { useEffect, useState, useMemo } from 'react'
import { Link, NavLink, useLocation, Outlet, useParams } from 'react-router-dom'
import $ from 'jquery'

import { reqGet } from "../../../../service/apiRequest";
import { PrivateComponent, fuzzyFilter, PrivateLinkAdd, ProgressColor, DebouncedInput, Header, ResolveIcon } from '../../../common/common'
import { LAYOUT, DEFAULT_IMG, GROUP, PERMISSION, ICONS } from '../../../common/constants'
import { IBrand, IProduct, ISupplier } from '../../../common/types'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef
} from '@tanstack/react-table'

export default function Piece({ reload, setReload }) {
    const location = useLocation()

    const table = PieceTable(reload, setReload)

    const [suppliers, setSuppliers] = useState<Array<ISupplier>>([])
    const [brands, setBrands] = useState<Array<IBrand>>([])

    useEffect(() => {
        reqGet("supplier").then(res => setSuppliers(res))
        reqGet("brand").then(res => setBrands(res))
    }, [])

    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/piece") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    function FilterBrand(brand: string) {
        table.getColumn("ProductBrand")?.setFilterValue(brand)
    }

    function FilterSupplier(supplier: string) {
        table.getColumn("ProductSupplier")?.setFilterValue(supplier)
    }


    function FilterStock(inStock: boolean) {

        //table.getColumn("StorageID")?.setFilterValue(0)
    }

    return <div className="content h-100">
        <div className="row h-100">

            <div className={display + " col-md-6 col-lg-7 col-xl-8 col-h-fill px-0 pe-lg-3"}>
                <div className="body-container">

                    {Header(
                        <h2 className="section-title">Pièces</h2>,
                        undefined,
                        <div>
                            {PrivateLinkAdd("add", GROUP.crud_sav, PERMISSION.product)}
                        </div>
                    )}


                    <section className='container-fluid'>
                        <div className="row mb-3">

                            <div className="col col-lg-3">
                                <label className="form-label mb-1">Marque</label>
                                <select className="form-select" onChange={(e) => FilterBrand(e.target.value)}>
                                    <option value="">Tout</option>

                                    {brands.map((b) => {
                                        return <option value={b.Name} key={b.BrandID}>{b.Name}</option>
                                    })}
                                </select>
                            </div>

                            <div className="col col-lg-3">
                                <label className="form-label mb-1">Fournisseur</label>
                                <select className="form-select" onChange={(e) => FilterSupplier(e.target.value)}>
                                    <option value="">Tout</option>

                                    {suppliers.map((s) => {
                                        return <option value={s.Name} key={s.SupplierID}>{s.Name}</option>
                                    })}
                                </select>
                            </div>

                            {/*
                            <div className="col col-lg-2 h-100">
                                <label className="form-label mb-1">En stock</label>
                                <div className="form-check form-switch mt-2 mb-0">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={(e) => FilterStock(e.target.checked)} />
                                </div>
                            </div>
                            */}

                            <div className="col-lg offset-lg-1 mt-3 mt-lg-0 h-lg-100 d-flex align-items-end">
                                <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche" />
                            </div>

                        </div>
                    </section>



                    <section className="body container-fluid">

                        <div className="list list-hover">

                            {table.getRowModel().rows.map(row => {
                                return <NavLink to={row.original.ProductID.toString()} key={row.original.ProductID} className="list-item list-item-sm" >
                                    <ProductItem product={row.original} />
                                </NavLink>
                            })}

                        </div>
                    </section>

                    <footer className="container-fluid mt-3">
                        <div className="row mx-0 pagination">

                            <div className="col-8 col-lg-4 px-0 text-start order-2 order-lg-1">
                                <button className="btn btn-outline me-2" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                                    {'<<'}
                                </button>
                                <button className="btn btn-outline me-2" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                                    {'<'}
                                </button>

                                <p className="d-inline mb-0 me-2">
                                    {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                                </p>

                                <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                                    {'>'}
                                </button>
                                <button className="btn btn-outline me-2" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                                    {'>>'}
                                </button>
                            </div>

                            <div className="col-12 col-lg-4 text-center order-1 order-lg-2 mb-3 mb-lg-0">
                                <p className="text mb-0">
                                    {table.getCoreRowModel().rows.length} pièces - {table.getCoreRowModel().rows.reduce((accumulator, row) => {
                                        return accumulator + parseInt(row.original.Quantity ?? "0");
                                    }, 0)} en stock
                                </p>
                            </div>

                            <div className="col col-lg-2 offset-lg-2 pe-0 text-end order-3">
                                <select className="form-select float-end" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                                    {[50, 100, 200].map(pageSize => (

                                        <option key={pageSize} value={pageSize}>
                                            Afficher {pageSize}
                                        </option>

                                    ))}

                                </select>
                            </div>

                        </div>

                    </footer>

                </div>


            </div>

            <div className="col-md-6 col-lg-5 col-xl-4 col-h-fill">
                <Outlet />
            </div>

        </div>

    </div>
}

function ProductItem({ product }) {
    const qMax: number = parseInt(product?.QuantityMax ?? "0")
    const quantity: number = parseInt(product?.Quantity ?? "0")
    const qMin: number = parseInt(product?.QuantityMin ?? "0")

    var bg = ProgressColor(quantity, qMin, qMax)

    return <>
        <div className="list-item-start">
            <div className="item-start">
                {product?.ProductImage ?
                    <img className="img-fluid" src={product?.ProductImage ? "../media/images/product/" + product?.ProductImage : DEFAULT_IMG} alt="" />
                    : <div className="bg-background-light p-2 rounded-5 h-100 w-100 d-flex align-items-center justify-content-center">
                        <i className={ResolveIcon(product?.ProductType) + " text-muted"} style={{ fontSize: "1.6rem" }}></i>
                    </div>
                }
            </div>
        </div>

        <section className="list-item-data pe-md-3">
            <div className="row d-flex">

                <div className="col-8 col-sm-5">
                    <h6 className="title text-truncate">{product?.ProductModel}</h6>
                    <p className="text-muted mb-1">
                        {product?.ProductBrand} <span className="d-none d-lg-inline">{product?.ProductBrand && product?.ProductReference && " - "} {product?.ProductReference}</span>
                    </p>
                </div>

                <div className="col-4 col-sm-7 text-end">

                    <div className="row justify-content-end row-cols-sm-3 row-cols-lg-4">

                        <div className="col d-none d-lg-block">
                            <h6 className="text-muted">Fournisseur</h6>
                            <p className="paragraph">{product?.ProductSupplier}</p>
                        </div>
                        <div className="col d-none d-sm-block">
                            <h6 className="text-muted">Min</h6>
                            <p className="paragraph">{qMin}</p>
                        </div>
                        <div className="col">
                            <h6 className="text-muted">Quantité</h6>
                            <p className="paragraph">{quantity}</p>
                        </div>
                        <div className="col d-none d-sm-block">
                            <h6 className="text-muted">Max</h6>
                            <p className="paragraph">{qMax}</p>
                        </div>
                    </div>
                </div>

                <div className="col-12">

                    <div className="progress" style={{ "height": "5px" }}>
                        <div className={"progress-bar " + bg} role="progressbar" aria-label="Example 1px high" style={{ "width": (quantity * 100 / qMax) + "%" }} aria-valuenow={quantity} aria-valuemin={0} aria-valuemax={qMax}></div>
                    </div>
                </div>

            </div>

        </section>

        <div className="list-item-more d-flex d-md-none">
            <i className="bi bi-chevron-right"></i>
        </div>
    </>
}

export function PieceTable(reload?: boolean, setReload?) {

    const [data, setData] = useState<Array<IProduct>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const columns = useMemo<ColumnDef<IProduct, any>[]>(
        () => [
            {
                accessorFn: row => row.ProductModel,
                id: 'Model',
                header: 'Modèle',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductBrand,
                id: 'ProductBrand',
                header: 'Marque',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductReference,
                id: 'Reference',
                header: 'Référence',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductSupplier,
                id: 'ProductSupplier',
                header: 'Fournisseur',
                cell: info => info.getValue()
            }

        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        table.setPageSize(50)

        reqGet('product/piece').then((res) => {
            setData(res)
        })
    }, [])

    useEffect(() => {
        if (reload) {
            reqGet('product/piece').then((res) => { setData(res); })
            setReload(false)
        }
    }, [reload])

    return table
}