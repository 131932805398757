import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { IAddress, IHeating } from "../../../common/types"
import { ButtonSubmit, Header } from '../../../common/common'

export default function heatingAdd({ setReload }) {
    const location = useLocation()
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idAddress = params.idAddress

    const [address, setAddress] = useState<IAddress>()

    const validationSchema = yup.object({
        HeatingDate: yup.string().required("Ce champ est obligatoire"),
        HeatingAction: yup.string().required("Ce champ est obligatoire"),
        HeatingNote: yup.string().nullable()
    }).required();


    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IHeating>({
        mode: "onTouched",
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        if (!idAddress)
            navigate(-1)
        else
            setValue("AddressID", parseInt(idAddress))

        reqGet("address/" + idAddress).then(res => setAddress(res))
    }, [])


    const LinkBack = () => {
        if (location?.state?.path)
            return <Link to={location.state.path} className="link-nav">{"<"} Retour</Link>
        else
            return <Link to={"/address/" + idAddress} className="link-nav">{"<"} Retour</Link>
    }

    const onSubmit: SubmitHandler<IHeating> = data => {
        reqPost('heating', { ...data, ActivityUserID: token.UserID, ...address }).then((res) => {
            setReload(true)
            navigate('/address/' + idAddress + "/heating")
        })
    };

    return (
        <div className="row justify-content-center h-100">
            <div className="col-md-10 col-lg-8 col-h-fill">
                <section className="body-container container-fuild h-100">

                    {Header(
                        <h3 className="section-title">Ajout arrêt/MER chauffage</h3>,
                        LinkBack()
                    )}

                    <section className="body container-fluid">

                        <form onSubmit={handleSubmit(onSubmit)}>

                            <section className="row mb-3">
                                <div className="col-6">
                                    <label className="form-label">Date *</label>

                                    <input type="date" className={"form-control " + (errors?.HeatingDate ? "is-invalid" : "")} {...register("HeatingDate")} />

                                    {errors.HeatingDate?.message && <p className="input-msg-error">{errors.HeatingDate?.message}</p>}
                                </div>

                                <div className="col-6">
                                    <label className="form-label">Action *</label>

                                    <select className={"form-select " + (errors?.HeatingAction ? "is-invalid" : "")} {...register("HeatingAction")} >
                                        <option value="Start">Mise en route</option>
                                        <option value="Stop">Arrêt</option>
                                    </select>

                                    {errors.HeatingAction?.message && <p className="input-msg-error">{errors.HeatingAction?.message}</p>}
                                </div>
                            </section>

                            <div className="mb-3">
                                <label className="form-label">Information</label>
                                <textarea rows={4} className={(errors?.HeatingNote ? "is-invalid" : "")} {...register("HeatingNote")} />
                            </div>

                            <div className="text-end">
                                <p><small>Les champs avec une * sont obligatoire</small></p>
                                <ButtonSubmit />
                            </div>
                        </form>


                    </section>
                </section>

            </div>
        </div>
    );
}
