import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom"
import $ from 'jquery'

import { Header, PrivateLinkAdd } from "../../../../common/common"
import { reqGet } from "../../../../../service/apiRequest";
import { IExchanger } from "../../../../common/types";
import { LAYOUT } from "../../../../common/constants";

export default function ExchangerTemplate({ reload, setReload }) {
    const location = useLocation()

    const [exchangers, setExchangers] = useState<Array<IExchanger>>([])

    useEffect(() => {
        reqGet("exchanger").then(res => { setExchangers(res) })
    }, [])

    useEffect(() => {
        if (reload) {
            reqGet("exchanger").then(res => { setExchangers(res) })
            setReload(false)
        }
    }, [reload])


    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/exchanger/template") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    return <section className="container-fluid h-100">

        <div className="row h-100">

            {/* Exchanger list */}
            <div className={display + " col-md-6 col-xl-4 col-h-100 px-0 pe-md-3"}>
                <section className="body-container">
                    {Header(
                        <h2 className="section-title">Modèle d'échangeurs</h2>,
                        <Link to="/exchanger" className="link-nav">{"<"} Retour</Link>,
                        PrivateLinkAdd("/exchanger/template/add")
                    )}

                    <section className="body">

                        <div className="card card-fill">
                            <section className="card-body">

                                {exchangers?.length > 0 ?
                                    <nav className="list-group list-group-hover" id="exchangerList">

                                        {exchangers.map(ex => {
                                            return <NavLink to={ex.ExchangerID.toString()} key={ex.ExchangerID} className="list-group-item text-start">

                                                <div className="row">
                                                    <div className="col-3 d-flex align-items-center">
                                                        <h5 className="mb-0">{ex.ExchangerLabel}</h5>
                                                    </div>
                                                    <div className="col">
                                                        <h6 className="item-title">{ex.ExchangerModel}</h6>
                                                        <p className="item-subtitle mb-0">{ex.ExchangerBrand}</p>
                                                    </div>
                                                </div>


                                            </NavLink>
                                        })}

                                    </nav>
                                    : <p className="text-muted">Aucun échangeur</p>
                                }

                            </section>
                        </div>

                    </section>
                </section>
            </div>

            {/* Exchanger add */}
            <div className="col-md-6 col-xxl-5 px-0">
                <Outlet />
            </div>
        </div>

    </section>
}



