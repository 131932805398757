import React, { useState, useEffect, useMemo } from 'react'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom'
import $ from 'jquery'

import { reqGet, reqDelete } from '../../../service/apiRequest'
import { getToken } from '../../../useToken'
import { CardBtnLink, DebouncedInput, PrivateComponent, PrivateLinkAdd, fuzzyFilter } from "../../common/common"
import { GROUP, ICONS, LAYOUT } from "../../common/constants"
import { IUser } from "../../common/types"

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
} from '@tanstack/react-table'

export default function Users({ reload, setReload }) {
    const location = useLocation()

    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/users") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    return (
        <section className="container-fluid h-100">
            <div className="row h-100">

                <div className={display + " col-md-4 col-xl-3 col-h-fill border-right p-3"}>
                    {UsersList(reload, setReload)}
                </div>

                <div className="col-md-8 col-xl-9 col-h-fill px-0">
                    <Outlet />
                </div>
            </div>
        </section>
    );
}

function UsersList(reload, setReload) {
    const location = useLocation()
    const token = getToken()

    const columns = useMemo<ColumnDef<IUser, any>[]>(
        () => [
            {
                accessorFn: row => row.Username,
                id: 'Username',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.FirstName,
                id: 'FirstName',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Name,
                id: 'Name',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Phone,
                id: 'Phone',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Role,
                id: 'Role',
                cell: info => info.getValue()
            }
        ], []
    )

    const [data, setData] = useState<Array<IUser>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        reqGet("user").then(res => setData(res.filter(u => u.UserArchived != 1)))
    }, [])

    useEffect(() => {
        if (reload) {
            reqGet("user").then(res => setData(res.filter(u => u.UserArchived != 1)))
            setReload(false)
        }
    }, [reload])

    return <section className="body-container">

        <header className="header">
            <h2 className="section-title">Utilisateurs</h2>

            {PrivateLinkAdd("add", GROUP.rh, ["UserAdd"])}
        </header>

        <article className="card card-flush card-fill">

            <section className="card-body">

                <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} className="mb-3" placeholder="Recherche" />

                {PrivateComponent(
                    <button className="card card-btn card-sm">
                        <header className='card-header'>
                            <div className="header-content">
                                <i className={"card-icon " + ICONS.ARCHIVE}></i>
                            </div>
                        </header>

                        <section className="card-body">
                            <h5 className="card-title">Archivé</h5>
                        </section>

                        <footer className="card-footer">
                            <i className="bi bi-chevron-right"></i>
                        </footer>
                    </button>,
                    GROUP.dev
                )}

                <nav className="list-group list-group-hover">

                    {table && table?.getRowModel().rows.map(row => {

                        return (
                            <NavLink to={row.original.UserID.toString()} key={row.original.UserID} className="list-group-item">

                                <h6 className="item-title">{row.original.FirstName} {row.original.Name}</h6>

                                <p className="item-subtitle">{row.original.Role}</p>

                                <p className="item-subtitle">{row.original.Phone}</p>

                            </NavLink>
                        )

                    })}
                </nav>
            </section>
        </article>

    </section>
}