import React, { useState, useEffect } from 'react'
import { Navigate, useRoutes, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom'

import useToken, { getToken } from "./useToken";
import Home from './components/content/home'
import { Cholet } from './components/cholet'

import Login from "./login";
import Dashboard from "./components/dashboard";

// ACCOUNT --------------------------
import Account from './components/content/account/account'
import AccountUpdate from './components/content/account/account-update'
import AccountVehicleGuarantor from './components/content/account/account-vehicle/account-vehicle-guarantor'
import AccountVehicle from './components/content/account/account-vehicle/account-vehicle'
import AccountUpdatePassword from './components/content/account/account-update-password'

import AccountSurvey from './components/content/account/account-survey/account-survey'
import AccountSurveyContent from './components/content/account/account-survey/account-survey-content'

import AccountHeating from './components/content/account/account-heating/account-heating'
import AccountHeatingContent from './components/content/account/account-heating/account-heating-content'

import AccountHoliday from './components/content/account/account-holiday/account-holiday'
import AccountHolidayAdd from './components/content/account/account-holiday/account-holiday-add'
import AccountHolidayUpdate from './components/content/account/account-holiday/account-holiday-update'

import AccountCounterStatement from './components/content/account/account-counter-statement/account-counter-statement'
import AccountCounterStatementContent from './components/content/account/account-counter-statement/account-counter-statement-content'

import AccountMission from './components/content/account/account-mission/account-mission'
import AccountMissionAdd from './components/content/account/account-mission/account-mission-add'
import AccountMissionUpdate from './components/content/account/account-mission/account-mission-update'

import AccountPicket from './components/content/account/account-picket/account-picket'
import AccountPicketAdd from './components/content/account/account-picket/account-picket-add'
import AccountPicketUpdate from './components/content/account/account-picket/account-picket-update'

import AccountTask from './components/content/account/account-task/account-task'
import AccountTaskAdd from './components/content/account/account-task/account-task-add'

import AccountTimesheet from './components/content/account/account-timesheet/account-timesheet'
import AccountTimesheetAdd from './components/content/account/account-timesheet/account-timesheet-add'
import AccountTimesheetUpdate from './components/content/account/account-timesheet/account-timesheet-update'
import AccountTimesheetKmUpdate from './components/content/account/account-timesheet/account-km/account-km-update'
// ----------------------------------

import Activity from './components/content/activity/activity'
import ActivityContent from './components/content/activity/activity-content'

// ADDRESS --------------------------
import Address from './components/content/address/address'
import AddressController from './components/content/address/address-controller'
import AddressContent from './components/content/address/address-content'
import AddressAdvanced from './components/content/address/address-advanced'

import AddressAdd from "./components/content/address/address-add";
import AddressUpdate from "./components/content/address/address-update";
import AddressUpdateNote from "./components/content/address/address-component/address-update-note";

import AddressActivity from "./components/content/address/address-activity/address-activity";

import AddressContract from './components/content/address/address-contract/address-contract'
import AddressContractAdd from './components/content/address/address-contract/address-contract-add'
import AddressContractUpdate from './components/content/address/address-contract/address-contract-update'
import AddressContractNewPrice from './components/content/address/address-contract/address-contract-newprice';

//import AddressApartmentAdd from "./components/content/address/address-apartment-add";
import AddressWorkbook from "./components/content/address/address-workbook/address-workbook";
import AddressReport from "./components/content/address/address-report/address-report";

import AddressDocument from './components/content/address/address-document/address-document'
import AddressDocumentContent from './components/content/address/address-document/address-document-content'
import AddressDocumentAdd from './components/content/address/address-document/address-document-add'
import AddressDocumentUpdate from './components/content/address/address-document/address-document-update'

import AddressHeating from './components/content/address/address-heating/address-heating'
import AddressHeatingAdd from './components/content/address/address-heating/address-heating-add'
import AddressHeatingUpdate from './components/content/address/address-heating/address-heating-update'

import AddressInvoice from "./components/content/address/address-invoice/address-invoice"
import AddressInvoiceContent from "./components/content/address/address-invoice/address-invoice-content"
import AddressInvoiceAdd from "./components/content/address/address-invoice/address-invoice-add/address-invoice-add"
import AddressInvoiceAddService from "./components/content/address/address-invoice/address-invoice-add/address-invoice-add-service"
import AddressInvoiceAddContract from './components/content/address/address-invoice/address-invoice-add/address-invoice-add-contract';
//import AddressInvoiceUpdate from "./components/content/address/address-invoice/address-invoice-update"

import AddressService from './components/content/address/address-service/address-service'
import AddressServiceContent from './components/content/address/address-service/address-service-content'
import AddressServiceAdd from './components/content/address/address-service/address-service-add'
import AddressServiceUpdate from './components/content/address/address-service/address-service-update'

import AddressInstalls from "./components/content/address/address-installation/address-installation";
import AddressInstallAdd from "./components/content/address/address-installation/address-install-add";
import AddressInstallUpdateController from "./components/content/address/address-installation/address-install-update";

import AddressGood from "./components/content/address/address-good/address-good"
import AddressGoodContent from "./components/content/address/address-good/address-good-content"
import AddressGoodAdd from "./components/content/address/address-good/address-good-add"

import AddressCounters from "./components/content/address/address-counter/address-counters";
import AddressCounterAdd from "./components/content/address/address-counter/address-counter-add";
import AddressCounterUpdate from "./components/content/address/address-counter/address-counter-update";
// ----------------------------------

import BrandContent from './components/content/database/brand/brand-content'
import BrandAdd from './components/content/database/brand/brand-add'
import BrandUpdate from './components/content/database/brand/brand-update'

// CLIENT --------------------------
import Client from "./components/content/client/client";
import ClientContent from "./components/content/client/client-content";
import ClientAdd from "./components/content/client/client-add";

import ClientAddress from "./components/content/client/client-address/client-address";
import ClientAddressDetail from "./components/content/client/client-address/client-address-detail";
import ClientAddressAdd from "./components/content/client/client-address/client-address-add";

import ClientHeating from "./components/content/client/client-heating/client-heating";
import ClientHeatingInsert from "./components/content/client/client-heating/client-heating-insert";
import ClientHeatingUpdate from "./components/content/client/client-heating/client-heating-update";
// ----------------------------------

import Contract from './components/content/contract/contract';

import Construction from "./components/content/construction/construction";
import ConstructionContent from "./components/content/construction/construction-content";
import ConstructionAdd from './components/content/construction/construction-add';
import ConstructionUpdate from './components/content/construction/construction-update';
import ConstructionIndex from './components/content/construction/construction-index'
import ConstructionData from './components/content/construction/construction-data'
import ConstructionPv from './components/content/construction/construction-pv'
import ConstructionDocuments from './components/content/construction/construction-documents'
import ConstructionGallery from './components/content/construction/construction-gallery'

import CounterStatement from './components/content/counter-statement/counter-statement';
import CounterStatementContent from './components/content/counter-statement/counter-statement-content';

import Database from './components/content/database/database'

import Exchanger from './components/content/product/exchanger/exchanger'
import ExchangerContent from './components/content/product/exchanger/exchanger-content'
import ExchangerAdd from './components/content/product/exchanger/exchanger-add'
import ExchangerUpdate from './components/content/product/exchanger/exchanger-update'
import ExchangerTemplate from './components/content/product/exchanger/exchanger-template/exchanger-template'
import ExchangerTemplateContent from './components/content/product/exchanger/exchanger-template/exchanger-template-content'
import ExchangerTemplateAdd from './components/content/product/exchanger/exchanger-template/exchanger-template-add'
import ExchangerTemplateUpdate from './components/content/product/exchanger/exchanger-template/exchanger-template-update'

import Heating from './components/content/heating/heating'
import HeatingContent from './components/content/heating/heating-content'
import HeatingAdd from './components/content/heating/heating-add'
import HeatingUpdate from './components/content/heating/heating-update'

import Help from './components/content/help/help'

import Holiday from './components/content/holiday/holiday'
import HolidayAdd from './components/content/holiday/holiday-add'

import Installation from './components/content/installation/installation'

import ManagementContent from './components/content/database/management/management-content'
import ManagementAdd from './components/content/database/management/management-add'
import ManagementUpdate from './components/content/database/management/management-update'

import Material from './components/content/material/material'
import MaterialContent from './components/content/material/material-content'
import MaterialAdd from './components/content/material/material-add'
import MaterialUpdate from './components/content/material/material-update'
import MaterialMerge from './components/content/material/material-merge'
import MaterialAddress from './components/content/material/material-address'

import OwnerContent from './components/content/database/owner/owner-content'
import OwnerAdd from './components/content/database/owner/owner-add'
import OwnerUpdate from './components/content/database/owner/owner-update'

import Observation from './components/content/observation/observation'
import ObservationAdd from "./components/content/observation/observation-add";

import Permission from './components/content/permission/permission'
import PermissionRole from './components/content/permission/permission-role'
import PermissionGroup from './components/content/permission/permission-group'

import PDF from './components/content/pdf/pdf'
import PdfTest from './components/content/pdf/pdf-test'

import Piece from './components/content/product/piece/piece'
import PieceContent from './components/content/product/piece/piece-content'
import PieceActivity from './components/content/product/piece/piece-activity'
import PieceAdd from './components/content/product/piece/piece-add'
import PieceUpdate from './components/content/product/piece/piece-update'

import PieceMaterial from './components/content/product-material/product-material'
import PieceMaterialAdd from './components/content/product-material/product-material-add'

import ProductPrice from './components/content/product/product-price/product-price'
import ProductPriceContent from './components/content/product/product-price/product-price-content'
import ProductPriceAdd from './components/content/product/product-price/product-price-add'
import ProductPriceUpdate from './components/content/product/product-price/product-price-update'

import Report from './components/content/report'

import StorageMap from './components/content/storage/storage-map'
import Storage from './components/content/storage/storage'
import StorageContent from './components/content/storage/storage-content'
import StorageContentLabel from './components/content/storage/storage-content-label'
import StorageAdd from './components/content/storage/storage-add'
import StorageUpdate from './components/content/storage/storage-update'
import StorageRepair from './components/content/storage/storage-repair'

import SubcontractorContent from './components/content/database/subcontractor/subcontractor-content'
import SubcontractorAdd from './components/content/database/subcontractor/subcontractor-add'
import SubcontractorUpdate from './components/content/database/subcontractor/subcontractor-update'

import SupplierContent from './components/content/database/supplier/supplier-content'
import SupplierAdd from './components/content/database/supplier/supplier-add'
import SupplierUpdate from './components/content/database/supplier/supplier-update'

import Survey from './components/content/survey/survey'
import SurveyContent from './components/content/survey/survey-content'

import TaskTypeContent from './components/content/database/tasktype/tasktype-content';
import TaskTypeAdd from './components/content/database/tasktype/tasktype-add';
import TaskTypeUpdate from './components/content/database/tasktype/tasktype-update';

import TenantContent from './components/content/database/tenant/tenant-content';
import TenantAdd from './components/content/database/tenant/tenant-add';
import TenantUpdate from './components/content/database/tenant/tenant-update';

import Timesheet from './components/content/timesheet/timesheet'
import TimesheetAdd from './components/content/timesheet/timesheet-add'
import TimesheetUpdate from './components/content/timesheet/timesheet-update'
import TimesheetPrint from './components/content/timesheet/timesheet-print'

import Tool from './components/content/product/tool/tool'
import ToolContent from './components/content/product/tool/tool-content'
import ToolActivity from './components/content/product/tool/tool-activity'
import ToolAdd from './components/content/product/tool/tool-add'
import ToolUpdate from './components/content/product/tool/tool-update'

import Test from './components/test'

import Ui from './components/content/ui'

// USER --------------------------
import Users from './components/content/user/users'
import UserContent from './components/content/user/user-content'
import UserAdd from "./components/content/user/user-add"
import UserUpdate from "./components/content/user/user-update"
import UserUpdatePassword from "./components/content/user/user-update-password"
import UserHoliday from './components/content/user/user-holiday'

import UserContactAdd from './components/content/user/user-contact/user-contact-add'
import UserContactUpdate from './components/content/user/user-contact/user-contact-update'
// USER --------------------------

import UserRoleContent from './components/content/database/userrole/userrole-content'
import UserRoleAdd from './components/content/database/userrole/userrole-add'
import UserRoleUpdate from './components/content/database/userrole/userrole-update'

import Vehicle from './components/content/vehicle/vehicle'
import VehicleContent from './components/content/vehicle/vehicle-content'
import VehicleReport from "./components/content/vehicle/vehicle-report"
import VehicleAdd from "./components/content/vehicle/vehicle-add"
import VehicleUpdate from "./components/content/vehicle/vehicle-update"
import VehicleCheckAdd from "./components/content/vehicle/vehiclecheck-add"

import Warehouse from './components/content/warehouse/warehouse'
import WarehouseAdd from './components/content/warehouse/warehouse-add'
import WarehouseUpdate from './components/content/warehouse/warehouse-update'
import WarehouseActivity from './components/content/warehouse/warehouse-activity'
import WarehouseContent from './components/content/warehouse/warehouse-content'
import WarehouseInsufficient from './components/content/warehouse/warehouse-insufficient'
import WarehouseOutside from './components/content/warehouse/warehouse-outside'
import WarehouseRestore from './components/content/warehouse/warehouse-restore'
import WarehouseInventoryPicking from './components/content/warehouse/warehouse-inventory-picking'
import WarehouseInventoryGlobal from './components/content/warehouse/warehouse-inventory-global'
import WarehouseOrder from './components/content/warehouse/warehouse-order'
import WarehousePutBack from './components/content/warehouse/warehouse-putback';
import WarehouseResupply from './components/content/warehouse/warehouse-resupply'
import WarehouseTakeOut from './components/content/warehouse/warehouse-takeout'

import WorkbookAdd from './components/content/workbook/workbook-add'

import Zone from './components/content/zone/zone'
import ZoneContent from './components/content/zone/zone-content'
import ZoneAdd from './components/content/zone/zone-add'
import ZoneUpdate from './components/content/zone/zone-update'

import ZipcodeContent from './components/content/database/zipcode/zipcode-content'
import ZipcodeAdd from './components/content/database/zipcode/zipcode-add'
import ZipcodeUpdate from './components/content/database/zipcode/zipcode-update'

import useAuth from "./authentication"

import { GROUP, PERMISSION } from "./components/common/constants"


export default function AppRouter() {
  const { token, setToken } = useToken();
  const navigate = useNavigate()
  const location = useLocation()

  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!getToken())
      navigate("/login")
  }, [location.pathname])

  return useRoutes(
    [
      { path: "/login", element: <Login setToken={setToken} /> },
      {
        path: "/",
        element: PrivateRoute(<Dashboard />),
        children: [
          { path: "*", element: <Navigate to="" /> },

          { path: "", element: <Home /> },

          // Account
          {
            path: "account",
            element: <Account reload={reload} setReload={setReload} />,
            children: [
              { path: "update", element: <AccountUpdate setReload={setReload} /> },
              { path: "password/update", element: <AccountUpdatePassword /> },

              // Account statement
              {
                path: "counter-statement",
                element: PrivateRoute(<AccountCounterStatement reload={reload} setReload={setReload} />, GROUP.crud_survey),
                children: [
                  { path: "entry", element: PrivateRoute(<AccountCounterStatementContent reload={reload} setReload={setReload} />, GROUP.crud_survey) }
                ]
              },

              // Account heating
              {
                path: "heating",
                element: <AccountHeating reload={reload} setReload={setReload} />,
                children: [
                  { path: ":idAddress", element: <AccountHeatingContent reload={reload} setReload={setReload} />, }
                ]
              },

              // Account holiday
              {
                path: "holiday",
                element: <AccountHoliday reload={reload} setReload={setReload} />,
                children: [
                  { path: "add", element: <AccountHolidayAdd setReload={setReload} /> },
                  { path: ":idHoliday/update", element: <AccountHolidayUpdate setReload={setReload} /> }
                ]
              },

              // Account mission
              {
                path: "mission",
                element: <AccountMission reload={reload} setReload={setReload} />,
                children: [
                  { path: "add", element: <AccountMissionAdd setReload={setReload} /> },
                  { path: ":idMission/update", element: <AccountMissionUpdate setReload={setReload} /> }
                ]
              },

              // Account piquet
              {
                path: "picket",
                element: <AccountPicket reload={reload} setReload={setReload} />,
                children: [
                  { path: "add", element: <AccountPicketAdd setReload={setReload} /> },
                  { path: ":idTimesheet/update", element: <AccountPicketUpdate setReload={setReload} /> }
                ]
              },

              // Account survey
              {
                path: "survey",
                element: <AccountSurvey reload={reload} setReload={setReload} />,
                children: [
                  { path: ":idAddress", element: <AccountSurveyContent reload={reload} setReload={setReload} /> }
                ]
              },

              // Account tasks
              {
                path: "task",
                element: PrivateRoute(<AccountTask />, GROUP.dev),
                children: [
                  { path: "add", element: <AccountTaskAdd /> }
                ]
              },

              // Account timesheet
              {
                path: "timesheet",
                element: <AccountTimesheet reload={reload} setReload={setReload} />,
                children: [
                  { path: "add", element: <AccountTimesheetAdd setReload={setReload} /> },
                  { path: ":idTimesheet/update", element: <AccountTimesheetUpdate setReload={setReload} /> },
                  { path: ":idTimesheet/update-km", element: <AccountTimesheetKmUpdate setReload={setReload} /> }
                ]
              },

              // Account vehicles
              { path: "vehicle/:idVehicle", element: <AccountVehicle /> },
              { path: "vehicle/:idVehicle/update", element: <VehicleUpdate setReload={setReload} /> },
              { path: "vehicle/:idVehicle/check-add", element: <VehicleCheckAdd setReload={setReload} /> },
              { path: "vehicle/guarantor", element: <AccountVehicleGuarantor /> }
            ]
          },

          // Activity
          { path: "activity", element: PrivateRoute(<Activity />, GROUP.admin, ["ActivityRead"]), },
          { path: "activity/:idActivity", element: PrivateRoute(<ActivityContent />) },

          // Address
          {
            path: "address",
            element: <Address reload={reload} setReload={setReload} />,
            children: [
              //Address
              { path: "advanced", element: PrivateRoute(<AddressAdvanced />, GROUP.crud_sav, ["AddressAdvanced"]) },
              {
                path: ":idAddress",
                element: <AddressController reload={reload} setReload={setReload} />,
                children: [
                  { path: "", element: <AddressContent /> },
                  { path: "update", element: PrivateRoute(<AddressUpdate setReload={setReload} />, GROUP.crud_sav, ["AddressUpdate"]) },
                  { path: "update-note", element: PrivateRoute(<AddressUpdateNote setReload={setReload} />, GROUP.crud_sav, ["AddressUpdate"]) },

                  // ACTIVITY
                  { path: "activity", element: PrivateRoute(<AddressActivity />, GROUP.crud_sav) },


                  // Contract
                  { path: "contract", element: <AddressContract reload={reload} setReload={setReload} /> },
                  { path: "contract/add", element: PrivateRoute(<AddressContractAdd />, GROUP.crud_sav) },
                  { path: "contract/:idAddressContract/update", element: PrivateRoute(<AddressContractUpdate />, GROUP.crud_sav) },
                  { path: "contract/:idAddressContract/newprice", element: PrivateRoute(<AddressContractNewPrice />, GROUP.crud_sav) },
                  { path: "contract/:idAddressContract/service/add", element: PrivateRoute(<AddressServiceAdd setReload={setReload} />, GROUP.crud_sav) },
                  { path: "contract/:idAddressContract/service/:idAddressService/update", element: PrivateRoute(<AddressServiceUpdate setReload={setReload} />, GROUP.crud_sav) },

                  // Counters
                  {
                    path: "counter",
                    element: <AddressCounters />,
                    children: [
                      { path: ":idCounter", element: <AddressCounters /> },
                    ]
                  },
                  { path: "counter/add", element: PrivateRoute(<AddressCounterAdd />, GROUP.crud_sav, ["CounterCreate"]) },
                  {
                    path: "counter/:idCounter/update", element: PrivateRoute(<AddressCounterUpdate />, GROUP.crud_sav, ["CounterUpdate"])
                  },

                  // Document
                  {
                    path: "document",
                    element: PrivateRoute(<AddressDocument reload={reload} setReload={setReload} />, GROUP.crud_sav, ["AddressDocumentRead"]),
                    children: [
                      { path: ":idDocument", element: PrivateRoute(<AddressDocumentContent setReload={setReload} />, GROUP.crud_sav, ["AddressDocumentRead"]) }
                    ]
                  },
                  { path: "document/add", element: PrivateRoute(<AddressDocumentAdd />, GROUP.crud_sav, ["AddressDocumentAdd"]) },
                  { path: "document/:idDocument/update", element: PrivateRoute(<AddressDocumentUpdate setReload={setReload} />, GROUP.crud_sav, ["AddressDocumentUpdate"]) },

                  //heating
                  { path: "heating", element: <AddressHeating reload={reload} setReload={setReload} /> },
                  { path: "heating/add", element: PrivateRoute(<AddressHeatingAdd setReload={setReload} />, GROUP.crud_sav, ["HeatingCreate"]) },
                  { path: "heating/:idHeating/update", element: PrivateRoute(<AddressHeatingUpdate setReload={setReload} />, GROUP.crud_sav, ["HeatingUpdate"]) },

                  // installation
                  { path: "installation", element: <AddressInstalls /> },
                  { path: "installation/add", element: PrivateRoute(<AddressInstallAdd />, [...GROUP.crud_sav, ...GROUP.crud_tec], ["InstallationAdd"]) },
                  { path: "installation/:idInstall/update", element: PrivateRoute(<AddressInstallUpdateController />, [...GROUP.crud_sav, ...GROUP.crud_tec], ["InstallationUpdate"]) },

                  // Intervention
                  { path: "good", element: PrivateRoute(<AddressGood />, GROUP.crud_sav) },
                  { path: "good/:idGood", element: PrivateRoute(<AddressGoodContent />, GROUP.crud_sav) },
                  { path: "good/add", element: PrivateRoute(<AddressGoodAdd />, GROUP.crud_sav) },


                  // Intervention
                  {
                    path: "invoice",
                    element: PrivateRoute(<AddressInvoice />, GROUP.admin),
                    children: [
                      { path: ":idInvoice", element: <AddressInvoiceContent /> }
                    ]
                  },
                  { path: "invoice/add", element: PrivateRoute(<AddressInvoiceAdd />, GROUP.admin) },
                  { path: "invoice/add/service", element: PrivateRoute(<AddressInvoiceAddService />, GROUP.admin) },
                  { path: "invoice/add/contract", element: PrivateRoute(<AddressInvoiceAddContract />, GROUP.admin) },
                  //{path: "invoice/:idInvoice/update", element: PrivateRoute(<AddressInvoiceUpdate />, GROUP.admin)},

                  // pdf report
                  { path: "report", element: PrivateRoute(<AddressReport />, GROUP.crud_sav) },

                  // Service
                  {
                    path: "service",
                    element: <AddressService />,
                    children: [
                      { path: "", element: <AddressServiceContent reload={reload} setReload={setReload} /> },
                      { path: "add", element: PrivateRoute(<AddressServiceAdd setReload={setReload} />, GROUP.crud_sav, ["AddressServiceAdd"]) },
                      { path: ":idAddressService/update", element: PrivateRoute(<AddressServiceUpdate setReload={setReload} />, GROUP.crud_sav, ["AddressServiceUpdate"]) },
                    ]
                  },

                  // Workbook
                  { path: "workbook", element: PrivateRoute(<AddressWorkbook />) },
                  { path: "workbook/add", element: PrivateRoute(<WorkbookAdd token={token} setReload={setReload} />) },
                  { path: "workbook/:idWorkbook/observation/add", element: PrivateRoute(<ObservationAdd token={token} />, GROUP.dev) },

                ]
              },

              { path: "add", element: PrivateRoute(<AddressAdd setReload={setReload} />, GROUP.crud_sav, ["AddressCreate"]) },


            ]
          },

          {
            path: "client",
            element: PrivateRoute(<Client reload={reload} setReload={setReload} />, GROUP.crud_sav, ["ClientRead"]),
            children: [
              { path: "add", element: PrivateRoute(<ClientAdd setReload={setReload} />, GROUP.crud_sav, ["ClientAdd"]) },

              // Management
              {
                path: "management/:idManagement",
                element: PrivateRoute(<ClientContent reload={reload} setReload={setReload} />, GROUP.crud_sav, ["ClientRead"]),
                children: [
                  { path: "", element: <Navigate to="address" /> },
                  { path: "address", element: PrivateRoute(<ClientAddress reload={reload} setReload={setReload} />, GROUP.crud_sav, ["ClientRead"]) },

                  { path: "address/:idAddress", element: PrivateRoute(<ClientAddressDetail />, GROUP.crud_sav, ["AddressRead"]) },
                  { path: "address/:idAddress/add", element: PrivateRoute(<ClientAddressAdd />, GROUP.crud_sav, ["AddressUpdate"]) },
                  { path: "address/:idAddress/*", element: PrivateRoute(<ClientAddressDetail />, GROUP.crud_sav, ["AddressRead"]) },

                  // Management heating
                  { path: "heating", element: PrivateRoute(<ClientHeating />, GROUP.crud_sav, ["HeatingRead"]) },
                  { path: "heating/add", element: PrivateRoute(<ClientHeatingInsert />, GROUP.crud_sav, ["HeatingAdd"]) },
                  { path: "heating/:idHeating/update", element: PrivateRoute(<ClientHeatingUpdate />, GROUP.crud_sav, ["HeatingUpdate"]) },
                ]
              },
              { path: "management/add", element: PrivateRoute(<ManagementAdd />, GROUP.crud_sav, ["ManagementAdd"]) },
              { path: "management/:idManagement/update", element: PrivateRoute(<ManagementUpdate />, GROUP.crud_sav, ["ManagementUpdate"]) },

              // Owner
              {
                path: "owner/:idOwner",
                element: PrivateRoute(<ClientContent reload={reload} setReload={setReload} />, GROUP.crud_sav, ["ClientRead"]),
                children: [
                  { path: "", element: <Navigate to="address" /> },
                  { path: "address", element: PrivateRoute(<ClientAddress reload={reload} setReload={setReload} />, GROUP.crud_sav, ["ClientRead"]) },

                  { path: "address/:idAddress", element: PrivateRoute(<ClientAddressDetail />, GROUP.crud_sav, ["AddressRead"]) },
                  { path: "address/:idAddress/add", element: PrivateRoute(<ClientAddressAdd />, GROUP.crud_sav, ["AddressUpdate"]) },

                  // owner heating
                  { path: "heating", element: PrivateRoute(<ClientHeating />, GROUP.crud_sav, ["HeatingRead"]) },
                  { path: "heating/add", element: PrivateRoute(<ClientHeatingInsert />, GROUP.crud_sav, ["HeatingAdd"]) },
                  { path: "heating/:idHeating/update", element: PrivateRoute(<ClientHeatingUpdate />, GROUP.crud_sav, ["HeatingUpdate"]) },
                ]
              },
              { path: "owner/add", element: PrivateRoute(<OwnerAdd />, GROUP.crud_sav, ["OwnerAdd"]) },
              { path: "owner/:idOwner/update", element: PrivateRoute(<OwnerUpdate />, GROUP.crud_sav, ["OwnerUpdate"]) },

            ]
          },

          // Construction start -------------------------------------------
          { path: "construction", element: PrivateRoute(<Construction reload={reload} setReload={setReload} />, GROUP.crud_sav) },
          { path: "construction/add", element: PrivateRoute(<ConstructionAdd />, GROUP.crud_sav) },
          { path: "construction/:idConstruction", element: PrivateRoute(<ConstructionContent />, GROUP.crud_sav) },
          { path: "construction/:idConstruction/update", element: PrivateRoute(<ConstructionUpdate />, GROUP.crud_sav) },

          {
            path: "construction-old/:idConstruction", element: PrivateRoute(<ConstructionContent />, GROUP.admin),
            children: [
              { path: "", element: PrivateRoute(<ConstructionIndex />, GROUP.admin) },
              { path: "homepage", element: PrivateRoute(<ConstructionIndex />, GROUP.admin) },
              { path: "data", element: PrivateRoute(<ConstructionData />, GROUP.admin) },
              { path: "pv/*", element: PrivateRoute(<ConstructionPv />, GROUP.admin) },
              { path: "documents", element: PrivateRoute(<ConstructionDocuments />, GROUP.admin) },
              { path: "gallery/*", element: PrivateRoute(<ConstructionGallery />, GROUP.admin) },
            ]
          },
          // Construction end -------------------------------------------

          {
            path: "contract",
            element: PrivateRoute(<Contract />, GROUP.crud_sav, ["ContractRead"])
          },

          {
            path: "counter-statement",
            element: <CounterStatement />,
            children: [
              { path: ":idCounter", element: <CounterStatementContent /> },
            ]
          },

          {
            path: "database",
            element: PrivateRoute(<Database />, GROUP.admin, ["ManagementRead", "OwnerRead", "ZipCodeRead"]),
            children: [

              { path: "", element: <Navigate to="zipcode" /> },

              { path: "brand", element: PrivateRoute(<BrandContent />, GROUP.crud_sav) },
              { path: "brand/add", element: PrivateRoute(<BrandAdd />, GROUP.crud_sav) },
              { path: "brand/:idBrand/update", element: PrivateRoute(<BrandUpdate />, GROUP.crud_sav) },

              { path: "management", element: PrivateRoute(<ManagementContent />, GROUP.crud_sav, ["ManagementRead"]) },
              { path: "management/add", element: PrivateRoute(<ManagementAdd />, GROUP.crud_sav, ["ManagementCreate"]) },
              { path: "management/:idManagement/update", element: PrivateRoute(<ManagementUpdate />, GROUP.crud_sav, ["ManagementUpdate"]) },

              { path: "owner", element: PrivateRoute(<OwnerContent />, GROUP.crud_sav, ["OwnerRead"]) },
              { path: "owner/add", element: PrivateRoute(<OwnerAdd />, GROUP.crud_sav, ["OwnerCreate"]) },
              { path: "owner/:idOwner/update", element: PrivateRoute(<OwnerUpdate />, GROUP.crud_sav, ["OwnerUpdate"]) },

              { path: "subcontractor", element: PrivateRoute(<SubcontractorContent />, GROUP.crud_sav) },
              { path: "subcontractor/add", element: PrivateRoute(<SubcontractorAdd />, GROUP.crud_sav) },
              { path: "subcontractor/:idSubcontractor/update", element: PrivateRoute(<SubcontractorUpdate />, GROUP.crud_sav) },

              { path: "supplier", element: PrivateRoute(<SupplierContent />, GROUP.crud_sav) },
              { path: "supplier/add", element: PrivateRoute(<SupplierAdd />, GROUP.crud_sav) },
              { path: "supplier/:idSupplier/update", element: PrivateRoute(<SupplierUpdate />, GROUP.crud_sav) },

              { path: "tasktype", element: PrivateRoute(<TaskTypeContent />, GROUP.admin, ["TaskTypeRead"]) },
              { path: "tasktype/add", element: PrivateRoute(<TaskTypeAdd />, GROUP.admin, ["TaskTypeAdd"]) },
              { path: "tasktype/:idTaskType/update", element: PrivateRoute(<TaskTypeUpdate />, GROUP.admin, ["TaskTypeUpdate"]) },

              { path: "tenant", element: PrivateRoute(<TenantContent />, GROUP.crud_sav, ["TenantRead"]) },
              { path: "tenant/add", element: PrivateRoute(<TenantAdd />, GROUP.crud_sav, ["TenantCreate"]) },
              { path: "tenant/:idTenant/update", element: PrivateRoute(<TenantUpdate />, GROUP.crud_sav, ["TenantUpdate"]) },

              { path: "userrole", element: PrivateRoute(<UserRoleContent />, GROUP.admin) },
              { path: "userrole/add", element: PrivateRoute(<UserRoleAdd />, GROUP.admin) },
              { path: "userrole/:idUserRole/update", element: PrivateRoute(<UserRoleUpdate />, GROUP.admin) },

              { path: "zipcode", element: PrivateRoute(<ZipcodeContent />, GROUP.crud_sav, ["ZipCodeRead"]) },
              { path: "zipcode/add", element: PrivateRoute(<ZipcodeAdd />, GROUP.crud_sav, ["ZipCodeCreate"]) },
              { path: "zipcode/:idZipCode/update", element: PrivateRoute(<ZipcodeUpdate />, GROUP.crud_sav, ["ZipCodeUpdate"]) },
            ]
          },

          {
            path: "exchanger",
            element: PrivateRoute(<Exchanger reload={reload} setReload={setReload} />, GROUP.admin, PERMISSION.product),
            children: [
              { path: ":idProduct", element: PrivateRoute(<ExchangerContent setReload={setReload} />, GROUP.admin, PERMISSION.product) }
            ]
          },
          { path: "exchanger/add", element: PrivateRoute(<ExchangerAdd />, GROUP.admin, PERMISSION.product) },
          { path: "exchanger/:idProduct/update", element: PrivateRoute(<ExchangerUpdate />, GROUP.admin, PERMISSION.product) },

          {
            path: "exchanger/template",
            element: PrivateRoute(<ExchangerTemplate reload={reload} setReload={setReload} />, GROUP.admin, PERMISSION.product),
            children: [
              { path: ":idExchanger", element: PrivateRoute(<ExchangerTemplateContent />, GROUP.admin, PERMISSION.product) },
              { path: "add", element: PrivateRoute(<ExchangerTemplateAdd setReload={setReload} />, GROUP.admin, PERMISSION.product) },
              { path: ":idExchanger/update", element: PrivateRoute(<ExchangerTemplateUpdate setReload={setReload} />, GROUP.admin, PERMISSION.product) },
            ]
          },

          {
            path: "heating",
            element: PrivateRoute(<Heating reload={reload} setReload={setReload} />, GROUP.crud_sav, ["HeatingRead"]),
            children: [
              {
                path: ":idHeating",
                element: PrivateRoute(<HeatingContent reload={reload} setReload={setReload} />, GROUP.crud_sav, ["HeatingRead"]),
                children: [
                  { path: "add", element: PrivateRoute(<HeatingAdd setReload={setReload} />, GROUP.crud_sav, ["HeatingAdd"]) },
                  { path: "update", element: PrivateRoute(<HeatingUpdate setReload={setReload} />, GROUP.crud_sav, ["HeatingUpdate"]) }
                ]
              },
            ]
          },

          { path: "help/*", element: <Help /> },

          {
            path: "holiday",
            element: PrivateRoute(<Holiday reload={reload} setReload={setReload} />, GROUP.rh),
            children: [
              { path: "add", element: <HolidayAdd setReload={setReload} /> },
              { path: ":idHoliday/update", element: <AccountHolidayUpdate setReload={setReload} /> }
            ]
          },

          { path: "installation", element: PrivateRoute(<Installation />, GROUP.crud_sav) },

          {
            path: "material",
            element: PrivateRoute(<Material reload={reload} setReload={setReload} />, GROUP.crud_sav, ["MaterialRead"]),
            children: [
              { path: ":idMaterial", element: <MaterialContent setReload={setReload} /> },
              { path: "add", element: PrivateRoute(<MaterialAdd setReload={setReload} />, GROUP.crud_sav, ["MaterialCreate"]) },
              { path: ":idMaterial/update", element: PrivateRoute(<MaterialUpdate setReload={setReload} />, GROUP.crud_sav, ["MaterialUpdate"]) },
              { path: ":idMaterial/address", element: <MaterialAddress /> },
            ]
          },
          { path: "material/:idMaterial/merge", element: PrivateRoute(<MaterialMerge />, GROUP.crud_sav, ["MaterialUpdate"]) },

          { path: "observation", element: PrivateRoute(<Observation />, GROUP.dev) },

          {
            path: "permission",
            element: PrivateRoute(<Permission />, GROUP.admin),
            children: [
              { path: "", element: <Navigate to="groups" /> },

              { path: "roles/*", element: <PermissionRole /> },
              { path: "groups/*", element: <PermissionGroup /> }
            ]
          },

          { path: "pdf", element: <PdfTest /> },

          {
            path: "piece",
            element: PrivateRoute(<Piece reload={reload} setReload={setReload} />, GROUP.admin, PERMISSION.product),
            children: [
              { path: ":idProduct", element: PrivateRoute(<PieceContent setReload={setReload} />, GROUP.admin, PERMISSION.product) },
              { path: ":idProduct/activity", element: PrivateRoute(<PieceActivity />, GROUP.admin, PERMISSION.product) }
            ]
          },
          { path: "piece/add", element: PrivateRoute(<PieceAdd />, GROUP.admin, PERMISSION.product) },
          { path: "piece/:idProduct/update", element: PrivateRoute(<PieceUpdate />, GROUP.admin, PERMISSION.product) },
          {
            path: "piece/:idProduct/material",
            element: PrivateRoute(<PieceMaterial />, GROUP.admin, PERMISSION.product),
            children: [
              { path: "add", element: PrivateRoute(<PieceMaterialAdd />, GROUP.admin, PERMISSION.product) },
            ]
          },

          {
            path: "product-price",
            element: PrivateRoute(<ProductPrice />, GROUP.crud_sav, ["PriceRead"]),
            children: [
              { path: ":idProduct", element: PrivateRoute(<ProductPriceContent setReload={setReload} />, GROUP.crud_sav, ["PriceRead"]) }
            ]
          },
          { path: "product-price/:idProduct/add", element: PrivateRoute(<ProductPriceAdd />, GROUP.crud_sav, ["PriceCreate"]) },
          { path: "product-price/:idProduct/update", element: PrivateRoute(<ProductPriceUpdate />, GROUP.crud_sav, ["PriceUpdate"]) },


          {
            path: "survey",
            element: PrivateRoute(<Survey reload={reload} setReload={setReload} />, GROUP.crud_sav, ["SurveyRead"]),
            children: [
              { path: ":idAddress", element: PrivateRoute(<SurveyContent />, GROUP.crud_sav), }
            ]
          },


          { path: "test", element: PrivateRoute(<Test />, GROUP.admin) },

          { path: "timesheet", element: PrivateRoute(<Timesheet />, GROUP.rh) },
          { path: "timesheet/add", element: PrivateRoute(<TimesheetAdd />, GROUP.rh) },
          {
            path: "timesheet/:idTimesheet/update",
            element: PrivateRoute(<TimesheetUpdate />, GROUP.rh)
          },
          { path: "timesheet/:idWeek/print", element: PrivateRoute(<TimesheetPrint />, GROUP.rh) },

          {
            path: "tool",
            element: PrivateRoute(<Tool reload={reload} setReload={setReload} />, GROUP.admin, PERMISSION.product),
            children: [
              { path: ":idProduct", element: PrivateRoute(<ToolContent setReload={setReload} />, GROUP.admin, PERMISSION.product) },
              { path: ":idProduct/activity", element: PrivateRoute(<ToolActivity />, GROUP.admin, PERMISSION.product) }
            ]
          },
          { path: "tool/add", element: PrivateRoute(<ToolAdd />, GROUP.admin, PERMISSION.product) },
          { path: "tool/:idProduct/update", element: PrivateRoute(<ToolUpdate />, GROUP.admin, PERMISSION.product) },

          { path: "ui", element: PrivateRoute(<Ui />, GROUP.admin) },


          {
            path: "users",
            element: PrivateRoute(<Users reload={reload} setReload={setReload} />, GROUP.crud_sav),
            children: [
              { path: "add", element: <UserAdd setReload={setReload} /> },
              {
                path: ":idUser",
                element: <UserContent reload={reload} setReload={setReload} />,
                children: [
                  { path: "update", element: <UserUpdate setReload={setReload} /> },
                  { path: "update-password", element: <UserUpdatePassword /> },
                  { path: "holiday", element: <UserHoliday /> },

                  // CONTACT
                  { path: "contact/add", element: <UserContactAdd setReload={setReload} /> },
                  { path: "contact/:idContact/update", element: <UserContactUpdate setReload={setReload} /> }
                ]
              },
            ]
          },

          {
            path: "vehicle",
            element: PrivateRoute(<Vehicle reload={reload} setReload={setReload} />, GROUP.admin, ["VehicleRead"]),
            children: [
              { path: ":idVehicle", element: <VehicleContent setReload={setReload} /> },
              { path: "report", element: <VehicleReport /> },

              { path: "add", element: PrivateRoute(<VehicleAdd setReload={setReload} />, GROUP.admin) },
              { path: ":idVehicle/update", element: PrivateRoute(<VehicleUpdate setReload={setReload} />, GROUP.admin, ["VehicleCheckCreate"]) },

              { path: ":idVehicle/check-add", element: PrivateRoute(<VehicleCheckAdd setReload={setReload} />, GROUP.admin, ["VehicleCheckCreate"]) }
            ]
          },


          { path: "warehouse", element: <Warehouse /> },
          { path: "warehouse/add", element: PrivateRoute(<WarehouseAdd />, GROUP.admin) },
          { path: "warehouse/:idWarehouse", element: <WarehouseContent /> },
          { path: "warehouse/:idWarehouse/update", element: PrivateRoute(<WarehouseUpdate />, GROUP.admin) },

          {
            path: "warehouse/:idWarehouse/insufficient",
            element: PrivateRoute(<WarehouseInsufficient />, GROUP.admin, PERMISSION.storage)
          },
          {
            path: "warehouse/:idWarehouse/outside",
            element: PrivateRoute(<WarehouseOutside />, GROUP.admin, PERMISSION.storage)
          },
          {
            path: "warehouse/:idWarehouse/restore",
            element: PrivateRoute(<WarehouseRestore />, GROUP.admin, PERMISSION.storage)
          },

          {
            path: "warehouse/:idWarehouse/activity",
            element: <WarehouseContent />,
            children: [
              { path: "", element: PrivateRoute(<WarehouseActivity />, GROUP.admin, ["InventoryGlobal"]) },
            ]
          },

          { path: "warehouse/:idWarehouse/order", element: PrivateRoute(<WarehouseOrder />, GROUP.admin) },

          { path: "warehouse/:idWarehouse/inventory-picking", element: PrivateRoute(<WarehouseInventoryPicking />, GROUP.admin, ["InventoryGlobal"]) },
          { path: "warehouse/:idWarehouse/inventory-global", element: PrivateRoute(<WarehouseInventoryGlobal />, GROUP.admin, ["InventoryPicking"]) },
          { path: "warehouse/:idWarehouse/putback", element: <WarehousePutBack /> },
          { path: "warehouse/:idWarehouse/resupply", element: PrivateRoute(<WarehouseResupply />, GROUP.admin, ["InventoryResupply"]) },
          { path: "warehouse/:idWarehouse/takeout", element: <WarehouseTakeOut /> },

          {
            path: "warehouse/:idWarehouse/storage",
            element: <StorageMap reload={reload} setReload={setReload} />,
            children: [
              { path: "", element: <Storage reload={reload} setReload={setReload} /> },
              { path: ":idStorage", element: <StorageContent reload={reload} setReload={setReload} /> },

              { path: "add", element: PrivateRoute(<StorageAdd setReload={setReload} />, GROUP.admin, PERMISSION.storage) },
              { path: ":idStorage/update", element: PrivateRoute(<StorageUpdate setReload={setReload} />, GROUP.admin, PERMISSION.storage) },
              { path: ":idStorage/label", element: PrivateRoute(<StorageContentLabel />, GROUP.admin, PERMISSION.product) },

              { path: ":idStorage/repair", element: PrivateRoute(<StorageRepair reload={reload} setReload={setReload} />, GROUP.crud_tec) },
            ]
          },

          {
            path: "zone",
            element: <Zone reload={reload} setReload={setReload} />,
            children: [
              { path: "add", element: PrivateRoute(<ZoneAdd setReload={setReload} />, GROUP.admin) },
              { path: ":idZone", element: <ZoneContent setReload={setReload} /> },
              { path: ":idZone/update", element: PrivateRoute(<ZoneUpdate setReload={setReload} />, GROUP.admin) }
            ]
          }
        ]
      }
    ]
  )
}

export function PrivateRoute(children: JSX.Element, roles?: Array<String>, permissions?: Array<String>) {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { token, setToken } = useToken();

  const isAuthenticated = () => { return true }
  //const { isAuthenticated, user, loading } = useSelector(state => state.auth);
  /*
          if (loading) {
              return <p className="container">Checking auth..</p>;
          }*/

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (roles) {
    let userHasRequiredRight = false

    // Right required
    if (permissions && token.Permission) {

      token.Permission.map(p => {

        permissions.includes(p.Permission) ? userHasRequiredRight = true : ""

      })

    }

    // Role required
    const userHasRequiredRole = token && roles.includes(token.Role) ? true : false;

    // If none redirect
    if (!userHasRequiredRole && !userHasRequiredRight) {
      return <Navigate to="/" state={{ from: location }} />;
    }
    /*
        if (isAuthenticated && !userHasRequiredRole) {
            return <AccessDenied />; // build your won access denied page (sth like 404)
        }
    */
  }


  return children;
};
