import React, { useEffect, useState } from "react";
import { CardBtnLink, DocumentTypeIcon, Header, PrivateLinkAdd, dateFormat } from "../../../common/common";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import $ from 'jquery'
import { IAddress, IDocument } from "../../../common/types";
import { reqGet } from "../../../../service/apiRequest";
import { GROUP, LAYOUT } from "../../../common/constants";

export default function AddressDocument({ reload, setReload }) {
    const location = useLocation()
    const params = useParams()
    const idAddress = params.idAddress

    const [address, setAddress] = useState<IAddress>()
    const [documents, setDocuments] = useState<Array<IDocument>>([])

    useEffect(() => {
        reqGet("address/" + idAddress).then(res => setAddress(res))
        reqGet("document/address/" + idAddress).then(res => setDocuments(res))
    }, [idAddress]);

    useEffect(() => {
        if (reload) {
            reqGet("address/" + idAddress).then(res => setAddress(res))
            reqGet("document/address/" + idAddress).then(res => setDocuments(res))

            setReload(false)
        }
    }, [reload]);

    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/address/" + idAddress + "/document") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    return <div className="h-100 container-fluid">
        <div className="row h-100">

            <div className={display + " col-xl-7 col-h-fill"}>

                <div className="body-container">

                    <header className="header">
                        <div>
                            <h2 className="section-title">Documents</h2>
                            <Link to={"/address/" + idAddress} className="link-nav">{"<"} Retour</Link>
                        </div>

                        {PrivateLinkAdd("Add", GROUP.crud_sav, ["AddressDocumentAdd"])}
                    </header>

                    <section className="body">


                        <div className="d-flex flex-column flex-grow-1">
                            <div className="row row-cols-2">

                                <div className="col">
                                    <CardBtnLink path={"/address/" + idAddress + "/report"} title="Rapport" icon="bi bi-clipboard2-check" />
                                </div>

                                <div className="col">

                                </div>

                            </div>
                        </div>

                        <div className="d-flex flex-column flex-grow-1">

                            {documents.length > 0 && <>
                                <header className="list-item bg-none mb-0 h-100">

                                    <header className="list-item-start">
                                    </header>

                                    <section className="list-item-data pe-0">
                                        <div className="row align-items-center">

                                            <div className="col-4">
                                                <h6 className="item-title">Nom</h6>
                                            </div>

                                            <div className="col-4">
                                                <h6 className="item-title">Date d'ajout</h6>
                                            </div>

                                            <div className="col-4">
                                                <h6 className="item-title">Utilisateur</h6>
                                            </div>
                                        </div>

                                    </section>

                                </header>


                                <div className="list list-hover d-flex flex-grow-1">

                                    {documents?.map(d => {
                                        return <Link key={d.DocumentID} to={d.DocumentID.toString()} className="list-item">

                                            <header className="list-item-start">
                                                <DocumentTypeIcon docName={d?.DocumentName} />
                                            </header>

                                            <div className="list-item-data px-3">
                                                <div className="row h-100 align-items-center">

                                                    <div className="col-4">
                                                        <h6 className="text-truncate mb-0">{d.DocumentTitle}</h6>
                                                    </div>

                                                    <div className="col-4">
                                                        <p className="mb-0">{dateFormat(d.DocumentDateAdded, "dd/MM/yyyy hh:mm")}</p>
                                                    </div>

                                                    <div className="col-4">
                                                        <p className="text-truncate mb-0">{d?.UserID && (d.FirstName + " " + d.Name)}</p>
                                                    </div>

                                                </div>

                                            </div>

                                        </Link>
                                    })}

                                </div>
                            </>
                            }

                        </div>

                    </section>

                </div>

            </div>

            <div className="col-lg-5">
                <Outlet />
            </div>

        </div>
    </div>
}