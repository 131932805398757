import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { IProduct, IBrand, ISupplier } from '../../../common/types'
import { HeaderRow, ButtonSubmit } from '../../../common/common'
import { PRODUCT_TYPE } from '../../../common/constants';

const validationSchema = yup.object({
    ProductModel: yup.string().required("Ce champ est obligatoire"),
    ProductReference: yup.string().required("Ce champ est obligatoire"),
    BrandID: yup.string().nullable(),
    SupplierID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    Image: yup.string().nullable()
})

const initialValues = {
    ProductType: PRODUCT_TYPE.PIECE,
    ProductModel: "",
    ProductReference: "",
    BrandID: null,
    SupplierID: null,
    ProductImage: ""
}

export default function PieceAdd() {
    const navigate = useNavigate()
    const token = getToken()

    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IProduct>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [brand, setBrand] = useState<Array<IBrand>>([])
    const [supplier, setSupplier] = useState<Array<ISupplier>>([])

    useEffect(() => {
        reqGet("brand").then(res => {
            setBrand(res)
            setValue("BrandID", res[0].BrandID)
        })
        reqGet("supplier").then(res => setSupplier(res))

    }, [])

    const onSubmit: SubmitHandler<IProduct> = data => {

        var d = { ...data, ActivityUserID: token.UserID, ProductReference: data.ProductReference?.replace(/\s+/g, '') }

        reqPost('product', d).then((res) => {
            navigate('/piece/' + res)
        })

    };

    return <div className="body-container content">

        {HeaderRow(
            <h3 className="section-title mb-1">Ajouter une pièce</h3>,
            <button className="btn link-nav" onClick={() => navigate(-1)}>{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-xl-6 col-md-8 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="row mb-3">

                            <div className="col col-6">
                                <label htmlFor="ProductModel" className="form-label">Modèle *</label>
                                <input type="text" className={"form-control " + (errors?.ProductModel ? "is-invalid" : "")} {...register("ProductModel")} />

                                {errors.ProductModel?.message && <p className="mt-2 text-danger">{errors.ProductModel?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="ProductReference" className="form-label">Référence *</label>
                                <input type="text" className={"form-control " + (errors?.ProductReference ? "is-invalid" : "")} {...register("ProductReference")} />

                                {errors.ProductReference?.message && <p className="mt-2 text-danger">{errors.ProductReference?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="BrandID" className="form-label">Marque</label>
                                <select className={"form-select" + (errors?.BrandID ? "is-invalid" : "")} id="" {...register("BrandID")}>
                                    <option value=""></option>
                                    {brand.map(b => {
                                        return <option key={b.BrandID} value={b.BrandID}>{b.Name}</option>
                                    })}
                                </select>

                                {errors.BrandID?.message && <p className="mt-2 text-danger">{errors.BrandID?.message}</p>}
                            </div>

                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>

                <div id="alert" className="d-none alert alert-danger align-self-end position-absolute w-75" role="alert">
                    A simple danger alert—check it out!
                </div>
            </div >


        </div>
    </div>
}