import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import $ from 'jquery'

import { getToken } from '../../../../useToken'
import { reqGet, reqPut } from '../../../../service/apiRequest'
import { IPrice, IProduct, ISupplier } from '../../../common/types'
import { HeaderRow, ButtonSubmit } from '../../../common/common'

const validationSchema = yup.object({
    SupplierID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    PriceSelling: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()
})

const initialValues = {
    PriceID: 0,
    SupplierID: null,
    Price: null,
    PriceBuying: null,
    PriceSelling: null,
    PriceAdded: ""
}

export default function PriceUpdate() {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idProduct = params.idProduct

    const { register, formState: { errors }, handleSubmit, setValue, getValues, watch } = useForm<IPrice>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [product, setProduct] = useState<IProduct>()
    const [supplier, setSupplier] = useState<Array<ISupplier>>([])

    useEffect(() => {
        reqGet("supplier").then(res => { setSupplier(res) })
    }, [])

    useLayoutEffect(() => {
        if (supplier) {
            reqGet("product/" + idProduct).then(res => {
                setProduct(res)

                setValue("PriceID", res.PriceID)
                setValue("SupplierID", res.SupplierID ?? null)
                setValue("Price", res.Price ?? null)
                setValue("PriceBuying", res.PriceBuying ?? null)
                setValue("PriceSelling", res.PriceSelling ?? null)
            })
        }
    }, [supplier])

    const onSubmit: SubmitHandler<IPrice> = data => {
        data.Price = parseFloat($("#inputPrice").val())
        data.PriceBuying = parseFloat($("#inputPriceBuying").val())

        reqPut('price/' + data.PriceID, { ...data, ProductID: idProduct, ProductModel: product?.ProductModel, ActivityUserID: token.UserID }).then((res) => {
            navigate('/product-price/' + params.idProduct)
        })

    };

    function CalculPriceSelling() {
        const price = parseInt($("#inputPrice").val());
        const priceBuying = parseInt($("#inputPriceBuying").val());
        var total = 0

        if (price && priceBuying) {

            if (priceBuying <= 20 && price <= 25) {
                total = priceBuying + 25

            } else if (Math.round(priceBuying * 100 / price) <= 35) {
                total = price

            } else if (priceBuying <= 150) {
                total = priceBuying * 1.7

            } else if (priceBuying <= 400) {
                total = priceBuying * 1.6

            } else if (priceBuying <= 700) {
                total = priceBuying * 1.5

            } else if (priceBuying <= 1000) {
                total = priceBuying * 1.4

            } else {
                total = priceBuying * 1.3
            }

            setValue("PriceSelling", Math.round(total))
        }

    }

    return <div className="body-container">

        {HeaderRow(
            <h3 className="section-title mb-1">Modifier le prix actuel</h3>,
            <button className="btn link-nav" onClick={() => navigate(-1)}>{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="row mb-3">

                            <div className="col-4">
                                <label htmlFor="SupplierID" className="form-label">Fournisseur *</label>
                                <select className={"form-select " + (errors?.SupplierID ? "is-invalid" : "")} id="" {...register("SupplierID")}>
                                    <option value=""></option>
                                    {supplier.map(s => {
                                        return <option key={s.SupplierID} value={s.SupplierID}>{s.Name}</option>
                                    })}
                                </select>

                                {errors.SupplierID?.message && <p className="mt-2 text-danger">{errors.SupplierID?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="Price" className="form-label">Prix facial</label>
                                <input type="number" id="inputPrice" className={"form-control " + (errors?.Price ? "is-invalid" : "")} step="any" defaultValue={getValues("Price") ?? undefined} onChange={(e) => { setValue("Price", parseInt(e.target.value)); CalculPriceSelling() }} />

                                {errors.Price?.message && <p className="mt-2 text-danger">{errors.Price?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="PriceBuying" className="form-label">Prix d'achat</label>
                                <input type="number" id="inputPriceBuying" className={"form-control " + (errors?.PriceBuying ? "is-invalid" : "")} step="any" defaultValue={getValues("PriceBuying") ?? undefined} onChange={(e) => { setValue("PriceBuying", parseInt(e.target.value)); CalculPriceSelling() }} />

                                {((watch("PriceBuying") ?? 0) > 0 && (watch("Price") ?? 0) > 0) &&
                                    (
                                        Math.round((watch("PriceBuying") ?? 0) * 100 / (watch("Price") ?? 0)) <= 100
                                            ? <p className="text-success mt-2">-{Math.round(100 - ((watch("PriceBuying") ?? 0) * 100 / (watch("Price") ?? 0)))}%</p>
                                            : <p className="text-danger mt-2">+{Math.round(100 - ((watch("PriceBuying") ?? 0) * 100 / (watch("Price") ?? 0)))}%</p>
                                    )
                                }

                                {errors.PriceBuying?.message && <p className="mt-2 text-danger">{errors.PriceBuying?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="PriceSelling" className="form-label">Prix de vente</label>
                                <input type="number" id="inputPriceSelling" className={"form-control " + (errors?.PriceSelling ? "is-invalid" : "")} {...register("PriceSelling")} />


                                {
                                    ((watch("PriceSelling") ?? 0) > 0) &&
                                    <p className="text-success mt-2">+{Math.round((watch("PriceSelling") ?? 0) - (watch("Price") ?? 0))}</p>
                                }

                                {errors.PriceSelling?.message && <p className="mt-2 text-danger">{errors.PriceSelling?.message}</p>}
                            </div>
                        </div>


                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>
            </div >


        </div>
    </div>
}