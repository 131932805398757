import React, { useEffect } from 'react'
import { faker } from '@faker-js/faker'

export default function ConstructionData() {
    faker.locale = 'fr_CH'
    return <>


        <div className="d-flex align-items-start">
            <h2 className="section-title me-3">Données de bases </h2>
            <span className="badge text-bg-success">Terminé</span>
        </div>

        <div className="row">


            <div className="col-5">

                <div className="card">

                    <div className="card-header">
                        <p className="card-title">Adresse</p>
                    </div>

                    <div className="card-body">
                        <h3 className="mb-1">{faker.address.streetAddress()}</h3>
                        <h3>{faker.address.zipCode()} {faker.address.cityName()}</h3>
                    </div>

                </div>

                <div className="row">

                    <div className="col-6">

                        <div className="card">

                            <div className="card-header">
                                <p className="card-title">Référence chantier</p>
                            </div>

                            <div className="card-body">
                                <h3 className="mb-0">RV 20_</h3>
                            </div>

                        </div>

                    </div>

                    <div className="col-6">

                        <div className="card">

                            <div className="card-header">
                                <p className="card-title">N° Abacus</p>
                            </div>

                            <div className="card-body">
                                <h3 className="mb-0">106_</h3>
                            </div>

                        </div>

                    </div>

                    <div className="col-6 mb-0">

                        <div className="card">

                            <div className="card-header">
                                <p className="card-title">Date de début</p>
                            </div>

                            <div className="card-body">
                                <h3 className="mb-0">23.05.2022</h3>
                            </div>

                        </div>
                    </div>

                    <div className="col-6 mb-0">

                        <div className="card">

                            <div className="card-header">
                                <p className="card-title">Date de fin</p>
                            </div>

                            <div className="card-body">
                                <h3 className="mb-0"></h3>
                            </div>

                        </div>

                    </div>
                </div>

            </div>







            <div className="col-7">

                <div className="card card-fill">

                    <div className="card-header">
                        <p className="card-title">Description</p>
                    </div>

                    <div className="card-body">
                        <p>{faker.lorem.paragraph()}</p>
                    </div>

                </div>

            </div>


            <div className="col-12">

                <div className="card">

                    <div className="card-header">
                        <p className="card-title">???</p>
                    </div>

                    <div className="card-body">

                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Fonction</th>
                                    <th>Société</th>
                                    <th>Nom/Prénom</th>
                                    <th>Adresse</th>
                                    <th>Téléphone</th>
                                    <th>Mail</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <th>Propriétaire/Régie</th>
                                    <td>{faker.company.companyName()}</td>
                                    <td>{faker.name.findName()}</td>
                                    <td>{faker.address.streetAddress()}</td>
                                    <td>{faker.phone.phoneNumber()}</td>
                                    <td>{faker.internet.email()}</td>
                                </tr>

                                <tr>
                                    <th>Locataire</th>
                                    <td>{faker.company.companyName()}</td>
                                    <td>{faker.name.findName()}</td>
                                    <td>{faker.address.streetAddress()}</td>
                                    <td>{faker.phone.phoneNumber()}</td>
                                    <td>{faker.internet.email()}</td>
                                </tr>

                                <tr>
                                    <th>MO/Architecte</th>
                                    <td>{faker.company.companyName()}</td>
                                    <td>{faker.name.findName()}</td>
                                    <td>{faker.address.streetAddress()}</td>
                                    <td>{faker.phone.phoneNumber()}</td>
                                    <td>{faker.internet.email()}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                </div>

            </div>


            <div className="col-12">

                <div className="card">

                    <div className="card-header">
                        <p className="card-title">Intervenants</p>
                    </div>

                    <div className="card-body">

                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Fonction</th>
                                    <th>Société</th>
                                    <th>Nom/Prénom</th>
                                    <th>Numéro de bon</th>
                                    <th>Budget heures</th>
                                    <th>Statut</th>
                                    <th>Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <th>Responsable projet</th>
                                    <td>{faker.company.companyName()}</td>
                                    <td>{faker.name.findName()}</td>
                                    <td>{faker.random.numeric(6)}</td>
                                    <td>{faker.finance.amount(500, 10000, 0)} CHF</td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <th>Monteur chauffage</th>
                                    <td>{faker.company.companyName()}</td>
                                    <td>{faker.name.findName()}</td>
                                    <td>{faker.random.numeric(6)}</td>
                                    <td>{faker.finance.amount(500, 10000, 0)} CHF</td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <th>Technicien</th>
                                    <td>{faker.company.companyName()}</td>
                                    <td>{faker.name.findName()}</td>
                                    <td>{faker.random.numeric(6)}</td>
                                    <td>{faker.finance.amount(500, 10000, 0)} CHF</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                </div>

            </div>



        </div>









        <div className="mt-5 mb-3">
            <h2 className="section-title d-inline-block mb-0 me-3">Coordination</h2>
            <span className="badge text-bg-success">Terminé</span>

            <h4 className="section-subtitle">Elaboration des plans, persements et commande du matériel</h4>

        </div>

        <div className="row">

            <div className="col-6">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Plans</h5>
                    </div>

                    <div className="card-body">


                    </div>

                </div>

            </div>

            <div className="col-6">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Persements</h5>
                    </div>

                    <div className="card-body">

                    </div>

                </div>

            </div>

            <div className="col-12">

                <div className="card mb-4">

                    <div className="card-header">
                        <p className="card-title">Matériel</p>
                    </div>

                    <div className="card-body">

                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Marque</th>
                                    <th>Modèle</th>
                                    <th>Type</th>
                                    <th>Puissance/contenance</th>
                                    <th>Quantité</th>
                                    <th>MES</th>
                                    <th>Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>

                        </table>

                    </div>

                </div>

                <div className="card">

                    <div className="card-header">
                        <p className="card-title">Fournisseur</p>
                    </div>

                    <div className="card-body">

                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Fournisseur</th>
                                    <th>Fonction</th>
                                    <th>Nom/Prénom</th>
                                    <th>Adresse</th>
                                    <th>Téléphone</th>
                                    <th>Email</th>
                                    <th>MES</th>
                                    <th>Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>

                        </table>

                    </div>

                </div>
            </div>


        </div>





        <div className="d-flex align-items-start">
            <h2 className="section-title me-3">Exécution</h2>
            <span className="badge text-bg-primary">En cours</span>

        </div>


        <div className="row">

            <div className="col">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Tâche</h5>
                    </div>

                    <div className="card-body d-flex justify-content-between">
                        <h3>Réception matériel</h3>
                        <input className="check-big" type="checkbox" id="scales" name="scales" defaultChecked />
                    </div>

                </div>

            </div>

            <div className="col-8">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Commentaires</h5>
                    </div>

                    <div className="card-body">
                        <p className="mb-0">Il manque</p>
                    </div>

                </div>

            </div>

        </div>


        <div className="row">

            <div className="col">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Tâche</h5>
                    </div>

                    <div className="card-body d-flex justify-content-between">
                        <h3>Local chaufferie</h3>
                        <input className="check-big" type="checkbox" id="scales" name="scales" defaultChecked />
                    </div>

                </div>

            </div>

            <div className="col-8">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Commentaires</h5>
                    </div>

                    <div className="card-body">
                        <p className="mb-0">{faker.lorem.paragraph()}</p>
                    </div>

                </div>

            </div>

        </div>

        <div className="row">

            <div className="col">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Tâche</h5>
                    </div>

                    <div className="card-body d-flex justify-content-between">
                        <h3>Distribution</h3>
                        <input className="check-big" type="checkbox" id="scales" name="scales" defaultChecked />
                    </div>

                </div>

            </div>

            <div className="col-8">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Commentaires</h5>
                    </div>

                    <div className="card-body">
                        <p className="mb-0">***** Une superbe distribution de chaleur sans problèmes ! Sublime !</p>
                    </div>

                </div>

            </div>

        </div>

        <div className="row">

            <div className="col">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Tâche</h5>
                    </div>

                    <div className="card-body d-flex justify-content-between">
                        <h3>Radiateur</h3>
                        <input className="check-big" type="checkbox" id="scales" name="scales" />
                    </div>

                </div>

            </div>

            <div className="col-8">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Commentaires</h5>
                    </div>

                    <div className="card-body">
                        <p className="mb-0"></p>
                    </div>

                </div>

            </div>

        </div>



        <div className="d-flex align-items-start">
            <h2 className="section-title me-3">Raccordement éléctrique</h2>
            <span className="badge text-bg-primary">En cours</span>

        </div>

        <div className="row">

            <div className="col-4">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Schéma éléctrique</h5>
                    </div>

                    <div className="card-body">
                        <p>fichier.pdf <i className="bi bi-download"></i></p>
                        <p><b>Envoyé à l'éléctricien:</b></p>

                        <dl className="row">
                            <dt className="col-sm-4">Status</dt>
                            <dd className="col-sm-8">non</dd>

                            <dt className="col-sm-4">email</dt>
                            <dd className="col-sm-8">electricien@foudre.com</dd>
                        </dl>
                    </div>

                </div>

            </div>

        </div>




        <div className="d-flex align-items-start">
            <h2 className="section-title me-3">Mise en service</h2>
            <span className="badge text-bg-warning">En attente</span>

        </div>

        <div className="row">



        </div>


        <div className="row">

            <div className="col-4">

                <div className="d-flex align-items-start">
                    <h2 className="section-title me-3">Fin de chantier</h2>
                    <span className="badge text-bg-warning">En attente</span>

                </div>

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Liste</h5>
                    </div>

                    <div className="card-body">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">Remplissage purge</label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">Etiquettage</label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">Dossier révision</label>
                        </div>

                    </div>

                </div>

            </div>

            <div className="col-4 offset-md-2">

                <div className="d-flex align-items-start">
                    <h2 className="section-title me-3">Retouche</h2>
                    <span className="badge text-bg-warning">En attente</span>

                </div>

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Liste</h5>
                    </div>

                    <div className="card-body">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">Retouche 1</label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">Retouche 2</label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">Retouche 3</label>
                        </div>

                    </div>

                </div>

            </div>

        </div>













        <div className="d-flex align-items-start">
            <h2 className="section-title me-3">Contrat d'entretien/maintenance</h2>
            <span className="badge text-bg-warning">En attente</span>

        </div>

        <div className="row">

        </div>


    </>
}