import React, { useState, useEffect } from 'react'
import { Routes, Route, Link, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { HeaderRow } from '../../../common/common'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { ISubcontractor } from '../../../common/types'
import { getToken } from '../../../../useToken';

const validationSchema = yup.object({
    Company: yup.string().required("Ce champ est obligatoire"),
    Email: yup.string().email("L'email n'est pas valide").nullable(),
    Phone: yup.string().nullable(),
})

const initialValues = {
    Company: "",
    Email: null,
    Phone: null
}

export default function SubcontractorAdd() {
    let navigate = useNavigate()
    const token = getToken()

    const { register, formState: { errors }, handleSubmit, watch } = useForm<ISubcontractor>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const onSubmit: SubmitHandler<ISubcontractor> = data => {

        reqPost('subcontractor', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate(-1)
        })

    };

    return <div className="body-container h-100">

        {HeaderRow(
            <h3 className="section-title mb-1">Ajouter un sous-traitant</h3>,
            <button onClick={() => navigate(-1)} className="btn link-nav">{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="Company" className="form-label">Entrepise *</label>
                            <input className={"form-control " + (errors?.Company ? "is-invalid" : "")} {...register("Company")} />

                            {errors.Company?.message && <p className="mt-2 text-danger">{errors.Company?.message}</p>}

                        </div>
                        <div className="mb-3">

                            <label htmlFor="Email" className="form-label">Email</label>
                            <input className={"form-control " + (errors?.Email ? "is-invalid" : "")} {...register("Email")} />

                            {errors.Email?.message && <p className="mt-2 text-danger">{errors.Email?.message}</p>}

                        </div>

                        <div className="mb-3">

                            <label htmlFor="Phone" className="form-label">Téléphone</label>
                            <input className={"form-control " + (errors?.Phone ? "is-invalid" : "")} {...register("Phone")} />

                            {errors.Phone?.message && <p className="mt-2 text-danger">{errors.Phone?.message}</p>}

                        </div>



                        <p><small>Les champs avec une * sont obligatoire</small></p>

                        <input type="submit" className="btn btn-primary" value="Ajouter" />

                    </form>

                </div>

                <div id="alert" className="d-none alert alert-danger align-self-end position-absolute w-75" role="alert">
                    A simple danger alert—check it out!
                </div>
            </div >


        </div>
    </div>
}