import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, Outlet, useLocation, } from "react-router-dom";
import { reqGet, reqDelete } from "../../../service/apiRequest";

import { getToken } from '../../../useToken'
import { PrivateComponent, ServiceBadge } from "../../common/common";
import { GROUP, ICONS } from "../../common/constants"
import { IAddress } from "../../common/types"

export default function AddressController({ reload, setReload }) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams();
    const idAddress = params.idAddress;
    const token = getToken()

    const [address, setAddress] = useState<IAddress>();

    useEffect(() => {
        reqGet("address/" + idAddress).then((res) => {
            setAddress(res);
        }, (rej) => {
            navigate("/address")
        });
    }, [idAddress]);


    useEffect(() => {
        if (reload) {
            reqGet("address/" + idAddress).then((res) => {
                setAddress(res);
            }, (rej) => {
                navigate("/address")
            });

            setReload(false)
        }
    }, [reload]);

    function RemoveAddress() {
        reqDelete("address/" + idAddress, { data: { ActivityUserID: token.UserID } }).then(res => {
            setReload(true)
            navigate("/address")
        })
    }

    const LinkBack = () => {
        if (location?.state?.back)
            return <Link to={location.state.back} className="link-nav">{"<"} Retour</Link>
        else
            return <Link to="/address" className="link-nav">{"<"} Retour</Link>
    }


    return (
        <>
            <section className="body-container">

                {/* Remove modal */}
                <div className="modal fade" id="modalRemoveAddress" tabIndex={-1} aria-labelledby="modalRemoveAddress" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="modalRemoveAddress">Supprimer</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p>Êtes-vous sur de vouloir supprimer :</p>
                                <h6 className="item-title"><b>{address?.AddressLine}, {address?.ZipCode} {address?.City}</b></h6>
                                {address?.AddressComplementary && <p className="item-text"><i>{address?.AddressComplementary}</i></p>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                                <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => RemoveAddress()}>Supprimer</button>
                            </div>
                        </div>
                    </div>
                </div>


                {(location.pathname != "/address/" + idAddress) &&
                    <header className="px-3 py-1">
                        <Link to={"/address/" + idAddress}>
                            <h5 className="d-inline text-color-darker text-hover me-3 link-underline">{address?.AddressLine}, {address?.ZipCode} {address?.City}</h5>
                        </Link>

                        <h6 className="d-inline text-light">{address?.AddressComplementary}</h6>
                    </header>
                }


                {(location.pathname == "/address/" + idAddress) &&

                    <header className="header content">
                        <div className="pe-3">
                            <h3 className="section-title mb-0">
                                {address?.AddressLine}, {address?.ZipCode} {address?.City}
                            </h3>

                            <h5 className="section-subtitle me-3 mb-1"><i>{address?.AddressComplementary}</i></h5>

                            {(address?.Services && address.Services.length > 0) &&
                                <div className="mb-2">
                                    {address?.Services?.map((s, k) => {
                                        if (!s.InstallationID)
                                            return <ServiceBadge service={s.Service} className="me-2" key={k} />
                                    })}
                                </div>
                            }

                            <LinkBack />
                        </div>

                        {PrivateComponent(
                            <div className="dropdown">
                                <button className="btn btn-sm btn-outline rounded-3" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                                <ul className="dropdown-menu">
                                    {PrivateComponent(
                                        <li>
                                            <Link to="update" className="dropdown-item mb-2"><i className={ICONS.UPDATE + " me-2"}></i>Modifier</Link>
                                        </li>,
                                        undefined, ["AddressUpdate"]
                                    )}
                                    {PrivateComponent(
                                        <li>
                                            <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target="#modalRemoveAddress">
                                                <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                            </button>
                                        </li>,
                                        GROUP.dev, ["AddressDelete"]
                                    )}
                                </ul>
                            </div>,
                            GROUP.crud_sav
                        )}
                    </header>
                }

                <section className="body">
                    <Outlet context={address} />
                </section >
            </section >
        </>
    )
}