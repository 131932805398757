import React, { useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import $ from 'jquery'

import { getToken } from '../../../useToken'
import { ButtonSubmit, Header } from '../../common/common'
import { reqPut } from "../../../service/apiRequest"
import { HashPassword } from '../../common/hash'

interface IFormInput {
    newPassword: String,
    newPassword2: String,
    ActivityUserID: number | null
}

const validationSchema = yup.object({
    newPassword: yup.string().required("Ce champ est obligatoire"),
    newPassword2: yup.string().required('Ce champ est obligatoire').oneOf([yup.ref('newPassword')], 'Le mot de passe ne correspond pas'),
})

const initialValues = {
    newPassword: '',
    newPassword2: '',
    ActivityUserID: null
}

function TogglePassword() {
    var input = $(".inputPassword");
    if (input[0].type === "password") {
        input[0].type = "text";
        input[1].type = "text";
    } else {
        input[0].type = "password";
        input[1].type = "password";
    }
}

export default function PasswordUpdate() {
    const navigate = useNavigate()

    const token = getToken()

    const params = useParams()
    const userID = params.idUser

    const { register, formState: { errors }, handleSubmit, setValue, watch } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        setValue("ActivityUserID", token.UserID)
    })

    const onSubmit: SubmitHandler<IFormInput> = data => {
        data.newPassword = HashPassword(data.newPassword)

        reqPut('user/updatepassword/' + userID, data).then((res) => {
            navigate('/users/' + userID)
        })

    }

    return <section className="body-container">

        {Header(
            <h3 className="section-title">Réinitialiser le mot de passe</h3>,
            <button onClick={() => navigate(-1)} className="btn link-nav">{'<'} Retour</button>
        )}

        <section className="body container-fluid">

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">Nouveau mot de passe *</label>
                    <input type="password" className={"inputPassword form-control " + (errors?.newPassword ? "is-invalid" : "")} {...register("newPassword")} />

                    {errors.newPassword?.message && <p className="input-msg-error">{errors.newPassword?.message}</p>}
                </div>

                <div className="mb-3">
                    <label htmlFor="newPassword2" className="form-label">Confirmer mot de passe *</label>
                    <input type="password" className={"inputPassword form-control " + (errors?.newPassword2 ? "is-invalid" : "")} {...register("newPassword2")} />

                    {errors.newPassword2?.message && <p className="input-msg-error">{errors.newPassword2?.message}</p>}
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onClick={() => TogglePassword()} />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Afficher mot de passe
                    </label>
                </div>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <ButtonSubmit />
                </div>

            </form>

        </section>

    </section>
}