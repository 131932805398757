import React from "react";

export default function HelpHome() {
    return <>

        <h2 className="section-title">Bienvenue dans l'aide</h2>

        <p className="paragaph">Cet espace est dedié </p>

    </>
}