import React from "react";
import { Link, useParams } from "react-router-dom";
import { CardBtnLink } from "../../../../common/common";
import { ICONS } from "../../../../common/constants";


export default function AddressInvoiceAdd() {
    const params = useParams()
    const idAddress = params.idAddress

    return <main className="d-flex justify-content-center">

        <div style={{ width: "300px" }}>

            <header className="mb-3">
                <h2 className="section-title mb-0">Créer facture</h2>
                <Link to={"/address/" + idAddress + "/invoice"} className="link-nav">{"<"} Retour</Link>
            </header>

            <section className="list">
                <CardBtnLink path={"construction"} title="Chantier" icon={ICONS.CONSTRUCTION} />
                <CardBtnLink path={"contract"} title="Contrat" icon={ICONS.CONTRACT} />
                <CardBtnLink path={"execution"} title="Exécution" icon={ICONS.EXECUTION} />
                <CardBtnLink path={"intervention"} title="Intervention" icon={ICONS.INTERVENTION} />
                <CardBtnLink path={"service"} title="Prestation" icon={ICONS.SERVICES} />
                <CardBtnLink path={"blank"} title="Vierge" icon={ICONS.INVOICE} />
            </section>

        </div>

    </main>
}