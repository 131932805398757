import React, { useState, useEffect } from 'react'
import { Link,  Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'

import { reqGet, reqDelete, reqPut } from '../../../service/apiRequest'
import { getToken } from '../../../useToken'
import {  PrivateComponent, CardBtnLink, dateFormat, TimeCalculation } from "../../common/common"
import { DEFAULT_IMG, GROUP, ICONS, IMAGE_PATH, LAYOUT } from "../../common/constants"
import { IContact, ITimesheet, IUser, IVehicle } from "../../common/types"
import { TimesheetCard } from '../account/account-timesheet/account-timesheet'
import { UserData } from '../account/account'

export default function UserContent({ reload, setReload }) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const idUser = params.idUser
    const token = getToken()
    const [user, setUser] = useState<IUser>()

    const [display, setDisplay] = useState<string>("")
    const [display2, setDisplay2] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.xl) {
            setDisplay('d-flex')
            setDisplay2('d-flex')
        } else {
            if (location.pathname != "/users/" + idUser) {
                setDisplay('d-none')
                setDisplay2('d-flex')
            } else {
                setDisplay('d-flex')
                setDisplay2('d-none')
            }
        }
    }
    useEffect(() => {
        reqGet("user/" + idUser).then(res => setUser(res), rej => navigate("/users"))
    }, [idUser])

    useEffect(() => {
        if (reload) {
            reqGet("user/" + idUser).then(res => setUser(res))
            setReload(false)
        }
    }, [reload])


    function RemoveUser() {
        reqDelete("user/" + user?.UserID, { data: { ActivityUserID: token.UserID } }).then(res => {
            setReload(true)
            navigate("/users")
        })
    }

    function ArchiveUser() {
        reqPut("user/" + user?.UserID + "/archive", { ActivityUserID: token.UserID, UserArchived: 1 }).then(res => {
            setReload(true)
        })
    }

    return <div className="container-fluid h-100">
        <div className="row h-100">
            <div className={display + " col-xl-6 col-h-fill px-0"}>

                <section className="body-container content">

                    <div className="modal fade" id="removeUser" tabIndex={-1} aria-labelledby="removeUser" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="removeUser">Supprimer</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>Êtes vous  sur de vouloir supprimer :</p>
                                    <p><b>{user?.Username}, {user?.FirstName} {user?.Name}</b></p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                                    <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => RemoveUser()}>Supprimer</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="archiveUser" tabIndex={-1} aria-labelledby="archiveUser" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="archiveUser">Archiver</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>Êtes vous  sur de vouloir archiver :</p>
                                    <p><b>{user?.Username}, {user?.FirstName} {user?.Name}</b></p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal" onClick={() => ArchiveUser()}>Archiver</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            <h2 className="section-title mb-1">Utilisateur</h2>
                            <Link to="/users" className="link-nav d-block mb-2">{"<"} Retour</Link>
                        </div>

                        <div className="dropdown">
                            <button className="btn btn-sm btn-outline rounded-2 m-0" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                            <ul className="dropdown-menu">

                                {PrivateComponent(
                                    <li>
                                        <Link to="update" className="dropdown-item"><i className={ICONS.UPDATE + " me-2"}></i> Modifier</Link>
                                    </li>,
                                    GROUP.rh
                                )}

                                {PrivateComponent(
                                    <li>
                                        <button className="dropdown-item" title="Archiver" data-bs-toggle="modal" data-bs-target="#archiveUser">
                                            <i className={ICONS.ARCHIVE + " me-2"}></i> Archiver
                                        </button>
                                    </li>,
                                    GROUP.rh
                                )}

                                {PrivateComponent(
                                    <li>
                                        <button className="dropdown-item btn-delete" title="Supprimer" data-bs-toggle="modal" data-bs-target="#removeUser">
                                            <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                        </button>
                                    </li>,
                                    GROUP.dev
                                )}
                            </ul>
                        </div>
                    </div>

                    <section className="body">

                        <div className="text-center mb-3">
                            <img style={{ height: "9rem", width: "9rem", borderRadius: "4.5rem", objectFit: "cover", marginBottom: "1rem" }} src={user?.UserImage ? IMAGE_PATH.USERS + user.UserImage : DEFAULT_IMG} />

                            <h5 className="text-muted mb-1">{user?.Username}</h5>
                            <h3 className='mb-1'>{user?.FirstName} {user?.Name}</h3>
                            <p className='mb-1'>{user?.Role}</p>
                            {user?.UserArchived == 1 &&
                                <div className="badge bg-warning" title='Archivé'><i className={ICONS.ARCHIVE + " me-2"}></i> Archivé</div>
                            }
                        </div>

                        <UserData user={user} expand={true} />

                        {PrivateComponent(<CardBtnLink path="update-password" title="Modifier le mot de passe" icon={ICONS.PASSWORD_UPDATE} />, GROUP.admin)}

                        {PrivateComponent(<CardBtnLink path="holiday" title="Vacances" icon={ICONS.HOLIDAY} />, GROUP.dev)}

                        {PrivateComponent(<UserContact user={user} reload={reload} setReload={setReload} />)}

                        {PrivateComponent(<UserVehicle idUser={idUser} />, GROUP.crud_sav)}

                        {PrivateComponent(<UserTimesheet idUser={idUser} />, GROUP.admin)}

                    </section>
                </section>

            </div>

            <div className={display2 + " col-lg-6 col-h-fill px-0 border-start"}>
                <Outlet context={user} />
            </div>
        </div>
    </div>
}

function UserTimesheet({ idUser }: { idUser }) {
    const [timesheet, setTimesheet] = useState<ITimesheet>()
    const [duration, setDuration] = useState<string>("00:00")

    useEffect(() => {
        reqGet("timesheet/user/" + idUser + "/today").then(res => {
            setTimesheet(res)

            const start = dateFormat(res?.TimesheetStart, "hh:mm")
            const startLunch = dateFormat(res?.TimesheetLunchStart, "hh:mm")
            const endLunch = dateFormat(res?.TimesheetLunchEnd, "hh:mm")
            const end = dateFormat(res?.TimesheetEnd, "hh:mm")

            setDuration(TimeCalculation(start, end, startLunch, endLunch))
        })
    }, [idUser])

    if (timesheet)
        return <>

            <h3 className="section-title-2">Heures aujourd'hui</h3>

            <TimesheetCard timesheet={timesheet} />
        </>

    else
        return <></>
}

function UserVehicle({ idUser }: { idUser }) {
    const [vehicles, setVehicles] = useState<Array<IVehicle>>()

    useEffect(() => {
        reqGet("vehicle/user/" + idUser).then(res => {
            setVehicles(res)
        }, rej => { })
    }, [idUser])

    return <>
        {vehicles && vehicles?.length > 0 &&
            <>
                <h3 className="section-title-2">Véhicule{(vehicles && vehicles?.length > 1) && "s (" + vehicles.length + ")"}</h3>

                {vehicles.map(v => {
                    return <React.Fragment key={v.VehicleID}>
                        <CardBtnLink
                            path={"/vehicle/" + v?.VehicleID}
                            title={(v?.VehicleNumber && ("N°" + v.VehicleNumber + " | ")) + " " + v?.Brand + " " + v?.Model}
                            text={v?.VehicleType + " " + v?.Numberplate}
                            icon={ICONS.VEHICLES} />
                    </React.Fragment>
                })}
            </>
        }
    </>
}


function UserContact({ user, reload, setReload }: { user: IUser | undefined, reload: boolean, setReload }) {
    const token = getToken()
    const navigate = useNavigate()
    const [contacts, setContacts] = useState<Array<IContact>>([])

    useEffect(() => {
        if (user?.UserID)
            reqGet("contact/user/" + user?.UserID).then(res => {
                setContacts(res)
            }, rej => setContacts([]))
    }, [user])

    useEffect(() => {
        if (reload) {
            reqGet("contact/user/" + user?.UserID).then(res => {
                setContacts(res)
            }, rej => setContacts([]))
        }
    }, [reload])

    function RemoveContact(idContact) {
        reqDelete("contact/" + idContact, { data: { UserID: user?.UserID, UserName: user?.FirstName + " " + user?.Name, ActivityUserID: token.UserID } }).then(res => {
            setReload(true)
        })
    }

    return <>
        <header className="d-flex justify-content-between align-items-start mt-3">
            <h3 className="section-title-2">Contacts</h3>

            <Link to="contact/add" className="btn btn-add"><i className={ICONS.ADD + " me-2"}></i>Ajouter contact</Link>
        </header>

        <section>
            {contacts.length > 0 && contacts?.map((c: IContact) => {
                return <div key={c.ContactID} className="card">


                    <div className="modal fade" id={"removeContact" + c.ContactID} tabIndex={-1} aria-labelledby={"removeContact" + c.ContactID} aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id={"removeContact" + c.ContactID}>Supprimer</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>Êtes vous  sur de vouloir supprimer le contact :</p>
                                    <p><b>{c?.ContactName}</b></p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                                    <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => RemoveContact(c.ContactID)}>Supprimer</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <header className="card-header">
                        <h6 className="card-title">{c.ContactName}</h6>


                        <div className="dropdown">
                            <button className="btn btn-sm btn-outline rounded-2 m-0" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                            <ul className="dropdown-menu">

                                {PrivateComponent(
                                    <li>
                                        <Link to={"contact/" + c.ContactID + "/update"} className="dropdown-item"><i className={ICONS.UPDATE + " me-2"}></i> Modifier</Link>
                                    </li>,
                                    GROUP.rh
                                )}

                                {PrivateComponent(
                                    <li>
                                        <button className="dropdown-item btn-delete" title="Supprimer" data-bs-toggle="modal" data-bs-target={"#removeContact" + c.ContactID}>
                                            <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                        </button>
                                    </li>,
                                    GROUP.rh
                                )}
                            </ul>
                        </div>
                    </header>

                    <section className='card-body'>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-1 row-cols-xxl-2">
                            <div className="col mb-3">
                                <h6 className="item-title">Téléphone</h6>

                                {c.ContactPhone &&
                                    <div>
                                        <p className="item-text d-inline me-3">{c.ContactPhone}</p>
                                        <a href={"tel:" + c.ContactPhone} className="d-inline link-nav"><i className={ICONS.PHONE_CALL}></i></a>
                                    </div>
                                }
                            </div>

                            <div className="col mb-3">
                                <h6 className="item-title">Email</h6>

                                {c.ContactEmail &&
                                    <div>
                                        <p className="item-text d-inline me-3">{c.ContactEmail}</p>
                                        <a href={"mailto:" + c.ContactEmail} className="d-inline link-nav"><i className={ICONS.EMAIL_SEND}></i></a>
                                    </div>
                                }
                            </div>

                            <div className="col-12 mb-3">
                                <h6 className="item-title">Adresse</h6>

                                {c.ContactAddress &&
                                    <div>
                                        <p className="item-text d-inline me-3">{c.ContactAddress}</p>
                                        <a href={"https://www.google.com/maps/search/?api=1&query=" + c.ContactAddress.replace(" ", "+")} target="_blank" className="d-inline link-nav"><i className={ICONS.MAP + " me-2"}></i></a>
                                    </div>
                                }
                            </div>
                        </div>

                    </section>
                </div>
            })}
        </section>
    </>
}