import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { getToken } from '../../../../useToken'
import { reqGet, reqDelete } from "../../../../service/apiRequest";
import { Header, PrivateLinkUpdate, PrivateComponent, ProductTypeLinkResolver } from '../../../common/common'
import { DEFAULT_IMG, GROUP, PERMISSION, PRODUCT_TYPE } from '../../../common/constants'
import { IProduct, IStorage, IWarehouse } from '../../../common/types'
import { StorageAvailabilityBadge } from '../../storage/storage';

interface IStorageWarehouse extends IStorage, IWarehouse { }

export default function ExchangerContent({ setReload }) {
    const navigate = useNavigate()
    const params = useParams()
    const idProduct = params.idProduct
    const token = getToken()

    const [product, setProduct] = useState<IProduct>()
    const [productStorages, setProductStorages] = useState<Array<IStorageWarehouse> | null>(null)

    useEffect(() => {
        reqGet("product/" + idProduct).then(res => {
            if (res.ProductType != PRODUCT_TYPE.EXCHANGER)
                navigate("/" + ProductTypeLinkResolver(res.ProductType) + "/" + idProduct);

                setProduct(res)
        }, rej => {
            navigate(-1)
        })
    }, [idProduct])

    useEffect(() => {

        if (product) {

            reqGet("storage/product/" + product.ProductID).then((res) => {
                setProductStorages(res)

            }, (rej) => {
                setProductStorages(null)
            })
        }

    }, [product])

    function RemoveTool() {
        reqDelete("product/" + idProduct, { data: { ActivityUserID: token.UserID } }).then(res => {
            setReload(true)
            navigate("/exchanger")
        })
    }

    return <div className="body-container">

        <div className="modal fade" id="removeModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes vous  sur de vouloir supprimer :</p>
                        <p><b>{product?.ProductModel}, {product?.ProductBrand} - {product?.ProductReference}</b></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => RemoveTool()}>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>

        {Header(
            <h2 className="section-title">Détails</h2>,
            <Link to="/exchanger" className="link-nav">{"<"} Retour</Link>,
            <>
                {PrivateLinkUpdate("/exchanger/" + product?.ProductID + "/update", GROUP.admin, PERMISSION.product)}
                {PrivateComponent(
                    <button className="btn btn-delete ms-3" title="Supprimer" data-bs-toggle="modal" data-bs-target="#removeModal">
                        <i className="bi bi-trash3 me-lg-1"></i> <span className='d-none d-lg-inline'>Supprimer</span>
                    </button>,
                    GROUP.admin,
                    PERMISSION.product
                )}
            </>
        )}

        <section className="body container-fluid">
            <article className="card mb-3">
                <div className="row">
                    <section className="col-4">
                        <img className="img-product rounded" src={DEFAULT_IMG} />
                    </section>

                    <section className="col-8">
                        <h6 className="mb-1">{product?.ProductModel}</h6>
                        <p className="mb-1">{product?.ProductBrand} {product?.ProductBrand && product?.ProductReference && " - "} {product?.ProductReference}</p>
                    </section>
                </div>
            </article>

            {productStorages && productStorages.map(storage => {
                return <React.Fragment key={storage.StorageID}>

                    <article className="card card-btn">
                        <Link to={"/warehouse/" + storage.WarehouseID + "/storage/" + storage.StorageID} className="card-body">
                            <i className="card-icon bi bi-grid-3x3-gap"></i>

                            <div className="card-content">
                                <h5 className="card-title">{storage?.WarehouseName}: {storage?.Rack}{storage?.Row}-{storage?.Col}{storage?.Compartment ? "-" + storage?.Compartment : ""}</h5>
                            </div>
                            <i className="bi bi-chevron-right"></i>
                        </Link>
                    </article>

                    <div className="row row-cols-2">

                        <div className="col">
                            <div className="card">
                                <header className="card-header pb-0">
                                    <h5 className="card-title">Status</h5>
                                </header>
                                <section className="card-body">
                                    <p className="mb-0">{StorageAvailabilityBadge(storage.ProductAvailable)}</p>
                                </section>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card">
                                <header className="card-header pb-0">
                                    <h5 className="card-title">Etat</h5>

                                </header>
                                <section className="card-body">
                                    <p className="d-inline mb-0">
                                        {storage?.ProductCondition == "Hors service" && <i className="bi bi-x-octagon text-danger me-2"></i>}
                                        {storage?.ProductCondition == "Maintenance" && <i className="bi bi-exclamation-triangle text-warning me-2"></i>}
                                        {storage?.ProductCondition ? storage?.ProductCondition : "Non utilisé"}
                                    </p>
                                </section>
                            </div>
                        </div>
                    </div>

                    {storage.ProductAvailable == 0 &&
                        <article className="card">

                            <header className="card-header">
                                <h5 className="card-title">Emplacement</h5>
                            </header>

                            <div className="card-body">
                                <p className="mb-0">{storage.ProductLocation}</p>
                            </div>

                        </article>
                    }

                    {storage.ProductAvailable == 2 &&
                        <article className="card">

                            <header className="card-header">
                                <h5 className="card-title">Emplacement</h5>
                            </header>

                            <div className="card-body">
                                <p className="mb-0">{storage.RepairLocation}</p>
                            </div>

                        </article>
                    }

                    <div className="card card-btn">
                        <Link to={"/warehouse/" + storage.WarehouseID + "/storage/" + storage.StorageID + "/repair"} className="card-body">
                            <i className="card-icon bi bi-wrench-adjustable-circle"></i>

                            <div className="card-content">
                                <h5 className="card-title">
                                    {storage.ProductAvailable == 2 ? "Retour de réparation" : "Réparation"}
                                </h5>
                            </div>
                            <i className="bi bi-chevron-right"></i>
                        </Link>
                    </div>

                    {PrivateComponent(
                        <article className="card card-btn">
                            <Link to={"activity"} className="card-body">
                                <i className="card-icon bi bi-activity"></i>

                                <div className="card-content">
                                    <h5 className="card-title">Activité</h5>
                                </div>
                                <i className="bi bi-chevron-right"></i>
                            </Link>
                        </article>,
                        GROUP.admin
                    )}

                </React.Fragment>
            })
            }

        </section>
    </div>
}