import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { fuzzyFilter, Filter, dateFormat } from "../../common/common";
import { reqGet } from "../../../service/apiRequest";
import { IVehicle, IVehicleCheck } from "../../common/types"

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef
} from '@tanstack/react-table'

interface IVehicleCheckDetails extends IVehicle, IVehicleCheck { }

export default function VehicleReports() {

    const [data, setData] = useState<Array<IVehicleCheckDetails>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const columns = useMemo<ColumnDef<IVehicleCheckDetails, any>[]>(
        () => [
            {
                accessorFn: row => row.DateCheck,
                id: 'DateCheck',
                header: 'Date',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Km,
                id: 'Km',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.FirstName + " " + row.Name,
                id: 'technician',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Note,
                id: 'Note',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.DernierService,
                id: 'DernierService',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.TiresSummer + " " + row.TiresWinter,
                id: 'Tires',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Brand + " " + row.Model,
                id: 'Vehicle',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Numberplate,
                id: 'Numberplate',
                cell: info => info.getValue()
            }
        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        table.setPageSize(100)
        table.setSorting([{ id: "DateCheck", desc: true }])
        reqGet("vehiclecheck").then((res) => { setData(res) })
    }, [])

    return <section className="body-container h-100">

        <section className="header">
            <h3 className="section-title">Rapports de véhicules</h3>
            <Link to="/vehicle" className="link-nav">{"<"} Retour</Link>
        </section>

        <section className="body">

            <div className="card card-fill card-flush">
                <section className="card-body">

                    <div className="table-responsive">
                        <table className="table table-sticky align-middle" id="table" style={{ minWidth: "800px" }}>

                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id} className="align-bottom pb-3">

                                        <th style={{ width: "170px" }}>
                                            <p className="mb-1" style={{ cursor: "pointer", width: "170px" }} {...{ onClick: table.getColumn("technician")?.getToggleSortingHandler() }}>
                                                Technicien
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("technician")?.getIsSorted() as string] ?? null}
                                            </p>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("technician")} table={table} />
                                            </div>

                                            <p className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("DateCheck")?.getToggleSortingHandler() }}>
                                                Date
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("DateCheck")?.getIsSorted() as string] ?? null}
                                            </p>
                                            <div className="d-flex flex-direction-column">
                                                <Filter column={table.getColumn("DateCheck")} table={table} />
                                            </div>
                                        </th>

                                        <th style={{ width: "200px" }}>
                                            <p className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Vehicle")?.getToggleSortingHandler() }}>
                                                Modèle
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Vehicle")?.getIsSorted() as string] ?? null}
                                            </p>
                                            <div className="d-flex flex-direction-column mb-3">
                                                <Filter column={table.getColumn("Vehicle")} table={table} />
                                            </div>


                                            <p className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Numberplate")?.getToggleSortingHandler() }}>
                                                Plaque
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Numberplate")?.getIsSorted() as string] ?? null}
                                            </p>
                                            <div className="d-flex flex-direction-column">
                                                <Filter column={table.getColumn("Numberplate")} table={table} />
                                            </div>
                                        </th>

                                        <th style={{ width: "170px" }}>
                                            <p className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Tires")?.getToggleSortingHandler() }}>
                                                Pneus
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Tires")?.getIsSorted() as string] ?? null}
                                            </p>
                                            <div className="d-flex flex-direction-column">
                                                <Filter column={table.getColumn("Tires")} table={table} />
                                            </div>
                                        </th>


                                        <th style={{ width: "170px" }}>
                                            <p className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Km")?.getToggleSortingHandler() }}>
                                                Kilométrage
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Km")?.getIsSorted() as string] ?? null}
                                            </p>
                                            <div className="d-flex flex-direction-column">
                                                <Filter column={table.getColumn("Km")} table={table} />
                                            </div>
                                        </th>

                                        <th style={{ width: "170px" }}>
                                            <p className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("DernierService")?.getToggleSortingHandler() }}>
                                                Dernier service
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("DernierService")?.getIsSorted() as string] ?? null}
                                            </p>
                                            <div className="d-flex flex-direction-column">
                                                <Filter column={table.getColumn("DernierService")} table={table} />
                                            </div>
                                        </th>

                                        <th style={{ minWidth: "170px" }}>
                                            <p className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Note")?.getToggleSortingHandler() }}>
                                                Remarque
                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Note")?.getIsSorted() as string] ?? null}
                                            </p>
                                            <div className="d-flex flex-direction-column">
                                                <Filter column={table.getColumn("Note")} table={table} />
                                            </div>
                                        </th>

                                        <th></th>

                                    </tr>
                                ))}
                            </thead>

                            <tbody>
                                {table.getRowModel().rows.map(row => {
                                    return (
                                        <tr key={row.id}>
                                            <td>
                                                <h6 className="item-title">{row.original.FirstName} {row.original.Name}</h6>
                                                <p className="mb-0">{dateFormat(row.original.DateCheck, "dd/MM/yyyy hh:mm")}</p>
                                            </td>
                                            <td>
                                                <h6 className="item-title">{row.original.Brand} {row.original.Model}</h6>
                                                <p className="mb-0">{row.original.Numberplate}</p>
                                            </td>
                                            <td>
                                                <p className="mb-0">{row.original.TiresSummer}</p>
                                                <p className="mb-0">{row.original.TiresWinter}</p>
                                            </td>
                                            <td>{row.original.Km?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, "'")} Km</td>
                                            <td>{dateFormat(row.original.DernierService, "dd/MM/yyyy")}</td>
                                            <td><p className="text-truncate-2">{row.original.Note}</p></td>
                                            <td><Link to={"/vehicle/" + row.original.VehicleID} className="link-nav text-nowrap">Détails {">"}</Link></td>
                                        </tr>
                                    )

                                })}
                            </tbody>

                        </table>
                    </div>

                </section>

                <footer className="card-footer d-flex justify-content-between align-items-center">

                    <nav className="pagination">
                        <button className="btn btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                            {'<<'}
                        </button>
                        <button className="btn btn-outline me-3" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                            {'<'}
                        </button>

                        <p className="mb-0 me-3">
                            {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                        </p>

                        <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                            {'>'}
                        </button>
                        <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                            {'>>'}
                        </button>

                        <div className="ms-3">
                            <select className="form-select form-sm" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                                {[100, 200, 500].map(pageSize => (

                                    <option key={pageSize} value={pageSize}>
                                        Afficher {pageSize}
                                    </option>

                                ))}
                            </select>
                        </div>
                    </nav>


                </footer>

            </div>

        </section >

    </section >
}