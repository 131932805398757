import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { ButtonSubmit, DateToday } from '../../common/common'
import { IHoliday, IUser } from "../../common/types";
import { reqGet, reqPost } from "../../../service/apiRequest";
import { getToken } from "../../../useToken";
import { HOLIDAY_STATUS, HOLIDAY_TYPE } from "../../common/constants";

const initialValues = {
    HolidayType: HOLIDAY_TYPE.ACCIDENT,
    HolidayDateStart: DateToday(),
    HolidayDateEnd: DateToday(),
    HolidayAllDay: 1,
    HolidayNote: null,
    HolidayStatus: HOLIDAY_STATUS.WAITING
}

const validationSchema = yup.object().shape({
    UserID: yup.number().required("Ce champ est obligatoire"),
    HolidayType: yup.string().required("Ce champ est obligatoire"),
    HolidayDateStart: yup.string().required("Ce champ est obligatoire"),
    HolidayDateEnd: yup.string().required("Ce champ est obligatoire"),
    HolidayAllDay: yup.number().transform((value, orignal) => (orignal ? 1 : 0)).required("Ce champ est obligatoire"),
    HolidayNote: yup.string().nullable()
}).required();

export default function HolidayAdd({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()

    const { register, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm<IHoliday>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [users, setUsers] = useState<Array<IUser>>([])

    useEffect(() => {
        reqGet("user").then(res => setUsers(res))
    }, [])

    const onSubmit: SubmitHandler<IHoliday> = data => {

        data.HolidayDateStart = new Date(data.HolidayDateStart).toISOString().replace("T", " ").split(".")[0]
        data.HolidayDateEnd = new Date(data.HolidayDateEnd).toISOString().replace("T", " ").split(".")[0]

        reqPost('holiday', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate("/account/holiday")
            setReload(true)
        })
    };

    return <div className="body-container content">
        <header className="mb-3">
            <h2 className="section-title mb-0">Ajouter un congé</h2>
            <Link to="/account/holiday" className="link-nav">{"<"} Retour</Link>
        </header>

        <section className="body">

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="mb-3">
                    <label className="form-label">Utilisateur *</label>
                    <select id="UserID" className="form-select"{...register("UserID")} >
                        <option value=""></option>
                        {users.length > 0 && users.map((u, k) => <option key={k} value={u.UserID}>{u.FirstName} {u.Name}</option>)}
                    </select>

                    
                    {errors.UserID?.message && <p className="input-msg-error">{errors.UserID?.message}</p>}
                </div>

                <div className="mb-3">
                    <label className="form-label">Catégorie *</label>
                    <select id="HolidayType" className="form-select"{...register("HolidayType")} >
                        {Object.entries(HOLIDAY_TYPE).map((t, k) => <option key={k} value={t[1]}>{t[1]}</option>)}
                    </select>
                </div>

                <div className="mb-3">
                    <label className="form-label" htmlFor="HolidayAllDay">Toute la journée *</label>
                    <div className="form-switch">
                        <input className="form-check-input" type="checkbox" id="HolidayAllDay" defaultChecked {...register("HolidayAllDay")} />
                    </div>

                    {errors.HolidayAllDay?.message && <p className="input-msg-error">{errors.HolidayAllDay?.message}</p>}
                </div>

                <div className="row row-cols-2 mb-3">

                    <div className="col">
                        <label className="form-label">Date début *</label>

                        <input type={watch("HolidayAllDay") ? "date" : "datetime-local"} className={"form-control " + (errors?.HolidayDateStart ? "is-invalid" : "")} {...register("HolidayDateStart")} id="HolidayDateStart" />

                        {errors.HolidayDateStart?.message && <p className="input-msg-error">{errors.HolidayDateStart?.message}</p>}
                    </div>

                    <div className="col">
                        <label className="form-label">Date fin *</label>

                        <input type={watch("HolidayAllDay") ? "date" : "datetime-local"} className={"form-control " + (errors?.HolidayDateEnd ? "is-invalid" : "")} {...register("HolidayDateEnd")} id="HolidayDateEnd" />

                        {errors.HolidayDateEnd?.message && <p className="input-msg-error">{errors.HolidayDateEnd?.message}</p>}
                    </div>
                </div>


                <div className="mb-3">
                    <label className="form-label">Description</label>

                    <textarea id="" rows={4} {...register("HolidayNote")}></textarea>
                </div>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <ButtonSubmit />
                </div>

            </form>
        </section>
    </div>
}