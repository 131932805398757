import React from "react";
import { Header } from "../../common/common";
import { Link, useParams } from "react-router-dom";

export default function UserHoliday() {
    const params = useParams()
    const idUser = params.idUser

    return <div className="body-container">

        {Header(
            <h2 className="section-title">Vacances</h2>,
            <Link to={"/users/" + idUser} className="link-nav">{"<"} Retour</Link>
        )}

        <section className="body">

        </section>
    </div>
}