import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Page, Text, View, Document, StyleSheet, Image, Font, PDFViewer } from '@react-pdf/renderer';
import { IAddress, IAddressService } from "../../../../common/types";
import { reqGet } from "../../../../../service/apiRequest";
import { dateFormat, DateToday, ServiceBadge } from "../../../../common/common";
import { ICONS, LAYOUT, PDF_STYLE } from "../../../../common/constants";
import $ from "jquery"

interface IServiceInvoice {

}


export default function AddressInvoiceAddService() {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const idAddress = params.idAddress

    const LinkBack = () => {
        if (location?.state?.back)
            return location.state.back
        else
            return "/address/" + idAddress + "/invoice/add"
    }

    // Display size -------------------------------------------
    const [displayNavServices, setDisplayNavServices] = useState<boolean>($(window).width() >= LAYOUT.xxl ? true : false)
    const [displayMenu, setDisplayMenu] = useState<boolean>(true)
    const [displayPDF, setDisplayPDF] = useState<boolean>(true)
    const [displayMobilePDF, setDisplayMobilePDF] = useState<boolean>(false)

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location, displayMobilePDF])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.xxl) {
            setDisplayNavServices(true)
        } else {
            setDisplayNavServices(false)
        }

        if ($(window).width() >= LAYOUT.lg) {
            setDisplayMenu(true)
            setDisplayPDF(true)

        } else {
            if (displayMobilePDF) {
                setDisplayMenu(false)
                setDisplayPDF(true)
            } else {
                setDisplayMenu(true)
                setDisplayPDF(false)
            }
        }
    }
    // Display size -------------------------------------------

    const [address, setAddress] = useState<IAddress>(useOutletContext())

    const [services, setServices] = useState<Array<IAddressService>>([])
    const [serviceActive, setServiceActive] = useState<IAddressService>()
    const [servicesChecked, setServicesChecked] = useState<Array<IAddressService>>([])

    const [settings, setSettings] = useState<any>()

    useEffect(() => {
        if (idAddress) {
            reqGet("addressservice/address/" + idAddress).then(res => {

                setServices(res)
                setServicesChecked(res)

                if (res.length > 0)
                    setServiceActive(res[0])
            })

            if (!address)
                reqGet("address/" + idAddress).then(res => setAddress(res))
        } else
            navigate("/address")

        setSettings({
            Total: 2662.55,
            Discount: null,
            TVA: 8.1
        })
    }, [idAddress])



    // Service changing -------------------------
    function HandleServiceChange(idAddressService) {
        setServiceActive(services.find(s => s.AddressServiceID === idAddressService))
    }

    useEffect(() => {
        if (serviceActive) {
            $("[id^=btnSA]").removeClass("active") // every btn starting by
            $("#btnSA-" + serviceActive.AddressServiceID).addClass("active")
        }
    }, [serviceActive])
    // Service changing -------------------------

    return <main className="body-container content">

        <header className="header">
            <div>
                <h2 className="section-title">Créer facture de prestations</h2>
                <div>
                    {services?.length > 0 &&
                        <div className="mb-2">
                            {services?.filter((obj1, i, arr) =>
                                /* FILTER DUPPLICATE */
                                arr.findIndex(obj2 => (obj2.ServiceID === obj1.ServiceID)) === i
                            ).map((s, k) => {
                                return <ServiceBadge service={s.Service} className="me-2" key={k} />
                            })}
                        </div>
                    }
                </div>
                {!displayMobilePDF &&
                    <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
                }
            </div>
        </header>

        <section className="body">

            <div className="container-fluid h-100">

                <div className="row h-100">

                    {displayNavServices &&
                        <section className="col-xxl-2 col-h-fill ps-lg-0">
                            <header className="header">
                                <h3 className="section-title-2 mb-2">Prestations</h3>
                            </header>

                            <section className="body">
                                <div className="list">
                                    {services?.length > 0 && services?.map((s, k) => {
                                        return <button className="card card-btn" onClick={(e) => HandleServiceChange(s.AddressServiceID)} id={"btnSA-" + s.AddressServiceID}>
                                            <section className="card-body ps-0">
                                                <h5 className="card-title">
                                                    <ServiceBadge service={s.Service} className="me-2" key={k} firstLetter={true} />
                                                    {s.Service}
                                                </h5>
                                                {s.InstallationID &&
                                                    <p className="card-text text-start">{s.Type || s.IType || s.Class || s.IClass} <br /> {s.Model || s.IModel}</p>
                                                }
                                            </section>

                                            <footer className="card-footer">
                                                <i className="bi bi-chevron-right"></i>
                                            </footer>
                                        </button>
                                    })}
                                </div>
                            </section>

                        </section>
                    }



                    {displayMenu &&
                        <section className="col-lg-4 col-xxl-3 col-h-fill px-0 pe-lg-3 ps-xxl-3">


                            {!displayNavServices && <>
                                <header className="header ">
                                    <h3 className="section-title-2 ">Prestations</h3>
                                    {!displayPDF &&
                                        <button className="btn btn-add" onClick={() => setDisplayMobilePDF(true)}><i className={ICONS.ADD + " me-2"}></i> Afficher PDFs</button>
                                    }
                                </header>

                                <nav className="text-center mb-3">

                                    <div className="dropdown">
                                        <button className="form-select d-inline text-start w-100" style={{ height: "3.5rem" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <h6 className="mb-0">{serviceActive?.Service}</h6>
                                            <p className="mb-0">{serviceActive?.Model}</p>
                                        </button>
                                        <ul className="dropdown-menu w-100">
                                            {services?.length > 0 && services?.map((s, k) => {
                                                return <button onClick={() => HandleServiceChange(s.AddressServiceID)} className="dropdown-item">
                                                    <h6><ServiceBadge service={s.Service} className="me-2" key={k} firstLetter={true} /> {s.Service}</h6>
                                                    {s.InstallationID &&
                                                        <p className="text-truncate mb-0">{s.Type || s.IType || s.Class || s.IClass}, {s.Model || s.IModel}</p>
                                                    }
                                                </button>
                                            })}
                                        </ul>
                                    </div>
                                </nav>
                            </>
                            }

                            <section className="body">
                                <h3 className="section-title-2 mb-2">{serviceActive?.Service}</h3>



                                <h4 className="section-title-2 mb-2">Paramètres</h4>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" defaultChecked={true} />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">Afficher la facture</label>
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Exemple</label>
                                    <input type="text" className="form-control" id="" onBlur={(e) => { }} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Date</label>
                                    <input type="date" className="form-control" id="" defaultValue={DateToday()} onBlur={(e) => { }} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Concerne</label>
                                    <input type="text" className="form-control" id="" onBlur={(e) => { }} />
                                </div>


                                <h3 className="section-title-2">Prix</h3>

                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Montant total</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" id="" defaultValue={settings?.Total} onBlur={(e) => setSettings({ ...settings, Total: parseFloat(e.target.value) })} />
                                        <span className="input-group-text" id="">CHF</span>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Rabais</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" id="" defaultValue={settings?.Discount && settings?.Discount.toString()} onBlur={(e) => setSettings({ ...settings, Discount: parseFloat(e.target.value) })} />
                                        <span className="input-group-text" id="">%</span>
                                    </div>
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">TVA</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" id="" defaultValue={settings?.TVA.toString()} onBlur={(e) => setSettings({ ...settings, TVA: parseFloat(e.target.value) })} />
                                        <span className="input-group-text" id="">%</span>
                                    </div>
                                </div>

                            </section>
                        </section>
                    }

                    {displayPDF &&
                        <section className="col-lg-8 col-xxl-7 col-h-fill px-0 ps-lg-3">

                            <nav>

                            </nav>

                            <div className="card card-fill w-100">
                                <PDFViewer width={"100%"} height={"100%"}>
                                    <PdfTimesheet address={address} servicesChecked={servicesChecked} settings={settings} />
                                </PDFViewer>
                            </div>
                        </section>
                    }

                </div>

            </div>

        </section>
    </main>
}



const PdfTimesheet = ({ address, servicesChecked, settings }: { address: IAddress | undefined, servicesChecked: Array<IAddressService>, settings }) => {
    console.log(address);

    const finalDiscount = settings?.Discount ? Math.round(settings?.Total * settings?.Discount) / 100 : 0
    const finalTVA = settings?.TVA ? Math.round((settings.Total - finalDiscount) * settings?.TVA) / 100 : 0

    return <Document>
        {servicesChecked.map((service: IAddressService) => {
            return <Page style={PDF_STYLE.body}>
                <Image style={[PDF_STYLE.logo, PDF_STYLE.mb3]} src="/media/images/multitherme/mt_logo_text.jpg" />

                <View style={[PDF_STYLE.mb3, { display: "flex", alignSelf: "flex-end", width: "45%" }]}>
                    <Text style={PDF_STYLE.text}>{address?.ManagementName}</Text>
                    <Text style={PDF_STYLE.text}>{address?.AddressLine}</Text>
                    <Text style={PDF_STYLE.text}>{address?.ZipCode} {address?.City}</Text>
                </View>


                <Text style={[PDF_STYLE.title2, PDF_STYLE.mb1]}>Facture </Text>

                <View style={PDF_STYLE.mb1}>
                    <View style={[{ display: "flex", flexDirection: "row" }]}>
                        <Text style={[PDF_STYLE.text, { width: "70px" }]}>N° de client : </Text>
                        <Text style={[PDF_STYLE.text, { overflow: "hidden", textOverflow: "ellipsis" }]}>{address?.ManagementID}</Text>
                    </View>

                    <View style={[{ display: "flex", flexDirection: "row" }]}>
                        <Text style={[PDF_STYLE.text, { width: "70px" }]}>N° de l'inst. : </Text>
                        <Text style={[PDF_STYLE.text, { overflow: "hidden", textOverflow: "ellipsis" }]}></Text>
                    </View>

                    <View style={[{ display: "flex", flexDirection: "row" }]}>
                        <Text style={[PDF_STYLE.text, { width: "70px" }]}>Bon de régie : </Text>
                        <Text style={[PDF_STYLE.text, { overflow: "hidden", textOverflow: "ellipsis" }]}></Text>
                    </View>

                    <View style={[{ display: "flex", flexDirection: "row" }]}>
                        <Text style={[PDF_STYLE.text, { width: "70px" }]}>N° de TVA : </Text>
                        <Text style={[PDF_STYLE.text, { overflow: "hidden", textOverflow: "ellipsis" }]}>CHE-107.892.889</Text>
                    </View>
                </View>


                <View style={[PDF_STYLE.mb1, { display: "flex", alignSelf: "flex-end", width: "45%" }]}>
                    <Text style={[PDF_STYLE.text]}>Genève, le {dateFormat(DateToday(), "dd MMM yyyy")} </Text>
                </View>



                <View style={[PDF_STYLE.mb2, { display: "flex", flexDirection: "row" }]}>
                    <Text style={[PDF_STYLE.title2, { marginRight: "15px" }]}>Concerne : </Text>
                    <Text style={PDF_STYLE.text}>{address?.AddressLine}, {address?.ZipCode} {address?.City}</Text>
                </View>



                <View style={[PDF_STYLE.table]}>
                    <View style={[PDF_STYLE.row, PDF_STYLE.mb2, { backgroundColor: "#DDDDDD", padding: "5px" }]} wrap={false}>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.th, { width: "55%" }]}>Description</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.th, { width: "12%" }]}>Quantité</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.th, { width: "12%", textAlign: "center" }]}>Prix HT</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.th, { width: "7%" }]}></Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.th, { width: "12%", textAlign: "right" }]}>Montant HT</Text>
                    </View>

                    <Text style={[PDF_STYLE.title2, { textDecoration: "underline" }]}>Contrat de {service.Service.toLowerCase()} N° {service.AddressServiceID}</Text>
                    <View style={[PDF_STYLE.row, PDF_STYLE.mb1, { borderBottom: "1px solid black" }]} wrap={false}>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "55%" }]}>Période : {dateFormat(service.AddressServiceDate, "dd.MM.yyyy")} au </Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "12%" }]}>1.00 An</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "12%" }]}>{settings?.Total?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "7%", textAlign: "center" }]}>CHF</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "12%", textAlign: "right" }]}>{settings?.Total?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}</Text>
                    </View>

                    {settings?.Discount && <>
                        <View style={[PDF_STYLE.row, PDF_STYLE.mb0]} wrap={false}>
                            <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "55%" }]}>Total avant rabais</Text>
                            <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "12%" }]}></Text>
                            <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "12%" }]}></Text>
                            <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "7%", textAlign: "center" }]}>CHF</Text>
                            <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "12%", textAlign: "right" }]}>{settings?.Total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}</Text>
                        </View>
                        <View style={[PDF_STYLE.row, PDF_STYLE.mb1, { borderBottom: "1px solid black" }]} wrap={false}>
                            <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "55%" }]}>Rabais doc. 1</Text>
                            <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "12%" }]}>{settings?.Discount?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")} %</Text>
                            <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "12%" }]}>{settings?.Total?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}</Text>
                            <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "7%", textAlign: "center" }]}></Text>
                            <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "12%", textAlign: "right" }]}>-{(Math.round(settings?.Total * settings?.Discount) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}</Text>
                        </View>
                    </>
                    }

                    <View style={[PDF_STYLE.row, PDF_STYLE.mb0]} wrap={false}>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "55%" }]}>Total TVA exclue</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "12%" }]}></Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "12%" }]}></Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "7%", textAlign: "center" }]}>CHF</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "12%", textAlign: "right" }]}>{(settings.Total - finalDiscount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}</Text>
                    </View>
                    <View style={[PDF_STYLE.row, PDF_STYLE.mb1, { borderBottom: "1px solid black" }]} wrap={false}>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "55%" }]}>TVA</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "12%" }]}>{settings.TVA.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")} %</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "12%" }]}>{(settings?.Total - (Math.round(settings?.Total * settings?.Discount) / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "7%", textAlign: "center" }]}></Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, { width: "12%", textAlign: "right" }]}>{finalTVA}</Text>
                    </View>


                    <View style={[PDF_STYLE.row, PDF_STYLE.mb1, { borderBottom: "2px solid black" }]} wrap={false}>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "55%" }]}>Total TTC</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "12%" }]}></Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "12%" }]}></Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "7%", textAlign: "center" }]}>CHF</Text>
                        <Text style={[PDF_STYLE.col, PDF_STYLE.text, PDF_STYLE.bold, { width: "12%", textAlign: "right" }]}>{((settings.Total - finalDiscount + finalTVA).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}</Text>
                    </View>

                    <Text style={PDF_STYLE.text}>Paiement :    30 jours nets</Text>
                </View>
            </Page>
        })}

    </Document>
}

Font.register({
    family: 'Helvetica',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});