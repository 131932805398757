import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";

import { PrivateComponent } from '../../common/common';
import { IStorageProduct } from '../../common/types'
import { reqGet } from "../../../service/apiRequest";
import { GROUP, PERMISSION } from '../../common/constants';


export default function ProductContentLabel() {
    const navigate = useNavigate()
    const params = useParams()
    const idStorage = params.idStorage

    const [storage, setStorage] = useState<IStorageProduct>()

    useEffect(() => {
        reqGet("storage/" + idStorage).then(res => {
            setStorage(res);
        })
    }, [idStorage])

    const componentRef = React.useRef(null);

    const onBeforeGetContentResolve = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve;
            }, 2000);
        });
    }, [setLoading, setText]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        pageStyle: `@media print {
      @page {
        margin: None;
      }
    }`,
        content: reactToPrintContent,
        documentTitle: "Storage label",
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: false
    });

    React.useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    return <section className="body-container content">

        <section className="header">
            <div>
                <h3 className="section-title">{storage?.Rack}{storage?.Row}-{storage?.Col}{storage?.Compartment != null ? ("-" + storage?.Compartment) : ""}: Etiquette</h3>
                <button className="btn link-nav mb-3" onClick={() => navigate(-1)}>{'< Retour'}</button>
            </div>
        </section>

        <div className="label mx-auto mb-3" ref={componentRef}>
            <QRCode className="label-qrcode" value={idStorage?.toString() ?? ""} />

            <div className="label-content">
                <div className="d-flex flex-column flex-grow-1">
                    <h6 className="text-truncate-2">{storage?.ProductModel}</h6>
                    <p className='text-truncate mb-0'>{storage?.ProductReference}</p>
                </div>

                <footer className="d-flex justify-content-between">
                    <h6 className="mb-0">{storage?.Rack}{storage?.Row}-{storage?.Col}{storage?.Compartment != null ? ("-" + storage?.Compartment) : ""}</h6>
                    <h6 className="mb-0">{storage?.StorageID}</h6>
                </footer>

            </div>
        </div>

        {loading &&
            <div className="card bg-primary mx-auto mb-3" style={{ width: "63mm" }}>
                <div className="card-body">
                    <h6 className="mb-0">Chargement...</h6>
                </div>
            </div>
        }

        {!loading &&
            PrivateComponent(
                <div className="text-center" onClick={handlePrint}>
                    <button className="btn btn-primary">
                        <i className='bi bi-printer me-2'></i>Imprimer étiquette
                    </button>
                </div>,
                GROUP.admin,
                PERMISSION.product
            )
        }

    </section>
}