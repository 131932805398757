import React, { useEffect, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import $ from 'jquery'
import { faker } from '@faker-js/faker'
faker.locale = 'fr_CH'

import { reqGet } from "../../../service/apiRequest";
import { PrivateComponent, ProductTypeIconResolver, ProgressColor } from '../../common/common'
import { GROUP, LAYOUT, PERMISSION, PRODUCT_CLASS_ID, PRODUCT_TYPE } from '../../common/constants'
import { IStorageProduct, IRack } from '../../common/types'
import { StorageAvailabilityBadge } from "./storage"

export default function StorageManagement({ reload, setReload }) {
    const params = useParams()
    const idWarehouse = params.idWarehouse
    const idStorage = params.idStorage
    const location = useLocation()

    const [currentRack, setCurrentRack] = useState<number>(0)
    const [rackList, setRackList] = useState<Array<IRack>>([])
    const [storages, setStorages] = useState<Array<IStorageProduct>>([])
    const [mapViewMode, setMapViewMode] = useState<string>("storage")

    useEffect(() => {
        reqGet('storage/warehouse/' + idWarehouse).then((res) => { setStorages(res); })
        reqGet("storage/warehouse/" + idWarehouse + "/rack").then(res => { setRackList(res) })
    }, [])

    useEffect(() => {
        if (reload) {
            reqGet('storage/warehouse/' + idWarehouse).then((res) => { setStorages(res) })
            reqGet("storage/warehouse/" + idWarehouse + "/rack").then(res => {
                setRackList(res)

                // IF we are in rack view context
                // If no more rack
                if (res.length > 0) {
                    setMapViewMode("storage")

                }
                // if current rack does not exist anymore
                else if (currentRack >= res.length) {
                    setCurrentRack(res.length - 1)
                }
            })
            setReload(false)
        }
    }, [reload])

    function handlePrev() {
        if (currentRack == 0) {
            setCurrentRack(rackList.length - 1)
        } else {
            setCurrentRack(currentRack - 1)
        }
    }

    function handleNext() {
        if (currentRack == rackList.length - 1)
            setCurrentRack(0)
        else
            setCurrentRack(currentRack + 1)
    }

    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.lg) {
            setDisplay('d-flex')

        } else {
            if (!idStorage) {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }



    if (location.pathname.includes("storage") && (location.pathname.includes("add") || location.pathname.includes("update"))) {
        return <section className="body-container content">
            <Outlet />
        </section>
    } else {
        return <section className="body-container content">

            <section className={display + " header container-fluid justify-content-between align-items-start"}>
                <div>
                    <h3 className="section-title mb-0">Stockage</h3>
                    <Link to={"/warehouse/" + idWarehouse} className="link-nav">{"<"} Retour</Link>
                </div>

                <div className="d-flex align-items-start justify-content-end flex-wrap">
                    {PrivateComponent(
                        <Link to="add" className="btn btn-add text-nowrap mb-3">
                            <i className="bi bi-plus-lg me-1"></i> Emplacement
                        </Link>,
                        GROUP.admin,
                        PERMISSION.storage
                    )}
                </div>
            </section>

            <section className="body container-fluid">

                <div className="row h-100 justify-content-center">
                    <div className="col-lg-8 col-h-fill d-none d-lg-flex ps-0">

                        <div className="storage-canvas">

                            <div className="storage-toolbar">
                                {mapViewMode == "rack" &&
                                    <button className="btn" onClick={() => setMapViewMode("storage")} style={{ fontSize: "1.4rem" }}>
                                        <i className="bi bi-grid-3x3-gap"></i>
                                    </button>
                                }
                            </div>

                            {/* CONTROL ARROWS */}
                            {mapViewMode == "rack" && <>
                                <div className="storage-control-prev col-1">
                                    {rackList.length > 1 && <button className="btn" onClick={() => handlePrev()}>
                                        <i className="bi bi-caret-left-fill"></i>
                                    </button>}
                                </div>

                                <div className="storage-control-next col-1">
                                    {rackList.length > 1 && <button className="btn" onClick={() => handleNext()}>
                                        <i className="bi bi-caret-right-fill"></i>
                                    </button>}
                                </div>
                            </>}

                            {/* MAP */}
                            <div className="storage-container col">

                                {mapViewMode == "storage" && rackList.length > 0 && <StorageRackMap rackList={rackList} setMapViewMode={setMapViewMode} setCurrentRack={setCurrentRack} />}

                                {mapViewMode == "rack" && rackList.length > 0 && <StorageRack idWarehouse={idWarehouse} rackLetter={rackList[currentRack].Rack} reload={reload} />}

                            </div>

                            {/* FOOTER */}
                            <div className="storage-footer">
                                <p className="mb-0">
                                    {storages.length} emplacements - {storages.reduce((accumulator, product) => {
                                        return accumulator + parseInt(product.Quantity ?? "0");
                                    }, 0)} pièces en stock
                                </p>
                            </div>

                        </div>

                    </div>

                    <div className="col-sm-8 col-lg-4 col-h-fill px-0 ps-lg-3">
                        <Outlet />
                    </div>
                </div >

            </section>
        </section>
    }
}

function StorageRackMap({ rackList, setMapViewMode, setCurrentRack }) {

    return <div className="storage-map">
        <div className="row row-cols-5">

            {rackList.map((rack, k) => {

                return <div key={k} className="col">
                    <div className={"btn rack-item justify-content-center text-center "} onClick={() => { setMapViewMode("rack"); setCurrentRack(k) }}>
                        <b>{rack.Rack}</b>
                        <p className='text-muted mb-0'>{rack.NBStorage}</p>
                    </div>
                </div>
            })}

        </div>
    </div>
}

function StorageRack({ idWarehouse, rackLetter, reload }) {
    const [rackProducts, setRackProducts] = useState<Array<IStorageProduct>>([])
    const [rackRows, setRackRows] = useState<Array<number>>([])

    useEffect(() => {
        reqGet("storage/warehouse/" + idWarehouse + "/rack/" + rackLetter).then(res => { setRackProducts(res) })
        reqGet("storage/warehouse/" + idWarehouse + "/row/" + rackLetter).then(res => { setRackRows(res) })
    }, [rackLetter])

    useEffect(() => {
        if (reload) {
            reqGet("storage/warehouse/" + idWarehouse + "/rack/" + rackLetter).then(res => { setRackProducts(res) })
            reqGet("storage/warehouse/" + idWarehouse + "/row/" + rackLetter).then(res => { setRackRows(res) })
        }
    }, [reload])

    const ItemResume = (e, storage: IStorageProduct) => {
        if (storage?.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE) {
            const resume = ResumeProductConsumable(storage)
            $(".rack-item-resume").html(renderToStaticMarkup(resume))

        } else if (storage?.ProductClassID == PRODUCT_CLASS_ID.REUSABLE) {
            const resume = ResumeProductReusable(storage)
            $(".rack-item-resume").html(renderToStaticMarkup(resume))

        } else {
            $(".rack-item-resume").html(
                `
            <p>Aucune pièce affectée</p>
            `
            )
        }

        var top = Math.round(e.currentTarget.offsetTop + (e.currentTarget.offsetHeight / 2) - ($(".rack-item-resume").height() / 2) - $(".storage-rack .rack-body").scrollTop())

        var left = e.currentTarget.offsetLeft + e.currentTarget.offsetWidth + 10

        if (left + 200 > $(".storage-canvas")[0].clientWidth) {
            left = left - 220 - e.currentTarget.offsetWidth
        }

        $(".rack-item-resume").css({ "top": top, "left": left }).show()
    }

    return <>

        <div className="rack-item-resume"></div>

        <div className="storage-rack">

            <div className="storage-rack-header">
                <h5 className="rack-title">Etagère {rackLetter}</h5>
            </div>

            <div className="storage-rack-body">
                {rackRows.map(row => {

                    return <React.Fragment key={row}>
                        <h6 className="text-muted"><small>Rangée {row}</small></h6>
                        <div className="row row-cols-4 row-cols-xl-5 mb-1">

                            {rackProducts.filter(p => p.Row == row).map(storage => {
                                return <div key={storage.StorageID} className="col">

                                    <Link to={"" + storage.StorageID} className="rack-item" onMouseOver={(e) => ItemResume(e, storage)} onMouseLeave={(e) => $(".rack-item-resume").hide()}>

                                        <header className="rack-item-header">
                                            <p className="mb-0"><b>{rackLetter}{row}-{storage.Col}{storage?.Compartment != null ? ("-" + storage?.Compartment) : ""}</b></p>

                                            <p className="text-muted mb-0">
                                                {storage.ProductCondition == "Hors service" && <i className="bi bi-x-octagon text-danger me-2"></i>}
                                                {storage.ProductCondition == "Maintenance" && <i className="bi bi-exclamation-triangle text-warning me-2"></i>}
                                                <small>
                                                    {ProductTypeIconResolver(storage.ProductType)}
                                                </small>
                                            </p>
                                        </header>

                                        <section className="rack-item-body">
                                            <p className="text-truncate mb-1"><small>{storage?.ProductModel ?? "-"}</small></p>
                                        </section>

                                        <section className="rack-item-footer">
                                            {storage.ProductType == PRODUCT_TYPE.PIECE &&
                                                RackProductConsumable(storage)
                                            }
                                            {storage.ProductType == PRODUCT_TYPE.TOOL &&
                                                RackProductReusable(storage)
                                            }

                                        </section>

                                    </Link>
                                </div>
                            })}

                        </div>
                    </React.Fragment>
                })}
            </div>

            <div className="storage-rack-footer">
            </div>
        </div>
    </>
}

// Rack square
function RackProductConsumable(storage: IStorageProduct) {

    if (storage?.ProductID) {
        const qMax: number = parseInt(storage?.QuantityMax ?? "0")
        const quantity: number = parseInt(storage?.Quantity ?? "0")
        const qMin: number = parseInt(storage?.QuantityMin ?? "0")

        var bg = ProgressColor(quantity, qMin, qMax)
        return <>

            <div className='d-block w-100'>
                {
                    /* If no min/max */
                    qMin >= 0 && qMax >= 1 ?
                        <div className="progress bg-background" style={{ "height": "5px" }}>
                            <div
                                className={"progress-bar " + bg}
                                role="progressbar"
                                aria-label="Example 1px high"
                                style={{ "width": (quantity * 100 / qMax) + "%" }} aria-valuenow={quantity}
                                aria-valuemin={0}
                                aria-valuemax={qMax}>
                            </div>
                        </div> :
                        <></>
                }
            </div>
        </>
    } else {
        return <p className="text-muted text-truncate mb-0">Vide</p>
    }

}

function RackProductReusable(storage: IStorageProduct) {
    return <p className="text-muted text-truncate mb-0">
        <small> {StorageAvailabilityBadge(storage.ProductAvailable)}</small>
    </p>
}

// Resume hover rack
function ResumeProductConsumable(storage: IStorageProduct) {
    const qMax: number = parseInt(storage?.QuantityMax ?? "0")
    const quantity: number = parseInt(storage?.Quantity ?? "0")
    const qMin: number = parseInt(storage?.QuantityMin ?? "0")

    var bg = ProgressColor(quantity, qMin, qMax)

    return <>
        <div className="d-flex justify-content-between">
            <h6>{storage?.Rack}{storage?.Row}-{storage.Col}{storage.Compartment ? "-" + storage.Compartment : ""}</h6>

            <p className="text-muted mb-0"><small>
                {ProductTypeIconResolver(storage.ProductType)}
            </small></p>
        </div>

        <h6 className="text-truncate mb-0"><small>{storage.ProductModel ?? ""}</small></h6>
        <p className='text-muted mb-0'>{storage.ProductBrand ?? ""}</p>
        <p className='text-muted'>{storage.ProductReference ?? ""}</p>

        <div className="d-flex justify-content-between align-items-start mb-2">
            <p className="mb-0"><span className="text-muted">Min:</span> <b>{storage.QuantityMin ?? "0"}</b></p>
            <p className="mb-0"><span className="text-muted">Quantité:</span> <b>{storage.Quantity ?? "0"}</b></p>
            <p className="mb-0"><span className="text-muted">Max:</span> <b>{storage.QuantityMax ?? "0"}</b></p>
        </div>

        {qMin >= 0 && qMax >= 1 ?
            <div className="progress bg-background mb-3" style={{ "height": "5px" }}>
                <div className={"progress-bar " + bg} role="progressbar" aria-label="Example 1px high" style={{ "width": (quantity * 100 / qMax) + "%" }} aria-valuenow={quantity} aria-valuemin={0} aria-valuemax={qMax}></div>
            </div>
            : ""}

        <p><span className="text-muted me-1">Fournisseur:</span> {storage?.ProductSupplier}</p>
    </>
}

function ResumeProductReusable(storage: IStorageProduct) {

    return <>
        <div className="d-flex justify-content-between">
            <h6>{storage?.Rack}{storage?.Row}-{storage.Col}{storage.Compartment ? "-" + storage.Compartment : ""}</h6>

            <p className="text-muted mb-0"><small>
                {ProductTypeIconResolver(storage.ProductType)}
            </small></p>
        </div>

        <h6 className="text-truncate mb-0"><small>{storage.ProductModel ?? ""}</small></h6>
        <p className='text-muted mb-0'>{storage.ProductBrand ?? ""}</p>
        <p className='text-muted'>{storage.ProductReference ?? ""}</p>

        <p className="text-muted text-truncate">
            {StorageAvailabilityBadge(storage.ProductAvailable)}
        </p>

        {storage.ProductAvailable == 0 && <p>{storage?.ProductLocation}</p>}

        {storage.ProductCondition == "Hors service" &&
            <p className="text-muted text-truncate">
                <i className="bi bi-x-octagon text-danger me-2"></i>
                Hors service
            </p>}
        {storage.ProductCondition == "Maintenance" &&
            <p className="text-muted text-truncate">
                <i className="bi bi-exclamation-triangle text-warning me-2"></i>
                Maintenance
            </p>}

        <h6 className="card-title"><small>Remarque</small></h6>
        <p className="mb-0">{storage?.ProductNote ?? "aucune"}</p>

    </>
}