import React, { useState, useEffect, useMemo } from 'react'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import { reqGet, reqPost, reqDelete } from '../../../service/apiRequest'
import $ from 'jquery'

import { getToken } from '../../../useToken'
import { DebouncedInput, Header, PrivateComponent, PrivateLinkUpdate, fuzzyFilter } from '../../common/common'
import { GROUP, ICONS } from '../../common/constants'
import { IZone, IZipCode } from '../../common/types'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    FilterFn,
    ColumnDef,
} from '@tanstack/react-table'

interface Zone extends IZone {
    Role: string | null
    FirstName: string | null,
    Name: string | null,
    Email: string | null,
    Phone: string | null
}

export default function ZoneContent({ setReload }) {
    const navigate = useNavigate()
    const params = useParams()
    const idZone = params.idZone
    const token = getToken()

    const [zone, setZone] = useState<Zone>()
    const [zipcodes, setZipcodes] = useState<Array<IZipCode>>()
    const [selectedZipcode, setSelectedZipcode] = useState({})

    const [newEntry, setNewEntry] = useState(false)
    const [sort, setSort] = useState('')

    const [data, setData] = useState<Array<IZone>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')
    const [display, setDisplay] = useState<string>("")

    useEffect(() => {

        reqGet('zone/' + idZone).then((res) => { setZone(res) })

        reqGet('zipcode/zone/' + idZone).then((res) => { setData(res) })

        if (newEntry == true) {
            setNewEntry(false)
        }

        $('#zipcodeList .card-item.active').removeClass('active')
        sortZipCodes('')

        setSelectedZipcode([])

    }, [idZone, newEntry])

    function HandleSelection(zipcode, e) {
        $('#zipcodeList .card.active').removeClass('active')
        e.currentTarget.classList.add('active')

        setSelectedZipcode(zipcode)

    }

    function sortZipCodes(sortby) {
        let col = ""

        if (sortby == 'City') {
            col = "City"
            setSort("City")

            $("#btnSortZipcode").removeClass("active")
            $("#btnSortCity").addClass("active")

        } else {
            col = "ZipCode"
            setSort("ZipCode")

            $("#btnSortCity").removeClass("active")
            $("#btnSortZipcode").addClass("active")

        }

        if (data) {
            // ... duplicate array
            let sortedArray = [...data].sort(function (a, b) {
                if (a[col] < b[col]) {
                    return -1;
                } else if (a[col] > b[col]) {
                    return 1;
                } else {
                    return 0;
                }

            })

            setData(sortedArray)
        }
    }

    function removeZone() {
        reqDelete("zone/" + idZone, { data: { ActivityUserID: token.UserID } }).then(res => {
            setReload(true)
            navigate("/zone")
        })
    }
    const location = useLocation()

    const columns = useMemo<ColumnDef<IZipCode, any>[]>(
        () => [
            {
                accessorFn: row => row.City,
                id: 'City',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ZipCode,
                id: 'ZipCode',
                cell: info => info.getValue()
            },
        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    return <section className="body-container">

        <div className="modal fade" id="removeZone" tabIndex={-1} aria-labelledby="removeZone" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="removeZone">Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes vous  sur de vouloir supprimer :</p>
                        <p><b>{zone?.ZoneName}, {zone?.FirstName} {zone?.Name}</b></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => removeZone()}>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>


        {Header(
            <h3 className="section-title mb-0">{zone?.ZoneName}</h3>,
            <Link to="/zone" className="link-nav">{'<'} Retour</Link>,
            <>
                {PrivateLinkUpdate("update", GROUP.admin)}
                {PrivateComponent(
                    <button className="btn btn-delete ms-3" title="Supprimer" data-bs-toggle="modal" data-bs-target="#removeZone">
                        <i className="bi bi-trash3 me-1"></i> Supprimer
                    </button>,
                    GROUP.admin
                )}
            </>,
            { backMobileOnly: true }
        )}

        <section className="body container-fluid">

            <div className="row h-100">

                <div className="col-lg-8 col-h-fill overflow-auto order-2 order-lg-1">

                    {PrivateComponent(
                        <div className="row mb-3">
                            <div className='col-8 col-lg-8 col-xl-7'>
                                {ZipCodeAdd(data, setNewEntry, zone)}
                            </div>
                            <div className="col-4 col-lg-3 offset-lg-1 offset-xl-2 text-end">
                                {DeleteZipCode(selectedZipcode, setNewEntry, zone)}
                            </div>
                        </div>,
                        GROUP.crud_sav
                    )}

                    <div className="row mb-3 d-flex align-items-end">
                        <div className='col-5 col-xl-3'>
                            <label htmlFor="" className='form-label d-inline'>Trier par</label>
                            <select name="" id="" className="form-select" onChange={(e) => sortZipCodes(e.target.value)}>
                                <option value="Zipcode">Code postal</option>
                                <option value="City">Ville</option>
                            </select>
                        </div>
                        <div className="col-7 col-xl-9">
                            <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche" />
                        </div>
                    </div>


                    <div className="card card-fill card-flush">
                        <div className="card-body overflow-x-hidden">
                            <div className="row row-cols-2 row-cols-sm-3 row-cols-md-2 row-cols-xl-3 row-cols-xxl-4" id="zipcodeList">
                                {table.getRowModel().rows.map(row => {
                                    const zipcode = row.original
                                    return <div key={zipcode.ZipCodeID} className="col">

                                        <div className="card card-hover" onClick={(e) => { HandleSelection(zipcode, e) }}>
                                            <h6 className="item-title">{zipcode.City}</h6>
                                            <p className="item-subtitle">{zipcode.ZipCode}</p>
                                        </div>

                                    </div>
                                })}
                            </div>

                        </div>
                    </div>

                </div>


                <div className="col-lg-4 order-1 order-lg-2">

                    <div className="card">

                        <div className="card-header">
                            <h5 className="card-title">Technicien</h5>
                        </div>

                        <div className="card-body">

                            <div className="row">

                                <div className="col-4 col-lg-12 mb-0">
                                    <dl className="row mb-0 mb-lg-3">

                                        <dt className="col-12 mb-1">Role</dt>
                                        <dd className="col-12 mb-1">{zone?.Role}</dd>

                                    </dl>
                                </div>

                                <div className="col-8 col-lg-12 mb-0">
                                    <dl className="row mb-0">
                                        <dt className="col-12 mb-1">Responsable</dt>
                                        <dd className="col-12">
                                            {zone?.UserID != null ?
                                                <>
                                                    <p className="mb-1">{zone.FirstName} {zone.Name}</p>
                                                    <p className="mb-1">{zone.Email}</p>
                                                    <p className="mb-1">{zone.Phone}</p>
                                                </>
                                                : 'Non attribué'}
                                        </dd>
                                    </dl>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </section>
    </section>
}

function ZipCodeAdd(zipcodeList, setNewEntry, zone) {
    const params = useParams()
    const token = getToken()
    const [data, setData] = useState([])
    const [selectedID, setSelectedID] = useState<string>('')

    useEffect(() => {
        if (zipcodeList)
            reqGet('zipcode').then(function (res) {
                setData(RemoveExistingZipcode(res))
            })

    }, [zipcodeList])

    function RemoveExistingZipcode(allZipCode) {
        let existingZipCode = zipcodeList.map(b => b.ZipCodeID)

        return allZipCode.filter(id => !existingZipCode.includes(id.ZipCodeID));
    }

    function handleAdd() {

        if (selectedID != null && selectedID != '0') {
            let selectedZipCode = {
                idZone: params.idZone,
                idZipcode: selectedID
            }

            reqPost('zonezipcode', { ...selectedZipCode, ...zone, ActivityUserID: token.UserID }).then((res) => {
                setNewEntry(true)

                $('#zipcodeID').val('0')
                setSelectedID('')
            })

        }
    }

    return <div className="row">
        <div className='col col-xxl-9'>
            <select name="select-zipcode" className="form-select d-inline me-4" id="zipcodeID" defaultValue="0" onChange={(e) => setSelectedID(e.target.value)}>
                <option value="0">Code postal</option>
                {data.map((z: IZipCode, k: number) => {
                    return <option key={k} value={z.ZipCodeID}>{z.ZipCode} {z.City}</option>;
                })}
            </select>
        </div>

        <div className='col col-xxl-3 text-end'>
            <button className="btn btn-add d-inline" onClick={() => { handleAdd() }} disabled={selectedID == '' ? true : false}>
                <span className={ICONS.ADD + " me-2"}></span> Ajouter
            </button>
        </div>

    </div>
}

function DeleteZipCode(selectedZipcode, setNewEntry, zone) {
    const params = useParams()
    const token = getToken()

    useEffect(() => {
        $("#btnRemove").prop("disabled", true)

    }, [])

    useEffect(() => {
        if (!$.isEmptyObject(selectedZipcode))
            $("#btnRemove").prop("disabled", false)
        else
            $("#btnRemove").prop("disabled", true)

    }, [selectedZipcode])

    function handleRemove(zipcodeID) {
        reqDelete('zonezipcode/zone/' + params.idZone + '/zipcode/' + zipcodeID, { data: { ...selectedZipcode, ...zone, ActivityUserID: token.UserID } }).then((res) => {
            setNewEntry(true)
        })
    }

    return <button id="btnRemove" className="btn btn-outline" onClick={() => { handleRemove(selectedZipcode.ZipCodeID) }} title="Retirer de la liste">
        <i className={ICONS.DELETE + " me-2"}></i> Retirer
    </button>
}