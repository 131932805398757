import React, { useEffect, useMemo, useState } from "react";
import { DateCompare, DebouncedInput, ResolveIcon, dateFormat, fuzzyFilter } from "../../../common/common";
import { Link, NavLink, Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { IAddress, ICounterStatement, ITimesheet, IUser, IWeek } from "../../../common/types";
import { reqGet } from "../../../../service/apiRequest";
import $ from 'jquery'
import { ICONS, LAYOUT } from "../../../common/constants";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

export default function AccountCounterStatement({ reload, setReload }) {
    const location = useLocation()

    const [display, setDisplay] = useState<string>("")
    const [display2, setDisplay2] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.xxl) {
            setDisplay('d-flex')
            setDisplay2('d-flex')

        } else {
            if (location.pathname == "/account/counter-statement") {
                setDisplay('d-flex')
                setDisplay2('d-none')

            } else {
                setDisplay('d-none')
                setDisplay2('d-flex')
            }
        }

    }

    const user = useOutletContext<IUser>()

    if (user)
        return <div className="container-fluid h-100">
            <div className="row h-100">
                <div className={display + " col-xxl-6 col-h-fill px-0 border-right"}>
                    <div className="body-container content">

                        <header className="mb-3">
                            <h2 className="section-title mb-1">Relevés compteurs</h2>
                            <Link to="/account" className="link-nav">{"<"} Retour</Link>
                        </header>

                        <section className="body">
                            <article className="card card-fill card-flush">

                                <section className="card-body">

                                    <div className="list-group list-group-hover">

                                        <CounterStatementWeek user={user} reload={reload} setReload={setReload} />

                                    </div>
                                </section>


                            </article>
                        </section>
                    </div>
                </div>


                <div className={display2 + " col-xxl-6 px-0 col-h-fill overflow-auto px-0"}>

                    <Outlet />

                </div>
            </div>
        </div>
    else return <></>
}



function CounterStatementWeek({ user, reload, setReload }: { user: IUser, reload: boolean, setReload }) {
    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [weeks, setWeeks] = useState<Array<IWeek>>([])
    const [week, setWeek] = useState<IWeek>()

    const [counterStatements, setCounterStatements] = useState<Array<any | ICounterStatement>>([])
    const [arrayCounters, setArrayCounters] = useState<Array<any>>([])

    const [btnSortDone, setBtnSortDone] = useState<string>("")

    const [init, setInit] = useState<boolean>(true)

    const columns = useMemo<ColumnDef<any | ICounterStatement>[]>(
        () => [
            {
                accessorFn: row => row.AddressLine,
                id: 'AddressLine',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.City,
                id: 'City',
                cell: info => info.getValue()
            }
        ], []
    )

    const [data, setData] = useState<Array<any | ICounterStatement>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    // INIT
    useEffect(() => {
        reqGet("week/year/" + year).then(res => {
            setWeeks(res)

            var currentDate = new Date()
            var oneJan = new Date(currentDate.getFullYear(), 0, 1);
            let weekNb = Math.ceil((((currentDate.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7);

            const w = res.find(w => w.WeekNumber >= weekNb)
            setWeek(w)
        })
    }, [])

    // WEEK CHANGE
    useEffect(() => {
        if (week) {
            reqGet("counterstatement/user/" + user.UserID + "/week/" + week.WeekID).then(res => {
                setCounterStatements(res)
            })
        }
    }, [week])

    // RELOAD
    useEffect(() => {
        if (week && reload) {
            reqGet("counterstatement/user/" + user.UserID + "/week/" + week.WeekID).then(res => {
                setCounterStatements(res)
            })
        }
    }, [reload])

    // COUNTERS STATEMENTS CHANGE
    useEffect(() => {
        if (counterStatements) {
            const array = BuildCounterList(counterStatements)
            setArrayCounters(array)
            HandleSortAdressDone(btnSortDone, array)
        }
    }, [counterStatements])

    // YEAR CHANGE
    useEffect(() => {
        if (year && !init)
            reqGet("week/year/" + year).then(res => {
                setWeeks(res)
                if (week?.WeekNumber == 1)
                    setWeek(res[res.length - 1])
                else
                    setWeek(res[0])
            })
        else setInit(false)
    }, [year])

    function HandleWeekChange(idWeek) {
        setWeek(weeks.find(w => w.WeekID == idWeek))
    }

    function HandleNextWeek() {
        if (week?.WeekNumber == 52) {
            setYear(year + 1)
        } else {
            var w = weeks.find(w => w.WeekNumber == (week?.WeekNumber ?? 0) + 1)
            setWeek(w)
            $("#selectWeek").val(w?.WeekID)
        }
    }

    function HandlePreviousWeek() {
        if (week?.WeekNumber == 1) {
            setYear(year - 1)
        } else {
            var w = weeks.find(w => w.WeekNumber == (week?.WeekNumber ?? 0) - 1)
            setWeek(w)
            $("#selectWeek").val(w?.WeekID)
        }
    }

    function ValidateAddress(counters: Array<any>) {
        var valid = true

        counters.forEach((c) => {
            if (!c.CounterStatementValue)
                valid = false
        })
        return valid
    }

    function HandleSortAdressDone(sort, address = arrayCounters) {
        if (sort == "notDone") {
            const array = address.filter(a => {
                if (!ValidateAddress(a.Counters))
                    return a
            })

            setData(array)
            setBtnSortDone("notDone")
        } else if (sort == "done") {
            const array = address.filter(a => {
                if (ValidateAddress(a.Counters))
                    return a
            })
            setData(array)
            setBtnSortDone("done")
        } else {
            setData(address)
            setBtnSortDone("")
        }
    }

    function LastDate(counters) {
        var finalDate = ""
        counters.forEach((c, i) => {
            if (c.CounterStatementDate) {
                if (finalDate == "")
                    finalDate = c.CounterStatementDate
                else if (DateCompare(finalDate, c.CounterStatementDate) == 2)
                    finalDate = c.CounterStatementDate

            }
        })

        return finalDate
    }

    return <div className="body-container">
        <header className="header">
            <h3 className="section-title-2">
                Semaine {week?.WeekNumber}
                <span className="section-subtitle ms-3">{dateFormat(week?.DateStart, "dd")} - {dateFormat(week?.DateEnd, "dd")}{dateFormat(week?.DateStart, "/MM/yyyy")}</span>
            </h3>
        </header>




        <section className="body">

            <nav className="text-center mb-3">

                <button
                    className="btn btn-outline d-inline rounded"
                    onClick={() => HandlePreviousWeek()}
                >
                    <i className="bi bi-caret-left"></i>
                </button>

                <select className="form-select mx-3 d-inline w-auto" onChange={(e) => HandleWeekChange(e.target.value)} id="selectWeek" value={week?.WeekID}>
                    {weeks.map((w) => {
                        return <option key={w.WeekID} value={w.WeekID}>Semaine {w.WeekNumber}: {dateFormat(w.DateStart, "dd/MM/yyyy")}</option>
                    })}
                </select>

                <input type="number" className="form-control me-3 d-inline" style={{ width: "65px" }} value={year} onChange={(e) => setYear(parseInt(e.target.value))} />

                <button
                    className="btn btn-outline d-inline rounded" id="btnNext"
                    onClick={() => HandleNextWeek()}
                >
                    <i className="bi bi-caret-right"></i>
                </button>

            </nav>


            <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} className="mb-3" placeholder="Recherche" />

            <nav className="mb-3">
                <div className="toolbar">
                    <button className={"btn btn-filter d-inline rounded-5 " + (btnSortDone == "" ? "active" : "")} id="btnSortDone" onClick={() => HandleSortAdressDone("")}>
                        Tout
                    </button>
                    <button className={"btn btn-filter d-inline rounded-5 " + (btnSortDone == "notDone" ? "active" : "")} id="btnSortDone" onClick={() => HandleSortAdressDone("notDone")}>
                        A remplir
                    </button>
                    <button className={"btn btn-filter d-inline rounded-5 " + (btnSortDone == "done" ? "active" : "")} id="btnSortDone" onClick={() => HandleSortAdressDone("done")}>
                        Rempli
                    </button>
                </div>
            </nav>



            <section className="list list-hover-bordered">

                {table && table?.getRowModel().rows.map((row, i) => {
                    let cs = row.original

                    return <Link key={i} to="entry" state={{ AddressCounters: cs }} className={"list-item flex-column rounded " + (ValidateAddress(cs.Counters) ? "bg-success-inversed" : "")}>

                        <div className="w-100 d-flex flex-row justify-content-between">
                            <section className="list-item-data ps-0">
                                <h6>{cs.AddressLine}, {cs.ZipCode} {cs.City} ({cs.Counters.filter(c => c.CounterStatementValue).length}/{cs.Counters.length})</h6>

                                {cs.Counters.map((c, j) => {
                                    return <p key={i + "_" + j} className="mb-1"><i className={ResolveIcon(c.CounterType) + " me-2"}></i>{c.Label}</p>
                                })}
                            </section>

                            <footer className="list-item-more">
                                {ValidateAddress(cs.Counters) ? <i className={ICONS.SUCCESS}></i> : <i className={ICONS.WAITING}></i>}
                            </footer>

                        </div>

                        <div className="w-100 text-end">
                            <p className="mb-0">{dateFormat(LastDate(cs.Counters), "dd/MM/yyyy")}</p>
                        </div>
                    </Link>
                })}


            </section>

        </section>

        <footer className="footer d-block text-center">
            <p className="paragrapah mb-0">{data?.filter(a => ValidateAddress(a.Counters)).length}/{data.length} adresses</p>
        </footer>
    </div>
}



function BuildCounterList(counterStatements) {
    var array: Array<any> = []

    counterStatements?.forEach((c, i) => {
        if (i > 0 && c.AddressID == counterStatements[i - 1]?.AddressID) {

            array[array.length - 1].Counters.push({
                CounterID: c.CounterID,
                Label: c.Label,
                IDCounter: c.IDCounter,
                Capacity: c.Capacity,
                VolumeMin: c.VolumeMin,
                Tolerance: c.Tolerance,
                Unit: c.Unit,
                CounterType: c.CounterType,
                CounterStatementID: c.CounterStatementID,
                ObservationID: c.ObservationID,
                WeekID: c.WeekID,
                CounterStatementValue: c.CounterStatementValue,
                CounterStatementDate: c.CounterStatementDate,
                CounterStatementNote: c.CounterStatementNote,
                UserID: c.UserID,
                LastID: c.LastID,
                LastValue: c.LastValue,
                LastDate: c.LastDate
            })
        } else {
            array.push({
                AddressID: c.AddressID,
                AddressLine: c.AddressLine,
                ZipCode: c.ZipCode,
                City: c.City,
                Counters: [{
                    CounterID: c.CounterID,
                    Label: c.Label,
                    IDCounter: c.IDCounter,
                    Capacity: c.Capacity,
                    VolumeMin: c.VolumeMin,
                    Tolerance: c.Tolerance,
                    Unit: c.Unit,
                    CounterType: c.CounterType,
                    CounterStatementID: c.CounterStatementID,
                    ObservationID: c.ObservationID,
                    WeekID: c.WeekID,
                    CounterStatementValue: c.CounterStatementValue,
                    CounterStatementDate: c.CounterStatementDate,
                    CounterStatementNote: c.CounterStatementNote,
                    UserID: c.UserID,
                    LastID: c.LastID,
                    LastValue: c.LastValue,
                    LastDate: c.LastDate
                }]
            })
        }
    })


    return array
}