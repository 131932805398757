import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { DB_LOGIN } from './components/common/constants'
import { getToken } from "./useToken";


interface IFormInput {
  username: String;
  password: String;
}

const validationSchema = yup.object({
  username: yup.string().required("Ce champ est obligatoire"),
  password: yup.string().required("Ce champ est obligatoire")
})

const initialValues = {
  username: "",
  password: ""
}

class ApiErrors {
  errors = {}
  constructor(errors) {
    this.errors = errors
  }
}

async function loginUser(credentials) {
  return axios
    .post(DB_LOGIN, JSON.stringify(credentials),
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        },
      }
    )
    .then((res) => {

      if (res.data === false) {
        return null;
      } else {
        return res.data
      }
    });
}

export default function Login({ setToken }) {
  const navigate = useNavigate()
  const token = getToken()

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (token)
      navigate("/")
  }, [token])

  const { register, formState: { errors }, handleSubmit } = useForm<IFormInput>({
    mode: "onTouched",
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  })

  const onSubmit: SubmitHandler<IFormInput> = async function (data) {
    setError("")
    setLoading(true)

    try {
      const token = await loginUser(data);

      if (token != null) {
        setToken(token);

        window.location.href = "/home"
      } else {
        setError("Nom d'utilisateur ou mot de passe incorrect")
      }

    } catch (e) {
      setError(e)
      console.error(e)
    }

    setLoading(false)
  }

  return (
    <div className="container-full flex-column">

      <div className="card card-login">

        <div className="card-header justify-content-center pb-4">
          <img src="./media/images/multitherme/mt_logo_text.jpg" style={{ height: "200px" }} alt="" />
        </div>

        <div className="card-body">

          <form className="form px-0" onSubmit={handleSubmit(onSubmit)}>

            <div className="form-group mb-4">

              <label htmlFor="username" className="form-label">Nom d'utilisateur </label>

              <input className={"form-control " + (errors?.username ? "is-invalid" : "")} type="text" {...register("username")} />

              {errors.username?.message && <p className="mt-1 text-danger">{errors.username?.message}</p>}

            </div>

            <div className="form-group mb-4">

              <label htmlFor="password" className="form-label"> Mot de passe </label>

              <input className={"form-control " + (errors?.password ? "is-invalid" : "")} type="password" {...register("password")} />

              {errors.password?.message && <p className="mt-1 text-danger">{errors.password?.message}</p>}

            </div>

            <div className="text-end">
              <button disabled={loading} type="submit" className="btn btn-primary submit">
                <i className="bi bi-box-arrow-in-right me-2"></i>Connexion
              </button>
            </div>
          </form>

        </div>
      </div>


      {error && <Alert Error={error}></Alert>}

    </div>
  );
}


function Alert({ Error }) {
  return <div className="alert alert-danger">
    {Error}
  </div>
}