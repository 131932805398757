import React, { useEffect, useMemo, useState } from "react";
import { IAddress, IHeating, IManagement, IObservation, IZipCode, IZone } from "../../common/types";
import { reqGet } from "../../../service/apiRequest";
import { DebouncedInput, ManagementInputSearch, OwnerInputSearch, PrivateComponent, ReplaceSpecialChars, dateFormat, fuzzyFilter } from "../../common/common";
import { GROUP, LAYOUT, MONTH_FR } from "../../common/constants"
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import $ from 'jquery'
import { useReactTable, getCoreRowModel, getFilteredRowModel, getSortedRowModel, ColumnDef, } from '@tanstack/react-table'

interface IHeatingAddress extends IHeating, IAddress, IObservation { }

export default function Heating({ reload, setReload }) {
    const location = useLocation()
    const date = new Date();

    const [year, setYear] = useState<number>(date.getFullYear())
    const [month, setMonth] = useState<number>(date.getMonth() + 1)

    const [zones, setZones] = useState<Array<IZone>>([])
    const [zipCodes, setZipCodes] = useState<Array<IZipCode>>([])
    const [dataBackup, setDataBackup] = useState<Array<IHeatingAddress>>([])
    const [data, setData] = useState<Array<IHeatingAddress>>([])

    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/heating") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    const columns = useMemo<ColumnDef<IHeatingAddress, any>[]>(
        () => [
            {
                accessorFn: row => row.AddressLine,
                id: 'AddressLine',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.AddressComplementary,
                id: 'AddressComplementary',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ZoneName,
                id: 'ZoneName',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ZipCode,
                id: 'ZipCode',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.HeatingAction,
                id: 'HeatingAction',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ManagementID,
                id: 'ManagementID',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ManagementName,
                id: 'ManagementName',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.OwnerName,
                id: 'OwnerName',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ObservationID,
                id: 'ObservationID',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.HeatingDate,
                id: 'HeatingDate',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ObservationDate,
                id: 'ObservationDate',
                cell: info => info.getValue()
            }
        ], []
    )

    const [globalFilter, setGlobalFilter] = useState<string>('')

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        reqGet("heating/year/" + year).then(res => { setData(res) })
        reqGet("zone").then(res => { setZones(res) })
        reqGet("zipcode").then(res => { setZipCodes(res) })
    }, [])

    useEffect(() => {

        reqGet("heating/year/" + year).then(res => {
            setDataBackup(res)
            setData(res);
        }, rej => {
            setDataBackup([])
            setData([])
        })
    }, [year])


    useEffect(() => {
        if (reload) {
            reqGet("heating/year/" + year).then(res => {
                setDataBackup(res)
                setData(res);

                //Keep filters after reload
                HandleSort($("#sortGlobal").val())
                FilterObservation($("#filterObservation").val())
            }, rej => {
                setDataBackup([])
                setData([])
            })
            setReload(false)
        }
    }, [reload])

    function FilterAction(action) {
        table.getColumn("HeatingAction")?.setFilterValue(action)
    }

    function FilterZone(ZoneName) {
        table.getColumn("ZoneName")?.setFilterValue(ZoneName)
    }

    function FilterZipCode(ZipCode) {
        table.getColumn("ZipCode")?.setFilterValue(ZipCode)
    }

    function HandleSort(value: string) {
        if (value == "address")
            table.setSorting([{ id: "AddressLine", desc: false }])
        else if (value == "heatingDate")
            table.setSorting([{ id: "HeatingDate", desc: false }, { id: "ObservationDate", desc: false }])
        else if (value == "observationDate")
            table.setSorting([{ id: "ObservationDate", desc: false }, { id: "HeatingDate", desc: false }])
        else
            table.setSorting([{ id: "AddressLine", desc: false }])
    }

    function HandleManagementSelection(management) {
        table.getColumn("ManagementName")?.setFilterValue(management?.ManagementName)
    }

    function HandleOwnerSelection(owner) {
        table.getColumn("OwnerName")?.setFilterValue(owner?.OwnerName)
    }

    function FilterObservation(done) {
        if (done == "done")
            setData(dataBackup.filter(d => d.ObservationID != null))
        else if (done == "waiting")
            setData(dataBackup.filter(d => d.ObservationID == null))
        else if (done == "")
            setData(dataBackup)
    }

    return <div className="container-fluid h-100">
        <div className="row h-100">
            <div className={display + " col-md-7 col-lg-8 col-h-fill border-right py-3"}>
                <div className="body-container">

                    <header className="header">
                        <h2 className="section-title">Arrêt/MER chauffage</h2>
                    </header>

                    <section className="body">
                        <div className="card card-flush card-fill">

                            <header className="card-header d-block">

                                <div className="row mb-3">
                                    <div className="col-4 col-xl">
                                        <ManagementInputSearch onChange={(management) => HandleManagementSelection(management)} className={"form-sm"} />
                                    </div>

                                    <div className="col-4 col-xl">
                                        <OwnerInputSearch onChange={(management) => HandleOwnerSelection(management)} className={"form-sm"} />
                                    </div>

                                    <div className="col-4 col-xl">
                                        <label htmlFor="" className="form-label mb-1">Action</label>
                                        <select className="form-select form-sm" name="select-month" id="month" defaultValue={month} onChange={(e) => FilterAction(e.target.value)}>
                                            <option value="">Tout</option>
                                            <option value="Start">Mise en route</option>
                                            <option value="Stop">Arrêt</option>
                                        </select>
                                    </div>

                                    <div className="col-3 col-xl mt-2 mt-xl-0">
                                        <label htmlFor="" className="form-label mb-1">Année</label>
                                        <input className="form-control form-sm" type="number" name="control-year" id="year" defaultValue={date.getFullYear()} min={2022} max={date.getFullYear() + 1} onChange={(e) => setYear(parseInt(e.target.value))} />
                                    </div>

                                    <div className="col-3 col-xl mt-2 mt-xl-0">
                                        <label htmlFor="" className="form-label mb-1">Effectué</label>
                                        <select className="form-select form-sm" id="filterObservation" defaultValue={month} onChange={(e) => FilterObservation(e.target.value)}>
                                            <option value="">Tout</option>
                                            <option value="done">Effectué</option>
                                            <option value="waiting">En attente</option>
                                        </select>
                                    </div>

                                    <div className="col-3 col-xl mt-2 mt-xl-0">
                                        <label className="form-label mb-1">Zone</label>
                                        <select className="form-select form-sm" onChange={(e) => FilterZone(e.target.value)}>
                                            <option value="">Tout</option>

                                            {zones?.map((z) => {
                                                return <option value={z.ZoneName} key={z.ZoneID}>{z.FirstName} {z.Name}: {z.ZoneName}</option>
                                            })}
                                        </select>
                                    </div>

                                    <div className="col-3 col-xl mt-2 mt-xl-0">
                                        <label className="form-label mb-1">Ville</label>
                                        <select className="form-select form-sm" onChange={(e) => FilterZipCode(e.target.value)}>
                                            <option value="">Tout</option>

                                            {zipCodes?.map((z) => {
                                                return <option value={z.ZipCode} key={z.ZipCodeID}>{z.ZipCode} {z.City}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <hr />

                                <div className="row">

                                    <div className="col-8">
                                        <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} className="mb-3" placeholder="Recherche" />
                                    </div>

                                    <div className="col-4">
                                        <select name="" id="sortGlobal" className="form-select" onChange={(e) => HandleSort(e.target.value)}>
                                            <option value="heatingDate">Date prévu</option>
                                            <option value="observationDate">Date effectué</option>
                                            <option value="address">Adresse</option>
                                        </select>
                                    </div>
                                </div>

                            </header>

                            <section className="card-body">
                                <ul className="list-group list-group-hover">

                                    {/* If there's no heating to do */}
                                    {data.length == 0 && <div className="d-flex flex-column flex-grow-1 text-center justify-content-center">
                                        <p className="text-muted">Vous n'avez pas d'adresse sous surveillance</p>
                                    </div>}

                                    {/* Heating list */}
                                    {table.getRowModel().rows.map(row => {
                                        const h = row.original
                                        return <NavLink to={h.HeatingID.toString()} className="list-group-item mb-3">
                                            <div className="row">

                                                <div className="col-7 col-lg-6">
                                                    <h6 className="item-title text-truncate">{h.AddressLine} {h.AddressComplementary && "(" + h.AddressComplementary + ")"}</h6>

                                                    <p className="paragraph text-truncate mb-0">{h.ZipCode} {h.City}</p>
                                                </div>


                                                <div className="col-4 col-lg-3 text-end">
                                                    <h6 className="item-title mb-0">{h?.HeatingAction == "Start" ? "Mise en route" : "Arrêt"}</h6>
                                                    <p className="paragraph mb-0">
                                                        dès le {dateFormat(h.HeatingDate, "dd/MM/yyyy")}
                                                    </p>
                                                </div>

                                                <div className="col-4 col-lg-2 text-end d-none d-lg-block">
                                                    <h6 className="item-title mb-0">Effectué le</h6>
                                                    <p className="paragraph mb-0">
                                                        {h.ObservationDate ? dateFormat(h.ObservationDate, "dd/MM/yyyy") : ""}
                                                    </p>
                                                </div>

                                                <div className="col-1 d-flex align-items-center justify-content-end px-0">
                                                    {h?.ObservationID ?
                                                        <div className="badge rounded-5 bg-success d-flex justify-content-content align-items-center" style={{ padding: ".3rem .4rem", fontSize: "1.1rem" }}>
                                                            <i className="bi bi-check"></i>
                                                        </div> :
                                                        <div className="badge rounded-5 bg-warning d-flex justify-content-content align-items-center" style={{ padding: ".3rem .4rem", fontSize: "1.1rem" }}>
                                                            <i className="bi bi-clock"></i>
                                                        </div>}
                                                </div>
                                            </div>

                                            {(h.AddressServiceNote || h.HeatingNote) &&
                                                <div className="mt-2">
                                                    <h6 className="item-title">Information</h6>

                                                    {h.AddressServiceNote && <p className="paragraph mb-0">{h.AddressServiceNote}</p>}

                                                    {h.HeatingNote && <p className="pargraph mb-0">{h.HeatingNote}</p>}
                                                </div>
                                            }
                                        </NavLink>
                                    }
                                    )}
                                </ul>
                            </section>

                        </div>


                    </section>

                </div>
            </div>

            <div className="col col-md-5 col-lg-4 col-h-fill content">
                <Outlet />
            </div>
        </div>
    </div>
}