import React, { useMemo, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { reqGet, reqDelete } from "../../../../service/apiRequest";
import $ from 'jquery'

import { getToken } from '../../../../useToken'
import { Header, PrivateComponent, PrivateLinkUpdate, fuzzyFilter } from '../../../common/common'
import { LAYOUT, DEFAULT_IMG, GROUP, PERMISSION } from '../../../common/constants'
import { IActivity } from '../../../common/types'

import {
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
} from '@tanstack/react-table'

export default function PieceActivity() {
    const params = useParams()
    const idProduct = params.idProduct

    const [data, setData] = useState<Array<IActivity>>([])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')

    const columns = useMemo<ColumnDef<IActivity, any>[]>(
        () => [
            {
                accessorFn: row => row.DateTime,
                id: 'DateTime',
                header: 'Date',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Type,
                id: 'Type',
                header: 'Type',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Action,
                id: 'Action',
                header: 'Action',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.FirstName,
                id: 'FirstName',
                header: 'Prénom',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Name,
                id: 'Name',
                header: 'Nom',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Description,
                id: 'Description',
                header: 'Description',
                cell: info => info.getValue()
            },
        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,

        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        table.setPageSize(50)

        reqGet("product/piece/" + idProduct + "/activity").then(res => {
            setData(res);



        }, rej => {
            setData([])
        })
    }, [])


    return <section className="body-container">

        {Header(
            <h2 className="section-title">Activité</h2>,
            <Link className="link-nav mb-3" to={'/piece/' + idProduct}>{'< Retour'}</Link>
        )}

        <section className="body">
            {
                data.length > 0 ?
                    <div className="list-group list-group-flush">
                        {table.getRowModel().rows.map(row => {

                            return (
                                <div key={row.original.ActivityID} className="list-group-item">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 ps-0">
                                            <h6 className="item-title text-truncate mb-0">{row.original.Action}</h6>
                                            <p className="text-muted text-truncate mb-0">{row.original.Description}</p>
                                        </div>

                                        <div className="col-5 col-xl-4 text-end pe-0">
                                            <h6 className="item-title text-truncate mb-0">{row.original.FirstName} {row.original.Name}</h6>
                                            <p className="text-muted mb-0">{row.original.DateTime}</p>
                                        </div>
                                    </div>

                                </div>
                            )

                        })}
                    </div> :
                    <p className="text-muted text-center mt-5">Aucune activité pour cette pièces</p>
            }
        </section>

    </section>
}