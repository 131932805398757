import React, { useEffect, useState } from 'react'
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { reqGet, reqPut } from '../../../../service/apiRequest'
import { HeaderRow, ButtonSubmit } from "../../../common/common"
import { IManagement, IZipCode } from "../../../common/types"

const validationSchema = yup.object({
    ManagementID: yup.number().required(),
    ManagementName: yup.string().required("Ce champ est obligatoire"),
    ManagementAddress: yup.string().nullable(),
    ZipCodeID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ManagementEmail: yup.string().nullable(),
    ManagementPhone: yup.string().nullable(),
    ManagementFax: yup.string().nullable(),
    ManagementWebsite: yup.string().nullable(),
    ManagementEmailMazout: yup.string().nullable(),
    ManagementNote: yup.string().nullable()
})

const initialValues = {
    ManagementID: 0,
    ManagementName: "",
    ManagementAddress: null,
    ZipCodeID: null,
    ManagementEmail: null,
    ManagementPhone: null,
    ManagementFax: null,
    ManagementWebsite: null,
    ManagementEmailMazout: null,
    ActivityUserID: null,
    ManagementNote: null
}


export default function ManagementUpdate() {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const idManagement = params.idManagement
    const token = getToken()

    const [zipCode, setZipCode] = useState<Array<IZipCode>>([])

    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IManagement>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    })

    useEffect(() => {
        reqGet("zipcode").then(res => {
            setZipCode(res)

            reqGet("management/" + idManagement).then(res => {
                setValue("ManagementID", parseInt(idManagement!))
                setValue("ManagementName", res.ManagementName)
                setValue("ManagementAddress", res.ManagementAddress)
                setValue("ZipCodeID", res.ZipCodeID)
                setValue("ManagementEmail", res.ManagementEmail)
                setValue("ManagementPhone", res.ManagementPhone)
                setValue("ManagementFax", res.ManagementFax)
                setValue("ManagementWebsite", res.ManagementWebsite)
                setValue("ManagementEmailMazout", res.ManagementEmailMazout)
                setValue("ManagementNote", res.ManagementNote)
            })
        })

    }, [])

    const LinkBack = () => {
        if (location.state?.from == "client")
            return "/client/management/" + idManagement
        if (location.state?.from == "address")
            return location.state.path
        else
            return "/database/management"
    }

    const onSubmit: SubmitHandler<IManagement> = data => {

        reqPut('management', {...data, ActivityUserID: token.UserID}).then((res) => {
            navigate(LinkBack())
        })
    };

    return <div className="body-container">

        {HeaderRow(
            <h3 className="section-title">Modifier la régie</h3>,
            <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="ManagementName" className="form-label">Nom *</label>
                            <input type="text" className={"form-control " + (errors?.ManagementName ? "is-invalid" : "")} {...register("ManagementName")} />

                            {errors.ManagementName?.message && <p className="mt-2 text-danger">{errors.ManagementName?.message}</p>}

                        </div>

                        <div className="row mb-3">

                            <div className="col-6">
                                <label htmlFor="ManagementAddress" className="form-label">Adresse</label>
                                <input type="text" className={"form-control " + (errors?.ManagementAddress ? "is-invalid" : "")} {...register("ManagementAddress")} />

                                {errors.ManagementAddress?.message && <p className="mt-2 text-danger">{errors.ManagementAddress?.message}</p>}
                            </div>

                            <div className="col-6">
                                <label htmlFor="ZipCodeID" className="form-label">Code postal</label>
                                <select className='form-select' {...register("ZipCodeID")} >
                                    <option value=""></option>
                                    {zipCode.map(z => {
                                        return <option key={z.ZipCodeID} value={z.ZipCodeID}>{z.ZipCode} {z.City}</option>
                                    })}
                                </select>

                                {errors.ZipCodeID?.message && <p className="mt-2 text-danger">{errors.ZipCodeID?.message}</p>}
                            </div>

                        </div>

                        <div className="mb-3">

                            <label htmlFor="ManagementEmail" className="form-label">Email</label>
                            <input type="text" className={"form-control " + (errors?.ManagementEmail ? "is-invalid" : "")} {...register("ManagementEmail")} />

                            {errors.ManagementEmail?.message && <p className="mt-2 text-danger">{errors.ManagementEmail?.message}</p>}

                        </div>

                        <div className="mb-3">

                            <label htmlFor="ManagementEmailMazout" className="form-label">Email mazout</label>
                            <input type="text" className={"form-control " + (errors?.ManagementEmailMazout ? "is-invalid" : "")} {...register("ManagementEmailMazout")} />

                            {errors.ManagementEmailMazout?.message && <p className="mt-2 text-danger">{errors.ManagementEmailMazout?.message}</p>}

                        </div>

                        <div className="row mb-3">

                            <div className="col-6">
                                <label htmlFor="ManagementPhone" className="form-label">Téléphone</label>
                                <input type="text" className={"form-control " + (errors?.ManagementPhone ? "is-invalid" : "")} {...register("ManagementPhone")} />

                                {errors.ManagementPhone?.message && <p className="mt-2 text-danger">{errors.ManagementPhone?.message}</p>}
                            </div>

                            <div className="col-6">
                                <label htmlFor="ManagementFax" className="form-label">Fax</label>
                                <input type="text" className={"form-control " + (errors?.ManagementFax ? "is-invalid" : "")} {...register("ManagementFax")} />

                                {errors.ManagementFax?.message && <p className="mt-2 text-danger">{errors.ManagementFax?.message}</p>}
                            </div>


                        </div>

                        <div className="mb-3">

                            <label htmlFor="ManagementWebsite" className="form-label">Site internet</label>
                            <input type="text" className={"form-control " + (errors?.ManagementWebsite ? "is-invalid" : "")} {...register("ManagementWebsite")} />

                            {errors.ManagementWebsite?.message && <p className="mt-2 text-danger">{errors.ManagementWebsite?.message}</p>}

                        </div>

                        <div className="mb-3">

                            <label htmlFor="ManagementNote" className="form-label">Remarques</label>
                            <textarea className={(errors?.ManagementNote ? "is-invalid" : "")} id="ManagementNote" rows={4} {...register("ManagementNote")}></textarea>

                            {errors.ManagementNote?.message && <p className="mt-2 text-danger">{errors.ManagementNote?.message}</p>}

                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>

            </div>
        </div>
    </div>
}