import React, { useEffect, useState } from "react";
import { Header, PrivateComponent, PrivateLinkAdd, PrivateLinkUpdate, dateFormat } from "../../../common/common"
import { Link, Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { IAddress, IHeating, IObservation } from "../../../common/types";
import $ from 'jquery'

import { reqDelete, reqGet } from "../../../../service/apiRequest";
import { GROUP, ICONS, LAYOUT } from "../../../common/constants";
import { getToken } from "../../../../useToken";

interface IHeatingObservation extends IHeating, IObservation { }

export default function AddressHeating({ reload, setReload }) {
    const navigate = useNavigate()
    const location = useLocation()
    const token = getToken()
    const params = useParams()
    const idAddress = params.idAddress

    const [address, setAddress] = useState<IAddress>()
    const [heatings, setHeatings] = useState<Array<IHeatingObservation>>([])
    const [heatingSelected, setHeatingSelected] = useState<IHeating>()

    const LinkBack = () => {

        if (location?.state?.from == "client")
            return <button className="btn link-nav" onClick={() => navigate(-1)}>{"<"} Retour</button>
        else if (location?.state?.path)
            return <Link to={location.state.path} className="link-nav">{"<"} Retour</Link>
        else
            return <Link to={"/address/" + idAddress} className="link-nav">{"<"} Retour</Link>
    }


    useEffect(() => {
        reqGet("address/" + idAddress).then(res => setAddress(res))
        reqGet("heating/address/" + idAddress).then(res => setHeatings(res))
    }, [])


    useEffect(() => {
        if (reload) {
            reqGet("heating/address/" + idAddress).then(res => setHeatings(res))
            setReload(false)
        }
    }, [reload])

    function HandleSelection(heating, e) {
        $('#listHeating .list-item.active').removeClass('active')
        e.currentTarget.classList.add('active')

        setHeatingSelected(heating)
    }

    function RemoveHeating() {
        if (heatingSelected?.HeatingID)
            reqDelete("heating/" + heatingSelected?.HeatingID, { data: { ActivityUserID: token.UserID, ...address, ...heatingSelected } }).then(res => {
                setReload(true)
            })
    }

    return <section className="body-container content">


        <div className="modal fade" id="modalRemoveHeating" tabIndex={-1} aria-labelledby="modalRemoveHeating" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="modalRemoveHeating">Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes-vous sur de vouloir supprimer cet arrêt/MER de chauffage ?</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => RemoveHeating()}>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>


        <header className="header">
            <div>
                <h3 className="section-title">Arrêt/MER chauffage</h3>
                {LinkBack()}
            </div>

            {PrivateLinkAdd("add", GROUP.crud_sav, ["HeatingCreate"])}
        </header>



        <section className="body">
            
            {heatings.length > 0 &&
                <>
                    <section className="list list-hover-bordered" id="listHeating">
                        {heatings?.map(h => {
                            return <div className="list-item py-2" key={h.HeatingID} onClick={(e) => HandleSelection(h, e)}>
                                <header className="list-item-start">
                                    {h?.ObservationID ?
                                        <div className="badge rounded-5 bg-success" style={{ padding: ".3rem .4rem", fontSize: "1.1rem" }}>
                                            <i className="bi bi-check"></i>
                                        </div> :
                                        <div className="badge rounded-5 bg-warning" style={{ padding: ".3rem .4rem", fontSize: "1.1rem" }}>
                                            <i className="bi bi-clock"></i>
                                        </div>}
                                </header>

                                <section className="list-item-data pe-0" style={{ height: "auto" }}>

                                    <div className="row">
                                        {/* Action & date */}
                                        <div className="col-4 col-xl-2">
                                            <h6 className="mb-0">{h.HeatingAction == "Start" ? "Mise en route" : "Arrêt"}</h6>
                                            <p className="mb-0">{dateFormat(h?.HeatingDate, "dd/MM/yyyy")}</p>
                                        </div>

                                        {/* Information */}
                                        <div className="col-8 col-xl-4">
                                            <h6 className="mb-0">Information</h6>
                                            <p className="mb-0">{h?.HeatingNote}</p>
                                        </div>

                                        {/* Description */}
                                        <div className="col col-xl-6 mt-3 mt-lg-0">

                                            {h?.ObservationDate ?
                                                <div className="row">
                                                    <div className="col-4">
                                                        <h6 className="mb-0">{h.FirstName} {h.Name}</h6>
                                                        <p className="mb-0">{dateFormat(h.ObservationDate, "dd/MM/yyyy")}</p>
                                                    </div>
                                                    <div className="col">
                                                        <h6 className="mb-0">Remarque</h6>
                                                        <p className="mb-0">{h.Note}</p>
                                                    </div>
                                                </div> :
                                                <p className="text-muted mb-0">Pas encore effectué</p>
                                            }
                                        </div>

                                    </div>
                                </section>

                                <footer className="list-item-more h-100" style={{ width: "1.75rem" }}>
                                    <div className="dropdown">

                                        <button className="btn btn-unstyled" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="bi bi-three-dots-vertical" style={{ fontSize: "1.2rem" }}></i>
                                        </button>

                                        <ul className="dropdown-menu">
                                            {PrivateComponent(
                                                <Link to={h.HeatingID + "/update"} title="Modifier" className="dropdown-item">
                                                    <i className={ICONS.UPDATE + " me-2"}></i>Modifier
                                                </Link>,
                                                GROUP.crud_sav,
                                                ["HeatingUpdate"]
                                            )}

                                            {PrivateComponent(
                                                <button className="dropdown-item" title="Supprimer" data-bs-toggle="modal" data-bs-target="#modalRemoveHeating">
                                                    <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                                </button>,
                                                GROUP.crud_sav,
                                                ["HeatingDelete"]
                                            )}
                                        </ul>

                                    </div>
                                </footer>

                            </div>
                        })}

                    </section>
                </>
            }
        </section>

    </section>
}