import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { reqGet } from "../../../service/apiRequest";
import { IActivity } from "../../common/types";
import { Header, ResolveIcon, dateFormat } from "../../common/common";
import { ResolveAction, ResolveActivtiyLink, ResolveStatus } from "./activity";

export default function ActivityContent() {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const idActivity = params.idActivity

    const [activity, setActivity] = useState<IActivity>()

    useEffect(() => {
        if (!idActivity)
            navigate(-1)

        reqGet("activity/" + idActivity).then(res => {
            res.Data = JSON.parse(res.Data)

            setActivity(res)
        }, rej => {
            navigate(-1)
        })
    }, [idActivity])

    const LinkBack = () => {
        if (location?.state?.path)
            return location.state.path
        else
            return "/activity"
    }

    return <div className="container-fluid h-100">
        <div className="row h-100 justify-content-center">

            <div className="col-md-6 col-xxl-4 col-h-fill px-0">

                <div className="body-container">
                    {Header(
                        <h2 className="section-title">Détail de l'activité</h2>,
                        !location?.state?.path ?
                            <Link to={LinkBack()} className="link-nav"> {"<"} Retour</Link>
                            : <></>,
                        ResolveActivtiyLink(activity) ?
                            <Link className="link-nav" to={ResolveActivtiyLink(activity)}>
                                Aller vers l'élément {">"}
                            </Link> :
                            <></>
                    )}

                    <section className="body">
                        <article className="card">

                            <header className="card-header">
                                <h5 className="card-title">Activité</h5>
                            </header>

                            <section className="card-body">
                                <dl className="row mb-0">
                                    <dt className="col-5 mb-2">Utilisateur</dt>
                                    <dd className="col-7 mb-0">{activity?.FirstName} {activity?.Name}</dd>

                                    <dt className="col-5 mb-2">Date</dt>
                                    <dd className="col-7 mb-0">{dateFormat(activity?.DateTime, "dd/MM/yyyy, hh:mm")}</dd>

                                    <dt className="col-5 mb-2">Type</dt>
                                    <dd className="col-7 mb-0">{activity?.Type}</dd>

                                    <dt className="col-5 mb-2">Action</dt>
                                    <dd className="col-7 mb-0">{activity?.Action}</dd>
                                </dl>
                            </section>

                        </article>


                        {activity?.Description &&
                            <article className="card">

                                <header className="card-header">
                                    <h5 className="card-title">Description</h5>
                                </header>

                                <section className="card-body">
                                    <p className="paragraph mb-0">{activity.Description}</p>
                                </section>

                            </article>
                        }


                        {activity?.Data && ResolveData(activity.Action, activity.Data)}

                    </section>
                </div>

            </div>

        </div>
    </div >
}


function ResolveData(action, data: any) {
    switch (action) {
        case "Réapprovisionnement":
            return <article className="card">

                <header className="card-header">
                    <h5 className="card-title">Réapprovisionnement</h5>
                </header>

                <section className="card-body">

                    <div className="row mb-2">
                        <div className="col-2 ps-4">
                            <p className="text-muted mb-0">Nb</p>
                        </div>
                        <div className="col-10 ps-4">
                            <p className="text-muted mb-0">Pièce</p>
                        </div>
                    </div>


                    <ul className="list-group overflow-y-auto" style={{ maxHeight: "30rem" }}>
                        {data?.Data.map((d: any, key: number) => {
                            return <li className="list-group-item" key={key}>
                                <div className="row" key={key}>
                                    <div className="col-2 d-flex flex-grow-1 align-items-center">
                                        <h2 className="mb-0">{d?.ResupplyQuantity}</h2>
                                    </div>
                                    <div className="col-6">
                                        <h6 className="mb-0">{d?.ProductModel}</h6>
                                        <p className="mb-0">{d?.ProductReference}</p>
                                    </div>
                                    <div className="col-4 text-end">
                                        <h6 className="mb-0">{d?.Rack}{d?.Row}-{d?.Col}{d?.Compartment != null ? ("-" + d?.Compartment) : ""}</h6>
                                    </div>
                                </div>
                            </li>
                        })}
                    </ul>

                </section>

            </article>
        default:
            return <article className="card">

                <header className="card-header">
                    <h5 className="card-title">Données</h5>
                </header>

                <section className="card-body">
                    <dl className="row mb-0">
                        {Object.entries(data).map((d: any, key: number) => {

                            if (!Array.isArray(d[1]))
                                return <React.Fragment key={key}>
                                    <dt className="col-5 mb-2">{d[0]}</dt>
                                    <dd className="col-7 mb-0">{d[1]}</dd>
                                </React.Fragment>
                        })}
                    </dl>

                </section>

            </article>
    }
}