import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { reqGet } from "../../../../service/apiRequest";
import $ from "jquery";

import { PrivateComponent, PrivateMore, ResolveIcon, ServiceBadge, dateFormat } from "../../../common/common";
import { BADGE, COUNTER_TYPE, GROUP, ICONS } from "../../../common/constants"
import { IAddressService, IInstallation, IApartment, ICounter, ICounterStatement, IAddressContract } from "../../../common/types"


export function AddressContractsCards({ idAddress }) {

  const [addressContracts, setAddressContracts] = useState<Array<IAddressContract>>([])

  useEffect(() => {
    console.log("Contract cards idaddress: ", idAddress);

    reqGet("addresscontract/address/" + idAddress).then(res => {
      setAddressContracts(res)
    }, rej => { setAddressContracts([]) })
  }, [idAddress])

  return <section>
    <header className="header d-none d-lg-flex">
      <h2 className="section-title-2"><i className={ICONS.CONTRACT + " me-2"}></i>Contrats ({addressContracts.length})</h2>

      {addressContracts.length > 0 &&
        <Link to="contract" className="link-nav">Détails {">"}</Link>
      }
    </header>

    {/*
    <section className="d-none d-lg-flex align-items-center justify-content-start mb-3">

      <span className="text-danger me-2" title="Résilié"><i className={ICONS.TERMINATE}></i></span>
      <p className="d-inline text-muted mb-0 me-4">Résilié</p>

    </section> 
    */}

    <article className="card d-none d-lg-flex mb-4 overflow-auto" style={{ maxHeight: "400px" }}>

      <section className="card-body">

        {addressContracts.length > 0 ?
          <table className="table table-borderless">
            <thead>
              <tr>
                <th scope="col">Numéro</th>
                <th scope="col">Date de contrat</th>
                <th scope="col">Date de facturation</th>
                <th scope="col">Prestations</th>
                <th scope="col" style={{ width: "400px" }}>Informations</th>
              </tr>
            </thead>

            <tbody>
              {addressContracts.map((ac: IAddressContract) => {

                return <tr key={ac.AddressContractID}>
                  <td className="ps-0">
                    {ac.AddressContractNumber} {ac.AddressContractTerminate == 1 &&
                      <span className="text-danger ms-2" title="Résilié"><i className={ICONS.TERMINATE}></i></span>}
                  </td>
                  <td>{dateFormat(ac.AddressContractDate, "dd/MM/yyyy")}</td>
                  <td>{dateFormat(ac.AddressContractBilling, "dd/MM/yyyy")}</td>
                  <td>{ac.Services?.filter((obj1, i, arr) =>
                    /* FILTER DUPPLICATE */
                    arr.findIndex(obj2 => (obj2.ServiceID === obj1.ServiceID)) === i
                  ).map((s, k) => {
                    return <ServiceBadge service={s.Service} className="me-2 mb-2" key={k} firstLetter={true} />
                  })}
                  </td>
                  <td className="text-truncate pe-0" style={{ width: "400px" }}>
                    {ac.AddressContractDateTerminate &&
                      <p className="item-text">Résilié le {dateFormat(ac.AddressContractDateTerminate, "dd/MM/yyyy")}</p>
                    }
                    {ac.AddressContractNote}
                  </td>
                </tr>
              })}
            </tbody>

          </table>

          :

          <section className="card-body d-flex flex-column justify-content-center align-items-center">
            <p className="text-muted">Il n'y a aucun contrat</p>
            {PrivateComponent(
              <Link to="contract/add" className="btn btn-add">
                <i className={ICONS.ADD + " me-1"}></i> Ajouter un contrat
              </Link>,
              [...GROUP.crud_sav],
              ["ContractAdd"]
            )}
          </section>
        }

      </section>

    </article>
  </section>
}




export function InstallsList() {
  const params = useParams();

  const [installs, setInstalls] = useState<Array<IInstallation>>([]);
  const [filteredData, setFilteredData] = useState<Array<IInstallation>>([]);

  const [apartments, setApartments] = useState<Array<IApartment>>([]);

  useEffect(() => {
    reqGet("installation/address/" + params.idAddress).then((res) => {
      setInstalls(res);
      setFilteredData(res);
    });

    reqGet("apartment/address/" + params.idAddress).then((res) => { setApartments(res); });
  }, [params.idAddress]);

  function handleFilter() {
    if ($("#apartmentsFilter").val() == "all") setFilteredData(installs);
    else
      setFilteredData(
        installs.filter((d) => {
          return d.ApartmentID == $("#apartmentsFilter").val();
        })
      );
  }

  return <section>

    <header className="d-none d-lg-flex header mb-0">
      <h4 className="section-title-2"><i className={ICONS.INSTALLATION + " me-2"}></i>Installations ({installs.length})</h4>

      {installs.length > 0 && PrivateMore("installation")}
    </header>

    {/*
    <section className="d-none d-lg-flex align-items-center justify-content-start mb-3">

      <span className="badge bg-cleaning rounded-pill me-2">D</span>
      <p className="d-inline text-muted mb-0 me-4">Décrassage</p>

      <span className="badge bg-scaling rounded-pill me-2">D</span>
      <p className="d-inline text-muted mb-0 me-4">Détartrage</p>

      <span className="badge bg-maintenance rounded-pill me-2">E</span>
      <p className="d-inline text-muted mb-0 me-4">Entretien</p>

      <span className="badge bg-subcontractor rounded-pill me-2">S</span>
      <p className="d-inline text-muted mb-0">Sous-traitance</p>

    </section>
     */}

    <div className="card d-none d-lg-flex mb-4 overflow-auto" style={{ maxHeight: "400px" }}>

      <header className="card-header">

        {installs.length > 0 && apartments.length > 0 ? (
          <select className="form-select w-25" id="apartmentsFilter" defaultValue="0" onChange={() => handleFilter()} >
            <option value="all"> Tout </option>

            {apartments.map((a, k) => {
              return (
                <option key={k} value={a.ApartmentID}>
                  Appartement {a.ApartmentNumber}
                </option>
              );
            })}
          </select>
        ) : (
          ""
        )}
      </header>

      {installs.length > 0 ?
        <>
          <section className="card-body">

            <div className="table-responsive">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Prestations</th>
                    <th scope="col">Catégorie</th>
                    <th scope="col">Type</th>
                    <th scope="col">Modèle</th>
                    <th scope="col">Remarques</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((i: IInstallation) => {
                    return (
                      <tr key={i.InstallationID}>

                        <td>
                          {i?.Service.map((s: IAddressService) =>
                            <ServiceBadge service={s.Service} firstLetter={true} className="me-2" key={i.InstallationID + "_" + s.ServiceID} />
                          )}
                        </td>

                        <td className="text-nowrap">{i.Class || i.IClass}</td>
                        <td className="text-nowrap">{i.IType || i.Type}</td>
                        <td className="text-nowrap">{i.IModel || i.Model}</td>
                        <td>{i.Note}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </section>
        </>
        :
        <section className="card-body d-flex flex-column justify-content-center align-items-center">
          <p className="text-muted">Il n'y a aucune installation</p>
          {PrivateComponent(
            <Link to="installation/add" className="btn btn-add">
              <i className="bi bi-plus-lg me-1"></i> Ajouter une installation
            </Link>,
            [...GROUP.crud_sav, ...GROUP.crud_tec],
            ["InstallationAdd"]
          )}
        </section>
      }
    </div>
  </section>
}



const AlertValue = ({ counter }) => {
  if (counter.CounterType == COUNTER_TYPE.TANK) {
    if (counter?.CounterStatementValue <= counter?.VolumeMin && counter?.CounterStatementValue >= (counter?.VolumeMin - counter?.Tolerance)) {
      return <i className={"text-warning " + ICONS.WARNING} title="Volume en dessous "></i>
    }
  }
  else return <></>

  return <></>
}

export function AddressCounters({ idAddress }: { idAddress: string | undefined }) {

  const [counters, setCounters] = useState<Array<ICounterStatement>>([])

  useEffect(() => {
    reqGet("counter/address/" + idAddress).then(res => setCounters(res))
  }, [idAddress])

  return <>
    <div className="d-flex justify-content-between align-items-start">
      <h3 className="section-title-2"><i className={ICONS.COUNTER + " me-2"}></i>Compteurs ({counters.length})</h3>

      {counters.length > 0 &&
        <Link to="counter" className="link-nav">Détails {">"}</Link>
      }
    </div>

    <article className="card d-none d-lg-flex mb-4 overflow-auto" style={{ maxHeight: "400px" }}>

      <section className="card-body">

        {counters.length > 0 ?
          <table className="table table-borderless">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Libellé</th>
                <th scope="col">ID</th>
                <th scope="col">Unité</th>
                <th scope="col">Dernier relevé</th>
                <th scope="col">Date relevé</th>
              </tr>
            </thead>

            <tbody>
              {counters.map((c: ICounterStatement) => {
                return <tr key={c.CounterID}>
                  <td><i className={ResolveIcon(c.CounterType) + " me-2"}></i> {c.CounterType}</td>
                  <td>{c.Label}</td>
                  <td>{c.IDCounter}</td>
                  <td>{c.Unit}</td>
                  <td>{c.CounterStatementValue} <AlertValue counter={c} /></td>
                  <td>{dateFormat(c.CounterStatementDate, "dd/MM/yyyy")}</td>
                </tr>
              })}
            </tbody>

          </table> :
          <section className="card-body d-flex flex-column justify-content-center align-items-center">
            <p className="text-muted">Il n'y a aucun compteur</p>
            {PrivateComponent(
              <Link to="counter/add" className="btn btn-add">
                <i className={ICONS.ADD + " me-1"}></i> Ajouter un compteur
              </Link>,
              [...GROUP.crud_sav, ...GROUP.crud_tec],
              ["InstallationAdd"]
            )}
          </section>
        }

      </section>

    </article>
  </>
}