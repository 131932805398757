import React, { useEffect, useState } from "react";
import { reqGet } from "../../../../service/apiRequest";
import { IAddress } from "../../../common/types";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { Header, PrivateLinkAdd, PrivateLinkUpdate } from "../../../common/common";
import { DebouncedInput } from '../../../common/common';
import { GROUP } from "../../../common/constants";

export default function AddressGood() {
    const params = useParams()
    const idAddress = params.idAddress

    const address = useOutletContext();

    const [goods, setGoods] = useState<Array<any>>([])

    useEffect(() => {
        console.log(address);

        setGoods([
            { GoodID: 1, GoodNumber: 2406001, GoodTitle: "Objet du bon", GoodManager: "Sebastien Pittet", GoodContract: 1, GoodStatus: "En cours", GoodInformation: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam magni enim iure, numquam iusto earum totam amet doloremque fuga nihil assumenda! Harum quidem praesentium asperiores. Dolores a porro repellendus ex?", GoodTypes: ["Type 1", "Type 2"] },
            { GoodID: 2, GoodNumber: 2406007, GoodTitle: "Object du bon 2", GoodManager: "Sebastien Pittet", GoodContract: 0, GoodStatus: "En cours", GoodInformation: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam magni enim iure, numquam iusto earum totam amet doloremque fuga nihil assumenda! Harum quidem praesentium asperiores. Dolores a porro repellendus ex?", GoodTypes: ["Type 2"] },
            { GoodID: 3, GoodNumber: 2406017, GoodTitle: "Objet 3", GoodManager: "Florian Torche", GoodContract: 0, GoodStatus: "En cours", GoodInformation: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam magni enim iure, numquam iusto earum totam amet doloremque fuga nihil assumenda! Harum quidem praesentium asperiores. Dolores a porro repellendus ex?", GoodTypes: ["Type 1", "Type 3"] },
        ])

    }, [])

    return <main className="body-container content">

        <header className="row mb-3">
            <div className="col-3">
                <h2 className="section-title mb-0">Bons office</h2>
                <Link to={"/address/" + idAddress} className="link-nav">{"<"} Retour</Link>
            </div>

            <div className="col-6">
                <input type="text" className="form-control" placeholder="Recherche..." />
            </div>

            <div className="col-3 text-end">
                {PrivateLinkAdd("add", GROUP.crud_sav)}
            </div>
        </header>

        <section className="body">

            <section className="list list-hover-bordered">

                {goods.length > 0 && goods.map(g => {
                    return <Link to={g.GoodID.toString()} className="list-item" key={g.GoodID}>
                        <section className="list-item-data px-0">
                            <div className="row mb-3">
                                <div className="col">
                                    <h6 className="item-title">Numéro</h6>
                                    <p className="item-text">{g.GoodNumber}</p>
                                </div>
                                <div className="col-2">
                                    <h6 className="item-title">{g.GoodTitle}</h6>

                                    <div>
                                        {g.GoodTypes.map(t =>
                                            <p className="item-text d-inline me-2">{t}</p>
                                        )}
                                    </div>

                                </div>
                                <div className="col">
                                    <h6 className="item-title">Contrat</h6>
                                    <p className="item-text">{g.GoodContrat ? "Oui" : "Non"}</p>
                                </div>

                                <div className="col">
                                    <h6 className="item-title">Etat</h6>
                                    <p className="item-text">{g.GoodStatus}</p>
                                </div>

                                <div className="col">
                                    <h6 className="item-title">Responsable</h6>
                                    <p className="item-text">{g.GoodManager}</p>
                                </div>

                                <div className="col-5">
                                    <h6 className="item-title">Informations</h6>
                                    <p className="item-text text-truncate">{g.GoodInformation}</p>
                                </div>
                            </div>
                        </section>

                        <footer className="list-item-footer">

                        </footer>
                    </Link>
                })}

            </section>


        </section>
    </main>

}