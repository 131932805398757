import React, { useEffect, useState } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { HeaderRow, ButtonSubmit } from "../../../common/common"
import { IUserRole } from "../../../common/types"

const validationSchema = yup.object({
    Role: yup.string().required("Ce champ est obligatoire"),
    RoleMondayStart: yup.string().nullable(),
    RoleMondayLunchStart: yup.string().nullable(),
    RoleMondayLunchEnd: yup.string().nullable(),
    RoleMondayEnd: yup.string().nullable(),
    RoleTuesdayStart: yup.string().nullable(),
    RoleTuesdayLunchStart: yup.string().nullable(),
    RoleTuesdayLunchEnd: yup.string().nullable(),
    RoleTuesdayEnd: yup.string().nullable(),
    RoleWednesdayStart: yup.string().nullable(),
    RoleWednesdayLunchStart: yup.string().nullable(),
    RoleWednesdayLunchEnd: yup.string().nullable(),
    RoleWednesdayEnd: yup.string().nullable(),
    RoleThursdayStart: yup.string().nullable(),
    RoleThursdayLunchStart: yup.string().nullable(),
    RoleThursdayLunchEnd: yup.string().nullable(),
    RoleThursdayEnd: yup.string().nullable(),
    RoleFridayStart: yup.string().nullable(),
    RoleFridayLunchStart: yup.string().nullable(),
    RoleFridayLunchEnd: yup.string().nullable(),
    RoleFridayEnd: yup.string().nullable()
})

export default function UserRoleAdd() {
    const navigate = useNavigate()
    const token = getToken()
    const location = useLocation()

    const LinkBack = () => {
        if (location.state?.path)
            return location.state.path

        else
            return "/database/userrole"
    }

    const { register, formState: { errors }, handleSubmit } = useForm<IUserRole>({
        mode: "onTouched",
        resolver: yupResolver(validationSchema)
    })

    const onSubmit: SubmitHandler<IUserRole> = data => {

        reqPost('userrole', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate(LinkBack())
        })
    };

    return <div className="body-container">

        {HeaderRow(
            <h3 className="section-title">Ajouter un rôle utilisateur</h3>,
            <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="Role" className="form-label">Nom *</label>
                            <input type="text" className={"form-control " + (errors?.Role ? "is-invalid" : "")} {...register("Role")} />

                            {errors.Role?.message && <p className="mt-2 text-danger">{errors.Role?.message}</p>}

                        </div>


                        <h5>Lundi</h5>

                        <div className="row row-cols-2 row-cols-lg-4 mb-3">

                            <div className="col">
                                <label htmlFor="RoleMondayStart" className="form-label">Début</label>
                                <input type="time" className={"form-control " + (errors?.RoleMondayStart ? "is-invalid" : "")} {...register("RoleMondayStart")} />

                                {errors.RoleMondayStart?.message && <p className="mt-2 text-danger">{errors.RoleMondayStart?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleMondayLunchStart" className="form-label">Pause début</label>
                                <input type="time" className={"form-control " + (errors?.RoleMondayLunchStart ? "is-invalid" : "")} {...register("RoleMondayLunchStart")} />

                                {errors.RoleMondayLunchStart?.message && <p className="mt-2 text-danger">{errors.RoleMondayLunchStart?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleMondayLunchEnd" className="form-label">Pause fin</label>
                                <input type="time" className={"form-control " + (errors?.RoleMondayLunchEnd ? "is-invalid" : "")} {...register("RoleMondayLunchEnd")} />

                                {errors.RoleMondayLunchEnd?.message && <p className="mt-2 text-danger">{errors.RoleMondayLunchEnd?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleMondayEnd" className="form-label">Fin</label>
                                <input type="time" className={"form-control " + (errors?.RoleMondayEnd ? "is-invalid" : "")} {...register("RoleMondayEnd")} />

                                {errors.RoleMondayEnd?.message && <p className="mt-2 text-danger">{errors.RoleMondayEnd?.message}</p>}
                            </div>

                        </div>

                        <h5>Mardi</h5>

                        <div className="row row-cols-2 row-cols-lg-4 mb-3">

                            <div className="col">
                                <label htmlFor="RoleTuesdayStart" className="form-label">Début</label>
                                <input type="time" className={"form-control " + (errors?.RoleTuesdayStart ? "is-invalid" : "")} {...register("RoleTuesdayStart")} />

                                {errors.RoleTuesdayStart?.message && <p className="mt-2 text-danger">{errors.RoleTuesdayStart?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleTuesdayLunchStart" className="form-label">Pause début</label>
                                <input type="time" className={"form-control " + (errors?.RoleTuesdayLunchStart ? "is-invalid" : "")} {...register("RoleTuesdayLunchStart")} />

                                {errors.RoleTuesdayLunchStart?.message && <p className="mt-2 text-danger">{errors.RoleTuesdayLunchStart?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleTuesdayLunchEnd" className="form-label">Pause fin</label>
                                <input type="time" className={"form-control " + (errors?.RoleTuesdayLunchEnd ? "is-invalid" : "")} {...register("RoleTuesdayLunchEnd")} />

                                {errors.RoleTuesdayLunchEnd?.message && <p className="mt-2 text-danger">{errors.RoleTuesdayLunchEnd?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleTuesdayEnd" className="form-label">Fin</label>
                                <input type="time" className={"form-control " + (errors?.RoleTuesdayEnd ? "is-invalid" : "")} {...register("RoleTuesdayEnd")} />

                                {errors.RoleTuesdayEnd?.message && <p className="mt-2 text-danger">{errors.RoleTuesdayEnd?.message}</p>}
                            </div>

                        </div>

                        <h5>Mercredi</h5>

                        <div className="row row-cols-2 row-cols-lg-4 mb-3">

                            <div className="col">
                                <label htmlFor="RoleWednesdayStart" className="form-label">Début</label>
                                <input type="time" className={"form-control " + (errors?.RoleWednesdayStart ? "is-invalid" : "")} {...register("RoleWednesdayStart")} />

                                {errors.RoleWednesdayStart?.message && <p className="mt-2 text-danger">{errors.RoleWednesdayStart?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleWednesdayLunchStart" className="form-label">Pause début</label>
                                <input type="time" className={"form-control " + (errors?.RoleWednesdayLunchStart ? "is-invalid" : "")} {...register("RoleWednesdayLunchStart")} />

                                {errors.RoleWednesdayLunchStart?.message && <p className="mt-2 text-danger">{errors.RoleWednesdayLunchStart?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleWednesdayLunchEnd" className="form-label">Pause fin</label>
                                <input type="time" className={"form-control " + (errors?.RoleWednesdayLunchEnd ? "is-invalid" : "")} {...register("RoleWednesdayLunchEnd")} />

                                {errors.RoleWednesdayLunchEnd?.message && <p className="mt-2 text-danger">{errors.RoleWednesdayLunchEnd?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleWednesdayEnd" className="form-label">Fin</label>
                                <input type="time" className={"form-control " + (errors?.RoleWednesdayEnd ? "is-invalid" : "")} {...register("RoleWednesdayEnd")} />

                                {errors.RoleWednesdayEnd?.message && <p className="mt-2 text-danger">{errors.RoleWednesdayEnd?.message}</p>}
                            </div>

                        </div>

                        <h5>Jeudi</h5>

                        <div className="row row-cols-2 row-cols-lg-4 mb-3">

                            <div className="col">
                                <label htmlFor="RoleThursdayStart" className="form-label">Début</label>
                                <input type="time" className={"form-control " + (errors?.RoleThursdayStart ? "is-invalid" : "")} {...register("RoleThursdayStart")} />

                                {errors.RoleThursdayStart?.message && <p className="mt-2 text-danger">{errors.RoleThursdayStart?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleThursdayLunchStart" className="form-label">Pause début</label>
                                <input type="time" className={"form-control " + (errors?.RoleThursdayLunchStart ? "is-invalid" : "")} {...register("RoleThursdayLunchStart")} />

                                {errors.RoleThursdayLunchStart?.message && <p className="mt-2 text-danger">{errors.RoleThursdayLunchStart?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleThursdayLunchEnd" className="form-label">Pause fin</label>
                                <input type="time" className={"form-control " + (errors?.RoleThursdayLunchEnd ? "is-invalid" : "")} {...register("RoleThursdayLunchEnd")} />

                                {errors.RoleThursdayLunchEnd?.message && <p className="mt-2 text-danger">{errors.RoleThursdayLunchEnd?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleThursdayEnd" className="form-label">Fin</label>
                                <input type="time" className={"form-control " + (errors?.RoleThursdayEnd ? "is-invalid" : "")} {...register("RoleThursdayEnd")} />

                                {errors.RoleThursdayEnd?.message && <p className="mt-2 text-danger">{errors.RoleThursdayEnd?.message}</p>}
                            </div>

                        </div>

                        <h5>Vendredi</h5>

                        <div className="row row-cols-2 row-cols-lg-4 mb-3">

                            <div className="col">
                                <label htmlFor="RoleFridayStart" className="form-label">Début</label>
                                <input type="time" className={"form-control " + (errors?.RoleFridayStart ? "is-invalid" : "")} {...register("RoleFridayStart")} />

                                {errors.RoleFridayStart?.message && <p className="mt-2 text-danger">{errors.RoleFridayStart?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleFridayLunchStart" className="form-label">Pause début</label>
                                <input type="time" className={"form-control " + (errors?.RoleFridayLunchStart ? "is-invalid" : "")} {...register("RoleFridayLunchStart")} />

                                {errors.RoleFridayLunchStart?.message && <p className="mt-2 text-danger">{errors.RoleFridayLunchStart?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleFridayLunchEnd" className="form-label">Pause fin</label>
                                <input type="time" className={"form-control " + (errors?.RoleFridayLunchEnd ? "is-invalid" : "")} {...register("RoleFridayLunchEnd")} />

                                {errors.RoleFridayLunchEnd?.message && <p className="mt-2 text-danger">{errors.RoleFridayLunchEnd?.message}</p>}
                            </div>
                            <div className="col">
                                <label htmlFor="RoleFridayEnd" className="form-label">Fin</label>
                                <input type="time" className={"form-control " + (errors?.RoleFridayEnd ? "is-invalid" : "")} {...register("RoleFridayEnd")} />

                                {errors.RoleFridayEnd?.message && <p className="mt-2 text-danger">{errors.RoleFridayEnd?.message}</p>}
                            </div>

                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>

            </div>
        </div>
    </div>
}