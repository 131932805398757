import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import $ from "jquery";

import { reqGet, reqDelete } from "../../../service/apiRequest";
import { getToken } from '../../../useToken'
import { PrivateComponent, PrivateLinkAdd, PrivateLinkUpdate, Header } from "../../common/common";
import { LAYOUT, GROUP, PERMISSION } from "../../common/constants"
import { IVehicle, IVehicleCheck } from "../../common/types"
import { VehicleChecking } from "./vehicle-checking";
import { ThreeMonthCheck } from "./vehicle";

export default function VehicleContent({ setReload }) {
    const navigate = useNavigate()
    const params = useParams();
    const idVehicle = params.idVehicle;
    const token = getToken()

    const [vehicle, setVehicle] = useState<IVehicle>();
    const [checkType, setCheckType] = useState('checking')

    useEffect(() => {
        reqGet("vehicle/" + idVehicle).then((res) => { setVehicle(res); });

    }, [idVehicle]);

    function removeVehicle() {
        reqDelete("vehicle/" + idVehicle, { data: { ActivityUserID: token.UserID } }).then(res => {
            setReload(true)
            navigate("/vehicle")
        })
    }

    return (
        <section className="body-container">

            <div className="modal fade" id="removeVehicle" tabIndex={-1} aria-labelledby="removeVehicleLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="removeVehicleLabel">Supprimer</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Êtes vous  sur de vouloir supprimer :</p>
                            <p><b>{vehicle?.VehicleNumber ? vehicle.VehicleNumber + " | " : ''} {vehicle?.Brand}, {vehicle?.Model}</b></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => removeVehicle()}>Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>

            {Header(
                <h3 className="section-title mb-0">
                    {vehicle?.VehicleNumber ? vehicle.VehicleNumber + " | " : ''} {vehicle?.Brand ? vehicle.Brand + " : " : ''} {vehicle?.Model}
                </h3>,
                <Link to="/vehicle" className="link-nav d-block d-md-none">{'<'} Retour</Link>,
                <>
                    {PrivateLinkUpdate("update", GROUP.admin, PERMISSION.vehicleCheck)}
                    {PrivateComponent(
                        <button className="btn btn-delete ms-3" title="Supprimer" data-bs-toggle="modal" data-bs-target="#removeVehicle">
                            <i className="bi bi-trash3"></i> <span className="d-inline d-md-none d-lg-inline ms-1">Supprimer</span>
                        </button>,
                        GROUP.admin
                    )}
                </>,
                { backMobileOnly: true }
            )}

            <div className="mb-3 ps-3">
                {token.UserID == vehicle?.GuarantorID &&
                    <p className="badge d-inline-block rounded-5 bg-survey mb-0 me-2" title="Répondant du véhicule">Répondant</p>
                }

                {(vehicle?.Assignment == "Chantier" && (vehicle?.LastCheck == null || !ThreeMonthCheck(vehicle?.LastCheck))) &&
                    <i className="bi bi-hourglass-bottom text-danger" title="Le dernier contrôle est ancien de plus de trois mois" style={{ fontSize: "1.1rem" }}></i>
                }
            </div>

            <div className="body overflow-auto">
                <div className="row h-100 mx-0">

                    <div className="col-xl-7 col-xxl-8 h-100 overflow-auto order-2 order-xl-1 px-0 pe-xl-3">

                        {VehicleChecking(idVehicle, vehicle)}

                    </div>

                    <div className="col-xl-5 col-xxl-4 order-1 order-xl-2 d-flex mb-3 mb-xl-0 px-0 ps-xl-3">
                        {vehicle && <VehicleData vehicle={vehicle} />}
                    </div>

                </div>

            </div>

        </section>
    )
}



export function VehicleData({ vehicle }) {

    const [showData, setShowData] = useState<boolean>(false);
    const [display, setDisplay] = useState<boolean>(false)

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() <= LAYOUT.xl) {
            setDisplay(true)

        } else {
            setDisplay(false)
        }
    }

    return <div className="card card-fill">
        <div className="card-header pb-0">
            <h5 className="card-title">Données véhicule</h5>
            {display ?
                showData ?
                    <button className="btn btn-outline" onClick={() => setShowData(false)}>Masquer <i className="bi bi-chevron-up"></i></button>
                    : <button className="btn btn-outline" onClick={() => setShowData(true)}>Afficher <i className="bi bi-chevron-down"></i></button>
                : ""
            }
        </div>

        {$(window).width() >= LAYOUT.xl || showData ?
            <div className="card-body px-3 pt-3">
                <div className="row">

                    <div className="col-6 col-xl-12">
                        <dl className="row mb-0">

                            <dt className="col-sm-6 mb-0 mb-sm-3">Répondant</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.GuarantorID ? (vehicle?.GFirstName + " " + vehicle?.GName) : ""}</dd>

                            <dt className="col-sm-6 mb-0 mb-sm-3">Affectation</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.Assignment}</dd>

                            <dt className="col-sm-6 mb-0 mb-sm-3">Responsable</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.UserID ? (vehicle?.FirstName + " " + vehicle?.Name) : "A disposition"}</dd>

                            <dt className="col-sm-6 mb-0 mb-sm-3">Type</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.VehicleType}</dd>

                            <dt className="col-sm-6 mb-0 mb-sm-3">Plaque</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.Numberplate}</dd>

                            <dt className="col-sm-6 mb-0 mb-sm-3">Année</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.Year}</dd>
                        </dl>
                    </div>

                    <div className="col-6 col-xl-12">

                        <dl className="row mb-0">

                            <dt className="col-sm-6 mb-0 mb-sm-3">Carburant</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.Fuel}</dd>

                            <dt className="col-sm-6 mb-0 mb-sm-3">Géoloc</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.Geoloc == 1 ? 'Oui' : 'Non'}</dd>

                            <dt className="col-sm-6 mb-0 mb-sm-3">Crit' Air</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.CritAir}</dd>

                            <dt className="col-sm-6 mb-0 mb-sm-3">Galerie de toit</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.RoofGallery == 1 ? 'Oui' : 'Non'}</dd>

                            <dt className="col-sm-6 mb-0 mb-sm-3">Pneu d'été</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.TiresSummer}</dd>

                            <dt className="col-sm-6 mb-0 mb-sm-3">Pneu d'hiver</dt>
                            <dd className="col-sm-6 mb-3">{vehicle?.TiresWinter}</dd>
                        </dl>


                    </div>


                </div>

            </div>
            : ""
        }

    </div >
}