import React, { useState, useEffect, useLayoutEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { QrReader } from 'react-qr-reader';
import $ from 'jquery'
import { Column, Table, sortingFns, FilterFn, SortingFn } from '@tanstack/react-table'
import { RankingInfo, rankItem, compareItems, } from '@tanstack/match-sorter-utils'
import Holidays from "date-holidays";

import { reqGet } from '../../service/apiRequest'
import { getToken } from '../../useToken'
import { COUNTER_TYPE, ICONS, LAYOUT, PRODUCT_TYPE, SERVICE, TIMESHEET } from './constants';
import { IManagement, IOwner, IProduct, IStorage, IStorageProduct, ITenant, IWeek } from './types';

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const defaultData: string[] = []

const SM: number = 576
const MD: number = 768
const LG: number = 992
const XL: number = 1200
const XXL: number = 1400

let timer;              // Timer identifier
const waitTime = 400;   // Wait time in milliseconds 


// A debounced input react component
export function DebouncedInput({ value: initialValue, onChange, debounce = waitTime, ...props }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {

    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <div className={"form-icon " + props?.className} style={props?.style}>
            <div className='input-icon'>
                <i className="bi bi-search"></i>
            </div>
            <input className="form-control" placeholder={props?.placeholder} value={value} onChange={e => setValue(e.target.value)} list={props?.list} />
        </div>
    )
}

export function Filter({ column, table, }: { column: Column<any, unknown> | undefined; table: Table<any> }) {

    if (column) {
        const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

        const columnFilterValue = column.getFilterValue()

        const sortedUniqueValues = React.useMemo(
            () => typeof firstValue === 'number' ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort(),
            [column.getFacetedUniqueValues()]
        )

        return typeof firstValue === 'number' ? (
            <div className="d-inline-flex">

                <DebouncedInput
                    type="number"
                    className="form-filter"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[0] ?? ''}
                    onChange={value => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
                    placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''}`}
                />

                <DebouncedInput
                    type="number"
                    className="form-filter ms-3"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[1] ?? ''}
                    onChange={value => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
                    placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''}`}
                />

            </div>
        ) : (
            <>
                <datalist id={column.id + 'list'}>
                    {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                        <option value={value} key={value} />
                    ))}
                </datalist>

                <DebouncedInput
                    type="text"
                    value={(columnFilterValue ?? '') as string}
                    onChange={value => column.setFilterValue(value)}
                    placeholder={`Recherche... (${column.getFacetedUniqueValues().size})`}
                    className="form-filter"
                    list={column.id + 'list'}
                />
            </>
        )
    } else {
        return <></>
    }
}

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
    let dir = 0

    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(
            rowA.columnFiltersMeta[columnId]?.itemRank!,
            rowB.columnFiltersMeta[columnId]?.itemRank!
        )
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}


// INPUT

export function InputSearch({ initialArray, searchParameters, debounce = waitTime, value: initialValue, onChange, ...props }) {

    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])


    useEffect(() => {
        const timeout = setTimeout(() => {

            onChange(initialArray.find(a => {

                return searchParameters.forEach(params => {
                    if (a[params].includes(value))
                        return a
                })

            }))

        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <div className="form-search w-100">
            <i className="bi bi-search"></i>
            <input {...props} value={value} onChange={e => setValue(e.target.value)} />
        </div>
    )
}


// Product

export function ProductInfoFormatter(product: IProduct | IStorageProduct) {
    const pRef = product.ProductReference ? " (" + product.ProductReference + ") " : ""

    return product.ProductModel + ", " + product.ProductBrand + pRef
}

export function ProductTypeIconResolver(type: string | null) {
    if (type == PRODUCT_TYPE.PIECE)
        return <i className={ICONS.PIECE}></i>

    else if (type == PRODUCT_TYPE.TOOL)
        return <i className={ICONS.TOOL}></i>

    else if (type == PRODUCT_TYPE.EXCHANGER)
        return <i className={ICONS.EXCHANGER}></i>

    else
        return <></>
}


// ALERT MSG

export function AlertMsg(type: string, msg: string) {

    switch (type) {
        case "primary":
            return <div className="card bg-primary">
                <section className="card-body">
                    <i className={ICONS.PRIMARY + " me-3"}></i>
                    {msg}
                </section>
            </div>
        case "success":
            return <div className="card bg-success">
                <section className="card-body">
                    <i className={ICONS.SUCCESS + " me-3"}></i>
                    {msg}
                </section>
            </div>
            break;
        case "warning":
            return <div className="card bg-warning">
                <section className="card-body">
                    <i className={ICONS.WARNING + " me-3"}></i>
                    {msg}
                </section>
            </div>
        case "danger":
            return <div className="card bg-danger">
                <section className="card-body">
                    <i className={ICONS.DANGER + " me-3"}></i>
                    {msg}
                </section>
            </div>
        case "info":
            return <div className="card bg-info">
                <section className="card-body">
                    <i className={ICONS.INFO + " me-3"}></i>
                    {msg}
                </section>
            </div>
        default:
            return <>
            </>
    }

}


// BUTTON LINK
export function ButtonSubmit({ onClick, disabled, text }: { onClick?, disabled?: boolean, text?: string }) {

    $("#btnSubmit").on("click", () => {
        setTimeout(function () {
            $("#btnSubmit").prop("disabled", true)
        }, 500);

        setTimeout(function () {
            $("#btnSubmit").prop("disabled", false)
        }, 1000);
    })

    return <button id="btnSubmit" type="submit" className="btn btn-add" onClick={onClick} disabled={disabled}>
        <i className={ICONS.VALIDATE + " me-1"}></i> {text ?? "Valider"}
    </button>
}

export function CardBtnLink({ path, title, text, icon, state }: { path: string, title: string, text?: string | null | undefined, icon?: string, state?: {} }) {
    return <NavLink to={path} className="card card-btn" state={state}>
        {icon &&
            <header className='card-header'>
                <div className="header-content">
                    <i className={"card-icon " + icon}></i>
                </div>
            </header>
        }
        <section className="card-body">
            <h5 className="card-title">{title}</h5>
            {text && <p className="card-text">{text}</p>}
        </section>

        <footer className="card-footer">
            <i className="bi bi-chevron-right"></i>
        </footer>
    </NavLink>
}

export function PrivateComponent(component: JSX.Element, roles?: Array<String>, permission?: Array<String>) {

    if (roles) {
        const token = getToken()

        let userHasRequiredRight = false

        // Right required
        if (permission && token.Permission) {

            token.Permission.map(p => {

                permission.includes(p.Permission) ? userHasRequiredRight = true : ""

            })

        }

        const userHasRequiredRole = token && roles.includes(token.Role) ? true : false;

        if (userHasRequiredRole || userHasRequiredRight) {

            return component
        }
    } else {
        return component
    }
}


export function PrivateLinkAdd(path: string, roles?: Array<String>, permission?: Array<String>) {
    const button = <Link to={path} className="btn btn-add" title="Ajouter">
        <i className={ICONS.ADD + " me-1"}></i> Ajouter
    </Link>
    return PrivateComponent(button, roles, permission)
}

export function PrivateLinkUpdate(path: string, roles?: Array<String>, permission?: Array<String>) {
    const link = <Link to={path} className="btn btn-outline" title="Modifier">
        <i className={ICONS.UPDATE + " me-1"}></i> Modifier
    </Link>
    return PrivateComponent(link, roles, permission)
}

export function PrivateMore(path: string, roles?: Array<String>, permission?: Array<String>) {
    const link = <Link to={path} className="link-nav"> Détails {">"}</Link>

    return PrivateComponent(link, roles, permission)
}

export function ModalFullScreen({ toggle, setToggle, title = "", content = <></> }) {

    if (toggle)
        return <div className="container-fullscreen-dark" onClick={() => setToggle(false)}>

            <div className="card rounded-3 p-3" style={{ width: "75vw", maxWidth: "25rem", maxHeight: "30rem" }} onClick={(e) => e.stopPropagation()}>

                <div className='card-header'>
                    <h5 className="card-title">{title}</h5>

                    <button className='btn p-0' onClick={() => setToggle(false)}>
                        <i className='bi bi-x-lg' style={{ fontSize: "1.3rem" }}></i>
                    </button>
                </div>

                <div className='card-body d-flex flex-column flex-grow-1' style={{ overflowY: "auto", overflowX: "hidden" }}>
                    {content}
                </div>

            </div>

        </div>
    else
        return <></>
}



export function isAuthorized(roles?: Array<String>, permission?: Array<String>) {
    if (roles) {
        const token = getToken()

        let userHasRequiredRight = false

        // Right required
        if (permission && token.Permission) {

            token.Permission.map(p => {

                permission.includes(p.Permission) ? userHasRequiredRight = true : ""

            })

        }

        const userHasRequiredRole = token && roles.includes(token.Role) ? true : false;

        if (userHasRequiredRole || userHasRequiredRight) {
            return true
        }
        else {
            return false
        }
    } else {
        return true
    }
}


// Layout

export function Header(title: JSX.Element, back?: JSX.Element, more?: JSX.Element, param?: { backMobileOnly: boolean }) {

    const initHeader = () => {
        if (param?.backMobileOnly) {
            return <>
                <div className="col-12 col-md-8 px-0 pe-md-2">
                    {title}
                </div>

                <div className="col-3 d-flex d-md-none ps-0">
                    {back}
                </div>

                <div className="col-9 col-md-4 d-flex justify-content-end align-items-start pe-0">
                    {more}
                </div>
            </>

        } else if (back) {
            return <>
                <div className="col-12 mb-2 px-0">
                    {title}
                </div>

                <div className="col-3 ps-0">
                    {back}
                </div>

                <div className="col-9 d-flex justify-content-end align-items-start pe-0">
                    {more}
                </div>
            </>
        } else {
            return <>
                <div className="col-md-8 mb-2 ps-0">
                    {title}
                </div>

                <div className="col-md-4 d-flex justify-content-end align-items-start pe-0">
                    {more}
                </div>
            </>
        }
    }


    return <header className="container-fluid mb-3">
        <div className="row">

            {initHeader()}

        </div>

    </header>
}

export function HeaderRow(title: JSX.Element, back?: JSX.Element, more?: JSX.Element) {

    return <header className="container-fluid mb-3">
        <div className="row">

            <div className="col-6 col-lg-3 order-2 order-lg-1 ps-0">
                {back}
            </div>

            <div className="col-12 col-lg-6 order-lg-2 order-1 text-lg-center px-0 px-lg-3">
                {title}
            </div>

            <div className="col-6 col-lg-3 order-3 text-end pe-0">
                {more}
            </div>

        </div>

    </header>
}

export function ButtonModal({ id, title, text, btnTitle, btnIcon, btnStyle = "outline", btnExpand = true, onClick, className }:
    { id: string, title: string, text: JSX.Element, btnTitle: string, btnIcon: string, btnStyle?: string, btnExpand?: boolean, onClick, className?: string }) {

    return <>
        <div className="modal" id={id} tabIndex={-1} aria-labelledby={id} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <header className="modal-header">
                        <h1 className="modal-title fs-5" id={id}>{title}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </header>

                    <section className="modal-body">
                        {text}
                    </section>

                    <footer className="modal-footer">
                        <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                            <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                        </button>

                        <button type="button" className={"btn btn-" + btnStyle} data-bs-dismiss="modal" onClick={() => onClick()}>
                            <i className={btnIcon}></i> <span className="d-inline d-md-none d-lg-inline ms-1">{btnTitle}</span>
                        </button>
                    </footer>
                </div>
            </div>
        </div>

        <button className={"btn btn-" + btnStyle + " " + className} title={btnTitle} data-bs-toggle="modal" data-bs-target={"#" + id}>
            <i className={btnIcon}></i> {btnExpand && <span className="d-inline d-md-none d-lg-inline ms-1">{btnTitle}</span>}
        </button>
    </>
}



// Utility
export function HandleDisplay({ setDisplay, path, layout, displayShow = "d-flex", displayHide = "d-none" }: { setDisplay, path: string, layout: number, displayShow?: string, displayHide?: string }) {

    if ($(window).width() >= layout) {
        setDisplay(displayShow)
    } else {
        if (location.pathname == path) {
            setDisplay(displayShow)
        } else {
            setDisplay(displayHide)
        }
    }

}

export function DocumentTypeIcon({ docName, size = 1.8 }: { docName: string, size?: number }) {
    var dimension = (size + 1.2).toString() + "rem"
    var fontSize = size.toString() + "rem"
    var name = docName.split(".")[1].toLocaleLowerCase()

    var params = {
        icon: ICONS.FILE,
        class: "file"
    }

    if (name == "pdf")
        params = { icon: ICONS.PDF, class: "pdf" }

    else if (name == "xls" || name == "xlsx" || name == "ods" || name == "fods")
        params = { icon: ICONS.EXCEL, class: "excel" }

    else if (name == "jpg" || name == "png")
        params = { icon: ICONS.IMAGE, class: "image" }

    else if (name == "ppt" || name == "pptx" || name == "odp" || name == "fodp")
        params = { icon: ICONS.POWERPOINT, class: "powerpoint" }

    else if (name == "doc" || name == "docx" || name == "odt" || name == "fodt")
        params = { icon: ICONS.WORD, class: "word" }

    return <div className={"rounded-5 d-flex justify-content-center align-items-center bg-" + params.class} style={{ width: dimension, height: dimension }}>
        <i className={params.icon + " text-" + params.class} style={{ fontSize: fontSize }}></i>
    </div>
}

export function ReplaceSpecialChars(str: string) {
    str = str.toLowerCase()

    str = str.replace(/[àáâãäå]/, "a");
    str = str.replace(/[ç]/, "c");
    str = str.replace(/[éèëê]/, "e");
    str = str.replace(/[îï]/, "i");
    str = str.replace(/[ôö]/, "o");
    str = str.replace(/[ùúüû]/, "u");

    return str;
}

export function ResolveIcon(name: string) {

    switch (name) {
        case "adresse":
            return ICONS.ADDRESS
        case "activité":
            return ICONS.ADDRESS

        case "Chantier":
            return ICONS.CONSTRUCTION
        case "Entretien":
            return ICONS.MAINTENANCE
        case "Dépannage":
            return ICONS.TROUBLESHOOTING

        case PRODUCT_TYPE.PIECE:
            return ICONS.PIECE
        case PRODUCT_TYPE.TOOL:
            return ICONS.TOOL
        case PRODUCT_TYPE.EXCHANGER:
            return ICONS.EXCHANGER

        case COUNTER_TYPE.ELECTRIC:
            return ICONS.ELECTRIC
        case COUNTER_TYPE.FUEL:
            return ICONS.FUEL
        case COUNTER_TYPE.GAZ:
            return ICONS.GAZ
        case COUNTER_TYPE.HEAT:
            return ICONS.HEAT
        case COUNTER_TYPE.PELLETS:
            return ICONS.PELLETS
        case COUNTER_TYPE.SOLAR:
            return ICONS.SOLAR
        case COUNTER_TYPE.TANK:
            return ICONS.TANK
        case COUNTER_TYPE.WATER_COLD:
            return ICONS.WATER
        case COUNTER_TYPE.WATER_WARM:
            return ICONS.WATER

        default:
            return ""
    }
}

export function ProductTypeLinkResolver(productType: string) {
    switch (productType) {
        case PRODUCT_TYPE.PIECE:
            return "piece"
        case PRODUCT_TYPE.TOOL:
            return "tool"
        case PRODUCT_TYPE.EXCHANGER:
            return "exchanger"
        default:
            return ""
    }
}

export const ServiceBadge = ({ service, firstLetter, className }: { service: string, firstLetter?: boolean, className?: string }) => {
    const serviceShown = firstLetter ? service[0] : service

    if (service == SERVICE.SURVEY)
        return <span className={"badge bg-survey rounded-pill " + className} title={service}>{serviceShown}</span>
    else if (service == SERVICE.OPTIMISATION)
        return <span className={"badge bg-optimisation rounded-pill " + className} title={service}>{serviceShown}</span>
    else if (service == SERVICE.MAINTEANANCE)
        return <span className={"badge bg-maintenance rounded-pill " + className} title={service}>{serviceShown}</span>
    else if (service == SERVICE.SCALING)
        return <span className={"badge bg-scaling rounded-pill " + className} title={service}>{serviceShown}</span>
    else if (service == SERVICE.SUBCONTRACTOR)
        return <span className={"badge bg-subcontractor rounded-pill " + className} title={service}>{serviceShown}</span>
    else if (service == SERVICE.HEATING)
        return <span className={"badge bg-heating rounded-pill " + className} title={service}>{serviceShown}</span>
    else if (service == SERVICE.IDC)
        return <span className={"badge bg-idc rounded-pill " + className} title={service}>{serviceShown}</span>
    else if (service == SERVICE.WATER)
        return <span className={"badge bg-water rounded-pill " + className} title={service}>{serviceShown}</span>
    else if (service == SERVICE.CLEANING)
        return <span className={"badge bg-cleaning rounded-pill " + className} title={service}>{serviceShown}</span>
    else if (service == SERVICE.PURGE)
        return <span className={"badge bg-purge rounded-pill " + className} title={service}>{serviceShown}</span>
    else if (service == SERVICE.COUNTERS)
        return <span className={"badge bg-counters rounded-pill " + className} title={service}>{serviceShown}</span>
    else
        return <span className={"badge btn-outline rounded-pill " + className} title={service}>{serviceShown}</span>
}

export function FormatDocumentName(name: string) {
    name = name.trim().replace(/\s+/g, '_');
    return name
}

export function ConvertBytes(bytes: number) {
    if (bytes < Math.pow(10, 6))
        return (bytes / Math.pow(10, 3)).toFixed(2) + " KB"

    else if (bytes < Math.pow(10, 9))
        return (bytes / Math.pow(10, 6)).toFixed(2) + " MB"

}


export const RandomMotivatingSentence = () => {
    const SentencesArray = [
        { sentence: "Tout est possible à qui rêve, ose, travaille et n'abandonne jamais.", author: "Xavier Dolan" },
        { sentence: "Ceux qui ne font rien ne se trompent jamais.", author: "Théodore de Banville" },
        { sentence: "Vous ne pouvez pas être ce gamin qui reste figé en haut du toboggan en réfléchissant.Vous devez glisser.", author: "Tina Fey" },
        { sentence: "Je ne perds jamais. Soit je gagne, soit j'apprends.", author: "Nelson Mandela" },
        { sentence: "Il n'y a qu'une chose qui puisse rendre un rêve impossible, c'est la peur d'échouer.", author: "Paulo Coelho" },
        { sentence: "L'indécision fait perdre plus de temps qu'une mauvaise décision.", author: "Marcus Tullius Cicero" },
        { sentence: "Si l'objectif prioritaire d'un capitaine était de préserver son navire, il ne le ferait jamais sortir du port.", author: "Thomas Aquinas" },
        { sentence: "Le meilleur chemin est toujours celui qui est le plus direct.", author: "Robert Frost" },
        { sentence: "Le seul moyen de découvrir les limites du possible est de s'aventurer un peu plus loin dans l'impossible.", author: "Arthur C.Clarke" },
        { sentence: "Vous n'allez jamais laisser des empreintes de pas qui durent si vous marchez toujours sur la pointe des pieds.", author: "Leymah Gbowee" },
        { sentence: "Si vous ne risquez rien, vous prenez encore plus de risque.", author: "Erica Jong" },
        { sentence: "Je préfèrerais regretter les choses que j'ai faites plutôt que celles que je n'ai pas faites.", author: "Lucille Ball" },
        { sentence: "Et vint le jour où le risque de rester serré dans un bourgeon devint plus douloureux que le risque de choisir de fleurir.", author: "Anaïs Nin" },
        { sentence: "En suivant le chemin qui s'appelle plus tard, nous arrivons sur la place qui s'appelle jamais.", author: "Sénèque" },
        { sentence: "Si vous pensez que l'aventure est dangereuse, essayez la routine, elle est mortelle.", author: "Paulo Coelho" },
        { sentence: "Quand on ose, on se trompe souvent.Quand on n'ose pas, on se trompe toujours.", author: "Romain Rolland" },
        { sentence: "N'aie pas peur d'avancer lentement.Aie peur de rester immobile.", author: "Proverbe chinois" },
        { sentence: "Il arrive souvent de ne rien obtenir parce que l'on ne tente rien.", author: "Jacques Deval" },
        { sentence: "Le commencement est beaucoup plus que la moitié de l'objectif.", author: "Aristote" },
        { sentence: "L'action est la clé fondamentale de tout succès.", author: "Pablo Picasso" },
        { sentence: "L'inspiration existe, mais il faut qu'elle vous trouve au travail.", author: "Pablo Picass" },
        { sentence: "Montrez-vous, montrez-vous, montrez-vous, et au bout d'un moment, la muse se montrera également.", author: "Isabel Allende" },
        { sentence: "Celui qui se perd dans sa passion a moins perdu que celui qui perd sa passion.", author: "Alexandre Jardin" },
        { sentence: "Gardez toujours un petit feu allumé, aussi petit ou caché soit-il.", author: "Cormac McCarthy" },
        { sentence: "N'imitez rien ni personne.Un lion qui copie un lion devient un singe.", author: "Victor Hugo" },
        { sentence: "L'art commence avec la difficulté.", author: "André Gide" },
        { sentence: "Il n'est d'intelligence que créatrice.", author: "Amélie Nothomb" },
        { sentence: "N'attendez pas l'inspiration. Elle vient pendant que l'on travaille.", author: "Henri Matisse" },
        { sentence: "La concentration est la danse qui ouvre le bal de l'inspiration.", author: "Jean-Pierre Szymaniak" },
        { sentence: "La flamme de l'inspiration doit être encouragée. Placez un verre autour de cette petite bougie et protégez-la du découragement ou du ridicule.", author: "Mary Higgins Clark" },
        { sentence: "La vie est un défi à relever, un bonheur à mériter, une aventure à tenter.", author: "Mère Teresa" },
        { sentence: "Si vous voulez que la vie vous sourie, apportez-lui d'abord votre bonne humeur.", author: "Baruch Spinoza" },
        { sentence: "Vous portez le passeport qui vous permettra d'atteindre votre propre bonheur.", author: "Diane von Furstenber" },
        { sentence: "Je ne regarde jamais en arrière. Cela détourne l'attention de l'instant présent.", author: "Edna Mode" },
        { sentence: "Quand la vie te donne une centaine de raisons de pleurer, montre-lui que tu en as mille de sourire.", author: "Johnny Depp" },
        { sentence: "La vie ce n'est pas d'attendre que les orages passent, c'est d'apprendre à danser sous la pluie", author: "Sénèque" },
        { sentence: "Vis comme si tu devais mourir demain, apprends comme si tu devais vivre toujours.", author: "Gandhi" },
        { sentence: "Plus tard, il sera trop tard. Notre vie, c'est maintenant.", author: "Jacques Prévert" },
        { sentence: "Il n'y a pas de honte à préférer le bonheur !", author: "Albert Camus" },
        { sentence: "J'ai décidé d'être heureux parce que c'est bon pour la santé.", author: "Voltaire" },
        { sentence: "Le bonheur, c'est de continuer à désirer ce que l'on possède.", author: "Saint Augustin" },
        { sentence: "Le bonheur n'est pas toujours dans un ciel éternellement bleu, mais dans les choses les plus simples de la vie.", author: "Confucius" },
        { sentence: "N'attendez pas d'être heureux pour sourire. Souriez plutôt afin d'être heureux.", author: "Edward Louis Kramer" },
        { sentence: "On a deux vies. La deuxième commence quand on réalise qu'on en a qu'une.", author: "Confucius" },
        { sentence: "La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre.", author: "Albert Einstein" },
        { sentence: "Je préfère l'avenir au passé, car c'est là que j'ai décidé de vivre le restant de mes jours.", author: "Victor Hugo" },
        { sentence: "La meilleure façon d'apprécier quoi que ce soit est de réaliser que tout peut être perdu.", author: "Gilbert K.Chesterton" },
        { sentence: "La résilience, c'est l'art de naviguer dans les torrents.", author: "Boris Cyrulnik" },
        { sentence: "Le bonheur ne consiste pas à avoir le meilleur de tout, mais à savoir comment tout rendre meilleur.", author: "Augusto Branco" },
        { sentence: "Rêve ta vie en couleur, c'est le secret du bonheur.", author: "Walt Disney" },
        { sentence: "Le courage est comme un muscle. On le renforce en le travaillant.", author: "Ruth Gordo" },
        { sentence: "C'est un chemin semé d'embûches qui mène au sommet de la grandeur.", author: "Sénèque" },
        { sentence: "Éliminez les distractions sans relâche. N'attendez pas pour faire les choses qui importent, et savourez le temps que vous avez.C'est ce qu'il faut faire quand la vie est courte.", author: "Paul Graham" },
        { sentence: "L'obstination est le chemin de la réussite.", author: "Charlie Chaplin" },
        { sentence: "Fixez-vous des objectifs ambitieux et ne vous arrêtez pas avant de les atteindre.", author: "Bo Jackson" },
        { sentence: "Si quelqu'un est assis à l'ombre aujourd'hui, c'est parce que quelqu'un a planté un arbre il y a longtemps.", author: "Warren Buffet" },
        { sentence: "Le courage croît en osant et la peur en hésitant.”", author: "proverbe romain" },
        { sentence: "Les grands accomplissements sont réussis non par la force, mais par la persévérance.", author: "Samuel Johnson" },
        { sentence: "Ils ne savaient pas que c'était impossible, alors ils l'ont fait.", author: "Mark Twain" },
        { sentence: "L'impossible recule toujours quand on marche vers lui.", author: "Antoine de Saint-Exupéry" },
        { sentence: "Avoir un but trace la voie.", author: "Lao-Tseu" },
        { sentence: "Quand on veut on peut, quand on peut on doit.", author: "Napoléon Bonaparte" },
        { sentence: "Le talent est un jardin et le travail est son jardinier.", author: "Vincent Thomas Rey" },
        { sentence: "Choisissez un travail que vous aimez et vous n'aurez pas à travailler un seul jour de votre vie.", author: "Confucius" },
        { sentence: "Le travail paie dans le futur, la paresse elle paie comptant.", author: "Jacques Dutronc" },
        { sentence: "Notre persévérance fait de nous ce que nous rêvons d'être.", author: "Euvrard Tristan" },
        { sentence: "Confronté à la roche, le ruisseau l'emporte toujours, non pas par la force, mais par la persévérance.", author: "Confucius" },
        { sentence: "Il n'est pas de vent favorable pour celui qui ne sait où il va.", author: "Sénèque" },
        { sentence: "Un objectif est un rêve doté d'une échéance.", author: "Napoléon Hill" },
        { sentence: "Je crois beaucoup en la chance. D'ailleurs, je constate que plus je travaille et plus la chance me sourit.", author: "Thomas Jefferson" },
        { sentence: "L'art de la réussite consiste à savoir s'entourer des meilleurs.", author: "John Fitzgerald Kennedy" },
        { sentence: "L'échec est la mère du succès.", author: "proverbe chinois" },
        { sentence: "L'échec est le fondement de la réussite.", author: "Lao-Tseu" },
        { sentence: "Construisez votre succès à partir de vos échecs. Le découragement et l'échec sont les étapes les plus sûres pour parvenir au succès.", author: "Dale Carnegie" },
        { sentence: "Ne vous laissez pas intimider par l'échec. Il suffit d'avoir raison une seule fois.", author: "Drew Houston" },
        { sentence: "Ce que d'autres ont réussi, on peut toujours le réussir.", author: "Antoine de Saint-Exupéry" },
        { sentence: "Le succès, c'est vous aimer vous-même, c'est aimer ce que vous faites et c'est aimer comment vous le faites.", author: "Maya Angelou" },
        { sentence: "Il n'y a qu'une façon d'échouer, c'est d'abandonner avant d'avoir réussi.", author: "Georges Clemenceau" },
        { sentence: "Il y a plus de courage que de talent dans la plupart des réussites.", author: "Félix Leclerc" },
        { sentence: "Il faut viser la lune, parce qu'au moins, si vous échouez, vous finirez dans les étoiles.", author: "Oscar Wilde" },
        { sentence: "Les gagnants cherchent des moyens, les perdants des excuses.", author: "Franklin Roosevelt" },
        { sentence: "Je peux accepter l'échec, tout le monde échoue dans quelque chose. Mais je ne peux pas accepter de ne pas essayer.", author: "Michael Jordan" },
        { sentence: "Entre possible et impossible, deux lettres et un état d'esprit.", author: "Charles de Gaulle" },
        { sentence: "Celui qui n'a pas d'objectifs ne risque pas de les atteindre.", author: "Sun-tzu" },
        { sentence: "Le succès c'est tomber sept fois, se relever huit.", author: "Proverbe Japonais" },
        { sentence: "Je suis convaincu que ce qui sépare les entrepreneurs qui ont du succès de ceux qui n'en ont pas est la persévérance.", author: "Steve Jobs" },
        { sentence: "J'aime l'impossible. La concurrence y est moins rude.", author: "Walt Disney" },
        { sentence: "Notre plus grande faiblesse réside dans l'abandon; La façon la plus sûre de réussir est d'essayer une autre fois.", author: "Thomas Edison" },
        { sentence: "La réussite ne se mesure pas à l'argent que vous gagnez mais à votre capacité à changer les vie des gens.", author: "Michelle Obama" },
        { sentence: "Je n'accepte plus les choses que je ne peux pas changer.Je change les choses que je ne peux pas accepter.", author: "Angela Davis" },
        { sentence: "Soyez vous-même, tous les autres sont déjà pris.", author: "Oscar Wilde" },
        { sentence: "Même si vous êtes la pêche la plus mure et la plus juteuse au monde, il y aura toujours quelqu'un qui détestera les pêches.", author: "Dita Von Teese" },
        { sentence: "Si vous n'aimez pas la route sur laquelle vous marchez, commencez à paver une nouvelle route.", author: "Dolly Parton" },
        { sentence: "Je choisis de faire du reste de ma vie la meilleure partie de ma vie.", author: "Louise Hay" },
        { sentence: "Pour être irremplaçable, il faut toujours être différent.", author: "Coco Chanel" },
        { sentence: "Croyez en vos rêves et ils se réaliseront peut-être. Croyez en vous et ils se réaliseront sûrement.", author: "Martin Luther King" },
        { sentence: "Beaucoup de chemins mènent à la réussite, mais un seul mène immanquablement à l'échec, celui qui consiste à tenter de plaire à tout le monde", author: "Benjamin Franklin" },
        { sentence: "Soyez le changement que vous voulez voir dans le monde.", author: "Gandhi" },
        { sentence: "Crée la vision la plus grandiose de ta vie, parce que tu deviens ce que en quoi tu crois.", author: "Oprah Winfrey" },
        { sentence: "Etre libre, ce n'est pas pouvoir faire ce que l'on veut, mais c'est vouloir ce que l'on peut.", author: "Jean-Paul Sartre" },
    ]

    return SentencesArray[Math.floor(Math.random() * SentencesArray.length)]
}


export function ProgressColor(quantity: number, qMin: number, qMax: number, margin: number = 7) {
    const marginNumber: number = Math.round(margin * qMax / 100)

    if (qMin == qMax) {
        return ""
    } else if (quantity < qMin) {
        return "bg-danger"
    } else if (quantity <= (qMin + marginNumber)) {
        return "bg-warning"
    } else if (quantity < (qMax - marginNumber)) {
        return "bg-success"
    }
    return ""
}

export function TablePagination(table) {
    return <div className="row justify-content-between">

        <div className="col-lg-5 d-flex justify-content-center justify-content-lg-start align-items-center mb-3 mb-lg-0">
            <p className="mb-0 me-3">
                <span className="text-muted">Page {' '}</span>
                {table.getState().pagination.pageIndex + 1}
                <span className="text-muted">{' '} / {' '}</span>
                {table.getPageCount()}
            </p>

            <button className="btn btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                {'<<'}
            </button>
            <button className="btn btn-outline me-3" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                {'<'}
            </button>
            <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                {'>'}
            </button>
            <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                {'>>'}
            </button>
        </div>

        <div className="col-7 col-lg-3 d-flex justify-content-start align-items-center">
            <p className="text-muted mb-0 me-3 text-nowrap"> Aller à la page:</p>

            <input type="number" className="form-control w-50" defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    table.setPageIndex(page)
                }}
            />
        </div>

        <div className="col-5 col-sm-4 col-lg-3 d-flex justify-content-start">
            <select className="form-select" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                {[25, 50, 100, 200].map(pageSize => (

                    <option key={pageSize} value={pageSize}>
                        Montrer {pageSize}
                    </option>

                ))}

            </select>
        </div>

    </div>
}

export function BreakpointMobile() {
    if ($(window).width() <= MD)
        return true
    else
        return false
}

/**
 * 
 * @param param0 
 * @returns true if same
 */
export function CompareString(s1: string | null, s2: string | null) {
    const string1 = s1?.toUpperCase().replace(/[^\w ]/ig, '').replace(/ /g, '')
    const string2 = s2?.toUpperCase().replace(/[^\w ]/ig, '').replace(/ /g, '')

    if (string1 === string2) {
        return true
    } else {
        return false
    }
}



//Storage Ref
export function QRScanner({ onChange, reader = false }: { onChange, reader: boolean }) {
    const [toggleReader, setToggleReader] = useState<boolean>(reader);
    const [facingMode, setFacingMode] = useState<string>("environment");

    const handleResult = data => {
        if (data) {
            onChange(data)
            setToggleReader(false)
        }
    };

    const handleError = err => {
        //console.error(err);
    };

    const toggleFacingMode = () => {

        if (facingMode == "environment")
            setFacingMode("user")
        else
            setFacingMode("environment")

    }


    return <div className="card">

        <div className="card-header pb-0">
            <h6 className="card-title">
                <i className="bi bi-qr-code card-icon me-2"></i>
                QR Code
            </h6>

            {toggleReader ?
                <button className="btn btn-outline" onClick={() => setToggleReader(false)}>Arreter <i className="bi bi-chevron-up"></i></button>
                : <button className="btn btn-outline" onClick={() => setToggleReader(true)}>Scanner <i className="bi bi-chevron-down"></i></button>
            }
        </div>

        {toggleReader && <div className="card-body pt-3">
            <div className="qr-container">

                <QrReader
                    constraints={{ facingMode: facingMode }}
                    onResult={(result, error) => {
                        if (!!result) {
                            handleResult(result.getText());
                        }

                        if (!!error) {
                            handleError(error);
                        }
                    }}
                    className="qr-reader"
                />
            </div>
        </div>}

    </div>
}

export function ManualRef({ onChange }: { onChange }) {

    const [value, setValue] = useState<string | null>(null)

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, waitTime)

        return () => clearTimeout(timeout)
    }, [value])

    return <>
        <div className="card">
            <div className="card-header">
                <h6 className="card-title">Référence</h6>
            </div>
            <div className="card-body">
                <input type="text" className="form-control mb-2" onChange={(e) => setValue(e.target.value)} />
            </div>
        </div>
    </>
}

// DATE
export function DateToday(format: number = 1, separator: string = '-') {

    var now = new Date();

    var month: number | string = (now.getMonth() + 1);
    var day: number | string = now.getDate();

    if (month < 10)
        month = "0" + month;

    if (day < 10)
        day = "0" + day;

    if (format == 2) {
        return day + separator + month + separator + now.getFullYear()
    } else if (format == 3) {
        return month + separator + day + separator + now.getFullYear()
    } else {
        return now.getFullYear() + separator + month + separator + day;
    }
}

export function DateFrom(initialDate, addingDays) {
    const today = new Date(initialDate)

    let tomorrow = new Date(today)
    tomorrow.setDate(today.getDate() + (addingDays ?? 0))

    return tomorrow
}

/**
 * 
 * @param date1 
 * @param date2 
 * @returns 1 = date1 more recent, 2 = date2 more recent, 3 = equal
 */
export function DateCompare(date1: string, date2: string) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    const time1 = d1.getTime()
    const time2 = d2.getTime()

    if (time1 < time2) {
        return 2
    } else if (time1 > time2) {
        return 1
    } else
        return 3
}
//a simple date formatting function
export function dateFormat(inputDate, format) {
    if (!inputDate)
        return ""

    //parse the input date
    const date = new Date(inputDate);

    //extract the parts of the date
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()


    //replace the day
    if (format.indexOf("ddd") > -1)
        format = format.replace("ddd", date.toLocaleString('fr-CH', { weekday: "short" }));

    if (format.indexOf("dd") > -1)
        format = format.replace("dd", date.toLocaleString('fr-CH', { day: "numeric" }));


    //replace the month
    if (format.indexOf("MMM") > -1)
        format = format.replace("MMM", date.toLocaleString('fr-CH', { month: "short" }));

    if (format.indexOf("MM") > -1)
        format = format.replace("MM", date.toLocaleString('fr-CH', { month: "numeric" }).padStart(2, "0"));


    //replace the year
    if (format.indexOf("yyyy") > -1)
        format = format.replace("yyyy", year.toString());

    if (format.indexOf("yy") > -1)
        format = format.replace("yy", year.toString().substr(2, 2));

    // replace hour
    format = format.replace("hh", hours < 10 ? "0" + hours : hours)

    // replace minutes
    format = format.replace("mm", minutes < 10 ? "0" + minutes : minutes)

    // replace seconds
    format = format.replace("ss", seconds)

    format = format.replaceAll("NaN", "")

    return format;
}

export function DownloadWeekCsv(IDStart, WeekStart, YearStart, YearEnd) {

    interface Week {
        WeekID: number,
        WeekNumber: number,
        DateStart: String,
        DateEnd: String
    }

    const WeekArray: Week[] = []

    const [week, setWeek] = useState(WeekArray)

    useEffect(() => {

        setWeek(GenerateWeek(IDStart, WeekStart, YearStart, YearEnd))

    }, [])



    function download_csv(data) {
        //var csv = 'WeekNumber,DateStart,DateEnd\n';
        var csv = ''
        data.forEach(function (row) {

            for (const [key, value] of Object.entries(row)) {

                if (key != 'WeekID') {
                    csv += ","
                }
                csv += value
            }
            csv += "\n";
        });

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'weeks.csv';
        hiddenElement.click();
    }

    return <button className="btn btn-primary" onClick={() => download_csv(week)}>Generate Weeks csv</button>
}

export function GenerateWeek(IDStart, WeekStart, YearStart, YearEnd) {

    interface Week {
        WeekID: number,
        WeekNumber: number,
        DateStart: String,
        DateEnd: String
    }

    const array: Week[] = []

    var WeekNumber = WeekStart

    function getDateStart(weekNum, year) {
        var simple = new Date(year, 0, 1 + (weekNum - 1) * 7);
        var dow = simple.getDay();
        var ISOweekStart = simple;
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());


        let day = (ISOweekStart.getDate()) < 10 ? '0' + (ISOweekStart.getDate()) : (ISOweekStart.getDate())
        let month = (ISOweekStart.getMonth() + 1) < 10 ? '0' + (ISOweekStart.getMonth() + 1) : (ISOweekStart.getMonth() + 1)

        return ISOweekStart.getFullYear() + "-" + month + "-" + day;
    }

    function getDateEnd(weekNum, year) {
        var simple = new Date(year, 0, 1 + (weekNum - 1) * 7);
        var dow = simple.getDay();
        var ISOweekStart = simple;
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

        //+6 days to get sunday
        ISOweekStart.setDate(ISOweekStart.getDate() + 6)

        let day = ISOweekStart.getDate() < 10 ? '0' + ISOweekStart.getDate() : ISOweekStart.getDate()
        let month = (ISOweekStart.getMonth() + 1) < 10 ? '0' + (ISOweekStart.getMonth() + 1) : (ISOweekStart.getMonth() + 1)

        return ISOweekStart.getFullYear() + "-" + month + "-" + day;
    }



    let i = IDStart
    let year = YearStart

    while (year < YearEnd) {

        array.push({
            WeekID: i,
            WeekNumber: WeekNumber,
            DateStart: getDateStart(WeekNumber, year),
            DateEnd: getDateEnd(WeekNumber, year)
        })

        i++
        WeekNumber++

        if (WeekNumber > 52) {
            WeekNumber = 1
            year++
        }

    }

    return array

}


export function GenerateWorkingHours(date?: string | Date) {

    let today = date ? new Date(date) : new Date()

    return {
        MORNING: {
            END: new Date(today.setHours(7, 0, 0)),
            PERCENT: 1.25
        },
        DAY: {
            START: new Date(today.setHours(7, 0, 0)),
            END: new Date(today.setHours(18, 0, 0)),
            PERCENT: 1
        },
        AFTERNOON: {
            START: new Date(today.setHours(18, 0, 0)),
            END: new Date(today.setHours(20, 0, 0)),
            PERCENT: 1.25
        },
        NIGHT: {
            START: new Date(today.setHours(20, 0, 0)),
            END: new Date(today.setHours(23, 0, 0)),
            PERCENT: 1.50
        },
        SATURDAY: {
            PERCENT: 1.50
        },
        SUNDAY: {
            PERCENT: 1.50
        },
        HOLIDAY: {
            PERCENT: 2
        }
    }
}

/**
 * 
 * @param timeStart string format hh:mm | Date
 * @param timeEnd string format hh:mm | Date
 * @param breakStart string format hh:mm | Date
 * @param breakEnd string format hh:mm | Date
 * @returns string format hh:mm
 */
export function TimeCalculation(timeStart: string | Date, timeEnd?: string | Date | null, breakStart?: string | Date | null, breakEnd?: string | Date | null, type?: string, percent: boolean = false) {
    var hd = new Holidays('CH', 'GE')

    const MIN_CONV = 60000

    var min_morning = 0, min_day = 0, min_afternoon = 0, min_night = 0, min_sat = 0, min_sun = 0, min_holiday = 0

    let start
    if (timeStart)
        start = new Date(timeStart)
    else
        return "00:00"

    const end = timeEnd ? new Date(timeEnd) : null
    const bStart = breakStart ? new Date(breakStart) : null
    const bEnd = breakEnd ? new Date(breakEnd) : null

    let pointer_temp

    /*
    const isHoliday = false
 
    console.log("2024: ", hd.getHolidays(2024));
    if (hd.isHoliday(start))
        console.log("cholet");
*/



    const WORKING_HOURS = GenerateWorkingHours(start)

    // if saturday
    if (start.getDay() == 6) {
        if (end)
            min_sat += Math.round((end.getTime() - start.getTime()) / MIN_CONV * (percent ? WORKING_HOURS.SATURDAY.PERCENT : 1))
        else
            return "00:00"
    }
    // if sunday
    else if (start.getDay() == 0) {
        if (end)
            min_sun += Math.round((end.getTime() - start.getTime()) / MIN_CONV * (percent ? WORKING_HOURS.SUNDAY.PERCENT : 1))
        else
            return "00:00"
    }

    else {
        /*
        // if before working hours
        if (start.getHours() < WORKING_HOURS.DAY.START.getHours()) {
            min_morning += Math.round((WORKING_HOURS.DAY.START.getTime() - start.getTime()) / MIN_CONV * (percent ? WORKING_HOURS.MORNING.PERCENT : 1))
            pointer_temp = WORKING_HOURS.DAY.START
        } else {
            pointer_temp = start
        }
        */
        pointer_temp = start

        // if break
        if (bStart) {
            min_day += Math.round((bStart.getTime() - pointer_temp.getTime()) / MIN_CONV)
        }

        // if end break
        if (bEnd) {
            pointer_temp = bEnd
        }

        // if end
        if (end) {

            // If after end of the day
            if (end > WORKING_HOURS.DAY.END) {

                // If start after end of the day
                if (start < WORKING_HOURS.DAY.END) {
                    min_day += Math.round((WORKING_HOURS.DAY.END.getTime() - pointer_temp.getTime()) / MIN_CONV)
                    pointer_temp = WORKING_HOURS.DAY.END
                }

                //if after end of afternoon
                if (end > WORKING_HOURS.AFTERNOON.END) {

                    // If start after end of the afternoon
                    if (start < WORKING_HOURS.AFTERNOON.END) {
                        min_afternoon +=
                            Math.round(
                                (WORKING_HOURS.AFTERNOON.END.getTime() - pointer_temp.getTime()) / MIN_CONV *
                                (percent ? WORKING_HOURS.AFTERNOON.PERCENT : 1)
                            )

                        pointer_temp = WORKING_HOURS.AFTERNOON.END
                    }

                    min_night += Math.round((
                        end.getTime() - pointer_temp.getTime()) / MIN_CONV *
                        (percent ? WORKING_HOURS.NIGHT.PERCENT : 1)
                    )
                } else
                    min_afternoon += Math.round((end.getTime() - pointer_temp.getTime()) / MIN_CONV * (percent ? WORKING_HOURS.AFTERNOON.PERCENT : 1))
            } else {
                min_day += Math.round((end.getTime() - pointer_temp.getTime()) / MIN_CONV)
            }
        }
    }

    let duration = min_morning + min_day + min_afternoon + min_night + min_sat + min_sun + min_holiday

    if (type == "Decimal") {
        return (Math.round(duration / 60 * 4) / 4).toFixed(2)
    }

    if (!duration || duration <= 0)
        return "00:00"
    else
        return Math.floor(duration / 60) + ":" + (duration % 60 > 9 ? duration % 60 : "0" + duration % 60)
}

export function TimeRound(time: Date, arrivalHour?: string | null, departureHour?: string | null) {

    if (arrivalHour && (time.getHours() * 60 + time.getMinutes()) <= (parseInt(arrivalHour.split(":")[0]) * 60 + parseInt(arrivalHour.split(":")[1]) + TIMESHEET.LATE)) {
        var date = new Date()
        date.setHours(parseInt(arrivalHour.split(":")[0]))
        date.setMinutes(parseInt(arrivalHour.split(":")[1]))
        date.setSeconds(0)
        return date
    }


    /*else if (departureHour && (time.getHours() * 60 + time.getMinutes()) >= (parseInt(departureHour.split(":")[0]) * 60 + parseInt(departureHour.split(":")[1]) + TIMESHEET.EXTRA_HOURS)) {
        //console.log("extra hours");
        return new Date(time)
    } */

    else {
        console.log("nearest 15 minutes");
        const ms = 1000 * 60 * TIMESHEET.ROUND

        return new Date(Math.round(time.getTime() / ms) * ms)
    }
}

export function MonthWeeks(weeks: Array<IWeek>, month: number) {
    const monthWeeks = weeks.filter(w => parseInt(w.DateStart.split('-')[1]) == month || parseInt(w.DateEnd.split('-')[1]) == month)

    // Check first week of month
    var week = monthWeeks[0]
    // remove first week if between two month and days majority is for the previous one
    if (parseInt(week.DateStart.split('-')[1]) != parseInt(week.DateEnd.split('-')[1]) && parseInt(week.DateEnd.split('-')[2]) < 5) {
        // remove first
        monthWeeks.shift()
    }


    // Check last week of month
    week = monthWeeks[monthWeeks.length - 1]
    // remove last week if between two month and days majority is for the next one
    if (parseInt(week.DateStart.split('-')[1]) != parseInt(week.DateEnd.split('-')[1]) && parseInt(week.DateEnd.split('-')[2]) >= 5) {
        // remove last
        monthWeeks.pop()
    }

    return monthWeeks
}




// InputSearch
export function ManagementInputSearch({ initManagementID, onChange, className }: { initManagementID?: number | null, onChange, className?: string }) {
    const [managements, setManagements] = useState<Array<IManagement>>([])
    const [managementsFiltered, setManagementsFiltered] = useState<Array<IManagement>>([])
    const [managementSelected, setManagementSelected] = useState<IManagement | null>(null)

    useEffect(() => {
        reqGet("management").then(res => {
            setManagements(res)
        })
    }, [])

    useEffect(() => {
        if (initManagementID)
            HandleManagementSelection(managements.find(m => m.ManagementID === initManagementID))
    }, [initManagementID])

    function HandleManagementSearch(value: string) {
        if (value === "") {
            setManagementSelected(null)
            setManagementsFiltered([])
        } else {
            value = ReplaceSpecialChars(value).trim().replace("/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/", "")

            var result = managements.filter((m) => {
                var management = ReplaceSpecialChars(m.ManagementName)
                    .trim()
                    .replace("/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/", "")

                if (management.includes(value))
                    return m
            })

            setManagementsFiltered(result);
        }
    }

    function HandleManagementSelection(management) {
        onChange(management)
        if (management) {
            setManagementSelected(management)
        } else {
            $("#managementInputSearch").val("")
            setManagementsFiltered([])
            setManagementSelected(null)
        }
    }

    return <>
        <label className="form-label mb-1">Régie</label>

        <div className="dropdown">
            <button className={"form-select text-start text-truncate " + className} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {managementSelected ?
                    managementSelected.ManagementName :
                    "Aucune"}
            </button>

            <div className="dropdown-menu">
                <input id="managementInputSearch" type="text" placeholder="Recherche..." className="form-control mb-3" onChange={(e) => HandleManagementSearch(e.target.value)} />

                <ul className="list-group list-group-hover list-group-flush d-block overflow-auto" style={{ maxHeight: "15rem" }}>

                    <li className="list-group-item d-block text-truncate border-0 p-2" onClick={() => HandleManagementSelection(null)} style={{ cursor: "pointer" }}>Aucune</li>

                    {managementsFiltered?.map(m => {
                        return <li key={m.ManagementID} className="list-group-item d-block text-truncate border-0 p-2" onClick={() => HandleManagementSelection(m)} style={{ cursor: "pointer" }}>
                            {m.ManagementName}
                        </li>
                    })}

                </ul>

            </div>
        </div>
    </>
}

export function OwnerInputSearch({ initOwnerID, onChange, className }: { initOwnerID?: number | null, onChange, className?: string }) {
    const [owners, setOwners] = useState<Array<IOwner>>([])
    const [ownersFiltered, setOwnersFiltered] = useState<Array<IOwner>>([])
    const [ownerSelected, setOwnerSelected] = useState<IOwner | null>(null)

    useEffect(() => {
        reqGet("owner").then(res => {
            setOwners(res)
        })
    }, [])

    useEffect(() => {
        if (initOwnerID)
            HandleOwnerSelection(owners.find(o => o.OwnerID === initOwnerID))
    }, [initOwnerID])

    function HandleOwnerSearch(value: string) {
        if (value === "") {
            setOwnerSelected(null)
            setOwnersFiltered([])
        } else {
            value = ReplaceSpecialChars(value).trim().replace("/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/", "")

            var result = owners.filter((o) => {
                var owner = ReplaceSpecialChars(o.OwnerName)
                    .trim()
                    .replace("/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/", "")

                if (owner.includes(value))
                    return o
            })

            setOwnersFiltered(result);
        }
    }

    function HandleOwnerSelection(owner) {
        onChange(owner)
        if (owner) {
            setOwnerSelected(owner)
        } else {
            $("#ownerInputSearch").val("")
            setOwnersFiltered([])
            setOwnerSelected(null)
        }
    }

    return <>
        <label className="form-label mb-1">Propriétaire</label>

        <div className="dropdown">
            <button className={"form-select text-start text-truncate " + className} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {ownerSelected ?
                    ownerSelected.OwnerName :
                    "Aucun"}
            </button>

            <div className="dropdown-menu">
                <input id="ownerInputSearch" type="text" placeholder="Recherche..." className="form-control mb-3" onChange={(e) => HandleOwnerSearch(e.target.value)} />

                <ul className="list-group list-group-hover list-group-flush d-block overflow-auto" style={{ maxHeight: "15rem" }}>

                    {ownersFiltered.length <= 0 && <li className="list-group-item d-block text-truncate border-0 p-2" onClick={() => HandleOwnerSelection(null)} style={{ cursor: "pointer" }}>Aucun</li>}

                    {ownersFiltered?.map(o => {
                        return <li key={o.OwnerID} className="list-group-item d-block text-truncate border-0 p-2" onClick={() => HandleOwnerSelection(o)} style={{ cursor: "pointer" }}>
                            {o.OwnerName}
                        </li>
                    })}

                </ul>

            </div>
        </div>
    </>
}

export function TenantInputSearch({ initTenantID, onChange }: { initTenantID?: number | null, onChange }) {
    const [tenants, setTenants] = useState<Array<ITenant>>([])
    const [tenantsFiltered, setTenantsFiltered] = useState<Array<ITenant>>([])
    const [tenantSelected, setTenantSelected] = useState<ITenant | null>(null)

    useEffect(() => {
        reqGet("tenant").then(res => {
            setTenants(res)

        })
    }, [])

    useEffect(() => {
        if (initTenantID)
            HandleTenantSelection(tenants.find(t => t.TenantID === initTenantID))
    }, [initTenantID])


    function HandleTenantSearch(value: string) {
        if (value === "") {
            setTenantSelected(null)
            setTenantsFiltered([])
        } else {
            value = ReplaceSpecialChars(value).trim().replace("/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/", "")

            var result = tenants.filter((t) => {
                var tenant = ReplaceSpecialChars(t.TenantName)
                    .trim()
                    .replace("/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/", "")

                if (tenant.includes(value))
                    return t
            })

            setTenantsFiltered(result);
        }
    }

    function HandleTenantSelection(tenant) {
        onChange(tenant)
        if (tenant) {
            setTenantSelected(tenant)
        } else {
            $("#tenantInputSearch").val("")
            setTenantsFiltered([])
            setTenantSelected(null)
        }
    }

    return <> <label className="form-label">Locataire</label>

        <div className="dropdown">
            <button className="form-select text-start text-truncate" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {tenantSelected ?
                    tenantSelected.TenantName :
                    "Choisir un locataire"}
            </button>

            <div className="dropdown-menu w-100 p-3">
                <input id="tenantInputSearch" type="text" placeholder="Recherche..." className="form-control mb-3" onChange={(e) => HandleTenantSearch(e.target.value)} />

                <ul className="list-group list-group-hover list-group-flush d-block overflow-auto" style={{ maxHeight: "15rem" }}>

                    {tenantsFiltered.length <= 0 && <li className="list-group-item d-block text-truncate border-0 p-2" onClick={() => HandleTenantSelection(null)} style={{ cursor: "pointer" }}>Aucun</li>}

                    {tenantsFiltered?.map(t => {
                        return <li key={t.TenantName} className="list-group-item d-block text-truncate border-0 p-2" onClick={() => HandleTenantSelection(t)} style={{ cursor: "pointer" }}>
                            {t.TenantName}
                        </li>
                    })}

                </ul>

            </div>
        </div>
    </>
}

export function SelectInputSearch({ data, onChange, id, values, label, initID, ...props }: { data, onChange, id, values: Array<string>, label, initID?} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [dataFiltered, setDataFiltered] = useState([])
    const [elementSelected, setElementSelected] = useState(null)

    useEffect(() => {
        if (initID)
            HandleSelection(data.find(t => t[id] === initID))
    }, [initID])


    function HandleSearch(val: string) {
        if (val === "") {
            setElementSelected(null)
            setDataFiltered([])
        } else {
            val = ReplaceSpecialChars(val.toString()).trim().replace("/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/", "")

            var result = data.filter((d) => {
                var element = ""
                values.forEach(v => {
                    if (d[v])
                        element += ReplaceSpecialChars(d[v].toString())
                            .trim()
                            .replace("/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/", "")
                })
                if (element.includes(val))
                    return d
            })


            setDataFiltered(result);
        }
    }

    function HandleSelection(val) {
        onChange(val)
        if (val) {
            setElementSelected(val)
        } else {
            $("#inputSearch").val("")
            setDataFiltered([])
            setElementSelected(null)
        }
    }

    return <> <label className="form-label">{label}</label>

        <div className="dropdown">
            <button className={"form-select text-start text-truncate " + props?.className} type="button" data-bs-toggle="dropdown" aria-expanded="false" disabled={props?.disabled}>
                {elementSelected ?
                    values.map(v => elementSelected[v] + " ") :
                    "Rechercher ..."}
            </button>

            <div className="dropdown-menu w-100 p-3">
                <input id="inputSearch" type="text" placeholder="Recherche..." className="form-control mb-3" onChange={(e) => HandleSearch(e.target.value)} />

                <ul className="list-group list-group-hover list-group-flush d-block overflow-auto" style={{ maxHeight: "15rem" }}>

                    {dataFiltered.length <= 0 && <li className="list-group-item d-block text-truncate border-0 p-2" onClick={() => HandleSelection(null)} style={{ cursor: "pointer" }}>Aucun</li>}

                    {dataFiltered?.map(t => {
                        return <li key={t[id]} className="list-group-item d-block text-truncate border-0 p-2" onClick={() => HandleSelection(t)} style={{ cursor: "pointer" }}>
                            {values.map(v => t[v] + " ")}
                        </li>
                    })}

                </ul>

            </div>
        </div>
    </>
}

