import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { reqGet } from '../../../service/apiRequest'
import $ from 'jquery'

import { HeaderRow, PrivateComponent, PrivateLinkAdd } from "../../common/common"
import { LAYOUT, GROUP, PERMISSION } from "../../common/constants"
import { IMaterial, IAddress } from "../../common/types"

export default function MaterialAddress() {
    const params = useParams()
    const idMaterial = params.idMaterial

    const [material, setMaterial] = useState<IMaterial>()
    const [address, setAddress] = useState<Array<IAddress>>([])

    useEffect(() => {
        reqGet('material/' + idMaterial).then(res => { setMaterial(res) })

        reqGet('address/material/' + idMaterial).then(res => { setAddress(res) })
    }, [])


    return (
        <section className="body-container content">

            {HeaderRow(
                <h3 className="section-title">Adresses du matériel</h3>,
                <Link to="/material">{"<"} Retour</Link>
            )}

            <section className="body container-fluid">
                <div className="row justify-content-center h-100">

                    <div className="col-md-3">
                        <div className="card card-fill">
                            <div className="card-header">
                                <h5 className="card-title">Matériel</h5>
                            </div>

                            <div className="card-body">
                                <p className="text-light">Type</p>
                                <p>{material?.Type}</p>

                                <p className="text-light">Marque</p>
                                <p>{material?.Brand}</p>

                                <p className="text-light">Modèle</p>
                                <p>{material?.Model}</p>

                                <p className="text-light">Référence</p>
                                <p>{material?.Reference}</p>

                                <p className="text-light">Capacité</p>
                                <p>{material?.Capacity}</p>

                                <p className="text-light">Plage de puissance</p>
                                <p>{material?.PowerRange}</p>

                                <p className="text-light">ICF</p>
                                <p>{material?.ICF}</p>

                                <p className="text-light">Surface</p>
                                <p>{material?.Surface}</p>

                                <p className="text-light">Longueur</p>
                                <p>{material?.Longueur}</p>

                                <p className="text-light">Alimentation</p>
                                <p>{material?.Alimentation}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-h-fill">

                        <div className="list">
                            {address && address.map(a => {
                                return <Link key={a.AddressID} to={"/address/" + a.AddressID}>
                                    <AddressItem address={a} />
                                </Link>
                            })}
                        </div>

                    </div>

                </div>
            </section>
        </section>
    )
}

function AddressItem({ address }) {
    return <div className="item">
        <div className="item-data">
            <h5 className="item-title">{address?.AddressLine}, {address?.ZipCode} {address?.City}</h5>
        </div>

        <div className="item-icon">
            <i className="bi bi-chevron-right"></i>
        </div>
    </div>
}