import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import $ from 'jquery'

import { Header, ButtonSubmit, TimeRound, dateFormat } from '../../../common/common'
import { ITimesheet, IUser } from "../../../common/types";
import { reqGet, reqPut } from "../../../../service/apiRequest";
import { getToken } from "../../../../useToken";
import Timesheet from './../../timesheet/timesheet';
import { TIMESHEET } from "../../../common/constants";
import { CheckValidTimesheetHour } from "./account-timesheet-add";

export default function AccountTimesheetUpdate({ setReload }) {
    const params = useParams()
    const idTimesheet = params.idTimesheet
    const navigate = useNavigate()
    const token = getToken()
    const location = useLocation()

    const [timesheet, setTimesheet] = useState<ITimesheet>()
    const [user, setUser] = useState<IUser>()

    useEffect(() => {
        reqGet("user/" + token.UserID).then(res => setUser(res))
        reqGet("timesheet/" + idTimesheet).then(res => {
            // if timesheet is validate can't modify
            if (res.TimesheetValidate == 1)
                navigate(-1)

            setTimesheet(res)
            $("#TimesheetStart").val(res.TimesheetStart.substring(11, 16))
            $("#TimesheetLunchStart").val(res.TimesheetLunchStart.substring(11, 16))
            $("#TimesheetLunchEnd").val(res.TimesheetLunchEnd.substring(11, 16))
            $("#TimesheetEnd").val(res.TimesheetEnd.substring(11, 16))
        })
    }, [idTimesheet])

    function LunchBreak() {
        const ms = 1000 * 60 * TIMESHEET.ROUND

        var lunchStart, lunchEnd

        if ($("#TimesheetLunchStart").val()) {
            lunchStart = new Date()
            lunchStart.setHours($("#TimesheetLunchStart").val().split(":")[0])
            lunchStart.setMinutes($("#TimesheetLunchStart").val().split(":")[1])
            lunchStart = new Date(Math.round(lunchStart.getTime() / ms) * ms)
            $("#TimesheetLunchStart").val(lunchStart.toString().substring(16, 21))
        }

        if ($("#TimesheetLunchEnd").val()) {
            lunchEnd = new Date()
            lunchEnd.setHours($("#TimesheetLunchEnd").val().split(":")[0])
            lunchEnd.setMinutes($("#TimesheetLunchEnd").val().split(":")[1])
            lunchEnd = new Date(Math.round(lunchEnd.getTime() / ms) * ms)

            if (lunchStart && lunchEnd) {
                var diff = Math.floor((Math.abs(lunchEnd - lunchStart) / 1000) / 60);

                if (diff < TIMESHEET.MIDDAY_MIN) {
                    var minToAdd = TIMESHEET.MIDDAY_MIN - diff

                    lunchEnd = new Date(lunchEnd.getTime() + (minToAdd * 60000))
                }
            }
            $("#TimesheetLunchEnd").val(lunchEnd.toString().substring(16, 21))
        }
    }

    const onSubmit = () => {
        var data = {
            ActivityUserID: token.UserID,
            TimesheetID: idTimesheet,
            TimesheetStart: $("#TimesheetStart").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetStart").val() + ":00" : null,
            TimesheetLunchStart: $("#TimesheetLunchStart").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetLunchStart").val() + ":00" : null,
            TimesheetLunchEnd: $("#TimesheetLunchEnd").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetLunchEnd").val() + ":00" : null,
            TimesheetEnd: $("#TimesheetEnd").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetEnd").val() + ":00" : null
        }

        reqPut('timesheet/' + idTimesheet, data).then((res) => {
            setReload(true)
            navigate(-1)
        })
    };

    const LinkBack = () => {

        if (location?.state?.path)
            return <Link to={location.state.path} className="link-nav">{"<"} Retour</Link>
        else
            return <Link to="/account/timesheet" className="link-nav">{"<"} Retour</Link>
    }

    return <div className="body-container content">

        <header className="header">
            <div>
                <h2 className="section-title">Modifier la feuille d'heure</h2>
                {LinkBack()}
            </div>
        </header>

        <section className="body">

            <div className="mb-3">
                <label className="form-label">Date</label>
                <h4 className="item-title">{dateFormat(timesheet?.TimesheetStart, "dd/MM/yyyy")}</h4>
            </div>

            <div className="row row-cols-2">
                <div className="col mb-3">
                    <label className="form-label">Début de journée</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetStart" className="form-control" onBlur={(e) => CheckValidTimesheetHour(user, dateFormat(timesheet?.TimesheetStart, "yyyy-MM-dd"), e)} />
                    </div>
                </div>

                <div className="col mb-3">
                    <label className="form-label">Pause de midi</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetLunchStart" className="form-control" onBlur={(e) => LunchBreak()} />
                    </div>
                </div>

                <div className="col mb-3">
                    <label className="form-label">Fin pause de midi</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetLunchEnd" className="form-control" onBlur={(e) => LunchBreak()} />
                    </div>
                </div>

                <div className="col mb-3">
                    <label className="form-label">Fin de journée</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetEnd" className="form-control" onBlur={(e) => CheckValidTimesheetHour(user, dateFormat(timesheet?.TimesheetStart, "yyyy-MM-dd"), e)} />
                    </div>
                </div>
            </div>


            <div className="text-end">
                <p><small>Les champs avec une * sont obligatoire</small></p>
                <ButtonSubmit onClick={onSubmit} />
            </div>
        </section>
    </div>
}