import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useOutletContext } from "react-router-dom";
import $ from 'jquery'

import { ICONS, LAYOUT, TIMESHEET } from './../../../common/constants';
import { CardBtnLink, Header, PrivateLinkAdd, TimeCalculation, dateFormat } from "../../../common/common";
import { ITimesheet, IUser, IWeek } from "../../../common/types";
import { reqDelete, reqGet, reqPost, reqPut } from "../../../../service/apiRequest";
import { TimesheetCard } from "../account-timesheet/account-timesheet";

export default function AccountPicket({ reload, setReload }) {
    const location = useLocation()

    const [display, setDisplay] = useState<string>("")
    const [display2, setDisplay2] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.xxl) {
            setDisplay('d-flex')
            setDisplay2('d-flex')

        } else {
            if (location.pathname == "/account/picket") {
                setDisplay('d-flex')
                setDisplay2('d-none')

            } else {
                setDisplay('d-none')
                setDisplay2('d-flex')
            }
        }

    }

    const user = useOutletContext<IUser>()
    const [timesheetToday, setTimesheetToday] = useState<Array<ITimesheet>>([])

    useEffect(() => {
        if (user) {
            reqGet("timesheet/type/picket/user/" + user.UserID + "/today").then(res => {
                setTimesheetToday(res)
            })

            setReload(false)
        }
    }, [user])

    useEffect(() => {
        if (user && reload) {
            reqGet("timesheet/type/picket/user/" + user.UserID + "/today").then(res => {
                setTimesheetToday(res)
            })

            setReload(false)
        }
    }, [reload])

    if (user)
        return <div className="container-fluid h-100">


            <div className="row h-100">
                <div className={display + " col-xxl-6 col-h-fill px-0"}>
                    <div className="body-container content">

                        <header className="header">
                            <div>
                                <h2 className="section-title">Piquet</h2>
                                <Link to="/account" className="link-nav">{"<"} Retour</Link>
                            </div>

                            {PrivateLinkAdd("add")}
                        </header>

                        <div>
                            {(timesheetToday.length == 0 || timesheetToday[0].TimesheetEnd) &&
                                <PicketStart user={user} setReload={setReload} />
                            }

                            {(timesheetToday.length > 0 && !timesheetToday[0].TimesheetEnd) &&
                                <PicketEnd user={user} timesheet={timesheetToday[0]} setReload={setReload} />
                            }
                        </div>


                        <section className="body">

                            {timesheetToday.length > 0 &&
                                <>
                                    <h3 className="secton-title-2">Ajourd'hui</h3>

                                    {(timesheetToday && timesheetToday.length > 0) && timesheetToday.map(t => <TimesheetCard timesheet={t} setReload={setReload} km={false} />)}
                                </>
                            }

                            <TimesheetsWeek user={user} reload={reload} setReload={setReload} />

                        </section>
                    </div>

                </div>

                <div className={display2 + " col-xxl-6 col-h-fill"}>
                    <Outlet />
                </div>
            </div>
        </div>

    else return <></>
}


function PicketStart({ user, setReload }: { user: IUser, setReload }) {

    function StartDay() {
        reqPost("timesheet/type/picket/start", { ActivityUserID: user.UserID, FirstName: user.FirstName, Name: user.Name }).then(res => {
            setReload(true)
        })
    }

    return <button className="card card-btn w-100 btn-picket" onClick={() => StartDay()}>
        <header className="card-header">
            <div className="header-content">
                <i className={"card-icon bi bi-play"}></i>
            </div>
        </header>

        <section className="card-body">
            <h5 className="card-title">Départ piquet</h5>
        </section>
    </button>
}

function PicketEnd({ user, timesheet, setReload }: { user: IUser, timesheet: ITimesheet, setReload }) {

    function EndDay() {

        reqPut("timesheet/" + timesheet.TimesheetID + "/end", { ActivityUserID: user.UserID, TimesheetNote: "", FirstName: user.FirstName, Name: user.Name }).then(res => {
            setReload(true)
        })
    }

    return <button className="card card-btn w-100 btn-picket-end" onClick={() => EndDay()}>
        <header className="card-header">
            <div className="header-content">
                <i className={"card-icon bi bi-stop-fill"}></i>
            </div>
        </header>

        <section className="card-body">
            <h5 className="card-title">Retour piquet</h5>
        </section>
    </button>
}

function TimesheetsWeek({ user, reload, setReload }: { user: IUser, reload: boolean, setReload }) {
    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [timesheets, setTimesheets] = useState<Array<ITimesheet>>([])
    const [weeks, setWeeks] = useState<Array<IWeek>>([])
    const [week, setWeek] = useState<IWeek>()

    useEffect(() => {
        reqGet("week/year/" + year).then(res => {
            setWeeks(res)

            var currentDate = new Date()
            var oneJan = new Date(currentDate.getFullYear(), 0, 1);
            let weekNb = Math.ceil((((currentDate.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7);

            const w = res.find(w => w.WeekNumber >= weekNb)
            setWeek(w)
        })
    }, [])

    useEffect(() => {
        if (week) {
            reqGet("timesheet/type/picket/user/" + user.UserID + "/week/" + week.WeekID).then(res => {
                setTimesheets(res);
            })
        }
    }, [week])

    useEffect(() => {
        if (week && reload) {
            reqGet("timesheet/type/picket/user/" + user.UserID + "/week/" + week.WeekID).then(res => {
                setTimesheets(res);
            })
            setReload(false)
        }
    }, [reload])

    var [init, setInit] = useState<boolean>(true)
    useEffect(() => {
        if (year && !init)
            reqGet("week/year/" + year).then(res => {
                setWeeks(res)
                if (week?.WeekNumber == 1)
                    setWeek(res[res.length - 1])
                else
                    setWeek(res[0])
            })
        else setInit(false)
    }, [year])

    function HandleWeekChange(idWeek) {
        setWeek(weeks.find(w => w.WeekID == idWeek))
    }

    function HandleNextWeek() {
        if (week?.WeekNumber == 52) {
            setYear(year + 1)
        } else {
            var w = weeks.find(w => w.WeekNumber == (week?.WeekNumber ?? 0) + 1)
            setWeek(w)
            $("#selectWeek").val(w?.WeekID)
        }

    }

    function HandlePreviousWeek() {
        if (week?.WeekNumber == 1) {
            setYear(year - 1)
        } else {
            var w = weeks.find(w => w.WeekNumber == (week?.WeekNumber ?? 0) - 1)
            setWeek(w)
            $("#selectWeek").val(w?.WeekID)
        }
    }


    function RemovePicket(timesheet: ITimesheet) {
        if (timesheet.TimesheetValidate != 1)
            reqDelete("timesheet/" + timesheet.TimesheetID).then(() => {
                setReload(true)
            })
    }

    return <>
        <h3 className="section-title-2">
            Semaine {week?.WeekNumber}
            <span className="section-subtitle ms-3">{dateFormat(week?.DateStart, "dd")} - {dateFormat(week?.DateEnd, "dd")}{dateFormat(week?.DateStart, "/MM/yyyy")}</span>
        </h3>

        <nav className="text-center mb-3">

            <button
                className="btn btn-outline d-inline rounded"
                onClick={() => HandlePreviousWeek()}
            >
                <i className="bi bi-caret-left"></i>
            </button>

            <select className="form-select mx-3 d-inline w-auto" onChange={(e) => HandleWeekChange(e.target.value)} id="selectWeek" value={week?.WeekID}>
                {weeks.map((w) => {
                    return <option key={w.WeekID} value={w.WeekID}>Semaine {w.WeekNumber}: {dateFormat(w.DateStart, "dd/MM/yyyy")}</option>
                })}
            </select>

            <input type="number" className="form-control me-3 d-inline" style={{ width: "65px" }} value={year} onChange={(e) => setYear(parseInt(e.target.value))} />

            <button
                className="btn btn-outline d-inline rounded" id="btnNext"
                onClick={() => HandleNextWeek()}
            >
                <i className="bi bi-caret-right"></i>
            </button>

        </nav>

        {(timesheets && timesheets.length > 0) && timesheets.map(t => <TimesheetCard timesheet={t} setReload={setReload} />)}
    </>
}