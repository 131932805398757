import React, {useEffect} from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useNavigate } from "react-router-dom";
// Import our custom CSS
import './style/main.scss'

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'


import AppRouter from "./router";


const mainContainer = ReactDOM.createRoot(
  document.getElementById("mainContainer") as HTMLDivElement
);

mainContainer.render(
  <BrowserRouter>
    <AppRouter />
  </BrowserRouter>
);


function App() {

  /*
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {

      navigate("/login", { replace: true });
    }
  }, []);
*/
  return AppRouter()

}