import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


import { Header, ButtonSubmit, ReplaceSpecialChars, ManagementInputSearch, OwnerInputSearch, TenantInputSearch } from '../../../common/common'
import { reqGet, reqPost } from "../../../../service/apiRequest";
import { IAddress, IConstruction, ITask, ITaskType } from "../../../common/types";
import { getToken } from "../../../../useToken";

const validationSchema = yup.object({
    TaskStart: yup.string().required("Ce champ est obligatoire"),
    TaskEnd: yup.string().required("Ce champ est obligatoire"),
    TaskNote: yup.string().nullable(),
    TaskLocation: yup.string().nullable(),
    TaskAddressID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    TaskConstructionID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()
}).required();

const initialValues = {
    TaskStart: "",
    TaskEnd: "",
    TaskNote: null,
    TaskLocation: "",
    TaskAddressID: null,
    TaskConstructionID: null
}

export default function AccountTaskAdd() {
    const token = getToken()
    const navigate = useNavigate()

    const [address, setAddress] = useState<Array<IAddress>>([])
    const [constructions, setConstructions] = useState<Array<IConstruction>>([])
    const [taskTypes, setTaskTypes] = useState<Array<ITaskType>>([])

    useEffect(() => {
        reqGet("address").then((res) => setAddress(res))
        reqGet("construction").then((res) => setConstructions(res))
        reqGet("tasktype").then(res => {
            setTaskTypes(res)
            setValue("TaskTypeID", res[0].TaskTypeID)
        })
    }, [])

    const { register, formState: { errors }, handleSubmit, watch, setValue } = useForm<ITask>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });


    useEffect(() => {

        if (watch("TaskLocation")) {
            $("#inputLocation").prop("disabled", false)
            $("#inputAddress").prop("disabled", true)
            $("#inputConstruction").prop("disabled", true)
        } else if (watch("TaskAddressID")) {
            $("#inputLocation").prop("disabled", true)
            $("#inputAddress").prop("disabled", false)
            $("#inputConstruction").prop("disabled", true)
        } else if (watch("TaskConstructionID")) {
            $("#inputLocation").prop("disabled", true)
            $("#inputAddress").prop("disabled", true)
            $("#inputConstruction").prop("disabled", false)
        } else {
            $("#inputLocation").prop("disabled", false)
            $("#inputAddress").prop("disabled", false)
            $("#inputConstruction").prop("disabled", false)
        }

    }, [watch("TaskLocation"), watch("TaskAddressID"), watch("TaskConstructionID")])


    const onSubmit: SubmitHandler<ITask> = data => {

        console.log(data);

        /*
        reqPost('task', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate('/account/task')
        })
        */
    };


    return <div className="body-container content">

        {Header(
            <h2 className="section-title">Entrez une tâche</h2>,
            <Link to="/account/task">{"<"} Retour</Link>
        )}

        <section className="body">

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label className="form-label">Catégorie *</label>
                    <select id="" className={"form-select " + (errors?.TaskTypeID ? "is-invalid" : "")} {...register("TaskTypeID")}>
                        {taskTypes.length > 0 && taskTypes.filter(tt => tt.TaskType == "Chantier").map(tt => {
                            return <option key={tt.TaskTypeID} value={tt.TaskTypeID}>{tt.TaskType}</option>
                        })}
                    </select>

                    {errors.TaskTypeID?.message && <p className="input-msg-error">{errors.TaskTypeID?.message}</p>}
                </div>

                <div className="mb-3">
                    <label className="form-label">Lieux *</label>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="inputLocation" {...register("TaskLocation")} />
                        <label htmlFor="inputLocation">Entrer manuellement</label>
                    </div>

                    <div className="form-floating mb-3">
                        <select className="form-select" id="inputAddress" {...register("TaskAddressID")}>
                            <option value=""></option>
                            {address.length > 0 && address.map(a => {
                                return <option key={a.AddressID} value={a.AddressID}>{a.AddressLine}, {a.ZipCode} {a.City}</option>
                            })}
                        </select>
                        <label htmlFor="inputAddress">Choisir adresse</label>
                    </div>

                    <div className="form-floating">
                        <select className="form-select" id="inputConstruction" {...register("TaskConstructionID")}>
                            <option value=""></option>
                            {constructions.length > 0 && constructions.map(c => {
                                return <option key={c.ConstructionID} value={c.ConstructionID}>{c.ConstructionAddress}</option>
                            })}
                        </select>
                        <label htmlFor="inputConstruction">Choisir chantier</label>
                    </div>
                </div>

                <hr />


                <div className="row row-cols-2 mb-3">

                    <div className="col">
                        <label htmlFor="inputStart" className="form-label">Commence à *</label>
                        <input type="datetime-local" className={"form-control " + (errors?.TaskStart ? "is-invalid" : "")} id="inputStart" {...register("TaskStart")} />

                        {errors.TaskStart?.message && <p className="input-msg-error">{errors.TaskStart?.message}</p>}
                    </div>

                    <div className="col">
                        <label htmlFor="inputEnd" className="form-label">Termine à *</label>
                        <input type="datetime-local" className={"form-control " + (errors?.TaskEnd ? "is-invalid" : "")} id="inputEnd" {...register("TaskEnd")} />

                        {errors.TaskEnd?.message && <p className="input-msg-error">{errors.TaskEnd?.message}</p>}
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="inputNote" className="form-label">Remarque</label>
                    <textarea id="inputNote" rows={4} {...register("TaskNote")}></textarea>

                    {errors.TaskNote?.message && <p className="input-msg-error">{errors.TaskNote?.message}</p>}
                </div>

                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <ButtonSubmit />
                </div>

            </form>

        </section>

    </div>
}