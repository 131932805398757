import React, { useEffect, useState } from "react";
import { Link, NavLink, Route, Routes, useLocation, useParams } from "react-router-dom";
import { IAddress, IAddressContract, IAddressService, IHeating, IObservation, IWorkbook, IZone } from "../../../common/types";
import { reqDelete, reqGet } from "../../../../service/apiRequest";
import { Filter, Header, ModalFullScreen, PrivateComponent, PrivateLinkAdd, ServiceBadge, dateFormat } from "../../../common/common";
import { GROUP, ICONS, LAYOUT } from "../../../common/constants";
import $ from 'jquery'
import { getToken } from "../../../../useToken";
import { WorkbookTable } from "../../address/address-workbook/address-workbook";
import { flexRender } from "@tanstack/react-table";
import { AddressContractCard } from "../../address/address-contract/address-contract";

export default function ClientAddressDetail() {
    const location = useLocation()
    const params = useParams()
    const idAddress = params.idAddress
    const idManagement = params.idManagement
    const idOwner = params.idOwner

    const [address, setAddress] = useState<IAddress>()

    useEffect(() => {
        reqGet("address/" + idAddress).then(res => setAddress(res))
    }, [idManagement, idOwner, idAddress])

    const LinkBack = () => {
        const address = $(window).width() <= LAYOUT.lg ? "/address" : ""
        if (idManagement)
            return <Link className="link-nav" to={"/client/management/" + idManagement + address}>{"<"} Retour</Link>

        else if (idOwner)
            return <Link className="link-nav" to={"/client/owner/" + idOwner + address}>{"<"} Retour</Link>

        else
            return undefined
    }

    const AddressLinks: {
        Title: string, State, Path: string, Icon: string, PrivateGroup: Array<String> | undefined, PrivatePermission: Array<String> | undefined
    }[] = [
            { Title: "Carnet de chaufferie", Path: "workbook", Icon: ICONS.WORKBOOK, State: { from: "client", path: location.pathname }, PrivateGroup: undefined, PrivatePermission: undefined }
        ]

    return <section className="body-container">

        <header className="mb-3">
            <h3 className="section-title mb-0">
                {address?.AddressLine}, {address?.ZipCode} {address?.City}
            </h3>

            {address?.AddressComplementary && <h5 className="d-inline-block me-3 mb-0"><i>{address?.AddressComplementary}</i></h5>}

            <div className="d-flex justify-content-between">
                {LinkBack()}
                <Link to={"/address/" + idAddress} className="link-nav">Vers adresse {">"}</Link>
            </div>
        </header>

        <section className="body">

            <nav className="nav-horizontal mb-0" style={{ minHeight: "2.5rem" }}>
                <NavLink to="details" className="nav-link">Détails</NavLink>
                <NavLink to="heating" className="nav-link">Arrêt/MER chauffage</NavLink>
                <NavLink to="workbook" className="nav-link">Carnet de chaufferie</NavLink>
                <NavLink to="contracts" className="nav-link">Contrats</NavLink>
                {/*<NavLink to="services" className="nav-link">Prestations</NavLink>*/}
            </nav>

            <Routes>
                <Route path="details" element={<ClientAddressDetails address={address} />} />
                <Route path="heating" element={<ClientAddressHeating address={address} />} />
                <Route path="workbook" element={<ClientAddressWorkbook address={address} />} />
                <Route path="contracts" element={<ClientAddressContracts address={address} />} />
                {/*<Route path="services" element={<ClientAddressServices address={address} />} />*/}
            </Routes>

        </section>

    </section>
}


function ClientAddressDetails({ address }: { address: IAddress | undefined }) {
    const location = useLocation()
    const [zone, setZone] = useState<IZone>();

    const [toggleManagement, setToggleManagement] = useState<boolean>(false)
    const [toggleOwner, setToggleOwner] = useState<boolean>(false)
    const [toggleTenant, setToggleTenant] = useState<boolean>(false)

    useEffect(() => {
        if (address)
            reqGet("zone/address/" + address.AddressID).then(res => { setZone(res) });
    }, [address]);

    return <>

        <ModalFullScreen
            toggle={toggleManagement}
            setToggle={setToggleManagement}
            title={address?.ManagementName ?? ""}
            content={
                <>

                    {address?.ManagementWebsite &&
                        <div className="mb-3">
                            <a href={address.ManagementWebsite} target="_blank" className="link-nav">Site internet {">"}</a>
                        </div>
                    }

                    <dl className="row">
                        <dt className="col-sm-4 mb-1 mb-sm-3">Adresse</dt>
                        <dd className="col-sm-8">
                            <p className="mb-1">{address?.ManagementAddress}</p>
                            <p>{address?.ManagementZipCode} {address?.ManagementCity}</p>
                        </dd>

                        <dt className="col-sm-4 mb-1 mb-sm-3">Email</dt>
                        <dd className="col-sm-8 mb-3">{address?.ManagementEmail}</dd>

                        <dt className="col-sm-4 mb-1 mb-sm-3">Email mazout</dt>
                        <dd className="col-sm-8 mb-3">{address?.ManagementEmailMazout}</dd>

                        <dt className="col-sm-4 mb-1 mb-sm-3">Téléphone</dt>
                        <dd className="col-sm-8 mb-3">{address?.ManagementPhone}</dd>

                        <dt className="col-sm-4 mb-1 mb-sm-3">Fax</dt>
                        <dd className="col-sm-8 mb-3">{address?.ManagementFax}</dd>
                    </dl>
                </>
            }
        />

        <ModalFullScreen
            toggle={toggleOwner}
            setToggle={setToggleOwner}
            title={address?.OwnerName ?? ""}
            content={
                <>
                    <dl className="row">
                        <dt className="col-sm-4 mb-1 mb-sm-3">Adresse</dt>
                        <dd className="col-sm-8 mb-3">
                            <p className="mb-1">{address?.OwnerAddress}</p>
                            <p>{address?.OwnerZipCode} {address?.OwnerCity}</p>
                        </dd>

                        <dt className="col-sm-4 mb-1 mb-sm-3">Email</dt>
                        <dd className="col-sm-8 mb-3">{address?.OwnerEmail}</dd>

                        <dt className="col-sm-4 mb-1 mb-sm-3">Téléphone</dt>
                        <dd className="col-sm-8 mb-3">{address?.OwnerPhone}</dd>
                    </dl>
                </>
            }
        />


        <ModalFullScreen
            toggle={toggleTenant}
            setToggle={setToggleTenant}
            title={address?.TenantName ?? ""}
            content={
                <>
                    <dl className="row">
                        <dt className="col-sm-4 mb-1 mb-sm-3">Email</dt>
                        <dd className="col-sm-8 mb-3">{address?.TenantEmail}</dd>

                        <dt className="col-sm-4 mb-1 mb-sm-3">Téléphone</dt>
                        <dd className="col-sm-8 mb-3">{address?.TenantPhone}</dd>
                    </dl>
                </>
            }
        />

        <header className="header">
            <h2 className="section-title-2">Détails</h2>
        </header>

        <section>
            <section className="row row-cols-2 row-cols-xxl-4">

                <div className="col">
                    <div className="card">
                        <header className="card-header">
                            <h5 className="card-title">Zone</h5>

                            {zone &&
                                <Link to={"/zone/" + zone?.ZoneID} className="link-nav ms-3">Détails</Link>
                            }
                        </header>

                        <section className="card-body">
                            {zone ?
                                <p className="mb-0">{zone?.FirstName}  {zone?.Name}</p> :
                                <p className="text-muted mb-0">Non défini</p>
                            }
                        </section>
                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <header className="card-header justify-content-between">
                            <h5 className="card-title">Régie</h5>

                            {address?.ManagementID &&
                                <i className="bi bi-info-circle link-nav me-1" onClick={() => setToggleManagement(true)}></i>
                            }
                        </header>
                        <section className="card-body">
                            {address?.ManagementName ?
                                <p className="mb-0">{address?.ManagementName}</p> :
                                <p className="text-muted mb-0">Non défini</p>
                            }
                        </section>
                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <header className="card-header justify-content-start">
                            <h5 className="card-title">Propriétaire</h5>

                            {address?.OwnerID &&
                                <button className="btn link-nav ms-3" onClick={() => setToggleOwner(true)}>
                                    <i className="bi bi-info-circle me-1"></i>
                                </button>
                            }
                        </header>

                        <section className="card-body">
                            {address?.OwnerName ?
                                <p className="mb-0">{address?.OwnerName}</p> :
                                address?.Owner ?
                                    <p className="mb-0">{address?.Owner}</p> :
                                    <p className="text-muted mb-0">Inconnu</p>
                            }
                        </section>
                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <header className="card-header justify-content-start">
                            <h6>Locataire</h6>

                            {address?.TenantID &&
                                <button className="btn link-nav ms-3" onClick={() => setToggleTenant(true)}>
                                    <i className="bi bi-info-circle me-1"></i>
                                </button>
                            }
                        </header>
                        <section className="card-body">
                            {address?.TenantName ?
                                <p className="mb-0">{address?.TenantName}</p> :
                                <p className="text-muted mb-0">Inconnu</p>
                            }
                        </section>
                    </div>
                </div>

            </section>

            <section>
                <header className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="section-title-2">Remarques</h3>

                    {PrivateComponent(
                        <Link to={"/address/" + address?.AddressID + "/update-note"} className="btn btn-outline" title="Modifier les remarques" state={{ path: location.pathname }}>
                            <i className={ICONS.UPDATE + " me-1"}></i> Modifier
                        </Link>,
                        GROUP.crud_sav, ["AddressUpdate"]
                    )}
                </header>

                <section className="row row-cols-1 row-cols-lg-2">

                    <section className="col">
                        <div className="card">
                            <header className="card-header">
                                <h5 className="card-title">Interne</h5>
                            </header>

                            <div className="overflow-auto" style={{ maxHeight: "100px" }}>
                                {address?.InternNote ?
                                    <pre className="paragraph h-100">{address?.InternNote}</pre> :
                                    <p className="text-muted mb-0">Aucune</p>
                                }
                            </div>

                        </div>
                    </section>

                    <div className="col">
                        <div className="card">
                            <header className="card-header">
                                <h5 className="card-title">Général</h5>
                            </header>

                            <div className="card-body overflow-auto" style={{ maxHeight: "100px" }}>
                                {address?.Note ?
                                    <pre className="paragraph h-100">{address?.Note}</pre> :
                                    <p className="text-muted mb-0">Aucune</p>
                                }
                            </div>

                        </div>
                    </div>
                </section>
            </section>
        </section>

    </>
}


function ClientAddressHeating({ address }: { address: IAddress | undefined }) {
    interface IHeatingObservation extends IHeating, IObservation { }

    const token = getToken()
    const location = useLocation()

    const [reload, setReload] = useState<boolean>(false)

    const [heatings, setHeatings] = useState<Array<IHeatingObservation>>([])

    useEffect(() => {
        if (address)
            reqGet("heating/address/" + address?.AddressID).then(res => {
                setHeatings(res);
            })
    }, [address])

    useEffect(() => {
        if (reload) {
            reqGet("heating/address/" + address?.AddressID).then(res => {
                setHeatings(res);
            })

            setReload(false)
        }
    }, [reload])

    function RemoveHeating(heating) {
        if (heating?.HeatingID)
            reqDelete("heating/" + heating?.HeatingID, { data: { ActivityUserID: token.UserID, ...address, ...heating } }).then(res => {
                setReload(true)
            })
    }

    return <>

        <header className="header">
            <h2 className="section-title-2">Arrêt/MER Chauffage</h2>

            <Link to={"/address/" + address?.AddressID + "/heating/add"} className="btn btn-add" state={{ path: location.pathname }}><i className={ICONS.ADD + " me-2"}></i> Ajouter</Link>
        </header>

        <section>
            {heatings.length > 0 &&
                <>
                    <section className="list" id="listHeating">
                        {heatings?.map(h => {
                            return <div className="list-item py-2" key={h.HeatingID}>


                                <div className="modal fade" id="modalRemoveHeating" tabIndex={-1} aria-labelledby="modalRemoveHeating" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h1 className="modal-title fs-5" id="modalRemoveHeating">Supprimer</h1>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <p>Êtes-vous sur de vouloir supprimer cet arrêt/MER de chauffage ?</p>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                                                <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => RemoveHeating(h)}>Supprimer</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <header className="list-item-start">
                                    {h?.ObservationID ?
                                        <div className="badge rounded-5 bg-success" style={{ padding: ".3rem .4rem", fontSize: "1.1rem" }}>
                                            <i className="bi bi-check"></i>
                                        </div> :
                                        <div className="badge rounded-5 bg-warning" style={{ padding: ".3rem .4rem", fontSize: "1.1rem" }}>
                                            <i className="bi bi-clock"></i>
                                        </div>}
                                </header>

                                <section className="list-item-data pe-0" style={{ height: "auto" }}>

                                    <div className="row mb-2">
                                        {/* Action & date */}
                                        <div className="col-3">
                                            <h6 className="mb-0">{h.HeatingAction == "Start" ? "Mise en route" : "Arrêt"}</h6>
                                            <p className="mb-0">{dateFormat(h?.HeatingDate, "dd/MM/yyyy")}</p>
                                        </div>

                                        {/* Information */}
                                        <div className="col-9">
                                            <h6 className="mb-0">Information</h6>
                                            <p className="mb-0">{h?.HeatingNote}</p>
                                        </div>
                                    </div>

                                    {h?.ObservationDate ?
                                        <div className="row">
                                            <div className="col-3">
                                                <h6 className="mb-0">{h.FirstName} {h.Name}</h6>
                                                <p className="mb-0">{dateFormat(h.ObservationDate, "dd/MM/yyyy")}</p>
                                            </div>
                                            <div className="col-9">
                                                <h6 className="mb-0">Remarque</h6>
                                                <p className="mb-0">{h.Note}</p>
                                            </div>
                                        </div> :
                                        <p className="text-muted mb-0">Pas encore effectué</p>
                                    }
                                </section>

                                <footer className="list-item-more h-100" style={{ width: "1.75rem" }}>
                                    <div className="dropdown">

                                        <button className="btn btn-unstyled" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="bi bi-three-dots-vertical" style={{ fontSize: "1.2rem" }}></i>
                                        </button>

                                        <ul className="dropdown-menu">
                                            {PrivateComponent(
                                                <Link to={"/address/" + address?.AddressID + "/heating/" + h.HeatingID + "/update"} title="Modifier" className="dropdown-item" state={{ path: location.pathname }}>
                                                    <i className={ICONS.UPDATE + " me-2"}></i>Modifier
                                                </Link>,
                                                GROUP.crud_sav,
                                                ["HeatingUpdate"]
                                            )}

                                            {PrivateComponent(
                                                <button className="dropdown-item" title="Supprimer" data-bs-toggle="modal" data-bs-target="#modalRemoveHeating">
                                                    <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                                </button>,
                                                GROUP.crud_sav,
                                                ["HeatingDelete"]
                                            )}
                                        </ul>

                                    </div>
                                </footer>

                            </div>
                        })}

                    </section>
                </>
            }
        </section>

    </>
}


function ClientAddressContracts({ address }: { address: IAddress | undefined }) {
    const location = useLocation()

    const LinkBack = () => {
        if (location?.state?.back)
            return location.state.back
        else if (address?.AddressID)
            return "/address/" + address.AddressID
        else
            return "/address"
    }

    const [addressContracts, setAddressContracts] = useState<Array<IAddressContract>>([])

    useEffect(() => {
        reqGet("addresscontract/address/" + address?.AddressID).then(res => {
            setAddressContracts(res)
        })
    }, [address?.AddressID])

    /*
    useEffect(() => {
        if (reload) {
            reqGet("address/" + address.AddressID).then(res => setAddress(res))
            reqGet("addresscontract/address/" + address.AddressID).then(res => {
                setAddressContracts(res)
            })
        }
    }, [reload])
    */


    return <main className="body-container">

        <header className="header">
            <div>
                <h2 className="section-title">Contrats</h2>
                <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
            </div>

            {PrivateLinkAdd("add", GROUP.crud_sav)}
        </header>


        <section className="body">
            {addressContracts.length > 0 && addressContracts.map(addressContract =>
                <React.Fragment key={addressContract.AddressContractID}>
                    <AddressContractCard addressContract={addressContract} address={address} />
                </React.Fragment>
            )}
        </section>

    </main>
}


function ClientAddressServices({ address }: { address: IAddress | undefined }) {
    const [addressServices, setAddressServices] = useState<Array<IAddressService>>([])

    useEffect(() => {
        if (address)
            reqGet("addressservice/address/" + address?.AddressID).then(res => setAddressServices(res))
    }, [address])

    return <>


        <header className="header">
            <h2 className="section-title-2">Prestations</h2>
        </header>


        <section className="list" id="list">
            {addressServices?.map((s, k) => {
                return <div className="list-item py-2 mb-2" key={k} >

                    <div className="container-fluid px-0">
                        <div className="row" style={{ minHeight: "3rem" }}>
                            <div className="col-3 d-flex align-items-center">
                                <ServiceBadge service={s.Service} />
                            </div>

                            {/* Every services parameters exept heating*/}
                            {s.Service != "Chauffage" &&
                                <>
                                    <div className="col-4 pe-0">
                                        <h6 className="mb-1">Date de contrat</h6>
                                        <p className="mb-0">{dateFormat(s.AddressServiceDate, "dd/MM/yyyy")}</p>
                                    </div>


                                    <div className="col-4">

                                        {(s.Service == "Entretien" || s.Service == "Détartrage") && <>
                                            <h6 className="mb-1">Périodicité</h6>
                                            <p className="mb-0">{s.AddressServicePeriodicity}</p>
                                        </>}

                                        {s.Service == "Sous-traitant" &&
                                            <>
                                                <h6 className="mb-1">Sous-traitant</h6>
                                                <p className="text-truncate mb-0">{s.Company}</p>
                                            </>
                                        }
                                    </div>

                                    {s.InstallationID &&
                                        <div className="col-12 mt-3">
                                            <h6 className="mb-1">Installation</h6>
                                            <p className="text-truncate mb-0">{s.Type || s.IType || s.Class || s.IClass}<span>, {s.Model || s.IModel}</span></p>
                                        </div>}


                                    {s.AddressServiceNote &&
                                        <div className="col-12 mt-3">
                                            <h6 className="mb-1">Note</h6>
                                            <p className="mb-0">{s.AddressServiceNote}</p>
                                        </div>}
                                </>}
                        </div>
                    </div>

                </div>
            })}
        </section>
    </>

}


function ClientAddressWorkbook({ address }: { address: IAddress | undefined }) {
    const location = useLocation()
    const [workbook, setWorkbook] = useState<Array<IWorkbook>>()
    const [observations, setObservations] = useState<Array<IObservation>>([])

    const table = WorkbookTable()

    useEffect(() => {
        if (address)
            reqGet("observation/address/" + address.AddressID).then(res => setObservations(res))
    }, [address])

    return <>

        <header className="header">
            <h2 className="section-title-2">Carnet de chaufferie</h2>
        </header>

        <nav>
            {table.getHeaderGroups().map(headerGroup => (
                <div className="row row-cols-2 row-cols-xl-4" key={headerGroup.id}>

                    {headerGroup.headers.map((header, k) => {
                        return (
                            <div className="col mb-3 mb-xl-0" key={k}>

                                {header.isPlaceholder ? null : (
                                    <>

                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: header.column.getToggleSortingHandler() }}>

                                            {flexRender(header.column.columnDef.header, header.getContext())}

                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[header.column.getIsSorted() as string] ?? null}

                                        </h6>

                                        {header.column.getCanFilter() ? (
                                            <div className="d-flex flex-direction-column">
                                                <Filter column={header.column} table={table} />
                                            </div>
                                        ) : null}

                                    </>
                                )}
                            </div>
                        )
                    })}

                </div>
            ))}
        </nav>

        <section className="d-flex flex-column flex-grow-1 overflow-auto">

            <section className="list mt-3">
                {table.getRowModel().rows.map(row => {
                    const observation: IObservation = row.original
                    return (

                        <div className="list-item" key={observation.ObservationID}>
                            <div className="list-item-data px-0">

                                <div className="row">

                                    <div className='col-5 col-lg-3 col-xl-2'>
                                        <ServiceBadge service={observation?.Service ?? ""} />
                                    </div>

                                    <div className='col-7 col-lg-4 col-xl-3 text-end text-lg-start'>
                                        <h6 className="text-truncate mb-0">{observation.FirstName} {observation.Name}</h6>
                                        <p className='mb-0'>{dateFormat(observation.ObservationDate, "dd/MM/yyyy, hh:mm")}</p>
                                    </div>

                                    <div className="col-12 col-lg-5 col-xl-7">
                                        <h6 className="mb-0">Remarque</h6>
                                        <p className="mb-0">{observation.Note}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )

                })}
            </section>

        </section>

        <footer className="card-footer d-flex justify-content-center align-items-center">
            <button className="btn btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                {'<<'}
            </button>
            <button className="btn btn-outline" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                {'<'}
            </button>

            <p className="mx-3 mb-0">
                {table.getState().pagination.pageIndex + 1}
                <span className="text-muted">{' '} sur {' '}</span>
                {table.getPageCount()}
            </p>

            <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                {'>'}
            </button>
            <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                {'>>'}
            </button>
        </footer>
    </>

}