import React, { useState, useEffect, useMemo } from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { PrivateLinkAdd, DebouncedInput, fuzzyFilter } from "../../common/common";
import $ from 'jquery'

import { GROUP } from "../../common/constants"

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
} from '@tanstack/react-table'
import { IZone } from '../../common/types'
import { reqGet } from '../../../service/apiRequest';

export default function Zone({ reload, setReload }) {

    return <div className="body-container">

        <div className="body container-fluid">

            <div className="row h-100">

                {ZoneList(reload, setReload)}

                <div className="col-md-7 col-lg h-100 overflow-auto content">

                    <Outlet />

                </div>

            </div>

        </div>
    </div>
}

function ZoneList(reload, setReload) {
    const location = useLocation()

    const [display, setDisplay] = useState<string>("")

    function handleNavOnResize() {

        if ($(window).width() >= 768) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/zone") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }

    }


    useEffect(() => {
        window.addEventListener('resize', handleNavOnResize)

    })

    useEffect(() => {
        handleNavOnResize()

    }, [location])

    const columns = useMemo<ColumnDef<IZone, any>[]>(
        () => [
            {
                accessorFn: row => row.ZoneName,
                id: 'ZoneName',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.FirstName,
                id: 'FirstName',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Name,
                id: 'Name',
                cell: info => info.getValue()
            }
        ], []
    )


    const [data, setData] = useState<Array<IZone>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        reqData()
        table.setPageSize(100)
    }, [])

    useEffect(() => {

        if (reload) {
            reqData()
            setReload(false)
        }

    }, [reload])

    function reqData() {
        reqGet("zone").then((res) => {
            setData(res)
        })
    }




    return <div id="cardNavList" className={display + " col-md-5 col-lg-4 col-xl-3 col-h-fill border-right py-3"}>
        <header className="d-flex justify-content-between align-tiems-start">
            <h2 className='section-title'>Zones</h2>
            <div>
                {PrivateLinkAdd("add", GROUP.crud_sav)}
            </div>
        </header>


        <article className="card card-flush card-fill">

            <section className="card-body">

                <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} className="mb-3" placeholder="Recherche" />

                <nav className="list-group list-group-hover">

                    {table.getRowModel().rows.map(row => {

                        return (
                            <NavLink to={row.original.ZoneID.toString()} key={row.original.AddressID} className="list-group-item d-flex justify-content-between">
                                <div>
                                    <h6 className="item-title">{row.original.ZoneName}</h6>
                                    <p className="item-subtitle"><i>{row.original.FirstName} {row.original.Name}</i></p>
                                </div>

                                <p className='item-subtitle'>{row.original.Role}</p>
                            </NavLink>
                        )

                    })}
                </nav>


            </section>
        </article>
    </div>
}