import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import $ from 'jquery'
import { getToken } from "../../../useToken";
import { ITimesheet, IUser } from "../../common/types";
import { reqGet, reqPut } from "../../../service/apiRequest";
import { ButtonSubmit, Header, TimeRound, dateFormat, isAuthorized } from "../../common/common";
import { GROUP } from "../../common/constants";

export default function TimesheetUpdate() {
    const params = useParams()
    const idTimesheet = params.idTimesheet
    const navigate = useNavigate()

    const [timesheet, setTimesheet] = useState<ITimesheet>()

    useEffect(() => {
        reqGet("timesheet/" + idTimesheet).then(res => {
            // if timesheet is validate can't modify
            if (res.TimesheetValidate == 1 && !isAuthorized(GROUP.admin))
                navigate(-1)

            setTimesheet(res)
        })
    }, [idTimesheet])


    return <div className="container-fluid">
        <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-6">

                {timesheet?.TimesheetType == "Timesheet" && <UpdateTimesheet timesheet={timesheet} />}
                {timesheet?.TimesheetType == "Picket" && <UpdatePicket timesheet={timesheet} />}
            </div>
        </div>
    </div>
}


function UpdateTimesheet({ timesheet }) {
    const token = getToken()
    const navigate = useNavigate()

    useEffect(() => {
        $("#TimesheetStart").val(timesheet.TimesheetStart.substring(11, 16))
        $("#TimesheetLunchStart").val(timesheet.TimesheetLunchStart?.substring(11, 16))
        $("#TimesheetLunchEnd").val(timesheet.TimesheetLunchEnd?.substring(11, 16))
        $("#TimesheetEnd").val(timesheet.TimesheetEnd?.substring(11, 16))
    }, [timesheet])

    const onSubmit = () => {
        var data = {
            ActivityUserID: token.UserID,
            TimesheetID: timesheet.TimesheetID,
            TimesheetStart: $("#TimesheetStart").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetStart").val() + ":00" : null,
            TimesheetLunchStart: $("#TimesheetLunchStart").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetLunchStart").val() + ":00" : null,
            TimesheetLunchEnd: $("#TimesheetLunchEnd").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetLunchEnd").val() + ":00" : null,
            TimesheetEnd: $("#TimesheetEnd").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetEnd").val() + ":00" : null
        }

        reqPut('timesheet/' + timesheet.TimesheetID, data).then((res) => {
            navigate("/timesheet", { state: { date: timesheet.TimesheetStart.split(" ")[0] } })
        })
    };

    return <div className="body-container content">
        <header className="mb-3">
            <h2 className="section-title mb-0">Modifier la feuille d'heure</h2>
            <Link to="/timesheet" className="link-nav" state={{ date: timesheet.TimesheetStart.split(" ")[0] }}>{"<"} Retour</Link>
        </header>

        <section className="body">

            <div className="row row-cols-2">
                <div className="col mb-3">
                    <label className="form-label">Utilisateur</label>
                    <h4 className="item-title">{timesheet.FirstName} {timesheet.Name}</h4>
                </div>
                <div className="col mb-3">
                    <label className="form-label">Date</label>
                    <h4 className="item-title">{dateFormat(timesheet?.TimesheetStart, "dd/MM/yyyy")}</h4>
                </div>
            </div>

            <div className="row row-cols-2">
                <div className="col mb-3">
                    <label className="form-label">Début de journée</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetStart" className="form-control" />
                    </div>
                </div>

                <div className="col mb-3">
                    <label className="form-label">Pause de midi</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetLunchStart" className="form-control" />
                    </div>
                </div>

                <div className="col mb-3">
                    <label className="form-label">Fin pause de midi</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetLunchEnd" className="form-control" />
                    </div>
                </div>

                <div className="col mb-3">
                    <label className="form-label">Fin de journée</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetEnd" className="form-control" />
                    </div>
                </div>
            </div>


            <div className="text-end">
                <p><small>Les champs avec une * sont obligatoire</small></p>
                <ButtonSubmit onClick={onSubmit} />
            </div>
        </section>
    </div>
}


function UpdatePicket({ timesheet }) {
    const navigate = useNavigate()
    const token = getToken()

    useEffect(() => {
        $("#TimesheetStart").val(timesheet.TimesheetStart?.substring(11, 16))
        $("#TimesheetEnd").val(timesheet.TimesheetEnd?.substring(11, 16))
    }, [timesheet])

    const onSubmit = () => {
        var data = {
            ActivityUserID: token.UserID,
            TimesheetID: timesheet.TimesheetID,
            TimesheetStart: $("#TimesheetStart").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetStart").val() + ":00" : null,
            TimesheetEnd: $("#TimesheetEnd").val() ? timesheet?.TimesheetStart.split(" ")[0] + " " + $("#TimesheetEnd").val() + ":00" : null
        }

        reqPut('timesheet/' + timesheet.TimesheetID, data).then((res) => {
            navigate("/timesheet")
        })
    };

    return <div className="body-container content">
        <header className="mb-3">
            <h2 className="section-title">Modifier le service de piquet</h2>
            <Link to="/timesheet" className="link-nav">{"<"} Retour</Link>
        </header>

        <section className="body">

            <div className="row row-cols-2">
                <div className="col mb-3">
                    <label className="form-label">Utilisateur</label>
                    <h4 className="item-title">{timesheet.FirstName} {timesheet.Name}</h4>
                </div>
                <div className="col mb-3">
                    <label className="form-label">Date</label>
                    <h4 className="item-title">{dateFormat(timesheet?.TimesheetStart, "dd/MM/yyyy")}</h4>
                </div>
            </div>

            <div className="row row-cols-2">
                <div className="col mb-3">
                    <label className="form-label">Début de de piquet</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetStart" className="form-control" />
                    </div>
                </div>

                <div className="col mb-3">
                    <label className="form-label">Retour de piquet</label>

                    <div className="input-group">
                        <input type="time" id="TimesheetEnd" className="form-control" />
                    </div>
                </div>
            </div>


            <div className="text-end">
                <p><small>Les champs avec une * sont obligatoire</small></p>
                <ButtonSubmit onClick={onSubmit} />
            </div>
        </section>
    </div>
}