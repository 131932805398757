import React from "react";
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";

import HelpHome from "./help-pages/help-home"
import HelpStoragePutBack from "./help-pages/help-storage/help-storage-putback"
import HelpStorageTakeOut from "./help-pages/help-storage/help-storage-takeout"
import HelpVehicleCheck from "./help-pages/help-vehicle/help-vehicle-check"
import HelpWarehouse from "./help-pages/help-storage/help-warehouse"
import HelpDocumentConvention from "./help-pages/help-documents/help-documents-convention";

export default function HelpController() {
    return <>
        <Routes>
            <Route path="" element={<HelpHome />} />

            {/* DOCUMENTS */}
            <Route path="documents/convention" element={<HelpDocumentConvention />} />

            {/* STORAGE */}
            <Route path="storage" element={<Navigate to="/help/storage/warehouse" />}></Route>
            <Route path="storage/warehouse" element={<HelpWarehouse />} />
            <Route path="storage/product-takeout" element={<HelpStorageTakeOut />} />
            <Route path="storage/product-putback" element={<HelpStoragePutBack />} />

            {/* VEHICLE */}
            <Route path="vehicle/check" element={<HelpVehicleCheck />} /> {/* 👈 Renders at /app/ */}

            <Route path="/*" element={<Navigate to="/help" />}></Route>
        </Routes>
    </>
}