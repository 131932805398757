import React, { useEffect, useState, useMemo, JSXElementConstructor } from 'react'
import { Link, NavLink, useLocation, Outlet, useParams } from 'react-router-dom'
import $ from 'jquery'

import { reqGet } from "../../../../service/apiRequest";
import { Header, fuzzyFilter, PrivateLinkAdd, ProgressColor, DebouncedInput } from '../../../common/common'
import { LAYOUT, DEFAULT_IMG, GROUP, PERMISSION } from '../../../common/constants'
import { IBrand, IProduct, IProductLocation, IRepair, ISupplier } from '../../../common/types'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
} from '@tanstack/react-table'

interface IProductActivity extends IProductLocation, IRepair { }

export default function ToolActivity() {
    const params = useParams()
    const idProduct = params.idProduct

    const [productLocation, setProductLocation] = useState<Array<IProductLocation>>()
    const [repair, setRepair] = useState<Array<IRepair>>()
    const [activity, setActivity] = useState<Array<IProductActivity>>()

    useEffect(() => {
        let promise1 = reqGet("product/tool/" + idProduct + "/activity")
        let promise2 = reqGet("repair/product/" + idProduct)

        Promise.all([promise1, promise2]).then((res) => {
            InitActivity(res[0], res[1])
        })
    }, [])

    function InitActivity(productLocation, repair) {
        var p = 0, r = 0
        var array: Array<IProductActivity> = []

        // merge the two arrays
        while (p < productLocation.length || r < repair.length) {


            // IF there's still productLocation
            if (p < productLocation.length) {

                // if there's no repair more
                if (r >= repair.length) {
                    array.push(productLocation[p])
                    p++
                }
                // If product location is younger
                else if (new Date(productLocation[p].DateTakeOut) > new Date(repair[r].DateSent)) {
                    array.push(productLocation[p])
                    p++
                }
                // then repair is younger
                else {
                    array.push(repair[r])
                    r++
                }
            } else {
                array.push(repair[r])
                r++
            }

        }

        setActivity(array)
    }

    return <main className="body-container">
        {Header(
            <h2 className="section-title">Activité</h2>,
            <Link className="link-nav" to={"/tool/" + idProduct}>{"<"} Retour</Link>
        )}

        <section className='body container-fluid'>

            <div className='list list-location'>

                {activity && PathMaker(activity).map((a, key) => {
                    return <React.Fragment key={key}>{a}</React.Fragment>
                })}
            </div>

        </section>
    </main>
}

function PathMaker(activity: Array<IProductActivity>) {

    var activityArray: Array<JSX.Element> = []

    const DOT_SMALL = <i className="bi bi-circle-fill my-1" style={{ fontSize: ".3rem" }}></i>
    const DOT_STEP = <i className="bi bi-circle-fill" style={{ fontSize: "1rem" }}></i>

    for (var i = 0; i < activity.length; i++) {

        // if contains a productlocation element
        if (activity[i].ProductLocationID) {
            var next = activity[i + 1]

            // checking if its a path
            if (next?.LocationMoveTo) {
                var pathArray = [activity[i]]

                var j = 1
                next = activity[i + j]
                // while the next activity is linked => add to path
                while (i + j < activity.length && !activity[i + j]?.DatePutBackFormat) {
                    pathArray.push(activity[i + j])
                    j++
                }
                i += j

                activityArray.push(
                    <div className="card">
                        {pathArray.map((a, k) => {
                            if (k == 0) {
                                // Path end
                                return <>
                                    <div key={a.ProductLocationID} className="row" style={{ height: "5rem" }}>
                                        <div className="col-2 d-flex flex-column align-items-center justify-content-end">
                                            {DOT_STEP}
                                            {DOT_SMALL}
                                            {DOT_SMALL}
                                        </div>
                                        <div className="col d-flex flex-column justify-content-start">
                                            <h6 className='mb-1'>Retour en stock</h6>
                                            <p className="mb-0">{a.DatePutBackFormat}</p>
                                            <p>{a.PutBackFirstName} {a.PutBackName}</p>
                                            <h6 className="mb-1">Remarque</h6>
                                            <p className="mb-0">{a.ProductPutBackNote}</p>
                                        </div>
                                        <div className='col text-end'>
                                            <p>Etat: <b>{a.ProductCondition}</b></p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ height: "7rem" }}>
                                        <div className="col-2 d-flex flex-column align-items-center justify-content-center">
                                            {DOT_SMALL}
                                            {DOT_SMALL}
                                            {DOT_SMALL}
                                            {DOT_STEP}
                                            {DOT_SMALL}
                                            {DOT_SMALL}
                                            {DOT_SMALL}
                                        </div>
                                        <div className="col d-flex flex-column justify-content-center">
                                            <h6 className='mb-1'>{a.ProductLocation}</h6>
                                            <p className="mb-0">{a.DateTakeOutFormat}</p>
                                            <p>{a.TakeOutFirstName} {a.TakeOutName}</p>
                                            <h6 className="mb-1">Remarque</h6>
                                            <p className="mb-0">{a.ProductTakeOutNote}</p>
                                        </div>
                                    </div>
                                </>
                            } else if (k == pathArray.length - 1) {
                                // Path beginning
                                return <div key={a.ProductLocationID} className="row" style={{ height: "5rem" }}>
                                    <div className="col-2 d-flex flex-column align-items-center justify-content-start">
                                        {DOT_SMALL}
                                        {DOT_SMALL}
                                        {DOT_STEP}
                                    </div>
                                    <div className="col d-flex flex-column justify-content-end">
                                        <h6 className='mb-1'>{a.ProductLocation}</h6>
                                        <p className="mb-0">{a.DateTakeOutFormat}</p>
                                        <p>{a.TakeOutFirstName} {a.TakeOutName}</p>
                                        <h6 className="mb-1">Remarque</h6>
                                        <p className="mb-0">{a.ProductPutBackNote}</p>
                                    </div>
                                </div>
                            } else {
                                // Path middle step
                                return <div key={a.ProductLocationID} className="row" style={{ height: "6rem" }}>
                                    <div className="col-2 d-flex flex-column align-items-center justify-content-center">
                                        {DOT_SMALL}
                                        {DOT_SMALL}
                                        {DOT_STEP}
                                        {DOT_SMALL}
                                        {DOT_SMALL}
                                        {DOT_SMALL}
                                    </div>
                                    <div className="col d-flex flex-column justify-content-center">
                                        <h6 className='mb-1'>{a.ProductLocation}</h6>
                                        <p className="mb-0">{a.DateTakeOutFormat}</p>
                                        <p>{a.TakeOutFirstName} {a.TakeOutName}</p>
                                        <h6 className="mb-1">Remarque</h6>
                                        <p className="mb-0">{a.ProductTakeOutNote}</p>
                                    </div>
                                </div>
                            }
                        })}
                    </div>
                )
            } else {
                activityArray.push(<div className="card" key={activity[i].ProductLocationID}>
                    <div className="row" style={{ height: "10rem" }}>
                        <div className="col-2 d-flex flex-column align-items-center justify-content-end">
                            {DOT_STEP}
                            {DOT_SMALL}
                            {DOT_SMALL}
                            {DOT_SMALL}
                            {DOT_SMALL}
                            {DOT_SMALL}
                            {DOT_SMALL}
                            {DOT_SMALL}
                        </div>
                        <div className="col d-flex flex-column justify-content-start">
                            <h6 className='mb-1'>Retour en stock</h6>
                            <p className="mb-0">{activity[i].DatePutBackFormat}</p>
                            <p className="mb-1">{activity[i].PutBackFirstName} {activity[i].PutBackName}</p>
                            <h6 className="mb-0">Remarque</h6>
                            <p className="mb-0">{activity[i].ProductPutBackNote}</p>
                        </div>
                        <div className='col text-end'>
                            <p>Etat: <b>{activity[i].ProductCondition}</b></p>
                        </div>
                    </div>
                    <div className="row" style={{ height: "10rem" }}>
                        <div className="col-2 d-flex flex-column align-items-center justify-content-start">
                            {DOT_SMALL}
                            {DOT_SMALL}
                            {DOT_SMALL}
                            {DOT_SMALL}
                            {DOT_SMALL}
                            {DOT_SMALL}
                            {DOT_SMALL}
                            {DOT_STEP}
                        </div>
                        <div className="col d-flex flex-column justify-content-start">
                            <h6 className='mb-1'>{activity[i].ProductLocation}</h6>
                            <p className="mb-0">{activity[i].DateTakeOutFormat}</p>
                            <p className="mb-1">{activity[i].TakeOutFirstName} {activity[i].TakeOutName}</p>
                            <h6 className="mb-0">Remarque</h6>
                            <p className="mb-0">{activity[i].ProductTakeOutNote}</p>
                        </div>
                    </div>
                </div>)
            }
        }
        // if contains a repair element
        else {
            activityArray.push(<div className="card" key={activity[i].RepairID}>
                <header>
                    <h5 className="card-title"><i className="bi bi-wrench-adjustable-circle me-2"></i>Réparation</h5>
                </header>

                <section className="card-body">
                    <div className="row">
                        <div className="col-5">
                            <h6 className='mb-1'>{activity[i].RepairLocation}</h6>
                            <p className="mb-0">{activity[i].DateSentFormat}</p>
                            <p className="mb-0">{activity[i].SentFirstName} {activity[i].SentName}</p>
                        </div>
                        <div className="col-2">

                        </div>
                        <div className="col-5 text-end">
                            <h6 className='mb-1'>{activity[i].RepairNote}</h6>
                            <p className="mb-0">{activity[i].DateReturnFormat}</p>
                            <p className="mb-0">{activity[i].ReturnFirstName} {activity[i].ReturnName}</p>
                        </div>
                    </div>
                </section>
            </div>)
        }
    }

    return activityArray
}