import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { HeaderRow, ButtonSubmit } from '../../../common/common'
import { reqGet, reqPut } from '../../../../service/apiRequest'
import { IOwner, IZipCode } from '../../../common/types'

const validationSchema = yup.object({
    OwnerName: yup.string().required("Ce champ est obligatoire"),
    OwnerAddress: yup.string().nullable(),
    ZipCodeID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    OwnerEmail: yup.string().nullable(),
    OwnerPhone: yup.string().nullable(),
    OwnerNote: yup.string().nullable(),
})

const initialValues = {
    OwnerName: ""
}

export default function OwnerUpdate() {
    const location = useLocation()
    const token = getToken()
    let navigate = useNavigate()
    const params = useParams()
    let idOwner = params.idOwner

    const [zipCode, setZipCode] = useState<Array<IZipCode>>([])

    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IOwner>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {

        setValue("OwnerID", idOwner ? parseInt(idOwner) : 0)

        reqGet("zipcode").then(res => {
            setZipCode(res)

            reqGet("owner/" + idOwner).then(res => {
                setValue("OwnerName", res.OwnerName)
                setValue("OwnerAddress", res.OwnerAddress)
                setValue("ZipCodeID", res.ZipCodeID)
                setValue("OwnerEmail", res.OwnerEmail)
                setValue("OwnerPhone", res.OwnerPhone)
                setValue("OwnerNote", res.OwnerNote)
            })
        })

    }, [])


    const LinkBack = () => {
        if (location.state?.from == "client")
            return "/client/owner/" + idOwner
        if (location.state?.from == "address")
            return location.state.path
        else
            return "/database/owner"
    }

    const onSubmit: SubmitHandler<IOwner> = data => {
        if (data.OwnerID != 0) {
            reqPut('owner/' + idOwner, { ...data, ActivityUserID: token.UserID }).then((res) => {
                navigate(LinkBack())
            })
        }
    };

    return <div className="body-container h-100">

        {HeaderRow(
            <h3 className="section-title mb-1">Modifier le propiétaire</h3>,
            <button onClick={() => navigate(LinkBack())} className="btn link-nav">{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-lg-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="OwnerName" className="form-label">Nom *</label>
                            <input className={"form-control " + (errors?.OwnerName ? "is-invalid" : "")} {...register("OwnerName")} />

                            {errors.OwnerName?.message && <p className="mt-2 text-danger">{errors.OwnerName?.message}</p>}

                        </div>

                        <div className="row row-cols-2 mb-3">
                            <div className="col">

                                <label htmlFor="OwnerAddress" className="form-label">Adresse</label>
                                <input className={"form-control " + (errors?.OwnerAddress ? "is-invalid" : "")} {...register("OwnerAddress")} />

                                {errors.OwnerAddress?.message && <p className="mt-2 text-danger">{errors.OwnerAddress?.message}</p>}

                            </div>

                            <div className="col">

                                <label htmlFor="ZipCodeID" className="form-label">Code postale</label>
                                <select className={"form-select " + (errors?.ZipCodeID ? "is-invalid" : "")} {...register("ZipCodeID")}>
                                    <option value=""></option>
                                    {zipCode.map(z => {
                                        return <option key={z.ZipCodeID} value={z.ZipCodeID}>{z.ZipCode} {z.City}</option>
                                    })}
                                </select>

                                {errors.ZipCodeID?.message && <p className="mt-2 text-danger">{errors.ZipCodeID?.message}</p>}

                            </div>
                        </div>

                        <div className="row row-cols-2 mb-3">
                            <div className="col">

                                <label htmlFor="OwnerEmail" className="form-label">Email</label>
                                <input className={"form-control " + (errors?.OwnerEmail ? "is-invalid" : "")} {...register("OwnerEmail")} />

                                {errors.OwnerEmail?.message && <p className="mt-2 text-danger">{errors.OwnerEmail?.message}</p>}

                            </div>
                            <div className="col">

                                <label htmlFor="OwnerPhone" className="form-label">Téléphone</label>
                                <input className={"form-control " + (errors?.OwnerPhone ? "is-invalid" : "")} {...register("OwnerPhone")} />

                                {errors.OwnerPhone?.message && <p className="mt-2 text-danger">{errors.OwnerPhone?.message}</p>}

                            </div>
                        </div>

                        <div className="mb-3">

                            <label htmlFor="OwnerNote" className="form-label">Remarques</label>
                            <textarea className={(errors?.OwnerNote ? "is-invalid" : "")} id="OwnerNote" rows={4} {...register("OwnerNote")}></textarea>

                            {errors.OwnerNote?.message && <p className="mt-2 text-danger">{errors.OwnerNote?.message}</p>}

                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>
            </div >


        </div>
    </div>
}