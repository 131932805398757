import React, { useState, useEffect } from 'react'
import { NavLink, Link, useParams, useNavigate } from 'react-router-dom'
import { reqGet, reqPost } from '../../../service/apiRequest'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../useToken'
import { HeaderRow, ButtonSubmit } from '../../common/common'
import { IUser, IUserRole, IZone } from '../../common/types'

interface IFormInput extends IZone {
    ActivityUserID: number | null
}

const validationSchema = yup.object({
    ZoneName: yup.string().required("Ce champ est obligatoire"),
    UserID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    UserRoleID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),

}).required();

const initialValues = {
    ZoneName: "",
    UserID: null,
    UserRoleID: null,
    ActivityUserID: null
}


export default function ZoneAdd({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()

    const [users, setUsers] = useState([])
    const [userRole, setUserRole] = useState([])

    const { register, formState: { errors }, handleSubmit, setValue } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        setValue("ActivityUserID", token.UserID)

        reqGet('user').then((res) => { setUsers(res) })
        reqGet('userrole').then((res) => { setUserRole(res) })
    }, [])

    const onSubmit: SubmitHandler<IFormInput> = data => {

        reqPost('zone', data).then((res) => {
            setReload(true)
            navigate('/zone/' + res)
        })

    };

    return <section className="body-container">

        {HeaderRow(
            <h3 className="section-title">Ajouter une zone</h3>,
            <Link to={"/zone"} className="link-nav d-block d-md-none">{'<'} Retour</Link>
        )}

        <section className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="ZoneName" className="form-label">Nom *</label>
                            <input type="text" className={"form-control " + (errors?.ZoneName ? "is-invalid" : "")} placeholder="Exemple: Surveillance Z" {...register("ZoneName")} />

                            {errors.ZoneName?.message && <p className="mt-2 text-danger">{errors.ZoneName?.message}</p>}

                        </div>

                        <div className="mb-3">

                            <label htmlFor="UserID" className="form-label">Responsable *</label>
                            <select className={"form-select " + (errors?.UserID ? "is-invalid" : "")} {...register("UserID")}>
                                <option hidden disabled></option>
                                {users.map((u: IUser, k) => {
                                    return <option key={k} value={u.UserID}>{u.FirstName} {u.Name}</option>;
                                })}
                            </select>

                            {errors.UserID?.message && <p className="mt-2 text-danger">{errors.UserID?.message}</p>}

                        </div>

                        <div className="mb-3">

                            <label htmlFor="UserRoleID" className="form-label">Role</label>
                            <select className={"form-select " + (errors?.UserRoleID ? "is-invalid" : "")} {...register("UserRoleID")}>

                                <option hidden disabled></option>

                                {userRole.filter((ur: IUserRole) => ur?.Role == "Surveillant" || ur.Role == "Technicien").map((u: IUserRole, k) => {
                                    return <option key={k} value={u.UserRoleID}>{u.Role}</option>;
                                })}
                            </select>

                            {errors.UserRoleID?.message && <p className="mt-2 text-danger">{errors.UserRoleID?.message}</p>}

                        </div>

                        <div className="text-end">
                            <p className="mb-2"><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit text={"Ajotuer"} />
                        </div>

                    </form>
                </div>

            </div>
        </section>
    </section>
}