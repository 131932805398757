import React, { useState, useEffect, useMemo } from "react";
import { useLocation, Outlet, Link, NavLink } from "react-router-dom";
import $ from "jquery";

import { LAYOUT, GROUP } from "../../common/constants"
import { PrivateComponent, PrivateLinkAdd, DebouncedInput, fuzzyFilter } from "../../common/common";
import { reqGet } from "../../../service/apiRequest";
import { IVehicle } from "../../common/types"
import { getToken } from "../../../useToken";

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    FilterFn,
    ColumnDef,
} from '@tanstack/react-table'



export function ThreeMonthCheck(lastCheck) {
    const dateArray = lastCheck.split(".")

    const date = new Date()
    date.setMonth(date.getMonth() - 3)

    const dateCheck = new Date(dateArray[2], dateArray[1] - 1, dateArray[0])

    if (dateCheck <= date) {
        return false
    } else {
        return true
    }
}

export default function Vehicle({ reload, setReload }) {

    return (
        <div className="body-container">

            <div className="body container-fluid">
                <div className="row h-100">

                    {AddressList(reload, setReload)}

                    <div className="col-md h-100 pe-md-3 overflow-auto content">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
}


function AddressList(reload, setReload) {
    const location = useLocation()

    const columns = useMemo<ColumnDef<IVehicle, any>[]>(
        () => [
            {
                accessorFn: row => row.VehicleNumber,
                id: 'VehicleNumber',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Brand,
                id: 'Brand',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Model,
                id: 'Model',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.VehicleType,
                id: 'VehicleType',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Numberplate,
                id: 'Numberplate',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Username,
                id: 'Username',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.FirstName,
                id: 'FirstName',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Name,
                id: 'Name',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Fuel,
                id: 'Fuel',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Year,
                id: 'Year',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.GFirstName,
                id: 'GFirstName',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.GName,
                id: 'GName',
                cell: info => info.getValue()
            },
        ], []
    )

    const token = getToken()

    const [data, setData] = useState<Array<IVehicle>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')
    const [display, setDisplay] = useState<string>("")

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        reqData()
    }, [])

    useEffect(() => {

        if (reload) {
            reqData()
            setReload(false)
        }

    }, [reload])

    function reqData() {
        reqGet("vehicle").then(function (res) {
            setData(res)
        })
    }

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md && !location.pathname.includes("counter")) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/vehicle") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }

    }

    return <div className={display + " col-md-4 col-xl-3 col-h-fill border-right py-3"}>

        <header className="d-flex justify-content-between align-tiems-start mb-3">
            <div>
                <h2 className="section-title mb-1">Véhicules</h2>
                <Link to="report" className="link-nav">Rapport {">"}</Link>
            </div>
            <div>
                {PrivateLinkAdd("add", GROUP.crud_sav)}
            </div>
        </header>

        <section className="card card-flush card-fill">

            <section className="card-body">

                <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} className="mb-3" placeholder="Recherche" />

                <div className="list-group list-group-hover">

                    {table.getRowModel().rows.map(row => {
                        const vehicle = row.original

                        return (
                            <NavLink to={vehicle.VehicleID.toString()} key={row.id} className="list-group-item">

                                <div className="row h-100">

                                    <div className="col-8">
                                        <h6 className="item-title text-truncate">{vehicle.VehicleNumber ? "N°" + vehicle.VehicleNumber + " | " : ""} {vehicle.Brand} {vehicle.Model}</h6>

                                        <p className="item-subtitle text-truncate"><i>{vehicle.VehicleType} {vehicle.Numberplate}</i></p>

                                        {vehicle.FirstName ?
                                            <p className="item-subtitle text-truncate"><i>{vehicle.FirstName} {vehicle.Name}</i></p>
                                            : <p className="item-subtitle text-truncate"><i>{vehicle.Username}</i></p>}
                                    </div>

                                    <div className="col-4 text-end ps-0 d-flex h-100 flex-column align-items-end justify-content-between">
                                        <p className="text-muted text-truncate mb-1">{vehicle.LastCheck}</p>


                                        <div className="mt-1 d-flex align-items-center">

                                            {token.UserID == vehicle.GuarantorID &&
                                                <p className="badge d-inline-block rounded-5 bg-survey mb-0 me-2" title="Répondant">R</p>
                                            }

                                            {(vehicle.Assignment == "Chantier" && (vehicle.LastCheck == null || !ThreeMonthCheck(vehicle.LastCheck))) &&
                                                <i className="bi bi-hourglass-bottom text-danger" title="Le dernier contrôle est ancien de plus de trois mois" style={{ fontSize: "1.1rem" }}></i>
                                            }

                                            {vehicle.Status != "Bad" ?

                                                (vehicle.Status == "Warning" ?
                                                    <span className="dot d-inline-block bg-warning rounded-5 ms-2"></span> :
                                                    "") :

                                                <span className="dot d-inline-block bg-danger rounded-5 ms-2"></span>
                                            }
                                        </div>

                                    </div>

                                </div>

                            </NavLink>
                        )

                    })}
                </div>
            </section>

        </section>

    </div>
}