import React, { useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { IAddress, IHeating, IObservation } from "../../common/types";
import { reqGet } from "../../../service/apiRequest";
import { PrivateComponent, PrivateLinkAdd, PrivateLinkUpdate, dateFormat } from "../../common/common";
import { GROUP, ICONS } from "../../common/constants";

interface IHeatingObservation extends IHeating, IAddress {
    AddressServiceNote: string,
    UserID: number,
    ObservationDate: string,
    ObservationFirstName: string,
    ObservationName: string,
    ZoneFirstName: string,
    ZoneName: string
}

export default function HeatingContent({ reload, setReload }) {
    const params = useParams()
    const idHeating = params.idHeating

    const [heating, setHeating] = useState<IHeatingObservation>()

    useEffect(() => {
        reqGet("heating/" + idHeating).then(res => { setHeating(res) })
    }, [idHeating])

    useEffect(() => {
        if (reload) {
            reqGet("heating/" + idHeating).then(res => { setHeating(res) })
            setReload(false)
        }
    }, [reload])

    return <div className="body-container">

        <header className="header">
            <h2 className="section-title">{heating?.AddressLine}</h2>
            <h4 className="section-subtitle mb-0"><i>{heating?.AddressComplementary}</i></h4>
            <h4 className="section-subtitle">{heating?.ZipCode} {heating?.City}</h4>

            <div className="d-flex justify-content-between mb-3">
                <Link to="/heating" className="link-nav d-md-none">{"<"} Retour</Link>
                <Link to={"/address/" + heating?.AddressID} className="link-nav">Voir adresse {">"}</Link>
            </div>
        </header>

        <section className="body">

            <div className="container-fluid">
                <div className="row row-cols-2">
                    <div className="col ps-0">
                        <div className="card">
                            <header className="card-header">
                                <h5 className="card-title">Régie</h5>
                            </header>
                            <section className="card-body">
                                {heating?.ManagementName ?? "Non défini"}
                            </section>
                        </div>
                    </div>

                    <div className="col pe-0">
                        <div className="card">
                            <header className="card-header">
                                <h5 className="card-title">Zone</h5>
                            </header>
                            <section className="card-body">
                                {heating?.ZoneFirstName ?? "Non défini"} {heating?.ZoneName}
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <h4 className="section-title-2">{heating?.HeatingAction == "Start" ? "Mise en route" : "Arrêt"} du chauffage</h4>

            <div className="card">
                <section className="card-body">
                    <div className="row row-cols-2 mb-2">
                        <div className="col">
                            <h6 className="mb-0">Programmé pour le</h6>
                            <p className="mb-0">{dateFormat(heating?.HeatingDate, "dd/MM/yyyy")}</p>
                        </div>

                        <div className="col">
                            <h6 className="mb-0">Attribué à</h6>
                            <p className="mb-0">{heating?.ZoneFirstName ?? "Non défini"} {heating?.ZoneName}</p>
                        </div>
                    </div>

                    {heating?.AddressServiceNote && <>
                        <h6 className="mt-3 mb-0">Information</h6>
                        <p className="mb-0">{heating?.AddressServiceNote}</p>
                    </>}
                </section>
            </div>


            {location.pathname == "/heating/" + idHeating ?
                <>
                    <div className="d-flex justify-content-between align-items-start mt-3">
                        <h4 className="section-title-2">Effectué</h4>

                        {heating?.ObservationID ?
                            PrivateLinkUpdate("update", GROUP.crud_sav)
                            : PrivateComponent(<Link to="add" className="btn btn-add"><i className={ICONS.UPDATE + " me-2"}></i>Remplir</Link>, GROUP.crud_sav)
                        }
                    </div>

                    <div className="card">
                        <section className="card-body">

                            {heating?.ObservationID ? <>
                                <div className="row row-cols-2 mb-2">
                                    <div className="col">
                                        <h6 className="mb-0">Technicien</h6>
                                        <p className="mb-0">{heating?.ObservationFirstName} {heating?.ObservationName}</p>
                                    </div>

                                    <div className="col">
                                        <h6 className="mb-0">Date de passage</h6>
                                        <p className="mb-0">{dateFormat(heating?.ObservationDate, "dd/MM/yyyy")}</p>
                                    </div>
                                </div>


                                <h6 className="mt-3">Remarque</h6>
                                <p className="mb-0">{heating?.Note}</p>
                            </>
                                : <p className="mb-0">En attente...</p>
                            }


                        </section>
                    </div>
                </>
                : <Outlet />
            }

        </section>

    </div>
}
