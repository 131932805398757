import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { reqPost } from '../../../service/apiRequest'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../useToken'
import { IWarehouse } from "../../common/types"
import { ButtonSubmit, HeaderRow } from '../../common/common'

const validationSchema = yup.object({
    WarehouseName: yup.string().required("Ce champ est obligatoire"),
    WarehouseAddress: yup.string().nullable()
}).required();

const initialValues = {
    WarehouseName: "",
    WarehouseAddress: null
}

export default function WarehouseAdd() {
    const navigate = useNavigate()
    const token = getToken()

    const { register, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm<IWarehouse>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const onSubmit: SubmitHandler<IWarehouse> = data => {

        reqPost('warehouse', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate('/warehouse/' + res)
        })

    };

    return <section className="body-container content">

        {HeaderRow(
            <h3 className="section-title mb-1">Ajouter un entrepôt</h3>,
            <Link to="/warehouse" className="link-nav">{'< Retour'}</Link>
        )}

        <section className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="row row-cols-2 mb-3">

                            <div className="col">
                                <label htmlFor="WarehouseName" className="form-label">Nom *</label>
                                <input type="text" className={"form-control " + (errors?.WarehouseName ? "is-invalid" : "")} {...register("WarehouseName")} />

                                {errors.WarehouseName?.message && <p className="mt-2 text-danger">{errors.WarehouseName?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="WarehouseAddress" className="form-label">Adresse</label>
                                <input type="text" className={"form-control " + (errors?.WarehouseAddress ? "is-invalid" : "")} {...register("WarehouseAddress")} />

                                {errors.WarehouseAddress?.message && <p className="mt-2 text-danger">{errors.WarehouseAddress?.message}</p>}
                            </div>

                        </div>


                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>
            </div >


        </section>
    </section>
}