import React, { useState } from "react";
import { IInvoice } from "../../../common/types";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { CardBtnLink, PrivateLinkAdd } from "../../../common/common";
import { GROUP, ICONS } from "../../../common/constants";

export default function AddressInvoice() {
    const location = useLocation()
    const params = useParams()
    const idAddress = params.idAddress

    const [invoices, setInvoices] = useState<Array<IInvoice>>([])


    const LinkBack = () => {
        if (location?.state?.back)
            return location.state.back
        else
            return "/address/" + idAddress
    }


    return <div className="container-fluid h-100">

        <div className="row h-100">

            <div className="col-4 col-h-fill px-0">

                <main className="body-container content">

                    <header className="header">
                        <div>
                            <h2 className="section-title">Factures</h2>
                            <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
                        </div>

                        <Link to="add" className="btn btn-add"><i className={ICONS.ADD + " me-2"}></i> Créer</Link>
                    </header>

                    <section className="body">

                        <div className="list">
                            <CardBtnLink title={"Invoice 1"} path="1" text={"Cholet"} icon={ICONS.INVOICE} />

                            {invoices.length > 0 && invoices.map(invoice => {
                                return <React.Fragment key={invoice.InvoiceID}>
                                    <CardBtnLink title={invoice.InvoiceTitle} path="" />
                                </React.Fragment>
                            })}

                        </div>

                    </section>

                </main>

            </div>

            <div className="col-8 col-h-fill px-0">
                <Outlet />
            </div>

        </div>

    </div>

}