import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import { CardBtnLink, Header, PrivateLinkAdd } from "../../common/common"
import { reqGet } from "../../../service/apiRequest";
import { IWarehouse } from "../../common/types";
import { GROUP, ICONS } from "../../common/constants";

export default function Storage() {

    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>([])

    useEffect(() => {
        reqGet("warehouse").then(res => {
            setWarehouses(res)
        })
    }, [])

    return <div className="body-container">

        <div className="body container-fluid">

            <div className="row justify-content-center content">
                <div className="col-sm-8 col-lg-6 col-xxl-5 px-0">
                    <div className="body-container overflow-hidden">

                        {Header(
                            <h2 className="section-title me-3">Entrepots</h2>,
                            undefined,
                            PrivateLinkAdd("add", GROUP.dev)
                        )}

                        <section className="body container-fluid">

                            {warehouses.map(w => <React.Fragment key={w.WarehouseID}>
                                <CardBtnLink path={w.WarehouseID.toString()} title={w.WarehouseName} text={w?.WarehouseAddress} icon={ICONS.WAREHOUSE} />
                            </React.Fragment>
                            )}

                        </section>

                    </div>
                </div>
            </div>

        </div>

    </div>

}