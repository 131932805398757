import React, { useState, useEffect, useMemo } from "react";
import { Link, NavLink, useLocation, Outlet } from "react-router-dom";
import $ from "jquery";

import { LAYOUT, GROUP, SERVICE, ICONS } from "../../common/constants"
import { PrivateLinkAdd, DebouncedInput, fuzzyFilter, ServiceBadge, Filter, PrivateComponent } from "../../common/common";
import { reqGet } from "../../../service/apiRequest";
import { IAddress } from "../../common/types"

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
} from '@tanstack/react-table'

export default function Address({ reload, setReload }) {

  return (
    <section className="container-fluid h-100">
      <div className="row h-100">

        {AddressList(reload, setReload)}

        <div className="col-md h-100 overflow-auto px-0">

          <Outlet />

        </div>
      </div>
    </section>
  );
}

function AddressList(reload, setReload) {
  const location = useLocation()

  const columns = useMemo<ColumnDef<IAddress, any>[]>(
    () => [
      {
        accessorFn: row => row.AddressLine,
        id: 'AddressLine',
        cell: info => info.getValue()
      },
      {
        accessorFn: row => row.AddressComplementary,
        id: 'AddressComplementary',
        cell: info => info.getValue()
      }
    ], []
  )

  const [data, setData] = useState<Array<IAddress>>([])
  const [globalFilter, setGlobalFilter] = useState<string>('')

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })


  useEffect(() => {
    reqData()
    table.setPageSize(100)
  }, [])

  useEffect(() => {

    if (reload) {
      reqData()
      setReload(false)
    }

  }, [reload])

  function reqData() {
    reqGet("address").then((res) => {

      setData(res)
    })
  }

  const [display, setDisplay] = useState<string>("")

  useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

  useEffect(() => { handleNavOnResize() }, [location])

  function handleNavOnResize() {

    if ($(window).width() >= LAYOUT.md && !location.pathname.includes("counter") && !location.pathname.includes("invoice/add/")) {
      setDisplay('d-flex')

    } else {
      if (location.pathname == "/address") {
        setDisplay('d-flex')

      } else {
        setDisplay('d-none')
      }
    }
  }

  return <section className={display + " col-md-4 col-xl-3 col-h-fill border-right p-3"}>

    <header className="d-flex justify-content-between align-tiems-start mb-3">
      <div>
        <h2 className="section-title mb-1">Adresses</h2>
        {PrivateComponent(<Link to="advanced" className="link-nav">Avancé {">"}</Link>, GROUP.crud_sav)}
      </div>
      <div>
        {PrivateLinkAdd("add", GROUP.crud_sav)}
      </div>
    </header>

    <article className="card card-flush card-fill">

      <section className="card-body">

        <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} className="mb-3" placeholder="Recherche" />

        <nav className="list-group list-group-hover">

          {table?.getRowModel().rows.length > 0 && table?.getRowModel().rows.map(row => {

            var address = row.original

            return (
              <NavLink to={address.AddressID.toString()} key={address.AddressID} className="list-group-item">

                <h6 className="item-title mb-0">{address.AddressLine}, <small>{address.ZipCode} {address.City}</small></h6>

                <p className="item-subtitle">{address.AddressComplementary}</p>

                {address.ManagementName &&
                  <p className="item-subtitle mb-3" title="Régie"><i className={ICONS.MANAGEMENT + " me-2"}></i>{address.ManagementName}</p>
                }
                {address.OwnerName &&
                  <p className="item-subtitle mb-3" title="Propriétaire"><i className={ICONS.OWNER + " me-2"}></i>{address.OwnerName}</p>
                }
                {address.TenantName &&
                  <p className="item-subtitle mb-3" title="Locataire"><i className={ICONS.TENANT + " me-2"}></i>{address.TenantName}</p>
                }

                {/*
                <div>
                  {address.Services?.find(s => s.Service == SERVICE.SURVEY) &&
                    <ServiceBadge service={SERVICE.SURVEY} className="me-2" />}

                  {address.Services?.find(s => s.Service == SERVICE.OPTIMISATION) &&
                    <ServiceBadge service={SERVICE.OPTIMISATION} />}
                </div>
                */}

                {address.Services?.length > 0 && <div className="py-1">

                  <div className="d-inline me-2" title="Contrats">
                    <i className={ICONS.CONTRACT + " me-2"}></i>
                    <p className="item-text d-inline">({address.NbAddressContract})</p>
                  </div>

                  <div className="d-inline" title="Prestations">
                    {address.Services?.filter((obj1, i, arr) =>
                      /* FILTER DUPPLICATE */
                      arr.findIndex(obj2 => (obj2.ServiceID === obj1.ServiceID)) === i
                    ).map((s, k) => {
                      return <ServiceBadge service={s.Service} className="me-2" key={k} firstLetter={true} />
                    })}

                  </div>
                </div>
                }

              </NavLink>
            )

          })}
        </nav>
      </section>

      <footer className="card-footer d-flex justify-content-center align-items-center">

        <nav className="pagination">
          <button className="btn btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
            {'<<'}
          </button>
          <button className="btn btn-outline" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
            {'<'}
          </button>

          <p className="mx-3 mb-0">
            {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
          </p>

          <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
            {'>'}
          </button>
          <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
            {'>>'}
          </button>
        </nav>
      </footer>
    </article>

  </section>
}