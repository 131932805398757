import React, { useState, useEffect } from 'react'
import { NavLink, Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { reqGet, reqPut } from '../../../../service/apiRequest'
import $ from 'jquery'
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { IAddress } from "../../../common/types"
import { HeaderRow, ButtonSubmit } from '../../../common/common'

const validationSchema = yup.object({
    Note: yup.string().nullable(),
    InternNote: yup.string().nullable()
}).required();

const initialValues = {
    Note: null,
    InternNote: null
}


export default function AddressUpdateNote({ setReload }) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const idAddress = params.idAddress
    const token = getToken()

    const [address, setAddress] = useState<IAddress>()

    const { register, formState: { errors }, handleSubmit, setValue, watch } = useForm<IAddress>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        reqGet('address/' + idAddress).then(function (res) {
            setAddress(res)

            setValue("Note", res.Note)
            setValue("InternNote", res.InternNote)
        })
    }, [])

    const onSubmit: SubmitHandler<IAddress> = data => {
        reqPut('address/' + idAddress + "/note", { ...data, AddressLine: address?.AddressLine, ActivityUserID: token.UserID }).then((res) => {
            setReload(true)
            navigate('/address/' + idAddress)
        })
    };

    const LinkBack = () => {
        if (location?.state?.path)
            return <Link to={location.state.path} className="link-nav">{"<"} Retour</Link>
        else
            return <Link to={"/address/" + idAddress} className="link-nav">{"<"} Retour</Link>
    }

    return <div className="body-container">
        {HeaderRow(
            <>
                <h2 className="section-title">Modifier les remarques de l'adresse</h2>
                <h4 className='section-title-2 mb-1'>{address?.AddressLine}, {address?.ZipCode} {address?.City}</h4>
                <h5 className='text-muted'>{address?.AddressComplementary}</h5>
            </>,
            LinkBack()
        )}

        <div className="body container-fluid">

            <div className="row justify-content-center h-100">

                <div className="col-xl-6">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">

                            <label htmlFor="internNote" className="form-label">Remarques interne</label>
                            <textarea className={(errors?.InternNote ? "is-invalid" : "")} id="internNote" rows={4} {...register("InternNote")}></textarea>

                            {errors.InternNote?.message && <p className="mt-2 text-danger">{errors.InternNote?.message}</p>}

                        </div>

                        <div className="mb-3">

                            <label htmlFor="note" className="form-label">Remarques général</label>
                            <textarea className={(errors?.Note ? "is-invalid" : "")} id="note" rows={4} {...register("Note")}></textarea>

                            {errors.Note?.message && <p className="mt-2 text-danger">{errors.Note?.message}</p>}

                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>

            </div>

        </div>
    </div>
}