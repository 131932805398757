import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import $ from 'jquery'

import { IAddress, IObservation } from "../../../common/types";
import { dateFormat } from '../../../common/common'
import { reqGet, reqPost } from "../../../../service/apiRequest";
import { getToken } from "../../../../useToken";

export default function AccountSurveyContent({ reload, setReload }) {
    const navigate = useNavigate()
    const params = useParams()
    const idAddress = params.idAddress
    const token = getToken()

    const data = useOutletContext<Array<IAddress>>()

    const [address, setAddress] = useState<IAddress>()
    const [lastObservation, setLastObservation] = useState<IObservation>()

    useEffect(() => {
        // Check id validity
        if (!data.find(a => a.AddressID === parseInt(idAddress ?? "0"))) {
            navigate("/account/survey")
        }

        reqGet("address/" + idAddress).then(res => {
            setAddress(res)

            reqGet("observation/workbook/" + res.WorkbookID + "/last").then(r => {
                setLastObservation(r)
            })
        })

    }, [idAddress])

    useEffect(() => {
        if (reload) {
            reqGet("address/" + idAddress).then(res => {
                setAddress(res)

                reqGet("observation/workbook/" + res.WorkbookID + "/last").then(r => {
                    setLastObservation(r)
                })
            })
            setReload(false)
        }

    }, [reload])


    function submitSurvey() {
        var data = {
            ...address,
            ActivityUserID: token.UserID,
            WorkbookID: address?.WorkbookID,
            Note: $("#ObservationNote").val()
        }


        reqPost('observation/survey', data).then((res) => {
            setReload(true)
        })
        $("#ObservationNote").val("")
    };

    return <div className="body-container content">

        <header className="header">
            <h4 className="section-title-2 mb-0">{address?.AddressLine}, {address?.ZipCode} {address?.City}</h4>
            <h5 className="mb-0">{address?.AddressComplementary}</h5>

            <div className="d-flex justify-content-between">
                <Link className={"link-nav"} to={"/account/survey"}>{"<"} Retour</Link>

                <Link className="link-nav" to={"/address/" + idAddress}>Aller à l'adresse {">"}</Link>
            </div>
        </header>

        <section className="body container-fluid">

            {lastObservation &&
                <>

                    <h3 className="section-title-2">Dernière surveillance</h3>

                    <div className="card">

                        <section className="card-body">

                            <div className="row row-cols-2 mb-3">
                                <div className="col">
                                    <h6 className="mb-0">Technicien</h6>
                                    <p className="mb-0">{lastObservation?.FirstName} {lastObservation?.Name}</p>
                                </div>

                                <div className="col">
                                    <h6 className="mb-0">Date</h6>
                                    <p className="mb-0">{dateFormat(lastObservation?.ObservationDate, "dd/MM/yyyy")}</p>
                                </div>
                            </div>


                            <h6 className="mb-0">Remarque</h6>
                            <p className="mb-0">{lastObservation?.Note || "Aucun commentaire"} </p>
                        </section>
                    </div>
                </>
            }



            <h4 className="section-title-2 mt-4">Effectuer surveillance</h4>

            <label className="form-label" htmlFor="ObservationNote">Commentaire</label>
            <textarea className="mb-3" name="ObservationNote" id="ObservationNote" rows={4}></textarea>


            <div className="text-end">
                <p><small>Les champs avec une * sont obligatoire</small></p>
                <button className="btn btn-primary" onClick={() => submitSurvey()}>
                    <i className="bi bi-check me-1"></i> Valider
                </button>
            </div>

        </section>
    </div>
}