import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { dateFormat } from '../../common/common'

import { reqGet } from '../../../service/apiRequest'
import { fuzzyFilter, fuzzySort, DebouncedInput } from "../../common/common"
import { ICONS, PRODUCT_TYPE } from "../../common/constants"
import { IActivity } from "../../common/types"

import {
    Column,
    Table,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    flexRender,
} from '@tanstack/react-table'

export default function Activity() {
    const location = useLocation()
    const [reload, setReload] = useState<boolean>(false)

    const table = ActivityTable(reload, setReload)




    return <section className="body-container content">

        <header className="row mb-3">
            <div className="col-6 col-sm-3 order-1">
                <h2 className="section-title">Activités</h2>
            </div>

            <div className="col-sm-6 col-lg-4 offset-lg-1 order-3 order-sm-2">
                <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche" />
            </div>

            <div className="col-6 col-sm-3 offset-lg-1 text-end order-2 order-sm-3">
                <button className="btn btn-outline" onClick={() => setReload(true)}>
                    <i className="bi bi-arrow-clockwise me-1"></i> Rafraîchir
                </button>
            </div>
        </header>

        <nav className="nav-horizontal">
            <div className="row" style={{ minWidth: "500px" }}>

                {table.getHeaderGroups().map(headerGroup =>
                    <React.Fragment key={headerGroup.id}>

                        {headerGroup.headers.map((header, k) => {
                            return (
                                <div className='col' key={k}>

                                    {!header.isPlaceholder && (
                                        <>

                                            <label className="form-label mb-1" style={{ cursor: "pointer" }} {...{ onClick: header.column.getToggleSortingHandler() }}>

                                                {flexRender(header.column.columnDef.header, header.getContext())}

                                                {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[header.column.getIsSorted() as string] ?? null}

                                            </label>

                                            {header.column.getCanFilter() ? (
                                                <div className="d-flex flex-direction-column">
                                                    <Filter column={header.column} table={table} />
                                                </div>
                                            ) : null}

                                        </>
                                    )}

                                </div>
                            )
                        })}

                    </React.Fragment>
                )}
            </div>
        </nav>


        <section className="body">

            <div className='list'>

                {table.getRowModel().rows.map(row => {
                    const activity: IActivity = row.original
                    const status = ResolveStatus(activity.Status)
                    const action = ResolveAction(activity.Action)
                    const link = ResolveActivtiyLink(activity)

                    return <div className="list-item">

                        <header className="list-item-start h-100" style={{ width: "1.75rem" }}>
                            {status}
                        </header>


                        <section className="list-item-data">

                            <div className="row">

                                <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                    <h6 className="mb-0">{activity.FirstName} {activity.Name}</h6>
                                    <p className="mb-0">{dateFormat(activity.DateTime, "dd/MM/yyyy, hh:mm")}</p>
                                </div>

                                <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-start align-items-center">
                                    <p className="mb-0">{activity.Action}</p>
                                </div>

                                <div className="d-none d-sm-flex col-sm-4 col-md-6 col-lg-8 align-items-center">
                                    <p className="text-truncate-2 mb-0">{activity.Description}</p>
                                </div>

                            </div>

                        </section>


                        <footer className="list-item-more h-100" style={{ width: "1.75rem" }}>
                            <div className="dropdown">

                                <button className="btn btn-unstyled" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-three-dots-vertical" style={{ fontSize: "1.2rem" }}></i>
                                </button>

                                <ul className="dropdown-menu p-0">
                                    {link &&
                                        <Link className="dropdown-item" to={link} state={{ path: location.pathname }}>Aller à la page</Link>
                                    }

                                    <Link className="dropdown-item" to={activity.ActivityID.toString()}>Voir les détails</Link>
                                </ul>

                            </div>
                        </footer>

                    </div>
                })
                }
            </div>


        </section>



        <footer className="footer container-fluid">
            <div className="row pagination">

                <div className="col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start mb-3 mb-lg-0">

                    <button className="btn btn-outline me-2" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                        {'<<'}
                    </button>
                    <button className="btn btn-outline" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                        {'<'}
                    </button>

                    <p className="mb-0 mx-3 text-nowrap">
                        {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                    </p>

                    <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                        {'>'}
                    </button>
                    <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                        {'>>'}
                    </button>
                </div>

                <div className="col-7 col-sm-3 d-flex align-items-center mb-3 mb-lg-0 justify-content-md-end">
                    <p className="text-muted mb-0 me-3 text-nowrap">Page</p>

                    <input type="number" className="form-control w-50" defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                        }}
                    />
                </div>

                <div className="col-5 col-sm-4 mb-3 mb-lg-0">
                    <select className="form-select" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                        {[100, 200, 500].map(pageSize => (

                            <option key={pageSize} value={pageSize}>
                                Montrer {pageSize}
                            </option>

                        ))}

                    </select>
                </div>

            </div>
        </footer>

    </section>
}

function ActivityTable(reload?: boolean, setReload?) {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')

    const columns = useMemo<ColumnDef<IActivity, any>[]>(
        () => [
            {
                accessorFn: row => row.Status,
                id: 'Status',
                header: 'Etat',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.DateTime,
                id: 'DateTime',
                header: 'Date',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.FirstName + ' ' + row.Name,
                id: 'User',
                header: 'Utilisateur',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Action,
                id: 'Action',
                header: 'Catégorie',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Description,
                id: 'Description',
                header: 'Description',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
        ], []
    )

    const [data, setData] = useState<Array<IActivity>>([])

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,

        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useLayoutEffect(() => {
        if (reload) {
            reqGet("activity").then((res) => { setData(res) })
            setReload(false)
        }
    }, [reload])

    useLayoutEffect(() => {
        table.setPageSize(100)

        reqGet("activity").then((res) => { setData(res); })

    }, [])

    return table
}

function Filter({ column, table, }: { column: Column<any, unknown>; table: Table<any> }) {

    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    const sortedUniqueValues = React.useMemo(
        () => typeof firstValue === 'number' ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    return typeof firstValue === 'number' ? (
        <div className="d-inline-flex">

            <DebouncedInput
                type="number"
                className="form-filter"
                min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                value={(columnFilterValue as [number, number])?.[0] ?? ''}
                onChange={value => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
                placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''}`}
            />

            <DebouncedInput
                type="number"
                className="form-filter"
                min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                value={(columnFilterValue as [number, number])?.[1] ?? ''}
                onChange={value => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
                placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''}`}
            />

        </div>
    ) : (
        <>
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>

            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={value => column.setFilterValue(value)}
                placeholder={`Recherche... (${column.getFacetedUniqueValues().size})`}
                className="form-filter"
                list={column.id + 'list'}
            />
        </>
    )
}


export function ResolveStatus(status) {
    switch (status) {
        case "Success":
            return <i className={ICONS.SUCCESS + " text-success"} style={{ fontSize: "1.2rem" }}></i>
        case "Pending":
            return <i className={ICONS.WARNING + " text-warning"} style={{ fontSize: "1.2rem" }}></i>
        case "Failed":
            return <i className={ICONS.DANGER + " text-danger"} style={{ fontSize: "1.2rem" }}></i>
        default:
            return <></>
    }
}

export function ResolveAction(action: string) {

    if (action.includes("Ajout")) {
        return <span className="badge rounded bg-success">{action}</span>
    } else if (action.includes("Modification")) {
        return <span className="badge rounded bg-warning">{action}</span>
    } else if (action.includes("Suppression")) {
        return <span className="badge rounded bg-danger">{action}</span>
    } else {
        return <span className="badge rounded bg-primary">{action}</span>
    }
}

export function ResolveActivtiyLink(activity) {
    var link = ""

    if (!activity)
        return ""

    if (!activity.ParentRow && activity.Action.includes("Suppression")) {
        return ""
    }

    switch (activity.Type) {
        case "Adresse":
            link = "/address/" + activity.TableRow
            break;
        case "Document":
            if (activity.ParentRow) {
                link = "/address/" + activity.ParentRow + "/document"
            }
            break;
        case "Entrepôt":
            link = "/warehouse/" + activity.TableRow
            break;
        case "Installation":
            if (activity.ParentRow) {
                link = "/address/" + activity.ParentRow + "/installation"
            }
            break;
        case "Locataire":
            link = "/client/tenant/" + activity.TableRow
            break;
        case "Matériel":
            link = "/material"
            break;

        // Product ------------
        case "Pièce":
            link = "/piece/" + activity.TableRow
            break;
        case "Outil":
            link = "/tool/" + activity.TableRow
            break;
        case "Echangeur":
            link = "/exchanger/" + activity.TableRow
            break;
        // --------------------

        case "Régie":
            link = "/client/management/" + activity.TableRow
            break;
        case "Réparation":
            var data = JSON.parse(activity.Data)
            var productType = data.ProductType == PRODUCT_TYPE.TOOL ? "tool" : "exchanger"
            link = "/" + productType + "/" + data.ProductID + "/activity"
            break;
        case "Stockage":
            if (activity.ParentRow) {
                link = "/warehouse/" + activity.ParentRow + "/storage/" + activity.TableRow
            }
            break;
        case "Surveillance":
            link = "/address/" + JSON.parse(activity.Data).AddressID + "/workbook"
            break;
        case "Prestation":

            if (activity.ParentRow) {
                link = "/address/" + activity.ParentRow + "/service"
            }
            break;
        case "Utilisateur":
            link = "/users"
            break;

        // Vehicle ------------
        case "Véhicule":
            link = "/vehicle/" + activity.TableRow
            break;
        case "Contrôle véhicule":
            if (activity.ParentRow) {
                link = "/vehicle/" + activity.ParentRow
            }
            break;
        case "Véhicule check":
            if (activity.ParentRow) {
                link = "/vehicle/" + activity.ParentRow
            }
            break;
        // --------------------

        case "Zone":
            link = "/zone/" + activity.TableRow
            break;
    }
    return link
}
