import React, { useState, useEffect } from 'react'
import { NavLink, Link, useNavigate, useParams } from 'react-router-dom'
import { reqGet, reqPut } from '../../../service/apiRequest'
import $ from 'jquery'
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../useToken'
import { IAddress, IZipCode, IManagement, IOwner, ITenant } from "../../common/types"
import { HeaderRow, ButtonSubmit, ReplaceSpecialChars, ManagementInputSearch, OwnerInputSearch, TenantInputSearch } from '../../common/common'

interface IFormInput extends IAddress {
    ActivityUserID: number | null;
}

const validationSchema = yup.object({
    AddressLine: yup.string().required("Ce champ est obligatoire"),
    AddressComplementary: yup.string().nullable(),
    OwnerID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    Tenant: yup.string().nullable(),
    TenantID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ZipCodeID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    ManagementID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()

}).required();

const initialValues = {
    AddressLine: "",
    AddressComplementary: null,
    OwnerID: null,
    Tenant: null,
    TenantID: null,
    ZipCodeID: null,
    ManagementID: null,
    ActivityUserID: null
}


export default function AddressUpdate({ setReload }) {
    const navigate = useNavigate()
    const params = useParams()
    const idAddress = params.idAddress
    const token = getToken()

    const [zipcode, setZipcode] = useState<Array<IZipCode>>([])
    const [managementID, setManagementID] = useState<number | null>(null)
    const [ownerID, setOwnerID] = useState<number | null>(null)
    const [tenantID, setTenantID] = useState<number | null>(null)

    const [managementSelected, setManagementSelected] = useState<IManagement | null>(null)
    const [ownerSelected, setOwnerSelected] = useState<IOwner | null>(null)
    const [tenantSelected, setTenantSelected] = useState<ITenant | null>(null)


    const { register, formState: { errors }, handleSubmit, setValue, watch } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {

        reqGet('zipcode').then((result) => {
            setZipcode(result)

            reqGet('address/' + idAddress).then(function (res) {
                setValue("ActivityUserID", token.UserID)

                setValue("AddressLine", res.AddressLine)
                setValue("AddressComplementary", res.AddressComplementary)
                setValue("ZipCodeID", res.ZipCodeID)
                setValue("ManagementID", res.ManagementID)
                setValue("OwnerID", res.OwnerID)
                setValue("TenantID", res.TenantID)

                setManagementID(res.ManagementID)
                setOwnerID(res.OwnerID)
                setTenantID(res.TenantID)
            })

        })
    }, [])



    const onSubmit: SubmitHandler<IFormInput> = data => {

        data.ManagementID = managementSelected?.ManagementID || null
        data.OwnerID = ownerSelected?.OwnerID || null
        data.TenantID = tenantSelected?.TenantID || null

        reqPut('address/' + idAddress, data).then((res) => {
            setReload(true)
            navigate('/address/' + idAddress)
        })
    };

    return <div className="body-container">
        {HeaderRow(
            <h3 className="section-title">Modifier l'adresse</h3>,
            <Link to={'/address/' + idAddress} className="link-nav">{'< Retour'}</Link>,
        )}

        <div className="body container-fluid">

            <div className="row justify-content-center h-100">

                <div className="col-xl-6">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-3">
                            <label className="form-label">Adresse *</label>

                            <div className="input-group">

                                <input className={"form-control w-75 " + (errors?.AddressLine ? "is-invalid" : "")} placeholder="Rue" {...register("AddressLine")} />

                            </div>

                            {errors.AddressLine?.message && <p className="input-msg-error">{errors.AddressLine?.message}</p>}


                        </div>

                        <div className="row mb-3">

                            <div className="col-6">

                                <label className="form-label">Code postal *</label>

                                <select className={"form-select " + (errors?.ZipCodeID ? "is-invalid" : "")} {...register("ZipCodeID")}>

                                    <option hidden> </option>

                                    {zipcode.map((z: IZipCode, k: number) => {
                                        return <option key={k} value={z.ZipCodeID}>{z.ZipCode} {z.City}</option>;
                                    })}

                                </select>

                                {errors.ZipCodeID?.message && <p className="input-msg-error">{errors.ZipCodeID?.message}</p>}

                            </div>

                            <div className="col-6">

                                <label className="form-label">Complément d'adresse</label>

                                <input className={"form-control " + (errors?.AddressComplementary ? "is-invalid" : "")} {...register("AddressComplementary")} />

                                {errors.AddressComplementary?.message && <p className="input-msg-error">{errors.AddressComplementary?.message}</p>}

                            </div>

                        </div>


                        <div className="row row-cols-2 mb-3">

                            <div className="col">
                                <ManagementInputSearch initManagementID={managementID} onChange={(management) => setManagementSelected(management)} />
                            </div>

                            <div className="col">
                                <OwnerInputSearch initOwnerID={ownerID} onChange={(owner) => setOwnerSelected(owner)} />
                            </div>

                        </div>


                        <div className="row row-cols-2 mb-3">
                            <div className="col">
                                <TenantInputSearch initTenantID={tenantID} onChange={(tenant) => setTenantSelected(tenant)} />
                            </div>
                        </div>

                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>

            </div>

        </div>
    </div>
}