import React from "react"
import { Link } from "react-router-dom"

export default function HelpDocumentConvention() {
    return <>
        <h2 className="section-title">Convention collectives</h2>

        <h4>Document de la CCT</h4>
        <p>01.03.2024</p>
        <a href={"../../documents/general/CCT_01032024.pdf"} target="_blank" className="link-nav mb-5">Consulter ici</a>

        

        <h4>Travail entre 6h et 7h</h4>
        <a href={"../../documents/general/Travail_entre_6-7.pdf"} target="_blank" className="link-nav">Consulter ici</a>
    </>
}