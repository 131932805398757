import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import $ from 'jquery'

import { IAddress, IObservation } from "../../common/types";
import { reqGet, reqPost } from "../../../service/apiRequest";
import { getToken } from "../../../useToken";
import { GROUP, LAYOUT } from "../../common/constants";
import { ServiceBadge, dateFormat } from "../../common/common";

export default function AccountSurveyContent() {
    const navigate = useNavigate()
    const params = useParams()
    const idAddress = params.idAddress
    const location = useLocation()

    const token = getToken()

    const data = useOutletContext<Array<IAddress>>()

    const [address, setAddress] = useState<IAddress>()
    const [observations, setObservations] = useState<Array<IObservation>>([])

    useEffect(() => {
        // Check id validity
        if (!data.find(a => a.AddressID === parseInt(idAddress ?? "0"))) {
            navigate("/account/survey")
        }

        reqGet("address/" + idAddress).then(res => {
            setAddress(res)

            reqGet("observation/workbook/" + res.WorkbookID).then(r => {
                setObservations(r)
            })
        })

    }, [idAddress])


    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() <= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/survey") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    return <div className="body-container">

        <header className="mb-3">
            <h4 className="section-title mb-0">{address?.AddressLine}, {address?.ZipCode} {address?.City}</h4>
            <h5 className="section-subtitle mb-0">{address?.AddressComplementary}</h5>

            <div className="d-flex justify-content-between">
                <Link className={display + " link-nav"} to={"/survey"}>{"<"} Retour</Link>

                <Link className="link-nav" to={"/address/" + idAddress}>Aller à l'adresse {">"}</Link>
            </div>
        </header>

        <section className="body">

            {observations?.length > 0 &&
                <>
                    <h3 className="section-title-2">{observations.length > 1 ? "Dernières surveillances" : "Dernière surveillance"}</h3>

                    {observations?.map(o => {
                        return <div className="card" key={o.ObservationID}>
                            <section className="card-body">

                                <div className="row row-cols-3 mb-3">
                                    <div className="col">
                                        <h6 className="mb-0">Type</h6>
                                        <p className="mb-0"><ServiceBadge service={o.Service ?? ""} /></p>
                                    </div>

                                    <div className="col">
                                        <h6 className="mb-0">Technicien</h6>
                                        <p className="mb-0">{o?.FirstName} {o?.Name}</p>
                                    </div>

                                    <div className="col">
                                        <h6 className="mb-0">Date</h6>
                                        <p className="mb-0">{dateFormat(o?.ObservationDate, "dd/MM/yyyy")}</p>
                                    </div>
                                </div>


                                <h6 className="mb-0">Remarque</h6>
                                <p className="mb-0">{o?.Note || "Aucun commentaire"} </p>
                            </section>
                        </div>
                    })}
                </>
            }

        </section>
    </div>
}