import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'

import { StorageAvailabilityBadge } from "../storage/storage"
import { reqGet } from "../../../service/apiRequest";
import { Header, PrivateComponent } from '../../common/common';
import { GROUP } from '../../common/constants';
import { IStorageProduct } from "../../common/types"

export default function WarehouseOutside() {
    const params = useParams()
    const idWarehouse = params.idWarehouse
    const [outside, setOutside] = useState<Array<IStorageProduct>>([])

    useEffect(() => {
        if (idWarehouse) {
            reqGet("storage/warehouse/" + idWarehouse + "/outside").then(res => {
                setOutside(res)
            })
        }
    }, [idWarehouse])

    return <main className="body-container content">

        {Header(
            <h2 className='section-title'>Stock sorti</h2>,
            <Link to={"/warehouse/" + idWarehouse} className="link-nav">{"<"} Retour</Link>
        )}

        <section className="body">

            <div className="row h-100">
                <div className="col-lg-6 col-xxl-4 col-h-fill">

                    <article className="card card-fill">

                        <header className="card-header">
                            <h2 className="card-title">Liste des emplacements</h2>
                        </header>

                        <section className='card-body'>

                            {outside.length > 0 ?
                                <div className="list-group list-group-flush">
                                    {outside.map(storage => {
                                        return <div className="list-group-item" key={storage.StorageID}>
                                            <div className="row">

                                                <div className="col-5 col-lg-6 ps-0">
                                                    <h6 className="item-title text-truncate mb-0">{storage.ProductModel}</h6>

                                                    <Link to={"/warehouse/" + idWarehouse + "/storage/" + storage.StorageID} className="link-nav d-inline me-2">{storage.Rack}{storage.Row}{storage?.Col ? "-" + storage.Col : ""}{storage?.Compartment ? "-" + storage.Compartment : ""}</Link>

                                                    <p className="item-subtitle d-inline me-2">{storage.ProductReference}</p>
                                                    <p className="item-subtitle d-none d-lg-inline">{storage.ProductBrand}</p>
                                                </div>

                                                <div className="col-6 px-0 text-end">
                                                    {StorageAvailabilityBadge(storage.ProductAvailable)}
                                                    <p className="mb-0">{storage.ProductLocation}</p>
                                                </div>

                                            </div>
                                        </div>
                                    })}
                                </div> :
                                <p className="text-center text-muted">Touts les éléments de l'entrepôt sont en stock</p>
                            }

                        </section>

                        <footer className='card-footer text-center'>
                            <p className="text-muted mb-0">{outside.length} éléments</p>
                        </footer>

                    </article>

                </div>
            </div>
        </section>
    </main>
}