import React, { useState, useEffect, useMemo } from "react"
import { Link, useLocation, useParams } from "react-router-dom";
import $ from 'jquery'

import { reqGet } from "../../../service/apiRequest";
import { Header, fuzzyFilter, fuzzySort } from "../../common/common";
import { MONTH_FR } from "../../common/constants"
import { IActivity } from "../../common/types"

import {
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef
} from '@tanstack/react-table'

export default function WarehouseActivity() {
    const params = useParams()
    const idWarehouse = params.idWarehouse
    const currentDate = new Date()
    const defaultDateFormat = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1) < 10 ? "0" + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)}-${currentDate.getDate() < 10 ? "0" + currentDate.getDate() : currentDate.getDate()}`

    const [data, setData] = useState<Array<IActivity>>([])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')

    const [periodFilter, setPeriodFilter] = useState<string>("date")
    const [year, setYear] = useState<number>(currentDate.getFullYear())
    const [month, setMonth] = useState<number>(currentDate.getMonth() + 1)
    const [week, setWeek] = useState<number>(0)
    const [date, setDate] = useState<string>(defaultDateFormat)

    const columns = useMemo<ColumnDef<IActivity, any>[]>(
        () => [
            {
                accessorFn: row => row.DateTime,
                id: 'DateTime',
                header: 'Date',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Type,
                id: 'Type',
                header: 'Type',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Action,
                id: 'Action',
                header: 'Action',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.FirstName,
                id: 'FirstName',
                header: 'Prénom',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Name,
                id: 'Name',
                header: 'Nom',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Description,
                id: 'Description',
                header: 'Description',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,

        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        table.setPageSize(100)
    }, [])

    useEffect(() => {
        if (periodFilter == "month" && month) {

            reqGet("activity/warehouse/" + idWarehouse + "/month/" + month + "/year/" + year).then(res => {
                setData(res);

            }, rej => {
                setData([])
            })
        }
    }, [periodFilter, month, year])

    useEffect(() => {
        if (periodFilter == "week" && week) {

            reqGet("activity/warehouse/" + idWarehouse + "/week/" + week + "/year/" + year).then(res => {
                setData(res);

            }, rej => {
                setData([])
            })
        }
    }, [periodFilter, week, year])

    useEffect(() => {
        if (periodFilter == "date" && date) {

            reqGet("activity/warehouse/" + idWarehouse + "/date/" + date).then(res => {
                setData(res);

            }, rej => {
                setData([])
            })
        }
    }, [periodFilter, date])

    function handlePeriodFilter(btn) {
        $("#periodFilters .btn.active").removeClass("active")
        $(btn).addClass("active")

        if ($(btn).val() == "month") {
            setYear(currentDate.getFullYear())
            setMonth(currentDate.getMonth() + 1)
            setPeriodFilter("month")

        } else if ($(btn).val() == "week") {

            var today = new Date()
            var oneJan = new Date(today.getFullYear(), 0, 1);
            let weekNb = Math.ceil((((today.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7);

            setWeek(weekNb)

            setPeriodFilter("week")

        } else if ($(btn).val() == "date") {
            setDate(defaultDateFormat)
            setPeriodFilter("date")
        }
    }

    function handleActivityFilter(btn) {
        $("#activityFilters .btn.active").removeClass("active")
        $(btn).addClass("active")

        table.setColumnFilters([{ id: 'Action', value: $(btn).val() }])
    }

    function HandleNextDate() {
        let d = new Date(date)
        // increment date
        d.setTime(d.getTime() + 1000 * 60 * 60 * 24)

        setDate(d.toISOString().split("T")[0])
        $("#date").val(d.toISOString().split("T")[0])
    }

    function HandlePreviousDate() {
        let d = new Date(date)
        // decrement date
        d.setTime(d.getTime() - 1000 * 60 * 60 * 24)

        setDate(d.toISOString().split("T")[0])
        $("#date").val(d.toISOString().split("T")[0])
    }

    return <div className="body-container content">
        <header className="header">
            <h3 className="section-title mb-0">Activité</h3>

            <Link to={"/warehouse/" + idWarehouse} className="link-nav d-md-none bm-3">{"<"} Retour</Link>
        </header>


        <article className="card card-flush card-fill">


            <div id="periodFilters" className="d-flex justify-content-center mb-3">
                <button className="btn btn-outline active" value="date" onClick={(e) => handlePeriodFilter(e.currentTarget)}>
                    <i className="bi bi-calendar2-day me-1"></i> Date
                </button>
                <button className="btn btn-outline ms-3" value="week" onClick={(e) => handlePeriodFilter(e.currentTarget)}>
                    <i className="bi bi-calendar2-week me-1"></i> Semaine
                </button>
                <button className="btn btn-outline ms-3" value="month" onClick={(e) => handlePeriodFilter(e.currentTarget)}>
                    <i className="bi bi-calendar2-month me-1"></i> Mois
                </button>
            </div>

            <div className="d-flex justify-content-center align-items-center mb-2" style={{ height: "3rem" }}>

                {periodFilter == "month" &&
                    <div className="col-8 text-center d-flex justify-content-between align-items-start">
                        <select className="form-select" name="select-month" id="month" defaultValue={month} onChange={(e) => setMonth(parseInt(e.target.value))}>
                            {MONTH_FR.map((m, k) => {
                                return <option key={k} value={(k + 1)}>{m}</option>
                            })}
                        </select>

                        <input className="form-control ms-3" type="number" name="control-year" id="year" defaultValue={year} min={2022} max={currentDate.getFullYear()} onChange={(e) => setYear(parseInt(e.target.value))} />
                    </div>
                }

                {periodFilter == "week" &&
                    <div className="col-10 col-md-8 text-center d-flex justify-content-start align-items-start">
                        <div className="d-flex flex-grow-1 align-items-center">
                            <input className="form-control" type="number" name="control-week" id="week" defaultValue={week} min={1} max={52} onChange={(e) => { setWeek(parseInt(e.target.value)); }} />
                        </div>

                        <div className="d-flex flex-grow-1">
                            <input className="form-control ms-3" type="number" name="control-year" id="year" defaultValue={year} min={2022} max={currentDate.getFullYear()} onChange={(e) => setYear(parseInt(e.target.value))} />
                        </div>
                    </div>
                }


                {periodFilter == "date" &&
                    <nav className="col-8 text-center d-flex justify-content-start align-items-center">

                        <button
                            className="btn btn-outline d-inline rounded"
                            onClick={() => HandlePreviousDate()}
                        >
                            <i className="bi bi-caret-left"></i>
                        </button>

                        <input className="form-control mx-3" type="date" name="control-year" id="date" defaultValue={date} max={defaultDateFormat} onChange={(e) => setDate(e.target.value)} />

                        <button
                            className="btn btn-outline d-inline rounded" id="btnNext"
                            onClick={() => HandleNextDate()}
                        >
                            <i className="bi bi-caret-right"></i>
                        </button>
                    </nav>
                }
            </div>


            <nav className="nav-horizontal pb-3" id="activityFilters">
                <button className="btn nav-link active" value="" onClick={(e) => handleActivityFilter(e.currentTarget)}>
                    Tout
                </button>
                <button className="btn nav-link ms-3" value="Inventaire" onClick={(e) => handleActivityFilter(e.currentTarget)}>
                    Inventaire
                </button>
                <button className="btn nav-link ms-3" value="Réapprovisionnement" onClick={(e) => handleActivityFilter(e.currentTarget)}>
                    Réapprovisionnement
                </button>
                <button className="btn nav-link ms-3" value="Retrait" onClick={(e) => handleActivityFilter(e.currentTarget)}>
                    Retrait
                </button>
                <button className="btn nav-link ms-3" value="Stockage" onClick={(e) => handleActivityFilter(e.currentTarget)}>
                    Stockage
                </button>
                <button className="btn nav-link ms-3" value="Réparation" onClick={(e) => handleActivityFilter(e.currentTarget)}>
                    Réparation
                </button>
            </nav>

            <section className="card-body">
                {
                    data.length > 0 ?
                        <div className="list">
                            {table.getRowModel().rows.map(row => {
                                return <div className="list-item" key={row.original.ActivityID}>
                                    <ActivityItem activity={row.original} />
                                </div>
                            })}
                        </div> :
                        <p className="text-muted text-center mt-5">Aucune activité pour cette période</p>
                }

            </section>

            <footer className="card-footer d-flex justify-content-between align-items-end pagination">
                <div className="">
                    <button className="btn btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                        {'<<'}
                    </button>
                    <button className="btn btn-outline me-3" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                        {'<'}
                    </button>

                    <p className="d-inline mb-0 me-3">
                        {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                    </p>

                    <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                        {'>'}
                    </button>
                    <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                        {'>>'}
                    </button>
                </div>

                <div>
                    <select className="form-select" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                        {[100, 200].map(pageSize => (

                            <option key={pageSize} value={pageSize}>
                                Montrer {pageSize}
                            </option>

                        ))}

                    </select>
                </div>
            </footer>
        </article>
    </div>
}


function ActivityItem({ activity }: { activity: IActivity }) {
    const location = useLocation()
    const icon = () => {
        if (activity.Type == "Réparation") {
            return "wrench-adjustable-circle"

        } else if (activity.Action.includes("Inventaire")) {
            return "ui-checks"

        } else if (activity.Type == "Stockage") {
            if (activity.Action.includes("Retrait") || activity.Action.includes("Déplacement")) {
                return "box-arrow-up"
            } else if (activity.Action.includes("Dépôt")) {
                return "box-arrow-in-down"
            } else {
                return "grid-3x3-gap"
            }

        } else if (activity.Type == "Réparation") {
            return "wrench-adjustable-circle"

        } else if (activity.Action.includes("Réapprovisionnement")) {
            return "box-seam"

        } else {
            return ""
        }
    }
    return <>
        <div className="list-item-start h-100" style={{ width: "2.5rem" }}>
            <i className={"bi bi-" + icon()} style={{ fontSize: "1.8rem" }}></i>
        </div>

        <section className="list-item-data">
            <div className="row d-flex">

                <div className="col-7">
                    <h6 className="text-truncate">{activity?.Action}</h6>
                    <p className="text-truncate-2 mb-0">{activity?.Description}</p>
                </div>

                <div className="col-5 ps-0 text-end">
                    <h6 className="mb-0">{activity.DateTime.split(",")[0]}</h6>
                    <h6>{activity.DateTime.split(",")[1]}</h6>
                    <p className="text-truncate mb-0">{activity.FirstName} {activity.Name}</p>
                </div>

            </div>
            {activity.Action == "Réapprovisionnement" &&
                <div>
                    <Link to={"/activity/" + activity.ActivityID} state={{ path: location.pathname }} className="link-nav">Voir détails {">"}</Link>
                </div>
            }
        </section>

        <div className="tool-more d-md-none">
            <i className="bi bi-chevron-right"></i>
        </div>
    </>
}