import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import $ from "jquery"

import { Header, ButtonSubmit } from "../../common/common";
import { getToken } from "../../../useToken";
import { IStorageProduct, IRepair } from "../../common/types";
import { reqGet, reqPost, reqPut } from "../../../service/apiRequest";

export default function StorageRepair({ reload, setReload }) {
    const navigate = useNavigate()
    const params = useParams()
    const idStorage = params.idStorage
    const idWarehouse = params.idWarehouse
    const token = getToken()

    const [storage, setStorage] = useState<IStorageProduct>()
    const [lastRepair, setLastRepair] = useState<IRepair>()
    const [actualRepair, setActualRepair] = useState<IRepair>()

    useEffect(() => {
        reqGet("storage/" + idStorage).then(res => {
            setStorage(res)

            reqGet("repair/product/" + res.ProductID + "/last").then(r => {
                setLastRepair(r)

            }, () => { })

            reqGet("repair/product/" + res.ProductID + "/actual").then(r => {
                setActualRepair(r)

            }, () => { })
        })
    }, [idStorage])


    useEffect(() => {
        if (reload) {
            reqGet("storage/" + idStorage).then(res => {
                setStorage(res)

                reqGet("repair/product/" + res.ProductID).then(r => {
                    setLastRepair(r)
                })
            })
            setReload(false)
        }

    }, [reload])


    function SendLocation() {
        var data = {
            ...storage,
            RepairLocation: $("#RepairLocation").val(),
            ActivityUserID: token.UserID,
            WarehouseID: idWarehouse
        }

        reqPost("repair", data).then((res) => {
            setReload(true)
            navigate("/warehouse/" + idWarehouse + "/storage/" + idStorage)
        })
    }

    function ReturnStock() {
        if (actualRepair?.RepairID) {
            var data = {
                ...storage,
                RepairID: actualRepair?.RepairID,
                RepairNote: $("#RepairNote").val(),
                ActivityUserID: token.UserID,
                WarehouseID: idWarehouse
            }

            reqPut("repair/" + actualRepair?.RepairID, data).then((res) => {
                setReload(true)
                navigate("/warehouse/" + idWarehouse + "/storage/" + idStorage)
            })
        }
    }


    return <>

        {Header(
            <h2 className="section-title">{storage?.Rack}{storage?.Row}-{storage?.Col}{storage?.Compartment != null ? ("-" + storage?.Compartment) : ""}: Réparation</h2>,
            <Link to={"/warehouse/" + idWarehouse + "/storage/" + idStorage} className="link-nav">{"<"} Retour</Link>
        )}

        <div className="body container-fluid">

            {lastRepair &&
                <>
                    <h4 className="section-title-2">Dernière réparation</h4>

                    <div className="row row-cols-2 mb-3">

                        <div className="col">
                            <div className="card h-100">
                                <header className="card-header">
                                    <h5 className="card-title">Lieux</h5>

                                    <h5 className="card-title">{lastRepair?.DateSent}</h5>
                                </header>

                                <section className="card-body">
                                    <p className="mb-0">{lastRepair?.RepairLocation} </p>
                                </section>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100">
                                <header className="card-header">
                                    <h5 className="card-title">Retour</h5>

                                    <h5 className="card-title">{lastRepair?.DateReturn}</h5>
                                </header>

                                <section className="card-body">
                                    <p className="mb-0">{lastRepair?.RepairNote || "Aucun commentaire"} </p>
                                </section>
                            </div>
                        </div>

                    </div>
                </>}

            {!actualRepair &&
                <>
                    <h4 className="section-title-2">Envoyer en réparation</h4>
                    <div>
                        <label htmlFor="" className="form-label">Lieux de réparation *</label>
                        <input className="form-control mb-3" id="RepairLocation" type="text" />

                        <div className="text-end">
                            <ButtonSubmit onClick={() => SendLocation()} />
                        </div>
                    </div>
                </>
            }



            {actualRepair &&
                <>
                    <h4 className="section-title-2">Actuellement en réparation</h4>
                    <div className="card bg-info flex-row justify-content-between">
                        <div className="d-flex align-items-center justify-content-center me-3">
                            <i className="bi bi-info-circle" style={{ fontSize: "1.5rem" }}></i>
                        </div>
                        <div className="d-flex flex-column flex-grow-1">
                            <h5 className="card-title">En réparation depuis le {actualRepair.DateSent}</h5>
                            <p className="mb-0">{actualRepair.RepairLocation}</p>
                        </div>
                    </div>

                    <div>
                        <h4 className="section-title-2">Retour en stock</h4>

                        <label htmlFor="" className="form-label">Commentaire</label>
                        <input className="form-control mb-3" id="RepairNote" type="text" />

                        <div className="text-end">
                            <ButtonSubmit onClick={() => ReturnStock()} />
                        </div>
                    </div>
                </>
            }
        </div>
    </>
}