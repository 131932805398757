import React, { useEffect } from 'react'
import { faker } from '@faker-js/faker'

export default function ConstructionIndex() {
    faker.locale = 'fr_CH'

    return <>

        <div className="d-flex justify-content-between align-items-start">
            <h3 className="section-title">Accueil</h3>
        </div>

        <div className="row">

            <div className="col-md-6 col-xxl-4">

                <div className="card mb-3">

                    <div className="card-header">
                        <h5 className="card-title">Données</h5>
                    </div>

                    <div className="card-body">

                        <dl className="row mb-0">
                            <dt className="col-sm-6">Responsable</dt>
                            <dd className="col-sm-6">{faker.name.findName()}</dd>

                            <dt className="col-sm-6">Technicien de suivi</dt>
                            <dd className="col-sm-6">{faker.name.findName()}</dd>

                            <dt className="col-sm-6">Employé exécutant</dt>
                            <dd className="col-sm-6">{faker.name.findName()}</dd>

                            <dt className="col-sm-6">Architecte</dt>
                            <dd className="col-sm-6">{faker.name.findName()}</dd>

                            <dt className="col-sm-6">Sanitaire</dt>
                            <dd className="col-sm-6">{faker.name.findName()}</dd>

                            <dt className="col-sm-6 mb-0">Mec de l'aeration</dt>
                            <dd className="col-sm-6">{faker.name.findName()}</dd>
                        </dl>

                    </div>

                </div>

                <div className="row">

                    <div className="col-6">

                        <div className="card">

                            <div className="card-header">
                                <p className="card-title">Référence chantier</p>
                            </div>

                            <div className="card-body">
                                <h3 className="mb-0">RV 20_</h3>
                            </div>

                        </div>

                    </div>

                    <div className="col-6">

                        <div className="card">

                            <div className="card-header">
                                <p className="card-title">N° Abacus</p>
                            </div>

                            <div className="card-body">
                                <h3 className="mb-0">106_</h3>
                            </div>

                        </div>

                    </div>

                    <div className="col-6">

                        <div className="card mb-0">

                            <div className="card-header">
                                <p className="card-title">Date de début</p>
                            </div>

                            <div className="card-body">
                                <h3 className="mb-0">23.05.2022</h3>
                            </div>

                        </div>
                    </div>

                    <div className="col-6">

                        <div className="card mb-0">

                            <div className="card-header">
                                <p className="card-title">Date de fin</p>
                            </div>

                            <div className="card-body">
                                <h3 className="mb-0"></h3>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div className="col-md-6 col-xxl-4">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title mb-1">Plan actuel</h5>
                    </div>

                    <div className="card-body d-flex justify-content-between">
                        <div>
                            <h6 className="item-title">{faker.company.catchPhrase()}</h6>
                            <p className="item-subtitle">18.04.2022</p>
                        </div>
                        <button className="btn btn-tools">Ouvrir</button>
                    </div>

                </div>

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title mb-1">Persement</h5>
                    </div>

                    <div className="card-body d-flex justify-content-between">
                        <div>
                            <h6 className="item-title">{faker.company.catchPhrase()}</h6>
                            <p className="item-subtitle">27.02.2022</p>
                        </div>
                        <button className="btn btn-tools">Ouvrir</button>
                    </div>

                </div>
            </div>

            <div className="col-md-6 col-xxl-4">

                <div className="card">

                    <div className="card-header">
                        <h5 className="card-title">Etat actuel</h5>
                    </div>

                    <div className="card-body">

                        <dl className="row mb-0">
                            <dt className="col-sm-8">Données de base</dt>
                            <dd className="col-sm-4">Terminé</dd>

                            <dt className="col-sm-8">Coordination</dt>
                            <dd className="col-sm-4">Terminé</dd>

                            <dt className="col-sm-8">Exécution</dt>
                            <dd className="col-sm-4">En cours</dd>

                            <dt className="col-sm-8">Raccordement éléctrique</dt>
                            <dd className="col-sm-4">En cours</dd>

                            <dt className="col-sm-8">Mise en service</dt>
                            <dd className="col-sm-4">En attente</dd>

                            <dt className="col-sm-8">Fin de chantier</dt>
                            <dd className="col-sm-4">En attente</dd>

                            <dt className="col-sm-8">Retouche</dt>
                            <dd className="col-sm-4">En attente</dd>

                            <dt className="col-sm-8">Contrat d'entretien/maintenance</dt>
                            <dd className="col-sm-4">En attente</dd>
                        </dl>

                    </div>

                </div>

            </div>

        </div>




    </ >
}