import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { reqGet, reqPut } from '../../../service/apiRequest'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import $ from 'jquery'
import { getToken } from '../../../useToken'
import { IStorage, IStorageProduct, IProduct } from "../../common/types"
import { HeaderRow, ButtonSubmit, ProductInfoFormatter } from '../../common/common'
import { ProductSelector } from './storage-add';
import { PRODUCT_AVAILABLE, PRODUCT_CLASS_ID } from '../../common/constants';

interface IFormInput extends IStorage {
    ActivityUserID: number | null;
}

const validationSchema = yup.object({
    Rack: yup.string().required("Ce champ est obligatoire"),
    Row: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    Col: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    Compartment: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ProductID: yup.string().nullable(),
    Quantity: yup.string().nullable(),
    QuantityMin: yup.string().nullable(),
    QuantityMax: yup.string().nullable()

}).required();

const initialValues = {
    WarehouseID: 0,
    Rack: "",
    Row: 0,
    Col: 0,
    Compartment: null,
    ProductID: null,
    Quantity: null,
    QuantityMin: null,
    QuantityMax: null
}

export default function StorageUpdate({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idWarehouse = params.idWarehouse
    const idStorage = params.idStorage

    const [storage, setStorage] = useState<IStorageProduct>()
    const [product, setProduct] = useState<IProduct>()

    const { register, formState: { errors }, handleSubmit, watch, setValue } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        if (idStorage) {
            setValue("StorageID", parseInt(idStorage))
            setValue("ActivityUserID", token.UserID)

            reqGet("storage/" + idStorage).then(res => {
                setStorage(res)

                $("#inputSelectedProduct").val(ProductInfoFormatter(res))

                setValue("ProductID", res.ProductID)
                setValue("Rack", res.Rack)
                setValue("Row", res.Row)
                setValue("Col", res.Col)
                setValue("Compartment", res.Compartment)
                setValue("Quantity", res.Quantity)
                setValue("QuantityMin", res.QuantityMin)
                setValue("QuantityMax", res.QuantityMax)
                setValue("WarehouseID", res.WarehouseID)
            })
        }
    }, [idStorage])

    // when product selected
    useEffect(() => {
        if (product) {
            setProductID(product?.ProductID)

            $("#inputSelectedProduct").val(ProductInfoFormatter(product))
        }
    }, [product])


    function setProductID(productID) {
        setValue("ProductID", productID)
    }

    function RemoveProduct() {
        setProductID(null)

        $("#inputSelectedProduct").val("")
    }

    const onSubmit: SubmitHandler<IFormInput> = data => {

        if (product?.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE) {
            data.ProductAvailable = 0
        } else if (product?.ProductClassID == PRODUCT_CLASS_ID.REUSABLE) {
            data.ProductAvailable = PRODUCT_AVAILABLE.IN_STOCK
            data.Quantity = null
            data.QuantityMin = null
            data.QuantityMax = null
        }

        data.Rack = data.Rack.toUpperCase()

        reqPut('storage/' + idStorage, data).then((res) => {
            setReload(true)
            navigate("/warehouse/" + idWarehouse + "/storage/" + idStorage)
        })
    };

    return (
        <section className="body-container h-100">
            {HeaderRow(
                <h3 className="section-title">Modifier l'emplacement de stockage</h3>,
                <Link to={"/warehouse/" + idWarehouse + "/storage/" + idStorage} className="link-nav">{'<'} Retour</Link>,
            )}

            <section className="body container-fluid">
                
                <div className="row h-100 justify-content-center">


                    <div className="col-md-7 px-0">

                        <section className="row row-cols-2 row-cols-md-4 mb-3">

                            <div className="col">
                                <label className="form-label">Etagère *</label>

                                <div className="input-group">

                                    <input className={"form-control " + (errors?.Rack ? "is-invalid" : "")} placeholder="A, B, C..." {...register("Rack")} />
                                </div>

                                {errors.Rack?.message && <p className="input-msg-error">{errors.Rack?.message}</p>}
                            </div>

                            <div className="col">
                                <label className="form-label">Rangée *</label>

                                <div className="input-group">

                                    <input type="number" className={"form-control " + (errors?.Row ? "is-invalid" : "")} placeholder="1, 2, 3..." {...register("Row")} />
                                </div>

                                {errors.Row?.message && <p className="input-msg-error">{errors.Row?.message}</p>}
                            </div>

                            <div className="col">
                                <label className="form-label">Colonne *</label>

                                <div className="input-group">

                                    <input type="number" className={"form-control " + (errors?.Col ? "is-invalid" : "")} placeholder="1, 2, 3..." {...register("Col")} />
                                </div>

                                {errors.Col?.message && <p className="input-msg-error">{errors.Col?.message}</p>}
                            </div>

                            <div className="col">
                                <label className="form-label">Compartiment</label>

                                <div className="input-group">

                                    <input type="number" className={"form-control " + (errors?.Compartment ? "is-invalid" : "")} placeholder="1, 2, 3..." {...register("Compartment")} />
                                </div>

                                {errors.Compartment?.message && <p className="input-msg-error">{errors.Compartment?.message}</p>}
                            </div>
                        </section>


                        {/* PRODUCT SELECTED */}
                        <div className="mt-5 mb-3">
                            <div className="row">
                                <div className="col-7">
                                    <label htmlFor="inputSelectedProduct" className="form-label mb-2">Selection</label>
                                </div>

                                <div className="col-5 text-end">
                                    <button className="btn btn-outline" disabled={watch("ProductID") ? false : true} onClick={(e) => { e.preventDefault(); RemoveProduct() }}>
                                        <i className="bi bi-eraser"></i> Retirer la pièce
                                    </button>
                                </div>

                                <div className="col-12 mt-3">
                                    <input type="text" className="form-control mb-0" id="inputSelectedProduct" disabled />
                                </div>
                            </div>

                        </div>

                        {product?.ProductClassID == PRODUCT_CLASS_ID.REUSABLE && product?.StorageID &&
                            <div>
                                <p className="text-danger"><i className="bi bi-exclamation-triangle me-1"></i> Cet outil est déjà stocké à {product.WarehouseName}, {product?.Rack}{product?.Row}-{product?.Col}{product?.Compartment != null ? ("-" + product?.Compartment) : ""}. Vous ne pouvez le stocké qu'à un seul endroit à la fois</p>
                            </div>
                        }


                        {<ProductSelector setProduct={setProduct} product={storage} />}

                        {
                            ((storage?.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE && !product) || product?.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE) &&
                            <section className="row row-cols-5 mb-3">

                                {product?.ProductID != storage?.ProductID &&
                                    <div className="col">
                                        <label className="form-label">Quantité</label>

                                        <div className="input-group">

                                            <input type="number" className={"form-control " + (errors?.Quantity ? "is-invalid" : "")} {...register("Quantity")} />
                                        </div>

                                        {errors.Row?.message && <p className="input-msg-error">{errors.Quantity?.message}</p>}
                                    </div>
                                }

                                <div className="col">
                                    <label className="form-label">Minimum</label>

                                    <div className="input-group">

                                        <input type="number" className={"form-control " + (errors?.QuantityMin ? "is-invalid" : "")} {...register("QuantityMin")} />
                                    </div>

                                    {errors.QuantityMin?.message && <p className="input-msg-error">{errors.QuantityMin?.message}</p>}
                                </div>

                                <div className="col">
                                    <label className="form-label">Maximum</label>

                                    <div className="input-group">

                                        <input type="number" className={"form-control " + (errors?.QuantityMax ? "is-invalid" : "")} {...register("QuantityMax")} />
                                    </div>

                                    {errors.QuantityMax?.message && <p className="input-msg-error">{errors.QuantityMax?.message}</p>}
                                </div>
                            </section>
                        }



                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit onClick={handleSubmit(onSubmit)} disabled={(product?.ProductClass == "Tool" && product?.StorageID) ? true : false} />
                        </div>

                    </div>

                </div>

            </section>
        </section >
    );
}

