import React, { useState, useEffect, useMemo } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { IObservation } from '../../../common/types'
import { reqGet } from '../../../../service/apiRequest'
import { HeaderRow, DebouncedInput, fuzzyFilter, fuzzySort, PrivateLinkAdd, ServiceBadge, dateFormat, Header } from '../../../common/common'

import {
    Column,
    Table,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    flexRender
} from '@tanstack/react-table'

export default function Workbook() {
    const location = useLocation()
    const params = useParams()
    const idAddress = params.idAddress

    const table = WorkbookTable()

    const LinkBack = () => {
        if (location.state?.path)
            return location.state.path
        else
            return '/address/' + idAddress
    }

    return <section className="body-container content">

        <header className="header">
            <div>
                <h3 className="section-title">Carnet de chaufferie</h3>
                <Link to={LinkBack()} className="link-nav">{'<'} Retour</Link>
            </div>

            {PrivateLinkAdd("add")}
        </header>

        <div className="container-fluid">
            {table.getHeaderGroups().map(headerGroup => (
                <div className="row row-cols-2 row-cols-xl-4" key={headerGroup.id}>

                    {headerGroup.headers.map((header, k) => {
                        return (
                            <div className="col mb-3 mb-xl-0" key={k}>

                                {header.isPlaceholder ? null : (
                                    <>

                                        <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: header.column.getToggleSortingHandler() }}>

                                            {flexRender(header.column.columnDef.header, header.getContext())}

                                            {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[header.column.getIsSorted() as string] ?? null}

                                        </h6>

                                        {header.column.getCanFilter() ? (
                                            <div className="d-flex flex-direction-column">
                                                <Filter column={header.column} table={table} />
                                            </div>
                                        ) : null}

                                    </>
                                )}
                            </div>
                        )
                    })}

                </div>
            ))}
        </div>

        <section className="d-flex flex-column flex-grow-1 container-fluid overflow-auto">

            <section className="list mt-3">
                {table.getRowModel().rows.map(row => {
                    const observation: IObservation = row.original
                    return (

                        <div className="list-item" key={observation.ObservationID}>
                            <div className="list-item-data px-0">

                                <div className="row">

                                    <div className='col-5 col-lg-3 col-xl-2'>
                                        <ServiceBadge service={observation?.Service ?? ""} />
                                    </div>

                                    <div className='col-7 col-lg-4 col-xl-3 text-end text-lg-start'>
                                        <h6 className="text-truncate mb-0">{observation.FirstName} {observation.Name}</h6>
                                        <p className='mb-0'>{dateFormat(observation.ObservationDate, "dd/MM/yyyy, hh:mm")}</p>
                                    </div>

                                    <div className="col-12 col-lg-5 col-xl-7">
                                        <h6 className="mb-0">Remarque</h6>
                                        <p className="mb-0">{observation.Note}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )

                })}
            </section>

        </section>

        <footer className="card-footer d-flex justify-content-center align-items-center">
            <button className="btn btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                {'<<'}
            </button>
            <button className="btn btn-outline" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                {'<'}
            </button>

            <p className="mx-3 mb-0">
                {table.getState().pagination.pageIndex + 1}
                <span className="text-muted">{' '} sur {' '}</span>
                {table.getPageCount()}
            </p>

            <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                {'>'}
            </button>
            <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                {'>>'}
            </button>
        </footer>
    </section >
}

export function WorkbookTable(reload?: boolean, setReload?) {
    const params = useParams()
    const idAddress = params.idAddress

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')

    const columns = useMemo<ColumnDef<IObservation, any>[]>(
        () => [
            {
                accessorFn: row => row.Service,
                id: 'Service',
                header: 'Prestations',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.ObservationDate,
                id: 'ObservationDate',
                header: 'Date',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.FirstName + " " + row.Name,
                id: 'User',
                header: 'Utilisateur',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.Note,
                id: 'Note',
                header: 'Remarques',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            }
        ], []
    )

    const [data, setData] = useState<Array<IObservation>>([])

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,

        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        table.setPageSize(50)

        reqGet("workbook/address/" + idAddress).then((res) => { setData(res) })

    }, [])

    useEffect(() => {
        if (reload) {
            reqGet("workbook/address/" + idAddress).then((res) => { setData(res) })
            setReload(false)
        }
    }, [reload])

    return table
}

export function Filter({ column, table, }: { column: Column<any, unknown>; table: Table<any> }) {

    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    const sortedUniqueValues = React.useMemo(
        () => typeof firstValue === 'number' ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    return typeof firstValue === 'number' ? (
        <div className="d-inline-flex">

            <DebouncedInput
                type="number"
                className="form-filter"
                min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                value={(columnFilterValue as [number, number])?.[0] ?? ''}
                onChange={value => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
                placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''}`}
            />

            <DebouncedInput
                type="number"
                className="form-filter"
                min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                value={(columnFilterValue as [number, number])?.[1] ?? ''}
                onChange={value => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
                placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''}`}
            />

        </div>
    ) : (
        <>
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>

            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={value => column.setFilterValue(value)}
                placeholder={`Recherche... (${column.getFacetedUniqueValues().size})`}
                className="form-filter"
                list={column.id + 'list'}
            />
        </>
    )
}
