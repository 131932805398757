import React, { useEffect, useState, useMemo } from 'react'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'

import { getToken } from '../../../useToken'
import { StorageAvailabilityBadge } from "./storage"
import { reqGet, reqDelete } from "../../../service/apiRequest";
import { AlertMsg, CardBtnLink, DateCompare, PrivateComponent, ProductTypeIconResolver, ProductTypeLinkResolver, ProgressColor, dateFormat } from '../../common/common'
import { LAYOUT, DEFAULT_IMG, GROUP, PERMISSION, PRODUCT_TYPE, PRODUCT_AVAILABLE, PRODUCT_CONDITION, PRODUCT_CLASS_ID, ICONS } from '../../common/constants'
import { IProduct, IStorageProduct } from '../../common/types'

export default function StorageContent({ reload, setReload }) {
    const location = useLocation()
    const params = useParams()
    const idStorage = params.idStorage
    const token = getToken()
    const navigate = useNavigate()

    const [storage, setStorage] = useState<IStorageProduct>()

    useEffect(() => {
        reqGet("storage/" + idStorage).then(res => setStorage(res))
    }, [idStorage])

    useEffect(() => {
        if (reload)
            reqGet("storage/" + idStorage).then(res => setStorage(res))
    }, [reload])



    function removeMaterial() {
        reqDelete("storage/" + storage?.StorageID, { data: { ActivityUserID: token.UserID } }).then(res => {
            setReload(true)
            navigate("/warehouse/" + params.idWarehouse + "/storage", { state: location.state })
        })
    }

    return <div className="body-container">
        <div className="modal fade" id="removeModal" tabIndex={-1} aria-labelledby="removeModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="removeModalLabel">Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes-vous sur de vouloir supprimer :</p>
                        <p><b>{storage?.Rack}{storage?.Row}-{storage?.Col}{storage?.Compartment ? "-" + storage?.Compartment : ""} {storage?.ProductID ? ", " + storage?.ProductModel + " (" + storage?.ProductReference + ")" : ""}</b></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => removeMaterial()}>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="header">
            <div>
                <h2 className="section-title mb-0">
                    {storage?.Rack}{storage?.Row}-{storage?.Col}{storage?.Compartment != null ? ("-" + storage?.Compartment) : ""}
                </h2>
                <Link to={"/warehouse/" + params.idWarehouse + "/storage"} className="link-nav" state={location.state}>
                    {"<"} Stockage
                </Link>
            </div>

            <div className='toolbar'>
                {PrivateComponent(
                    <Link to="update" className="btn btn-outline">
                        <i className="bi bi-pencil-square me-1"></i> Modifier
                    </Link>,
                    GROUP.admin,
                    PERMISSION.storage
                )}

                {PrivateComponent(
                    <button className="btn btn-delete ms-3" title="Supprimer" data-bs-toggle="modal" data-bs-target="#removeModal">
                        <i className="bi bi-trash3 me-0 me-md-1"></i> Supprimer
                    </button>,
                    GROUP.admin,
                    PERMISSION.storage
                )}
            </div>
        </div>

        <div className="body container-fluid">
            <StorageProduct storage={storage} />

            {!storage?.ProductID &&
                <div className="w-100 text-center mt-5">
                    <p className="text-muted">Aucune produit n'est affecté à cet emplacement</p>
                    <Link to="update" className="btn btn-primary">
                        <i className="bi bi-pencil-square me-1"></i> Modifier
                    </Link>
                </div>
            }
        </div>



    </div>
}


function StorageProduct({ storage }: { storage: IStorageProduct | undefined }) {
    const [progressColor, setProgressColor] = useState<{ background: string, width: number, max: number }>({ background: "", width: 0, max: 0 })

    useEffect(() => {
        setProgressColor({
            background: ProgressColor(
                parseInt(storage?.Quantity ?? "0"),
                parseInt(storage?.QuantityMin ?? "0"),
                parseInt(storage?.QuantityMax ?? "0")
            ),
            width: (parseInt(storage?.Quantity ?? "0") * 100 / parseInt(storage?.QuantityMax ?? "0")),
            max: parseInt(storage?.QuantityMax ?? "0")
        })
    }, [storage])

    return <>
        <article className="card">
            <section className="card-body">

                <div className="row">
                    <section className="col-4">
                        <img className="img-product" src={storage?.ProductImage ? "../../../media/images/product/" + storage?.ProductImage : "../../" + DEFAULT_IMG} />
                    </section>

                    <section className="col-8">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h6 className="mb-1">{storage?.ProductModel}</h6>
                                <p className="paragraph mb-1">{storage?.ProductBrand}</p>
                            </div>
                            <p className="text-end ps-2">
                                {storage && ProductTypeIconResolver(storage.ProductType)}
                            </p>
                        </div>
                        <p>{storage?.ProductReference}</p>
                    </section>
                </div>
            </section>
        </article>



        {storage?.Price &&
            <>
                <article className="card">

                    <section className="card-body">
                        <div className='row'>

                            <div className="col-4">
                                <h6 className="mb-1">Fournisseur</h6>
                                <p className="mb-0">{storage?.ProductSupplier}</p>
                            </div>
                            {PrivateComponent(
                                <>
                                    <div className="col">
                                        <h6 className="mb-1">Facial</h6>
                                        <p className="mb-0">{storage?.Price}</p>
                                    </div>

                                    <div className="col">
                                        <h6 className="mb-1">Achat</h6>
                                        <p className="mb-0">{storage?.PriceBuying}</p>
                                    </div>
                                </>,
                                GROUP.crud_sav,
                                ["PriceRead"]
                            )}

                            <div className="col">
                                <h6 className="mb-1">Vente</h6>
                                <p className="mb-0">{storage?.PriceSelling}</p>
                            </div>
                        </div>
                    </section>
                </article>
            </>}

        <article className="card">
            <header className="card-header"><h5 className="card-title">Remarque</h5></header>

            <section className="card-body"><p className="mb-0">{storage?.ProductNote ?? "aucune"}</p></section>
        </article>

        {PrivateComponent(
            <div className="row row-cols-2">

                <div className="col">
                    <CardBtnLink
                        path={"/" + ProductTypeLinkResolver(storage?.ProductType ?? "") + "/" + storage?.ProductID}
                        title="Produit"
                        icon="bi bi-journal-text"
                    />
                </div>


                <div className="col">
                    <CardBtnLink path="label" title="Etiquette" icon={ICONS.QRCODE} />
                </div>

            </div>,
            GROUP.admin,
            PERMISSION.product
        )}

        {storage?.ProductAvailable != PRODUCT_AVAILABLE.REPAIRING &&
            storage?.ProductCondition != PRODUCT_CONDITION.OUT_OF_ORDER &&
            <CardBtnLink
                path={"/warehouse/" + storage?.WarehouseID + "/takeout"}
                title={(storage?.ProductType == PRODUCT_TYPE.PIECE || storage?.ProductAvailable) ? "Retirer" : "Déplacer"}
                icon={ICONS.TAKEOUT}
                state={{ idStorage: storage?.StorageID }}
            />
        }

        {// IF product is reusable and is not in stock
            (storage?.ProductClassID == PRODUCT_CLASS_ID.REUSABLE &&
                storage?.ProductAvailable == PRODUCT_AVAILABLE.OUT) &&
            <CardBtnLink path={"/warehouse/" + storage?.WarehouseID + "/putback"} title="Déposer" icon={ICONS.PUTBACK} state={{ idStorage: storage?.StorageID }} />
        }


        {storage?.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE &&
            <>
                <article className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-between">
                            <div>
                                <h5 className="card-title">Quantité</h5>
                                <div className="mb-3">
                                    <p className="text-muted d-inline me-1">Min:</p>
                                    <p className="d-inline me-3">{storage?.QuantityMin}</p>
                                    <p className="text-muted d-inline me-1">Max:</p>
                                    <p className="d-inline">{storage?.QuantityMax}</p>
                                </div>
                            </div>

                            <div>
                                <h1 className="mb-0">{storage?.Quantity}</h1>
                            </div>
                        </div>

                        <div className="progress" style={{ "height": "10px" }}>
                            <div className={"progress-bar " + progressColor?.background} role="progressbar" aria-label="Example 1px high" style={{ "width": progressColor.width ? progressColor.width + "%" : "0%" }} aria-valuenow={parseInt(storage?.Quantity ?? "0")} aria-valuemin={0} aria-valuemax={progressColor.max}></div>
                        </div>
                    </div>
                </article>
            </>
        }



        {storage?.ProductClassID == PRODUCT_CLASS_ID.REUSABLE &&
            <>
                <div className="row row-cols-2">

                    <div className="col">
                        <div className="card">
                            <header className="card-header pb-0">
                                <h5 className="card-title">Status</h5>
                            </header>
                            <section className="card-body">
                                <p className="mb-0">{StorageAvailabilityBadge(storage.ProductAvailable)}</p>
                            </section>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card">
                            <header className="card-header pb-0">
                                <h5 className="card-title">Etat</h5>

                            </header>
                            <section className="card-body">
                                <p className="d-inline mb-0">
                                    {storage?.ProductCondition == "Hors service" && <i className="bi bi-x-octagon text-danger me-2"></i>}
                                    {storage?.ProductCondition == "Maintenance" && <i className="bi bi-exclamation-triangle text-warning me-2"></i>}
                                    {storage?.ProductCondition ? storage?.ProductCondition : PRODUCT_CONDITION.NEVER_USED}
                                </p>
                            </section>
                        </div>
                    </div>
                </div>


                { // Product OUT
                    storage?.ProductAvailable == PRODUCT_AVAILABLE.OUT &&
                    <div className="card">
                        <header className="card-header">
                            <h5 className="card-title">Emplacement</h5>
                        </header>

                        <section className="card-body">
                            <p className="mb-2">{storage?.TakeOutFirstName} {storage?.TakeOutName}, {dateFormat(storage?.DateTakeOut, "dd/MM/yyyy, hh:mm")}</p>
                            <p>{storage?.ProductLocation}</p>

                            <h6>Remarque</h6>
                            <p className="mb-0">{storage?.ProductTakeOutNote}</p>
                        </section>
                    </div>
                }

                { // Product REPAIRING
                    storage.ProductAvailable == PRODUCT_AVAILABLE.REPAIRING &&
                    <article className="card">

                        <header className="card-header">
                            <h5 className="card-title">Emplacement</h5>
                        </header>

                        <div className="card-body">
                            <p className="mb-0">{storage.RepairLocation}</p>
                        </div>

                    </article>
                }

                <StorageNoteResolver storage={storage} />
            </>
        }

        {storage?.ProductAvailable != PRODUCT_AVAILABLE.REPAIRING && storage?.ProductCondition == PRODUCT_CONDITION.OUT_OF_ORDER &&
            AlertMsg("danger", "Ce produit est hors service et nécessite une réparation")
        }
        {storage?.ProductCondition == PRODUCT_CONDITION.MAINTENANCE &&
            AlertMsg("warning", "Ce produit nécessiterait une maintenace rapidement")
        }


        {(storage?.ProductClassID == PRODUCT_CLASS_ID.REUSABLE && storage?.ProductAvailable != PRODUCT_AVAILABLE.OUT) ?
            <Link className="card card-btn" to="repair">
                <header className='card-header'>
                    <div className="header-content">
                        <i className="card-icon bi bi-wrench-adjustable-circle"></i>
                    </div>
                </header>

                <section className="card-body">
                    <h5 className="card-title">

                        {storage?.ProductType == PRODUCT_TYPE.TOOL ?
                            storage.ProductAvailable == PRODUCT_AVAILABLE.REPAIRING ? "Retour de réparation" :
                                "Réparation"
                            : ""
                        }

                        {storage?.ProductType == PRODUCT_TYPE.EXCHANGER ?
                            storage.ProductAvailable == PRODUCT_AVAILABLE.REPAIRING ? "Retour de détartrage" :
                                "Détartrage"
                            : ""
                        }
                    </h5>
                </section>

                <footer className="card-footer">
                    <i className="bi bi-chevron-right"></i>
                </footer>
            </Link> :
            <></>
        }


    </>
}



function StorageNoteResolver({ storage }: { storage: IStorageProduct }) {
    if (storage.ProductAvailable == PRODUCT_AVAILABLE.IN_STOCK) {
        if (storage.ProductPutBackNote) {

            if (storage.DatePutBack && storage.DateReturn && DateCompare(storage.DatePutBack, storage.DateReturn) == 2) {
                return <div className="card">
                    <header className="card-header"><h5 className="card-title">Remarque (réparation)</h5></header>
                    <section className="card-body">{storage?.RepairNote}</section>
                </div>
            } else {
                return <div className="card">
                    <header className="card-header"><h5 className="card-title">Remarque (dernier retour)</h5></header>
                    <section className="card-body">{storage?.ProductPutBackNote || storage?.ProductTakeOutNote || "Aucune"}</section>
                </div>
            }

        } else if (storage.RepairNote) {
            return <div className="card">
                <header className="card-header"><h5 className="card-title">Remarque (réparation)</h5></header>
                <section className="card-body">{storage?.RepairNote}</section>
            </div>
        }
    }

    return <></>

}