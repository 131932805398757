import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IAddress, IDocument } from "../../../common/types";
import { reqGet, reqDelete } from "../../../../service/apiRequest";
import { Header, PrivateComponent, PrivateLinkUpdate } from "../../../common/common";
import { GROUP, ICONS } from "../../../common/constants";
import { getToken } from "../../../../useToken";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function AddressDocumentContent({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idAddress = params.idAddress
    const idDocument = params.idDocument

    const [address, setAddress] = useState<IAddress>()
    const [document, setDocument] = useState<IDocument>()

    useEffect(() => {
        reqGet("address/" + idAddress).then(res => setAddress(res))
        reqGet("document/" + idDocument).then(res => {
            setDocument(res)
            var file = res
            updatePDF(res)

        })
    }, [idAddress, idDocument])

    function RemoveDocument() {
        reqDelete("document/" + idDocument, { data: { ActivityUserID: token.UserID, AddressLine: address?.AddressLine } }).then(res => {
            setReload(true)
            navigate("/address/" + idAddress + "/document")
        })
    }

    function updatePDF(file) {
        const PDF = pdfMake.createPdf(file)

        const iFrame: HTMLElement | null = document.getElementById('printPdf')

        var callback = function (url) { iFrame?.setAttribute('src', url); }
        PDF.getDataUrl(callback, PDF);
    }



    return <div className="body-container">

        <div className="modal fade" id="modalRemoveDocument" tabIndex={-1} aria-labelledby="modalRemoveDocument" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">

                    <header className="modal-header">
                        <h1 className="modal-title fs-5" id="modalRemoveDocument">Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </header>

                    <section className="modal-body">
                        <p>Êtes-vous  sur de vouloir supprimer :</p>
                        <p><b>{document?.DocumentTitle}</b></p>
                    </section>

                    <footer className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => RemoveDocument()}>Supprimer</button>
                    </footer>
                </div>
            </div>
        </div>

        {Header(
            <h2 className="section-title">{document?.DocumentTitle}</h2>,
            <Link className="link-nav d-lg-none" to={"/address/" + idAddress + "/document"}>{"<"} Retour</Link>,
            <div>
                {PrivateLinkUpdate("update", GROUP.crud_sav, ["AddressDocumentUpdate"])}
                {PrivateComponent(
                    <button className="btn btn-delete ms-3" title="Supprimer" data-bs-toggle="modal" data-bs-target="#modalRemoveDocument">
                        <i className={ICONS.DELETE}></i> <span className="d-inline d-md-none d-lg-inline ms-1">Supprimer</span>
                    </button>,
                    GROUP.admin,
                    ["AddressDocumentDelete"]
                )}
            </div>
        )}

        <section className="body">

            <iframe id="printPdf" name="printPdf" className="d-flex flex-grow-1 rounded w-100 border"></iframe>
        </section>

    </div >
}