import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'

import { reqGet } from "../../../service/apiRequest";
import { IProduct, ISupplier } from "../../common/types"
import { Header } from '../../common/common';

interface IOrder extends IProduct {
    OrderQuantity: number,
    Location: string
}

export default function WarehouseOrder() {
    const params = useParams()
    const idWarehouse = params.idWarehouse

    const currentDate = new Date();
    const defaultDateFormat = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1) < 10 ? "0" + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)}-${currentDate.getDate() < 10 ? "0" + currentDate.getDate() : currentDate.getDate()}`

    const [insufficient, setInsufficient] = useState<Array<IProduct>>([])
    const [order, setOrder] = useState<Array<IOrder>>([])
    const [suppliers, setSuppliers] = useState<Array<ISupplier>>([])

    const [supplier, setSupplier] = useState<ISupplier>()

    const [recipient, setRecipient] = useState<string>("")
    const [subject, setSubject] = useState<string>("")
    const [intro, setIntro] = useState<string>(`Bonjour,

Merci de commander les pièces suivantes:
`)
    const [outro, setOutro] = useState<string>(`Livraison atelier.

Salutations.
`)

    useEffect(() => {

        reqGet("storage/warehouse/" + idWarehouse + "/insufficient").then(res => {
            setInsufficient(res)

            reqGet("supplier").then(res => {
                setSuppliers(res)
                setSupplier(res[0])
            })
        })
    }, [])

    useEffect(() => {
        if (suppliers.length > 0)
            handleSupplier(suppliers[0].SupplierID)

    }, [suppliers])


    function handleSupplier(id) {

        const supplier = suppliers.find(s => s.SupplierID == id)
        setSupplier(supplier)
        setRecipient(supplier?.ContactEmail ?? supplier?.Email ?? "")

        setSubject(`${defaultDateFormat} - ${supplier?.Name} - Commande stock réapprovisionnement`)


        setOrder(insufficient
            .filter(i => i.SupplierID == id || i.TempSupplierID == id)
            .map(p => {
                return {
                    ...p,
                    OrderQuantity: parseInt(p.QuantityMax ?? "0") - parseInt(p.Quantity ?? "0"),
                    Location: `${p.Rack}${p.Row}-${p.Col}${(p.Compartment ? "-" + p.Compartment : "")}`
                }
            })
        );
    }


    function MailTo() {
        window.location.href = 'mailto:' +
            recipient +
            '?subject=' + subject +
            '&body=' +
            encodeURIComponent(intro) +
            encodeURIComponent("\n<table><tr><th> col 1</th></tr> <tr><td>row 1</td></tr></table> \n\n") +
            encodeURIComponent(outro)
    }

    function handleOrderQuantity(product, quantity) {

        const newOrder = order.map(p => {
            if (p.ProductID == product.ProductID) {
                return { ...p, OrderQuantity: quantity }
            }
            return p;
        });

        setOrder(newOrder);
    }

    return <section className="container-fluid content h-100">
        <div className="row h-100">

            <section className="col-lg-6 col-h-fill">
                {Header(
                    <h2 className="section-title mb-0">Commandes de pièces</h2>,
                    <Link to={"/warehouse/" + idWarehouse + "/insufficient"} className="link-nav">{"<"} Retour</Link>
                )}



                <h5 className="section-title2 mb-0">Fournisseur</h5>

                <div className="mb-3">
                    <p className="paragraph"><i><small>Selectionner le fournisseur auquel passer commande. Les pièces concernées sont automatiquement sélectionnées.</small></i></p>
                    <select className="form-select" name="" id="" onChange={(e) => handleSupplier(parseInt(e.target.value))} >
                        {suppliers.map((s, k) => {
                            return <option key={k} value={s.SupplierID}>{s.Name}</option>
                        })
                        }
                    </select>
                </div>

                <h5 className="section-title2 mb-3">Données de l'email</h5>

                <div className="mb-3">
                    <label htmlFor="sre" className="form-label">Sujet</label>
                    <input type="text" className="form-control" defaultValue={subject} onChange={(e) => setSubject(e.target.value)} />
                </div>

                <div className="mb-3">
                    <label htmlFor="sre" className="form-label">Introduction</label>
                    <textarea className="form-control" rows={4} defaultValue={intro} onChange={(e) => setIntro(e.target.value)} ></textarea>
                </div>

                <div className="mb-3">
                    <label htmlFor="sre" className="form-label">Conclusion</label>
                    <textarea className="form-control" rows={4} defaultValue={outro} onChange={(e) => setOutro(e.target.value)} ></textarea>
                </div>

                {order.length > 0 && <>
                    <h5 className="section-title2 mb-3">Pièces</h5>

                    <div className="mb-3">

                        <div className="row">

                            <div className="col-6">
                                <label htmlFor="nbApartement" className="form-label">Pièce</label>
                            </div>
                            <div className="col-4">
                                <label htmlFor="nbApartement" className="form-label">Prix</label>
                            </div>

                            <div className="col-2 text-end">
                                <label htmlFor="nbApartement" className="form-label">Quantité</label>
                            </div>

                        </div>

                        {order.map((p, k) => {
                            return <div className="row mb-3 border-bottom" key={k}>

                                <div className="col-6 py-3">
                                    <h6 className='text-truncate mb-1'>{p.ProductModel}</h6>

                                    <p className='mb-0'>
                                        {p.ProductReference}
                                        <span className="text-muted me-1 ms-3">Min:</span>{p.QuantityMin}
                                        <span className="text-muted me-1 ms-3">Max:</span>{p?.QuantityMax}
                                    </p>
                                </div>

                                <div className="col-3 py-3">
                                    <p className="mb-1"><span className="text-muted me-1">Prix achat:</span>{p?.PriceBuying}</p>
                                    <p><span className="text-muted me-1">Total:</span>{(p?.PriceBuying ?? 0) * p.OrderQuantity}</p>
                                </div>

                                <div className="col-3 py-3">
                                    <input className='form-control' type="number" defaultValue={p.OrderQuantity} min={0} onChange={(e) => { handleOrderQuantity(p, e.target.value) }} />
                                </div>

                            </div>
                        })}
                    </div>
                </>
                }

            </section>

            <section className="col-6 d-none d-lg-block col-h-fill">
                <h2 className="section-title">Aperçu email</h2>

                <article className='card card-border card-fill card-viewer'>

                    <header className='card-header flex-column border-bottom mb-1'>
                        <p className='paragraph'>
                            <span className='me-2'>pour:</span>
                            <i>{recipient}</i>
                        </p>
                        <h5 className='card-title'>{subject}</h5>
                    </header>

                    <section className='card-body mt-3'>
                        <pre className="paragraph overflow-visible">{intro}</pre>

                        <table className='table-bordered my-3'>
                            <thead>
                                <tr>
                                    <th>Désignation</th>
                                    <th>Référence</th>
                                    <th>Quantité</th>
                                    <th>Emplacement</th>
                                </tr>
                            </thead>

                            <tbody>
                                {order.map((p, k) => {
                                    return <tr key={k}>
                                        <td>{p?.ProductModel}</td>
                                        <td>{p?.ProductReference}</td>
                                        <td>{p?.OrderQuantity}</td>
                                        <td>{p?.Location}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>

                        <pre className="paragraph overflow-visible">{outro}</pre>
                    </section>

                    {/*
                <footer className='border-top pt-3'>
                    <p className="paragraph"></p>

                </footer>
                */}

                </article>

                <div className='d-flex justify-content-end mt-3'>
                    <button className="btn btn-primary" disabled={order.length > 0 ? false : true} onClick={(e) => MailTo()}>
                        <i className="bi bi-check-lg me-1"></i> Valider et envoyer email
                    </button>
                </div>
            </section>
        </div>
    </section>
}