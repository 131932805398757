import React, { useState, useEffect } from 'react'
import { reqGet } from '../../service/apiRequest'

const defaultData: string[] = []

export default function Home() {

    return <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center text-center">

        <h2>Bienvenue sur la nouvelle <br className="d-block d-lg-none" /> interface web de Multitherme</h2>
    </div>
}