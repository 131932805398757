import React, { useEffect, useState } from "react";
import $ from 'jquery'

import { DebouncedInput, InputSearch, PrivateLinkAdd } from "../../common/common";
import { reqGet } from "../../../service/apiRequest"
import { IAddress, IManagement, IOwner } from "../../common/types";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { GROUP, ICONS, LAYOUT } from "../../common/constants";
import { ManagementTable } from "../database/management/management-content";
import { OwnerTable } from '../database/owner/owner-content';

export default function Client({ reload, setReload }) {
    const location = useLocation()

    var managementTable = ManagementTable(reload, setReload)
    var ownerTable = OwnerTable(reload, setReload)

    const [display, setDisplay] = useState<string>("")
    const [displayContent, setDisplayContent] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')
            setDisplayContent('d-flex')

        } else {
            if (location.pathname == "/client") {
                setDisplay('d-flex')
                setDisplayContent('d-none')

            } else {
                setDisplay('d-none')
                setDisplayContent('d-flex')
            }
        }
    }

    const [showManagement, setShowManagement] = useState<Boolean>(true)
    const [showOwner, setShowOwner] = useState<Boolean>(false)


    function HandleNavLink(navLink) {
        $(".list-group-item .active").removeClass("active")
        $(navLink).addClass('active')
    }

    return (
        <div className="container-fluid h-100">
            <div className="row h-100">

                <div className={display + " col-md-5 col-xxl-3 col-h-fill border-right py-3 px-0"}>
                    <div className="body-container content">

                        <header className="header">
                            <h3 className="section-title">Clients</h3>

                            {PrivateLinkAdd("add", GROUP.crud_sav)}
                        </header>

                        <section className="body">

                            <div className="card p-0 border-0">
                                <h5 className="toggle-item mb-0" onClick={() => setShowManagement(showManagement ? false : true)}>

                                    <div className="toggle-icon pe-0">
                                        <i className={"bi bi-chevron-" + (showManagement ? "up" : "down") + ""}></i>
                                    </div>
                                    <button className="toggle-button ps-0"><i className={ICONS.MANAGEMENT + " me-3"}></i>Régie</button>
                                </h5>

                                {showManagement && <div className="toggle-body p-0">
                                    <DebouncedInput value={''} onChange={value => managementTable.setGlobalFilter(String(value))} className="mb-3" placeholder="Recherche" />

                                    <ul className="list-group list-group-hover overflow-auto" style={{ height: "35rem" }}>

                                        {managementTable.getRowModel().rows.map(row => {
                                            var m = row.original
                                            return <NavLink key={m.ManagementID} to={"management/" + m.ManagementID} onClick={(e) => HandleNavLink(e.target)} className="list-group-item text-start">
                                                <h6 className="item-title mb-0">{m.ManagementName}</h6>
                                                <p className="item-subtitle mb-0">{m.ManagementPhone}</p>
                                            </NavLink>
                                        })}

                                    </ul>
                                </div>}
                            </div>


                            <div className="card p-0 border-0">
                                <h5 className="toggle-item mb-0" onClick={() => setShowOwner(showOwner ? false : true)}>

                                    <div className="toggle-icon pe-0">
                                        <i className={"bi bi-chevron-" + (showOwner ? "up" : "down") + ""}></i>
                                    </div>
                                    <button className="toggle-button ps-0"><i className={ICONS.OWNER + " me-3"}></i>Propriétaire</button>
                                </h5>

                                {showOwner && <div className="toggle-body p-0">

                                    <DebouncedInput value={''} onChange={value => ownerTable.setGlobalFilter(String(value))} className="mb-3" placeholder="Recherche" />

                                    <ul className="list-group list-group-hover overflow-auto" style={{ height: "35rem" }}>

                                        {ownerTable.getRowModel().rows.map(row => {
                                            var o = row.original
                                            return <NavLink key={o.OwnerID} to={"owner/" + o.OwnerID} className="list-group-item text-start">
                                                <h6 className="item-title mb-0">{o.OwnerName}</h6>
                                                <p className="item-subtitle mb-0">{o.OwnerPhone}</p>
                                            </NavLink>
                                        })}

                                    </ul>
                                </div>}
                            </div>


                        </section>
                    </div>
                </div>

                <div className={displayContent + " col-md-7 col-xxl-9 col-h-fill p-0"}>

                    <Outlet />

                </div>

            </div>
        </div>
    )
}