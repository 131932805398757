import React, { useEffect, useState } from "react";
import { reqGet } from "../../../../service/apiRequest";
import { IAddress } from "../../../common/types";
import { Link, useParams } from "react-router-dom";
import { Header, PrivateComponent, PrivateLinkAdd, PrivateLinkUpdate } from "../../../common/common";
import { GROUP, ICONS } from "../../../common/constants";

export default function AddressInterventionContent() {
    const params = useParams()
    const idAddress = params.idAddress
    const idIntervention = params.idIntervention

    const [address, setAddress] = useState<IAddress>()

    const [goods, setInterventions] = useState<Array<any>>([])

    useEffect(() => {
        reqGet("address/" + idAddress).then(res => setAddress(res))

        setInterventions([
            { InterventionID: 1, InterventionNumber: 240600101, InterventionDate: "", InterventionTitle: "Titre de l'inter", InterventionTechnician: "Florian Sansone", InterventionContract: 1, InterventionStatus: "A planifier", InterventionInformation: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam magni enim iure, numquam iusto earum totam amet doloremque fuga nihil assumenda! Harum quidem praesentium asperiores. Dolores a porro repellendus ex?", InterventionTypes: ["Type 1", "Type 2"] },
            { InterventionID: 2, InterventionNumber: 240600102, InterventionDate: "17/06/2024", InterventionTitle: "Titre de l'inter 2", InterventionTechnician: "Florian Ibanez", InterventionContract: 0, InterventionStatus: "Terminé", InterventionInformation: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam magni enim iure, numquam iusto earum totam amet doloremque fuga nihil assumenda! Harum quidem praesentium asperiores. Dolores a porro repellendus ex?", InterventionTypes: ["Type 2"] },
            { InterventionID: 3, InterventionNumber: 240600103, InterventionDate: "02/06/2024", InterventionTitle: "Titre de l'inter 3", InterventionTechnician: "Florian Ibanez", InterventionContract: 0, InterventionStatus: "Terminé", InterventionInformation: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam magni enim iure, numquam iusto earum totam amet doloremque fuga nihil assumenda! Harum quidem praesentium asperiores. Dolores a porro repellendus ex?", InterventionTypes: ["Type 1", "Type 3"] },
        ])
    }, [])

    return <div className="body-container content">

        <header className="header">
            <div>
                <h2 className="section-title">Titre du bon</h2>
                <Link to={"/address/" + idAddress + "/good"} className="link-nav">{"<"} Retour</Link>
            </div>

            {PrivateComponent(
                <div className="dropdown">
                    <button className="btn btn-sm btn-outline rounded-3" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                    <ul className="dropdown-menu">
                        {PrivateComponent(
                            <li>
                                <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target="#">
                                    <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                </button>
                            </li>,
                            GROUP.dev
                        )}
                    </ul>
                </div>,
                GROUP.crud_sav
            )}
        </header>

        <section className="body">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-6 col-xl-4 col-xxl-3 col-h-fill ps-0">

                        <header className="header">
                            <h3 className="section-title-2">Données</h3>

                            {PrivateLinkUpdate("update", GROUP.crud_sav)}
                        </header>

                        <article className="card">
                            <section className="card-body">
                                <dl className="row">
                                    <dt className="col-5">Catégories</dt>
                                    <dd className="col-7">...</dd>

                                    <dt className="col-5">Status</dt>
                                    <dd className="col-7">...</dd>

                                    <dt className="col-5">Responsable</dt>
                                    <dd className="col-7">...</dd>

                                    <dt className="col-12">Information</dt>
                                    <dd className="col-12">...</dd>
                                </dl>
                            </section>
                        </article>


                        <h3 className="section-title-2">Budget</h3>

                        <article className="card">
                            <section className="card-body">
                                <dl className="row">
                                    <dt className="col-5">Main d'oeuvre</dt>
                                    <dd className="col-7">630.-</dd>

                                    <dt className="col-5">Matériel</dt>
                                    <dd className="col-7">237.-</dd>
                                </dl>
                            </section>
                        </article>
                    </div>

                    <div className="col-lg-6 col-xl-8 col-xxl-9">

                        <h3 className="section-title-2">Informations complémentaires</h3>

                        <article className="card mb-4">
                            <section className="card-body">

                                <div className="d-flex justify-content-center align-items-center" style={{ height: "4rem" }}>
                                    {PrivateLinkAdd("add")}
                                </div>

                            </section>
                        </article>


                        <header className="header">
                            <h3 className="section-title-2 mt-0">Interventions</h3>

                            {PrivateLinkAdd("intervention/add")}
                        </header>


                        <section className="list list-hover-bordered">

                            {goods.length > 0 && goods.map(i => {
                                return <Link to={i.InterventionID.toString()} className="list-item" key={i.InterventionID}>
                                    <section className="list-item-data px-0">
                                        <div className="row mb-3">
                                            <div className="col">
                                                <h6 className="item-title">Numéro</h6>
                                                <p className="item-text">{i.InterventionNumber}</p>
                                            </div>

                                            <div className="col">
                                                <h6 className="item-title">Etat</h6>
                                                <p className="item-text">{i.InterventionStatus}</p>
                                            </div>

                                            <div className="col">
                                                <h6 className="item-title">Date</h6>
                                                <p className="item-text">{i.InterventionDate}</p>
                                            </div>

                                            <div className="col">
                                                <h6 className="item-title">Technicien</h6>
                                                <p className="item-text">{i.InterventionTechnician}</p>
                                            </div>
                                        </div>
                                    </section>

                                    <footer className="list-item-footer">

                                    </footer>
                                </Link>
                            })}

                        </section>
                    </div>

                </div>

            </div>
        </section>

    </div>

}