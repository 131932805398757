import React, { useState, useEffect } from 'react'
import { Routes, Route, Link, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { HeaderRow, ButtonSubmit } from '../../../common/common'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { ISupplier } from '../../../common/types'

const validationSchema = yup.object({
    Name: yup.string().required("Ce champ est obligatoire"),
    Email: yup.string().email("L'email n'est pas valide").nullable(),
    Phone: yup.string().nullable(),
    Website: yup.string().nullable(),
    ContactName: yup.string().nullable(),
    ContactEmail: yup.string().email("L'email n'est pas valide").nullable(),
    ContactPhone: yup.string().nullable(),
})

const initialValues = {
    Name: ""
}

export default function SupplierAdd() {
    const token = getToken()
    let navigate = useNavigate()

    const { register, formState: { errors }, handleSubmit, watch } = useForm<ISupplier>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const onSubmit: SubmitHandler<ISupplier> = data => {

        reqPost('supplier', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate(-1)
        })

    };

    return <div className="body-container h-100">

        {HeaderRow(
            <h3 className="section-title mb-1">Ajouter un fournisseur</h3>,
            <button onClick={() => navigate(-1)} className="btn link-nav">{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-lg-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="row mb-3">
                            <div className="col-5">

                                <label htmlFor="Name" className="form-label">Nom *</label>
                                <input className={"form-control " + (errors?.Name ? "is-invalid" : "")} {...register("Name")} />

                                {errors.Name?.message && <p className="mt-2 text-danger">{errors.Name?.message}</p>}

                            </div>
                            <div className="col">

                                <label htmlFor="Email" className="form-label">Email</label>
                                <input className={"form-control " + (errors?.Email ? "is-invalid" : "")} {...register("Email")} />

                                {errors.Email?.message && <p className="mt-2 text-danger">{errors.Email?.message}</p>}

                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-5">

                                <label htmlFor="Phone" className="form-label">Téléphone</label>
                                <input className={"form-control " + (errors?.Phone ? "is-invalid" : "")} {...register("Phone")} />

                                {errors.Phone?.message && <p className="mt-2 text-danger">{errors.Phone?.message}</p>}

                            </div>

                            <div className="col">

                                <label htmlFor="Website" className="form-label">Site internet</label>
                                <input className={"form-control " + (errors?.Website ? "is-invalid" : "")} {...register("Website")} />

                                {errors.Website?.message && <p className="mt-2 text-danger">{errors.Website?.message}</p>}

                            </div>
                        </div>

                        <h5 className="mt-5">Personne de contact</h5>

                        <div className="row mb-3">
                            <div className="col">

                                <label htmlFor="ContactName" className="form-label">Nom</label>
                                <input className={"form-control " + (errors?.ContactName ? "is-invalid" : "")} {...register("ContactName")} />

                                {errors.ContactName?.message && <p className="mt-2 text-danger">{errors.ContactName?.message}</p>}

                            </div>
                            <div className="col">

                                <label htmlFor="ContactEmail" className="form-label">Email</label>
                                <input className={"form-control " + (errors?.ContactEmail ? "is-invalid" : "")} {...register("ContactEmail")} />

                                {errors.ContactEmail?.message && <p className="mt-2 text-danger">{errors.ContactEmail?.message}</p>}

                            </div>
                            <div className="col">

                                <label htmlFor="ContactPhone" className="form-label">Téléphone</label>
                                <input className={"form-control " + (errors?.ContactPhone ? "is-invalid" : "")} {...register("ContactPhone")} />

                                {errors.ContactPhone?.message && <p className="mt-2 text-danger">{errors.ContactPhone?.message}</p>}

                            </div>
                        </div>


                        <p><small>Les champs avec une * sont obligatoire</small></p>
                        <ButtonSubmit />

                    </form>

                </div>

                <div id="alert" className="d-none alert alert-danger align-self-end position-absolute w-75" role="alert">
                    A simple danger alert—check it out!
                </div>
            </div >


        </div>
    </div>
}