import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import $ from 'jquery'

import { getToken } from '../../../../useToken'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { IProduct, IBrand, ISupplier, IExchanger } from '../../../common/types'
import { HeaderRow, ButtonSubmit, CompareString } from '../../../common/common'
import { ICONS, PRODUCT_CLASS_ID, PRODUCT_TYPE } from '../../../common/constants';

const validationSchema = yup.object({
    ProductType: yup.string().required("Ce champ est obligatoire"),
    ProductLabel: yup.string().nullable(),
    ProductModel: yup.string().nullable(),
    ProductReference: yup.string().nullable(),
    BrandID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    SupplierID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ProductHeight: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ProductWidth: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ProductDepth: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()
})

const initialValues = {
    ProductClassID: PRODUCT_CLASS_ID.REUSABLE,
    ProductType: PRODUCT_TYPE.EXCHANGER,
    ProductLabel: "",
    ProductModel: "",
    ProductReference: "",
    BrandID: null,
    SupplierID: null,
    ProductHeight: null,
    ProductWidth: null,
    ProductDepth: null,
    ProductOutput: null
}

export default function ExchangerAdd() {
    const navigate = useNavigate()
    const token = getToken()

    const { register, formState: { errors }, handleSubmit, setValue, getValues } = useForm<IProduct>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [brand, setBrand] = useState<Array<IBrand>>([])
    const [productExchangers, setProductExchangers] = useState<Array<IProduct>>([])
    const [exchangers, setExchangers] = useState<Array<IExchanger>>([])

    useEffect(() => {
        reqGet("exchanger").then(res => {
            setExchangers(res)

            SetProductFromExchanger(res[0])
        })
        reqGet("product/exchanger").then(res => {
            setProductExchangers(res)
        })

        reqGet("brand").then(res => {
            setBrand(res)
            setValue("BrandID", res[0].BrandID)
        })

    }, [])

    function SetProductFromExchanger(exchanger: IExchanger) {
        setValue("ProductModel", exchanger.ExchangerModel)
        setValue("ProductLabel", exchanger.ExchangerLabel)
        setValue("ProductReference", exchanger.ExchangerReference)
        setValue("BrandID", exchanger.BrandID)
        setValue("ProductHeight", exchanger.ExchangerHeight)
        setValue("ProductWidth", exchanger.ExchangerWidth)
        setValue("ProductDepth", exchanger.ExchangerDepth)
        setValue("ProductOutput", exchanger.ExchangerOutput)

        $("#ExchangerLabel").val(exchanger.ExchangerLabel)

        var output = exchanger.ExchangerOutput?.split("-")
        if (output) {
            $("#ProductOutputPt1").val(output[0])
            $("#ProductOutputPt2").val(output[1])
        }
    }

    function HandleProductModel(exchangerID: number) {
        var ex = exchangers.find(e => e.ExchangerID === exchangerID)
        if (ex)
            SetProductFromExchanger(ex)
    }

    const onSubmit: SubmitHandler<IProduct> = data => {
        data.ProductLabel = $("#ExchangerLabel").val() + "-" + data.ProductLabel
        data.ProductOutput = $("#ProductOutputPt1").val() + "-" + $("#ProductOutputPt2").val()

        reqPost('product', { ...data, ActivityUserID: token.UserID }).then((res) => {
            navigate('/exchanger/' + res)
        })

    };

    const [validLabel, setValidLabel] = useState<Boolean>()

    function ValidateLabel(label: string) {
        const exchangerLabel = getValues("ProductLabel") + "-" + label

        if (productExchangers?.some(ex => CompareString(ex?.ProductLabel, exchangerLabel))) {
            setValidLabel(false)
        } else {
            setValidLabel(true)
        }
    }

    return <div className="body-container">

        {HeaderRow(
            <h3 className="section-title mb-1">Ajouter un échangeur</h3>,
            <button className="btn link-nav" onClick={() => navigate(-1)}>{'< Retour'}</button>
        )}

        <div className="body container-fluid">

            <div className="row h-100 justify-content-center">

                <div className="col-md-6 col-h-fill overflow-auto">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="row row-cols-2 mb-3">

                            <div className="col">
                                <label htmlFor="ProductModel" className="form-label">Modèle *</label>
                                <select className="form-select" onChange={(e) => HandleProductModel(parseInt(e.target.value))}>
                                    {exchangers.map(e => {
                                        return <option key={e.ExchangerID} value={e.ExchangerID}>{e.ExchangerModel}</option>
                                    })}
                                </select>

                                {errors.ProductModel?.message && <p className="mt-2 text-danger">{errors.ProductModel?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="ProductLabel" className="form-label">Label *</label>
                                <div className='row'>
                                    <div className="col-3">
                                        <input type="text" className="form-control" id="ExchangerLabel" disabled />
                                    </div>
                                    <div className='col-9'>
                                        <input type="number" className={"form-control " + (errors?.ProductLabel ? "is-invalid" : "")} {...register("ProductLabel")} min={1} onChange={(e) => ValidateLabel(e.target.value)} placeholder={"0"} />
                                    </div>
                                </div>

                                {errors.ProductLabel?.message && <p className="mt-2 text-danger">{errors.ProductLabel?.message}</p>}

                                {validLabel == false && <p className="mb-0 mt-2 text-danger"><i className={ICONS.DANGER + " me-2"}></i>Le label existe déjà</p>}
                            </div>

                        </div>

                        <div className="row row-cols-2 mb-3">
                            <div className="col">
                                <label htmlFor="ProductReference" className="form-label">Référence</label>
                                <input type="text" className={"form-control " + (errors?.ProductReference ? "is-invalid" : "")} {...register("ProductReference")} disabled />

                                {errors.ProductReference?.message && <p className="mt-2 text-danger">{errors.ProductReference?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="BrandID" className="form-label">Marque</label>
                                <select className={"form-select " + (errors?.BrandID ? "is-invalid" : "")} id="" {...register("BrandID")} disabled>
                                    {brand.map(b => {
                                        return <option key={b.BrandID} value={b.BrandID}>{b.Name}</option>
                                    })}
                                </select>

                                {errors.BrandID?.message && <p className="mt-2 text-danger">{errors.BrandID?.message}</p>}
                            </div>
                        </div>

                        <div className="row row-cols-3 mb-3">
                            <div className="col">
                                <label htmlFor="ProductHeight" className="form-label">Hauteur</label>
                                <input type="text" className={"form-control " + (errors?.ProductHeight ? "is-invalid" : "")} {...register("ProductHeight")} disabled />

                                {errors.ProductHeight?.message && <p className="mt-2 text-danger">{errors.ProductHeight?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="ProductWidth" className="form-label">Largeur</label>
                                <input type="text" className={"form-control " + (errors?.ProductWidth ? "is-invalid" : "")} {...register("ProductWidth")} disabled />

                                {errors.ProductWidth?.message && <p className="mt-2 text-danger">{errors.ProductWidth?.message}</p>}
                            </div>

                            <div className="col">
                                <label htmlFor="ProductDepth" className="form-label">Profondeur</label>
                                <input type="text" className={"form-control " + (errors?.ProductDepth ? "is-invalid" : "")} {...register("ProductDepth")} disabled />

                                {errors.ProductDepth?.message && <p className="mt-2 text-danger">{errors.ProductDepth?.message}</p>}
                            </div>
                        </div>

                        <label htmlFor="ProductOutput" className="form-label">Sortie</label>
                        <div className="row">
                            <div className='col-4 col-md-3'>
                                <input type="text" maxLength={4} id="ProductOutputPt1" className={"form-control " + (errors?.ProductOutput ? "is-invalid" : "")} placeholder='1010' disabled />
                            </div>
                            <div className='col-1 text-center'>-</div>
                            <div className='col-4 col-md-3'>
                                <input type="text" maxLength={4} id="ProductOutputPt2" className={"form-control " + (errors?.ProductOutput ? "is-invalid" : "")} placeholder='1010' disabled />
                            </div>

                            {errors.ProductOutput?.message && <p className="mt-2 text-danger">{errors.ProductOutput?.message}</p>}
                        </div>


                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            <ButtonSubmit />
                        </div>

                    </form>

                </div>
            </div >


        </div >
    </div >
}