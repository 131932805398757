import React, { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { reqGet, reqDelete } from '../../../service/apiRequest'

import { getToken } from '../../../useToken'
import { PrivateComponent, DebouncedInput, fuzzySort, fuzzyFilter, Filter } from "../../common/common"
import { GROUP, ICONS, MATERIAL_CLASS_ID } from "../../common/constants"
import { IAddress, IMaterial } from "../../common/types"

import {
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    flexRender
} from '@tanstack/react-table'

interface IMaterialAddresses extends IAddress {
    NbInstallation: number | null
}

export default function MaterialContent({ setReload }) {
    const params = useParams()
    const idMaterial = params.idMaterial
    const token = getToken()

    const [material, setMaterial] = useState<IMaterial>()

    useEffect(() => {
        reqGet("material/" + idMaterial).then(res => setMaterial(res))
    }, [idMaterial])


    function removeMaterial() {
        reqDelete("material/" + material?.MaterialID, { data: { ActivityUserID: token.UserID } }).then(res => {
            setReload(true)
        })
    }

    return <section className="body-container content">


        <div className="modal fade" id="modalMaterialRemove" tabIndex={-1} aria-labelledby="modalMaterialRemove" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="modalMaterialRemove">Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes-vous sur de vouloir supprimer :</p>
                        <p><b>{material?.Brand} {material?.Model}</b></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => removeMaterial()}>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>


        <header className="header">
            <div>
                <h2 className="section-title mb-1">{material?.Model}</h2>
                <Link to="/material" className="link-nav">{"<"} Retour</Link>
            </div>


            {PrivateComponent(
                <div className="dropdown">
                    <button className="btn btn-sm btn-outline rounded-3" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                    <ul className="dropdown-menu">
                        {PrivateComponent(
                            <li>
                                <Link to="update" className="dropdown-item mb-2"><i className={ICONS.UPDATE + " me-2"}></i>Modifier</Link>
                            </li>,
                            undefined, ["MaterialUpdate"]
                        )}

                        {PrivateComponent(
                            <li>
                                <Link to="merge" className="dropdown-item mb-2"><i className={ICONS.MERGE + " me-2"}></i>Fusionner</Link>
                            </li>,
                            undefined, ["MaterialUpdate"]
                        )}

                        {PrivateComponent(
                            <li>
                                <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target="modalMaterialRemove">
                                    <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                </button>
                            </li>,
                            undefined, ["MaterialDelete"]
                        )}
                    </ul>
                </div>,
                GROUP.crud_sav
            )}

        </header>



        <section className="body">

            <h3 className='section-title-2'>Données</h3>

            <article className="card">
                <section className="card-body">
                    <div className="row row-cols-3">

                        <div className='col mb-3' style={{ minHeight: "3rem" }}>
                            <h6 className="item-title">Catégorie</h6>
                            <p className="item-text">{material?.Class}</p>
                        </div>

                        <div className='col-8 mb-3' style={{ minHeight: "3rem" }}>
                            <h6 className="item-title">Type</h6>
                            <p className="item-text">{material?.Type}</p>
                        </div>

                        <div className='col mb-3' style={{ minHeight: "3rem" }}>
                            <h6 className="item-title">Marque</h6>
                            <p className="item-text">{material?.Brand}</p>
                        </div>

                        <div className='col mb-3' style={{ minHeight: "3rem" }}>
                            <h6 className="item-title">Référence</h6>
                            <p className="item-text">{material?.Reference}</p>
                        </div>

                        <div className='col mb-3' style={{ minHeight: "3rem" }}>
                            <h6 className="item-title">Production</h6>
                            <p className="item-text">{material?.MaterialYearProduction} {(material?.MaterialYearProduction && material?.MaterialYearEndProduction) && " - "} {material?.MaterialYearEndProduction}</p>
                        </div>

                        {(material?.MaterialClassID == MATERIAL_CLASS_ID.BOILER ||
                            material?.MaterialClassID == MATERIAL_CLASS_ID.TANK ||
                            material?.MaterialClassID == MATERIAL_CLASS_ID.EXPANSION) &&
                            <div className='col mb-3' style={{ minHeight: "3rem" }}>
                                <h6 className="item-title">Capacité (L)</h6>
                                <p className="item-text">{material?.Capacity}</p>
                            </div>
                        }
                        {(material?.MaterialClassID == MATERIAL_CLASS_ID.BURNER ||
                            material?.MaterialClassID == MATERIAL_CLASS_ID.GENERATOR) &&
                            <div className='col mb-3' style={{ minHeight: "3rem" }}>
                                <h6 className="item-title">Puissance (kW)</h6>
                                <p className="item-text">{material?.PowerRange}</p>
                            </div>
                        }

                        {material?.MaterialClassID == MATERIAL_CLASS_ID.GENERATOR &&
                            <div className='col mb-3' style={{ minHeight: "3rem" }}>
                                <h6 className="item-title">ICF</h6>
                                <p className="item-text">{material?.ICF}</p>
                            </div>
                        }

                        {material?.MaterialClassID == MATERIAL_CLASS_ID.PUMP &&
                            <>
                                <div className='col mb-3' style={{ minHeight: "3rem" }}>
                                    <h6 className="item-title">Alimentation</h6>
                                    <p className="item-text">{material?.Alimentation}</p>
                                </div>

                                <div className='col mb-3' style={{ minHeight: "3rem" }}>
                                    <h6 className="item-title">Longueur</h6>
                                    <p className="item-text">{material?.Longueur}</p>
                                </div>
                            </>
                        }

                        {material?.MaterialClassID == MATERIAL_CLASS_ID.SOLAR &&
                            <div className='col mb-3' style={{ minHeight: "3rem" }}>
                                <h6 className="item-title">Surface</h6>
                                <p className="item-text">{material?.Surface}</p>
                            </div>
                        }
                    </div>
                </section>
            </article>

            <MaterialAdresses material={material} />

        </section>

    </section >
}


function MaterialAdresses({ material }: { material: IMaterial | undefined }) {
    const [data, setData] = useState<Array<IMaterialAddresses>>([])

    useEffect(() => {
        reqGet("address/material/" + material?.MaterialID).then(res => {
            setData(res)
            console.log(res);
        })
    }, [material])

    const columns = useMemo<ColumnDef<IMaterialAddresses, any>[]>(
        () => [
            {
                accessorFn: row => row.AddressLine,
                id: 'AddressLine',
                header: 'Adresse',
                cell: info => info.getValue()
            }
        ], []
    )

    const [globalFilter, setGlobalFilter] = useState('')

    const table = useReactTable({
        data,
        columns,
        filterFns: { fuzzy: fuzzyFilter },
        state: { globalFilter },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    if (data.length > 0)
        return <>
            <h3 className="section-title-2">Adresses installées ({material?.NbInstallation})</h3>

            <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Rechercher une adresse" className='mb-3' />

            <div className="card card-fill card-flush" style={{ minHeight: "400px" }}>
                <section className="card-body">
                    <div className="list">

                        {table.getRowModel().rows.map(row => {
                            const address = row.original
                            return <div className="list-item" key={address.AddressID}>

                                <section className='list-item-data ps-0'>
                                    <div className="row">
                                        <div className="col-9">
                                            <h6 className="item-title text-truncate">{address.AddressLine}, {address.ZipCode} {address.City}</h6>
                                            <p className="item-text">{address.AddressComplementary}</p>
                                        </div>

                                        <div className='col-3'>
                                            <h6 className="item-title">Nombre</h6>
                                            <p className="item-text">{address?.NbInstallation}</p>
                                        </div>
                                    </div>

                                </section>

                                <footer className='list-item-more align-items-start'>
                                    <Link className="link-nav" to={"/address/" + address.AddressID + "/installation"} state={{ back: location.pathname }}>Détails {">"}</Link>
                                </footer>

                            </div>
                        })}

                    </div>
                </section>
            </div>
        </>
    else return <></>
}