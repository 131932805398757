import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { reqGet, reqPost } from '../../../../service/apiRequest'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { getToken } from '../../../../useToken'
import { IAddress, IManagement, IOwner, ITenant, IUser, IZipCode } from "../../../common/types"
import { HeaderRow, ButtonSubmit, ReplaceSpecialChars, ManagementInputSearch, OwnerInputSearch, TenantInputSearch } from '../../../common/common'

interface IFormInput extends IAddress {
    AddressNumber: string;
    ActivityUserID: number | null;
}

const validationSchema = yup.object({
    AddressLine: yup.string().required("Ce champ est obligatoire"),
    AddressNumber: yup.string().required("Ce champ est obligatoire"),
    AddressComplementary: yup.string().nullable(),
    OwnerID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    Tenant: yup.string().nullable(),
    TenantID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ZipCodeID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    ManagementID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable()

}).required();

const initialValues = {
    AddressLine: "",
    AddressNumber: "",
    AddressComplementary: null,
    OwnerID: null,
    Tenant: null,
    TenantID: null,
    ZipCodeID: null,
    ManagementID: null,
    ActivityUserID: null
}

export default function AddressGoodAdd() {
    const location = useLocation()
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idAddress = params.idAddress

    const LinkBack = () => {
        if (location?.state?.back)
            return location.state.back
        else
            return "/address/" + idAddress + "/good"
    }

    const { register, formState: { errors }, handleSubmit, watch, setValue } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [zipcode, setZipcode] = useState<Array<IZipCode>>([])
    const [managementSelected, setManagementSelected] = useState<IManagement | null>(null)
    const [ownerSelected, setOwnerSelected] = useState<IOwner | null>(null)
    const [tenantSelected, setTenantSelected] = useState<ITenant | null>(null)
    const [managers, setManagers] = useState<Array<IUser>>([])

    useEffect(() => {
        setValue("ActivityUserID", token.UserID)

        reqGet('zipcode').then(res => { setZipcode(res) })

        reqGet("user/manager").then(res => setManagers(res))
    }, [])


    const onSubmit: SubmitHandler<IFormInput> = data => {

        reqPost('address', data).then((res) => {
            navigate("/address/" + idAddress + "/good/" + res)
        })
    };

    return (<div className="row h-100 justify-content-center content">

        <div className="col-xl-6">

            <header className="header">
                <div>
                    <h3 className="section-title">Ajouter un bon</h3>
                    <Link to={LinkBack()} className="link-nav">{'<'} Retour</Link>
                </div>
            </header>

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="row row-cols-3 mb-3">

                    <div className="col">
                        <label className="form-label">Numéro de bon *</label>
                        <input className={"form-control " + (errors?.AddressNumber ? "is-invalid" : "")} {...register("AddressNumber")} />
                    </div>

                    <div className="col">
                        <label className="form-label">Repsonsable *</label>
                        <select id="" className="form-select">
                            {managers.length > 0 && managers.map((m) => {
                                return <option key={m.UserID} value={m.UserID}>{m.FirstName} {m.Name}</option>
                            })}
                        </select>
                    </div>

                    <div className="col">
                        <label className="form-label">Sous-contrat *</label>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <label className="form-label">Objet du bon *</label>
                    <input className={"form-control " + (errors?.AddressNumber ? "is-invalid" : "")} {...register("AddressNumber")} />
                </div>

                <div className="mb-3">
                    <label className="form-label">Informations</label>
                    <textarea id="" rows={4}></textarea>
                </div>



                <div className="text-end">
                    <p><small>Les champs avec une * sont obligatoire</small></p>
                    <ButtonSubmit />
                </div>

            </form>

        </div>

    </div>
    );
}
