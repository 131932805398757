import React, { useState, useEffect, useLayoutEffect } from 'react'
import { NavLink, Link, useLocation, Outlet, useNavigate } from 'react-router-dom'
import $ from 'jquery'

import { reqGet, reqPost } from '../../../service/apiRequest'
import { getToken } from '../../../useToken'
import { IVehicle, IUser, IAddress, IHeating, ITimesheet, ITask, IMission, IWeek } from '../../common/types'
import { PrivateLinkUpdate, PrivateComponent, TimeCalculation, DateToday, dateFormat, CardBtnLink } from '../../common/common'
import { BADGE, DEFAULT_IMG, GROUP, ICONS, IMAGE_PATH, LAYOUT, SERVICE } from '../../common/constants'



export default function Account({ reload, setReload }) {
    const location = useLocation()
    const navigate = useNavigate()
    const token = getToken()

    const [profile, setProfile] = useState<IUser>()

    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [weeks, setWeeks] = useState<Array<IWeek>>([])
    const [week, setWeek] = useState<IWeek>()

    useEffect(() => {
        if (token)
            reqGet("user/" + token.UserID).then(res => {
                setProfile(res)
            })

        reqGet("week/year/" + year).then(res => {
            setWeeks(res)

            var currentDate = new Date()
            var oneJan = new Date(currentDate.getFullYear(), 0, 1);
            let weekNb = Math.ceil((((currentDate.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7);

            const w = res.find(w => w.WeekNumber >= weekNb)
            setWeek(w)
        })
    }, [])

    useEffect(() => {
        if (reload)
            setReload(false)
    }, [reload])

    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/account") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }

    }

    function logout() {
        localStorage.removeItem('token')
        navigate('/login')
    }


    return <div className="container-fluid h-100">
        <div className="row h-100">

            <div className={display + " col-md-6 col-xl-4 col-h-fill px-0 border-right"}>
                <section className="body-container content">


                    <header className="d-flex justify-content-between align-items-start">
                        <h2 className="section-title">Profile</h2>
                        {PrivateLinkUpdate("update")}
                    </header>

                    <section className="body">

                        <section className="text-center mb-3">
                            <img style={{ height: "9rem", width: "9rem", borderRadius: "4.5rem", objectFit: "cover", marginBottom: "1rem" }} src={profile?.UserImage ? IMAGE_PATH.USERS + profile.UserImage : DEFAULT_IMG} />

                            <h5 className="text-muted mb-1">{profile?.Username}</h5>
                            <h3 className='mb-1'>{profile?.FirstName} {profile?.Name}</h3>
                            <p className='mb-1'>{profile?.Role}</p>
                            {profile?.UserArchived == 1 &&
                                <div className="badge bg-warning" title='Archivé'><i className={ICONS.ARCHIVE + " me-2"}></i> Archivé</div>
                            }
                        </section>

                        <UserData user={profile} />

                        <NavLink className='list-item card-hover' to="password/update">
                            <div className="list-item-start">
                                <i className={ICONS.PASSWORD_UPDATE + " text-color"} style={{ fontSize: "1.8rem" }}></i>
                            </div>

                            <div className="list-item-data">
                                <h6 className="mb-0">Modifier mot de passe</h6>
                            </div>

                            <div className='list-item-more'>
                                <i className="bi bi-chevron-right"></i>
                            </div>
                        </NavLink>

                        {/* Account vechicles */}
                        {token?.UserID && <AccountVehicles UserID={token.UserID} reload={reload} />}



                        {/* Timesheets */}
                        <h3 className="section-title-2">Heures</h3>
                        <AccountHoliday UserID={token.UserID} reload={reload} />
                        <AccountTimesheet UserID={token.UserID} reload={reload} />
                        <AccountPicket UserID={token.UserID} reload={reload} />
                        <AccountMission UserID={token.UserID} reload={reload} />


                        <h3 className="section-title-2">Relevés</h3>
                        {(profile && profile.Zones?.length > 0) && <AccountHeating UserID={token.UserID} reload={reload} />}
                        {(profile && profile.Zones?.length > 0) && <AccountSurvey UserID={token.UserID} reload={reload} />}
                        {PrivateComponent(<AccountCounterStatement UserID={token.UserID} reload={reload} WeekID={week?.WeekID} />, GROUP.crud_survey)}
                        {PrivateComponent(<AccountTask UserID={token.UserID} reload={reload} />, GROUP.dev)}

                        {PrivateComponent(<>
                            <h3 className="section-title-2">Admin</h3>
                            <AccountVehiclesGuarantor UserID={token.UserID} reload={reload} />
                        </>,
                            GROUP.admin
                        )}

                        <button className="card card-btn bg-danger-inversed border-0 mt-4" onClick={() => logout()}>

                            <header className='card-header'>
                                <div className="header-content">
                                    <i className={"card-icon " + ICONS.LOGOUT}></i>
                                </div>
                            </header>

                            <section className="card-body">
                                <h5 className="card-title">Déconnexion</h5>
                            </section>

                            <footer className="card-footer">
                                <i className="bi bi-chevron-right"></i>
                            </footer>
                        </button>

                    </section>

                </section>

            </div>

            <div className="col-md-6 col-xl-8 px-0 col-h-fill">
                <Outlet context={profile} />
            </div>

        </div>

    </div>
}

export function UserData({ user, expand = false }: { user: IUser | undefined, expand?: boolean }) {

    const [more, setMore] = useState<boolean>(!expand)

    useLayoutEffect(() => {
        ToggleMore()
    }, [])

    function ToggleMore() {
        let btnMore = $("#btnMore")

        if (more) {
            setMore(false)
            btnMore.children("span").text("Plus")
            btnMore.children("i").removeClass("bi-chevron-up").addClass("bi-chevron-down")
        } else {
            setMore(true)
            btnMore.children("span").text("Moins")
            btnMore.children("i").removeClass("bi-chevron-down").addClass("bi-chevron-up")
        }
    }

    return <>

        <h3 className="section-title-2">Informations</h3>

        <article className="card">

            <header className='card-header justify-content-end'>
                <button id="btnMore" className="btn btn-link link-nav" onClick={() => ToggleMore()}><span>Plus</span><i className="bi bi-chevron-down"></i></button>
            </header>

            <section className="card-body">

                <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-1 row-cols-xxl-2">

                    <div className='col mb-3' style={{ minHeight: "3rem" }}>
                        <h6 className='item-title'><i className={ICONS.EMAIL + " me-2"}></i> Email</h6>

                        {user?.Email &&
                            <div>
                                <p className="item-text d-inline me-3">{user?.Email}</p>
                                <a href={"mailto:" + user?.Email} className="d-inline link-nav"><i className={ICONS.EMAIL_SEND}></i></a>
                            </div>
                        }
                    </div>

                    <div className='col mb-3' style={{ minHeight: "3rem" }}>
                        <h6 className="item-title"><i className={ICONS.PHONE + " me-2"}></i> Téléphone</h6>

                        {user?.Phone &&
                            <div>
                                <p className="item-text d-inline me-3">{user.Phone}</p>
                                <a href={"tel:" + user.Phone} className="d-inline link-nav"><i className={ICONS.PHONE_CALL}></i></a>
                            </div>
                        }
                    </div>

                    <div className='col-12 w-100 mb-3' style={{ minHeight: "3rem" }}>
                        <h6 className="item-title"><i className={ICONS.ADDRESS + " me-2"}></i> Adresse</h6>

                        {user?.UserAddress &&
                            <div>
                                <p className="item-text d-inline me-3">{user?.UserAddress}</p>
                                <a href={"https://www.google.com/maps/search/?api=1&query=" + user?.UserAddress.replace(" ", "+")} target="_blank" className="d-inline link-nav"><i className={ICONS.MAP + " me-2"}></i></a>
                            </div>
                        }
                    </div>

                    {more && <>

                        <div className='col mb-3' style={{ minHeight: "3rem" }}>
                            <h6 className="item-title"><i className={ICONS.MANAGER + " me-2"}></i> Responsable</h6>

                            {user?.UserManagerID &&
                                <div>
                                    <p className="item-text d-inline me-3">{user?.ManagerFirstName} {user?.ManagerName}</p>
                                    {PrivateComponent(
                                        <Link to={"/users/" + user.UserManagerID} className="d-inline link-nav"><i className={ICONS.MANAGER + " me-2"}></i></Link>,
                                        GROUP.rh
                                    )}
                                </div>
                            }
                        </div>

                        <div className='col mb-3' style={{ minHeight: "3rem" }}>
                            <h6 className="item-title"><i className={ICONS.CONTRACT + " me-2"}></i> Date de contrat</h6>
                            {user?.UserDateContract && <p className="item-text">{dateFormat(user?.UserDateContract, "dd MMM yyyy")} ({Math.abs(Math.round((new Date().getTime() - new Date(user?.UserDateContract).getTime()) / 1000 / (60 * 60 * 24) / 365.25))} ans)</p>}
                        </div>

                        <div className='col mb-3' style={{ minHeight: "3rem" }}>
                            <h6 className="item-title"><i className={ICONS.AVS + " me-2"}></i> AVS</h6>
                            <p className="item-text">{user?.UserAVS}</p>
                        </div>

                        <div className='col mb-3' style={{ minHeight: "3rem" }}>
                            <h6 className="item-title"><i className={ICONS.CIVILSTATUS + " me-2"}></i> Etat civil</h6>
                            <p className="item-text">{user?.UserCivilStatus}</p>
                        </div>

                        <div className='col mb-3' style={{ minHeight: "3rem" }}>
                            <h6 className="item-title"><i className={ICONS.NATIONALITY + " me-2"}></i> Nationalité</h6>
                            <p className="item-text">{user?.UserNationality}</p>
                        </div>

                        <div className='col mb-3' style={{ minHeight: "3rem" }}>
                            <h6 className="item-title"><i className={ICONS.BIRTHDAY + " me-2"}></i> Anniversaire</h6>
                            <p className="item-text">{dateFormat(user?.UserBirthday, "dd/MM/yyyy")}</p>
                        </div>
                    </>}
                </div>

            </section>

        </article >
    </>
}


function AccountCounterStatement({ UserID, reload, WeekID }: { UserID: number, reload: boolean, WeekID: any }) {

    const [nbStatements, setNbStatements] = useState<number>(0)

    useEffect(() => {
        reqGet("counterstatement/user/" + UserID + "/week/" + WeekID + "/nbstatements").then(res => {
            setNbStatements(res.NBStatements);
        })
    }, [UserID, WeekID])


    useEffect(() => {
        if (reload) {
            reqGet("counterstatement/user/" + UserID + "/week/" + WeekID + "/nbstatements").then(res => {
                setNbStatements(res.NBStatements);
            })
        }
    }, [reload])


    return <NavLink className='list-item card-hover' to="counter-statement">
        <div className="list-item-start">
            <i className={ICONS.COUNTER + " text-color"} style={{ fontSize: "1.8rem" }}></i>
        </div>

        <div className="list-item-data">
            <h6 className="mb-1">Relevés compteurs</h6>
            <p className="d-inline mb-0">{nbStatements} compteurs</p>
        </div>

        <div className='list-item-more'>
            <i className="bi bi-chevron-right"></i>
        </div>
    </NavLink>
}

function AccountHeating({ UserID, reload }: { UserID: number, reload: boolean }) {
    const [heating, setHeating] = useState<Array<IHeating>>([])

    useEffect(() => {
        reqGet("heating/user/" + UserID).then((res) => {
            setHeating(res)
        })
    }, [])

    useEffect(() => {
        if (reload)
            reqGet("heating/user/" + UserID).then((res) => {
                setHeating(res)
            })
    }, [reload])

    return <NavLink className='list-item card-hover' to="heating">
        <div className="list-item-start">
            <i className={ICONS.HEATING + " text-color"} style={{ fontSize: "1.8rem" }}></i>
        </div>

        <div className="list-item-data">
            <h6 className="mb-1">{SERVICE.HEATING}</h6>
            <p className="d-inline mb-0">{heating.length} adresses</p>
        </div>

        <div className='list-item-more'>
            <i className="bi bi-chevron-right"></i>
        </div>
    </NavLink>
}

function AccountHoliday({ UserID, reload }: { UserID: number, reload: boolean }) {
    const [waitingHolidays, setWaintingHolidays] = useState<Array<ITimesheet>>([])

    useEffect(() => {
        reqGet("holiday/user/" + UserID + "/waiting").then(res => setWaintingHolidays(res), rej => { })
    }, [reload])

    return <NavLink className='list-item card-hover' to="holiday">
        <div className="list-item-start">
            <i className={ICONS.HOLIDAY + " text-color"} style={{ fontSize: "1.8rem" }}></i>
        </div>

        <div className="list-item-data">
            <h6 className="mb-1">Congé {BADGE.BETA}</h6>
            <p className="d-inline mb-0">{waitingHolidays.length} congés en attente de réponse</p>
        </div>

        <div className='list-item-more'>
            <i className="bi bi-chevron-right"></i>
        </div>
    </NavLink>
}

function AccountMission({ UserID, reload }: { UserID: number, reload: boolean }) {
    const [missions, setMissions] = useState<Array<IMission>>([])

    useEffect(() => {
        reqGet("mission/user/" + UserID + "/date/" + DateToday()).then(res => setMissions(res))
    }, [])

    useEffect(() => {
        if (reload)
            reqGet("mission/user/" + UserID + "/date/" + DateToday()).then(res => setMissions(res))
    }, [reload])

    return <NavLink className='list-item card-hover' to="mission">
        <div className="list-item-start">
            <i className={ICONS.MISSION + " text-color"} style={{ fontSize: "1.8rem" }}></i>
        </div>

        <div className="list-item-data">
            <h6 className="mb-1">Mission {BADGE.BETA}</h6>
            <p className="d-inline mb-0">{missions.length} mission{missions.length > 1 ? "s" : ""} aujourd'hui</p>
        </div>

        <div className='list-item-more'>
            <i className="bi bi-chevron-right"></i>
        </div>
    </NavLink>
}

function AccountPicket({ UserID, reload }: { UserID: number, reload: boolean }) {
    const [duration, setDuration] = useState<string>("00:00")

    useEffect(() => {
        reqGet("timesheet/type/picket/user/" + UserID + "/today").then((res: Array<ITimesheet>) => {
            var minutes = 0
            res.forEach(t => {
                var timeBetween = TimeCalculation(t?.TimesheetStart, t?.TimesheetEnd ?? new Date())

                minutes += parseInt(timeBetween.split(":")[0]) * 60 + parseInt(timeBetween.split(":")[1])
            })
            setDuration((Math.floor(minutes / 60) < 9 ? "0" : "") + Math.floor(minutes / 60) + ":" + (minutes % 60 < 9 ? "0" : "") + minutes % 60)
        })
    }, [])


    useEffect(() => {
        if (reload)
            reqGet("timesheet/type/picket/user/" + UserID + "/today").then((res: Array<ITimesheet>) => {
                var minutes = 0
                res.forEach(t => {
                    var timeBetween = TimeCalculation(t?.TimesheetStart, t?.TimesheetEnd ?? new Date())

                    minutes += parseInt(timeBetween.split(":")[0]) * 60 + parseInt(timeBetween.split(":")[1])
                })
                setDuration((Math.floor(minutes / 60) < 9 ? "0" : "") + Math.floor(minutes / 60) + ":" + (minutes % 60 < 9 ? "0" : "") + minutes % 60)
            })
    }, [reload])

    return <NavLink className='list-item card-hover' to="picket">
        <div className="list-item-start">
            <i className={ICONS.PICKET + " text-color"} style={{ fontSize: "1.8rem" }}></i>
        </div>

        <div className="list-item-data">
            <h6 className="mb-1">Piquet</h6>
            <p className="d-inline mb-0">{duration} aujourd'hui</p>
        </div>

        <div className='list-item-more'>
            <i className="bi bi-chevron-right"></i>
        </div>
    </NavLink>
}

function AccountSurvey({ UserID, reload }: { UserID: number, reload: boolean }) {
    const [survey, setSurvey] = useState<Array<IAddress>>([])

    useEffect(() => {
        reqPost("address/survey", { UserID: UserID }).then((res) => {
            setSurvey(res)
        })
    }, [])

    useEffect(() => {
        if (reload)
            reqPost("address/survey", { UserID: UserID }).then((res) => {
                setSurvey(res)
            })
    }, [reload])

    return <NavLink className='list-item card-hover' to="survey">
        <div className="list-item-start">
            <i className={ICONS.SURVEY + " text-color"} style={{ fontSize: "1.8rem" }}></i>
        </div>

        <div className="list-item-data">
            <h6 className="mb-1">Surveillances</h6>
            <p className="d-inline mb-0">{survey.length} adresses</p>
        </div>

        <div className='list-item-more'>
            <i className="bi bi-chevron-right"></i>
        </div>
    </NavLink>
}

function AccountTask({ UserID, reload }: { UserID: number, reload: boolean }) {
    const [tasks, setTasks] = useState<Array<ITask>>([])
    const date = new Date()
    /*
        useEffect(() => {
            reqGet("task/user/" + UserID + "/date/" + date.toISOString().slice(0, 10)).then(res => setTasks(res))
        }, [])
    
        useEffect(() => {
            if (reload)
                reqGet("task/user/" + UserID + "/date/" + date.toISOString().slice(0, 10)).then(res => setTasks(res))
        }, [reload])*/

    return <NavLink className='list-item card-hover' to="task">
        <div className="list-item-start">
            <i className={ICONS.TASK + " text-color"} style={{ fontSize: "1.8rem" }}></i>
        </div>

        <div className="list-item-data">
            <h6 className="mb-1">Tâches {BADGE.DEV}</h6>
            <p className="d-inline mb-0">{tasks.length} tâches aujourd'hui</p>
        </div>

        <div className='list-item-more'>
            <i className="bi bi-chevron-right"></i>
        </div>
    </NavLink>
}

function AccountTimesheet({ UserID, reload }: { UserID: number, reload: boolean }) {
    const [duration, setDuration] = useState<string>("00:00")

    useEffect(() => {
        reqGet("timesheet/type/timesheet/user/" + UserID + "/today").then((res: ITimesheet) => {

            setDuration(TimeCalculation(res?.TimesheetStart, ((!res?.TimesheetLunchStart || res.TimesheetLunchEnd) && !res?.TimesheetEnd) ? new Date() : res?.TimesheetEnd, res?.TimesheetLunchStart, res?.TimesheetLunchEnd))
        })
    }, [])


    useEffect(() => {
        if (reload)
            reqGet("timesheet/type/timesheet/user/" + UserID + "/today").then((res: ITimesheet) => {
                setDuration(TimeCalculation(res?.TimesheetStart, ((!res?.TimesheetLunchStart || res.TimesheetLunchEnd) && !res?.TimesheetEnd) ? new Date() : res?.TimesheetEnd, res?.TimesheetLunchStart, res?.TimesheetLunchEnd))
            })
    }, [reload])

    return <NavLink className='list-item card-hover' to="timesheet">
        <div className="list-item-start">
            <i className={ICONS.TIMESHEET + " text-color"} style={{ fontSize: "1.8rem" }}></i>
        </div>

        <div className="list-item-data">
            <h6 className="mb-1">Pointage</h6>
            <p className="d-inline mb-0">{duration} aujourd'hui</p>
        </div>

        <div className='list-item-more'>
            <i className="bi bi-chevron-right"></i>
        </div>
    </NavLink>
}

function AccountVehicles({ UserID, reload }: { UserID: number, reload: boolean }) {
    const [vehicles, setVehicles] = useState<Array<IVehicle>>([])

    useEffect(() => {
        reqGet("vehicle/user/" + UserID).then((res) => {

            if (res.length > 0) {
                setVehicles(res)
            }
        })
    }, [])

    useEffect(() => {
        if (reload)
            reqGet("vehicle/user/" + UserID).then((res) => {

                if (res.length > 0) {
                    setVehicles(res)
                }
            })
    }, [reload])

    return <>
        {vehicles.length > 0 &&
            <>
                <h3 className="section-title-2 mt-2">{vehicles.length > 1 ? "Mes véhicules" : "Mon véhicule"}</h3>

                {vehicles?.map((v: IVehicle, k: number) => {
                    return <Link to={"vehicle/" + v.VehicleID} key={k}>
                        <div className="list-item card-hover">
                            <div className="list-item-start">
                                <i className={ICONS.VEHICLES + " text-color"} style={{ fontSize: "1.8rem" }}></i>
                            </div>

                            <div className="list-item-data">
                                <h5 className="item-title text-truncate mb-1 pe-3">{v.VehicleNumber ? "N°" + v.VehicleNumber + " |" : ""} {v?.Model} {v?.Year}</h5>

                                <p className="paragraph mb-0">{v?.Numberplate}</p>
                            </div>

                            <div className='list-item-more'>
                                <i className="bi bi-chevron-right"></i>
                            </div>
                        </div>
                    </Link>
                })}
            </>
        }
    </>
}

function AccountVehiclesGuarantor({ UserID, reload }: { UserID: number, reload: boolean }) {
    const [vehiclesGuarantor, setVehiclesGuarantor] = useState<Array<IVehicle>>([])

    useEffect(() => {
        reqGet("vehicle/guarantor/" + UserID).then(res => {
            setVehiclesGuarantor(res)
        }, rej => { })
    }, [reload])

    return <Link className='list-item card-hover' to="vehicle/guarantor">
        <div className="list-item-start">
            <i className={ICONS.VEHICLES_GUARANTOR + " text-color"} style={{ fontSize: "1.8rem" }}></i>
        </div>

        <div className="list-item-data">
            <h6 className="mb-1">Répondant de véhicules {BADGE.BETA}</h6>
            <p className="d-inline mb-0">{vehiclesGuarantor.length} véhicules</p>
        </div>

        <div className='list-item-more'>
            <i className="bi bi-chevron-right"></i>
        </div>
    </Link>
}