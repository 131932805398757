import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'

import { getToken } from '../../../useToken'
import { GROUP, DEFAULT_IMG, LAYOUT } from '../../common/constants'
import { reqGet, reqPost, reqPut } from "../../../service/apiRequest";
import { IStorageProduct, IRack } from "../../common/types"


interface IInventoryGlobal extends IStorageProduct {
    Validate: boolean
}
export default function StorageInventoryPicking() {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idWarehouse = params.idWarehouse

    const [data, setData] = useState<Array<IInventoryGlobal>>([])
    const [rackList, setRackList] = useState<Array<IRack>>([])
    const [inventoryValid, setInventoryValid] = useState<boolean>(false)

    const [filter, setFilter] = useState<string>("A")
    useEffect(() => {
        reqGet("product/warehouse/" + idWarehouse).then(res => { setData(res) })

        reqGet("storage/warehouse/" + idWarehouse + "/rack").then(res => { setRackList(res) })
    }, [])


    function HandleRackSort(btn, rack) {
        $("#rackNav .btn.active").removeClass("active")
        $(btn).addClass("active")

        setFilter(rack)
    }

    function ClearRackSort(btn) {
        $("#rackNav .btn.active").removeClass("active")
        $(btn).addClass("active")
        setFilter("All")
    }

    function UnvalidateSort(btn) {
        $("#rackNav .btn.active").removeClass("active")
        $(btn).addClass("active")
        setFilter("Validate")
    }


    function ValidateQuantity(idProduct) {

        const newData = data.map(product => {
            if (idProduct == product.ProductID) {
                if (product.Validate == true) {
                    return { ...product, Validate: false }
                } else {
                    return { ...product, Validate: true }
                }
            }
            return product;
        });

        if (newData.filter(item => item.Validate != true).length == 0) {
            setInventoryValid(true)
        } else if (inventoryValid) {
            setInventoryValid(false)
        }

        setData(newData);
    }

    function UpdateQuantity(btn, idProduct) {

        const newData = data.map(product => {
            if (idProduct == product.ProductID) {
                return { ...product, Quantity: btn.currentTarget.value }
            }
            return product;
        });
        setData(newData);

    }

    const TableFiltered = () => {
        if (filter === "") {
            setFilter("A")
            return data.filter(item => item.Rack === "A")
        } else if (filter == "Validate") {
            return data.filter(item => item.Validate != true)
        } else if (filter == "All") {
            return data
        } else {
            return data.filter(item => item.Rack === filter)
        }
    }

    function ConfirmInventory() {
        const inventory = {
            ActivityUserID: token.UserID,
            Data: data
        }

        reqPut("warehouse/inventory/" + idWarehouse + "/global", inventory).then(res => {
            navigate("/warehouse")
        })

    }

    function SaveInventory() {
        const inventory = {
            ActivityUserID: token.UserID,
            Data: data
        }
        reqPut("warehouse/inventory/" + idWarehouse + "/save", inventory).then(res => {
            

            //navigate("/warehouse")
        })

    }

    return <section className="body-container">

        <div className="modal fade" id="saveModal" tabIndex={-1} aria-labelledby="saveModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="saveModalLabel">Confirmer inventaire</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>La sauvegarde est temporaire et ne valide pas l'état actuel de l'inventaire</p>
                        <p>Êtes-vous sûr de vouloir sauvegarder l'inventaire ?</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => SaveInventory()}>Sauvegarder</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="confirmModalLabel">Confirmer inventaire</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Êtes-vous sûr d'avoir terminé l'inventaire ?</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => ConfirmInventory()}>Confirmer</button>
                    </div>
                </div>
            </div>
        </div>

        <section className="header">
            <div className="d-flex flex-wrap w-100 mb-3">

                <div className="col-3 col-lg-4 order-2 order-lg-1">
                    <Link to={"/warehouse/" + idWarehouse} className="link-nav">{"<"} Retour</Link>
                </div>

                <div className="col-12 col-lg-4 order-lg-2 order-1 text-lg-center mb-3">
                    <h3 className="section-title">Inventaire global </h3>
                </div>

                <div className="col-9 col-lg-4 order-3 text-end">
                    <button className="btn btn-outline mb-3" title="Sauvegarder" disabled={inventoryValid} data-bs-toggle="modal" data-bs-target="#saveModal">
                        <i className="bi bi-device-ssd me-1"></i> Sauvegarder
                    </button>

                    <button className="btn btn-primary ms-3 mb-3" title="Confirmer" disabled={!inventoryValid} data-bs-toggle="modal" data-bs-target="#confirmModal">
                        <i className="bi bi-plus-lg me-1"></i> Confirmer
                    </button>
                </div>

            </div>

            <section className="nav-horizontal mb-0 justify-content-md-center" id="rackNav">
                <button className="btn btn-outline me-3" style={{ fontSize: "1.1rem" }} onClick={(e) => ClearRackSort(e.currentTarget)}>
                    Tout
                </button>

                <button className="btn btn-outline me-3" style={{ fontSize: "1.1rem" }} onClick={(e) => UnvalidateSort(e.currentTarget)}>
                    Non validé
                </button>

                {rackList.map(rack => {
                    return <button key={rack.Rack} className={"btn btn-outline me-3 " + (rack.Rack === "A" ? "active" : "")} style={{ fontSize: "1.1rem", minWidth: "2.5rem" }} onClick={(e) => HandleRackSort(e.currentTarget, rack)}>
                        {rack.Rack}
                    </button>
                })}
            </section>

        </section>
        <div className="d-inline mb-3">
            <p className="d-inline me-3"><span className="text-muted">Validé: </span>{data.filter(item => item.Validate == true).length}/{data.length}</p>
            <p className="d-inline me-3"><span className="text-muted">Pièces: </span>{TableFiltered().length}</p>
        </div>

        <section className="body">

            <div className="inventory-list">

                {TableFiltered().map(product => {

                    return <div className="product-item" key={product.ProductID}>
                        <div className="product-img ps-md-3">
                            <img className="border" src={product?.ProductImage ? "../media/images/product/" + product?.ProductImage : DEFAULT_IMG} alt="" />
                        </div>

                        <div className="product-data pe-md-3">
                            <div className="row d-flex">

                                <div className="col-8 ps-0 ps-md-3">
                                    <h6 className="title text-truncate">{product.ProductModel}</h6>
                                    <p className="text-light text-truncate mb-1">{product.ProductBrand} - {product.ProductReference}</p>
                                    {product.Rack && <h6 className="mb-0 text-truncate">{product.Rack}{product.Row} - {product.Col}</h6>}
                                </div>

                                <div className="col-4 col-md-2 pe-0">
                                    <h6 className="title text-truncate">Quantité</h6>
                                    <input type="number" className="form-control" defaultValue={product?.Quantity ? product.Quantity : 0} min={0} onChange={(e) => UpdateQuantity(e, product.ProductID)} />
                                </div>

                            </div>
                        </div>

                        <div className="product-more">
                            <button className={"btn " + (product.Validate == true ? "btn-success" : "btn-outline")} onClick={() => ValidateQuantity(product.ProductID)}>
                                <span className="d-none d-md-inline">Valider</span> <i className="bi bi-check-lg"></i>
                            </button>
                        </div>
                    </div>
                })}

            </div>

        </section>



    </section>
}

