import React, { useEffect, useState, useMemo } from 'react'
import { Link, NavLink, useLocation, Outlet } from 'react-router-dom'
import $ from 'jquery'

import { reqGet } from "../../../../service/apiRequest";
import { StorageAvailabilityBadge } from '../../storage/storage';
import { fuzzyFilter, PrivateLinkAdd, ProgressColor, DebouncedInput, Header } from '../../../common/common'
import { LAYOUT, DEFAULT_IMG, GROUP, PERMISSION, PRODUCT_AVAILABLE, ICONS } from '../../../common/constants'
import { IBrand, IProduct } from '../../../common/types'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
} from '@tanstack/react-table'


export default function Tool({ reload, setReload }) {
    const location = useLocation()

    const table = ToolTable(reload, setReload)
    const [brands, setBrands] = useState<Array<IBrand>>([])

    useEffect(() => {
        reqGet("brand").then(res => setBrands(res))
    }, [])

    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/tool") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    function FilterBrand(brand: string) {
        table.getColumn("ProductBrand")?.setFilterValue(brand)
    }

    function FilterStatus(id: number) {
        const column = table.getColumn("ProductAvailable")

        if (column)
            column.setFilterValue(id)
    }

    return <div className="h-100 content">
        <div className="row h-100">

            <div className={display + " col-md-6 col-lg-7 col-xl-8 col-h-fill"}>
                <div className="body-container">

                    {Header(
                        <h2 className="section-title">Outils</h2>,
                        undefined,
                        PrivateLinkAdd("add", GROUP.crud_sav, PERMISSION.product)
                    )}


                    <section className='container-fluid'>
                        <div className="row mb-3">

                            <div className="col col-lg-3">
                                <label className="form-label mb-1">Marque</label>
                                <select className="form-select" onChange={(e) => FilterBrand(e.target.value)}>
                                    <option value="">Tout</option>

                                    {brands.map((b) => {
                                        return <option value={b.Name} key={b.BrandID}>{b.Name}</option>
                                    })}
                                </select>
                            </div>

                            <div className="col-lg offset-lg-4 mt-3 mt-lg-0 h-lg-100 d-flex align-items-end">
                                <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche" />
                            </div>

                        </div>
                    </section>



                    <div className="body container-fluid">

                        <div className="list list-hover">

                            {table.getRowModel().rows.map(row => {
                                return <NavLink to={row.original.ProductID.toString()} key={row.original.ProductID} className="list-item list-item-sm" >
                                    <ToolItem tool={row.original} />
                                </NavLink>
                            })}

                        </div>


                    </div>

                    <footer className="container-fluid mt-3">
                        <div className="row mx-0 pagination">

                            <div className="col-8 col-lg-4 px-0 text-start order-2 order-lg-1">
                                <button className="btn btn-outline me-2" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                                    {'<<'}
                                </button>
                                <button className="btn btn-outline me-2" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                                    {'<'}
                                </button>

                                <p className="d-inline mb-0 me-2">{table.getState().pagination.pageIndex + 1} / {table.getPageCount()}</p>

                                <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                                    {'>'}
                                </button>
                                <button className="btn btn-outline me-2" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                                    {'>>'}
                                </button>
                            </div>

                            <div className="col-12 col-lg-4 text-center order-1 order-lg-2 mb-3 mb-lg-0">
                                <p className="paragraph mb-0">
                                    {table.getCoreRowModel().rows.length} outils - {table.getCoreRowModel().rows.reduce((accumulator, row) => {
                                        return accumulator + parseInt(row.original.Quantity ?? "0");
                                    }, 0)} en stock
                                </p>
                            </div>

                        </div>

                    </footer>

                </div>


            </div>

            <div className="col-md-6 col-lg-5 col-xl-4 col-h-fill">
                <Outlet />
            </div>

        </div>
    </div>
}

function ToolItem({ tool }) {
    return <>
        <div className="list-item-start">
            <div className="item-start">
                {tool?.ProductImage ?
                    <img className="img-fluid" src={tool?.ProductImage ? "../media/images/product/" + tool?.ProductImage : DEFAULT_IMG} alt="" />
                    : <div className="bg-background-light p-2 rounded-5 h-100 w-100 d-flex align-items-center justify-content-center">
                        <i className={ICONS.TOOL + " text-muted"} style={{ fontSize: "1.6rem" }}></i>
                    </div>
                }
            </div>
        </div>

        <section className="list-item-data pe-md-3">
            <div className="row d-flex">

                <div className="col-7 col-sm-5">
                    <h6 className="title text-truncate">{tool?.ProductModel}</h6>
                    <p className="text-muted mb-1">
                        {tool?.ProductBrand} {tool?.ProductBrand && tool?.ProductReference && " - "} {tool?.ProductReference}
                    </p>
                </div>

                <div className="col-5 col-sm-7 text-end">

                    <div className="row justify-content-end row-cols-sm-2 row-cols-lg-3">

                        <div className="col">
                            <h6 className="text-muted">Status</h6>
                            <p className="paragraph text-nowrap text-truncate">
                                {StorageAvailabilityBadge(tool.ProductAvailable)}
                            </p>
                        </div>
                        <div className="col d-none d-sm-block">
                            {tool.ProductAvailable == PRODUCT_AVAILABLE.IN_STOCK && <>
                                <h6 className="text-muted">Entrepôt</h6>
                                <p className="paragraph mb-0 text-truncate">{tool?.WarehouseName}</p>
                            </>
                            }
                            {tool.ProductAvailable == PRODUCT_AVAILABLE.OUT &&
                                <>
                                    <h6 className="text-muted">Emplacement</h6>
                                    <p className="paragraph mb-0 text-truncate">{tool?.ProductLocation}</p>
                                </>
                            }
                            {tool.ProductAvailable == PRODUCT_AVAILABLE.REPAIRING &&
                                <>
                                    <h6 className="text-muted">Réparation</h6>
                                    <p className="paragraph mb-0 text-truncate">{tool?.RepairLocation}</p>
                                </>
                            }
                        </div>

                        <div className="col d-none d-lg-block">
                            <h6 className="text-muted">Stockage</h6>
                            <p className="paragraph text-truncate">
                                {tool?.WarehouseName ?
                                    tool?.Rack + tool?.Row + "-" + tool?.Col + (tool?.Compartment ? "-" + tool?.Compartment : "") :
                                    ""
                                }
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </section>

        <div className="tool-item-more d-flex d-md-none">
            <i className="bi bi-chevron-right"></i>
        </div>
    </>
}

export function ToolTable(reload?: boolean, setReload?) {

    const [data, setData] = useState<Array<IProduct>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const columns = useMemo<ColumnDef<IProduct, any>[]>(
        () => [
            {
                accessorFn: row => row.ProductModel,
                id: 'ProductModel',
                header: 'Modèle',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductBrand,
                id: 'ProductBrand',
                header: 'Marque',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductAvailable,
                id: 'ProductAvailable',
                header: 'Status',
                cell: info => info.getValue()
            }

        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        table.setPageSize(50)

        reqGet('product/tool').then((res) => { setData(res); })
    }, [])

    useEffect(() => {
        if (reload) {
            reqGet('product/tool').then((res) => { setData(res); })
            setReload(false)
        }
    }, [reload])

    return table
}