import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

import { reqGet, reqPost } from '../service/apiRequest'
import { getToken } from '../useToken'
import { IAddress, IManagement, IZipCode } from "./common/types"
import { HeaderRow } from './common/common'

export default function Notification() {

    useEffect(() => {
        
    },[])

    return <>
        <header className="notification-menu-header">
            <div className="d-flex justify-content-between align-items-start mb-3">
                <h4>Notifications</h4>
                <button className="btn link-nav"><small><i className="bi bi-check-all me-1"></i>Marquer tout comme lu</small></button>
            </div>

            <nav className="nav-horizontal pb-0 mb-0">
                <button className="btn nav-link pb-1 active">Tout</button>
                <button className="btn nav-link pb-1"><i className="bi bi-flag"></i></button>
            </nav>
        </header>

        <section className="notification-menu-body">
            <ul className="notification-list">

                <li className="notification-list-item">

                    <div className="notification-thumbnail">
                        <div className="thumbnail tb-warning">
                            <i className="bi bi-exclamation-circle"></i>
                        </div>
                    </div>

                    <div className="notification">
                        <p className="notification-description">Le contrôle véhicule effectué par <b>Marc Vachon</b> sur le <b>véhicule N°2</b> n'est pas conforme.</p>

                        <footer className="notification-footer">
                            <p>09:46, 17.08.22</p>
                            <i className="bi bi-dot"></i>
                            <p>Contrôle véhicule</p>
                        </footer>
                    </div>

                </li>

                <Link to="/vehicle/40" className="notification-list-item">

                    <div className="notification-thumbnail">
                        <div className="thumbnail tb-info">
                            <i className="bi bi-info-circle"></i>
                        </div>
                    </div>

                    <div className="notification">
                        <p className="notification-description"><b>Marc Vachon</b> à réalisé un contrôle véhicule dont vous êtes répondant</p>

                        <footer className="notification-footer">
                            <p>09:46, 17.08.22</p>
                            <i className="bi bi-dot"></i>
                            <p>Contrôle véhicule</p>
                        </footer>
                    </div>

                </Link>

                <li className="notification-list-item">

                    <div className="notification-thumbnail">
                        <div className="thumbnail tb-info">
                            <i className="bi bi-info-circle"></i>
                        </div>
                    </div>

                    <div className="notification">
                        <p className="notification-description"><b>Florian Halhoute</b> à réalisé le contrôle du <b>véhicule N°3</b></p>

                        <footer className="notification-footer">
                            <p>09:46, 17.08.22</p>
                            <i className="bi bi-dot"></i>
                            <p>Contrôle véhicule</p>
                        </footer>
                    </div>
                </li>

                <li className="notification-list-item">

                    <div className="notification-thumbnail">
                        <div className="thumbnail tb-info">
                            <i className="bi bi-info-circle"></i>
                        </div>
                    </div>

                    <div className="notification">
                        <p className="notification-description"><b>William Ganière</b> à réalisé un contrôle véhicule</p>

                        <footer className="notification-footer">
                            <p>09:46, 17.08.22</p>
                            <i className="bi bi-dot"></i>
                            <p>Contrôle véhicule</p>
                        </footer>
                    </div>
                </li>

                <li className="notification-list-item">

                    <div className="notification-thumbnail">
                        <div className="thumbnail tb-info">
                            <i className="bi bi-info-circle"></i>
                        </div>
                    </div>

                    <div className="notification">
                        <p className="notification-description"><b>William Ganière</b> à réalisé un contrôle véhicule</p>

                        <footer className="notification-footer">
                            <p>09:46, 17.08.22</p>
                            <i className="bi bi-dot"></i>
                            <p>Contrôle véhicule</p>
                        </footer>
                    </div>

                </li>

                <li className="notification-list-item">

                    <div className="notification-thumbnail">
                        <div className="thumbnail tb-info">
                            <i className="bi bi-info-circle"></i>
                        </div>
                    </div>

                    <div className="notification">
                        <p className="notification-description"><b>William Ganière</b> à réalisé un contrôle véhicule</p>

                        <footer className="notification-footer">
                            <p>09:46, 17.08.22</p>
                            <i className="bi bi-dot"></i>
                            <p>Contrôle véhicule</p>
                        </footer>
                    </div>

                </li>

                <li className="notification-list-item">

                    <div className="notification-thumbnail">
                        <div className="thumbnail tb-info">
                            <i className="bi bi-info-circle"></i>
                        </div>
                    </div>

                    <div className="notification">
                        <p className="notification-description"><b>William Ganière</b> à réalisé un contrôle véhicule</p>

                        <footer className="notification-footer">
                            <p>09:46, 17.08.22</p>
                            <i className="bi bi-dot"></i>
                            <p>Contrôle véhicule</p>
                        </footer>
                    </div>

                </li>

                <li className="notification-list-item">

                    <div className="notification-thumbnail">
                        <div className="thumbnail tb-info">
                            <i className="bi bi-info-circle"></i>
                        </div>
                    </div>

                    <div className="notification">
                        <p className="notification-description"><b>William Ganière</b> à réalisé un contrôle véhicule</p>

                        <footer className="notification-footer">
                            <p>09:46, 17.08.22</p>
                            <i className="bi bi-dot"></i>
                            <p>Contrôle véhicule</p>
                        </footer>
                    </div>

                </li>

                <li className="notification-list-item">

                    <div className="notification-thumbnail">
                        <div className="thumbnail tb-info">
                            <i className="bi bi-info-circle"></i>
                        </div>
                    </div>

                    <div className="notification">
                        <p className="notification-description"><b>William Ganière</b> à réalisé un contrôle véhicule</p>

                        <footer className="notification-footer">
                            <p>09:46, 17.08.22</p>
                            <i className="bi bi-dot"></i>
                            <p>Contrôle véhicule</p>
                        </footer>
                    </div>

                </li>

            </ul>
        </section>
    </>
}

