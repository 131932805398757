import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { reqGet } from "../../../../service/apiRequest";
import { getToken } from '../../../../useToken'
import { PrivateComponent, dateFormat } from '../../../common/common'
import { GROUP, ICONS } from '../../../common/constants'
import { IProduct, IPrice } from '../../../common/types'


export default function ProductContent({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idProduct = params.idProduct

    const [product, setProduct] = useState<IProduct>()
    const [oldPrices, setOldPrices] = useState<Array<IPrice>>([])

    useEffect(() => {
        reqGet("product/" + idProduct).then(res => { setProduct(res) })

        reqGet("price/product/" + idProduct).then(res => {
            // remove first one => actual one
            setOldPrices(res.slice(1));
        }, rej => {
            setOldPrices([])
        })
    }, [idProduct])

    return <section className="body-container">


        <header className='container-fluid d-flex justify-content-between'>
            <div>
                <h3 className="section-title">Valeur</h3>
                <Link className="link-nav mb-3 d-md-none" to={'/product-price'}>{'< Retour'}</Link>
            </div>
            <div>
                {PrivateComponent(
                    <Link to="add" className="btn btn-add ms-3" title="Nouvelle valeur">
                        <i className={ICONS.ADD + " me-1"}></i> Nouvelle valeur
                    </Link>,
                    GROUP.crud_sav,
                    ["PriceCreate"]
                )}
            </div>
        </header>



        <section className="body container-fluid mt-3">
            <article className="card flex-row justify-content-between mb-3">
                <div>
                    <h4 className="mb-1">{product?.ProductModel}</h4>
                    <p className="mb-1">{product?.ProductBrand} {product?.ProductBrand && product?.ProductReference && " - "} {product?.ProductReference}</p>
                </div>

                <Link to={"/piece/" + product?.ProductID} className="text-nowrap link-nav">Détails {">"}</Link>
            </article>


            {product?.SupplierID &&
                <>
                    <div className="d-flex justify-content-between mt-3 mb-3">
                        <div>
                            <h3 className="section-title-2 mb-0">Valeur actuel</h3>
                        </div>

                        <div>
                            {PrivateComponent(
                                <Link to="update" className="btn btn-outline ms-3" title="Modifier valeur">
                                    <i className={ICONS.UPDATE + " me-1"}></i> Modifier
                                </Link>,
                                GROUP.crud_sav,
                                ["PriceUpdate"]
                            )}
                        </div>
                    </div>

                    <div className="row row-cols-2">

                        <div className="col">
                            <article className="card">
                                <header className="card-header pb-0">
                                    <h5 className="card-title">Fournisseur</h5>
                                </header>

                                <section className="card-body">
                                    <h5 className="text-nowrap mb-0">{product?.ProductSupplier}</h5>
                                </section>
                            </article>
                        </div>

                        <div className="col">
                            <article className="card">
                                <header className="card-header pb-0">
                                    <h5 className="card-title">Date</h5>
                                </header>

                                <section className="card-body">
                                    <h5 className="text-nowrap mb-0">{dateFormat(product?.PriceAdded, "dd/MM/yyyy")}</h5>
                                </section>
                            </article>
                        </div>
                    </div>

                    <div className="row row-cols-3 mb-3">

                        <div className="col">
                            <article className="card" style={{ height: "6.3rem" }}>
                                <header className="card-header pb-0">
                                    <h5 className="card-title">Prix facial</h5>
                                </header>

                                <section className="card-body">
                                    <h4 className="text-nowrap mb-0">{product?.Price}</h4>
                                </section>
                            </article>
                        </div>

                        <div className="col">
                            <article className="card" style={{ height: "6.3rem" }}>
                                <header className="card-header pb-0">
                                    <h5 className="card-title">Prix d'achat</h5>
                                </header>

                                <section className="card-body">
                                    <h4 className="d-block mb-0">{product?.PriceBuying}</h4>
                                    {<p className="d-block text-success mb-0">
                                        {(product?.Price && product?.PriceBuying) &&
                                            "-" + Math.round(100 - (product?.PriceBuying * 100 / product?.Price)) + "%"
                                        }
                                    </p>}
                                </section>
                            </article>
                        </div>

                        <div className="col">
                            <article className="card" style={{ height: "6.3rem" }}>
                                <header className="card-header pb-0">
                                    <h5 className="card-title">Prix de vente</h5>
                                </header>

                                <section className="card-body">
                                    <h4 className="d-inline-block mb-0">{product?.PriceSelling}</h4>
                                    {<p className="d-block text-success mb-0">
                                        {(product?.PriceSelling && product?.PriceBuying) &&
                                            "+" + Math.round(product?.PriceSelling - product?.PriceBuying)
                                        }
                                    </p>}
                                </section>
                            </article>
                        </div>

                    </div>
                </>}

            {oldPrices.length > 0 &&
                <>
                    <h3 className="section-title-2 mt-2">Historique du prix</h3>

                    { /* slice first row */
                        oldPrices.map(op => {
                            return <div className="card" key={op.PriceID}>
                                <header className="card-header justify-content-between">
                                    <h5 className="card-title">{op?.SupplierName}</h5>

                                    <h5 className="card-title">{dateFormat(op?.PriceAdded, "dd/MM/yyyy")}</h5>
                                </header>

                                <section className="card-body">
                                    <div className="row row-cols-3">
                                        <div className="col">
                                            <h6 className="text-muted">Prix facial</h6>
                                            <h6 className='mb-0'>{op?.Price}</h6>
                                        </div>

                                        <div className="col">
                                            <h6 className="text-muted">Prix d'achat</h6>
                                            <h6 className='mb-0'>{op?.PriceBuying}</h6>
                                        </div>

                                        <div className="col">
                                            <h6 className="text-muted">Prix de vente</h6>
                                            <h6 className='mb-0'>{op?.PriceSelling}</h6>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        })}
                </>
            }

        </section>
    </section >


}

