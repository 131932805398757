import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'

import { getToken } from '../../../useToken'
import { StorageAvailabilityBadge } from "../storage/storage"
import { AlertMsg, Header, QRScanner, ManualRef, ReplaceSpecialChars } from '../../common/common'
import { reqGet, reqPut } from "../../../service/apiRequest";
import { IAddress, IStorageProduct } from "../../common/types"
import { PRODUCT_AVAILABLE, PRODUCT_CLASS_ID, PRODUCT_CONDITION, PRODUCT_TYPE } from "../../common/constants";

export default function WarehouseTakeOut() {
    const params = useParams()
    const idWarehouse = params.idWarehouse
    const navigate = useNavigate()
    const token = getToken()

    const location = useLocation()
    const idStorage = location?.state?.idStorage

    const [reference, setReference] = useState<number | null>(null)
    const [storage, setStorage] = useState<IStorageProduct | null>(null)

    const [addresses, setAddresses] = useState<Array<IAddress>>([])
    const [addressesFiltered, setAddressesFiltered] = useState<Array<IAddress>>([])
    const [addressSelected, setAddressSelected] = useState<IAddress>()
    const [addressManual, setAddressManual] = useState<string | null>()

    const [entryMode, setEntryMode] = useState<string | null>(null)
    const [authSubmit, setAuthSubmit] = useState<boolean>(false)

    useEffect(() => {
        if (idStorage) {
            setEntryMode("qrCode")
            setReference(idStorage)
        }
    }, [idStorage])

    useEffect(() => {
        if (reference) {

            // checking storage ID
            reqGet("storage/" + reference + "/warehouse/" + idWarehouse).then(res => {
                if (res != false) {
                    setStorage(res)

                    // If nothing search with the product reference instead
                } else {

                    // checking product reference
                    reqGet("storage/productref/" + reference + "/warehouse/" + idWarehouse).then(result => {
                        if (result != false) {
                            setStorage(result)
                        } else {
                            setStorage(null)
                        }
                    })
                }

            })

            reqGet("address").then(res => { setAddresses(res) })
        }
    }, [reference])



    function HandleSearchAddress(value: string) {
        if (value === "") {
            setAddressSelected(undefined)
            setAddressesFiltered([])
        } else {
            value = ReplaceSpecialChars(value).trim().replace("/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/", "")

            var result = addresses.filter((a) => {
                var address = ReplaceSpecialChars(a.AddressLine)
                    .trim()
                    .replace("/^[a-zA-Z0-9!@#$%\^&*)(+=._-]*$/", "")

                if (address.includes(value))
                    return a
            })

            setAddressesFiltered(result);
        }
    }

    function HandleEntryMode(value) {
        if (value == null) {
            setEntryMode(null)
            setReference(null)
            setStorage(null)
        } else {
            setEntryMode(value)
        }
    }

    function TakeOut() {
        var data = {
            ...storage,
            ActivityUserID: token.UserID,
            TakeOutQuantity: parseInt($("#TakeOutQuantity").val()) ?? null,
            TakeOutLocation: addressManual,
            AddressID: addressSelected?.AddressID ?? null,
            AddressLine: (addressSelected?.AddressLine + ", " + addressSelected?.ZipCode + " " + addressSelected?.City) ?? null,
            ProductTakeOutNote: $("#ProductTakeOutNote").val() ?? null
        }

        reqPut("storage/takeout", data).then((res) => {
            setEntryMode(null)
            setReference(null)
            setStorage(null)

            navigate(-1)
        })

    }

    return <div className="row h-100 justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-5 col-h-fill">

            <section className="body-container content">
                <header className="mb-3">
                    <h3 className="section-title mb-0">Retirer / Déplacer</h3>
                    {entryMode == null ?
                        <button onClick={() => navigate(-1)} className="btn link-nav">{"<"} Retour</button> :
                        <button className="btn link-nav" onClick={() => idStorage ? navigate(-1) : HandleEntryMode(null)}>{"<"} Retour</button>
                    }
                </header>

                <section className="body container-fluid">

                    <StorageRefResolver entryMode={entryMode} setEntryMode={(value) => HandleEntryMode(value)} setReference={setReference} reference={idStorage} />

                    {reference == null ?
                        <></> :
                        storage ?
                            <StorageRefItem storage={storage} /> :
                            AlertMsg("info", "Aucune référence trouvée")
                    }

                    {reference && storage?.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE &&
                        <>
                            <div className="card">
                                <div className="card-body">
                                    <label htmlFor="TakeOutQuantity" className="form-label">Nombre à retirer *</label>
                                    <input id="TakeOutQuantity" type="number" className="form-control mb-3" min={1} onChange={(e) => parseInt(e.target.value) > 0 ? setAuthSubmit(true) : setAuthSubmit(false)} />
                                </div>
                            </div>

                            <div className="text-end">
                                <button className="btn btn-primary" id="btnSubmit" onClick={() => TakeOut()} disabled={!authSubmit}><i className="bi bi-check me-1"></i>Valider</button>
                            </div>
                        </>
                    }


                    {
                        // IF TOOL
                        reference && storage?.ProductClassID == PRODUCT_CLASS_ID.REUSABLE &&
                        <>
                            {storage?.ProductAvailable == PRODUCT_AVAILABLE.REPAIRING ?
                                <>
                                    {AlertMsg("warning", "Ce produit est actuellement en réparation")}

                                    <div className="card card-btn">
                                        <Link to={"/warehouse/" + idWarehouse + "/storage/" + storage?.StorageID + "/repair"} className="card-body">
                                            <i className="card-icon bi bi-wrench-adjustable-circle"></i>

                                            <div className="card-content">
                                                <h5 className="card-title">Retour de réparation</h5>
                                            </div>
                                            <i className="bi bi-chevron-right"></i>
                                        </Link>
                                    </div>
                                </>
                                :
                                // IF CONDITION NOT OKAY
                                storage?.ProductCondition == PRODUCT_CONDITION.OUT_OF_ORDER ?
                                    <>
                                        {AlertMsg("danger", "Ce produit est hors service et nécessite une réparation")}

                                        <div className="card card-btn">
                                            <Link to={"/warehouse/" + idWarehouse + "/storage/" + storage?.StorageID + "/repair"} className="card-body">
                                                <i className="card-icon bi bi-wrench-adjustable-circle"></i>

                                                <div className="card-content">
                                                    <h5 className="card-title">Réparer</h5>
                                                </div>
                                                <i className="bi bi-chevron-right"></i>
                                            </Link>
                                        </div>
                                    </> :
                                    <>
                                        <article className="card">

                                            <header className="card-header">
                                                <h5 className="card-title">{storage?.ProductAvailable ? "Retirer" : "Déplacer"} vers *</h5>
                                            </header>

                                            <div className="card-body">

                                                {/* Select an address */}
                                                <div className="mb-3">
                                                    <label htmlFor="TakeOutAddressID" className={"form-label " + (addressManual && "text-muted")}>Selectionner une adresse existante</label>

                                                    <div className="dropdown">
                                                        <button className="form-select text-start text-truncate" type="button" data-bs-toggle="dropdown" aria-expanded="false" disabled={addressManual ? true : false} >
                                                            {addressSelected ?
                                                                addressSelected.AddressLine + ", " + addressSelected.ZipCode + " " + addressSelected.City :
                                                                "Choisir un adresse"}
                                                        </button>

                                                        <div className="dropdown-menu w-100 p-3">
                                                            <input type="text" placeholder="Recherche..." className={"form-control " + (addressesFiltered.length > 0 && "mb-3")} onChange={(e) => HandleSearchAddress(e.target.value)} />

                                                            <ul className="list-group list-group-hover list-group-flush d-block overflow-auto" style={{ maxHeight: "15rem" }}>

                                                                {addressesFiltered?.map(a => {
                                                                    return <li key={a.AddressID} className="list-group-item d-block text-truncate border-0 p-2" onClick={() => setAddressSelected(a)} style={{ cursor: "pointer" }}>
                                                                        {a.AddressLine + ", " + a.ZipCode + " " + a.City}
                                                                    </li>
                                                                })}

                                                            </ul>

                                                        </div>
                                                    </div>
                                                </div>


                                                {/* Write the destination manually */}
                                                <div className="mb-3">
                                                    <label htmlFor="TakeOutLocation" className={"form-label " + (addressSelected && "text-muted")}>Destination manuelle</label>
                                                    <input id="TakeOutLocation" type="text" className="form-control mb-3" onChange={(e) => setAddressManual(e.target.value)} disabled={addressSelected ? true : false} />
                                                </div>
                                            </div>
                                        </article>

                                        <div className="mb-3">
                                            <label htmlFor="ProductTakeOutNote" className="form-label">Remarque</label>
                                            <textarea rows={4} id="ProductTakeOutNote"></textarea>
                                        </div>

                                        {/* Submit */}
                                        {reference && storage?.ProductID &&
                                            <div className="text-end">
                                                <button className="btn btn-primary" id="btnSubmit" onClick={() => TakeOut()} disabled={(!addressSelected && !addressManual) ? true : false}><i className="bi bi-check me-1"></i>Valider</button>
                                            </div>
                                        }
                                    </>
                            }
                        </>
                    }

                </section>

            </section>

        </div>
    </div >

}

export function StorageRefResolver({ entryMode, setEntryMode, reference, setReference, setProductRef }: { entryMode: string | null, setEntryMode, reference?: number, setReference, setProductRef?}) {

    if (!entryMode) {
        return <div className="row row-cols-2">

            <div className="col">
                <button className="btn btn-outline rounded w-100" onClick={() => setEntryMode("qrCode")}>
                    <i className="bi bi-qr-code-scan" style={{ fontSize: "5rem" }}></i>
                    <span className="d-block">Scanner QR code</span>
                </button>
            </div>

            <div className="col">
                <button className="btn btn-outline rounded w-100" onClick={() => setEntryMode("manual")}>
                    <i className="bi bi-keyboard" style={{ fontSize: "5rem" }}></i>
                    <span className="d-block">Entrer manuellement</span>
                </button>
            </div>

        </div>
    } else if (entryMode === "qrCode") {
        return <QRScanner onChange={value => setReference(value)} reader={reference ? false : true} />

    } else if (entryMode === "manual") {
        return <ManualRef onChange={value => setReference(value)} />
    }
    else {
        return <></>
    }
}

export function StorageRefItem({ storage }: { storage: IStorageProduct }) {

    if (storage.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE) {
        return <article className="card">

            <header className="card-header pb-2">
                <h6 className="card-title">{storage?.Rack}{storage?.Row}-{storage?.Col}{storage?.Compartment ? "-" + storage?.Compartment : ""}</h6>
            </header>

            <section className="card-body">


                <div className="row">

                    <div className="col-8">
                        <h6 className="text-truncate">{storage?.ProductModel}</h6>
                        <p className="text-muted mb-0">{storage.ProductReference}</p>
                    </div>


                    <div className="col-4 text-end">

                        <h4 className="mb-0">{storage.Quantity}</h4>

                        <p className="mb-0 text-muted d-inline me-1">Min:</p>
                        <p className="mb-0 d-inline me-2">{storage.QuantityMin}</p>

                        <p className="mb-0 text-muted d-inline me-1">Max:</p>
                        <p className="mb-0 d-inline">{storage.QuantityMax}</p>

                    </div>

                </div>

            </section>

        </article>

    } else if (storage.ProductClassID == PRODUCT_CLASS_ID.REUSABLE) {

        return <>
            <article className="card">

                <header className="card-header pb-2">
                    <h6 className="card-title text-truncate">{storage?.ProductModel}</h6>

                    <h6 className="card-title">{storage?.Rack}{storage?.Row}-{storage?.Col}{storage?.Compartment ? "-" + storage?.Compartment : ""}</h6>
                </header>

                <section className="card-body">

                    <div className="row">

                        <div className="col">
                            <p className="text-muted mb-0">{storage.ProductReference}</p>
                        </div>

                        <div className="col-4 text-end">

                            {StorageAvailabilityBadge(storage.ProductAvailable)}

                        </div>

                    </div>

                </section>

            </article>

            {storage.ProductAvailable == PRODUCT_AVAILABLE.OUT &&
                <article className="card">

                    <header className="card-header">
                        <h5 className="card-title">Emplacement</h5>
                    </header>

                    <section className="card-body">
                        <p className="mb-0">{storage?.ProductLocation}</p>
                    </section>

                </article>
            }

            {storage.ProductAvailable == PRODUCT_AVAILABLE.REPAIRING &&
                <article className="card">

                    <header className="card-header">
                        <h5 className="card-title">En réparation</h5>
                    </header>

                    <section className="card-body">
                        <p className="mb-0">{storage?.RepairLocation}</p>
                    </section>

                </article>
            }
        </>

    } else {
        return <></>
    }
}