import React, { useState, useEffect } from 'react'
import { Routes, Route, Link, NavLink, useParams, useNavigate } from 'react-router-dom'
import { faker } from '@faker-js/faker'

export default function ConstructionGallery() {
    faker.locale = 'fr_CH'


    return <>
       <Routes>
            <Route path="" element={<Galleries />} />
            <Route path=":idGallery" element={<Gallery />} />
        </Routes>

    </>
}

function Galleries() {
    const navigate = useNavigate()
    var cards = []

    for (var i = 0; i < 7; i++) {
        cards.push(<div className="col-md-6">
            <div className="card card-fill mb-4" style={{ minHeight: "18rem" }}>
                <div className="card-header">
                </div>

                <div className="card-body">

                    <div className="row h-100 mx-0">

                        <div className="col-md-6 h-100 overflow-hidden ps-0 pe-md-3">
                            <img className="gallery-img" src={faker.image.technics(0, 0, true)} alt="" onClick={() => { navigate('1') }} />
                        </div>

                        <div className="col-md-6 h-100 overflow-auto mt-4 mt-md-0 pe-0 ps-md-3">

                            <h5 className="card-title mb-1">{faker.company.catchPhrase()}</h5>
                            <h6 className="text-muted mb-3">23.07.2021</h6>

                            <h6 className="text-muted mb-1">Description</h6>

                            <p>{faker.lorem.paragraph()}</p>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        );
    }

    return <>
        <div className="header">
            <h3 className="section-title">Gallerie</h3>

            <div className="d-flex justify-content-end w-25">
                <Link to="" className="btn btn-tools">
                    <i className="bi bi-plus-circle"></i>
                    <span className="d-block">Ajouter</span>
                </Link>
            </div>
        </div>

        <div className="row">
            {cards}
        </div>
    </>
}

function Gallery() {

    var imgs = []

    for (var i = 0; i < 13; i++) {
        imgs.push(<div className="gallery-element">
            <img src={faker.image.technics(0, 0, true)} />
        </div>
        );
    }

    return <>
        <Link to={"/construction/1/gallery"}>{'< Retour à la gallerie'}</Link>

        <div className="header">
            <h3 className="section-title mb-0">{faker.company.catchPhrase()}</h3>

            <div className="d-flex justify-content-end w-25">

                <Link to="" id="btnUpdate" className="btn btn-tools">
                    <i className="bi bi-pencil-square"></i>
                    <span className="d-block">Modifier</span>
                </Link>
            </div>
        </div>

        <h4 className="section-title-2">Description</h4>

        <p className="w-50 mb-5">{faker.lorem.paragraph()}</p>

        <div className="gallery">
            {imgs}
        </div>
    </>
}