import React, { useEffect, useState } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import $ from 'jquery'
import { Page, Text, View, Document, StyleSheet, Image, Font, PDFViewer } from '@react-pdf/renderer';
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { reqGet } from '../../../../service/apiRequest'
import { DateToday, dateFormat, Header } from '../../../common/common'
import { IAddress, IInstallation, IMaterialClass } from '../../../common/types'
import { ICONS, INSTALLATION_STATUS, LAYOUT } from '../../../common/constants'

interface IInstallsProps {
    filename: string,
    date: string | null,
    sre: string | null,
    nbApartement: string | null,
    note: string | null,

    installation: Array<IInstallation>
}

const validationSchema = yup.object({
    filename: yup.string().required("Ce champ est obligatoire"),
    date: yup.string().transform((val, originalValue) => dateFormat(originalValue, 'dd.MM.yyyy')).nullable(),
    sre: yup.string().nullable(),
    nbApartement: yup.string().nullable()

}).required();

export default function AddressReport() {
    const navigate = useNavigate()
    const params = useParams()

    const idAddress = params.idAddress

    const [address, setAddress] = useState<IAddress>()
    const [installs, setInstalls] = useState<Array<IInstallation>>()
    const [materialClass, setMaterialClass] = useState<Array<IMaterialClass>>()

    const { register, control, formState: { errors }, handleSubmit, setValue, watch, getValues } = useForm<IInstallsProps>({
        mode: "onTouched",
        resolver: yupResolver(validationSchema)
    });

    const { fields } = useFieldArray({
        control,
        name: "installation",
    });

    useEffect(() => {

        if (idAddress) {

            const pAddress = reqGet("address/" + idAddress).then(res => res)
            const pInstalls = reqGet("installation/address/" + idAddress).then(res => res)
            const pMatericalClass = reqGet("materialclass").then(res => res)

            Promise.all([pAddress, pInstalls, pMatericalClass]).then(values => {

                setAddress(values[0])
                setInstalls(values[1])
                setMaterialClass(values[2])

                setValue('installation', values[1].sort((a, b) => (a.MaterialClassID > b.MaterialClassID) ? 1 : ((b.MaterialClassID > a.MaterialClassID) ? -1 : 0)))
                /*
                setValue('filename', "rapport_technique_" + values[0].AddressLine.toLowerCase().trim().replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "_"))
                setValue('installation', values[1].sort((a, b) => (a.MaterialClassID > b.MaterialClassID) ? 1 : ((b.MaterialClassID > a.MaterialClassID) ? -1 : 0)))
                setValue('note', values[0].Note)
*/

            })
        } else {
            navigate("/")
        }

    }, [])


    // HANDLE DISPLAY MOBILE PDF --------------------------
    const [display, setDisplay] = useState<string>("")
    const [display2, setDisplay2] = useState<string>("")
    const [displayMobilePDF, setDisplayMobilePDF] = useState<boolean>(false)

    function HandleDisplayPDF(isDisplay) {
        setDisplayMobilePDF(isDisplay)
    }


    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location, displayMobilePDF])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.lg) {
            setDisplay('d-flex')
            setDisplay2('d-flex')
        } else {
            if (displayMobilePDF) {
                setDisplay('d-flex')
                setDisplay2('d-none')
            } else {
                setDisplay('d-none')
                setDisplay2('d-flex')
            }
        }
    }
    // HANDLE DISPLAY MOBILE PDF --------------------------

    const [data, setData] = useState<any>({ Date: dateFormat(DateToday(), "dd/MM/yyyy") })


    return <>
        <section className="container-fluid h-100">

            <div className="row h-100">
                <div className={display2 + " col-xl-6 col-xxl-5 col-h-fill"}>

                    <section className='body-container'>

                        <header className="header">
                            <div>
                                <h2 className="section-title mb-0">Rapport technique</h2>
                                <Link to={"/address/" + idAddress + '/document'} className="link-nav">{'<'} Retour</Link>
                            </div>
                        </header>

                        <section className="body">

                            <p className="fst-italic">
                                <small>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    Seul les informations du pdf sont mises à jour depuis ici, les données de la base technique ne sont pas modifiée. Toutes modifications des données de la base technique ne doivent pas être faites ici.
                                </small>
                            </p>

                            <section className="mb-3">
                                <label htmlFor="date" className="form-label">Date</label>
                                <input className="form-control" type="date" defaultValue={DateToday()} onBlur={(e) => setData({ ...data, Date: dateFormat(e.target.value, "dd/MM/yyyy") })} />
                            </section>

                            <section className="mb-3 row row-cols-2">
                                <div className="col">
                                    <label htmlFor="sre" className="form-label">SRE</label>
                                    <input type="text" className="form-control" onBlur={(e) => setData({ ...data, SRE: e.target.value })} />
                                </div>

                                <div className="col">
                                    <label htmlFor="nbApartement" className="form-label">Nb appartements</label>
                                    <input type="text" className="form-control" onBlur={(e) => setData({ ...data, NbApartments: e.target.value })} />
                                </div>
                            </section>

                            <section className="mb-3">
                                <label htmlFor="sre" className="form-label">Remarques général</label>
                                <textarea className="form-control" rows={5} onBlur={(e) => setData({ ...data, Note: e.target.value })} ></textarea>
                            </section>

                            {installs && installs.length > 0 &&
                                <section className="mb-3">

                                    <header className="d-flex justify-content-between mb-3">
                                        <label className="form-label">Installation</label>
                                        <label className="form-label">Prévision</label>
                                    </header>

                                    {fields.map((i, index) => {
                                        if (i.MaterialID)
                                            return <div key={i.InstallationID} className="row mb-3">
                                                <div className="col-8">
                                                    <h6 className='item-text'>{i.Type} {i.Brand} {i.Model}</h6>
                                                    <p className='item-text text-muted'>{i.Note}</p>
                                                </div>

                                                <div className='col-4'>
                                                    <select {...register(`installation.${index}.Status`)} className='form-select'>
                                                        <option value={INSTALLATION_STATUS.GOOD}>Bon</option>
                                                        <option value={INSTALLATION_STATUS.MEDIUM}>Moyen</option>
                                                        <option value={INSTALLATION_STATUS.BAD}>Mauvais</option>
                                                    </select>
                                                </div>
                                            </div>
                                        else
                                            return <div key={i.InstallationID} className="row mb-3">
                                                <div className="col-8">
                                                    <h6 className='item-text'>{i.IType} {i.IBrand} {i.IModel}</h6>
                                                    <p className='item-text text-muted'>{i.Note}</p>
                                                </div>

                                                <div className='col-4'>
                                                    <select {...register(`installation.${index}.Status`)} className='form-select'>
                                                        <option value={INSTALLATION_STATUS.GOOD}>Bon</option>
                                                        <option value={INSTALLATION_STATUS.MEDIUM}>Moyen</option>
                                                        <option value={INSTALLATION_STATUS.BAD}>Mauvais</option>
                                                    </select>
                                                </div>
                                            </div>
                                    })}

                                </section>
                            }
                        </section>

                        <footer className="footer d-xl-none">
                            <button className="btn btn-add" onClick={() => HandleDisplayPDF(true)}><i className={ICONS.ADD + " me-2"}></i>Valider</button>
                        </footer>

                    </section>

                </div>

                <div className={display + " col-xl-6 col-xxl-7 col-h-fill px-0 align-items-start ps-lg-3"}>
                    <button className="btn d-block link-nav p-0 d-lg-none mb-3" onClick={() => HandleDisplayPDF(false)}>{"<"} Retour</button>

                    <div className="card card-fill w-100">
                        <PDFViewer width={"100%"} height={"100%"}>
                            <PdfAddressReport address={address} installs={installs} data={data}
                        />
                        </PDFViewer>

                    </div>
                </div>
            </div>

        </section>
    </>
}

const PdfAddressReport = ({ address, installs, data }: { address: IAddress | undefined, installs: Array<IInstallation> | undefined, data?: any | undefined }) => {

    console.log(data);


    return <Document>
        <Page style={styles.page}>
            <View style={styles.header}>
                <Image style={styles.logo} src="/media/images/multitherme/mt_logo_text.jpg" />
                <View style={[{ marginLeft: "15pt" }]}>
                    <Text style={styles.title}>{address?.AddressLine}, {address?.ZipCode} {address?.City}</Text>
                    <Text style={styles.title2}>Rapport technique</Text>
                    <Text style={styles.title2}>{data?.Date}</Text>
                </View>
            </View>



            {data?.SRE &&
                <View style={[{ display: 'flex', marginBottom: "5pt" }]}>
                    <Text style={[styles.title3, { display: 'flex' }]}>SRE</Text>
                    <Text style={[styles.text, { display: 'flex' }]}>{data?.SRE}</Text>
                </View>
            }

            {data?.NbApartments &&
                <View style={[{ marginBottom: "5pt" }]}>
                    <Text style={styles.title3}>Nb appartements</Text>
                    <Text style={styles.text}>{data?.NbApartments}</Text>
                </View>
            }

            {data?.Note &&
                <View style={[{ marginBottom: "20pt" }]}>
                    <Text style={styles.title3}>Remarque</Text>
                    <Text style={styles.text}>{data?.Note}</Text>
                </View>
            }

            <View style={[{ marginBottom: "20pt" }]}>
                <Text style={styles.title3}>Memo</Text>
                <Text style={styles.text}>Bon: Matériel en bon état</Text>
                <Text style={styles.text}>Moyen: Prévoir le remplacement à moyen terme</Text>
                <Text style={styles.text}>Mauvais: Prévoir le remplacement rapidement</Text>
            </View>

            <View style={styles.table}>

                <View style={styles.row} wrap={false}>
                    <View style={[styles.col, { width: "70%" }]}>
                        <Text style={styles.title3}>Installation</Text>
                    </View>

                    <View style={[styles.col, { width: "15%" }]}>
                        <Text style={styles.title3}>Année installé</Text>
                    </View>

                    <View style={[styles.col, { width: "15%" }]}>
                        <Text style={styles.title3}>Etat</Text>
                    </View>
                </View>

                {installs && installs?.map((i: IInstallation) => {

                    if (i.MaterialID)
                        return <View key={i.InstallationID} style={styles.row} wrap={false}>
                            <View style={[styles.col, { width: "70%" }]}>
                                <Text style={styles.title3}>{i.Type} {i.Brand}{i.Model}</Text>
                                <Text style={styles.text}>{i.Note}</Text>
                            </View>

                            <View style={[styles.col, { width: "15%" }]}>
                                <Text style={styles.text}>{i.YearInstalled}</Text>
                            </View>

                            <View style={[styles.col, { width: "15%" }]}>
                                <Text style={styles.text}>{i.Status}</Text>
                            </View>
                        </View>

                    else
                        return <View key={i.InstallationID} style={styles.row} wrap={false}>
                            <View style={[styles.col, { width: "70%" }]}>
                                <Text style={styles.title3}>{i.IType} {i.IBrand} {i.IModel}</Text>
                                <Text style={styles.text}>{i.Note}</Text>
                            </View>

                            <View style={[styles.col, { width: "15%" }]}>
                                <Text style={styles.text}>{i.YearInstalled}</Text>
                            </View>

                            <View style={[styles.col, { width: "15%" }]}>
                                <Text style={styles.text}>{i.Status}</Text>
                            </View>
                        </View>
                })}
            </View>

            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`Page ${pageNumber}/${totalPages}`)} fixed>

            </Text>
        </Page>
    </Document >
}

Font.register({
    family: 'Helvetica',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
    page: {
        paddingTop: 30,
        paddingBottom: 65,
        paddingHorizontal: 30,
    },
    header: {
        display: "flex",
        flexDirection: "row",
    },
    title: {
        fontSize: 14,
        fontFamily: 'Helvetica-Bold',
        marginBottom: "3pt"
    },
    title2: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        marginBottom: "3pt"
    },
    title3: {
        fontSize: 10,
        fontFamily: 'Helvetica-Bold',
        marginBottom: "3pt"
    },
    subtitle: {
        fontSize: 12,
        fontFamily: 'Helvetica',
        marginBottom: "50pt"
    },
    text: {
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Helvetica',
        marginBottom: "3pt"
    },
    bold: {
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold'
    },
    logo: {
        width: "80 pt",
        marginBottom: "50pt"
    },

    table: {
        width: '100%',
        border: "0"
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
    },
    col: {
        minWidth: "20%"
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey'
    }
});


