import React, { useState, useEffect, useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { reqGet, reqPost } from '../../../service/apiRequest'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import $ from 'jquery'
import { flexRender, } from '@tanstack/react-table'
import { getToken } from '../../../useToken'
import { IStorage, IProduct } from "../../common/types"
import { ButtonSubmit, HeaderRow, fuzzyFilter, TablePagination, DebouncedInput, ProductInfoFormatter } from '../../common/common'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
} from '@tanstack/react-table'
import { PRODUCT_CLASS_ID, PRODUCT_TYPE } from '../../common/constants';

interface IFormInput extends IStorage {
    ActivityUserID: number | null;
}

const validationSchema = yup.object({
    Rack: yup.string().required("Ce champ est obligatoire"),
    Row: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    Col: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
    Compartment: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    ProductID: yup.string().nullable(),
    Quantity: yup.string().nullable(),
    QuantityMin: yup.string().nullable(),
    QuantityMax: yup.string().nullable()

}).required();

const initialValues = {
    WarehouseID: 0,
    Rack: "",
    Row: 0,
    Col: 0,
    Compartment: null,
    ProductID: null,
    Quantity: null,
    QuantityMin: null,
    QuantityMax: null,
    ProductAvailable: null,
}

export default function StorageAdd({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()
    const params = useParams()
    const idWarehouse = params.idWarehouse

    const [product, setProduct] = useState<IProduct>()

    const { register, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm<IFormInput>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        if (!idWarehouse)
            navigate("/warehouse")
        else {
            setValue("ActivityUserID", token.UserID)
            setValue("WarehouseID", parseInt(idWarehouse))
        }
    }, [])

    // when product selected
    useEffect(() => {
        if (product) {
            setProductID(product?.ProductID)

            $("#inputSelectedProduct").val(ProductInfoFormatter(product))
        }
    }, [product])


    function setProductID(productID) {
        setValue("ProductID", productID)
    }

    function RemoveProduct() {
        setProductID(null)

        $("#inputSelectedProduct").val("")
    }

    const onSubmit: SubmitHandler<IFormInput> = data => {
        if (product?.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE) {
            data.ProductAvailable = null
        } else if (product?.ProductClassID == PRODUCT_CLASS_ID.REUSABLE) {
            data.ProductAvailable = 1
            data.Quantity = null
            data.QuantityMin = null
            data.QuantityMax = null
        }
        data.Rack = data.Rack.toUpperCase()

        reqPost('storage', data).then((res) => {
            setReload(true)
            navigate("/warehouse/" + idWarehouse + "/storage/" + res)
        })

    };
    return (
        <section className="body-container h-100">
            {HeaderRow(
                <h3 className="section-title">Ajouter un emplacement de stockage</h3>,
                <Link to={"/warehouse/" + idWarehouse + "/storage"} className="link-nav">{'<'} Retour</Link>
            )}

            <section className="body container-fluid">

                <div className="row h-100 justify-content-center">


                    <div className="col-md-7 px-0">

                        <section className="row row-cols-2 row-cols-md-4 mb-3">

                            <div className="col">
                                <label className="form-label">Etagère *</label>

                                <div className="input-group">

                                    <input className={"form-control " + (errors?.Rack ? "is-invalid" : "")} placeholder="A, B, C..." {...register("Rack")} />
                                </div>

                                {errors.Rack?.message && <p className="input-msg-error">{errors.Rack?.message}</p>}
                            </div>

                            <div className="col">
                                <label className="form-label">Rangée *</label>

                                <div className="input-group">

                                    <input type="number" className={"form-control " + (errors?.Row ? "is-invalid" : "")} placeholder="1, 2, 3..." {...register("Row")} />
                                </div>

                                {errors.Row?.message && <p className="input-msg-error">{errors.Row?.message}</p>}
                            </div>

                            <div className="col">
                                <label className="form-label">Colonne *</label>

                                <div className="input-group">

                                    <input type="number" className={"form-control " + (errors?.Col ? "is-invalid" : "")} placeholder="1, 2, 3..." {...register("Col")} />
                                </div>

                                {errors.Col?.message && <p className="input-msg-error">{errors.Col?.message}</p>}
                            </div>

                            <div className="col">
                                <label className="form-label">Compartiment</label>

                                <div className="input-group">

                                    <input type="number" className={"form-control " + (errors?.Compartment ? "is-invalid" : "")} placeholder="1, 2, 3..." {...register("Compartment")} />
                                </div>

                                {errors.Compartment?.message && <p className="input-msg-error">{errors.Compartment?.message}</p>}
                            </div>
                        </section>


                        {/* PRODUCT SELECTED */}
                        <div className="mt-5 mb-3">
                            <div className="row">
                                <div className="col-7">
                                    <label htmlFor="inputSelectedProduct" className="form-label mb-2">Selection</label>
                                </div>

                                <div className="col-5 text-end">
                                    <button className="btn btn-outline" disabled={watch("ProductID") ? false : true} onClick={(e) => { e.preventDefault(); RemoveProduct() }}>
                                        <i className="bi bi-eraser"></i> Retirer la pièce
                                    </button>
                                </div>

                                <div className="col-12 mt-3">
                                    <input type="text" className="form-control mb-0" id="inputSelectedProduct" disabled />
                                </div>
                            </div>

                        </div>

                        {(product?.ProductClassID == PRODUCT_CLASS_ID.REUSABLE && product?.StorageID) &&
                            <div>
                                <p className="text-danger"><i className="bi bi-exclamation-triangle me-1"></i> Cet outil est déjà stocké à {product.WarehouseName}, {product?.Rack}{product?.Row}-{product?.Col}{product?.Compartment != null ? ("-" + product?.Compartment) : ""}. Vous ne pouvez le stocké qu'à un seul endroit à la fois</p>
                            </div>
                        }



                        {<ProductSelector setProduct={setProduct} />}

                        {
                            product?.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE &&
                            <section className="row row-cols-5 mb-3">

                                <div className="col">
                                    <label className="form-label">Quantité</label>

                                    <div className="input-group">

                                        <input type="number" className={"form-control " + (errors?.Quantity ? "is-invalid" : "")} {...register("Quantity")} />
                                    </div>

                                    {errors.Row?.message && <p className="input-msg-error">{errors.Quantity?.message}</p>}
                                </div>

                                <div className="col">
                                    <label className="form-label">Minimum</label>

                                    <div className="input-group">

                                        <input type="number" className={"form-control " + (errors?.QuantityMin ? "is-invalid" : "")} {...register("QuantityMin")} />
                                    </div>

                                    {errors.QuantityMin?.message && <p className="input-msg-error">{errors.QuantityMin?.message}</p>}
                                </div>

                                <div className="col">
                                    <label className="form-label">Maximum</label>

                                    <div className="input-group">

                                        <input type="number" className={"form-control " + (errors?.QuantityMax ? "is-invalid" : "")} {...register("QuantityMax")} />
                                    </div>

                                    {errors.QuantityMax?.message && <p className="input-msg-error">{errors.QuantityMax?.message}</p>}
                                </div>
                            </section>
                        }



                        <div className="text-end">
                            <p><small>Les champs avec une * sont obligatoire</small></p>
                            {/* Disabled if already in stock somewhere */}
                            <ButtonSubmit onClick={handleSubmit(onSubmit)} disabled={(product?.ProductClassID == PRODUCT_CLASS_ID.REUSABLE && product?.StorageID) ? true : false} />
                        </div>

                    </div>

                </div>

            </section>
        </section >
    );
}


export function ProductSelector({ setProduct, product }: { setProduct, product?}) {
    const table = ProductTable()

    const [selectedProduct, setSelectedProduct] = useState<IProduct>()
    const [chooseProduct, setChooseProduct] = useState<boolean>(true)
    const [productNav, setProductNav] = useState<string>("Piece")

    // If product is not null
    useEffect(() => {
        if (product) {
            setSelectedProduct(product)
            HandleProductNav(product.ProductType)
            setChooseProduct(false)
        }
    }, [product])

    // Filter product table depending the nav
    useEffect(() => {
        if (productNav == PRODUCT_TYPE.PIECE) {
            table.setColumnFilters([{ id: "ProductType", value: PRODUCT_TYPE.PIECE }])
        }
        else if (productNav == PRODUCT_TYPE.TOOL) {
            table.setColumnFilters([{ id: "ProductType", value: PRODUCT_TYPE.TOOL }])
        }
        else if (productNav == PRODUCT_TYPE.EXCHANGER) {
            table.setColumnFilters([{ id: "ProductType", value: PRODUCT_TYPE.EXCHANGER }])
        }
    }, [productNav])


    function HandleProductNav(nav) {
        $("#productNav .btn.active").removeClass("active")

        if (nav == PRODUCT_TYPE.PIECE)
            $("#btnNavPiece").addClass("active")
        else if (nav == PRODUCT_TYPE.TOOL)
            $("#btnNavTool").addClass("active")
        else if (nav == PRODUCT_TYPE.EXCHANGER)
            $("#btnNavExchanger").addClass("active")

        setChooseProduct(true)
        setProductNav(nav)
    }


    function handleSelection(product, e) {
        $('#table tr.active').removeClass('active')
        e.currentTarget.classList.add('active')

        setSelectedProduct(product)
    }

    function ValidateSelection() {
        if (selectedProduct) {
            setProduct(selectedProduct)
            setChooseProduct(false)
        }
    }

    return <div className="mb-5">

        <div className="d-flex justify-content-between border-bottom">
            <nav className='nav-horizontal mb-0' id="productNav">

                <button id="btnNavPiece" className='btn nav-link active' onClick={(e) => { e.preventDefault(); HandleProductNav(PRODUCT_TYPE.PIECE) }}>Pièce</button>

                <button id="btnNavTool" className='btn nav-link' onClick={(e) => { e.preventDefault(); HandleProductNav(PRODUCT_TYPE.TOOL) }}>Outil</button>

                {/*
                <button id="btnNavExchanger" className='btn nav-link' onClick={(e) => { e.preventDefault(); HandleProductNav(PRODUCT_TYPE.EXCHANGER) }}>Echangeur</button>
                */}

            </nav>

            <div>
                {!chooseProduct &&
                    <button className="btn btn-primary" onClick={() => setChooseProduct(true)}>
                        Afficher liste <i className="bi bi-caret-down-fill"></i>
                    </button>
                }
            </div>
        </div>

        {/* PRODUCT TABLE */}
        {chooseProduct &&
            <article className="card card-100 card-border" style={{ height: "500px" }}>

                <header className="card-header row">
                    <div className="col-6 col-lg-3 order-1">
                        <h5 className="card-title">Liste des pièces</h5>
                    </div>

                    <div className="col-lg-6 order-3 order-lg-2 mt-3 mt-lg-0">
                        <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche dans toutes les pièces" />
                    </div>

                    <div className="col-6 col-lg-3 order-2 order-lg-2 text-end">
                        <button className="btn btn-outline me-2" onClick={() => setChooseProduct(false)}>Annuler</button>
                        <button className="btn btn-primary" onClick={() => ValidateSelection()}>Valider</button>
                    </div>
                </header>

                <section className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover table-sticky" id="table">

                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>

                                        {headerGroup.headers.map((header, k) => {
                                            return (
                                                <th key={k} colSpan={header.colSpan}>

                                                    {header.isPlaceholder ? null : (
                                                        <>

                                                            <p className="text-muted mb-1" {...{ onClick: header.column.getToggleSortingHandler() }}>

                                                                {flexRender(header.column.columnDef.header, header.getContext())}

                                                                {{ asc: <i className="bi bi-carret-up-fill"></i>, desc: <i className="bi bi-carret-down-fill"></i>, }[header.column.getIsSorted() as string] ?? null}

                                                            </p>

                                                            {header.column.getCanFilter() ? (
                                                                <div className="d-flex flex-direction-column">
                                                                    {/*<Filter column={header.column} table={table} />*/}
                                                                </div>
                                                            ) : null}

                                                        </>
                                                    )}
                                                </th>
                                            )
                                        })}

                                    </tr>
                                ))}
                            </thead>

                            <tbody>
                                {table.getRowModel().rows.map(row => {
                                    return (

                                        <tr key={row.id} onClick={(e) => handleSelection(row.original, e)}>

                                            {row.getVisibleCells().map(cell => {
                                                return (

                                                    <td key={cell.id} style={{ minWidth: "10rem" }}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </td>

                                                )
                                            })}

                                        </tr>
                                    )

                                })}
                            </tbody>

                        </table>
                    </div>
                </section>

                <footer className="card-footer">
                    {TablePagination(table)}
                </footer>

            </article>
        }

    </div>
}



export function ProductTable(reload?: boolean, setReload?) {

    const [data, setData] = useState<Array<IProduct>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const columns = useMemo<ColumnDef<IProduct, any>[]>(
        () => [
            {
                accessorFn: row => row.ProductModel,
                id: 'Model',
                header: 'Modèle',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductBrand,
                id: 'ProductBrand',
                header: 'Marque',
                show: false,
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductLabel,
                id: 'ProductLabel',
                header: 'Label',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductReference,
                id: 'ProductReference',
                header: 'Référence',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ProductType,
                id: 'ProductType',
                header: 'Catégorie',
                cell: info => info.getValue()
            }


        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
            columnVisibility: { ProductType: false }
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    useEffect(() => {
        table.setPageSize(50)

        reqGet('product').then((res) => { setData(res); })
    }, [])

    useEffect(() => {
        if (reload) {
            reqGet('product').then((res) => { setData(res); })
            setReload(false)
        }
    }, [reload])

    return table
}