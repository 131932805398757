import React, { useEffect, useState } from "react";
import { ButtonSubmit, DateToday, ResolveIcon, dateFormat } from './../../../common/common';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { reqGet, reqPut } from "../../../../service/apiRequest";
import { getToken } from "../../../../useToken";
import $ from 'jquery'
import { ICONS } from "../../../common/constants";

export default function AccountCounterStatementContent({ reload, setReload }) {
    const token = getToken()
    const location = useLocation()
    const navigate = useNavigate()

    const [addressCounters, setAddressCounters] = useState<any>()

    useEffect(() => {
        if (!location.state.AddressCounters) {
            navigate("/account/counter-statement")
        }

        reqGet("counterstatement/address/" + location.state.AddressCounters.AddressID + "/week/" + location.state.AddressCounters.Counters[0].WeekID + "/user/" + token.UserID).then(res => {
            setAddressCounters(res)
        })
    }, [location.state.AddressCounters.Counters[0].CounterStatementID])

    // INIT VALUES IN INPUTS
    useEffect(() => {
        if (addressCounters)
            addressCounters.forEach(c => {
                $("#counterStatementDate" + c.CounterStatementID).val(c.CounterStatementDate ?? DateToday())
                $("#counterStatementValue" + c.CounterStatementID).val(c.CounterStatementValue)
            })
    }, addressCounters)


    function UpdateCounterStatements() {

        var data: { Address: string, AddressID: number, UserID: number, Counters: Array<any> } = {
            Address: location.state.AddressCounters.AddressLine + ", " + location.state.AddressCounters.ZipCode + " " + location.state.AddressCounters.City,
            AddressID: location.state.AddressCounters.AddressID,
            UserID: token.UserID,
            Counters: []
        }

        addressCounters.forEach(c => {
            data.Counters.push({
                CounterStatementDate: $("#counterStatementDate" + c.CounterStatementID).val(),
                CounterStatementID: c.CounterStatementID,
                CounterStatementValue: parseInt($("#counterStatementValue" + c.CounterStatementID).val()),
                CounterStatementNote: $("#counterStatementNote" + c.CounterStatementID).val(),
                ObservationID: parseInt(addressCounters[0].ObservationID)
            })
        })

        reqPut('counterstatement', data).then((res) => {
            navigate('/account/counter-statement')
            setReload(true)
        })
    }


    return <div className="body-container content">

        <header className="mb-3">
            <h2 className="section-title mb-1">{location.state?.AddressCounters.AddressLine}, {location.state?.AddressCounters.ZipCode} {location.state?.AddressCounters.City}</h2>

            <div className="d-flex justify-content-between">
                <Link to="/account/counter-statement" className="link-nav">{"<"} Retour</Link>

                <Link to={"/address/" + location.state?.AddressCounters.AddressID} className="link-nav">Adresse {">"}</Link>
            </div>
        </header>

        <section className="body">

            {addressCounters?.map((c, i) => {

                return <div className="card" key={i}>
                    <header className="card-header mb-3">
                        <div>
                            <h6 className="card-title"><i className={ResolveIcon(c.CounterType) + " me-2"}></i> {c.Label}</h6>
                            <p className="card-subtitle">{c.CounterType} {c.IDCounter && " - " + c.IDCounter}</p>
                        </div>

                        {c.CounterStatementValue && <i className={ICONS.SUCCESS + " bg-success-inversed rounded-3 px-1"}></i>}
                    </header>


                    <section className="card-body">

                        <div className="row row-cols-2">
                            {c.Capacity && <div className="col">
                                <h6 className="mb-1">Capacité</h6>
                                <p className="pargraph">{c.Capacity}</p>
                            </div>
                            }
                            {c.VolumeMin && <div className="col">
                                <h6 className="mb-1">Volume minimum</h6>
                                <p className="pargraph">{c.VolumeMin}</p>
                            </div>
                            }
                            {c.Tolerance && <div className="col">
                                <h6 className="mb-1">Tolérance</h6>
                                <p className="pargraph">{c.Tolerance}</p>
                            </div>
                            }
                        </div>


                        <div className="row row-cols-2">
                            <div className="col">
                                <h6 className="form-label">Dernière valeur</h6>
                                {c.LastValue && <>
                                    <p className="pargraph mb-1">{c.LastValue} {c.Unit}</p>
                                    <p className="paragraph mb-1">{dateFormat(c.LastDate, "dd/MM/yyyy")}</p>
                                    <p className="paragraph">{c.LastFirstName} {c.LastName}</p>
                                </>}
                            </div>

                            <div className="col">
                                <h6 className="form-label">{c.CounterStatementValue ? "Modifier" : "Entrer"} valeur</h6>

                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" id={"counterStatementValue" + c.CounterStatementID} />
                                    <span className="input-group-text">{c.Unit}</span>
                                </div>

                                <input type="date" className="form-control mb-1" id={"counterStatementDate" + c.CounterStatementID} />

                                <p className="paragraph">{c.FirstName} {c.Name}</p>
                            </div>

                        </div>


                    </section>
                </div>
            })}

            <div className="text-end">
                <ButtonSubmit onClick={() => UpdateCounterStatements()} />
            </div>

        </section>
    </div>
}