import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { reqGet } from '../../../../service/apiRequest'
import { getToken } from '../../../../useToken'
import { IVehicle } from '../../../common/types'
import { Header } from '../../../common/common'

export default function AccountGuarantor() {
    const navigate = useNavigate()
    const token = getToken()
    const [vehicles, setVehicles] = useState<Array<IVehicle>>([])

    useEffect(() => {
        reqGet("vehicle/guarantor/" + token.UserID).then(res => {
            setVehicles(res)
        }, rej => {
            navigate(-1)
        })
    }, [token.UserID])

    function ThreeMonthCheck(lastCheck) {
        const dateArray = lastCheck.split(".")

        const date = new Date(dateArray[2], dateArray[1] - 1, dateArray[0])
        date.setMonth(date.getMonth() - 2)

        const dateCheck = new Date(dateArray[2], dateArray[1] - 1, dateArray[0])

        if (dateCheck <= date) {
            return false
        } else {
            return true
        }
    }


    return <>

        <section className="body-container content">

            {Header(
                <h3 className="section-title">Véhicules répondant</h3>,
                <Link to="/account" className="link-nav">{"<"} Retour</Link>,
                <Link to="/vehicle" className="link-nav">Aller aux véhicules {">"}</Link>
            )}


            <section className="body">

                <p className="text-muted">
                    <i>Les contrôles marqués par
                        <span className="text-primary mb-0 mx-2">
                            <i className="bi bi-calendar-check"></i>
                        </span>
                        correspondent au mois courant.</i>
                </p>

                <div className="list list-hover">
                    {vehicles.map(v => {
                        return <Link to={"/vehicle/" + v.VehicleID.toString()} key={v.VehicleID} state={{ path: location.pathname }} className="list-item mb-3" >
                            <section className="list-item-start">
                                <h3>{v?.VehicleNumber ? "N°" + v.VehicleNumber : ""}</h3>
                            </section>

                            <section className="list-item-data pe-md-3">
                                <div className="row d-flex">

                                    <div className="col-6 col-lg-4">
                                        <h6 className="title text-truncate">{v?.Model}</h6>
                                        <p className="text-muted text-truncate mb-0">{v?.Numberplate}</p>
                                    </div>

                                    <div className="col-6 col-lg-8 text-end">

                                        <div className="row justify-content-end row-cols-lg-3">

                                            <div className="col d-none d-sm-block d-md-none d-lg-block">
                                                <h6 className="text-light">Résponsable</h6>
                                                <p className="paragraph text-nowrap text-truncate">{v.FirstName} {v.Name}</p>
                                            </div>

                                            <div className="col pe-0 pe-lg-3 ">
                                                <h6 className="text-light">Date contrôle</h6>

                                                {v?.LastCheck && new Date().getMonth() == (parseInt(v?.LastCheck.split(".")[1]) - 1) &&
                                                    <span className="text-primary mb-0 mx-2">
                                                        <i className="bi bi-calendar-check"></i>
                                                    </span>
                                                }

                                                {v.Assignment == "Chantier" && (v.LastCheck == null || !ThreeMonthCheck(v.LastCheck)) ?
                                                    <span className="badge d-inline-block bg-danger rounded-5 mb-0 me-1" title="Le dernier contrôle est ancien de plus de trois mois">
                                                        <i className="bi bi-hourglass-bottom"></i>
                                                    </span> : ""
                                                }

                                                <p className="paragraph d-inline mb-0">{v.LastCheck}</p>
                                            </div>
                                            <div className="col d-none d-lg-block">
                                                <h6 className="text-light">Etât contrôle</h6>
                                                <p className="paragraph">
                                                    {v.Status != "Bad" ?
                                                        (v.Status == "Warning" ?
                                                            <span className="dot d-inline-block bg-warning rounded-5"></span> :
                                                            <span className="dot d-inline-block bg-success rounded-5"></span>) :
                                                        <span className="dot d-inline-block bg-danger rounded-5"></span>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </section>

                            <div className="list-item-more">
                                <i className="bi bi-chevron-right"></i>
                            </div>

                        </Link>
                    })
                    }

                </div>
            </section>

        </section>
    </>
}