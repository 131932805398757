import React, { useEffect, useMemo, useState } from "react";
import { IAddressContract, IService } from "../../common/types";
import { reqGet } from "../../../service/apiRequest";
import { Link, useLocation } from "react-router-dom";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { dateFormat, Filter, fuzzyFilter, ServiceBadge } from "../../common/common";
import $ from 'jquery'
import { ICONS } from "../../common/constants";

export default function Contract() {
    const location = useLocation()

    const [services, setServices] = useState<Array<IService>>([])
    const [contractSelected, setContractSelected] = useState<IAddressContract>()
    const [dataBackup, setDataBackup] = useState<Array<IAddressContract>>([])
    const [data, setData] = useState<Array<IAddressContract>>([])

    useEffect(() => {
        reqGet("service").then(res => { setServices(res) })
        reqGet("addresscontract").then(res => {
            setData(res)
            setDataBackup(res)
            table.setSorting([{ id: "AddressContractDate", desc: true }, { id: "AddressContractBilling", desc: true }])
            table.setPageSize(250)

        })
    }, [])

    const columns = useMemo<ColumnDef<IAddressContract, any>[]>(
        () => [
            {
                accessorFn: row => row.AddressLine,
                id: 'AddressLine',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.AddressContractNumber,
                id: 'AddressContractNumber',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.AddressContractDate,
                id: 'AddressContractDate',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.AddressContractBilling,
                id: 'AddressContractBilling',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.AddressContractPrice,
                id: 'AddressContractPrice',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.AddressContractTerminate,
                id: 'AddressContractTerminate',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.Services,
                id: 'Services',
                cell: info => info.getValue()
            }
        ], []
    )

    const [globalFilter, setGlobalFilter] = useState<string>('')

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    function HandleSelection(e, contract) {
        $('#contractList .list-item.active').removeClass('active')
        e.currentTarget.classList.add('active')

        setContractSelected(contract)
    }

    function FilterService(service: string) {
        if (service) {
            setData(dataBackup.filter(d => d?.Services?.some(s => s.Service == service)))
        } else {
            setData(dataBackup)
        }
    }

    function FilterTerminate(terminate: boolean) {
        if (terminate) {
            setData(dataBackup.filter(d => d?.AddressContractTerminate == 1))
        } else if (!terminate) {
            setData(dataBackup.filter(d => d?.AddressContractTerminate == 0))
        } else {
            setData(dataBackup)
        }
    }

    return <main className="body-container content">

        <header className="header">
            <h2 className="section-title">Contrats</h2>

            <div>
                <Link to="export" className="btn btn-outline" title="Exporter"><i className={ICONS.EXPORT + " me-2"}></i> Exporter</Link>
            </div>
        </header>

        <section className="px-3">

            <div className="row">
                <div className='col'>
                    <h6 className="text-truncate mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("AddressContractNumber")?.getToggleSortingHandler() }}>
                        Numéro
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("AddressContractNumber")?.getIsSorted() as string] ?? null}
                    </h6>
                    <div className="d-flex flex-direction-column mb-3">
                        <Filter column={table.getColumn("AddressContractNumber")} table={table} />
                    </div>
                </div>

                <div className='col'>
                    <h6 className="text-truncate mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("AddressLine")?.getToggleSortingHandler() }}>
                        Adresse
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("AddressLine")?.getIsSorted() as string] ?? null}
                    </h6>
                    <div className="d-flex flex-direction-column mb-3">
                        <Filter column={table.getColumn("AddressLine")} table={table} />
                    </div>
                </div>

                <div className='col'>
                    <h6 className="text-truncate mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("AddressContractTerminate")?.getToggleSortingHandler() }}>
                        Résilié
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("AddressContractTerminate")?.getIsSorted() as string] ?? null}
                    </h6>

                    <select name="" id="" className="form-select form-sm" onChange={(e) => FilterTerminate((/true/).test(e.target.value))}>
                        <option value="">Tout</option>
                        <option value="false">Non</option>
                        <option value="true">Oui</option>
                    </select>
                </div>

                <div className="col">
                    <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("Services")?.getToggleSortingHandler() }}>
                        Prestations
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("Services")?.getIsSorted() as string] ?? null}
                    </h6>

                    <select name="" id="" className="form-select form-sm" onChange={(e) => FilterService(e.target.value)}>
                        <option value="">Tout</option>
                        {services.map(s => {
                            return <option key={s.ServiceID} value={s.Service}>{s.Service}</option>
                        })}
                    </select>
                </div>

                <div className="col">
                    <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("AddressContractDate")?.getToggleSortingHandler() }}>
                        Date de contrat
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("AddressContractDate")?.getIsSorted() as string] ?? null}
                    </h6>
                    <div className="d-flex flex-direction-column mb-3">
                        <Filter column={table.getColumn("AddressContractDate")} table={table} />
                    </div>
                </div>

                <div className='col'>
                    <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("AddressContractBilling")?.getToggleSortingHandler() }}>
                        Date de facturation
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("AddressContractBilling")?.getIsSorted() as string] ?? null}
                    </h6>
                    <div className="d-flex flex-direction-column mb-3">
                        <Filter column={table.getColumn("AddressContractBilling")} table={table} />
                    </div>
                </div>

                <div className="col">
                    <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("AddressContractPrice")?.getToggleSortingHandler() }}>
                        Prix
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("AddressContractPrice")?.getIsSorted() as string] ?? null}
                    </h6>
                    <div className="d-flex flex-direction-column mb-3">
                        <Filter column={table.getColumn("AddressContractPrice")} table={table} />
                    </div>
                </div>

            </div>

        </section>

        <section className="body">

            <div className="list list-hover" id="contractList">

                {table && table?.getRowModel().rows.map(row => {
                    const contract: IAddressContract = row.original

                    return <div className="list-item" key={contract.AddressContractID} onClick={(e) => HandleSelection(e, contract)}>

                        <div className="list-item-data">
                            <div className="row">
                                <div className="col">
                                    <h6 className="item-title">{contract.AddressContractNumber}</h6>
                                </div>
                                <div className="col">
                                    <p className="item-text">{contract.AddressLine}</p>
                                </div>

                                <div className="col">
                                    {contract.AddressContractTerminate == 1 &&
                                        <span className="text-danger ms-2" title="Résilié"><i className={ICONS.TERMINATE}></i></span>}
                                </div>

                                <div className="col">
                                    {contract.Services?.filter((obj1, i, arr) =>
                                        /* FILTER DUPPLICATE */
                                        arr.findIndex(obj2 => (obj2.ServiceID === obj1.ServiceID)) === i
                                    ).map((s, k) => {
                                        return <ServiceBadge service={s.Service} className="me-2" key={k} firstLetter={true} />
                                    })}
                                </div>
                                <div className="col">
                                    <p className="item-text">{dateFormat(contract.AddressContractDate, "dd/MM/yyyy")}</p>
                                </div>
                                <div className="col">
                                    <p className="item-text">{dateFormat(contract.AddressContractBilling, "dd/MM/yyyy")}</p>
                                </div>
                                <div className="col">
                                    <p className="item-text">{contract.AddressContractPrice && contract.AddressContractPrice + ".-"}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                })
                }

            </div>

        </section>


        <footer className="card-footer d-flex justify-content-center align-items-center">

            <nav className="pagination">
                <button className="btn btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >{'<<'}</button>
                <button className="btn btn-outline" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>{'<'}</button>

                <p className="mx-3 mb-0">
                    {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                </p>

                <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>{'>'}</button>
                <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>{'>>'}</button>
            </nav>

            <div className="ms-3">
                <select className="form-select form-sm" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                    {[250, 500, 1000].map(pageSize => (

                        <option key={pageSize} value={pageSize}>
                            Afficher {pageSize}
                        </option>

                    ))}
                </select>
            </div>
        </footer>

    </main>
}