import React, { useState, useEffect, useMemo } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import $ from 'jquery'

import { PrivateLinkAdd, DebouncedInput, fuzzyFilter, ServiceBadge, Filter, PrivateComponent, dateFormat, PrivateLinkUpdate } from "../../common/common";
import { BADGE, GROUP, ICONS } from '../../common/constants'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
} from '@tanstack/react-table'
import { IConstruction } from '../../common/types'
import { reqDelete, reqGet, reqPut } from '../../../service/apiRequest';
import { DB_SERVER } from './../../common/constants';
import { getToken } from '../../../useToken';
import { ButtonModal } from './../../common/common';

export default function Constructions({ reload, setReload }) {
    const [constructionSelected, setConstructionSelected] = useState<IConstruction>()
    const location = useLocation()
    const navigate = useNavigate()

    const token = getToken()

    const columns = useMemo<ColumnDef<IConstruction, any>[]>(
        () => [
            {
                accessorFn: row => row.ConstructionReference,
                id: 'ConstructionReference',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ConstructionProjectNumber,
                id: 'ConstructionProjectNumber',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ConstructionName + row.ConstructionAddress + row.ConstructionAddressComplementary + row.ZipCode + row.City,
                id: 'ConstructionAddress',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.FirstName + " " + row.Name,
                id: 'ConstructionManager',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ConstructionDateAdded,
                id: 'ConstructionDateAdded',
                cell: info => info.getValue(),
                sortType: 'datetime'
            },
            {
                accessorFn: row => row.ConstructionDateClosed,
                id: 'ConstructionDateClosed',
                cell: info => info.getValue(),
                sortType: 'datetime'
            },
            {
                accessorFn: row => row.ConstructionArchived,
                id: 'ConstructionArchived',
                cell: info => info.getValue(),
                filterFn: (row, columnID, filterValue) => {
                    return row.original.ConstructionArchived === filterValue
                }
            }
        ], []
    )

    const [data, setData] = useState<Array<IConstruction>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        reqGet("construction").then(res => {
            setData(res)
        })
        table.setPageSize(100)
        table.setSorting([{ id: "ConstructionDateAdded", desc: true }, { id: "ConstructionReference", desc: true }])
        table.getColumn("ConstructionArchived")?.setFilterValue(0)
    }, [])

    useEffect(() => {
        if (reload) {
            reqGet("construction").then(res => {
                setData(res)
            })

            setReload(false)
        }
    }, [reload])

    function ToggleArchived() {
        // toggle checkbox
        $("#checkboxArchive").attr("checked", !$("#checkboxArchive").attr("checked"))

        let col = table.getColumn("ConstructionArchived")

        if ($("#checkboxArchive").is(":checked"))
            col?.setFilterValue("")
        else
            col?.setFilterValue(0)
    }


    function ToggleDateClosed() {
        let col = table.getColumn("ConstructionDateClosed")

        if (col?.getIsFiltered())
            col?.setFilterValue("")
        else
            col?.setFilterValue("")
    }



    function HandleSelection(e, construction) {
        $('#constructionList .list-item.active').removeClass('active')
        e.currentTarget.classList.add('active')

        setConstructionSelected(construction)
    }

    function ConstructionClose() {

    }

    function ConstructionArchive() {
        if (constructionSelected)
            reqPut("construction/" + constructionSelected.ConstructionID + "/archive", { ConstructionArchived: 1, ActivityUserID: token.UserID }).then(res => {
                setReload(true)
            })
    }


    function ConstructionUnarchive() {
        if (constructionSelected)
            reqPut("construction/" + constructionSelected.ConstructionID + "/archive", { ConstructionArchived: 0, ActivityUserID: token.UserID }).then(res => {
                setReload(true)
            })
    }

    function ConstructionDelete() {
        if (constructionSelected)
            reqDelete("construction/" + constructionSelected.ConstructionID, { data: { ActivityUserID: token.UserID } }).then(res => {
                setReload(true)
            })
    }


    return <div className="body-container content">


        {/* Remove modal */}
        <div className="modal fade" id="removeConstruction" tabIndex={-1} aria-labelledby="removeConstruction" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="removeConstruction">Supprimer</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <p>Êtes-vous sur de vouloir supprimer ce chantier ?</p>
                        <b>{constructionSelected?.ConstructionName}</b>
                        <b>{constructionSelected?.ConstructionAddress && constructionSelected?.ConstructionAddress + ", "}{constructionSelected?.ZipCode} {constructionSelected?.City}</b>
                        <b>{constructionSelected?.ConstructionAddressComplementary}</b>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-delete" data-bs-dismiss="modal" onClick={() => ConstructionDelete()}>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>

        <header className="container-fluid">
            <div className="row">

                <div className="col-6 col-md-3">
                    <h2 className="section-title">Chantiers</h2>
                </div>

                <div className="col-12 col-md-6 order-3 order-md-2 mt-3 mt-md-0">
                    <div className="d-flex align-items-center w-100">
                        <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche" />

                        <div className="dropdown d-inline-block ms-3 text-nowrap h-100">
                            <button className="dropdown-toggle btn btn-sm btn-outline rounded-2" type="button" data-bs-toggle="dropdown" aria-expanded="false" ><i className={ICONS.SHOW + " me-2"}></i> <span className="me-2">Afficher</span></button>

                            <ul className="dropdown-menu">
                                <button className="dropdown-item" onClick={() => ToggleArchived()}>
                                    <div className="form-check mb-0">
                                        <i className={ICONS.ARCHIVE + " me-2"}></i> Archive
                                        <input className="form-check-input me-3" type="checkbox" id="checkboxArchive" />
                                    </div>
                                </button>

                                {PrivateComponent(
                                    <button className="dropdown-item">
                                        <div className="form-check mb-0">
                                            <i className={ICONS.DONE + " me-2"}></i> Fermé
                                            <input className="form-check-input me-3" type="checkbox" value="" id="flexCheckDefault" defaultChecked={true} />
                                            {BADGE.DEV}
                                        </div>
                                    </button>,
                                    GROUP.dev
                                )}

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-3 order-2 order-md-3 text-end">
                    <div className="toolbar">
                        {PrivateLinkAdd("add", GROUP.crud_sav)}

                        <button onClick={() => navigate(constructionSelected?.ConstructionID?.toString() ?? "")} className="btn btn-outline" disabled={constructionSelected ? false : true}>
                            <i className={ICONS.MORE + " me-2"}></i> Détails
                        </button>

                        {PrivateComponent(
                            <div className="dropdown d-inline-block">
                                <button className="btn btn-sm btn-outline rounded-2" type="button" data-bs-toggle="dropdown" aria-expanded="false" disabled={constructionSelected ? false : true}><i className={ICONS.OPTIONS}></i></button>

                                <ul className="dropdown-menu">

                                    {PrivateComponent(
                                        <button onClick={() => navigate(constructionSelected?.ConstructionID + "/update", { state: { path: location.pathname } })} className="dropdown-item mb-2" disabled={constructionSelected ? false : true}>
                                            <i className={ICONS.UPDATE + " me-2"}></i> Modifier
                                        </button>,
                                        GROUP.crud_sav)
                                    }


                                    {PrivateComponent(
                                        <button onClick={() => ConstructionClose()} className="dropdown-item mb-2" disabled={constructionSelected && !constructionSelected?.ConstructionDateClosed ? false : true}>
                                            <i className={ICONS.DONE + " me-2"}></i> Fermer {BADGE.DEV}
                                        </button>,
                                        GROUP.dev)
                                    }

                                    {PrivateComponent(
                                        <>
                                            {constructionSelected?.ConstructionArchived == 0 ?
                                                <button onClick={() => ConstructionArchive()} className="dropdown-item mb-2">
                                                    <i className={ICONS.ARCHIVE + " me-2"}></i> Archiver
                                                </button> :
                                                <button onClick={() => ConstructionUnarchive()} className="dropdown-item mb-2">
                                                    <i className={ICONS.ARCHIVE + " me-2"}></i> Désarchiver
                                                </button>
                                            }
                                        </>,
                                        GROUP.crud_sav
                                    )}

                                    {PrivateComponent(
                                        <button className="dropdown-item btn-delete" title="Supprimer" data-bs-toggle="modal" data-bs-target={"#removeConstruction"} disabled={constructionSelected ? false : true}>
                                            <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                        </button>,
                                        GROUP.dev)
                                    }
                                </ul>
                            </div>,
                            GROUP.crud_sav
                        )}
                    </div>
                </div>

            </div>
        </header>

        <section className="px-3">

            <div className="row">
                <div className='col-3'>
                    <h6 className="text-truncate mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("ConstructionAddress")?.getToggleSortingHandler() }}>
                        Adresse
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("ConstructionAddress")?.getIsSorted() as string] ?? null}
                    </h6>
                    <div className="d-flex flex-direction-column mb-3">
                        <Filter column={table.getColumn("ConstructionAddress")} table={table} />
                    </div>
                </div>

                <div className='col-1'></div>

                <div className='col'>
                    <h6 className="text-truncate mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("ConstructionReference")?.getToggleSortingHandler() }}>
                        Référence
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("ConstructionReference")?.getIsSorted() as string] ?? null}
                    </h6>
                    <div className="d-flex flex-direction-column mb-3">
                        <Filter column={table.getColumn("ConstructionReference")} table={table} />
                    </div>
                </div>

                <div className="col">
                    <h6 className="text-truncate mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("ConstructionProjectNumber")?.getToggleSortingHandler() }}>
                        Numéro de projet
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("ConstructionProjectNumber")?.getIsSorted() as string] ?? null}
                    </h6>
                    <div className="d-flex flex-direction-column mb-3">
                        <Filter column={table.getColumn("ConstructionProjectNumber")} table={table} />
                    </div>
                </div>

                <div className="col">
                    <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("ConstructionManager")?.getToggleSortingHandler() }}>
                        Résponsable
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("ConstructionManager")?.getIsSorted() as string] ?? null}
                    </h6>
                    <div className="d-flex flex-direction-column mb-3">
                        <Filter column={table.getColumn("ConstructionManager")} table={table} />
                    </div>
                </div>

                <div className='col'>
                    <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("ConstructionDateAdded")?.getToggleSortingHandler() }}>
                        Ajouté le
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("ConstructionDateAdded")?.getIsSorted() as string] ?? null}
                    </h6>
                    <div className="d-flex flex-direction-column mb-3">
                        <Filter column={table.getColumn("ConstructionDateAdded")} table={table} />
                    </div>
                </div>

                <div className="col">
                    <h6 className="mb-1" style={{ cursor: "pointer" }} {...{ onClick: table.getColumn("ConstructionDateClosed")?.getToggleSortingHandler() }}>
                        Fermé le
                        {{ asc: <i className="bi bi-caret-up-fill ms-2"></i>, desc: <i className="bi bi-caret-down-fill ms-2"></i>, }[table.getColumn("ConstructionDateClosed")?.getIsSorted() as string] ?? null}
                    </h6>
                    <div className="d-flex flex-direction-column mb-3">
                        <Filter column={table.getColumn("ConstructionDateClosed")} table={table} />
                    </div>
                </div>

            </div>

        </section>



        <section className="body">

            <div className="list list-hover" id="constructionList">

                {table && table?.getRowModel().rows.map(row => {
                    const construction: IConstruction = row.original

                    return <div className="list-item" key={construction.ConstructionID} onClick={(e) => HandleSelection(e, construction)}>

                        <div className="list-item-data">
                            <div className="row">
                                <div className="col-3">
                                    <h6 className="text-truncate mb-1">{construction.ConstructionName}</h6>
                                    {construction.ConstructionAddressComplementary &&
                                        <p className='text-truncate mb-1'>{construction.ConstructionAddressComplementary}</p>
                                    }
                                    <p className='text-truncate text-muted mb-0'>{construction.ConstructionAddress}, {construction.ZipCode} {construction.City}</p>
                                </div>

                                <div className="col-1 d-flex align-items-center">

                                    <div className="row row-cols-3 flex-grow-1 text-center">
                                        <div className="col">
                                            {construction.ConstructionDateClosed &&
                                                <span className="badge bg-success" style={{ fontSize: ".8rem" }}><i className={ICONS.DONE}></i></span>
                                            }
                                        </div>
                                        <div className="col">
                                            {construction.ConstructionArchived == 1 &&
                                                <span className="badge bg-warning" style={{ fontSize: ".8rem" }}><i className={ICONS.ARCHIVE}></i></span>
                                            }
                                        </div>
                                    </div>

                                </div>

                                <div className="col">
                                    <h6>{construction.ConstructionReference}</h6>
                                </div>
                                <div className="col">
                                    <h6 className="mb-0">{construction.ConstructionProjectNumber}</h6>
                                </div>
                                <div className="col">
                                    <h6 className="mb-0">{construction.FirstName} {construction.Name}</h6>
                                </div>
                                <div className="col">
                                    <p className="mb-0">{dateFormat(construction.ConstructionDateAdded, "dd/MM/yyyy")}</p>
                                </div>
                                <div className="col">
                                    <p className="mb-0">{dateFormat(construction.ConstructionDateClosed, "dd/MM/yyyy")}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                })
                }

            </div>

        </section>

        <footer className="card-footer d-flex justify-content-center align-items-center">

            <nav className="pagination">
                <button className="btn btn-outline me-2 " onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                    {'<<'}
                </button>
                <button className="btn btn-outline" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                    {'<'}
                </button>

                <p className="mx-3 mb-0">
                    {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                </p>

                <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                    {'>'}
                </button>
                <button className="btn btn-outline" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                    {'>>'}
                </button>
            </nav>

            <div className="ms-3">
                <select className="form-select form-sm" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                    {[100, 200, 500, 1000].map(pageSize => (

                        <option key={pageSize} value={pageSize}>
                            Afficher {pageSize}
                        </option>

                    ))}
                </select>
            </div>
        </footer>

    </div >
}