import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, NavLink } from "react-router-dom";
import { reqGet } from "../../../service/apiRequest";

import { PrivateComponent, ModalFullScreen, CardBtnLink } from "../../common/common";
import { GROUP, ICONS } from "../../common/constants"
import { IAddress, IZone } from "../../common/types"
import { AddressContractsCards, AddressCounters, InstallsList } from "./address-component/address-component"

export default function AddressContent() {
  const navigate = useNavigate()
  const params = useParams();
  const idAddress = params.idAddress;

  const [address, setAddress] = useState<IAddress>();
  const [zones, setZones] = useState<Array<IZone>>();

  const [toggleZone, setToggleZone] = useState<boolean>(false)
  const [toggleManagement, setToggleManagement] = useState<boolean>(false)
  const [toggleOwner, setToggleOwner] = useState<boolean>(false)
  const [toggleTenant, setToggleTenant] = useState<boolean>(false)

  const [addressLink, setAddressLink] = useState<string>("")

  useEffect(() => {
    reqGet("address/" + idAddress).then((res) => {
      setAddress(res);
      setAddressLink(res.AddressLine.replace(" ", "+") + ", " + res?.ZipCode + "+" + res?.City)
    }, (rej) => {

      navigate("/address")
    });

    reqGet("zone/address/" + idAddress).then(res => setZones(res));
  }, [idAddress]);

  const AddressLinks: {
    Title: string, State, Path: string, Icon: string, PrivateGroup: Array<String> | undefined, PrivatePermission: Array<String> | undefined
  }[] = [
      { Title: "Activité", Path: "activity", Icon: ICONS.ACTIVITY, State: undefined, PrivateGroup: GROUP.crud_sav, PrivatePermission: undefined },
      { Title: "Arrêt/MER chauffage", Path: "heating", Icon: ICONS.HEATING, State: undefined, PrivateGroup: undefined, PrivatePermission: undefined },
      { Title: "Bons office", Path: "good", Icon: ICONS.INTERVENTION, State: undefined, PrivateGroup: GROUP.admin, PrivatePermission: undefined },
      { Title: "Carnet de chaufferie", Path: "workbook", Icon: ICONS.WORKBOOK, State: undefined, PrivateGroup: undefined, PrivatePermission: undefined },
      //{ Title: "Contrats", Path: "contract", Icon: ICONS.CONTRACT, State: undefined, PrivateGroup: GROUP.admin, PrivatePermission: undefined },
      { Title: "Documents", Path: "document", Icon: ICONS.DOCUMENT, State: { idAddress: idAddress }, PrivateGroup: GROUP.crud_sav, PrivatePermission: ["AddressDocumentRead"] },
      { Title: "Facturation", Path: "invoice", Icon: ICONS.INVOICE, State: undefined, PrivateGroup: GROUP.admin, PrivatePermission: undefined },
      //{ Title: "Prestations", Path: "service", Icon: ICONS.SERVICES, State: undefined, PrivateGroup: undefined, PrivatePermission: undefined },
      /*{ Title: "Devis", Path: "quotation", Icon: ICONS.QUOTATION, State: undefined, PrivateGroup: GROUP.dev, PrivatePermission: undefined },*/
    ]


  return (
    <main className="content">

      {/* ZONE */}
      <ModalFullScreen
        toggle={toggleZone}
        setToggle={setToggleZone}
        title={"Zone"}
        content={
          <>

            {zones?.map(z => {
              return <React.Fragment key={z.ZoneID}>
                <h6>{z.FirstName} {z.Name}</h6>
                <dl className="row">
                  <dt className="col-sm-4 mb-1 mb-sm-3">Email</dt>
                  <dd className="col-sm-8 mb-3">{z?.Email}</dd>

                  <dt className="col-sm-4 mb-1 mb-sm-3">Téléphone</dt>
                  <dd className="col-sm-8 mb-3">{z.Phone}</dd>
                </dl>
              </React.Fragment>
            })}
          </>
        }
      />

      {/* MANAGEMENT */}
      <ModalFullScreen
        toggle={toggleManagement}
        setToggle={setToggleManagement}
        title={address?.ManagementName ?? ""}
        content={
          <>

            {address?.ManagementWebsite &&
              <div className="mb-3">
                <a href={address.ManagementWebsite} target="_blank" className="link-nav">Site internet {">"}</a>
              </div>
            }

            <dl className="row">
              <dt className="col-sm-4 mb-1 mb-sm-3">Adresse</dt>
              <dd className="col-sm-8">
                <p className="mb-1">{address?.ManagementAddress}</p>
                <p>{address?.ManagementZipCode} {address?.ManagementCity}</p>
              </dd>

              <dt className="col-sm-4 mb-1 mb-sm-3">Email</dt>
              <dd className="col-sm-8 mb-3">{address?.ManagementEmail}</dd>

              <dt className="col-sm-4 mb-1 mb-sm-3">Email mazout</dt>
              <dd className="col-sm-8 mb-3">{address?.ManagementEmailMazout}</dd>

              <dt className="col-sm-4 mb-1 mb-sm-3">Téléphone</dt>
              <dd className="col-sm-8 mb-3">{address?.ManagementPhone}</dd>

              <dt className="col-sm-4 mb-1 mb-sm-3">Fax</dt>
              <dd className="col-sm-8 mb-3">{address?.ManagementFax}</dd>
            </dl>
          </>
        }
      />

      {/* OWNER */}
      <ModalFullScreen
        toggle={toggleOwner}
        setToggle={setToggleOwner}
        title={address?.OwnerName ?? ""}
        content={
          <>
            <dl className="row">
              <dt className="col-sm-4 mb-1 mb-sm-3">Adresse</dt>
              <dd className="col-sm-8 mb-3">
                <p className="mb-1">{address?.OwnerAddress}</p>
                <p>{address?.OwnerZipCode} {address?.OwnerCity}</p>
              </dd>

              <dt className="col-sm-4 mb-1 mb-sm-3">Email</dt>
              <dd className="col-sm-8 mb-3">{address?.OwnerEmail}</dd>

              <dt className="col-sm-4 mb-1 mb-sm-3">Téléphone</dt>
              <dd className="col-sm-8 mb-3">{address?.OwnerPhone}</dd>
            </dl>
          </>
        }
      />

      {/* TENANT */}
      <ModalFullScreen
        toggle={toggleTenant}
        setToggle={setToggleTenant}
        title={address?.TenantName ?? ""}
        content={
          <>
            <dl className="row">
              <dt className="col-sm-4 mb-1 mb-sm-3">Email</dt>
              <dd className="col-sm-8 mb-3">{address?.TenantEmail}</dd>

              <dt className="col-sm-4 mb-1 mb-sm-3">Téléphone</dt>
              <dd className="col-sm-8 mb-3">{address?.TenantPhone}</dd>
            </dl>
          </>
        }
      />

      {addressLink &&
        <div className="mb-3">
          <a href={"https://www.google.com/maps/search/?api=1&query=" + addressLink} target="_blank" className="link-nav"><i className={ICONS.MAP + " me-2"}></i>Voir sur la carte {">"}</a>
        </div>
      }


      {/* NAV CARDS */}
      <div className="d-none d-lg-block order-lg-1">
        <section className="nav-card">

          {AddressLinks.map((l, key) =>
            PrivateComponent(
              <NavLink to={l.Path} className="nav-card-item" state={l.State} key={key}>
                <i className={"card-icon " + l.Icon}></i>
                <h5 className="card-title">{l.Title}</h5>
              </NavLink>,
              l.PrivateGroup,
              l.PrivatePermission
            )
          )}

        </section>
      </div>


      {/* MOBILE CARDS LINK */}
      <div className="row row-cols-1 row-cols-sm-2 d-lg-none">
        {AddressLinks.map((l, key) => PrivateComponent(
          <div className="col" key={key}>
            <CardBtnLink path={l.Path} title={l.Title} icon={l.Icon} state={l.State} />
          </div>,
          l.PrivateGroup,
          l.PrivatePermission
        )
        )}

        <div className="col">
          {PrivateComponent(<CardBtnLink path="installation" title="Installations" icon={ICONS.INSTALLATION} />)}
        </div>

        <div className="col">
          {PrivateComponent(<CardBtnLink path="counter" title="Compteurs" icon={ICONS.COUNTER} />)}
        </div>
      </div>


      {/* ZONE, MANAGEMENT, OWNER, TENANT CARDS */}
      <h3 className="section-title-2"><i className={ICONS.INFO + " me-2"}></i>Informations</h3>

      <section className="row row-cols-2 row-cols-lg-4 order-3 order-lg-2">

        <div className="col">
          <div className="card">
            <header className="card-header">
              <h5 className="card-title"><i className={ICONS.ZONES + " me-2"}></i>Zone</h5>

              {zones?.length! > 0 &&
                <i className="bi bi-info-circle link-nav me-1" onClick={() => setToggleZone(true)}></i>
              }
            </header>

            <section className="card-body">

              {zones?.map(z => {
                return <div className="row" key={z.ZoneID}>
                  <div className="col-10">
                    <p className="paragraph text-truncate mb-0">{z?.FirstName}  {z?.Name}</p>
                  </div>
                  <div className="col-2">

                  </div>
                </div>
              })}

              {zones?.length! == 0 && <p className="text-muted mb-0">Non défini</p>}
            </section>
          </div>
        </div>

        <div className="col">
          <div className="card">
            <header className="card-header justify-content-between">
              <h5 className="card-title"><i className={ICONS.MANAGEMENT + " me-2"}></i>Régie</h5>

              {address?.ManagementID &&
                <i className="bi bi-info-circle link-nav me-1" onClick={() => setToggleManagement(true)}></i>
              }
            </header>
            <section className="card-body">
              {address?.ManagementName ?
                <p className="mb-0">{address?.ManagementName}</p> :
                <p className="text-muted mb-0">Non défini</p>
              }
            </section>
          </div>
        </div>

        <div className="col">
          <div className="card">
            <header className="card-header justify-content-between">
              <h5 className="card-title"><i className={ICONS.OWNER + " me-2"}></i>Propriétaire</h5>

              {address?.OwnerID &&
                <i className="bi bi-info-circle link-nav me-1" onClick={() => setToggleOwner(true)}></i>
              }
            </header>

            <section className="card-body">
              {address?.OwnerName ?
                <p className="mb-0">{address?.OwnerName}</p> :
                address?.Owner ?
                  <p className="mb-0">{address?.Owner}</p> :
                  <p className="text-muted mb-0">Inconnu</p>
              }
            </section>
          </div>
        </div>

        <div className="col">
          <div className="card">
            <header className="card-header justify-content-between">
              <h5 className="card-title"><i className={ICONS.TENANT + " me-2"}></i>Locataire</h5>

              {address?.TenantID &&
                <i className="bi bi-info-circle link-nav me-1" onClick={() => setToggleTenant(true)}></i>
              }
            </header>
            <section className="card-body">
              {address?.TenantName ?
                <p className="mb-0">{address?.TenantName}</p> :
                <p className="text-muted mb-0">Inconnu</p>
              }
            </section>
          </div>
        </div>

      </section>



      {/* NOTES */}
      <div className="order-4 order-lg-3">
        <header className="d-flex justify-content-between align-items-center mb-2">
          <h3 className="section-title-2"><i className={ICONS.NOTE + " me-2"}></i>Remarques</h3>

          {PrivateComponent(
            <Link to="update-note" className="btn btn-outline" title="Modifier les remarques">
              <i className={ICONS.UPDATE + " me-1"}></i> Modifier
            </Link>,
            GROUP.crud_sav, ["AddressUpdate"]
          )}
        </header>

        <section className="row row-cols-1 row-cols-md-2">

          <div className="col">
            <div className="card">
              <header className="card-header">
                <h5 className="card-title">Interne</h5>
              </header>

              <div className="overflow-y-auto" style={{ maxHeight: "150px" }}>
                {address?.InternNote ?
                  <pre className="paragraph h-100 w-100" style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{address?.InternNote}</pre> :
                  <p className="text-muted mb-0">Aucune</p>
                }
              </div>

            </div>
          </div>

          <div className="col">
            <div className="card">
              <header className="card-header">
                <h5 className="card-title">Général</h5>
              </header>

              <div className="overflow-y-auto" style={{ maxHeight: "150px" }}>
                {address?.Note ?
                  <pre className="paragraph h-100 w-100" style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{address?.Note}</pre> :
                  <p className="text-muted mb-0">Aucune</p>
                }
              </div>
            </div>
          </div>

        </section>
      </div>


      {/* INSTALLATIONS */}
      <section className="order-1 order-lg-4">
        <AddressContractsCards idAddress={idAddress} />
      </section>

      {/* INSTALLATIONS */}
      <section className="order-2 order-lg-5">
        <InstallsList />
      </section>


      {/* COUNTERS */}
      <section className="order-last">
        <AddressCounters idAddress={idAddress} />
      </section>
    </main>
  );
}