import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'

import { getToken } from '../../../useToken'
import { Header, AlertMsg } from '../../common/common'
import { reqGet, reqPut } from "../../../service/apiRequest";
import { IStorageProduct } from "../../common/types"
import { StorageRefResolver, StorageRefItem } from "./warehouse-takeout"
import { PRODUCT_AVAILABLE, PRODUCT_CLASS_ID } from "../../common/constants";

export default function WarehousePutBack() {
    const navigate = useNavigate()
    const params = useParams()
    const idWarehouse = params.idWarehouse
    const token = getToken()

    const location = useLocation()
    const idStorage = location?.state?.idStorage

    const [reference, setReference] = useState<number | null>(null)
    const [storage, setStorage] = useState<IStorageProduct | null>(null)
    const [entryMode, setEntryMode] = useState<string | null>(null)

    const [authSubmit, setAuthSubmit] = useState<boolean>(false)

    useEffect(() => {
        if (idStorage) {
            setEntryMode("qrCode")
            setReference(idStorage)
        }
    }, [idStorage])

    useEffect(() => {
        if (reference) {
            // checking storage ID
            reqGet("storage/" + reference + "/warehouse/" + idWarehouse).then(res => {

                if (res != false) {
                    setStorage(res)
                } else {

                    // checking product reference
                    reqGet("storage/productref/" + reference + "/warehouse/" + idWarehouse).then(result => {
                        if (result != false) {
                            setStorage(result)
                        } else {
                            setStorage(null)
                        }
                    })
                }

            })
        }
    }, [reference])

    function HandleEntryMode(value) {
        if (value == null) {
            setEntryMode(null)
            setReference(null)
            setStorage(null)
        } else {
            setEntryMode(value)
        }
    }

    function PutBack() {

        var data = {
            ...storage,
            ActivityUserID: token.UserID,
            PutBackQuantity: parseInt($("#PutBackQuantity").val()) ?? null,
            PutBackCondition: $("#PutBackCondition").val() ?? null,
            ProductPutBackNote: $("#ProductPutBackNote").val() ?? null
        }


        reqPut("storage/putback", data).then((res) => {
            setEntryMode(null)
            setReference(null)
            setStorage(null)

            navigate(-1)
        })
    }

    return <div className="row h-100 justify-content-center">
        <div className="col-sm-8 col-lg-4 col-h-fill">

            <section className="body-container content">
                <header className="mb-3">
                    <h3 className="section-title mb-0">Déposer</h3>
                    {entryMode == null ?
                        <button onClick={() => navigate(-1)} className="btn link-nav">{"<"} Retour</button> :
                        <button className="btn link-nav" onClick={() => idStorage ? navigate(-1) : HandleEntryMode(null)}>{"<"} Retour</button>}
                </header>

                <section className="body container-fluid">

                    <StorageRefResolver entryMode={entryMode} setEntryMode={(value) => HandleEntryMode(value)} setReference={setReference} reference={idStorage} />

                    {reference == null ?
                        <></> :
                        storage ?
                            <StorageRefItem storage={storage} /> :
                            AlertMsg("info", "Aucune référence trouvée")
                    }


                    { // If consummable
                        reference && storage?.ProductClassID == PRODUCT_CLASS_ID.CONSUMABLE &&
                        <>
                            <div className="card">
                                <div className="card-body">
                                    <label htmlFor="PutBackQuantity" className="form-label">Nombre à déposer *</label>
                                    <input id="PutBackQuantity" type="number" className="form-control mb-3" min={1} onChange={(e) => parseInt(e.target.value) > 0 ? setAuthSubmit(true) : setAuthSubmit(false)} />
                                </div>
                            </div>

                            <div className="text-end">
                                <button className="btn btn-primary" id="btnSubmit" onClick={() => PutBack()} disabled={!authSubmit}><i className="bi bi-check me-1"></i>Valider</button>
                            </div>
                        </>
                    }


                    { // If reusable
                        reference && storage?.ProductClassID == PRODUCT_CLASS_ID.REUSABLE ?

                            // If out
                            storage?.ProductAvailable == PRODUCT_AVAILABLE.OUT ?
                                <>
                                    <article className="card">

                                        <section className="card-body">
                                            <label htmlFor="PutBackCondition" className="form-label">Etat *</label>
                                            <select className="form-select" name="" id="PutBackCondition" onChange={(e) => e.target.value ? setAuthSubmit(true) : setAuthSubmit(false)}>
                                                <option value=""></option>
                                                <option value="Neuf">Neuf</option>
                                                <option value="Bon">Bon</option>
                                                <option value="Maintenance">Maintenance</option>
                                                <option value="Hors service">Hors service</option>
                                            </select>
                                        </section>
                                    </article>

                                    <div className="mb-3">
                                        <label htmlFor="ProductPutBackNote" className="form-label">Remarque</label>
                                        <textarea rows={4} id="ProductPutBackNote"></textarea>
                                    </div>

                                    <div className="text-end">
                                        <button className="btn btn-primary" onClick={() => PutBack()} disabled={!authSubmit}><i className="bi bi-check me-1"></i>Valider</button>
                                    </div>
                                </> :

                                // If repairing
                                storage?.ProductAvailable == PRODUCT_AVAILABLE.REPAIRING ?
                                    <>
                                        {AlertMsg("warning", "Ce produit est actuellement en réparation")}

                                        <div className="card card-btn">
                                            <Link to={"/warehouse/" + idWarehouse + "/storage/" + storage?.StorageID + "/repair"} className="card-body">
                                                <i className="card-icon bi bi-wrench-adjustable-circle"></i>

                                                <div className="card-content">
                                                    <h5 className="card-title">Retour de réparation</h5>
                                                </div>
                                                <i className="bi bi-chevron-right"></i>
                                            </Link>
                                        </div>
                                    </>
                                    :
                                    AlertMsg("success", "Ce produit est déjà à son emplacement de stockage")
                            : <></>
                    }

                </section>

            </section>

        </div>
    </div >

}