import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"
import { Header, PrivateLinkUpdate } from "../../../../common/common"
import { reqGet } from "../../../../../service/apiRequest";
import { IExchanger, IProduct } from "../../../../common/types";
import { StorageAvailabilityBadge } from "../../../storage/storage";
import { GROUP } from "../../../../common/constants";

export default function ExchangerTemplateContent() {
    const params = useParams();
    const [exchanger, setExchanger] = useState<IExchanger>()
    const [products, setProducts] = useState<Array<IProduct>>([])

    useEffect(() => {
        reqGet("exchanger/" + params.idExchanger).then((res: IExchanger) => {
            setExchanger(res)

            reqGet("product/exchanger/" + res.ExchangerLabel).then(res => setProducts(res))
        })
    }, [params.idExchanger])

    return <section className="body-container content">

        <header className="header">
            <div>
                <h2 className="section-title">{exchanger?.ExchangerModel}</h2>
                <Link to="/exchanger/template" className="link-nav d-md-none">{"<"} Retour</Link>
            </div>

            <div>
                {PrivateLinkUpdate("update", GROUP.crud_sav, ["ExchangerTemplateUpdate"])}
            </div>
        </header>

        <section className="body">

            <h3 className="section-title2">Données</h3>

            <div className="card">

                <section className="card-body">

                    <div className="row mb-1">
                        <div className="col-5">
                            <h6 className="text-muted">Label</h6>
                        </div>
                        <div className="col">
                            <p className="paragraph">{exchanger?.ExchangerLabel}</p>
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className="col-5">
                            <h6 className="text-muted">Marque</h6>
                        </div>
                        <div className="col">
                            <p className="paragraph">{exchanger?.ExchangerBrand}</p>
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className="col-5">
                            <h6 className="text-muted">Référence</h6>
                        </div>
                        <div className="col">
                            <p className="paragraph">{exchanger?.ExchangerReference}</p>
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className="col-5">
                            <h6 className="text-muted">Hauteur</h6>
                        </div>
                        <div className="col">
                            <p className="paragraph">{exchanger?.ExchangerHeight}</p>
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className="col-5">
                            <h6 className="text-muted">Largeur</h6>
                        </div>
                        <div className="col">
                            <p className="paragraph">{exchanger?.ExchangerWidth}</p>
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className="col-5">
                            <h6 className="text-muted">Profondeur</h6>
                        </div>
                        <div className="col">
                            <p className="paragraph">{exchanger?.ExchangerDepth}</p>
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className="col-5">
                            <h6 className="text-muted">Sortie</h6>
                        </div>
                        <div className="col">
                            <p className="paragraph">{exchanger?.ExchangerOutput}</p>
                        </div>
                    </div>
                </section>
            </div>

            {
                products.length > 0 &&
                <>
                    <h3 className="section-title2">Echangeurs</h3>

                    {products.map(p => <Link to={"/exchanger/" + p.ProductID} key={p.ProductID} className="list-item">
                        <div className="d-flex align-items-center">
                            <h6 className="mb-0">{p.ProductLabel}</h6>
                        </div>

                        <div className="list-item-data">
                            <div className="row row-cols-2 row-cols-sm-3 justify-content-end">

                                <div className="col">
                                    <h6 className="text-light">Status</h6>
                                    <p className="paragraph text-truncate">
                                        {StorageAvailabilityBadge(p.ProductAvailable)}
                                    </p>
                                </div>
                                <div className="col">
                                    <h6 className="text-light">Entrepôt</h6>
                                    <p className="paragraph text-truncate">{p?.WarehouseName}</p>
                                </div>

                                <div className="col d-none d-sm-block">
                                    <h6 className="text-light">Stockage</h6>
                                    <p className="paragraph text-truncate">
                                        {p?.WarehouseName ?
                                            p?.Rack + p?.Row + "-" + p?.Col + (p?.Compartment ? "-" + p?.Compartment : "") :
                                            ""
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="list-item-more">
                            <i className="bi bi-chevron-right"></i>
                        </div>
                    </Link>)}

                </>
            }


        </section >

    </section >
}