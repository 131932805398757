import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import { flexRender } from '@tanstack/react-table'

import { reqGet, reqPut } from '../../../service/apiRequest'
import { HeaderRow, DebouncedInput, Filter } from '../../common/common'
import { IMaterial } from '../../common/types'

import { MaterialTable } from '../material/material'
import { getToken } from '../../../useToken'
import { ICONS } from '../../common/constants';

export default function MaterialMerge() {
    const navigate = useNavigate()
    const params = useParams()
    const idMaterial = params.idMaterial
    const token = getToken()

    const [material1, setMaterial1] = useState<IMaterial>()
    const [material2, setMaterial2] = useState<IMaterial>()

    const table = MaterialTable()

    const [materialSelection, setMaterialSelection] = useState<boolean>(true)

    useEffect(() => {
        reqGet("material/" + idMaterial).then(res => setMaterial1(res))
    }, [])

    function handleSelection(material, e) {
        $('#table tr.active').removeClass('active')
        e.currentTarget.classList.add('active')

        setMaterial2(material)
    }

    function onSubmit() {
        const data = {
            Material1: material1,
            Material2: material2,
            ActivityUserID: token.UserID
        }

        reqPut("material/merge", data).then(res => {
            navigate(-1)
        })
    }

    return <section className="body-container content">

        {HeaderRow(
            <h3 className="section-title">Fusion de matériel</h3>,
            <button className="btn link-nav" onClick={() => navigate(-1)}>{"< Retour"}</button>
        )}

        <section className="body container-fluid">
            <div className="row h-100 justify-content-center">

                <div className="col-4 col-h-fill ps-0">

                    <div className="card card-fill">
                        <div className="card-header">
                            <h5 className="card-title">Matériel N°1</h5>
                        </div>

                        <div className="card-body">
                            <h6 className="text-light">Catégorie</h6>
                            <p>{material1?.Class}</p>

                            <h6 className="text-light">Type</h6>
                            <p>{material1?.Type}</p>

                            <h6 className="text-light">Marque</h6>
                            <p>{material1?.Brand}</p>

                            <h6 className="text-light">Modèle</h6>
                            <p>{material1?.Model}</p>

                            <h6 className="text-light">Référence</h6>
                            <p>{material1?.Reference}</p>

                            <h6 className="text-light">Année production</h6>
                            <p>{material1?.MaterialYearProduction}</p>

                            <h6 className="text-light">Année fin production</h6>
                            <p>{material1?.MaterialYearEndProduction}</p>

                            <h6 className="text-light">Capacité (L)</h6>
                            <p>{material1?.Capacity}</p>

                            <h6 className="text-light">Plage de puissance</h6>
                            <p>{material1?.PowerRange}</p>

                            <h6 className="text-light">ICF</h6>
                            <p>{material1?.ICF}</p>

                            <h6 className="text-light">Surface</h6>
                            <p>{material1?.Surface}</p>

                            <h6 className="text-light">Longueur</h6>
                            <p>{material1?.Longueur}</p>

                            <h6 className="text-light">Alimentation</h6>
                            <p>{material1?.Alimentation}</p>
                        </div>
                    </div>

                </div>

                {!materialSelection &&
                    <>
                        <div className="col-4 col-h-fill">

                            <div className="card card-fill">
                                <div className="card-header">
                                    <h5 className="card-title">Matériel N°2</h5>

                                    <button className="btn btn-outline" onClick={() => setMaterialSelection(true)}>Choisir</button>
                                </div>

                                <div className="card-body">
                                    <h6 className="text-light">Catégorie</h6>
                                    <p>{material2?.Class}</p>

                                    <h6 className="text-light">Type</h6>
                                    <p>{material2?.Type}</p>

                                    <h6 className="text-light">Marque</h6>
                                    <p>{material2?.Brand}</p>

                                    <h6 className="text-light">Modèle</h6>
                                    <p>{material2?.Model}</p>

                                    <h6 className="text-light">Référence</h6>
                                    <p>{material2?.Reference}</p>

                                    <h6 className="text-light">Année production</h6>
                                    <p>{material2?.MaterialYearProduction}</p>

                                    <h6 className="text-light">Année fin production</h6>
                                    <p>{material2?.MaterialYearEndProduction}</p>

                                    <h6 className="text-light">Capacité (L)</h6>
                                    <p>{material2?.Capacity}</p>

                                    <h6 className="text-light">Plage de puissance</h6>
                                    <p>{material2?.PowerRange}</p>

                                    <h6 className="text-light">ICF</h6>
                                    <p>{material2?.ICF}</p>

                                    <h6 className="text-light">Surface</h6>
                                    <p>{material2?.Surface}</p>

                                    <h6 className="text-light">Longueur</h6>
                                    <p>{material2?.Longueur}</p>

                                    <h6 className="text-light">Alimentation</h6>
                                    <p>{material2?.Alimentation}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-4 col-h-fill pe-0">

                            <div className="card card-fill">
                                <div className="card-header">
                                    <h5 className="card-title">Matériel fusionné</h5>

                                    <button className="btn btn-primary" onClick={() => onSubmit()}>Valider la fusion</button>
                                </div>

                                <div className="card-body">
                                    <h6 className="text-light">Catégorie</h6>
                                    <p>{material1?.Class || material2?.Class}</p>

                                    <h6 className="text-light">Type</h6>
                                    <p>{material1?.Type || material2?.Type}</p>

                                    <h6 className="text-light">Marque</h6>
                                    <p>{material1?.Brand || material2?.Brand}</p>

                                    <h6 className="text-light">Modèle</h6>
                                    <p>{material1?.Model || material2?.Model}</p>

                                    <h6 className="text-light">Référence</h6>
                                    <p>{material1?.Reference || material2?.Reference}</p>

                                    <h6 className="text-light">Année production</h6>
                                    <p>{material1?.MaterialYearProduction || material2?.MaterialYearProduction}</p>

                                    <h6 className="text-light">Année fin production</h6>
                                    <p>{material1?.MaterialYearEndProduction || material2?.MaterialYearEndProduction}</p>

                                    <h6 className="text-light">Capacité (L)</h6>
                                    <p>{material1?.Capacity || material2?.Capacity}</p>

                                    <h6 className="text-light">Plage de puissance</h6>
                                    <p>{material1?.PowerRange || material2?.PowerRange}</p>

                                    <h6 className="text-light">ICF</h6>
                                    <p>{material1?.ICF || material2?.ICF}</p>

                                    <h6 className="text-light">Surface</h6>
                                    <p>{material1?.Surface || material2?.Surface}</p>

                                    <h6 className="text-light">Longueur</h6>
                                    <p>{material1?.Longueur || material2?.Longueur}</p>

                                    <h6 className="text-light">Alimentation</h6>
                                    <p>{material1?.Alimentation || material2?.Alimentation}</p>
                                </div>
                            </div>
                        </div>
                    </>}

                {materialSelection &&
                    <div className="col-8 col-h-fill">

                        <div className="d-flex justify-content-between align-items-start mb-3">
                            <h4 className="section-title-2">Matériel à unire</h4>

                            <button className="btn btn-add" disabled={!material2} onClick={() => setMaterialSelection(false)}>
                                <i className={ICONS.ADD + " me-2"}></i> Valider
                            </button>
                        </div>


                        <div className="card card-fill">

                            <div className="card-header row">
                                <div className="col-6 col-lg-3 order-1">
                                    <h5 className="card-title">Liste du matériel</h5>
                                </div>

                                <div className="col-lg-6 order-3 order-lg-2 mt-3 mt-lg-0">
                                    <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche dans tout le matériel" />
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-hover table-sticky" id="table">

                                        <thead>

                                            {table.getHeaderGroups().map(headerGroup => (
                                                <tr key={headerGroup.id}>

                                                    {headerGroup.headers.map((header, k) => {
                                                        return (
                                                            <th key={k} colSpan={header.colSpan} style={{ minWidth: "170px" }}>

                                                                {header.isPlaceholder ? null : (
                                                                    <>

                                                                        <p className="mb-1" {...{ onClick: header.column.getToggleSortingHandler() }}>

                                                                            {flexRender(header.column.columnDef.header, header.getContext())}

                                                                            {{ asc: <i className="bi bi-carret-up-fill"></i>, desc: <i className="bi bi-carret-down-fill"></i>, }[header.column.getIsSorted() as string] ?? null}

                                                                        </p>

                                                                        {header.column.getCanFilter() ? (
                                                                            <div className="d-flex flex-direction-column">
                                                                                <Filter column={header.column} table={table} />
                                                                            </div>
                                                                        ) : null}

                                                                    </>
                                                                )}
                                                            </th>
                                                        )
                                                    })}

                                                </tr>
                                            ))}
                                        </thead>

                                        <tbody>
                                            {table.getRowModel().rows.map(row => {
                                                return (

                                                    <tr key={row.id} onClick={(e) => handleSelection(row.original, e)}>

                                                        {row.getVisibleCells().map(cell => {
                                                            return (

                                                                <td key={cell.id}>
                                                                    {flexRender(
                                                                        cell.column.columnDef.cell,
                                                                        cell.getContext()
                                                                    )}
                                                                </td>

                                                            )
                                                        })}

                                                    </tr>
                                                )

                                            })}
                                        </tbody>

                                    </table>
                                </div>
                            </div>

                            <footer className="container-fluid mt-3">
                                <div className="row mx-0 pagination">

                                    <div className="col-8 col-lg-4 px-0 text-start order-2 order-lg-1">
                                        <button className="btn btn-outline me-2" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} >
                                            {'<<'}
                                        </button>
                                        <button className="btn btn-outline me-2" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} >
                                            {'<'}
                                        </button>

                                        <p className="d-inline mb-0 me-2">
                                            {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                                        </p>

                                        <button className="btn btn-outline me-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} >
                                            {'>'}
                                        </button>
                                        <button className="btn btn-outline me-2" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} >
                                            {'>>'}
                                        </button>
                                    </div>

                                    <div className="col col-lg-3 offset-lg-5 pe-0 text-end order-4">
                                        <select className="form-select float-end" value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>

                                            {[50, 100, 200].map(pageSize => (

                                                <option key={pageSize} value={pageSize}>
                                                    Afficher {pageSize}
                                                </option>

                                            ))}

                                        </select>
                                    </div>

                                </div>

                            </footer>

                        </div>

                    </div>}
            </div>
        </section>

    </section >
}