import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, Outlet, Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import { LAYOUT } from "../../common/constants"
import { DebouncedInput, dateFormat, fuzzyFilter } from "../../common/common";
import { reqGet, reqPost } from "../../../service/apiRequest";
import { IAddress, IObservation, IZone } from "../../common/types"

import { useReactTable, getCoreRowModel, getFilteredRowModel, getSortedRowModel, ColumnDef, } from '@tanstack/react-table'
import { getToken } from "../../../useToken";

interface AddressObservation extends IAddress, IObservation { }

export default function Survey({ reload, setReload }) {
    const navigate = useNavigate()
    const token = getToken()
    const location = useLocation()
    const [display, setDisplay] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.md) {
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/survey") {
                setDisplay('d-flex')

            } else {
                setDisplay('d-none')
            }
        }
    }

    const columns = useMemo<ColumnDef<AddressObservation, any>[]>(
        () => [
            {
                accessorFn: row => row.AddressLine,
                id: 'AddressLine',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.AddressComplementary,
                id: 'AddressComplementary',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ZoneName,
                id: 'ZoneName',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.ObservationDate,
                id: 'ObservationDate',
                cell: info => info.getValue()
            }
        ], []
    )

    const [data, setData] = useState<Array<AddressObservation>>([])
    const [dataBackup, setDataBackup] = useState<Array<AddressObservation>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    const [zones, setZones] = useState<Array<IZone>>()

    useEffect(() => {
        reqGet("address/survey").then((res) => {
            setDataBackup(res)
            setData(res);
        }, rej => {
            setDataBackup([])
            setData([])
            navigate(-1)
        })
        reqGet("zone").then(res => setZones(res))
        table.setSorting([{ id: "ObservationDate", desc: true }])
    }, [])


    useEffect(() => {
        if (reload) {
            reqGet("address/survey").then((res) => {
                setDataBackup(res)
                setData(res);
            }, rej => {
                setDataBackup([])
                setData([])
                navigate(-1)
            })
            setReload(false)
        }
    }, [reload])

    function FilterZone(ZoneName) {
        table.getColumn("ZoneName")?.setFilterValue(ZoneName)
    }

    function HandleSort(value: string) {
        if (value == "address")
            table.setSorting([{ id: "AddressLine", desc: false }])
        else if (value == "observationDate") {
            table.setSorting([{ id: "ObservationDate", desc: true }])
        } else
            table.setSorting([{ id: "AddressLine", desc: false }])
    }

    return (
        <div className="container-fluid h-100">
            <div className="row h-100">
                <section className={display + " col-md-7 col-lg-5 col-h-fill border-right py-3"}>
                    <div className="body-container">

                        <header className="header">
                            <h2 className="section-title mb-0">Surveillance</h2>
                        </header>


                        <section className="body">
                            <article className="card card-fill card-flush">

                                <header className="card-header d-block">
                                    <div className="row">

                                        <div className="col-4">
                                            <label className="form-label mb-1">Zone</label>
                                            <select className="form-select form-sm" onChange={(e) => FilterZone(e.target.value)}>
                                                <option value="">Tout</option>

                                                {zones?.map((z) => {
                                                    return <option value={z.ZoneName} key={z.ZoneID}>{z.FirstName} {z.Name}: {z.ZoneName}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">

                                        <div className="col-8">
                                            <DebouncedInput value={''} onChange={value => table.setGlobalFilter(String(value))} placeholder="Recherche" />
                                        </div>

                                        <div className="col-4">
                                            <select name="" id="" className="form-select" onChange={(e) => HandleSort(e.target.value)}>
                                                <option value="observationDate">Date surveillance</option>
                                                <option value="address">Adresse</option>
                                            </select>
                                        </div>
                                    </div>

                                </header>

                                <section className="card-body">

                                    <nav className="list-group list-group-hover">


                                        {data.length == 0 && <p className="text-muted">Vous n'avez pas d'adresse sous surveillance</p>}

                                        {table.getRowModel().rows.map(row => {

                                            return (
                                                <NavLink to={row.original.AddressID.toString()} key={row.original.AddressID} className="list-group-item">

                                                    <div className="row mx-0">

                                                        <div className="col-9 px-0">
                                                            <h5 className="item-title" style={{ fontSize: "1.1rem" }}>{row.original.AddressLine} {row.original.AddressComplementary && "(" + row.original.AddressComplementary + ")"}</h5>

                                                            <p className="item-text">{row.original.ZipCode} {row.original.City}</p>
                                                        </div>

                                                        <div className="col-3 text-end px-0">
                                                            <h6 className="item-title">{dateFormat(row.original.ObservationDate, "dd/MM/yyyy hh:mm")}</h6>
                                                            <p className="item-text">{row.original.FirstName} {row.original.Name}</p>
                                                        </div>

                                                    </div>
                                                    {row.original.Note && <>
                                                        <h6 className="item-title mb-0 mt-2">Remarque</h6>
                                                        <p className="item-subtitle mb-0 text-truncate">{row.original.Note}</p>
                                                    </>
                                                    }
                                                </NavLink>
                                            )

                                        })}
                                    </nav>
                                </section>


                            </article>
                        </section>

                    </div>
                </section>


                {data.length > 0 &&
                    <div className="col col-md-5 col-lg-7 col-h-fill content">

                        <Outlet context={data} />

                    </div>}
            </div>
        </div>

    );
}