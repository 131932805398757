import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { reqGet, reqPut } from "../../../../service/apiRequest";
import { ButtonSubmit, Header, HeaderRow } from "../../../common/common";
import { IAddress, IAddressService, IInstallation, IService, ISubcontractor } from "../../../common/types";
import { getToken } from "../../../../useToken";
import { SERVICE } from "../../../common/constants";

export default function AddressServiceUpdate({ setReload }) {
    const navigate = useNavigate()
    const token = getToken()
    const location = useLocation()
    const params = useParams()
    const idAddress = params.idAddress
    const idAddressContract = params.idAddressContract
    const idAddressService = params.idAddressService

    const validationSchema = yup.object({
        ServiceID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Ce champ est obligatoire"),
        InstallationID: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        AddressServiceDate: yup.string().nullable(),
        AddressServicePeriodicity: yup.string().nullable(),
        AddressServiceNote: yup.string().nullable(),
        SubcontractorID: yup.number().nullable()

    }).required();

    const initialValues = {
        AddressContractID: parseInt(idAddressContract ?? ""),
        AddressID: parseInt(idAddress ?? ""),
        InstallationID: null,
        AddressServiceDate: null,
        AddressServicePeriodicity: null,
        AddressServiceNote: null,
        SubcontractorID: null
    }

    const { register, formState: { errors }, handleSubmit, setValue, watch, getValues } = useForm<IAddressService>({
        mode: "onTouched",
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [address, setAddress] = useState<IAddress>()
    const [installations, setInstallations] = useState<Array<IInstallation>>([])
    const [services, setServices] = useState<Array<IService>>([])
    const [subcontractors, setSubcontractors] = useState<Array<ISubcontractor>>([])

    const [serviceSelected, setServiceSelected] = useState<IService>()

    useEffect(() => {

        var P1 = reqGet("address/" + idAddress).then(res => setAddress(res))
        var P2 = reqGet("service").then(res => setServices(res))
        var P3 = reqGet("installation/address/" + idAddress).then(res => setInstallations(res))
        var P4 = reqGet("subcontractor").then(res => setSubcontractors(res))

        Promise.all([P1, P2, P3, P4]).then(result => {
            reqGet("addressservice/" + idAddressService).then(res => {

                setValue("AddressServiceID", res.AddressServiceID)
                setValue("AddressID", res.AddressID)
                setValue("ServiceID", res.ServiceID)
                setValue("InstallationID", res?.InstallationID)
                setValue("AddressServiceDate", res?.AddressServiceDate)
                setValue("AddressServicePeriodicity", res?.AddressServicePeriodicity)
                setValue("SubcontractorID", res?.SubcontractorID)
                setValue("AddressServiceNote", res?.AddressServiceNote)
            })
        })
    }, [idAddress, idAddressService])

    // Update selected service
    useEffect(() => {
        if (getValues("ServiceID")) {
            setServiceSelected(services.find(s => s.ServiceID == watch("ServiceID")))
        }
    }, [watch("ServiceID")])

    const LinkBack = () => {
        if (location?.state?.back)
            return location.state.back
        else
            return '/address/' + idAddress + "/contract"
    }

    const onSubmit: SubmitHandler<IAddressService> = data => {
        console.log(data);
        
        if (serviceSelected)
            reqPut('addressservice/' + idAddressService,
                {
                    ...data,
                    ActivityUserID: token.UserID,
                    Service: serviceSelected.Service,
                    AddressLine: address?.AddressLine,
                    AddressZipCode: address?.ZipCode,
                    City: address?.City
                }).then((res) => {
                    setReload(true)
                    navigate(LinkBack())
                })
    };

    return <div className="content">
        <div className="row justify-content-center">
            <div className="col-lg-8 px-8">
                <div className="body-container">

                    <header className="header">
                        <div>
                            <h2 className="section-title">Ajouter une prestation</h2>
                            <Link to={LinkBack()} className="link-nav">{"<"} Retour</Link>
                        </div>
                    </header>

                    <section className="body">

                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="row row-cols-2 mb-3">

                                <div className="col">
                                    <label className="form-label">Prestation *</label>
                                    <select className={"form-select " + (errors?.ServiceID ? "is-invalid" : "")} {...register("ServiceID")}>
                                        <option value=""> </option>
                                        {services.map((s: IService, k: number) => {
                                            return <option key={k} value={s.ServiceID}>{s.Service}</option>;
                                        })}
                                    </select>
                                </div>

                                {serviceSelected?.Service != SERVICE.HEATING &&
                                    <div className="col">
                                        <label className="form-label">Périodicité</label>
                                        <select className={"form-select " + (errors?.AddressServicePeriodicity ? "is-invalid" : "")} {...register("AddressServicePeriodicity")}>
                                            <option value=""></option>
                                            <option value="1 par semaine">1 par semaine</option>
                                            <option value="1 par mois">1 par mois</option>
                                            <option value="2 par mois">2 par mois</option>
                                            <option value="1 tout les 3 mois">1 tout les 3 mois</option>
                                            <option value="1 tout les 6 mois">1 tout les 6 mois</option>
                                            <option value="1 par an">1 par an</option>
                                            <option value="1 tout les 2 ans">1 tout les 2 ans</option>
                                            <option value="1 tout les 3 ans">1 tout les 3 ans</option>
                                            <option value="1 tout les 4 ans">1 tout les 4 ans</option>
                                            <option value="1 tout les 5 ans">1 tout les 5 ans</option>
                                        </select>

                                        {errors.AddressServicePeriodicity?.message && <p className="input-msg-error">{errors.AddressServicePeriodicity?.message}</p>}
                                    </div>
                                }

                            </div>


                            {serviceSelected?.Service == SERVICE.SUBCONTRACTOR &&
                                <div className="mb-3">
                                    <label className="form-label">Sous-traitant *</label>
                                    <select className={"form-select " + (errors?.SubcontractorID ? "is-invalid" : "")} {...register("SubcontractorID")}>
                                        <option value=""> </option>
                                        {subcontractors.map((s: ISubcontractor) => {
                                            return <option className="text-truncate" key={s.SubcontractorID} value={s.SubcontractorID}>{s.Company}</option>;
                                        })}
                                    </select>
                                </div>
                            }

                            {serviceSelected && [SERVICE.MAINTEANANCE, SERVICE.SCALING, SERVICE.SUBCONTRACTOR, SERVICE.CLEANING].includes(serviceSelected.Service) &&
                                <div className="mb-3">
                                    <label className="form-label">Installation</label>
                                    <select className={"form-select " + (errors?.InstallationID ? "is-invalid" : "")} {...register("InstallationID")}>
                                        <option value=""> </option>
                                        {installations.filter(i => i.Service.length == 0 || i.InstallationID == getValues("InstallationID") || serviceSelected.Service == SERVICE.SUBCONTRACTOR).map((i: IInstallation) => {
                                            return <option className="text-truncate" key={i.InstallationID} value={i.InstallationID}>{i.Type || i.IType || i.Class || i.IClass}, {i.Model || i.IModel}</option>;
                                        })}
                                    </select>
                                </div>
                            }

                            <div className="mb-3">
                                <label className="form-label">Information</label>
                                <textarea rows={4} className={(errors?.AddressServiceNote ? "is-invalid" : "")} {...register("AddressServiceNote")} />
                            </div>

                            <div className="text-end">
                                <p><small>Les champs avec une * sont obligatoire</small></p>
                                <ButtonSubmit />
                            </div>

                        </form>
                    </section>
                </div>

            </div>
        </div>
    </div>
}