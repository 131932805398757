import React, { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import $ from 'jquery'
import { GROUP, HOLIDAY_STATUS, HOLIDAY_TYPE, ICONS, LAYOUT } from "../../../common/constants";
import { PrivateComponent, PrivateLinkAdd, dateFormat, fuzzyFilter } from "../../../common/common";
import { IHoliday } from "../../../common/types";
import { getToken } from "../../../../useToken";
import { reqDelete, reqGet, reqPut } from "../../../../service/apiRequest";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

export default function AccountHoliday({ reload, setReload }) {
    const token = getToken()
    const location = useLocation()

    const [display, setDisplay] = useState<string>("")
    const [display2, setDisplay2] = useState<string>("")

    useEffect(() => { window.addEventListener('resize', handleNavOnResize) })

    useEffect(() => { handleNavOnResize() }, [location])

    function handleNavOnResize() {

        if ($(window).width() >= LAYOUT.xxl) {
            setDisplay('d-flex')
            setDisplay('d-flex')

        } else {
            if (location.pathname == "/account/holiday") {
                setDisplay('d-flex')
                setDisplay2('d-none')

            } else {
                setDisplay('d-none')
                setDisplay2('d-flex')
            }
        }

    }

    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [data, setData] = useState<Array<IHoliday>>([])
    const [globalFilter, setGlobalFilter] = useState<string>('')

    const columns = useMemo<ColumnDef<any | IHoliday>[]>(
        () => [
            {
                accessorFn: row => row.HolidayStatus,
                id: 'HolidayStatus',
                cell: info => info.getValue()
            },
            {
                accessorFn: row => row.HolidayType,
                id: 'HolidayType',
                cell: info => info.getValue()
            }
        ], []
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })


    useEffect(() => {
        if (year) {
            reqGet("holiday/user/" + token.UserID + "/year/" + year).then(res => setData(res))
        }
    }, [year])

    useEffect(() => {
        if (reload) {
            reqGet("holiday/user/" + token.UserID + "/year/" + year).then(res => setData(res))
            setReload(false)
        }
    }, [reload])

    function HandleSort(e, status?) {
        $(".btn-filter").removeClass("active")
        $(e.target).addClass("active")

        if (status)
            table.setColumnFilters([{ id: "HolidayStatus", value: status }])
        else
            table.setColumnFilters([])
    }

    return <div className="container-fluid h-100">
        <div className="row h-100">
            <div className={display + " col-xl-8 col-xxl-6 col-h-fill px-0"}>
                <div className="body-container content">

                    <header className="header">
                        <div>
                            <h2 className="section-title">Congé</h2>
                            <Link className="link-nav" to="/account">{"<"} Retour</Link>
                        </div>

                        {PrivateLinkAdd("add")}
                    </header>

                    <section className="body">

                        <nav className="row row-cols-3 mb-3">
                            <div className="col">
                                <label htmlFor="" className="form-label">Année</label>
                                <input className="form-control" type="number" defaultValue={year} onChange={(e) => setYear(parseInt(e.target.value))} />
                            </div>

                            <div className="col">
                                <label htmlFor="" className="form-label">Type</label>
                                <select className="form-select" onChange={e => table.setColumnFilters([{ id: "HolidayType", value: e.target.value }])}>
                                    <option value="">Tout</option>
                                    {Object.entries(HOLIDAY_TYPE).map((t, k) => <option key={k} value={t[1]}>{t[1]}</option>)}
                                </select>
                            </div>
                        </nav>

                        <nav className="toolbar w-100 mb-3">
                            <button className="btn btn-filter d-inline rounded-5 active" onClick={(e) => HandleSort(e)}>Tout</button>
                            <button className="btn btn-filter d-inline rounded-5" onClick={(e) => HandleSort(e, HOLIDAY_STATUS.ACCEPTED)}>Accepté</button>
                            <button className="btn btn-filter d-inline rounded-5" onClick={(e) => HandleSort(e, HOLIDAY_STATUS.WAITING)}>En attente</button>
                            <button className="btn btn-filter d-inline rounded-5" onClick={(e) => HandleSort(e, HOLIDAY_STATUS.REJECTED)}>Rejeté</button>
                        </nav>

                        {table && table?.getRowModel().rows.map(row =>
                            <React.Fragment key={row.original.HolidayID}>
                                <HolidayCard holiday={row.original} setReload={setReload} />
                            </React.Fragment>
                        )}

                    </section>
                </div>
            </div>

            <div className={display2 + " col-xxl-6 col-h-fill px-0"}>
                <Outlet />
            </div>
        </div>
    </div>
}

const ResolveHolidayStatus = ({ status }: { status: string }) => {
    switch (status) {
        case HOLIDAY_STATUS.ACCEPTED:
            return <p className="text-success"><i className={ICONS.SUCCESS + " me-2"}></i>{HOLIDAY_STATUS.ACCEPTED}</p>

        case HOLIDAY_STATUS.REJECTED:
            return <p className="text-danger"><i className={ICONS.CANCEL + " me-2"}></i>{HOLIDAY_STATUS.REJECTED}</p>

        case HOLIDAY_STATUS.WAITING:
            return <p className="text-warning"><i className={ICONS.WAITING + " me-2"}></i>{HOLIDAY_STATUS.WAITING}</p>

        default:
            return <></>
    }
}


export function HolidayCard({ holiday, showUser = false, setReload }: { holiday: IHoliday, showUser?: boolean, setReload?}) {
    const location = useLocation()
    const token = getToken()

    function RemoveHoliday() {
        reqDelete("holiday/" + holiday.HolidayID, { data: { ActivityUserID: token.UserID, ...holiday } }).then(() => {
            setReload(true)
        })
    }

    function Validate() {
        reqPut("holiday/" + holiday.HolidayID + "/validate", { ActivityUserID: token.UserID, ...holiday }).then(res => {
            setReload(true)
        })
    }

    function Reject() {
        reqPut("holiday/" + holiday.HolidayID + "/reject", { ActivityUserID: token.UserID, ...holiday }).then(res => {
            setReload(true)
        })
    }


    return <article className="card mb-3">

        {/* MODAL REMOVE */}
        <div className="modal" id={"removeHoliday" + holiday.HolidayID} tabIndex={-1} aria-labelledby={"removeHoliday" + holiday.HolidayID} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <header className="modal-header">
                        <h1 className="modal-title fs-5" id={"removeHoliday" + holiday.HolidayID}>Supprimer feuille d'heure</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </header>

                    <section className="modal-body">
                        <p>Etes-vous sûr de vouloir supprimer cette feuille d'heure ?</p>
                        <b>
                            {dateFormat(holiday.HolidayDateStart, "dd/MM/yyyy")}, {dateFormat(holiday.HolidayDateStart, "hh:mm")} - {dateFormat(holiday.HolidayDateEnd, "hh:mm")}
                        </b>
                    </section>

                    <footer className="modal-footer">
                        <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                            <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                        </button>

                        <button type="button" className={"btn btn-delete"} data-bs-dismiss="modal" onClick={() => RemoveHoliday()}>
                            <i className={ICONS.DELETE + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Supprimer</span>
                        </button>
                    </footer>
                </div>
            </div>
        </div>
        {/* MODAL REMOVE */}


        {/* MODAL ACCEPT */}
        <div className="modal" id={"validateHoliday" + holiday.HolidayID} tabIndex={-1} aria-labelledby={"validateHoliday" + holiday.HolidayID} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <header className="modal-header">
                        <h1 className="modal-title fs-5" id={"validateHoliday" + holiday.HolidayID}>Valider le congé</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </header>

                    <section className="modal-body">
                        <p>Êtes-vous sûr de vouloir valider le congé ?</p>

                        <b>{holiday.FirstName} {holiday.Name}</b>

                        <p>{dateFormat(holiday.HolidayDateStart, "dd/MM")} - {dateFormat(holiday.HolidayDateEnd, "dd/MM/yyyy")}, {holiday.HolidayType}</p>

                        <p>{holiday.HolidayNote}</p>
                    </section>

                    <footer className="modal-footer">
                        <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                            <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                        </button>

                        <button type="button" className={"btn btn-add"} data-bs-dismiss="modal" onClick={() => Validate()}>
                            <i className={ICONS.SUCCESS + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Valider</span>
                        </button>
                    </footer>
                </div>
            </div>
        </div>
        {/* MODAL ACCEPT */}


        {/* MODAL REJECT */}
        <div className="modal" id={"cancelHoliday" + holiday.HolidayID} tabIndex={-1} aria-labelledby={"cancelHoliday" + holiday.HolidayID} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <header className="modal-header">
                        <h1 className="modal-title fs-5" id={"cancelHoliday" + holiday.HolidayID}>Valider le congé</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </header>

                    <section className="modal-body">
                        <p>Êtes-vous sûr de vouloir rejeter le congé ?</p>

                        <b>{holiday.FirstName} {holiday.Name}</b>

                        <p>{dateFormat(holiday.HolidayDateStart, "dd/MM")} - {dateFormat(holiday.HolidayDateEnd, "dd/MM/yyyy")}, {holiday.HolidayType}</p>

                        <p>{holiday.HolidayNote}</p>
                    </section>

                    <footer className="modal-footer">
                        <button type="button" className="btn btn-outline me-2" data-bs-dismiss="modal">
                            <i className={ICONS.CANCEL + " me-1"}></i> Annuler
                        </button>

                        <button type="button" className={"btn btn-add"} data-bs-dismiss="modal" onClick={() => Reject()}>
                            <i className={ICONS.SUCCESS + " me-2"}></i> <span className="d-inline d-md-none d-lg-inline ms-1"> Valider</span>
                        </button>
                    </footer>
                </div>
            </div>
        </div>
        {/* MODAL REJECT */}


        <header className="card-header">
            <div>
                <h5 className="card-title">
                    <i className={ICONS.CALENDAR + " me-2"}></i>
                    {new Date(holiday.HolidayDateStart).toLocaleString('fr-CH', { month: "short", day: "numeric" })} - {new Date(holiday.HolidayDateEnd).toLocaleString('fr-CH', { month: "short", day: "numeric", year: "numeric" })}
                </h5>
                {holiday.HolidayAllDay == 0 && <h6>{dateFormat(holiday.HolidayDateStart, "hh:mm")} - {dateFormat(holiday.HolidayDateEnd, "hh:mm")}</h6>}

                {showUser && <h6>{holiday.FirstName} {holiday.Name}</h6>}
            </div>

            <div className="d-flex justify-content-end align-items-start">
                <ResolveHolidayStatus status={holiday.HolidayStatus} />


                <div className="dropdown ms-3">
                    <button className="btn btn-sm btn-outline border-0 rounded-3" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className={ICONS.OPTIONS}></i></button>

                    <ul className="dropdown-menu">

                        {holiday.HolidayStatus == HOLIDAY_STATUS.WAITING &&
                            <li>
                                <Link to={holiday.HolidayID + "/update"} className="dropdown-item mb-2" state={{ back: location.pathname }}><i className={ICONS.UPDATE + " me-2"}></i>Modifier</Link>
                            </li>
                        }

                        {holiday.HolidayStatus != HOLIDAY_STATUS.ACCEPTED &&
                            PrivateComponent(
                                <li>
                                    <button className="dropdown-item mb-2" title="Valider le congé" data-bs-toggle="modal" data-bs-target={"#validateHoliday" + holiday.HolidayID}>
                                        <i className={ICONS.SUCCESS + " me-2"}></i> Valider
                                    </button>
                                </li>,
                                GROUP.admin
                            )
                        }


                        {holiday.HolidayStatus != HOLIDAY_STATUS.REJECTED &&
                            PrivateComponent(
                                <li>
                                    <button className="dropdown-item mb-2" title="Valider le congé" data-bs-toggle="modal" data-bs-target={"#cancelHoliday" + holiday.HolidayID}>
                                        <i className={ICONS.CANCEL + " me-2"}></i> Rejeter
                                    </button>
                                </li>,
                                GROUP.admin
                            )
                        }

                        {PrivateComponent(
                            <li>
                                <button className="btn btn-delete w-100 text-start rounded-2" title="Supprimer" data-bs-toggle="modal" data-bs-target={"#removeHoliday" + holiday.HolidayID}>
                                    <i className={ICONS.DELETE + " me-2"}></i> Supprimer
                                </button>
                            </li>,
                            holiday.HolidayStatus == HOLIDAY_STATUS.WAITING ? undefined : GROUP.admin
                        )}
                    </ul>
                </div>
            </div>
        </header>

        <section className="card-content">
            <div className="row row-cols-3 mb-3">
                <div className="col">
                    <h6 className="item-title">Type</h6>
                    <p className="item-text">{holiday.HolidayType}</p>
                </div>
                {/*
                <div className="col">
                    <h6 className="item-title">Nombre de jours</h6>
                    <p className="item-text"></p>
                </div>*/}

                {(holiday.HolidayStatus == HOLIDAY_STATUS.ACCEPTED && holiday.HolidayValidatedBy) &&
                    <div className="col">
                        <h6 className="item-title">Accepté par</h6>
                        <p className="item-text">{holiday.ValidateFirstName} {holiday.ValidateName}</p>
                    </div>
                }

                {(holiday.HolidayStatus == HOLIDAY_STATUS.REJECTED && holiday.HolidayValidatedBy) &&
                    <div className="col">
                        <h6 className="item-title">Refusé par</h6>
                        <p className="item-text">{holiday.ValidateFirstName} {holiday.ValidateName}</p>
                    </div>
                }
            </div>

            <div style={{ minHeight: "3rem" }}>
                <h6 className="item-title">Description</h6>
                <p className="item-text">{holiday.HolidayNote}</p>
            </div>
        </section>
    </article>
}



export function HolidayNbHours(holiday: IHoliday) {

}